import { useSearchApiGouv } from './useSearchApiGouv';

export interface FetchDepartementSearchResult {
  nom: string;
  code: string;
}

export interface DepartementApiGouvOption {
  nom: string;
  code: string;
}

function buildSearchDepartementUrl(option: DepartementApiGouvOption): string {
  return `${
    process.env.REACT_APP_API_GEO_GOUV_URL
  }/departements?limit=15&nom=${encodeURI(option.nom)}`;
}

function mapFetchResultToOptions(
  result: Array<FetchDepartementSearchResult>,
): Array<DepartementApiGouvOption> {
  return result.map(option => {
    return {
      code: option.code,
      nom: option.nom,
    };
  });
}

export const useSearchDepartementApiGouv = (): [
  Array<DepartementApiGouvOption>,
  boolean,
  (str: string) => void,
] => {
  const [options, isFetching, fetchOptions] = useSearchApiGouv(
    {
      nom: '',
      code: '',
    },
    (option: DepartementApiGouvOption) => option.nom,
    buildSearchDepartementUrl,
    search => {
      return { nom: search, code: '' };
    },
    mapFetchResultToOptions,
  );

  return [options, isFetching, fetchOptions];
};
