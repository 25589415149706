import React from 'react';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { DossierActivityItem } from 'hooks/dossierAdmin/useFetchDetailedEvents';
import DetailedEventsTimeline from './DetailedEventsTimeline/DetailedEventsTimeline';
import DetailedEventsDetails from './DetailedEventsDetails/DetailedEventsDetails';

import './FicheAdminEvents.less';

export const FicheAdminEvents = (): React.ReactElement => {
  const [, { events, fetchInProgress, errorOccured, fetchEvents }] =
    useDossierAdminContext();

  const timeLineItems: DossierActivityItem[] = events.sort((a, b) =>
    b.date.localeCompare(a.date),
  );

  const scrollToContent = (eventIndex: number) => {
    const currentDivElement = timeLineItems[eventIndex].ref.current;
    if (currentDivElement) {
      currentDivElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className="FicheAdminEvents">
      <div className="Column TimelineContainer">
        <h2>Activités du dossier</h2>
        <AsyncComponent
          inProgress={fetchInProgress}
          errorOccured={errorOccured}
          reload={fetchEvents}
          render={() => {
            return (
              <DetailedEventsTimeline
                events={timeLineItems}
                scrollToContent={scrollToContent}
              />
            );
          }}
          errorMessage="Impossible de charger les évènements du dossier"
        />
      </div>
      <div className="ColumnDivider" />
      <div className="Column DetailedEvents">
        <div className="DetailedEventsWrapper">
          <h2>Détails des données liées aux événements</h2>
          <AsyncComponent
            inProgress={fetchInProgress}
            errorOccured={errorOccured}
            reload={fetchEvents}
            render={() => {
              return <DetailedEventsDetails events={timeLineItems} />;
            }}
            errorMessage="Impossible de charger les évènements du dossier"
          />
        </div>
      </div>
    </div>
  );
};
