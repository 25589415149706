import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { RequestStatus } from 'types/RequestStatus';

type ImportFunction = (file: Blob) => void;

/**
 * Hook to import any referentiel in CSV or JSOn format
 */
export function useImportReferentiel<ReferentielType>(
  setStatus: (newStatus: RequestStatus) => void,
  importReferentiel: (
    file: Blob,
    referentiel: ReferentielType,
  ) => Promise<Response>,
): [(referentiel: ReferentielType) => ImportFunction] {
  const behaviourOnError = useHandleBackErrors();

  const fetchReferentiel = (referentiel: ReferentielType) => {
    return async (file: Blob) => {
      setStatus(RequestStatus.PENDING);
      await importReferentiel(file, referentiel)
        .then((_: Response) => setStatus(RequestStatus.SUCCESS))
        .catch((errorResponse: Response) => {
          setStatus(RequestStatus.ERROR);
          behaviourOnError(errorResponse, true);
        });
    };
  };

  return [fetchReferentiel];
}
