import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DossierSummaryResponseDto } from 'lib_api/lib/api/gen/api';
import { Table } from 'components/WrappedComponents';
import {
  ColumnProps,
  FetchDataFunction,
} from 'components/WrappedComponents/Table/types';
import {
  SortOptions,
  useAdminSearchDossierSummaries,
} from 'hooks/dossiers/useSearchDossierSummaries';
import { buildColumns, buildHeader } from './utils';
import { DashboardDSRFilter } from '../types';

import { usePaginationContext } from 'hooks/PaginationStoreContext';
import { useDSRFilterContext } from 'hooks/DSRFilterStoreContext';
import { useSortContext } from 'hooks/SortStoreContext';

function DashboardTable(): React.ReactElement {
  const { pathname } = useLocation();
  const [filter, setfilter] = useDSRFilterContext();
  const [sort, setSort] = useSortContext();
  const searchDossierSummaries = useAdminSearchDossierSummaries();
  const [pagination, setPagination] = usePaginationContext();

  /**
   * Fetch dossiers based on search filters
   */
  const fetchData: FetchDataFunction<
    DossierSummaryResponseDto,
    DashboardDSRFilter,
    SortOptions
  > = useMemo(
    () => (pagination, sorters, filter) =>
      searchDossierSummaries(pagination, sorters, {
        statutDossierEnumList: filter.statut,
        classementList: filter.classement,
        idFourriereList: filter.fourriere,
        idNatureBrancheList: filter.natureMiseEnFourriere,
        dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
        dateRangeBonEnlevement: filter.dateRangeBonEnlevement,
        dateRangeSortieDefinitive: filter.dateRangeSortieDefinitive,
        dateRangeOrdreDestruction: filter.dateRangeOrdreDestruction,
        numeroImmatriculation: filter.search,
        includeDossiersSortis: filter.includeDossiersSortis,
        idMotifSortieList: filter.motifDeSortie,
        includeDossiersAnonymises: filter.includeDossiersAnonymises,
        listUniteFo: filter.idUniteFOList,
        statutsFacturation: filter.statutsTraitementFacturation,
      }),
    [searchDossierSummaries],
  );

  const columns: ColumnProps<DossierSummaryResponseDto, SortOptions>[] =
    React.useMemo(() => buildColumns(pathname), [pathname]);

  return (
    <div className="table-container">
      <Table
        columns={columns}
        header={buildHeader}
        fetchData={fetchData}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setfilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardTable;
