import React from 'react';

import { MotifSortieFormValues } from 'types/referentiels/MotifSortie';

import {
  useSubmitCreateMotifSortie,
  useValidateCreateMotifSortie,
} from '../utils';
import { ModalEditionMotifSortie } from './ModalEditionMotifSortie';

interface ModalDetailsProps {
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalCreate({
  visible,
  close,
  refreshDashboard,
}: ModalDetailsProps): React.ReactElement {
  const initials: MotifSortieFormValues = {};
  const { submit, inProgress } = useSubmitCreateMotifSortie(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateMotifSortie();

  return (
    <ModalEditionMotifSortie
      close={close}
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      visible={visible}
      title={'Créer un motif de sortie'}
      validate={validate}
    />
  );
}

export default ModalCreate;
