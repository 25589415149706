import React from 'react';
import { Select } from 'components/WrappedComponents';
import {
  ReferenceDtoAutoriteFourriereDto,
  ProfilFourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriereBlocFourriere,
  InputPropFourriere,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { autoriteFourriereDtoToString } from 'utils/dtoToString';
import { CSSUtils } from 'utils/CSSUtils';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { validateFicheProfilGf } from '../../utils';

type SelectAttachmentAuthorityProps = InputPropFourriere & {
  refAutoriteFourriere: ReferenceDtoAutoriteFourriereDto;
};

function SelectAttachmentAuthority({
  values,
  isEditable,
  generateRequestDto,
  refAutoriteFourriere,
}: SelectAttachmentAuthorityProps): React.ReactElement {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof FicheProfilFourriereBlocFourriere = 'attachmentAuthority';
  const label = 'Autorité de fourrière de rattachement';
  const selectItems = refAutoriteFourriere.referenceDtoList
    .sort(
      (a, b) =>
        a.ville.localeCompare(b.ville) ||
        a.type.type.localeCompare(b.type.type),
    )
    .map(autoriteFourriere => ({
      value: autoriteFourriere.id,
      displayValue: autoriteFourriereDtoToString(autoriteFourriere),
    }));

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return validateFicheProfilGf(
                  gfController,
                  ProfilFourriereViolationDtoFormFieldEnum.AUTORITES_FOURRIERE_IDS,
                  user.idCorrelationFourriere || '',
                  generateRequestDto({ ...values, [key]: value }),
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
      }}
    />
  );
}

export default SelectAttachmentAuthority;
