import React from 'react';
import classNames from 'classnames';

import './ResponsiveContainer.less';

interface ContainerProps {
  className?: string;
  maxWidth?: number;
  children: React.ReactNode;
}

/**
 * The main purpose of this component is to encapsulate content into a
 * responsive container, ie it will not exceed a max width, and will
 * adapt on smaller screens
 *
 * @param className: used to customize the container if needed
 * @param maxWidth: optional number representing the max width of the container (in rem)
 * @param children: content wrapped in the container
 * @constructor
 */
const ResponsiveContainer = ({
  className,
  children,
  maxWidth = 80,
}: ContainerProps): React.ReactElement => {
  return (
    <div
      className={classNames('ResponsiveContainer', className)}
      style={{ maxWidth: `${maxWidth}rem` }}
    >
      {children}
    </div>
  );
};

export default ResponsiveContainer;
