import React from 'react';
import { Select, Empty } from 'components/WrappedComponents';
import { Spin as AntdSpin } from 'antd';
import { useRefCommuneEnlevement } from 'hooks/referentiels/useRefCommuneEnlevement';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';

interface SelectCommuneEnlevementProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

function SelectCommuneEnlevement({
  values,
}: SelectCommuneEnlevementProps): React.ReactElement {
  const [refCommuneEnlevement, search, isFetching, fetch] =
    useRefCommuneEnlevement();

  const communeEnlevementItem = (commune: string) => ({
    value: commune,
    displayValue: commune,
  });

  const communesSelected =
    values.commune?.map(com => communeEnlevementItem(com)) ?? [];

  const selectItems =
    refCommuneEnlevement !== undefined &&
    refCommuneEnlevement.communeList.length > 0
      ? refCommuneEnlevement.communeList
          .sort((a, b) => a.localeCompare(b))
          .map(communeEnlevementItem)
      : communesSelected;

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: 'commune',
        itemProps: {
          label: "Commune d'enlèvement",
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
        onSearch: fetch,
        notFoundContent: isFetching ? (
          <AntdSpin size="small" />
        ) : search ? (
          <Empty description="Aucune commune trouvée (la commune cherchée ne compte peut-être pas de véhicule)" />
        ) : null,
      }}
    />
  );
}

export default SelectCommuneEnlevement;
