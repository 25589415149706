import React from 'react';
import moment from 'moment';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { FicheDisplay } from 'components/FicheDisplay/FicheDisplay';
import { BooleanFieldDisplay } from 'components/FieldDisplay/BooleanFieldFisplay';
import { DataFieldDisplay } from 'components/FieldDisplay/DataFieldDisplay';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import {
  autoriteFourriereDtoToString,
  motifMefDtoToString,
} from 'utils/dtoToString';
import { DATE_FORMAT, DATE_FORMAT_WITH_TIME } from 'utils/formats';

export const FicheInfraction = (): React.ReactElement => {
  const [dossier] = useDossierContext();

  const leftSideChildren = [
    <DataFieldDisplay
      key={1}
      label={'Autorité de fourrière'}
      value={dossier.computed?.autoriteFourriereDto || null}
      formatter={autoriteFourriereDtoToString}
    />,
    <DataFieldDisplay
      key={2}
      label={"Date de constatation de l'infraction"}
      value={dossier.body?.ficheInfraction?.dateConstatation}
      formatter={date => moment(date).format(DATE_FORMAT)}
    />,
    <FieldDisplay
      key={3}
      label={"Nom de l'autorité ayant prescrit la mesure"}
      value={
        dossier.body?.ficheInfraction?.autoritePrescriptrice?.nomAutorite ||
        null
      }
    />,
    <FieldDisplay
      key={4}
      label={"Qualité de l'autorité"}
      value={
        dossier.body?.ficheInfraction?.autoritePrescriptrice?.qualiteAutorite ||
        null
      }
    />,
    <FieldDisplay
      key={5}
      label={"Nom de l'agent"}
      value={dossier.body?.ficheInfraction?.agent?.nomAgent || null}
    />,
    <FieldDisplay
      key={6}
      label={'Identifiant/NIGEND'}
      value={dossier.body?.ficheInfraction?.agent?.nigend || null}
    />,
    <FieldDisplay
      key={7}
      label={'Unité'}
      value={dossier.computed?.uniteFODto?.unite || null}
    />,
    <FieldDisplay
      key={8}
      label={'Adresse électronique'}
      value={
        (dossier.body?.ficheInfraction?.emailUnite ??
          dossier.computed?.uniteFODto?.email) ||
        null
      }
    />,
  ];
  const rightSideChildren = [
    <BooleanFieldDisplay
      key={1}
      label={'Lieu privé'}
      value={dossier.body?.ficheInfraction?.lieuPrive || null}
    />,
    <DataFieldDisplay
      key={2}
      label={'Motivation de la mesure'}
      value={dossier.body?.ficheInfraction?.motifMef || null}
      formatter={motifMefDtoToString}
    />,
    <FieldDisplay
      key={3}
      label={"Lieu d'enlèvement"}
      value={dossier.body?.ficheInfraction?.lieuEnlevement || null}
    />,
    <FieldDisplay
      key={4}
      label={'Commune'}
      value={dossier.body?.ficheInfraction?.communeEnlevement || null}
    />,
    <BooleanFieldDisplay
      key={5}
      label={'Nuit'}
      value={dossier.body?.ficheInfraction?.nuit}
    />,
    <BooleanFieldDisplay
      key={6}
      label={'Pluie ou neige'}
      value={dossier.body?.ficheInfraction?.pluieOuNeige}
    />,
    <FieldDisplay
      key={7}
      label={'Autorité qualifée pour lever la mesure'}
      value={
        dossier.body?.ficheInfraction?.autoriteMainlevee?.description || null
      }
      additionalValueClassName={'multiLineValue'}
    />,
    <DataFieldDisplay
      key={8}
      label={'Date et heure'}
      value={dossier.body?.ficheInfraction?.dateRedaction}
      formatter={date => moment(date).format(DATE_FORMAT_WITH_TIME)}
    />,
  ];

  return (
    <FicheDisplay
      leftSideChildren={leftSideChildren}
      rightSideChildren={rightSideChildren}
    />
  );
};
