import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
import { WarningTwoTone as AntdWarningIcon } from '@ant-design/icons/lib/icons';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

import './FaussePlaqueFieldDisplay.less';

interface FaussePlaqueProps {
  immatriculation: string | null;
  immatriculationVisible: string | null;
}

const FaussePlaqueFieldDisplay = (
  props: FaussePlaqueProps,
): React.ReactElement => {
  if (!props.immatriculationVisible) {
    return <></>;
  }

  return (
    <FieldDisplay
      key="fausse_plaque"
      label={
        <div>
          <AntdTooltip
            title={
              <div className={'immatriculationTooltipTitle'}>
                {`Le véritable numéro d'immatriculation de ce véhicule est : ${props.immatriculation}.`}
              </div>
            }
          >
            <AntdWarningIcon twoToneColor="#FF6600" className="WarningSign" />
          </AntdTooltip>
          <span>Fausse Plaque</span>
        </div>
      }
      value={props.immatriculationVisible}
      labelClass="labelAdmin"
    />
  );
};

export default FaussePlaqueFieldDisplay;
