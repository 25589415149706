import { useState, useEffect } from 'react';
import { useApi } from '../ApiStoreContext';
import {
  AutoriteFourriereControllerApi,
  ForceOrdreControllerApi,
  SourcesImpossibiliteNotifierDto,
} from 'lib_api/lib/api/gen/api';
import { useHandleBackErrors } from '../utils/handleBackErrors';
import { CoordonneesProprietaire } from 'components/FicheDescriptiveDisplay/CoordonneesProprietaire/types';

export const useComputeImpossibiliteNotifier = (
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  id: string,
  values: CoordonneesProprietaire,
): SourcesImpossibiliteNotifierDto | undefined => {
  const behaviourOnError = useHandleBackErrors();
  const [sources, setSources] = useState<
    SourcesImpossibiliteNotifierDto | undefined
  >(undefined);

  const afController = useApi().AutoriteFourriereControllerApi;
  const foController = useApi().ForceOrdreControllerApi;

  useEffect(() => {
    if (controller instanceof AutoriteFourriereControllerApi) {
      afController
        .getSourceImpossibiliteNotifierAfUsingGET(id, values)
        .then(result => setSources(result))
        .catch(behaviourOnError);
    }
    if (controller instanceof ForceOrdreControllerApi) {
      foController
        .getSourceImpossibiliteNotifierFoUsingGET(id, values)
        .then(result => setSources(result))
        .catch(behaviourOnError);
    }
  }, [controller, behaviourOnError, afController, foController, id, values]);

  return sources;
};
