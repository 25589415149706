import React from 'react';
import { Empty, Spin as AntdSpin } from 'antd';
import { BaseSearchResult } from 'types/searchResult';

import BaseSelect, { BaseSelectProps } from './BaseSelect';
import { setFieldValue } from '../utils';
import { useBaseFormContext } from '../BaseFormContext';

interface BaseSelectWithSearchProps<FormValues, OptionType>
  extends Omit<BaseSelectProps<FormValues, OptionType>, 'options'> {
  useSearchEntities: (search?: string) => BaseSearchResult<OptionType>;
  additionalOptions?: OptionType[];
}

export default function BaseSelectWithSearch<FormValues, OptionType>({
  useSearchEntities,
  defaultValue,
  additionalOptions,
  ...props
}: BaseSelectWithSearchProps<FormValues, OptionType>): React.ReactElement {
  const { result, inProgress, search } = useSearchEntities(defaultValue);
  const form = useBaseFormContext<FormValues>();

  return (
    <BaseSelect
      {...props}
      onChange={(value, option) => {
        props.onChange && props.onChange(value, option);
        if (option && props.id) {
          setFieldValue(form, props.id, option);
        }
      }}
      showSearch={true}
      options={[...(additionalOptions ?? []), ...result]}
      dropdownMatchSelectWidth={350}
      onSearch={search}
      notFoundContent={inProgress ? <AntdSpin size="small" /> : <Empty />}
    />
  );
}
