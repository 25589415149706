import React from 'react';
import { Alert as AntdAlert } from 'antd';
import { ConditionsReactivateFaqDomainDto } from 'lib_api/lib/api/gen';

import './ConditionReactivateFaqDomain.less';

interface ConditionReactivateFaqDomainProps {
  conditions: ConditionsReactivateFaqDomainDto | undefined;
}

function ConditionReactivateFaqDomain({
  conditions,
}: ConditionReactivateFaqDomainProps): React.ReactElement {
  return (
    <>
      {(conditions?.faqDomainValid || conditions?.alreadyExists) && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.faqDomainValid && (
                <p className="alertInvalid--txt">Le domaine est déjà valide.</p>
              )}
              {conditions?.alreadyExists && (
                <p className="alertInvalid--txt">
                  Un domaine actif équivalent existe déjà dans le référentiel.
                </p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateFaqDomain;
