import React from 'react';
import {
  Button as AntdButton,
  ButtonProps as AntdButtonProps,
  Popover as AntdPopover,
} from 'antd';
import { useBaseFormContext } from './BaseFormContext';

export interface SubmitButtonProps
  extends Omit<AntdButtonProps, 'form' | 'disabled'> {
  hasError: boolean;
  text: string;
  isSubmitting: boolean;
  isValidatingBeforeSubmit: boolean;
  startIsValidating: () => void;
}

export default function SubmitButton<FormValues>({
  isSubmitting,
  hasError,
  startIsValidating,
  isValidatingBeforeSubmit,
  text,
  ...props
}: SubmitButtonProps): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  const SubmitButton = (
    <AntdButton
      {...props}
      disabled={isSubmitting || hasError || isValidatingBeforeSubmit}
      onClick={() => {
        startIsValidating();
        form.submit();
      }}
      loading={isSubmitting || isValidatingBeforeSubmit}
      type="primary"
    >
      {text}
    </AntdButton>
  );

  if (hasError) {
    return (
      <AntdPopover
        content={
          hasError
            ? 'Le formulaire comporte des erreurs. Corrigez-les pour continuer.'
            : undefined
        }
        trigger={'hover'}
      >
        {SubmitButton}
      </AntdPopover>
    );
  }

  return SubmitButton;
}
