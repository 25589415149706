import { GenreDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';

export function fetchGenre(
  refController: ReferentielControllerApi,
): Promise<GenreDto[]> {
  return refController.getValidGenreUsingGET().then(response => {
    return response.referenceDtoList.sort((a, b) =>
      a.code.localeCompare(b.code),
    );
  });
}
