import React from 'react';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { Navigate, Route, Routes } from 'react-router-dom';

import { NotFoundPage } from 'components/Error';
import { NOT_FOUND_PATH, profileEnumMapping } from 'utils/enumData';
import { useUserStore } from 'hooks/UserStoreContext';
import GfRouter from 'pages/gardien-fourriere/GfRouter';
import AfRouter from 'pages/autorite-fourriere/AfRouter';
import FoRouter from 'pages/forces-de-l-ordre/FoRouter';
import AdminRouter from 'pages/admin-fonctionnel/AdminRouter';
import DsrRouter from 'pages/delegue-securite-routiere/DsrRoutes';
import MentionsLegales from 'components/MentionsLegales';
import FAQ from 'components/FAQ';
import Tutorial from 'components/Tutorial/Tutorial';
import ProfileWrapper from 'components/ProfileWrapper';
import AuthGuard from './AuthGuard';

const AppRoutes: React.FC = () => {
  const user = useUserStore();
  const { mentionsLegalesPath, profilPath, faqPath, tutorielPath } =
    profileEnumMapping[user.profile];

  return (
    <Routes>
      {/* Common routes */}
      {profilPath && <Route path={profilPath} element={<ProfileWrapper />} />}
      {faqPath && <Route path={faqPath} element={<FAQ />} />}
      {tutorielPath && <Route path={tutorielPath} element={<Tutorial />} />}
      <Route path={mentionsLegalesPath} element={<MentionsLegales />} />
      <Route path={NOT_FOUND_PATH} element={<NotFoundPage />} />
      <Route path="*" element={<Navigate replace to={NOT_FOUND_PATH} />} />

      {user.profile !== null && (
        <Route
          path="/"
          element={<Navigate to={profileEnumMapping[user.profile].homePath} />}
        />
      )}

      {/* Specific routes for GF */}
      <Route
        path={profileEnumMapping[UserDtoProfileEnum.GARDIEN_FOURRIERE].rootPath}
        element={
          <AuthGuard roles={[UserDtoProfileEnum.GARDIEN_FOURRIERE]}>
            <GfRouter />
          </AuthGuard>
        }
      />

      {/* Specific routes for AF */}
      <Route
        path={
          profileEnumMapping[UserDtoProfileEnum.AUTORITE_FOURRIERE].rootPath
        }
        element={
          <AuthGuard roles={[UserDtoProfileEnum.AUTORITE_FOURRIERE]}>
            <AfRouter />
          </AuthGuard>
        }
      />

      {/* Specific routes for FO */}
      <Route
        path={profileEnumMapping[UserDtoProfileEnum.FORCE_DE_L_ORDRE].rootPath}
        element={
          <AuthGuard roles={[UserDtoProfileEnum.FORCE_DE_L_ORDRE]}>
            <FoRouter />
          </AuthGuard>
        }
      />

      {/* Specific routes for DSR */}
      <Route
        path={profileEnumMapping[UserDtoProfileEnum.DSR_BLR].rootPath}
        element={
          <AuthGuard roles={[UserDtoProfileEnum.DSR_BLR]}>
            <DsrRouter />
          </AuthGuard>
        }
      />

      {/* Specific routes for Admin */}
      <Route
        path={profileEnumMapping[UserDtoProfileEnum.ADMIN_FONCTIONNEL].rootPath}
        element={
          <AuthGuard roles={[UserDtoProfileEnum.ADMIN_FONCTIONNEL]}>
            <AdminRouter />
          </AuthGuard>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
