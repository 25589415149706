import React from 'react';

import { FourriereDto } from 'lib_api/lib/api/gen';

import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { FourriereSort } from 'enums/referentiels/Fourriere';
import { buildHeader, useSearchFourriere } from './utils';
import { useReferentielDashboardSearchWithSort } from '../search/search';
import CellDetailsAndActions from './CellDetailsAndActions';

import './DashboardFourriere.less';

function buildDetailsContent(
  data: FourriereDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions
      fourriere={data}
      refreshDashboard={refreshDashboard}
    />
  );
}

function DashboardFourriere(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchFourriere,
    {
      enabled: true,
    },
    {
      RAISON_SOCIALE: true,
    },
  );

  function buildColumns(): ColumnProps<FourriereDto, FourriereSort>[] {
    return [
      {
        title: 'Raison sociale',
        buildCellContent: data => data.raisonSociale,
        sortedField: FourriereSort.RAISON_SOCIALE,
      },
      {
        title: 'Code postal',
        buildCellContent: data => data.codePostal.padStart(5, '0'),
        sortedField: FourriereSort.CODE_POSTAL,
      },
      {
        title: 'Commune',
        buildCellContent: data => data.commune,
        sortedField: FourriereSort.COMMUNE,
      },
      {
        title: 'Raison sociale DNID',
        buildCellContent: data => data.raisonSocialeDnid,
        sortedField: FourriereSort.RAISON_SOCIALE_DNID,
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<FourriereDto, FourriereSort>[] = buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardFourriere;
