import React from 'react';
import { useLocation } from 'react-router-dom';
import { Menu } from 'antd';

import { NavbarProps } from './types';
import { buildItem } from './utils';

function NavBar({ items }: NavbarProps): React.ReactElement {
  const location = useLocation();

  const selectedKeys = items
    ?.flatMap(item => item.dropdown ?? item)
    // Find items that match current location
    ?.filter(item => item.link === location.pathname)
    // Get the label of the items
    ?.flatMap(item => (item.label ? [item.label] : []));

  return (
    <Menu mode={'horizontal'} selectedKeys={selectedKeys}>
      {items?.map(buildItem)}
    </Menu>
  );
}

export default NavBar;
