import React from 'react';

import {
  getCSVEnumWithDisplayableValue,
  getJSONEnumWithDisplayableValue,
} from 'utils/referentielUtils';
import ResponsiveContainer from 'components/ResponsiveContainer';
import useDownloadReferentiel from 'hooks/referentiels/useDownloadReferentiel';

import ReferentielExportComponent from './ReferentielExportComponent/ReferentielExportComponent';

import './ReferentielExport.less';

const ReferentielExport: React.FC = () => {
  const { status, downloadCsv, downloadJson } = useDownloadReferentiel();

  const referentialEnumCSV = getCSVEnumWithDisplayableValue();
  const referentialEnumJSON = getJSONEnumWithDisplayableValue();

  return (
    <ResponsiveContainer className="ReferentielExport">
      <h1>Export des référentiels</h1>
      <p className="description">
        {`Il vous est possible de télécharger les référentiels depuis cette page. 
        Pour cela, il vous suffit de cliquer 
        sur le référentiel que vous voulez télécharger.
        Le fichier généré pourra être de type JSON ou CSV.`}
      </p>
      <ReferentielExportComponent
        title="Téléchargement des référentiels au format CSV"
        status={status}
        submit={downloadCsv}
        values={referentialEnumCSV}
      />
      <ReferentielExportComponent
        title="Téléchargement des référentiels au format JSON"
        status={status}
        submit={downloadJson}
        values={referentialEnumJSON}
      />
    </ResponsiveContainer>
  );
};

export default ReferentielExport;
