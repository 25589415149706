import {
  ActionsRequisesDtoActionsRequisesEnum,
  CountDossiersResultDto,
} from 'lib_api/lib/api/gen';
import { DashboardFOFilter } from '../types';

export function libelleVehiculeAcontroler(
  filter: Partial<DashboardFOFilter>,
  count: CountDossiersResultDto | null,
): string {
  const total = count?.total ?? 0;
  const actionsRequises = filter.actionsRequises ?? [];

  if (
    (actionsRequises.length > 0 &&
      !actionsRequises.includes(
        ActionsRequisesDtoActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE,
      )) ||
    total === 0
  ) {
    return '';
  }

  return `dont ${total} ${total > 1 ? 'véhicules' : 'véhicule'} à contrôler`;
}
