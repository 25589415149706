import React from 'react';

import { MotifSortieDto } from 'lib_api/lib/api/gen';

import { ColumnProps } from 'components/WrappedComponents/Table/types';
import TableWithoutPaginationAndSort from 'components/WrappedComponents/Table/TableWithoutPaginationAndSort';

import { useReferentielDashboardUnpagedSearch } from '../utils';
import CellDetailsAndActions from './CellDetailsAndActions';
import { FilterDashboardMotifSortie } from './types';
import { buildHeader, useSearchMotifSortie } from './utils';

function buildDetailsAndActionsContent(
  motifSortie: MotifSortieDto,
  refreshDashboard: () => void,
) {
  return (
    <CellDetailsAndActions
      motifSortie={motifSortie}
      refreshDashboard={refreshDashboard}
    />
  );
}

function buildColumns(
  refreshDashboard: () => void,
): ColumnProps<MotifSortieDto>[] {
  return [
    {
      title: 'Motif',
      buildCellContent: motifSortie => motifSortie.motif,
    },
    {
      title: 'Commentaire',
      buildCellContent: motifSortie => motifSortie.commentaire,
    },
    {
      title: "Délai d'anonymisation (en mois)",
      buildCellContent: motifSortie => motifSortie.delaiEffacementEnMois,
    },
    {
      title: '',
      buildCellContent: motifSortie =>
        buildDetailsAndActionsContent(motifSortie, refreshDashboard),
    },
  ];
}

function DashboardMotifSortie(): React.ReactElement {
  const defaultFilter: FilterDashboardMotifSortie = { valid: true };

  const { fetchData, filter, setFilter, refreshDashboard } =
    useReferentielDashboardUnpagedSearch(useSearchMotifSortie, defaultFilter);

  return (
    <div className="table-container">
      <TableWithoutPaginationAndSort
        columns={buildColumns(refreshDashboard)}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={motifSortie => motifSortie.id}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  );
}

export default DashboardMotifSortie;
