import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { CentreVHUDto } from 'lib_api/lib/api/gen';
import React from 'react';
import { useReferentielDashboardSearchWithSort } from '../search/search';
import { buildHeader, useSearchCentreVHU } from './utils';

import { CentreVHUSortOptions } from './types';
import CellDetailsAndActions from './CellDetailsAndActions';

function DashboardCentreVHU(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchCentreVHU,
    {
      valid: true,
    },
    {
      RAISON_SOCIALE: true,
    },
  );

  function buildDetailsContent(
    data: CentreVHUDto,
    refreshDashboard: () => void,
  ): React.ReactElement {
    return (
      <CellDetailsAndActions
        centreVHU={data}
        refreshDashboard={refreshDashboard}
      />
    );
  }

  function buildColumns(): ColumnProps<CentreVHUDto, CentreVHUSortOptions>[] {
    return [
      {
        title: 'Raison sociale',
        buildCellContent: data => data.raisonSociale,
        sortedField: CentreVHUSortOptions.RAISON_SOCIALE,
      },
      {
        title: 'Code postal',
        buildCellContent: data => data.codePostal,
        sortedField: CentreVHUSortOptions.CODE_POSTAL,
      },
      {
        title: 'Commune',
        buildCellContent: data => data.commune,
        sortedField: CentreVHUSortOptions.COMMUNE,
      },
      {
        title: "Numéro d'agrément",
        buildCellContent: data => data.numeroAgrementVHU,
        sortedField: CentreVHUSortOptions.NUMERO_AGREMENT,
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<CentreVHUDto, CentreVHUSortOptions>[] =
    buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        setSort={setSort}
        sort={sort}
      />
    </div>
  );
}

export default DashboardCentreVHU;
