import React, { useCallback, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { Form } from 'components/WrappedComponents';
import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { useSubmitSortieVehicule } from 'hooks/dossiers/useSubmitSortieVehicule';
import { SortieVehicule } from 'types/SortieVehicule';
import { DatePickerSortieParc, SelectMotif } from './Fields';
import {
  generateSortieVehiculeFromDossier,
  generateSortieVehiculeRequestDtoFromSortieVehicule,
} from './utils';

import './SortieVehicule.less';
import { useValidateSortieVehiculeForm } from '../utils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

interface SortieVehiculeProps {
  dossier: DossierResponseDto;
}

const SortieVehiculeForm: React.FC<SortieVehiculeProps> = ({
  dossier,
}: SortieVehiculeProps): React.ReactElement => {
  const [values, setValues] = useState<SortieVehicule>(
    generateSortieVehiculeFromDossier(dossier),
  );

  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const [shouldRedirect, submitSortieVehicule] = useSubmitSortieVehicule();

  const submitForm = useCallback((): Promise<void> => {
    return submitSortieVehicule(values, dossierId);
  }, [values, dossierId, submitSortieVehicule]);

  const [validateForm] = useValidateSortieVehiculeForm(submitForm);

  if (shouldRedirect) {
    return <Navigate to="/gardien-fourriere" />;
  }

  return (
    <Form
      initialValue={values}
      id={'SortieVehiculeForm'}
      onSubmit={async (values): Promise<void> => {
        return await validateForm(gfController =>
          gfController.validateSortieVehiculeFormUsingPOST(
            dossierId,
            generateSortieVehiculeRequestDtoFromSortieVehicule(values),
          ),
        );
      }}
      onChange={(values: SortieVehicule): void => {
        setValues(values);
      }}
      validateButtonTitle={'Confirmer la sortie'}
    >
      {(values, submitButton): React.ReactElement => {
        return (
          <>
            <DatePickerSortieParc values={values} dossierId={dossierId} />
            <SelectMotif values={values} dossierId={dossierId} />

            <div className="SubmitWrapper">{submitButton}</div>
          </>
        );
      }}
    </Form>
  );
};

export default SortieVehiculeForm;
