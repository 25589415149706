import { useState, useEffect } from 'react';

import { ReferenceDtoEtatDto, EtatDtoEtatEnum } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefEtatDetailsType = ReferenceDtoEtatDto | undefined;

export const useRefEtatDetails = (
  etatEnum: EtatDtoEtatEnum | undefined,
): RefEtatDetailsType => {
  const behaviourOnError = useHandleBackErrors();
  const [refEtatDetails, setRefEtatDetails] =
    useState<RefEtatDetailsType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (etatEnum !== undefined) {
      referentielControllerApi
        .getDetailFromEtatUsingGET(etatEnum)
        .then(fetchedRef => {
          setRefEtatDetails(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, etatEnum]);
  return refEtatDetails;
};
