import { useCallback, useEffect, useState } from 'react';

import { MotifSortieDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefMotifSortieResult = {
  items: MotifSortieDto[];
  loading: boolean;
  errorOccured: boolean;
  fetchItems: () => Promise<void>;
};

export const useRefMotifSortie = (): RefMotifSortieResult => {
  const behaviourOnError = useHandleBackErrors();
  const [items, setItems] = useState<MotifSortieDto[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { ReferentielControllerApi: referentielController } = useApi();

  const fetchItems = useCallback(async () => {
    setItems([]);
    setErrorOccured(false);
    setLoading(true);

    await referentielController
      .getValidMotifSortieUsingGET()
      .then(result => {
        setItems(result.referenceDtoList);
      })
      .catch(errorResponse => {
        setErrorOccured(true);
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [referentielController, behaviourOnError]);

  useEffect(() => {
    void fetchItems();
  }, [referentielController, fetchItems]);

  return {
    items: items,
    loading: loading,
    errorOccured: errorOccured,
    fetchItems: fetchItems,
  };
};
