import React from 'react';
import classNames from 'classnames';
import { Timeline as AntdTimeline } from 'antd';
import {
  CheckCircleOutlined as AntdCheckCircleOutlined,
  WarningFilled as AntdWarningFilled,
} from '@ant-design/icons';

import { DossierActivityItem } from 'hooks/dossierAdmin/useFetchDetailedEvents';
import EventItemLabel from './EventItemLabel/EventItemLabel';

import './DetailedEventsTimeline.less';

interface DetailedEventsTimelineProps {
  events: DossierActivityItem[];
  scrollToContent: (eventIndex: number) => void;
}

export default function DetailedEventsTimeline({
  events,
  scrollToContent,
}: DetailedEventsTimelineProps): React.ReactElement {
  return (
    <AntdTimeline className="EventsTimeline">
      {events.map((item, index) => {
        const dot = item.isIncident ? (
          <AntdWarningFilled />
        ) : (
          <AntdCheckCircleOutlined />
        );

        return (
          <AntdTimeline.Item
            dot={dot}
            className={classNames('EventItem', {
              IncidentItem: item.isIncident,
            })}
            key={index}
          >
            <span
              onClick={() => {
                scrollToContent(index);
              }}
              className="DetailLink"
            >
              <EventItemLabel
                timestamp={item.date}
                label={item.label}
                inducedStatutDossier={item.inducedStatutDossier}
                details={item.details}
              />
            </span>
          </AntdTimeline.Item>
        );
      })}
    </AntdTimeline>
  );
}
