import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { Dispatch, SetStateAction } from 'react';
import { message as AntdMessage } from 'antd';
import { LONG_DELAY } from '../Dashboard/constants';

export function useSubmitResetUserPassword(
  setLoading: Dispatch<SetStateAction<boolean>>,
): (userId: string, username: string | null, email: string | null) => void {
  const cuController = useApi().AdminFonctionnelControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return (
    userId: string,
    username: string | null,
    email: string | null,
  ): void => {
    setLoading(true);
    cuController
      .resetPasswordUsingPUT(userId)
      .then(() => {
        void AntdMessage.success(
          `Un courriel de réinitialisation de mot de passe du compte ${username} a été envoyé à ${email}.`,
          LONG_DELAY,
        );
      })
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}
