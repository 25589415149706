export enum CSSUtils {
  //  TODO : à check
  defaultSuiviVehiculeCol = 24,
  lgSuiviVehiculeCol = 12,
  smallWrapperCol = 2,
  mediumWrapperCol = 4,
  largeWrapperCol = 8,
  LlargeWrapperCol = 10,
  XLargeWrapperCol = 12,
  //  global default
  defaultLabelCol = 13,
  defaultWrapperCol = largeWrapperCol,
}
