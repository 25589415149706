import { useState, useEffect, useCallback } from 'react';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  ReferenceDtoUniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';

type RefUniteDtoType = ReferenceDtoUniteFOResponseDto | undefined;
type RefUniteDtoFetcher = (str: string) => void;

export const useRefUniteFoSearch = (
  field: AutocompleteUniteFOResultDtoFieldEnum = AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
  callApi = true,
): [RefUniteDtoType, string | undefined, boolean, RefUniteDtoFetcher] => {
  const behaviourOnError = useHandleBackErrors();

  const [searched, setSearched] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);
  const [refUniteFo, setRefUniteFo] = useState<RefUniteDtoType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    let cancel = false;
    if (searched === undefined || searched === '') {
      setFetching(false);
      return;
    }

    referentielControllerApi
      .searchedValidUniteFOUsingGET({ field, searched })
      .then(fetchedRef => {
        if (cancel) {
          return;
        }

        setFetching(false);
        setRefUniteFo(fetchedRef.reference);
      })
      .catch((errorResponse: Response) => {
        if (cancel) {
          return;
        }

        setFetching(false);
        behaviourOnError(errorResponse);
      });

    return () => {
      cancel = true;
    };
  }, [referentielControllerApi, behaviourOnError, callApi, searched, field]);

  const fetch = useCallback(
    (str: string) => {
      setFetching(true);
      setSearched(encodeURIComponent(str));
      setRefUniteFo(undefined);
    },
    [setSearched, setRefUniteFo],
  );

  return [refUniteFo, searched, fetching, fetch];
};
