import React from 'react';
import { RangeDatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { CSSUtils } from 'utils/CSSUtils';
import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';

interface DateOrdreDestructionProps {
  values: Partial<DashboardAutoriteFourriereFilterForm>;
}

function DateOrdreDestruction({
  values,
}: DateOrdreDestructionProps): React.ReactElement {
  return (
    <RangeDatePicker
      wrapperProps={{
        values: values,
        fieldKey: 'dateRangeOrdreDestruction',
        itemProps: {
          label: "Date d'ordre de destruction",
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      dateRangeProps={{
        placeholder: ['De', 'À'],
        allowEmpty: [true, true],
        style: { width: '100%' },
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DateOrdreDestruction;
