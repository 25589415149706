import React from 'react';
import { DossierSummaryResponseDto } from 'lib_api/lib/api/gen';
import {
  Badge as AntdBadge,
  Col as AntdCol,
  Row as AntdRow,
  Tooltip as AntdTooltip,
} from 'antd';
import {
  LABEL_DONNEE_EXPIREE,
  LABEL_IMMAT_MANQUANTE,
  LABEL_SANS_PLAQUE,
} from '../../../utils/global';
import { ExclamationCircleTwoTone as AntdWarningIcon } from '@ant-design/icons/lib/icons';
import { Link } from 'react-router-dom';
import { gold } from '@ant-design/colors';
import './DashboardImmatriculationCell.less';

function buildImmatricutionTooltipTitle(
  dossier: DossierSummaryResponseDto,
): React.ReactNode {
  const label = `Le véritable numéro d'immatriculation de ce véhicule est : ${dossier.ficheVehicule?.numeroIdentification}. 

Le numéro d'immatriculation visible sur le véhicule apparaît entre parenthèses`;
  return <div className={'immatriculationTooltipTitle'}>{label}</div>;
}

interface DashbardImmatriculationCellProps {
  url: string;
  dossier: DossierSummaryResponseDto;
}

export const DashbardImmatriculationCell = ({
  url,
  dossier,
}: DashbardImmatriculationCellProps): React.ReactElement => {
  const antdWarningColor = gold.primary;

  return (
    <Link to={`${url}/dossier/${dossier.id}`} className="immat-link">
      <AntdRow gutter={12} justify="center" align="middle">
        {dossier.anonymise ? (
          <>
            <AntdCol>
              <AntdRow justify="center" align="middle">
                {LABEL_DONNEE_EXPIREE}
              </AntdRow>
            </AntdCol>
          </>
        ) : (
          <>
            <AntdCol>
              <AntdRow justify="center" align="middle">
                {dossier.ficheVehicule?.sansPlaques &&
                !dossier.ficheVehicule?.numeroIdentification
                  ? LABEL_SANS_PLAQUE
                  : dossier.ficheVehicule?.numeroIdentification ||
                    LABEL_IMMAT_MANQUANTE}
              </AntdRow>
              {dossier.ficheVehicule?.numeroIdentificationVisible && (
                <AntdRow justify="center" align="middle">
                  {'[ ' +
                    dossier.ficheVehicule?.numeroIdentificationVisible +
                    ' ]'}
                </AntdRow>
              )}
            </AntdCol>
            {((dossier.ficheVehicule?.sansPlaques &&
              dossier.ficheVehicule?.numeroIdentification) ||
              dossier.ficheVehicule?.numeroIdentificationVisible) && (
              <AntdCol>
                <AntdRow justify="center" align="middle">
                  <AntdTooltip
                    title={buildImmatricutionTooltipTitle(dossier)}
                    overlayStyle={{ cursor: 'pointer' }}
                  >
                    <AntdBadge size={'small'}>
                      <AntdWarningIcon
                        twoToneColor={antdWarningColor}
                        className="actionsRequisesIcon"
                      />
                    </AntdBadge>
                  </AntdTooltip>
                </AntdRow>
              </AntdCol>
            )}
          </>
        )}
      </AntdRow>
    </Link>
  );
};
