import FileSaver from 'file-saver';
import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';
import moment from 'moment';
import { DATE_FORMAT_FOR_FILE } from 'utils/formats';

/**
 * Hook to export dossier based on given filters.
 */
export function useDownloadDossierAdminFonctionnel(
  setLoading: (value: boolean) => void,
  dossierId: string,
  immatriculation: string | null,
): () => void {
  const { AdminFonctionnelControllerApi: dossierController } = useApi();
  const behaviourOnError = useHandleBackErrors();

  return async () => {
    setLoading(true);
    await dossierController
      .getAllDonneesAndEventsDossierUsingGET(dossierId)
      .then((result: ExportDonneesDossierAdminFonctionnelDto) => {
        FileSaver.saveAs(
          new Blob([JSON.stringify(result)]),
          `Dossier_${immatriculation}_${moment().format(
            DATE_FORMAT_FOR_FILE,
          )}.json`,
        );
      })
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}
