import { CreateDossierFromGardienFourriereDto } from 'lib_api/lib/api/gen';

import { FicheDescriptiveFormValues, VehiculeVerouillage } from './types';
import { vehiculeFormValuesToRequestDto } from './FicheVehiculeForm/utils';
import { fourriereFormValuesToRequestDto } from './FicheFourriereForm/utils';
import { infractionFormValuesToRequestDto } from './FicheInfractionForm/utils';

export function ficheDescriptiveFormValuesToRequest(
  ficheDescriptive: FicheDescriptiveFormValues,
  idCorrelationFourriere: string,
): CreateDossierFromGardienFourriereDto {
  return {
    ficheVehiculeRequest: vehiculeFormValuesToRequestDto(
      ficheDescriptive.ficheVehicule,
    ),
    ficheFourriereRequest: fourriereFormValuesToRequestDto(
      ficheDescriptive.ficheFourriere,
      idCorrelationFourriere,
    ),
    ficheInfractionRequest: infractionFormValuesToRequestDto(
      ficheDescriptive.ficheInfraction,
      idCorrelationFourriere,
    ),
  };
}

export function initialFicheDescriptiveFormValues(): FicheDescriptiveFormValues {
  return {
    ficheFourriere: {},
    ficheInfraction: {},
    ficheVehicule: {
      verrouillage: [
        VehiculeVerouillage.VEROUILLAGE_COFFRES,
        VehiculeVerouillage.VEROUILLAGE_PORTES,
      ],
    },
  };
}
