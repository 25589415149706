import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FluxDto } from 'lib_api/lib/api/gen';
import { useModal } from 'hooks/modal/useModal';
import ModalError from '../ModalError/ModalError';
import './ErrorCell.less';

interface CellDetailsAndActionsProps {
  flux: FluxDto;
}

interface TypeErrorProps {
  flux: FluxDto;
  openModale: () => void;
}

function ErrorCell({ flux }: CellDetailsAndActionsProps): React.ReactElement {
  const errorModale = useModal();

  return (
    <>
      <TypeError flux={flux} openModale={errorModale.open} />
      <ModalError
        flux={flux}
        visible={errorModale.visible}
        close={errorModale.close}
      />
    </>
  );
}

export default ErrorCell;

function TypeError({ flux, openModale }: TypeErrorProps): React.ReactElement {
  const { pathname } = useLocation();

  if (flux.error) {
    return (
      <Link onClick={openModale} to={'#'} className={'linkOnErrorOrWarning'}>
        Flux KO
      </Link>
    );
  } else if (flux.nbWarnings && flux.nbWarnings > 0) {
    return (
      <Link
        to={`${pathname}/${flux.id}?dateFlux=${flux.dateAppel}`}
        className={'linkOnErrorOrWarning'}
      >
        Retours non interprétés ({flux.nbWarnings}).
      </Link>
    );
  } else {
    return <div>/</div>;
  }
}
