import React from 'react';

import { Select } from 'components/WrappedComponents/index';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useRefMotifSortie } from 'hooks/referentiels/useRefMotifSortie';
import { InternalMotifSortieProps } from './type';

function InternalSelectMotifSortie<FormValues>({
  values,
  fieldKey,
  validator,
  mode,
  mapMotifSortieToSelectValue,
}: InternalMotifSortieProps<FormValues>): React.ReactElement {
  const { items } = useRefMotifSortie();

  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: fieldKey,
        itemProps: {
          label: 'Motif de sortie',
          hasFeedback: false,
          labelCol: { span: CSSUtils.defaultLabelCol },
          rules: validator && [
            {
              validator: async (_rule, value): Promise<void> => {
                return await validator(value);
              },
            },
          ],
        },
      }}
      optionProps={items.map(mapMotifSortieToSelectValue)}
      selectProps={{
        mode: mode,
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default InternalSelectMotifSortie;
