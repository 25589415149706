import React from 'react';

import { UniteFOResponseDto } from 'lib_api/lib/api/gen';

import { filterUndefinedOrNull } from '../index';
import { useRefUniteFoWithChildren } from 'hooks/referentiels/useRefUniteFoWithChildren';
import { useUserStore } from 'hooks/UserStoreContext';

interface UniteFO {
  value: string;
  displayValue: string;
}

/**
 * This method builds possible options for a select field using unite FO from referentiels.
 * displayValue: the abreviation (shortened label) of the selected unite FO.
 * value: the idCorrelation of the selected unite FO.
 */
function selectOptionsUniteFo(
  listUniteFo: Array<UniteFOResponseDto>,
): UniteFO[] {
  return filterUndefinedOrNull(
    listUniteFo
      .sort((a, b) => a.abreviation.localeCompare(b.abreviation))
      .map(uniteFo =>
        uniteFo.idCorrelation !== null
          ? {
              value: uniteFo.idCorrelation,
              displayValue: uniteFo.abreviation,
            }
          : null,
      ),
  );
}

/**
 * Hook to use previous method selectOptionsUniteFo
 */
export function useSelectOptionsUniteFo(): UniteFO[] {
  const idCorrelation = useUserStore().idCorrelationUniteFO;
  const listUniteFo =
    idCorrelation !== null
      ? useRefUniteFoWithChildren(idCorrelation)
      : undefined;
  return React.useMemo(
    () => (listUniteFo !== undefined ? selectOptionsUniteFo(listUniteFo) : []),
    [listUniteFo],
  );
}
