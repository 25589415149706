import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TableSort } from 'components/WrappedComponents/Table/types';

import PaginationProvider from 'hooks/PaginationStoreContext';
import FOFilterProvider from 'hooks/FOFilterStoreContext';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import Dashboard from 'pages/forces-de-l-ordre/Dashboard';
import PageDossier from 'pages/forces-de-l-ordre/PageDossier';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';
import { NOT_FOUND_PATH } from 'utils/enumData';
import SortProvider from 'hooks/SortStoreContext';
import { SortOptions } from 'hooks/dossiers/useSearchDossierSummaries';
import { useActionsRequises } from 'hooks/useActionsRequises';

interface TablePagination {
  page: number;
  pageSize: number;
}

const FoRouter = (): React.ReactElement => {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] = useState<Partial<DashboardForcesOrdreFilterForm>>(
    {},
  );
  const [sort, setSort] = useState<Partial<TableSort<SortOptions | undefined>>>(
    {
      IMMATRICULATION: true,
    },
  );

  const foActionsRequises = useActionsRequises();

  return (
    <SortProvider value={{ sort: sort, setSort: setSort }}>
      <PaginationProvider
        value={{ pagination: pagination, setPagination: setPagination }}
      >
        <FOFilterProvider
          value={{
            filter: filter,
            setFilter: setFilter,
            actionsRequises: foActionsRequises,
          }}
        >
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="dossier/:id" element={<PageDossier />} />
            <Route
              path="*"
              element={<Navigate replace to={NOT_FOUND_PATH} />}
            />
          </Routes>
        </FOFilterProvider>
      </PaginationProvider>
    </SortProvider>
  );
};

export default FoRouter;
