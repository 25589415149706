import React, { useCallback } from 'react';
import { Moment } from 'moment';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEqual } from 'lodash';

import { FilterButton, SearchBar } from 'components/WrappedComponents';
import {
  ActionsRequisesDtoActionsRequisesEnum,
  TraitementResponseDtoListStatutEnum,
} from 'lib_api/lib/api/gen';

import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';
import { useFOFilterContext } from 'hooks/FOFilterStoreContext';
import FilterFormForces from './FilterFormForces';
import { DashboardFOFilter } from '../../types';

interface FiltersProps {
  filter: Partial<DashboardFOFilter>;
  setFilter: (value: Partial<DashboardFOFilter>) => void;
}

function keepDossierSortis(
  statut: TraitementResponseDtoListStatutEnum[],
  sortieFilter: boolean,
  dateRangeSortieDefinitive: [Moment | undefined, Moment | undefined],
  motifDeSortieFilter: string[],
  includeDossiersAnonymises: boolean,
): boolean {
  // automatically check the "sorti" checkbox
  return (
    // if the statut SORTI is selected
    statut.includes(TraitementResponseDtoListStatutEnum.SORTI) ||
    // if the checkbox is checked
    (statut.length === 0 && sortieFilter) ||
    // if we search for a date sortie
    !!dateRangeSortieDefinitive[0] ||
    !!dateRangeSortieDefinitive[1] ||
    motifDeSortieFilter.length !== 0 ||
    // if we search for anonymized dossiers
    includeDossiersAnonymises
  );
}

function Filters({ filter, setFilter }: FiltersProps): React.ReactElement {
  const {
    search,
    includeDossiersSortis,
    includeOnlyActionsRequises,
    ...formFilter
  } = filter;
  const [, , foActionsRequises] = useFOFilterContext();

  const keepOnlyActionsRequises = useCallback(
    (actionsRequises?: ActionsRequisesDtoActionsRequisesEnum[]): boolean => {
      return isEqual(new Set(actionsRequises), new Set(foActionsRequises));
    },
    [foActionsRequises],
  );

  function setSearch(value: string) {
    setFilter({ ...filter, search: value });
  }

  function setFormFilter(value: Partial<DashboardAutoriteFourriereFilterForm>) {
    const {
      statut,
      dateRangeSortieDefinitive,
      actionsRequises,
      motifDeSortie,
      includeDossiersAnonymises,
    } = value;
    setFilter({
      ...filter,
      ...value,
      includeDossiersSortis: keepDossierSortis(
        statut ?? [],
        filter.includeDossiersSortis ?? false,
        dateRangeSortieDefinitive ?? [undefined, undefined],
        motifDeSortie ?? [],
        includeDossiersAnonymises ?? false,
      ),
      includeOnlyActionsRequises: keepOnlyActionsRequises(actionsRequises),
    });
  }

  function setIncludeDossiersSortis(event: CheckboxChangeEvent) {
    const { statut, dateRangeSortieDefinitive, motifDeSortie } = filter;
    const updatedStatut =
      statut?.filter(s => s !== TraitementResponseDtoListStatutEnum.SORTI) ||
      [];

    // Only add the SORTI statut filter if the statut filter is not empty
    if (event.target.checked && (statut?.length ?? -1) > 0) {
      updatedStatut.push(TraitementResponseDtoListStatutEnum.SORTI);
    }

    setFilter({
      ...filter,
      statut: updatedStatut,
      includeDossiersSortis: event.target.checked,
      motifDeSortie: event.target.checked ? motifDeSortie : [],
      // clear the date sortie filter when checkbox unchecked
      dateRangeSortieDefinitive: event.target.checked
        ? dateRangeSortieDefinitive
        : undefined,
      includeDossiersAnonymises: undefined,
    });
  }

  function setIncludeOnlyActionsRequises(event: CheckboxChangeEvent) {
    const isChecked = event.target.checked;
    setFilter({
      ...filter,
      actionsRequises: isChecked ? foActionsRequises : undefined,
      includeOnlyActionsRequises: isChecked,
    });
  }

  return (
    <div className="filters-container">
      <SearchBar
        value={filter.search}
        // Plates values are forced to uppercase
        setValue={search =>
          setFilter({ ...filter, search: search?.toUpperCase() })
        }
        antdProps={{
          placeholder: 'Rechercher une plaque...',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
      <FilterButton
        filter={formFilter}
        setFilter={setFormFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <FilterFormForces filter={data} setFilter={setData} />
        )}
      </FilterButton>
      <Checkbox
        checked={includeDossiersSortis}
        onChange={setIncludeDossiersSortis}
      >
        Afficher les véhicules sortis
      </Checkbox>
      <Checkbox
        checked={includeOnlyActionsRequises}
        onChange={setIncludeOnlyActionsRequises}
      >
        Afficher uniquement les actions requises
      </Checkbox>
    </div>
  );
}

export default Filters;
