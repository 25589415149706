import React, { useState } from 'react';
import {
  AdminFonctionnelControllerApi,
  DossierDtoAdminFonctionnel,
  UpdateClassementRequestDtoNewClassementEnum,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { UpdateClassementFormValues } from './types';

type SubmitFunction = {
  submit: (values: UpdateClassementFormValues) => void;
  inProgress: boolean;
};

function useSubmitUpdateClassement(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: UpdateClassementFormValues) => {
      setInProgress(true);
      return refController
        .updateClassementUsingPOST1(dossierId, {
          newClassement:
            values.newClassement ||
            UpdateClassementRequestDtoNewClassementEnum.DESTRUCTION,
          justification: values.comment ?? null,
        })
        .then(setDossier)
        .then(fetchEvents)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitUpdateClassement;
