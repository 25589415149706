import {
  FicheInfractionRequestDto,
  FicheInfractionViolationDtoFormFieldEnum,
  GardienFourriereControllerApi,
} from 'lib_api/lib/api/gen';

import { fetchFormViolations } from 'components/BaseForm/utils';
import { useApi } from 'hooks/ApiStoreContext';
import { qualiteAutoriteMapping } from 'utils/enumData';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { FicheInfractionFormValues } from '../types';

export function infractionFormValuesToRequestDto(
  formValues: FicheInfractionFormValues,
  idCorrelationFourriere: string,
): FicheInfractionRequestDto {
  return {
    idCorrelationAutoriteFourriere:
      formValues.idCorrelationAutoriteFourriere || null,
    idCorrelationFourriere: idCorrelationFourriere,
    communeEnlevement: formValues.communeEnlevement || null,
    dateConstatation:
      formValues.dateConstatation?.format(UNIX_TIMESTAMP_FORMAT) || null,
    dateRedaction:
      formValues.dateRedaction?.format(UNIX_TIMESTAMP_FORMAT) || null,
    lieuEnlevement: formValues.adresseEnlevement || null,
    lieuPrive: formValues.lieuPrive || false,
    motifMefId: formValues.idMotifMef || null, // Here we need to send the ref ID
    nuit: formValues.nuit || null,
    pluieOuNeige: formValues.pluieOuNeige || null,
    idCorrelationUniteFO: formValues.uniteFo?.option?.idCorrelation || null,
    emailUnite: formValues.emailUniteFo || null,
    autoriteQualifieePourLeverMesure: formValues.autoriteLeverMesure || null,
    nigend: formValues.nigend || null,
    nomAgent: formValues.nomAgent || null,
    nomAutorite: formValues.nomAutoritePrescriptrice || null,
    qualiteAutorite: formValues.qualiteAutorite
      ? qualiteAutoriteMapping[formValues.qualiteAutorite].requestValue
      : null,
  };
}

export function useValidateFicheInfractionField(
  idCorrelationFourriere: string,
): (
  field: FicheInfractionViolationDtoFormFieldEnum,
  formValues: FicheInfractionFormValues,
) => Promise<void> {
  const { GardienFourriereControllerApi: gfController } = useApi();
  if (!idCorrelationFourriere) {
    throw new Error('id correlation fourrière should be defined');
  }
  return (
    field: FicheInfractionViolationDtoFormFieldEnum,
    formValues: FicheInfractionFormValues,
  ) => {
    return validateFicheInfractionField(
      field,
      formValues,
      gfController,
      idCorrelationFourriere,
    );
  };
}

export async function validateFicheInfractionField(
  field: FicheInfractionViolationDtoFormFieldEnum,
  formValues: FicheInfractionFormValues,
  gfController: GardienFourriereControllerApi,
  idCorrelationFourriere: string,
): Promise<void> {
  return fetchFormViolations(
    () => {
      return gfController.validateFicheInfractionFieldWithoutDossierUsingPOST(
        field,
        infractionFormValuesToRequestDto(formValues, idCorrelationFourriere),
      );
    },
    violationResponse => {
      return violationResponse.ficheInfractionViolationDtoList;
    },
    violation => {
      return violation.message;
    },
  );
}
