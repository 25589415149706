import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Typography as AntdTypography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { WarningDto } from 'lib_api/lib/api/gen';
import { useFetchFluxVenteVehicules } from 'hooks/hermes/useFetchFluxVenteVehicules';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { TableWithoutSort } from 'components/WrappedComponents';
import { useSearchWarningFluxsDomaine } from '../../utils';
import { DATE_FORMAT } from 'utils/formats';
import './DashboardWarnings.less';

function DashboardWarnings(): React.ReactElement {
  const params = useParams<Record<string, string>>();
  const idFlux = params.id ?? '';

  const navigate = useNavigate();

  const [flux] = useFetchFluxVenteVehicules(idFlux);

  const { fetchData, pagination, setPagination, filter, setFilter } =
    useSearchWarningFluxsDomaine(idFlux);

  function buildDossierLink(data: WarningDto): React.ReactElement {
    return (
      <Link to={`/admin/dossier/${data.dossierId}`} className={'dossierLink'}>
        {data.dossierId}
      </Link>
    );
  }

  function buildColumns(): ColumnProps<WarningDto>[] {
    return [
      {
        title: 'Id dossier',
        buildCellContent: data => buildDossierLink(data),
      },
      {
        title: 'Id Hermès',
        buildCellContent: data => data.hermesId,
      },
      {
        title: `Message d'erreur`,
        buildCellContent: data => (
          <AntdTypography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
              symbol: 'voir plus',
            }}
            style={{ maxWidth: 1000, display: 'block', margin: 'auto' }}
          >
            {data.message}
          </AntdTypography.Paragraph>
        ),
      },
    ];
  }

  const columns: ColumnProps<WarningDto>[] = React.useMemo(
    () => buildColumns(),
    [],
  );

  return (
    <>
      <div className="dashboardWarningsDossier">
        <div className="headerWarnings">
          <Button
            className="returnButton"
            icon={<ArrowLeftOutlined />}
            onClick={(): void => navigate(-1)}
            type="default"
            shape="round"
            size="middle"
          >
            {'Retour'}
          </Button>
        </div>
        <h1>Hermès : Flux de récupération des statuts de vente des dossiers</h1>
        <h3>
          Détail sur le flux du {moment(flux?.dateAppel).format(DATE_FORMAT)}
        </h3>
        <div className="tableContainer">
          <TableWithoutSort
            columns={columns}
            fetchData={fetchData}
            buildItemKey={(item): string => item.id}
            pageSizeOptions={[10, 20, 50]}
            pagination={pagination}
            setPagination={setPagination}
            filter={filter}
            setFilter={setFilter}
          />
        </div>
      </div>
    </>
  );
}

export default DashboardWarnings;
