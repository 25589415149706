import React from 'react';
import { Alert as AntdAlert } from 'antd';

import { ConditionsReactivateFourriereDto } from 'lib_api/lib/api/gen/api';

import './ConditionReactivateFourriere.less';

interface ConditionReactivateFourriereProps {
  conditions: ConditionsReactivateFourriereDto | undefined;
}

function ConditionReactivateFourriere({
  conditions,
}: ConditionReactivateFourriereProps): React.ReactElement {
  return (
    <>
      {(conditions?.fourriereValid || conditions?.alreadyExists) && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.fourriereValid && (
                <p className="alertInvalid--txt">
                  La fourrière est déjà valide.
                </p>
              )}
              {conditions?.alreadyExists && (
                <p className="alertInvalid--txt">
                  Une fourrière active équivalente existe déjà dans le
                  référentiel.
                </p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateFourriere;
