import { CouleurDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';

export function fetchCouleur(
  refController: ReferentielControllerApi,
): Promise<CouleurDto[]> {
  return refController.getAllCouleurUsingGET().then(response => {
    return response.referenceDtoList.sort((a, b) =>
      a.couleur.localeCompare(b.couleur),
    );
  });
}
