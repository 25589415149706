import React from 'react';

import { Checkbox } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FilterDashboardFourriereProps } from '../../../types';

function CheckboxEmptyIdHermes({
  values,
}: FilterDashboardFourriereProps): React.ReactElement {
  return (
    <Checkbox
      wrapperProps={{
        values: values,
        fieldKey: 'emptyIdHermes',
        itemProps: {
          label: 'Id Hermes manquant',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.mediumWrapperCol },
        },
      }}
      checkboxProps={{}}
    />
  );
}

export default CheckboxEmptyIdHermes;
