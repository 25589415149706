import React from 'react';
import { useFormActionsContext } from 'components/WrappedComponents';
import { Button as AntdButton } from 'antd';

import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: Partial<DashboardAutoriteFourriereFilterForm>) => void;
}

export function FormAction({ setFilter }: FormActionProps): React.ReactElement {
  const formActions = useFormActionsContext();

  function resetFilter() {
    formActions.setFieldsValue({
      actionsRequises: undefined,
      dateRangeMiseEnFourriere: undefined,
      dateRangeBonEnlevement: undefined,
      dateRangeSortieDefinitive: undefined,
      classement: undefined,
      fourriere: undefined,
      commune: undefined,
      natureMiseEnFourriere: undefined,
      statut: undefined,
      motifDeSortie: undefined,
      typeVehicule: undefined,
      dateRangeOrdreDestruction: undefined,
      statutsTraitementFacturation: undefined,
      includeDossiersAnonymises: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type={'primary'}
        onClick={() => setFilter(formActions.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormAction;
