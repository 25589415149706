import React from 'react';

import {
  NotificationInfosViolationDtoFormFieldEnum,
  NotificationInfosRequestDto,
} from 'lib_api/lib/api/gen';
import { DatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import {
  NotificationInfosFieldsProps,
  NotificationInfos,
  validateNotificationInfosRule,
} from '../utils';
import { DATE_FORMAT } from 'utils/formats';
import { useApi } from 'hooks/ApiStoreContext';
import { CSSUtils } from 'utils/CSSUtils';
import { generateNotificationInfosRequestDto } from 'pages/forces-de-l-ordre/PageDossier/Actions/InfosNotification/utils';

function DatePickerEnvoi({
  values,
  dossierId,
  editable,
}: NotificationInfosFieldsProps): React.ReactElement {
  const gfController = useApi().ForceOrdreControllerApi;
  const key: keyof NotificationInfos = 'dateEnvoi';
  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: "Date d'envoi de la notification",
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                const notificationInfosRequestDto: NotificationInfosRequestDto =
                  generateNotificationInfosRequestDto({
                    ...values,
                    [key]: value,
                  });
                return await validateNotificationInfosRule(
                  dossierId,
                  gfController,
                  notificationInfosRequestDto,
                  NotificationInfosViolationDtoFormFieldEnum.DATE_ENVOI,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      datePickerProps={{
        disabled: !editable,
        style: { width: '100%' },
        placeholder: FormPlaceholders.Date,
        format: DATE_FORMAT,
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DatePickerEnvoi;
