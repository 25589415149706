import React from 'react';
import { Card, Skeleton } from 'antd';

import pdfIcon from 'assets/images/jpg/pdf-icon.jpg';
import { UserManual } from './types';
import useExportManuel from 'hooks/referentiels/useExportManuel';

interface UserManualCardsProps {
  manuals: UserManual[];
}

function UserManualCards({
  manuals,
}: UserManualCardsProps): React.ReactElement {
  return (
    <>
      <h1>Manuel utilisateur</h1>
      <>
        {manuals.length === 0 && (
          <div className="noAvailableContent">
            {`Aucun contenu n'est disponible.`}
          </div>
        )}
        {manuals.map((manual, i) => (
          <UserManualCard key={i} manual={manual} />
        ))}
      </>
    </>
  );
}

interface UserManualCardProps {
  manual: UserManual;
}

function UserManualCard({
  manual: { title, description, type, manuelType },
}: UserManualCardProps): React.ReactElement {
  const { download } = useExportManuel(type, manuelType);
  return (
    <Card className="card" hoverable onClick={download}>
      <Skeleton paragraph={{ rows: 1 }} loading={false} active>
        <Card.Meta
          avatar={
            <img src={pdfIcon} style={{ maxHeight: '4em' }} alt="Pdf icon" />
          }
          title={title}
          description={description}
          style={{ overflow: 'visible', whiteSpace: 'unset' }}
        />
      </Skeleton>
    </Card>
  );
}

export default UserManualCards;
