import React from 'react';
import { FilterButton } from 'components/WrappedComponents';
import FilterFormMarque from './FilterFormMarque';
import { FilterDashboardMarque } from '../DashboardHeader/types';

interface FilterProps {
  setFilter: (value: Partial<FilterDashboardMarque>) => void;
  filter: Partial<FilterDashboardMarque>;
}

export default function Filter({
  filter,
  setFilter,
}: FilterProps): React.ReactElement {
  return (
    <div className="dashboard-header-filter">
      <FilterButton
        filter={filter}
        setFilter={setFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <FilterFormMarque filter={data} setFilter={setData} />
        )}
      </FilterButton>
    </div>
  );
}
