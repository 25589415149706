import React from 'react';
import {
  FaqRequestDto,
  FaqViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { FaqFieldProps } from '../../types';
import SelectWithSearch from 'components/WrappedComponents/Form/FormFields/Select/SelectWithSearch';
import { useAsyncSearchDomain } from '../../utils';
import { CSSUtils } from 'utils/CSSUtils';

function InputDomain({
  values,
  validateField,
}: FaqFieldProps): React.ReactElement {
  const domainId: keyof FaqRequestDto = 'domainId';

  return (
    <SelectWithSearch
      label="Domaine"
      values={values}
      defaultValue={values.domainId ?? undefined}
      validateField={validateField}
      field={FaqViolationDtoFormFieldEnum.DOMAIN_ID}
      fieldsToValidateOnChange={[domainId]}
      formKey={domainId}
      useSearchEntities={useAsyncSearchDomain}
      labelCol={{ span: CSSUtils.LlargeWrapperCol }}
      wrapperCol={{ span: CSSUtils.defaultLabelCol }}
    />
  );
}

export default InputDomain;
