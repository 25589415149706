import React, { useEffect } from 'react';

import { UserDtoProfileEnum, VideoTutorielDto } from 'lib_api/lib/api/gen';
import { useUserStore } from 'hooks/UserStoreContext';
import { useGetTutorielsVideos } from 'hooks/referentiels/useRefTutorielsVideos';
import { useGetManuals } from './UserManualCard/utils';
import UserManualCards from './UserManualCard/UserManualCard';
import VideoCards from './VideoCard/VideoCard';
import PdfTemplateCard from './PdfTemplateCard/PdfTemplateCard';
import { useDisplayInformationMessageContext } from 'hooks/DisplayMessageContext';
import './Tutorial.less';

function Tutorial(): React.ReactElement {
  const { profile } = useUserStore();
  const manuals = useGetManuals(profile);
  const tutorielsVideos = useGetTutorielsVideos();
  const videoIds =
    tutorielsVideos?.referenceDtoList?.map(
      (video: VideoTutorielDto) => video.videoId,
    ) ?? [];
  const { setMessagesShown } = useDisplayInformationMessageContext();
  const isForceDeLOrdre = profile === UserDtoProfileEnum.FORCE_DE_L_ORDRE;

  // do not display the information messages
  useEffect(() => {
    setMessagesShown(false);
    return () => setMessagesShown(true);
  }, [setMessagesShown]);

  return (
    <div className="Tutorial-page">
      <div className="Tutorial-container">
        <UserManualCards manuals={manuals} />
        {isForceDeLOrdre && <PdfTemplateCard />}
        <VideoCards videoIds={videoIds} />
      </div>
    </div>
  );
}

export default Tutorial;
