import React from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';

import FieldWrapper from '../FieldWrapper';
import { WrappedProps } from '../types';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  textAreaProps: AntdTextAreaProps;
}

function TextArea<T>({
  wrapperProps,
  textAreaProps,
}: Props<T>): React.ReactElement {
  const textArea = React.useMemo(() => {
    return <AntdInput.TextArea {...textAreaProps} />;
  }, [textAreaProps]);

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const value = formActions.getFieldValue(fieldKey);

  return (
    <FieldWrapper
      {...wrapperProps}
      WrappedInput={textArea}
      ConsultationElement={value}
    />
  );
}

export default TextArea;
