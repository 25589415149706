import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import {
  BonEnlevementGardienViolationDtoFormFieldEnum,
  DossierResponseDto,
} from 'lib_api/lib/api/gen';

import BaseForm from 'components/BaseForm/BaseForm';
import { fetchCentreVHU } from 'search/searchCentreVHU';
import { BonEnlevementGardienFormValues } from 'types/BonEnlevementGardien';
import {
  useValidateUpdateBonEnlevementGardien,
  useSubmitUpdateBonEnlevementGardien,
  buildFormValues,
} from './utils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { useApi } from 'hooks/ApiStoreContext';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import AutocompleteCommune from 'components/BaseForm/Autocomplete/AutocompleteCommune';

import './BonEnlevementGardienForm.less';

const motifAbsenceCarteGrise = [
  'Vol',
  'Perte',
  'Détérioration du titre',
  'Non remise par le propriétaire',
];

interface BonEnlevementGardienFormProps {
  dossier: DossierResponseDto;
  setDossier: (dossierResponseDto: DossierResponseDto) => void;
  close: () => void;
}

const BonEnlevementGardienForm: React.FC<BonEnlevementGardienFormProps> = ({
  dossier,
  setDossier,
  close,
}: BonEnlevementGardienFormProps): React.ReactElement => {
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const { ReferentielControllerApi: refController } = useApi();
  const initials: BonEnlevementGardienFormValues = buildFormValues(dossier);

  const { validate } = useValidateUpdateBonEnlevementGardien(dossierId);
  const { inProgress, submit } = useSubmitUpdateBonEnlevementGardien(
    dossierId,
    setDossier,
    close,
  );

  return (
    <BaseForm
      initialValues={initials}
      onSubmit={submit}
      isSubmitting={inProgress}
      onChange={(_changed, all) => {
        if (!all.isCarteGriseRetiree) {
          all.motifAbsenceImmatriculation = undefined;
        }
        return all;
      }}
      validateField={validate}
      inputs={[
        {
          field:
            BonEnlevementGardienViolationDtoFormFieldEnum.FLAG_CARTE_GRISE_RETIREE,
          name: 'isCarteGriseRetiree',
          label: "Certificat d'immatriculation absent",
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          field:
            BonEnlevementGardienViolationDtoFormFieldEnum.MOTIF_ABSENCE_IMMATRICULATION,
          name: 'motifAbsenceImmatriculation',
          label: "Motif d'absence certificat d'immatriculation",
          disabled: values => {
            return !values.isCarteGriseRetiree;
          },
          render: ({ disabled }) => {
            return (
              <BaseSelect
                options={motifAbsenceCarteGrise}
                getOptionLabel={option => option}
                getOptionValue={option => option}
                disabled={disabled}
              />
            );
          },
        },
        {
          field:
            BonEnlevementGardienViolationDtoFormFieldEnum.ID_CORRELATION_CENTRE_VHU,
          name: 'idCorrelationCentreVhu',
          label: 'Centre VHU',
          render: () => {
            return (
              <AsyncSelect
                showSearch
                fetchOptions={() => {
                  return fetchCentreVHU(refController);
                }}
                getOptionLabel={centre => centre.raisonSociale}
                getOptionValue={centre => centre.idCorrelation}
                dependencies={[
                  {
                    name: 'raisonSociale',
                    getValue: centre => centre.raisonSociale,
                  },
                  {
                    name: 'numeroSiren',
                    getValue: centre => centre.siren,
                  },
                  {
                    name: 'adresseVhu',
                    getValue: centre => centre.adresse ?? undefined,
                  },
                  {
                    name: 'complementAdresse',
                    getValue: centre => centre.complementAdresse ?? undefined,
                  },
                  {
                    name: 'communeVhu',
                    getValue: centre => centre.commune ?? undefined,
                  },
                  {
                    name: 'codePostalVhu',
                    getValue: centre => centre.codePostal ?? undefined,
                  },
                  {
                    name: 'numeroAgrement',
                    getValue: centre => centre.numeroAgrementVHU ?? undefined,
                  },
                  {
                    name: 'prefectureAgrement',
                    getValue: centre => centre.prefecture ?? undefined,
                  },
                ]}
              />
            );
          },
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.RAISON_SOCIALE,
          name: 'raisonSociale',
          label: 'Raison sociale',
          required: true,
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.NUMERO_SIREN,
          name: 'numeroSiren',
          label: 'Numéro SIREN',
          required: true,
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.ADRESSE,
          name: 'adresseVhu',
          label: 'Adresse',
          required: true,
          render: ({ name }) => {
            return (
              <AutocompleteAdresse
                name={name}
                communeField="communeVhu"
                codePostalField="codePostalVhu"
              />
            );
          },
        },
        {
          field:
            BonEnlevementGardienViolationDtoFormFieldEnum.COMPLEMENT_ADRESSE,
          name: 'complementAdresse',
          label: "Complément d'adresse",
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.COMMUNE,
          name: 'communeVhu',
          label: 'Commune',
          required: true,
          render: ({ name }) => {
            return (
              <AutocompleteCommune
                name={name}
                codePostalField="codePostalVhu"
              />
            );
          },
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.CODE_POSTAL,
          name: 'codePostalVhu',
          label: 'Code postal',
          required: true,
          render: ({ name }) => {
            return <AutocompleteCommune name={name} />;
          },
        },
        {
          field: BonEnlevementGardienViolationDtoFormFieldEnum.NUMERO_AGREMENT,
          name: 'numeroAgrement',
          label: "Numéro d'agrement",
          required: true,
        },
        {
          field:
            BonEnlevementGardienViolationDtoFormFieldEnum.PREFECTURE_AGREMENT,
          name: 'prefectureAgrement',
          label: "Préfecture ayant délivré l'agrément",
          required: true,
        },
      ]}
    />
  );
};

export default BonEnlevementGardienForm;
