import React from 'react';

import ResponsiveContainer from 'components/ResponsiveContainer';
import { MainInfoHeader } from './FicheAdminMain/MainInfoHeader/MainInfoHeader';
import MainInfoBody from './FicheAdminMain/MainInfoBody/MainInfoBody';
import { FicheAdminEvents } from './FicheAdminEvents/FicheAdminEvents';

import './FicheAdmin.less';

export const FicheAdmin = (): React.ReactElement => {
  return (
    <ResponsiveContainer className="FicheAdmin">
      <div className="MainInfos Section">
        <MainInfoHeader />
        <MainInfoBody />
      </div>
      <div className="ListEvents Section">
        <FicheAdminEvents />
      </div>
    </ResponsiveContainer>
  );
};
