import React from 'react';
import { useUserStore } from 'hooks/UserStoreContext';
import { NOT_FOUND_PATH } from 'utils/enumData';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { Navigate } from 'react-router-dom';
import PageProfilGf from 'pages/gardien-fourriere/PageProfil';
import PageProfilAf from 'pages/autorite-fourriere/PageProfil';
import PageProfilFo from 'pages/forces-de-l-ordre/PageProfil';

const ProfileWrapper = (): React.ReactElement => {
  const { profile } = useUserStore();

  if (profile === UserDtoProfileEnum.GARDIEN_FOURRIERE) {
    return <PageProfilGf />;
  }

  if (profile === UserDtoProfileEnum.AUTORITE_FOURRIERE) {
    return <PageProfilAf />;
  }

  if (profile === UserDtoProfileEnum.FORCE_DE_L_ORDRE) {
    return <PageProfilFo />;
  }

  return <Navigate to={NOT_FOUND_PATH} />;
};

export default ProfileWrapper;
