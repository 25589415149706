import { useCallback, useEffect, useState } from 'react';

import {
  AutocompleteUniteFOResultDto,
  AutocompleteUniteFOResultDtoFieldEnum,
  ReferentielSearchResultDtoUniteFOAdminResponseDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import {
  searchUniteFOUsingSpecs,
  searchUniteFO,
  fetchUniteFo,
  FilterSearchUniteFo,
} from 'search/searchUniteFo';
import { UniteFoSort } from 'enums/referentiels/UniteFo';
import { useApi } from 'hooks/ApiStoreContext';

export function useAdminSearchUniteFo(): (
  filters: FilterSearchUniteFo,
  sorts: TableSort<UniteFoSort>,
  pagination: TablePagination,
) => Promise<ReferentielSearchResultDtoUniteFOAdminResponseDto> {
  const controller = useApi().ReferentielControllerApi;

  return (filters, sorts, pagination) => {
    return searchUniteFOUsingSpecs(controller, filters, sorts, pagination);
  };
}

export function useSearchUniteFo(): (filters: {
  field: AutocompleteUniteFOResultDtoFieldEnum;
  search: string;
}) => Promise<AutocompleteUniteFOResultDto> {
  const controller = useApi().ReferentielControllerApi;

  return filters => {
    return searchUniteFO(controller, filters.field, filters.search);
  };
}

export const useFetchUniteFo = (
  idCorrelationUniteFo: string | null,
): [UniteFOResponseDto | null, () => Promise<void>, boolean, boolean] => {
  const { ReferentielControllerApi: refController } = useApi();
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const [uniteFoDto, setUniteFoDto] = useState<UniteFOResponseDto | null>(null);

  const fetch = useCallback(async () => {
    if (!idCorrelationUniteFo) {
      return;
    }

    setInProgress(true);
    setErrorOccured(false);
    try {
      const response = await fetchUniteFo(refController, idCorrelationUniteFo);
      setUniteFoDto(response);
    } catch {
      setErrorOccured(true);
    }
    setInProgress(false);
  }, [idCorrelationUniteFo]);

  useEffect(() => {
    void fetch();
  }, [fetch]);

  return [uniteFoDto, fetch, inProgress, errorOccured];
};
