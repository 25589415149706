import React from 'react';
import { DataFieldDisplayProps } from './types';
import { FieldDisplay } from './FieldDisplay';

export function DataFieldDisplay<T>({
  label,
  value,
  formatter,
  labelClass = 'label',
}: DataFieldDisplayProps<T>): React.ReactElement {
  const formated =
    value === undefined || value === null ? null : formatter(value);
  return (
    <FieldDisplay label={label} value={formated} labelClass={labelClass} />
  );
}
