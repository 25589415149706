import React from 'react';
import { Link } from 'react-router-dom';
import { Divider as AntdDivider, Typography as AntdTypography } from 'antd';
import { LogoMinistere } from 'components/Logo';
import classNames from 'classnames';

import { useUserStore } from 'hooks/UserStoreContext';
import { useRefFooterLink } from 'hooks/referentiels/useRefFooterLink';
import { useVersion } from 'hooks/utils/useVersion';
import { profileEnumMapping } from 'utils/enumData';
import { FRONTEND_VERSION } from './constants';

import './Footer.less';

const { Text } = AntdTypography;

interface FooterProps {
  className?: string;
}

const Footer = ({ className }: FooterProps): React.ReactElement => {
  const user = useUserStore();
  if (user.profile === null) {
    throw new Error('Authenticated user should be mapped to a profile');
  }

  const backendVersion = useVersion();

  const { faqPath, tutorielPath, mentionsLegalesPath } =
    profileEnumMapping[user.profile];
  const links = useRefFooterLink();

  const linkComponents: React.ReactElement[] = [];
  if (faqPath) {
    linkComponents.push(
      <Link to={faqPath} className="coloredLink" target="_blank">
        FAQ
      </Link>,
    );
  }
  if (tutorielPath) {
    linkComponents.push(
      <Link to={tutorielPath} className="coloredLink" target="_blank">
        Manuels et Tutoriels
      </Link>,
    );
  }
  if (mentionsLegalesPath) {
    linkComponents.push(
      <Link to={mentionsLegalesPath} className="defaultLink">
        Mentions Légales
      </Link>,
    );
  }
  if (links?.referenceDtoList) {
    linkComponents.push(
      ...links.referenceDtoList.map(({ link, text }, index) => (
        <a
          key={index}
          href={'https://' + link}
          target={'_blank'}
          rel="noopener noreferrer"
          className="defaultLink"
        >
          {text ?? link}
        </a>
      )),
    );
  }

  return (
    <footer className={classNames('footer', className)}>
      <div className={'url-footer'}>
        <ul>
          {linkComponents.map((link, index) => (
            <li key={index}>
              {link}
              {index < linkComponents.length - 1 && (
                <AntdDivider type={'vertical'} />
              )}
            </li>
          ))}
        </ul>
      </div>

      <div className={'mint-footer'}>
        <LogoMinistere />
        <AntdDivider type={'vertical'} />
        <Text>Ministère de l&lsquo;intérieur</Text>
        <div className={'version'}>
          <Text className={'versionText'}>{`v${FRONTEND_VERSION} (F)`}</Text>
          <Text className={'versionText'}>
            {backendVersion ? `v${backendVersion} (B)` : '- (B)'}
          </Text>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
