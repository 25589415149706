import React, { useCallback, useEffect, useState } from 'react';
import { Label } from 'components/WrappedComponents';

import Spinner from 'components/WrappedComponents/Spinner';
import {
  EditableProfilFormContainer,
  ProfilFormContainer,
} from 'components/ProfilFormContainer';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { useRequiredUserActionContext } from 'hooks/RequiredUserActionContext';
import { ProfileConfirmation } from 'components/ProfileConfirmation/ProfileConfirmation';

import { FicheProfilAutoriteFourriere } from './types';
import {
  ficheProfilFromDto,
  validateAndSubmitAutoriteFourriere,
} from './utils';
import InputCodePostal from './Fields/InputCodePostal';
import InputCedex from './Fields/InputCedex';
import InputCommune from './Fields/InputCommune';
import InputComplementAdresse from './Fields/InputComplementAdresse';
import InputIntituleComplet from './Fields/InputIntituleComplet';
import InputNumeroVoie from './Fields/InputNumeroVoie';
import InputVoie from './Fields/InputVoie';
import SelectTypeAutoriteFourriere from './Fields/SelectTypeAutoriteFourriere';

import './PageProfil.less';
import { useConfirmProfile } from 'hooks/utils/useConfirmProfil';

function PageProfil(): React.ReactElement {
  const user = useUserStore();
  const { ReferentielControllerApi, AutoriteFourriereControllerApi } = useApi();
  const [data, setData] = useState<FicheProfilAutoriteFourriere | null>(null);
  const { requiredUserActions, updateRequiredUserActions } =
    useRequiredUserActionContext();
  const confirmProfile = useConfirmProfile(user.username);

  useEffect(() => {
    let cancel = false;
    if (user.idCorrelationAutoriteFourriere !== null) {
      ReferentielControllerApi.getValidAutoriteFourriereByIdCorrelationUsingGET(
        user.idCorrelationAutoriteFourriere,
      )
        .then(af => {
          if (cancel) {
            return;
          }
          setData(ficheProfilFromDto(af));
        })
        .catch(() => {
          if (cancel) {
            return;
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [ReferentielControllerApi, user]);

  const onSubmit = useCallback(
    async (values: FicheProfilAutoriteFourriere) => {
      if (user.idCorrelationAutoriteFourriere !== null) {
        const updatedAF = await validateAndSubmitAutoriteFourriere(
          AutoriteFourriereControllerApi,
          user.idCorrelationAutoriteFourriere,
          values,
        );
        // automatically confirm referentials edited by non admin users
        await confirmProfile({
          idAutoriteFourriereToConfirm: updatedAF?.id,
          mustConfirmAccount: requiredUserActions?.mustConfirmAccount,
        });
        updateRequiredUserActions();
      }
    },
    [
      AutoriteFourriereControllerApi,
      confirmProfile,
      updateRequiredUserActions,
      requiredUserActions,
      user,
    ],
  );

  if (data === null) {
    return <Spinner />;
  }

  return (
    <>
      <ProfilFormContainer title="Mes informations">
        <div className="profil-form-af-read-only-fields-container">
          <Label label="Nom" value={user.lastName || ''} />
          <Label label="Prénom" value={user.firstName || ''} />
        </div>
      </ProfilFormContainer>
      <EditableProfilFormContainer
        title="Ma structure"
        idForm="profil-form-autorite-fourriere"
        initialValue={data}
        submit={onSubmit}
      >
        {(values, isEdit) => (
          <>
            <SelectTypeAutoriteFourriere values={values} isEditable={false} />
            <InputIntituleComplet values={values} isEditable={false} />
            <InputNumeroVoie values={values} isEditable={isEdit} />
            <InputVoie values={values} isEditable={isEdit} />
            <InputComplementAdresse values={values} isEditable={isEdit} />
            <InputCodePostal values={values} isEditable={isEdit} />
            <InputCommune values={values} isEditable={isEdit} />
            <InputCedex values={values} isEditable={isEdit} />
          </>
        )}
      </EditableProfilFormContainer>

      {(requiredUserActions?.idAutoriteFourriereToConfirm ||
        requiredUserActions?.mustConfirmAccount) && (
        <ProfileConfirmation
          username={user.username}
          requiredActions={requiredUserActions}
          updateRequiredActions={updateRequiredUserActions}
        />
      )}
    </>
  );
}

export default PageProfil;
