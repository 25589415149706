import React from 'react';

import './ProfilFormContainer.less';

interface Props {
  title: string;
  button?: React.ReactElement;
  children: React.ReactNode;
}

function ProfilFormContainer({
  title,
  button,
  children,
}: Props): React.ReactElement {
  return (
    <div className="profile-form-container">
      <div className="profile-form-title">
        {title}
        {button}
      </div>
      {children}
    </div>
  );
}

export default ProfilFormContainer;
