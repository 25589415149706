import React from 'react';
import { AutoCompleteProps as AntdAutoCompleteProps } from 'antd';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import {
  AdresseApiGouvOption,
  useSearchAdresseApiGouv,
} from 'hooks/search/useSearchAdresseApiGouv';
import BaseAutocomplete, { AutocompleteDependencies } from './BaseAutocomplete';

interface AutocompleteAdresseProps<FormValues>
  extends Omit<
    AntdAutoCompleteProps<string, AdresseApiGouvOption>,
    'options' | 'name'
  > {
  codePostalField?: keyof FormValues & AntdNamePath & string;
  communeField?: keyof FormValues & AntdNamePath & string;
  name: keyof FormValues & AntdNamePath & string;
}

function buildValue(option: AdresseApiGouvOption): string {
  return `${option.adresse} ${
    option?.codepostal || option?.commune ? '-' : ''
  } ${option?.codepostal} ${option?.commune}`.trim();
}

export default function AutocompleteAdresse<FormValues>({
  codePostalField,
  communeField,
  name,
  onChange,
  ...props
}: AutocompleteAdresseProps<FormValues>): React.ReactElement {
  const dependents: AutocompleteDependencies<
    FormValues,
    AdresseApiGouvOption
  >[] = [];

  if (codePostalField) {
    dependents.push({
      name: codePostalField,
      getValue: option => option.codepostal,
    });
  }

  if (communeField) {
    dependents.push({
      name: communeField,
      getValue: option => option.commune,
    });
  }

  return (
    <BaseAutocomplete<FormValues, AdresseApiGouvOption>
      {...props}
      optionsFetcher={useSearchAdresseApiGouv}
      buildValue={buildValue}
      getCurrentFieldValue={option => option.adresse}
      name={name}
      dependencies={dependents}
    />
  );
}
