import React from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';
import { FilterFilled } from '@ant-design/icons';

interface Props<T> {
  setFilter: (value: Partial<T>) => void;
  filter: Partial<T>;
  textInactiveFilter: string;
  textActiveFilter: string;
  children: (
    setFilter: (value: Partial<T>) => void,
    filter: Partial<T>,
  ) => React.ReactElement;
}

// Check whether or not the value is not undefined or an array contains at least a non undefined value.
// It does not takes in charge deeps object
function isContainingValue(value: unknown): boolean {
  if (Array.isArray(value)) {
    return value.some(isContainingValue);
  }
  return value !== undefined && value !== null;
}

function isObjectContainingAValue(testObject: object): boolean {
  return Object.values(testObject).some(isContainingValue);
}

export default function FilterButton<T>({
  setFilter,
  filter,
  textActiveFilter,
  textInactiveFilter,
  children,
}: Props<T>): React.ReactElement {
  const [showFilter, setShowFilter] = React.useState(false);

  function onClickButton() {
    setShowFilter(true);
  }

  function onFilterSend(value: Partial<T>) {
    setShowFilter(false);
    setFilter(value);
  }

  const text = isObjectContainingAValue(filter)
    ? textActiveFilter
    : textInactiveFilter;

  return (
    <>
      <AntdModal
        visible={showFilter}
        footer={null}
        onCancel={() => setShowFilter(false)}
        closable={false}
        destroyOnClose={true}
      >
        {children(onFilterSend, filter)}
      </AntdModal>

      <AntdButton
        shape={'round'}
        onClick={onClickButton}
        icon={<FilterFilled />}
      >
        {text}
      </AntdButton>
    </>
  );
}
