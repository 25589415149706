import React from 'react';

import { ReferenceDtoFourriereDto } from 'lib_api/lib/api/gen';

import { filterUndefinedOrNull } from '../index';
import { useRefFourriere } from 'hooks/referentiels/useRefFourriere';
import { useRefFourriereAutoriteFourriere } from 'hooks/referentiels/useRefFourriereAutoriteFourriere';

interface Fourriere {
  value: string;
  displayValue: string;
}

/**
 * This method builds possible options for a select field using fourriere referential.
 * Those options will be displayed as "CODE POSTAL - Raison Sociale".
 * Value is the idCorrelation of the selected fourriere.
 *
 * Ex: 01000 - FOURRIERE MUNICIPALE
 */
function selectOptionsFourriere(refs: ReferenceDtoFourriereDto): Fourriere[] {
  return filterUndefinedOrNull(
    refs.referenceDtoList
      .sort((a, b) => a.codePostal.localeCompare(b.codePostal))
      .map(fourriere =>
        fourriere.idCorrelation !== null
          ? {
              value: fourriere.idCorrelation,
              displayValue:
                ('00000' + fourriere.codePostal).substr(-5, 5) + // format the String so that it has leading 0
                ' - ' +
                fourriere.raisonSociale,
            }
          : null,
      ),
  );
}

/**
 * Hook to use previous method selectOptionsFourriere
 */
export function useSelectOptionsFourriere(
  idCorrelationAutoriteFourriere?: string | null,
): Fourriere[] {
  const fourriere = idCorrelationAutoriteFourriere
    ? useRefFourriereAutoriteFourriere(idCorrelationAutoriteFourriere)
    : useRefFourriere();
  return React.useMemo(
    () => (fourriere !== undefined ? selectOptionsFourriere(fourriere) : []),
    [fourriere],
  );
}
