import { useCallback, useEffect, useState } from 'react';

import { useApi } from 'hooks/ApiStoreContext';
import { DownloadableFileMetaData } from 'hooks/DownloadableFilesContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

import {
  sortAndConvertFileDtoToFileData,
  getNewlyGeneratedFilesNumber,
} from './utils';

interface DownloadableReturnType {
  downloadPopUpVisible: boolean;
  files: DownloadableFileMetaData[];
  newAvailableFilesNumber: number;
  showDownloadablePopUp: (show: boolean) => void;
  resetNewDownloadNumber: () => void;
  getDownloadableFiles: () => void;
}

interface FilesInfo {
  files: DownloadableFileMetaData[];
  newAvailableFilesNumber: number;
}

const defaultFilesInfo: FilesInfo = {
  files: [],
  newAvailableFilesNumber: 0,
};

const useDownloadableFiles = (): DownloadableReturnType => {
  const [showDownloadablePopUp, setShowDownloadablePopUp] = useState(false);
  const [filesInfo, setFilesInfo] = useState<FilesInfo>(defaultFilesInfo);
  const exportControllerApi = useApi().ExportControllerApi;

  const getDownloadableFiles = useCallback(() => {
    exportControllerApi
      .searchExportFilesUsingGET()
      .then(results => {
        setFilesInfo(oldFilesInfo => {
          const newFiles = sortAndConvertFileDtoToFileData(results);
          const newAvailableFilesNumber =
            oldFilesInfo.newAvailableFilesNumber +
            getNewlyGeneratedFilesNumber(oldFilesInfo.files, newFiles);

          return {
            files: newFiles,
            newAvailableFilesNumber,
          };
        });
      })
      .catch(async err => {
        await backAlertMessage(err, false);
        setFilesInfo(defaultFilesInfo);
      });
  }, [exportControllerApi]);

  const resetNewDownloadNumber = useCallback(
    () =>
      setFilesInfo(prevState => {
        return { ...prevState, newAvailableFilesNumber: 0 };
      }),
    [],
  );

  useEffect(() => {
    getDownloadableFiles();
  }, [exportControllerApi, getDownloadableFiles]);

  useEffect(() => {
    if (showDownloadablePopUp) {
      setFilesInfo(prevState => {
        return {
          ...prevState,
          newAvailableFilesNumber: 0,
        };
      });
    }
  }, [filesInfo.newAvailableFilesNumber, showDownloadablePopUp]);

  return {
    downloadPopUpVisible: showDownloadablePopUp,
    files: filesInfo.files,
    newAvailableFilesNumber: filesInfo.newAvailableFilesNumber,
    showDownloadablePopUp: setShowDownloadablePopUp,
    resetNewDownloadNumber,
    getDownloadableFiles,
  };
};

export default useDownloadableFiles;
