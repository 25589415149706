import { createContext, useContext, Dispatch, SetStateAction } from 'react';

type TablePagination = {
  page: number;
  pageSize: number;
};

interface PaginationContextContent<TablePagination> {
  pagination: TablePagination;
  setPagination: Dispatch<SetStateAction<TablePagination>>;
}

const PaginationContext =
  createContext<PaginationContextContent<TablePagination> | null>(null);

export const usePaginationContext = (): [
  TablePagination,
  Dispatch<SetStateAction<TablePagination>>,
] => {
  const context = useContext(PaginationContext);
  if (context === null) {
    throw new Error('usePaginationContext called outside Context.');
  }

  return [context.pagination, context.setPagination];
};

const PaginationProvider = PaginationContext.Provider;

export default PaginationProvider;
