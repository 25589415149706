import React from 'react';

import { ReferentielSearchResultDtoFourriereDto } from 'lib_api/lib/api/gen';

import useAsyncSearch from 'hooks/search/async/useAsyncSearch';
import useSearchFourriere from 'hooks/search/useSearchFourriere';
import { SearchResult } from 'types/searchResult';

function useAsyncSearchFourriere(idCorrelation?: string): SearchResult {
  const mapResult = (result: ReferentielSearchResultDtoFourriereDto) => {
    return result.results.referenceDtoList.map(fourriere => {
      return {
        value: fourriere.idCorrelation,
        displayValue: `${fourriere.codePostal} - ${fourriere.raisonSociale}`,
      };
    });
  };
  const updateFilters = (query?: string) => {
    return { raisonSociale: query };
  };
  const defaultFilter = { idCorrelation };

  return useAsyncSearch(
    useSearchFourriere,
    mapResult,
    updateFilters,
    defaultFilter,
    {},
    { page: 1, pageSize: 20 },
  );
}

export default useAsyncSearchFourriere;
