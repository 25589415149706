import React, { ReactNode } from 'react';
import {
  Alert as AntdAlert,
  Modal as AntdModal,
  Typography as AntdTypography,
} from 'antd';
import moment from 'moment';
import {
  EtatConventionnementResponseDto,
  UniteFOAdminResponseDto,
  UniteFOAdminResponseDtoRpsiStatusEnum,
} from 'lib_api/lib/api/gen';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { useFetchEtatConventionnement } from 'hooks/dossierAdmin/useFetchEtatConventionnement';
import { useRefUniteFo } from 'hooks/referentiels/useRefUniteFo';

import { DATE_FORMAT } from 'utils/formats';
import './ModalDetails.less';

interface ModalDetailsProps {
  unite: UniteFOAdminResponseDto;
  visible: boolean;
  close: () => void;
}

function generateEtatConventionnement(
  unite: UniteFOAdminResponseDto,
  etat: EtatConventionnementResponseDto | null,
  errorOccured: boolean,
  fetchInProgress: boolean,
) {
  if (unite.type === 'PN' || unite.type === 'GN') {
    return 'Actif (les PN/GN sont systématiquement conventionnées)';
  }
  if (fetchInProgress) {
    return 'En attente de SwaPart...';
  }
  if (errorOccured) {
    return 'Une erreur est survenue, réessayez plus tard...';
  }
  if (!etat?.conventionnement?.dateDebut || !etat?.conventionnement?.dateFin) {
    return 'Inactif';
  }
  const newStart = moment(etat?.conventionnement?.dateDebut).format(
    DATE_FORMAT,
  );
  const newEnd = moment(etat?.conventionnement?.dateFin).format(DATE_FORMAT);
  if (etat?.active) {
    return `Actif (validité du ${newStart} au ${newEnd})`;
  }
  return `Inactif (validité du ${newStart} au ${newEnd})`;
}

type RpsiMessageMapping = {
  [key in UniteFOAdminResponseDtoRpsiStatusEnum]: ReactNode;
};

const rpsiMessageMapping: RpsiMessageMapping = {
  MISE_A_JOUR_ERREUR_VALIDATION: (
    <div className="detailRpsiStatus">
      <AntdTypography>
        Etat : Erreur lors de la mise à jour de l&apos;unité
      </AntdTypography>
      <AntdTypography>
        Cette unité a été modifiée dans le référentiel RPSI mais sa modification
        n&apos;a pas été prise en compte dans le référentiel SIF. Il se peut que
        les nouvelles données RPSI de cette unité ne respectent pas les
        contraintes de format imposées par le référentiel SIF.
      </AntdTypography>
    </div>
  ),
  NOUVELLE_UNITE: (
    <div className="detailRpsiStatus">
      <AntdTypography>Etat : Unité nouvellement créée</AntdTypography>
      <AntdTypography>
        Cette unité vient d&apos;être ajoutée au référentiel.
      </AntdTypography>
      <AntdTypography>
        Vous pouvez lui assigner une unité parent.
      </AntdTypography>
    </div>
  ),
  A_SUPPRIMER_DOSSIER_ATTACHE: (
    <div className="detailRpsiStatus">
      <AntdTypography>Etat : Invalidation bloquée</AntdTypography>
      <AntdTypography>
        Cette unité n&apos;existe plus dans le référentiel RPSI mais son
        invalidation est bloquée dans le référentiel SIF car des dossiers y sont
        rattachés.
      </AntdTypography>
      <AntdTypography>
        Vous devez migrer les dossiers rattachés à cette unité pour permettre
        son invalidation.
      </AntdTypography>
    </div>
  ),
  A_SUPPRIMER_UNITE_PARENT: (
    <div className="detailRpsiStatus">
      <AntdTypography>Etat : Invalidation bloquée</AntdTypography>
      <AntdTypography>
        Cette unité n&apos;existe plus dans le référentiel RPSI mais son
        invalidation est bloquée dans le référentiel SIF car elle est unité
        parent.
      </AntdTypography>
      <AntdTypography>
        Vous devez modifier l&apos;unité parent des unités rattachées à cette
        unité pour permettre son invalidation.
      </AntdTypography>
    </div>
  ),
  A_SUPPRIMER_UNITE_PARENT_ET_DOSSIER_ATTACHE: (
    <div className="detailRpsiStatus">
      <AntdTypography>Etat : Invalidation bloquée</AntdTypography>
      <AntdTypography>
        Cette unité n&apos;existe plus dans le référentiel RPSI mais son
        invalidation est bloquée dans le référentiel SIF car des dossiers y sont
        rattachés et elle est unité parent.
      </AntdTypography>
      <AntdTypography>
        Vous devez migrer les dossiers rattachés à cette unité et modifier
        l&apos;unité parent des unités rattachées à cette unité pour permettre
        son invalidation.
      </AntdTypography>
    </div>
  ),
};

function ModalDetails({
  unite,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  const { etat, errorOccured, fetchInProgress } = useFetchEtatConventionnement(
    unite.codeInsee,
  );
  const uniteParent = unite.idCorrelationParent
    ? useRefUniteFo(unite.idCorrelationParent)
    : undefined;
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails modalDetailsUniteFO"
    >
      {unite.rpsiStatus && (
        <AntdAlert message={rpsiMessageMapping[unite.rpsiStatus]} showIcon />
      )}
      <div className="modalContent">
        <FieldDisplay label="Id de corrélation" value={unite.idCorrelation} />
        <FieldDisplay label="Type d'unité" value={unite.type} />
        <FieldDisplay label="Code" value={unite.code} />
        <FieldDisplay
          label="Abréviation de l'unité"
          value={unite.abreviation}
        />
        <FieldDisplay label="Libellé de l'unité" value={unite.unite} />
        <FieldDisplay
          label="Unité parent"
          value={uniteParent?.abreviation ?? null}
        />
        <FieldDisplay label="Adresse de l'unité" value={unite.adresse} />
        <FieldDisplay label="Code postal" value={unite.codePostal} />
        <FieldDisplay label="Ville" value={unite.ville} />
        <FieldDisplay label="Code INSEE" value={unite.codeInsee} />
        <FieldDisplay label="CT de rattachement" value={unite.ctRattachement} />
        <FieldDisplay
          label="Etat de conventionnement"
          value={generateEtatConventionnement(
            unite,
            etat,
            errorOccured,
            fetchInProgress,
          )}
        />
        <FieldDisplay label="Courriel" value={unite.email} />
        <FieldDisplay label="Téléphone" value={unite.telephone} />
      </div>
    </AntdModal>
  );
}

export default ModalDetails;
