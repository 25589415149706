import React, { useCallback } from 'react';

import {
  ConditionsReactivateMotifSortieDto,
  MotifSortieDto,
  MotifSortieRequestDto,
  MotifSortieViolationDtoFormFieldEnum,
  ReferentielSearchResultDtoMotifSortieDto,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useBoolean } from 'utils/genericUtils';
import { MotifSortieFormValues } from 'types/referentiels/MotifSortie';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
} from 'components/WrappedComponents/Table/types';

import DashboardHeader from './DashboardHeader/DashboardHeader';
import {
  FilterDashboardMotifSortie,
  SubmitMotifSortie,
  ValidateMotifSortie,
} from './types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  MotifSortieDto,
  FilterDashboardMotifSortie
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData?.total ?? 0}
    filter={filter}
    setFilter={setFilter}
  />
);

export function useSearchMotifSortie(
  filter: FilterDashboardMotifSortie,
): [
  () => Promise<ReferentielSearchResultDtoMotifSortieDto>,
  (
    result: ReferentielSearchResultDtoMotifSortieDto,
  ) => FetchDataResult<MotifSortieDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  return [
    () => {
      return controller.searchMotifSortieUsingSpecsUsingGET({
        filters: {
          ...filter,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

function buildRequestDto(data: MotifSortieFormValues): MotifSortieRequestDto {
  return {
    motif: data.motif?.trim() || null,
    commentaire: data.commentaire || null,
    delaiEffacement: data.delaiEffacement || null,
  };
}

export function useSubmitCreateMotifSortie(
  refreshDashboard: () => void,
  close: () => void,
): SubmitMotifSortie {
  const progress = useBoolean(false);

  const controller = useApi().AdminFonctionnelControllerApi;

  const submit = useCallback(
    (values: MotifSortieFormValues) => {
      const requestDto: MotifSortieRequestDto = buildRequestDto(values);

      controller
        .createMotifSortieUsingPOST(requestDto)
        .then(close)
        .then(refreshDashboard)
        .finally(progress.setIsFalse);
    },
    [controller, progress.setIsFalse, refreshDashboard, close],
  );

  return {
    inProgress: progress.value,
    submit,
  };
}

export function useValidateCreateMotifSortie(): ValidateMotifSortie {
  const controller = useApi().AdminFonctionnelControllerApi;
  const validate = useCallback(
    async (
      field: MotifSortieViolationDtoFormFieldEnum,
      values: MotifSortieFormValues,
    ) => {
      const requestDto: MotifSortieRequestDto = buildRequestDto(values);
      const response: ReferentielViolationsDto =
        await controller.validateCreateMotifSortieFieldUsingPOST(
          field,
          requestDto,
        );

      const violations = response.motifSortieViolationsDto ?? [];
      return violations.length === 0
        ? Promise.resolve()
        : Promise.reject(violations[0]);
    },
    [controller],
  );

  return { validate };
}

export function useSubmitUpdateMotifSortie(
  idCorrelation: string,
  refreshDashboard: () => void,
  close: () => void,
): SubmitMotifSortie {
  const progress = useBoolean(false);

  const controller = useApi().AdminFonctionnelControllerApi;

  const submit = useCallback(
    (values: MotifSortieFormValues) => {
      const requestDto: MotifSortieRequestDto = buildRequestDto(values);

      controller
        .updateMotifSortieUsingPUT(idCorrelation, requestDto)
        .then(close)
        .then(refreshDashboard)
        .finally(progress.setIsFalse);
    },
    [controller, progress.setIsFalse, refreshDashboard, close, idCorrelation],
  );

  return {
    inProgress: progress.value,
    submit,
  };
}

export function useValidateUpdateMotifSortie(
  idCorrelation: string,
): ValidateMotifSortie {
  const controller = useApi().AdminFonctionnelControllerApi;
  const validate = useCallback(
    async (
      field: MotifSortieViolationDtoFormFieldEnum,
      values: MotifSortieFormValues,
    ) => {
      const requestDto: MotifSortieRequestDto = buildRequestDto(values);
      const response: ReferentielViolationsDto =
        await controller.validateUpdateMotifSortieFieldUsingPUT(
          field,
          idCorrelation,
          requestDto,
        );

      const violations = response.motifSortieViolationsDto ?? [];
      return violations.length === 0
        ? Promise.resolve()
        : Promise.reject(violations[0]);
    },
    [controller, idCorrelation],
  );

  return { validate };
}

export function useChangeValidityMotifSortie(): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateMotifSortieDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateMotifSortieUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateMotifSortieUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateMotifSortieDto> => {
      return controller.conditionReactivationMotifSortieUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ];
}
