import React from 'react';
import { RangeDatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { CSSUtils } from 'utils/CSSUtils';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';

interface DateBonEnlevementProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

function DateBonEnlevement({
  values,
}: DateBonEnlevementProps): React.ReactElement {
  return (
    <RangeDatePicker
      wrapperProps={{
        values: values,
        fieldKey: 'dateRangeBonEnlevement',
        itemProps: {
          label: "Date du bon d'enlèvement",
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      dateRangeProps={{
        placeholder: ['De', 'À'],
        allowEmpty: [true, true],
        style: { width: '100%' },
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DateBonEnlevement;
