import React, { useEffect, useState } from 'react';

import {
  AutoriteFourriereControllerApi,
  CoordonneesProprietaireViolationDtoFormFieldEnum,
  DossierResponseDto,
  ForceOrdreControllerApi,
} from 'lib_api/lib/api/gen';
import {
  convertCoordonneesProprietaireToCoordonneeProprietaireRequestDto,
  convertSivInfoToCoordonneesProprietaire,
  useSubmitCoordonneesProprietaires,
  validateCoordonneesProprietaireField,
} from './utils';
import { useFetchDonneesCoordonneesProprietaireSiv } from 'hooks/dossiers/useFetchDonneesCoordonneesProprietaireSiv';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import ImpossibiliteNotifierSourcesDisplay from './fields/ImpossibiliteNotifierSourcesDisplay';
import { CoordonneesProprietaire } from './types';
import AsyncComponent from '../../AsyncComponent/AsyncComponent';
import BaseForm from '../../BaseForm/BaseForm';
import AutocompleteCommune from '../../BaseForm/Autocomplete/AutocompleteCommune';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

import './CoordonneesProprietaireForm.less';

interface CoordonneesProprietaireFormProps {
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi;
  close: () => void;
  editable: boolean;
}

const CoordonneesProprietaireForm = ({
  controller,
  close,
  editable,
}: CoordonneesProprietaireFormProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const [coordonneesSiv, fetch, fetchCoordonneesInProgress, fetchErrorOccured] =
    useFetchDonneesCoordonneesProprietaireSiv(controller, dossierId);

  const [submit, submitCoordonneesInProgress] =
    useSubmitCoordonneesProprietaires(
      controller,
      dossierId,
      (updatedDossier: DossierResponseDto) => {
        setDossier(updatedDossier);
        return fetch();
      },
      close,
    );

  const [values, setValues] = useState<CoordonneesProprietaire>(
    convertSivInfoToCoordonneesProprietaire(coordonneesSiv),
  );

  useEffect(() => {
    setValues(convertSivInfoToCoordonneesProprietaire(coordonneesSiv));
  }, [coordonneesSiv]);

  return (
    <AsyncComponent
      reload={fetch}
      inProgress={fetchCoordonneesInProgress && !submitCoordonneesInProgress}
      errorOccured={fetchErrorOccured}
      render={() => {
        if (coordonneesSiv) {
          return (
            <>
              <h3 className={'mainTitle'}>Coordonnées du propriétaire</h3>
              <ImpossibiliteNotifierSourcesDisplay
                values={values}
                dossierId={dossierId}
                controller={controller}
              />
              {editable ? (
                <BaseForm
                  initialValues={convertSivInfoToCoordonneesProprietaire(
                    coordonneesSiv,
                  )}
                  isSubmitting={submitCoordonneesInProgress}
                  validateField={async (field, formValues) => {
                    return validateCoordonneesProprietaireField(
                      dossierId,
                      controller,
                      convertCoordonneesProprietaireToCoordonneeProprietaireRequestDto(
                        formValues,
                      ),
                      field,
                    );
                  }}
                  onSubmit={submit}
                  onCancel={close}
                  onChange={(_changedValues, allValues) => {
                    setValues(allValues);
                    return allValues;
                  }}
                  inputs={[
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.PRENOM,
                      name: 'prenom',
                      label: 'Prénom',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.NOM,
                      name: 'nom',
                      label: 'Nom',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.RAISON_SOCIALE,
                      name: 'raisonSociale',
                      label: 'Raison Sociale',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.POINT_REMISE,
                      name: 'pointRemise',
                      label: 'Point de remise',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.NUMERO,
                      name: 'numero',
                      label: 'Numéro de voie',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.EXTENSION,
                      name: 'extension',
                      label: 'Extension',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.TYPEVOIE,
                      name: 'typeVoie',
                      label: 'Type de voie',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.LIBELLE_VOIE,
                      name: 'libelleVoie',
                      label: 'Libellé de voie',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.COMPLEMENT_ADRESSE,
                      name: 'complementAdresse',
                      label: "Complément d'adresse",
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.LIEUDIT,
                      name: 'lieuDit',
                      label: 'Lieu dit',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.BOITE_POSTALE,
                      name: 'boitePostale',
                      label: 'Boite postale',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.CODE_POSTAL,
                      name: 'codePostal',
                      label: 'Code postal',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.LIBELLE_COMMUNE,
                      name: 'libelleCommune',
                      label: 'Commune',
                      render: ({ name }) => {
                        return (
                          <AutocompleteCommune
                            codePostalField="codePostal"
                            disabled={!editable}
                            name={name}
                          />
                        );
                      },
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.CODE_CEDEX,
                      name: 'codeCedex',
                      label: 'Code Cedex',
                    },
                    {
                      field:
                        CoordonneesProprietaireViolationDtoFormFieldEnum.LIBELLE_CEDEX,
                      name: 'libelleCedex',
                      label: 'Libellé cedex',
                    },
                  ]}
                  renderButtonAction={(Submit, Cancel) => {
                    return (
                      <div className="UpdateCoordonneesProprietaire">
                        <div className="UpdateAction">{Cancel}</div>
                        <div className="UpdateAction">{Submit}</div>
                      </div>
                    );
                  }}
                />
              ) : (
                <div className="fields">
                  <FieldDisplay label="Prénom" value={coordonneesSiv?.prenom} />
                  <FieldDisplay label="Nom" value={coordonneesSiv?.nom} />
                  <FieldDisplay
                    label="Raison sociale"
                    value={coordonneesSiv?.raisonSociale}
                  />
                  <FieldDisplay
                    label="Point de remise"
                    value={coordonneesSiv?.pointRemise}
                  />
                  <FieldDisplay
                    label="Numéro de voie"
                    value={coordonneesSiv?.numero}
                  />
                  <FieldDisplay
                    label="Extension"
                    value={coordonneesSiv?.extension}
                  />
                  <FieldDisplay
                    label="Type de voie"
                    value={coordonneesSiv?.typeVoie}
                  />
                  <FieldDisplay
                    label="Libellé de voie"
                    value={coordonneesSiv?.libelleVoie}
                  />
                  <FieldDisplay
                    label="Complément d'adresse"
                    value={coordonneesSiv?.complementAdresse}
                  />
                  <FieldDisplay
                    label="Lieu dit"
                    value={coordonneesSiv?.lieuDit}
                  />
                  <FieldDisplay
                    label="Boite postale"
                    value={coordonneesSiv?.boitePostale}
                  />
                  <FieldDisplay
                    label="Code postal"
                    value={coordonneesSiv?.codePostal}
                  />
                  <FieldDisplay
                    label="Commune"
                    value={coordonneesSiv?.libelleCommune}
                  />
                  <FieldDisplay
                    label="Code Cedex"
                    value={coordonneesSiv?.codeCedex}
                  />
                  <FieldDisplay
                    label="Libellé Cedex"
                    value={coordonneesSiv?.libelleCedex}
                  />
                </div>
              )}
            </>
          );
        }
        return <></>;
      }}
    />
  );
};

export default CoordonneesProprietaireForm;
