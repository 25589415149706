import { useMemo } from 'react';
import moment from 'moment';

import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';

import { FixDataNotificationManuelleFormValues } from './types';

function useInitialValues(
  dossierAdmin: ExportDonneesDossierAdminFonctionnelDto | null,
): FixDataNotificationManuelleFormValues {
  const dateEnvoi =
    dossierAdmin?.dossier.body?.traitement?.notificationManuelle?.dateEnvoi;
  const dateSignature =
    dossierAdmin?.dossier.body?.traitement?.notificationManuelle?.dateRetour;
  const etatDistribution =
    dossierAdmin?.dossier.body?.traitement?.notificationManuelle
      ?.etatDistribution;

  return useMemo(() => {
    return {
      dateEnvoi: dateEnvoi ? moment(dateEnvoi) : undefined,
      dateSignature: dateSignature ? moment(dateSignature) : undefined,
      etatDistribution: etatDistribution ?? undefined,
      justification: undefined,
    };
  }, [dateEnvoi, dateSignature, etatDistribution]);
}

export default useInitialValues;
