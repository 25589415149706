import {
  GenreSimplifieDto,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

export function fetchGenresimplifie(
  refController: ReferentielControllerApi,
): Promise<GenreSimplifieDto[]> {
  return refController.getValidGenresSimplifiesUsingGET().then(response => {
    return response.referenceDtoList;
  });
}
