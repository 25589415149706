import React, { useEffect } from 'react';

import { ActionsRequisesDtoActionsRequisesEnum } from 'lib_api/lib/api/gen';

import { TableSort } from 'components/WrappedComponents/Table/types';
import { useFetchCountDossier } from 'hooks/dossiers/useFetchCountDossier';
import { useUserStore } from 'hooks/UserStoreContext';
import Filters from './Filters';
import DownloadButton from './DownloadButton';
import { DashboardFOFilter } from '../types';

import { libelleVehiculeAcontroler } from './utils';

import './DashboardHeader.less';

interface DashboardHeaderProps<SortType extends string | undefined> {
  totalResult: number;
  filter: Partial<DashboardFOFilter>;
  setFilter: (value: Partial<DashboardFOFilter>) => void;
  sort: TableSort<SortType>;
}

function DashboardHeader<SortType extends string | undefined>({
  totalResult,
  filter,
  setFilter,
  sort,
}: DashboardHeaderProps<SortType>): React.ReactElement {
  const user = useUserStore();
  const { object: count, fetch } = useFetchCountDossier({
    idUniteFO: user.idCorrelationUniteFO ?? undefined,
    communeEnlevementList: filter.commune,
    statutDossierEnumList: filter.statut,
    classementList: filter.classement,
    idFourriereList: filter.fourriere,
    idNatureBrancheList: filter.natureMiseEnFourriere,
    dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
    dateRangeBonEnlevement: filter.dateRangeBonEnlevement,
    dateRangeSortieDefinitive: filter.dateRangeSortieDefinitive,
    dateRangeOrdreDestruction: filter.dateRangeOrdreDestruction,
    numeroImmatriculation: filter.search,
    includeDossiersSortis: filter.includeDossiersSortis,
    idMotifSortieList: filter.motifDeSortie ?? [],
    actionsRequises: [
      ActionsRequisesDtoActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE,
    ],
    includeDossiersAnonymises: filter.includeDossiersAnonymises,
    listUniteFo: filter.listUniteFo,
  });

  useEffect(fetch, [filter]);

  const countString = `${totalResult} ${
    totalResult > 1 ? 'véhicules trouvés' : 'véhicule trouvé'
  }`;

  const countAControlerString = libelleVehiculeAcontroler(filter, count);
  const separator = ', ';

  return (
    <div>
      <div className="dashboard-header-fields">
        <Filters filter={filter} setFilter={setFilter} />
        <div className="dashboard-header-actions">
          <DownloadButton filters={filter} sort={sort} />
        </div>
      </div>
      <p className="dashboard-number-vehicules">
        {countString}
        {countAControlerString && (
          <>
            {separator}
            <span
              className="vehicules-a-controler"
              onClick={() => {
                setFilter({
                  ...filter,
                  actionsRequises: [
                    ActionsRequisesDtoActionsRequisesEnum.CONTROLER_DONNEES_VEHICULE,
                  ],
                });
              }}
            >
              {countAControlerString}
            </span>
          </>
        )}
      </p>
    </div>
  );
}

export default DashboardHeader;
