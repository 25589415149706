import React from 'react';

import {
  CreateUserRequestDtoProfileEnum,
  CreateViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CreationCompteFormValues } from 'types/Compte/CreationCompte';
import { useApi } from 'hooks/ApiStoreContext';
import {
  generateCreateUserRequestDtoFromFormData,
  validateCreateUserRequest,
} from '../utils';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';

function SelectProfile({
  values,
}: FieldProps<CreationCompteFormValues>): React.ReactElement {
  const cuController = useApi().AdminFonctionnelControllerApi;
  const key: keyof CreationCompteFormValues = 'profile';

  // list of options of select
  const selectItems = [
    {
      value: CreateUserRequestDtoProfileEnum.GARDIEN_FOURRIERE,
      displayValue: 'Gardien de fourrière',
    },
    {
      value: CreateUserRequestDtoProfileEnum.AUTORITE_FOURRIERE,
      displayValue: 'Collectivité territoriale',
    },
    {
      value: CreateUserRequestDtoProfileEnum.FORCE_DE_L_ORDRE,
      displayValue: 'Police municipale',
    },
    {
      value: CreateUserRequestDtoProfileEnum.ADMIN_FONCTIONNEL,
      displayValue: 'Administrateur fonctionnel',
    },
    {
      value: CreateUserRequestDtoProfileEnum.DSR_BLR,
      displayValue: 'DSR BLR',
    },
  ];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Profil à créer',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateCreateUserRequestDtoFromFormData({
                  ...values,
                  profile: value,
                });

                return await validateCreateUserRequest(
                  cuController,
                  requestObject,
                  CreateViolationDtoFormFieldEnum.PROFILE,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        allowClear: true,
      }}
    />
  );
}

export default SelectProfile;
