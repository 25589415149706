import React from 'react';
import { Col as AntdCol, Row as AntdRow } from 'antd';
import { DossierResponseDto, UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import {
  getDisplayableStatutFacturation,
  isStatutFacturationVisibleForProfile,
} from './utils';

interface TraitementFacturationProps {
  dossier: DossierResponseDto;
  profile: UserDtoProfileEnum;
}

export function TraitementFacturation({
  dossier,
  profile,
}: TraitementFacturationProps): React.ReactElement | null {
  const libelleStatutFacturation = getDisplayableStatutFacturation(
    dossier?.body?.sortieVehicule?.statutFacturation,
  );

  // This component must not be visible if there is no libelle for the statut facturation
  // or if the user cannot see its statut
  if (
    !libelleStatutFacturation ||
    !isStatutFacturationVisibleForProfile(profile)
  ) {
    return null;
  }

  return (
    <AntdRow gutter={10}>
      <AntdCol className="label-col" span={8}>
        <span className="label">Etat de facturation :</span>
      </AntdCol>
      <AntdCol className="field-col">
        <span className="label">{libelleStatutFacturation}</span>
      </AntdCol>
    </AntdRow>
  );
}
