import {
  DetailedInformationMessageDto,
  InformationMessageDto,
} from 'lib_api/lib/api/gen';
import { createContext, useContext, Dispatch, SetStateAction } from 'react';

export interface InformationMessages {
  [key: string]: InformationMessageDto;
}

export interface DisplayedInformationMessage {
  messages: InformationMessages;
  addMessage: (message: DetailedInformationMessageDto) => void;
  removeMessage: (id: string) => void;
  setMessagesShown: Dispatch<SetStateAction<boolean>>;
}

export const DisplayedMessageContext =
  createContext<DisplayedInformationMessage | null>(null);

export const useDisplayInformationMessageContext =
  (): DisplayedInformationMessage => {
    const context = useContext(DisplayedMessageContext);
    if (context === null) {
      throw new Error(
        'useDisplayInformationMessageContext called outside Context.',
      );
    }

    return context;
  };

const DisplayedInformationMessageProvider = DisplayedMessageContext.Provider;

export default DisplayedInformationMessageProvider;
