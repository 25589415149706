import { useCallback, useEffect, useState } from 'react';
import {
  AutoriteFourriereControllerApi,
  CoordonneesProprietaireResponseDto,
  ForceOrdreControllerApi,
} from 'lib_api/lib/api/gen';

type SivIdentite = CoordonneesProprietaireResponseDto | null;

export const useFetchDonneesCoordonneesProprietaireSiv = (
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  id: string,
): [SivIdentite, () => Promise<void>, boolean, boolean] => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const fetch = useCallback(async () => {
    setInProgress(true);
    setErrorOccured(false);
    try {
      const response = await getCoordonneesProprietaire(controller, id);
      setCoordonneesProprietaire(response);
    } catch {
      setErrorOccured(true);
    }
    setInProgress(false);
  }, [id, controller]);

  const [coordonneesProprietaire, setCoordonneesProprietaire] =
    useState<SivIdentite>(null);

  useEffect(() => {
    void fetch();
  }, [fetch]);

  return [coordonneesProprietaire, fetch, inProgress, errorOccured];
};

const getCoordonneesProprietaire = async (
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  id: string,
): Promise<CoordonneesProprietaireResponseDto> => {
  if (controller instanceof AutoriteFourriereControllerApi) {
    return await controller.retrieveCoordonneesProprietaireUsingGET1(id);
  }

  return await controller.retrieveCoordonneesProprietaireUsingGET(id);
};
