import { useCallback, useEffect, useState } from 'react';
import { InformationsTechniquesResponseDto } from 'lib_api/lib/api/gen';
import { useApi } from '../ApiStoreContext';

type InformationsTechniques = InformationsTechniquesResponseDto | null;

export const useFetchDonneesTechniquesSiv = (
  id: string,
): [InformationsTechniques, () => Promise<void>, boolean, boolean] => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);

  const autoriteFourriereControllerApi =
    useApi().AutoriteFourriereControllerApi;

  const fetch = useCallback(async () => {
    setInProgress(true);
    setErrorOccured(false);
    try {
      const response =
        await autoriteFourriereControllerApi.retrieveInformationsTechniquesUsingGET(
          id,
        );
      setInformationsTechniques(response);
    } catch {
      setErrorOccured(true);
    }
    setInProgress(false);
  }, [id]);

  const [informationsTechniques, setInformationsTechniques] =
    useState<InformationsTechniques>(null);

  useEffect(() => {
    void fetch();
  }, [fetch]);

  return [informationsTechniques, fetch, inProgress, errorOccured];
};
