import React from 'react';
import { Button as AntdButton } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import PageSizeChanger from '../PageSizeChanger';
import { TablePaginationWithoutTotal } from '../../types';

import './PaginationWithoutTotal.less';

export interface PaginationWithoutTotalProps {
  pagination: {
    paginated: TablePaginationWithoutTotal;
    setPagination: (newPagination: TablePaginationWithoutTotal) => void;
  };
  hasNextPage: boolean;
  pageSizeOptions?: number[];
  disabled?: boolean;
  isLoadingData: boolean;
}

/**
 * This custom Pagination component is defined to replace the default one used in Table
 * component. We have therefore a better control over this component behaviour (better
 * style control + we can specify its size instead of relying on table size which would
 * require to load all the table components at once).
 *
 * @param pagination - getter and setter for pagination state:
 *        - page: page currently active (/!\ Antd Pagination component start at index 1)
 *        - pageSize: number of items deplayed in a page (used to define the total number
 *        of pages)
 * @param total - total number of items (used to defined total number of pages)
 *        (ex: pageSize = 10 ; total = 121 => 13 pages)
 * @param pageSizeOptions - when defined, add a PageSizeChanger component with those
 *        options
 * @param disabled
 * @param isLoadingData - Value is true if Table is loading data results
 * @param antdPaginationProps - to use other props defined by Antd library (/!\ may
 *        interfere with props already defined by this component)
 */
function PaginationWithoutTotal({
  pagination,
  pageSizeOptions,
  hasNextPage,
  disabled,
  isLoadingData,
}: PaginationWithoutTotalProps): React.ReactElement {
  const { paginated, setPagination } = pagination;

  return (
    <>
      <div className="pagination-without-total">
        <div className="page-changer-container">
          <AntdButton
            disabled={pagination.paginated.page === 1 || isLoadingData}
            type="text"
            onClick={() => {
              setPagination({
                page: pagination.paginated.page - 1,
                pageSize: pagination.paginated.pageSize,
                hasNextPage: true,
              });
            }}
            icon={<LeftOutlined />}
          />
          <span className="page-number">{pagination.paginated.page}</span>
          <AntdButton
            disabled={!hasNextPage || isLoadingData}
            type="text"
            onClick={() => {
              setPagination({
                page: pagination.paginated.page + 1,
                pageSize: pagination.paginated.pageSize,
                hasNextPage: false,
              });
            }}
            icon={<RightOutlined />}
          />
        </div>
        {pageSizeOptions !== undefined && (
          <div className="page-size-changer">
            <PageSizeChanger
              pageSize={paginated.pageSize}
              setPageSize={(newPageSize: number): void =>
                // Change page size, return to first page
                setPagination({
                  page: 1,
                  pageSize: newPageSize,
                  hasNextPage: false,
                })
              }
              options={pageSizeOptions}
              suffix="/ page"
              disabled={disabled}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default PaginationWithoutTotal;
