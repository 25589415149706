import { useCallback } from 'react';
import { DonneesAControlerResponseDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useFetchObject, UseFetchObjectResult } from '../utils/useFetchObject';

export const useFetchDonneesAControler = (
  id: string,
): UseFetchObjectResult<DonneesAControlerResponseDto | null> => {
  const controller = useApi().ForceOrdreControllerApi;
  const fetcher = useCallback(() => {
    return controller.getDonneesAControlerUsingGET(id);
  }, [id]);
  return useFetchObject(fetcher);
};
