import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchDossier } from 'hooks/dossiers/useFetchDossier';
import { DossierProvider } from 'hooks/dossiers/DossierContext';
import { FicheDescriptiveDisplay } from 'components/FicheDescriptiveDisplay/FicheDescriptiveDisplay';
import ActionSuiviVehicule from './Actions/ActionsSuiviVehicule';
import Spinner from 'components/WrappedComponents/Spinner';
import { PageDossierParam } from 'types/pageDossier';

const Details: React.FC = () => {
  const { id } = useParams<PageDossierParam>();

  if (id === undefined || id === null || typeof id !== 'string') {
    throw new Error("L'Id du dossier devrait être défini");
  }

  const [dossier, setDossier] = useFetchDossier(id);

  if (dossier === null) {
    return <Spinner />;
  }

  return (
    <DossierProvider value={{ dossier: dossier, setDossier: setDossier }}>
      <FicheDescriptiveDisplay>
        <ActionSuiviVehicule />
      </FicheDescriptiveDisplay>
    </DossierProvider>
  );
};

export default Details;
