import React, { useCallback, useState } from 'react';

import {
  ConditionsReactivateUniteFoDto,
  ReferentielUsesDto,
  AdminFonctionnelControllerApi,
  UniteFoRequestDto,
  UniteFoViolationDtoFormFieldEnum,
  ReferentielSearchResultDtoUniteFOAdminResponseDto,
  UniteFOAdminResponseDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { UniteFoSort } from 'enums/referentiels/UniteFo';
import {
  FilterDashboardUniteFo,
  UniteFoFormValues,
} from 'types/referentiels/UniteFo';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  UniteFOAdminResponseDto,
  FilterDashboardUniteFo
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
  />
);

/**
 * Hook sent to generic search hook to send search request and convert returned result to readable data
 */
export function useSearchUniteFO(
  filters: FilterDashboardUniteFo,
  sorts: TableSort<UniteFoSort>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoUniteFOAdminResponseDto>,
  (
    result: ReferentielSearchResultDtoUniteFOAdminResponseDto,
  ) => FetchDataResult<UniteFOAdminResponseDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () => {
      return controller.searchUniteFoUsingSpecsUsingGET({
        filters: {
          ...filters,
          page: pagination.page,
          pageSize: pagination.pageSize,
          code: filters.code?.option?.code,
          unite: filters.unite?.option?.unite,
          abreviation: filters.abreviation?.option?.abreviation,
        },
        sorts: {
          abreviation: sorts?.ABREVIATION,
          code: sorts?.CODE,
          codeInsee: sorts?.CODE_INSEE,
          codePostal: sorts?.CODE_POSTAL,
          type: sorts?.TYPE,
          ville: sorts?.VILLE,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityUniteFo(
  controller: AdminFonctionnelControllerApi,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateUniteFoDto>,
  (idCorrelation: string) => Promise<ReferentielUsesDto>,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateUniteFoDto> => {
      return controller.conditionReactivationUsingGET(idCorrelation);
    },
    [controller],
  );

  const getUsesRequest = useCallback(
    (idCorrelation: string): Promise<ReferentielUsesDto> => {
      return controller.searchUsesUniteFoUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ];
}

export const buildFormValues = (
  unite: UniteFOResponseDto,
): UniteFoFormValues => {
  return {
    type: unite.type,
    code: unite.code,
    unite: unite.unite,
    abreviation: unite.abreviation,
    adresse: unite.adresse ?? undefined,
    codePostal: unite.codePostal ?? undefined,
    ville: unite.ville ?? undefined,
    email: unite.email ?? undefined,
    telephone: unite.telephone ?? undefined,
    codeInsee: unite.codeInsee ?? undefined,
    ctRattachement: unite.ctRattachement ?? undefined,
    uniteFoParent: undefined,
  };
};

export const buildRequestDto = (
  formValues: UniteFoFormValues,
): UniteFoRequestDto => {
  return {
    type: formValues.type ?? null,
    code: formValues.code ?? null,
    unite: formValues.unite ?? null,
    abreviation: formValues.abreviation ?? null,
    adresse: formValues.adresse ?? null,
    codePostal: formValues.codePostal ?? null,
    ville: formValues.ville ?? null,
    email: formValues.email ?? null,
    telephone: formValues.telephone ?? null,
    codeInsee: formValues.codeInsee ?? null,
    ctRattachement: formValues.ctRattachement ?? null,
    idCorrelationUniteFoParent:
      formValues.uniteFoParent?.option?.idCorrelation ?? null,
  };
};

export function useValidateCreateUniteFo(): ValidateFunction<
  UniteFoFormValues,
  UniteFoViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: UniteFoViolationDtoFormFieldEnum,
      values: UniteFoFormValues,
    ) => {
      const response = await controller.validateCreateUniteFoFieldUsingPOST(
        field,
        buildRequestDto(values),
      );

      const violations = response.uniteFoViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitCreateUniteFo(
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<UniteFoFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: UniteFoFormValues) => {
      setInProgress(true);
      return controller
        .createUniteFoUsingPOST(buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}

export function useValidateUpdateUniteFo(
  uniteId: string,
): ValidateFunction<UniteFoFormValues, UniteFoViolationDtoFormFieldEnum> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: UniteFoViolationDtoFormFieldEnum,
      values: UniteFoFormValues,
    ) => {
      const response = await controller.validateUpdateUniteFoFieldUsingPOST(
        field,
        uniteId,
        buildRequestDto(values),
      );

      const violations = response.uniteFoViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitUpdateUniteFo(
  uniteId: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<UniteFoFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: UniteFoFormValues) => {
      setInProgress(true);
      return controller
        .updateUniteFoUsingPUT(uniteId, buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}

export function getTypeUniteMessage(type: string | undefined): string {
  return type === 'PM'
    ? "La création manuelle de ce type d'unité ne sera pas impactée par les mises à jour du référentiel RPSI"
    : 'La création manuelle d’une unité de ce type n’est pas viable sur le SI Fourrière. L’unité créée sera écrasée lors de la prochaine mise à jour du référentiel RPSI (J+1 de votre saisie)';
}
