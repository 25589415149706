import React from 'react';
import { RangeDatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { CSSUtils } from 'utils/CSSUtils';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';

interface DateFinProcedureProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

function DateFinProcedure({
  values,
}: DateFinProcedureProps): React.ReactElement {
  return (
    <RangeDatePicker
      wrapperProps={{
        values: values,
        fieldKey: 'dateRangeSortieDefinitive',
        itemProps: {
          label: 'Date de la sortie définitive',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      dateRangeProps={{
        placeholder: ['De', 'À'],
        allowEmpty: [true, true],
        style: { width: '100%' },
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DateFinProcedure;
