import React from 'react';
import { NumberInput } from 'components/WrappedComponents';
import { ProfilFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriereBlocFourriere,
  InputPropFourriere,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { validateFicheProfilGf } from '../../utils';

function InputParkSize({
  values,
  isEditable,
  generateRequestDto,
}: InputPropFourriere): React.ReactElement {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof FicheProfilFourriereBlocFourriere = 'parkSize';
  const label = 'Nombre de places sur le parc';

  return (
    <NumberInput
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                return validateFicheProfilGf(
                  gfController,
                  ProfilFourriereViolationDtoFormFieldEnum.NOMBRE_EMPLACEMENTS,
                  user.idCorrelationFourriere || '',
                  generateRequestDto({ ...values, [key]: value }),
                );
              },
            },
          ],
        },
        editable: isEditable,
      }}
      numberInputProps={{
        placeholder: FormPlaceholders.Number,
      }}
    />
  );
}

export default InputParkSize;
