import React from 'react';
import { Button as AntdButton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { DashboardAutoriteFourriereFilter } from '../../types';
import { TableSort } from 'components/WrappedComponents/Table/types';
import { useExportDashboard } from 'hooks/dossiers/useExportDashboard';
import { useUserStore } from 'hooks/UserStoreContext';

interface DownloadButtonProps<SortType extends string | undefined> {
  filters: Partial<DashboardAutoriteFourriereFilter>;
  sort: TableSort<SortType>;
}

function DownloadButton<SortType extends string | undefined>({
  filters,
  sort,
}: DownloadButtonProps<SortType>): React.ReactElement {
  const { exportDashboard, inProgress } = useExportDashboard();
  const user = useUserStore();

  return (
    <AntdButton
      className={'create-dossier-button'}
      icon={<DownloadOutlined />}
      type={'default'}
      shape={'round'}
      onClick={() => {
        exportDashboard(sort, {
          includeDossiersSortis: filters.includeDossiersSortis,
          communeEnlevementList: filters.commune,
          classementList: filters.classement,
          dateRangeMiseEnFourriere: filters.dateRangeMiseEnFourriere,
          dateRangeBonEnlevement: filters.dateRangeBonEnlevement,
          idNatureBrancheList: filters.natureMiseEnFourriere,
          numeroImmatriculation: filters.search,
          idFourriereList: filters.fourriere,
          idAutoriteFourriere: user.idCorrelationAutoriteFourriere || undefined,
          statutDossierEnumList: filters.statut,
          dateRangeSortieDefinitive: filters.dateRangeSortieDefinitive,
          actionsRequises: filters.actionsRequises,
          idMotifSortieList: filters.motifDeSortie,
          statutsFacturation: filters.statutsTraitementFacturation,
          includeDossiersAnonymises: filters.includeDossiersAnonymises,
          dateRangeOrdreDestruction: filters.dateRangeOrdreDestruction,
        });
      }}
      loading={inProgress}
    />
  );
}

export default DownloadButton;
