export enum GardienSortOptions {
  'ACTIONS_REQUISES' = 'ACTIONS_REQUISES',
  'CLASSEMENT' = 'CLASSEMENT',
  'COULEUR' = 'COULEUR',
  'DATE_MISE_FOURRIERE' = 'DATE_MISE_FOURRIERE',
  'DATE_MISE_A_JOUR' = 'DATE_MISE_A_JOUR',
  'IMMATRICULATION' = 'IMMATRICULATION',
  'MARQUE' = 'MARQUE',
  'MODELE' = 'MODELE',
  'NATURE_MISE_EN_FOURRIERE' = 'NATURE_MISE_EN_FOURRIERE',
  'STATUT' = 'STATUT',
}
