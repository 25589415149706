import React, { useEffect, useState } from 'react';
import { Label } from 'components/WrappedComponents';

import Spinner from 'components/WrappedComponents/Spinner';
import { ProfilFormContainer } from 'components/ProfilFormContainer';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { FicheProfilForceOrdre } from './types';
import { ficheProfilFromDto } from './utils';

import './PageProfil.less';

function PageProfil(): React.ReactElement {
  const user = useUserStore();
  const { ReferentielControllerApi } = useApi();
  const [data, setData] = useState<FicheProfilForceOrdre | null>(null);

  useEffect(() => {
    let cancel = false;
    if (user.idCorrelationUniteFO !== null) {
      ReferentielControllerApi.getValidUniteFOByIdCorrelationUsingGET(
        user.idCorrelationUniteFO,
      )
        .then(uniteFO => {
          if (cancel) {
            return;
          }
          setData(ficheProfilFromDto(uniteFO));
        })
        .catch(() => {
          if (cancel) {
            return;
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [ReferentielControllerApi, user]);

  if (data === null) {
    return <Spinner />;
  }

  return (
    <>
      <ProfilFormContainer title="Mes informations">
        <div className="profil-form-fo-read-only-fields-container">
          <Label label="Nom" value={user.lastName || ''} />
          <Label label="Prénom" value={user.firstName || ''} />
          {user.passage2UniteFoTypeOrganisation && (
            <Label
              label={"Type d'organisation (Passage 2)"}
              value={user.passage2UniteFoTypeOrganisation}
            />
          )}
          {user.passage2UniteFOLibelle && (
            <Label
              label={'Unité (Passage 2)'}
              value={user.passage2UniteFOLibelle}
            />
          )}
          {user.passage2UniteFoCodeUnite && (
            <Label
              label={'Code unité (Passage 2)'}
              value={user.passage2UniteFoCodeUnite}
            />
          )}
          {data.unite && (
            <Label label={'Unité SIF de rattachement'} value={data.unite} />
          )}
        </div>
      </ProfilFormContainer>
    </>
  );
}

export default PageProfil;
