import { Dispatch, SetStateAction } from 'react';
import { message as AntdMessage } from 'antd';

import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';

export function useSubmitDeleteAccount(
  setLoading: Dispatch<SetStateAction<boolean>>,
): (userId: string, username: string) => void {
  const cuController = useApi().AdminFonctionnelControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return (userId: string, username: string): void => {
    setLoading(true);
    cuController
      .deleteUserUsingDELETE(userId)
      .then(() => {
        void AntdMessage.success(
          `Le compte ${username} à bien été supprimé`,
          5,
        );
      })
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  };
}
