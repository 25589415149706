import {
  MarqueDto,
  ReferentielSearchResultDtoMarqueDto,
  SearchMarqueUsingSpecsUsingGETQueryParams,
} from 'lib_api/lib/api/gen';
import { BaseSearchResult } from 'types/searchResult';
import useAsyncSearch from './useAsyncSearch';
import { useSearchMarque } from '../useSearchMarque';

type MarqueTypeEnum = 'EXACTE' | 'APPROXIMATIVE' | 'INCONNUE';

export function useAsyncSearchMarque(
  types?: Array<MarqueTypeEnum>,
  initialMarque?: MarqueDto,
): () => BaseSearchResult<MarqueDto> {
  const mapResult = (result: ReferentielSearchResultDtoMarqueDto) => {
    return result.results.referenceDtoList;
  };
  const updateFilters = (
    query?: string,
  ): SearchMarqueUsingSpecsUsingGETQueryParams => {
    return {
      filters: { libelle: query ?? '', types: types },
      sorts: { libelle: true },
    };
  };

  const defaultFilter: SearchMarqueUsingSpecsUsingGETQueryParams = {
    filters: { libelle: initialMarque?.libelle, types: types },
    sorts: { libelle: true },
  };

  return () =>
    useAsyncSearch(useSearchMarque, mapResult, updateFilters, defaultFilter);
}
