import React from 'react';

import { MotifMefFormValues } from 'types/referentiels/MotifMef';
import { useSubmitCreateMotifMef, useValidateCreateMotifMef } from '../utils';
import ModalEditionMotifMef from './ModalEditionMotifMef';

interface ModalCreateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
}

export default function ModalCreate({
  close,
  visible,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const initials: MotifMefFormValues = {};
  const { submit, inProgress } = useSubmitCreateMotifMef(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateMotifMef();

  return (
    <ModalEditionMotifMef
      close={close}
      visible={visible}
      title="Créer un motif de MEF"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}
