import React from 'react';

export enum FluxVenteDossiersSortOptions {
  'DATE_APPEL' = 'DATE_APPEL',
  'DATE_FIN' = 'DATE_FIN',
  'DATE_DEBUT' = 'DATE_DEBUT',
}

export type FilterDashboardFluxVenteDossiers = {};

export type FilterDashboardFluxVenteDossiersWarnings = {
  fluxId?: string;
};

export enum FluxStatus {
  'SUCCESS' = 'SUCCESS',
  'ERROR' = 'ERROR',
  'WARNING' = 'WARNING',
}

export type FluxStatusType = {
  [key in FluxStatus]: React.ReactNode;
};
