import React from 'react';

import { TypeAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { TypeAfFormValues } from 'types/referentiels/TypeAf';

import {
  buildFormValues,
  useSubmitUpdateTypeAf,
  useValidateUpdateTypeAf,
} from '../utils';
import { ModalEditionTypeAf } from './ModalEditionTypeAf';

interface ModalCreateProps {
  typeAutoriteFourriere: TypeAutoriteFourriereDto;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalUpdate({
  typeAutoriteFourriere,
  visible,
  close,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const initials: TypeAfFormValues = buildFormValues(typeAutoriteFourriere);
  const { submit, inProgress } = useSubmitUpdateTypeAf(
    typeAutoriteFourriere.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateTypeAf(
    typeAutoriteFourriere.idCorrelation,
  );

  return (
    <ModalEditionTypeAf
      close={close}
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      visible={visible}
      title={"Mise à jour du type d'autorité de fourrière"}
      validate={validate}
    />
  );
}

export default ModalUpdate;
