import React from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { ConditionsReactivateFaqDto, FaqAdminDto } from 'lib_api/lib/api/gen';

import ModalDetails from './Modals/Details/ModalDetails';
import ModalModification from '../Faq/Modals/Modification/ModalModification';
import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation from '../Modals/ModalReactivation/ModalReactivation';
import { useApi } from 'hooks/ApiStoreContext';
import { useChangeValidityFaq } from './utils';
import conditionReactivateFaq from './ConditionReactivateFaq/ConditionReactivateFaq';
import { useModal } from 'hooks/modal/useModal';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  faq: FaqAdminDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  faq,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const detailModal = useModal();
  const updateModal = useModal();
  const validityModal = useModal();

  const controller = useApi().AdminFonctionnelControllerApi;

  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ] = useChangeValidityFaq(controller);

  const conditionUnlock = (
    conditions: ConditionsReactivateFaqDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item key={'update'} onClick={updateModal.open}>
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item key={'valid'} onClick={validityModal.open}>
        {faq.valid ? 'Désactiver la question' : 'Réactiver la question'}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <AntdButton onClick={detailModal.open}>Détails</AntdButton>
      <ModalModification
        refreshDashboard={refreshDashboard}
        faq={faq}
        visible={updateModal.visible}
        close={updateModal.close}
      />
      <ModalDetails
        faq={faq}
        visible={detailModal.visible}
        close={detailModal.close}
      />
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {faq.valid ? (
        <ModalDesactivation
          idCorrelation={faq.idCorrelation}
          titleModal="Désactiver la question"
          usesRequest={getUsesRequest}
          submitDeprecate={submitDeprecateReferentiel}
          componentUses={undefined}
          visible={validityModal.visible}
          close={validityModal.close}
          callAfterSubmit={refreshDashboard}
        />
      ) : (
        <ModalReactivation
          idCorrelation={faq.idCorrelation}
          titleModal="Réactiver la question"
          visible={validityModal.visible}
          close={validityModal.close}
          callAfterSubmit={refreshDashboard}
          conditionRequest={conditionsReactivateReferentiel}
          componentConditions={conditionReactivateFaq}
          conditionUnlock={conditionUnlock}
          reactivateRequest={submitReactivateReferentiel}
        />
      )}
    </>
  );
}

export default CellDetailsAndActions;
