import React, { useCallback } from 'react';

import {
  AdminFonctionnelControllerApi,
  ConditionsReactivateMotifMefDto,
  MotifMefDto,
  MotifMefRequestDto,
  MotifMefViolationDtoFormFieldEnum,
  ReferentielSearchResultDtoMotifMefDto,
} from 'lib_api/lib/api/gen';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { MotifMefFormValues } from 'types/referentiels/MotifMef';
import { useBoolean } from 'utils/genericUtils';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { FilterDashboardMotifMef, MotifMefSort } from './types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  MotifMefDto,
  FilterDashboardMotifMef
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData?.total ?? 0}
    filter={filter}
    setFilter={setFilter}
  />
);

export function useSearchMotifMef(
  filter: FilterDashboardMotifMef,
  sort: TableSort<MotifMefSort>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoMotifMefDto>,
  (
    result: ReferentielSearchResultDtoMotifMefDto,
  ) => FetchDataResult<MotifMefDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () => {
      return controller.searchMotifMefUsingSpecsUsingGET({
        filters: {
          ...filter,
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
        sorts: {
          libelle: sort?.LIBELLE,
          natureMef: sort?.NATURE,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export const buildFormValues = (motifMef: MotifMefDto): MotifMefFormValues => {
  return {
    motifMef: motifMef?.motifMef ?? undefined,
    nature: motifMef?.nature ?? undefined,
    branche: motifMef?.branche?.idCorrelation ?? undefined,
    dicem: motifMef?.dicem ?? undefined,
    rodeo: motifMef?.rodeo ?? undefined,
  };
};

export const buildRequestDto = (
  formValues: MotifMefFormValues,
): MotifMefRequestDto => {
  return {
    motifMef: formValues?.motifMef?.trim() || null,
    nature: formValues?.nature || null,
    brancheIdCorrelation: formValues?.branche || null,
    dicem: formValues?.dicem || null,
    rodeo: formValues?.rodeo || null,
  };
};

export function useValidateCreateMotifMef(): ValidateFunction<
  MotifMefFormValues,
  MotifMefViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = async (
    field: MotifMefViolationDtoFormFieldEnum,
    values: MotifMefFormValues,
  ) => {
    const response = await controller.validateCreateMotifMefFieldUsingPOST(
      field,
      buildRequestDto(values),
    );

    const violations = response.motifMefViolationsDto ?? [];
    if (violations.length === 0) {
      return Promise.resolve();
    }

    return Promise.reject(violations[0].message);
  };

  return { validate };
}

export function useSubmitCreateMotifMef(
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<MotifMefFormValues> {
  const {
    value: inProgress,
    setIsTrue: startProgress,
    setIsFalse: stopProgress,
  } = useBoolean();

  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: MotifMefFormValues) => {
      startProgress();
      return controller
        .createMotifMefUsingPOST(buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch(backAlertMessage)
        .finally(stopProgress);
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit,
    inProgress,
  };
}

export function useValidateUpdateMotifMef(
  motifMefIdCorrelation: string,
): ValidateFunction<MotifMefFormValues, MotifMefViolationDtoFormFieldEnum> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = async (
    field: MotifMefViolationDtoFormFieldEnum,
    values: MotifMefFormValues,
  ) => {
    const response = await controller.validateUpdateMotifMefFieldUsingPOST(
      field,
      motifMefIdCorrelation,
      buildRequestDto(values),
    );

    const violations = response.motifMefViolationsDto ?? [];
    if (violations.length === 0) {
      return Promise.resolve();
    }

    return Promise.reject(violations[0].message);
  };

  return {
    validate,
  };
}

export function useSubmitUpdateMotifMef(
  motifMefIdCorrelation: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<MotifMefFormValues> {
  const {
    value: inProgress,
    setIsTrue: startProgress,
    setIsFalse: stopProgress,
  } = useBoolean();

  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: MotifMefFormValues) => {
      startProgress();
      return controller
        .updateMotifMefUsingPUT(motifMefIdCorrelation, buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch(backAlertMessage)
        .finally(stopProgress);
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit,
    inProgress,
  };
}

export function useChangeValidityMotifMef(
  controller: AdminFonctionnelControllerApi,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateMotifMefDto>,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateMotifMefUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateMotifMefUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateMotifMefDto> => {
      return controller.conditionReactivationMotifMefUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ];
}
