import React from 'react';
import { Button as AntdButton } from 'antd';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import { FilterDashboardMarque } from '../../DashboardHeader/types';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: FilterDashboardMarque) => void;
}

export default function FormFooter({
  setFilter,
}: FormActionProps): React.ReactElement {
  const formActions = useBaseFormContext<FilterDashboardMarque>();

  function resetFilter() {
    formActions.setFieldsValue({
      id: undefined,
      libelle: undefined,
      categorie: undefined,
      type: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type={'primary'}
        onClick={() => setFilter(formActions.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}
