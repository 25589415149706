import React from 'react';
import { Empty as AntdEmpty } from 'antd';

interface EmptyProps {
  description?: string;
}

function Empty({ description }: EmptyProps): React.ReactElement {
  return (
    <AntdEmpty
      image={AntdEmpty.PRESENTED_IMAGE_SIMPLE}
      description={description ?? 'Aucun résultat trouvé'}
    />
  );
}

export default Empty;
