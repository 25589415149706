import React, { useCallback } from 'react';
import { Checkbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { isEqual } from 'lodash';

import { FilterButton, SearchBar } from 'components/WrappedComponents';
import {
  ActionsRequisesDtoActionsRequisesEnum,
  TraitementResponseDtoListStatutEnum,
} from 'lib_api/lib/api/gen';
import { useGFFilterContext } from 'hooks/GFFilterStoreContext';

import { FilterFormGardien } from './FilterFormGardien';
import { FilterDashboardGardien, FormFilterGardien } from './types';

import './Filter.less';

interface Props {
  setFilter: (value: Partial<FilterDashboardGardien>) => void;
  filter: Partial<FilterDashboardGardien>;
}

function keepDossierSortis(
  statut: TraitementResponseDtoListStatutEnum[],
  sortieFilter: boolean,
  motifDeSortieFilter: string[],
  includeDossiersAnonymises: boolean,
): boolean {
  return (
    statut.includes(TraitementResponseDtoListStatutEnum.SORTI) ||
    (statut.length === 0 && sortieFilter) ||
    motifDeSortieFilter.length !== 0 ||
    // if we search for anonymized dossiers
    includeDossiersAnonymises === true
  );
}

export function Filter({ filter, setFilter }: Props): React.ReactElement {
  const {
    searchPlaque,
    includeDossiersSortis,
    includeOnlyActionsRequises,
    ...formFilter
  } = filter;
  const [, , gfActionsRequises] = useGFFilterContext();

  const keepOnlyActionsRequises = useCallback(
    (actionsRequises?: ActionsRequisesDtoActionsRequisesEnum[]): boolean => {
      return isEqual(new Set(actionsRequises), new Set(gfActionsRequises));
    },
    [gfActionsRequises],
  );

  function setFormFilter(value: Partial<FormFilterGardien>) {
    const {
      statut,
      motifDeSortie,
      actionsRequises,
      includeDossiersAnonymises,
    } = value;
    setFilter({
      ...filter,
      ...value,
      includeDossiersSortis: keepDossierSortis(
        statut ?? [],
        filter.includeDossiersSortis ?? false,
        motifDeSortie ?? [],
        includeDossiersAnonymises ?? false,
      ),
      includeOnlyActionsRequises: keepOnlyActionsRequises(actionsRequises),
    });
  }

  function setIncludeDossiersSortis(event: CheckboxChangeEvent) {
    const { statut, motifDeSortie } = filter;
    const updatedStatut =
      statut?.filter(s => s !== TraitementResponseDtoListStatutEnum.SORTI) ||
      [];

    // Only add the SORTI statut filter if the statut filter is not empty
    if (event.target.checked && (statut?.length ?? -1) > 0) {
      updatedStatut.push(TraitementResponseDtoListStatutEnum.SORTI);
    }

    setFilter({
      ...filter,
      statut: updatedStatut,
      motifDeSortie: event.target.checked ? motifDeSortie : [],
      includeDossiersSortis: event.target.checked,
      includeDossiersAnonymises: undefined,
    });
  }

  function setIncludeOnlyActionsRequises(event: CheckboxChangeEvent) {
    const isChecked = event.target.checked;
    setFilter({
      ...filter,
      actionsRequises: isChecked ? gfActionsRequises : undefined,
      includeOnlyActionsRequises: isChecked,
    });
  }

  return (
    <div className="dashboard-header-filter">
      <SearchBar
        value={filter.searchPlaque}
        // Plates values are forced to uppercase
        setValue={value => {
          setFilter({
            ...filter,
            searchPlaque: value?.toUpperCase(),
          });
        }}
        antdProps={{
          placeholder: 'Rechercher une plaque...',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
      <FilterButton
        setFilter={setFormFilter}
        filter={formFilter}
        textInactiveFilter={'Filtres'}
        textActiveFilter={'Filtres (actifs)'}
      >
        {(setter, data) => (
          <FilterFormGardien setFilter={setter} filter={data} />
        )}
      </FilterButton>
      <Checkbox
        checked={includeDossiersSortis}
        onChange={setIncludeDossiersSortis}
      >
        Afficher les véhicules sortis
      </Checkbox>
      <Checkbox
        checked={includeOnlyActionsRequises}
        onChange={setIncludeOnlyActionsRequises}
      >
        Afficher uniquement les actions requises
      </Checkbox>
    </div>
  );
}
