import React from 'react';
import {
  SortieVehiculeRequestDto,
  SortieVehiculeViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { SortieVehiculeFieldsProps } from './types';
import { SortieVehicule } from 'types/SortieVehicule';
import { generateSortieVehiculeRequestDtoFromSortieVehicule } from '../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { SelectMotifSortieUsingId } from 'components/WrappedComponents/Form/FormFields/Select/Referentiels/MotifSortie';
import { validateSortieVehiculeRule } from '../utils';

function SelectMotif({
  values,
  dossierId,
}: SortieVehiculeFieldsProps): React.ReactElement {
  const { GardienFourriereControllerApi: gfController } = useApi();
  const key: keyof SortieVehicule = 'motifId';

  return (
    <SelectMotifSortieUsingId
      values={values}
      fieldKey={key}
      validator={async value => {
        const sortieVehiculeRequestDto: SortieVehiculeRequestDto =
          generateSortieVehiculeRequestDtoFromSortieVehicule({
            ...values,
            [key]: value,
          });
        return await validateSortieVehiculeRule(
          dossierId,
          gfController,
          sortieVehiculeRequestDto,
          SortieVehiculeViolationDtoFormFieldEnum.MOTIF_SORTIE,
        );
      }}
    />
  );
}

export default SelectMotif;
