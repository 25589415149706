type Timeout = ReturnType<typeof setTimeout>;

/**
 * function to call an api with a delay of 500ms
 * @param fetch
 * @param delay
 */
export function useDelay<T = unknown>(
  fetch: (timerID: Timeout | null, data?: T) => void,
  delay: number,
): [(data?: T) => void, () => void] {
  let timerID: Timeout | null = null;
  const fetchFunction = (data?: T) => {
    timerID = setTimeout(() => {
      fetch(timerID, data);
    }, delay);
  };

  const cancelTimer = () => {
    if (timerID !== null) {
      clearTimeout(timerID);
      timerID = null;
    }
  };

  return [fetchFunction, cancelTimer];
}
