import React, { useState } from 'react';
import { Button as AntdButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Filter from './Filter/Filter';
import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import ModalCreate from '../Modals/ModalCreate';

import './DashboardHeader.less';

interface DashboardHeaderProps {
  totalCount: number;
  setFilter: (value: Partial<FilterDashboardAutoriteFourriere>) => void;
  filter: Partial<FilterDashboardAutoriteFourriere>;
}

function DashboardHeader({
  totalCount,
  filter,
  setFilter,
}: DashboardHeaderProps): React.ReactElement {
  const countString = `${totalCount} autorité${
    totalCount > 1 ? 's' : ''
  } trouvée${totalCount > 1 ? 's' : ''}`;
  const [modalCreationOpen, setModalCreationOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalCreationOpen(true);
  };

  const closeModal = () => {
    setModalCreationOpen(false);
  };

  const refreshDashboard = () => {
    setFilter({ ...filter });
  };

  return (
    <div>
      <div className="dashboard-header-fields header-referentiels">
        <Filter filter={filter} setFilter={setFilter} />
        <AntdButton
          className={'create-dossier-button'}
          icon={<PlusOutlined />}
          type={'primary'}
          shape={'round'}
          onClick={openModal}
        >
          Créer une autorité de fourrières
        </AntdButton>
        <ModalCreate
          visible={modalCreationOpen}
          close={closeModal}
          refreshDashboard={refreshDashboard}
        />
      </div>
      <p className="dashboard-number-vehicules">{countString}</p>
    </div>
  );
}

export default DashboardHeader;
