import { createContext, useContext, Dispatch, SetStateAction } from 'react';
import { TableSort } from 'components/WrappedComponents/Table/types';

interface SortContextContent<SortType extends string | undefined> {
  sort: TableSort<SortType>;
  setSort: Dispatch<SetStateAction<TableSort<SortType>>>;
}

const SortContext = createContext<SortContextContent<
  string | undefined
> | null>(null);

export const useSortContext = <SortType extends string | undefined>(): [
  TableSort<SortType>,
  Dispatch<SetStateAction<TableSort<SortType>>>,
] => {
  const context = useContext(SortContext);
  if (context === null) {
    throw new Error('usePaginationContext called outside Context.');
  }

  return [context.sort, context.setSort];
};

const SortProvider = SortContext.Provider;

export default SortProvider;
