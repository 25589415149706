import { Form } from 'components/WrappedComponents';
import React from 'react';

import { FormFilterGardien } from '../types';
import {
  ClassementFilterField,
  DateDerniereMiseAJour,
  DateMiseEnFourriere,
  FormFooter,
  SelectActionsRequises,
  SelectNatureMiseEnFourriere,
  StatutMiseEnFourriere,
} from './Fields';
import { SelectMotifSortieUsingIdCorrelation } from 'components/WrappedComponents/Form/FormFields/Select/Referentiels/MotifSortie';
import CheckboxDossiersExpires from './Fields/CheckboxDossiersExpires';

interface Props {
  setFilter: (value: Partial<FormFilterGardien>) => void;
  filter: Partial<FormFilterGardien>;
}

export function FilterFormGardien({
  setFilter,
  filter,
}: Props): React.ReactElement {
  return (
    <Form
      id={'GardiendFilterForm'}
      initialValue={filter}
      validateButtonTitle={'Sauver'}
      className="bordered"
      // We cannot use the onSubmit function because it causes an error on console,
      // because it try to re-render FormWrapper in the same time as FilterButton
      onSubmit={() => Promise.resolve()}
    >
      {values => {
        return (
          <>
            <SelectNatureMiseEnFourriere values={values} />
            <StatutMiseEnFourriere values={values} />
            <ClassementFilterField values={values} />
            <DateMiseEnFourriere values={values} />
            <DateDerniereMiseAJour values={values} />
            <SelectMotifSortieUsingIdCorrelation
              values={values}
              fieldKey="motifDeSortie"
              mode="multiple"
            />
            <SelectActionsRequises values={values} />
            <CheckboxDossiersExpires values={values} />

            <FormFooter setFilter={setFilter} />
          </>
        );
      }}
    </Form>
  );
}
