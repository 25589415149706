import { useState } from 'react';
import {
  AutoriteFourriereControllerApi,
  CoordonneesProprietaireRequestDto,
  CoordonneesProprietaireResponseDto,
  CoordonneesProprietaireViolationDto,
  CoordonneesProprietaireViolationDtoFormFieldEnum,
  DossierResponseDto,
  ForceOrdreControllerApi,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import {
  useValidationRequest,
  ValidationCallback,
} from 'hooks/utils/handleValidationRequest';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { CoordonneesProprietaire } from './types';
import { useBoolean } from 'utils/genericUtils';

export function convertCoordonneesProprietaireToCoordonneeProprietaireRequestDto(
  coordonneesProprietaire: CoordonneesProprietaire,
): CoordonneesProprietaireRequestDto {
  return {
    boitePostale: coordonneesProprietaire.boitePostale || null,
    codeCedex: coordonneesProprietaire.codeCedex || null,
    codePostal: coordonneesProprietaire.codePostal || null,
    complementAdresse: coordonneesProprietaire.complementAdresse || null,
    extension: coordonneesProprietaire.extension || null,
    libelleCedex: coordonneesProprietaire.libelleCedex || null,
    libelleCommune: coordonneesProprietaire.libelleCommune || null,
    libelleVoie: coordonneesProprietaire.libelleVoie || null,
    lieuDit: coordonneesProprietaire.lieuDit || null,
    nom: coordonneesProprietaire.nom || null,
    numero: coordonneesProprietaire.numero || null,
    pointRemise: coordonneesProprietaire.pointRemise || null,
    prenom: coordonneesProprietaire.prenom || null,
    raisonSociale: coordonneesProprietaire.raisonSociale || null,
    typeVoie: coordonneesProprietaire.typeVoie || null,
  };
}

type CoordonneesProprietairesValidationCallback = ValidationCallback<
  AutoriteFourriereControllerApi | ForceOrdreControllerApi
>;

export async function validateCoordonneesProprietaireField(
  dossierId: string,
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  values: CoordonneesProprietaireRequestDto,
  field: CoordonneesProprietaireViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response =
      controller instanceof AutoriteFourriereControllerApi
        ? await controller.validateCoordonneesProprietairesFieldAfUsingPOST(
            field,
            dossierId,
            values,
          )
        : await controller.validateCoordonneesProprietairesFieldFoUsingPOST(
            field,
            dossierId,
            values,
          );

    if (response.updateCoordonneesProprietaireViolationDtoList) {
      const fieldViolations =
        response.updateCoordonneesProprietaireViolationDtoList.filter(
          violation => violation.formField === field,
        );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

export function useValidateCoordonneesProprietaire(
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  onSuccess: () => Promise<DossierResponseDto>,
  thenSubmit: (dossier: DossierResponseDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (
    requestGenerator: CoordonneesProprietairesValidationCallback,
  ) => Promise<void>,
  CoordonneesProprietaireViolationDto[] | null,
] {
  return useValidationRequest(
    controller,
    'updateCoordonneesProprietaireViolationDtoList',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}

type SubmitFunction = {
  submit: (values: CoordonneesProprietaire) => void;
  inProgress: boolean;
};

export function useSubmitCoordonneesProprietaires(
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  dossierId: string,
  onSuccess: (dossier: DossierResponseDto) => Promise<void>,
  closeModal: () => void,
): [(values: CoordonneesProprietaire) => void, boolean] {
  const {
    value: inProgress,
    setIsTrue: startProgress,
    setIsFalse: stopProgress,
  } = useBoolean();
  return [
    (values: CoordonneesProprietaire) => {
      startProgress();
      const coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto =
        convertCoordonneesProprietaireToCoordonneeProprietaireRequestDto(
          values,
        );
      return updateCoordonneesProprietaires(
        controller,
        coordonneesProprietaireRequestDto,
        dossierId,
      )
        .then(updatedDossier => {
          onSuccess(updatedDossier)
            .then(closeModal)
            .catch(backAlertMessage)
            .finally(stopProgress);
        })
        .catch((error: Response) => {
          void backAlertMessage(error);
        });
    },
    inProgress,
  ];
}

export function convertSivInfoToCoordonneesProprietaire(
  coordonneesProprietaireSiv:
    | CoordonneesProprietaireResponseDto
    | null
    | undefined,
): CoordonneesProprietaire {
  return {
    boitePostale: coordonneesProprietaireSiv?.boitePostale || undefined,
    codeCedex: coordonneesProprietaireSiv?.codeCedex || undefined,
    codePostal: coordonneesProprietaireSiv?.codePostal || undefined,
    complementAdresse:
      coordonneesProprietaireSiv?.complementAdresse || undefined,
    extension: coordonneesProprietaireSiv?.extension || undefined,
    libelleCedex: coordonneesProprietaireSiv?.libelleCedex || undefined,
    libelleCommune: coordonneesProprietaireSiv?.libelleCommune || '',
    libelleVoie: coordonneesProprietaireSiv?.libelleVoie || undefined,
    lieuDit: coordonneesProprietaireSiv?.lieuDit || undefined,
    nom: coordonneesProprietaireSiv?.nom || undefined,
    numero: coordonneesProprietaireSiv?.numero || undefined,
    pointRemise: coordonneesProprietaireSiv?.pointRemise || undefined,
    prenom: coordonneesProprietaireSiv?.prenom || undefined,
    raisonSociale: coordonneesProprietaireSiv?.raisonSociale || undefined,
    typeVoie: coordonneesProprietaireSiv?.typeVoie || undefined,
  };
}

function updateCoordonneesProprietaires(
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi,
  coordonneesProprietaireRequestDto: CoordonneesProprietaireRequestDto,
  dossierId: string,
): Promise<DossierResponseDto> {
  return controller instanceof AutoriteFourriereControllerApi
    ? controller.updateCoordonneesProprietairesAfUsingPOST(
        dossierId,
        coordonneesProprietaireRequestDto,
      )
    : controller.updateCoordonneesProprietairesFoUsingPOST(
        dossierId,
        coordonneesProprietaireRequestDto,
      );
}
