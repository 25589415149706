import React from 'react';
import { Typography as AntdTypo } from 'antd';

import { Form } from 'components/WrappedComponents';

import { FilterDashboardFourriere } from 'types/referentiels/Fourriere';
import FormFooter from './FormFooter/FormFooter';
import InputCommune from './Fields/InputCommune';
import InputCodePostal from './Fields/InputCodePostal';
import InputRaisonSociale from './Fields/InputRaisonSociale';
import SelectAutoriteFourriere from './Fields/SelectAutoriteFourriere';
import CheckboxEmptyIdHermes from './Fields/CheckboxEmptyIdHermes';
import InputIdHermes from './Fields/InputIdHermes';
import InputIdCorrelation from './Fields/InputIdCorrelation';

interface FilterFormProps {
  filter: FilterDashboardFourriere;
  setFilter: (value: FilterDashboardFourriere) => void;
}

function FilterFormFourriere({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  return (
    <Form
      id="FourriereFilterForm"
      initialValue={filter}
      // We cannot use the onSubmit function because it causes an error on console,
      // because it try to re-render FormWrapper in the same time as FilterButton
      onSubmit={() => Promise.resolve()}
      onChange={(values, newValues) => {
        if (newValues.commune) {
          values.codePostal = newValues.commune.codePostal;
        }
      }}
      validateButtonTitle="" // not used
    >
      {values => (
        <>
          <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
          <InputIdCorrelation values={values} />
          <InputCodePostal values={values} />
          <InputCommune values={values} />
          <InputRaisonSociale values={values} />
          <SelectAutoriteFourriere values={values} />
          <InputIdHermes values={values} />
          <CheckboxEmptyIdHermes values={values} />
          <FormFooter setFilter={setFilter} />
        </>
      )}
    </Form>
  );
}

export default FilterFormFourriere;
