import { Moment } from 'moment';
import {
  ActionsRequisesDtoActionsRequisesEnum,
  TraitementResponseDtoListStatutEnum,
  TraitementSummaryDtoClassementEnum,
} from 'lib_api/lib/api/gen';
import { StatutTraitementFacturation } from 'hooks/dossiers/useSearchDossierSummaries';

// filters defined in modal form
export interface DashboardAutoriteFourriereFilterForm {
  typeVehicule: string[]; //TODO: check needs for this filter
  statut: TraitementResponseDtoListStatutEnum[];
  classement: TraitementSummaryDtoClassementEnum[];
  fourriere: string[];
  commune: string[];
  actionsRequises: ActionsRequisesDtoActionsRequisesEnum[];
  statutsTraitementFacturation: StatutTraitementFacturation[];
  includeDossiersAnonymises: boolean;
  natureMiseEnFourriere: string[];
  motifDeSortie: string[];
  dateRangeMiseEnFourriere: [Moment | undefined, Moment | undefined];
  dateRangeBonEnlevement: [Moment | undefined, Moment | undefined];
  dateRangeSortieDefinitive: [Moment | undefined, Moment | undefined];
  dateRangeOrdreDestruction: [Moment | undefined, Moment | undefined];
}

// all filters, including filters from modal and search bar
export interface DashboardAutoriteFourriereFilter
  extends DashboardAutoriteFourriereFilterForm {
  search: string | undefined;
  includeDossiersSortis: boolean;
  includeOnlyActionsRequises: boolean;
  includeOnlyNonTraiteFacturation: boolean;
}

export enum AutoriteFourriereSortOptions {
  'ACTIONS_REQUISES' = 'ACTIONS_REQUISES',
  'CLASSEMENT' = 'CLASSEMENT',
  'FOURRIERE' = 'FOURRIERE',
  'DATE_BON_ENLEVEMENT' = 'DATE_BON_ENLEVEMENT',
  'DATE_MISE_FOURRIERE' = 'DATE_MISE_FOURRIERE',
  'DATE_SORTIE_DEFINITIVE' = 'DATE_SORTIE_DEFINITIVE',
  'IMMATRICULATION' = 'IMMATRICULATION',
  'NATURE_MISE_EN_FOURRIERE' = 'NATURE_MISE_EN_FOURRIERE',
  'STATUT' = 'STATUT',
  'DATE_ORDRE_DESTRUCTION' = 'DATE_ORDRE_DESTRUCTION',
}
