import React from 'react';
import { message, Modal, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { RcFile, UploadChangeParam } from 'antd/lib/upload';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { CallBackProps, UploaderProps } from './types';

import './Uploader.less';

const { Dragger } = Upload;

const userConfirm = (): Promise<void> => {
  return new Promise(function (resolve, reject) {
    Modal.confirm({
      title: `Êtes-vous sûr(e) de vouloir importer ce fichier ?`,
      content:
        'Cette action peut avoir des répercussions importantes sur le SI Fourrière.',
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
    });
  });
};

const Uploader = ({
  importRoute,
  title,
  accept,
  fileTypes,
}: UploaderProps): React.ReactElement => {
  function sendFile({ onSuccess, file }: CallBackProps): void {
    const response: Response = new Response(file);
    response
      .text()
      .then(() => importRoute(file))
      .catch(backAlertMessage);
    onSuccess?.(response, file);
  }

  return (
    <Dragger
      accept={accept}
      name={'file'}
      multiple={false}
      showUploadList={false}
      beforeUpload={(file: RcFile) => {
        const isFileSupported = fileTypes.includes(file.type);
        if (!isFileSupported) {
          void message.error(`${file.name} n'est pas dans un format supporté`);
        }
        return isFileSupported && userConfirm();
      }}
      onChange={(info: UploadChangeParam) => {
        if (info.file.status !== 'uploading') {
          void message.loading(`${info.file.name} est en cours de traitement.`);
        }
      }}
      customRequest={({ onSuccess, file }) => {
        if (typeof file !== 'string') {
          sendFile({ onSuccess, file });
        }
      }}
    >
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="Uploadtitle">{title}</p>
    </Dragger>
  );
};

export default Uploader;
