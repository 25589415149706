import React from 'react';
import { Typography as AntdTypo } from 'antd';

import { Form } from 'components/WrappedComponents';

import { FilterDashboardCentreVHU } from '../types';
import {
  InputCodePostal,
  InputCommune,
  InputNumeroAgrement,
  InputRaisonSociale,
  InputSiren,
  InputIdCorrelation,
} from './Fields';
import FormFooter from './FormFooter/FormFooter';

interface FilterFormProps {
  filter: FilterDashboardCentreVHU;
  setFilter: (value: FilterDashboardCentreVHU) => void;
}

function FilterFormCentreVHU({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  return (
    <Form
      id="CentreVHUFilterForm"
      initialValue={filter}
      onSubmit={() => Promise.resolve()}
      onChange={(values, newValues) => {
        if (newValues.commune) {
          values.codePostal = newValues.commune.codePostal;
        }
      }}
      validateButtonTitle="" // not used
    >
      {values => (
        <>
          <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
          <InputIdCorrelation values={values} />
          <InputRaisonSociale values={values} />
          <InputSiren values={values} />
          <InputCodePostal values={values} />
          <InputCommune values={values} />
          <InputNumeroAgrement values={values} />
          <FormFooter setFilter={setFilter} />
        </>
      )}
    </Form>
  );
}

export default FilterFormCentreVHU;
