import React, { useMemo } from 'react';
import {
  ExportDonneesDossierAdminFonctionnelDto,
  TraitementResponseDtoClassementEnum,
  UpdateClassementRequestDtoNewClassementEnum,
} from 'lib_api/lib/api/gen';

import { UpdateClassementFormValues } from './types';

function useInitialValues(
  dossierAdmin: ExportDonneesDossierAdminFonctionnelDto | null,
): UpdateClassementFormValues {
  const newClassement =
    dossierAdmin?.dossier.body?.traitement?.classement ===
    TraitementResponseDtoClassementEnum.DESTRUCTION
      ? UpdateClassementRequestDtoNewClassementEnum.ALIENATION
      : UpdateClassementRequestDtoNewClassementEnum.DESTRUCTION;

  return useMemo(() => {
    return {
      newClassement,
      comment: null,
    };
  }, [newClassement]);
}

export default useInitialValues;
