import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DossierSummaryResponseDto } from 'lib_api/lib/api/gen/api';
import { Table } from 'components/WrappedComponents';
import {
  ColumnProps,
  FetchDataFunction,
} from 'components/WrappedComponents/Table/types';

import { useFOFilterContext } from 'hooks/FOFilterStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import {
  SortOptions,
  useSearchDossierSummaries,
} from 'hooks/dossiers/useSearchDossierSummaries';
import { buildColumns, buildHeader } from './utils';
import { DashboardFOFilter } from '../types';

import { usePaginationContext } from 'hooks/PaginationStoreContext';
import { useSortContext } from 'hooks/SortStoreContext';

function DashboardTable(): React.ReactElement {
  const { pathname } = useLocation();
  const user = useUserStore();
  const [sort, setSort] = useSortContext();
  const [filter, setfilter, foActionsRequises] = useFOFilterContext();
  const searchDossierSummaries = useSearchDossierSummaries();
  const [pagination, setPagination] = usePaginationContext();

  /**
   * Fetch dossiers based on search filters
   */
  const fetchData: FetchDataFunction<
    DossierSummaryResponseDto,
    DashboardFOFilter,
    SortOptions
  > = useMemo(
    () => (pagination, sorters, filter) =>
      searchDossierSummaries(pagination, sorters, {
        idUniteFO: user.idCorrelationUniteFO
          ? user.idCorrelationUniteFO
          : undefined,
        communeEnlevementList: filter.commune,
        statutDossierEnumList: filter.statut,
        classementList: filter.classement,
        idFourriereList: filter.fourriere,
        idNatureBrancheList: filter.natureMiseEnFourriere,
        dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
        dateRangeBonEnlevement: filter.dateRangeBonEnlevement,
        dateRangeSortieDefinitive: filter.dateRangeSortieDefinitive,
        dateRangeOrdreDestruction: filter.dateRangeOrdreDestruction,
        numeroImmatriculation: filter.search,
        includeDossiersSortis: filter.includeDossiersSortis,
        idMotifSortieList: filter.motifDeSortie,
        actionsRequises: filter.actionsRequises,
        includeDossiersAnonymises: filter.includeDossiersAnonymises,
        listUniteFo: filter.listUniteFo,
      }),
    [searchDossierSummaries, user.idCorrelationUniteFO],
  );

  const columns: ColumnProps<DossierSummaryResponseDto, SortOptions>[] =
    useMemo(
      () => buildColumns(pathname, foActionsRequises),
      [pathname, foActionsRequises],
    );

  return (
    <div className="table-container">
      <Table
        columns={columns}
        header={buildHeader}
        fetchData={fetchData}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setfilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardTable;
