import React from 'react';
import './HelpMessage.less';

export interface HelpMessageProps {
  showMessage: boolean;
  message: string;
}

const HelpMessage: React.FC<HelpMessageProps> = ({
  showMessage,
  message,
}: HelpMessageProps): React.ReactElement => {
  return <>{showMessage && <div className="helpMessage">{message}</div>}</>;
};

export default HelpMessage;
