import { UserDto, SearchUsingGETQueryParams } from 'lib_api/lib/api/gen/api';
import {
  FetchDataFunctionWithJustFilter,
  TablePaginationWithoutTotal,
} from 'components/WrappedComponents/Table/types';

import { useApi } from '../ApiStoreContext';
import { FilterDashboardComptesUtilisateurs } from 'pages/admin-fonctionnel/Comptes/Dashboard/DashboardHeader/types';
import { backAlertMessage } from '../utils/backAlertMessage';

/**
 * Hook to filter search for users based on given filters.
 * It is especially used to ease the mapping between client's parameters
 * and frontend filters, by providing an intermediary Filter type.
 *
 * Return a function that can be used to fetch data for dashboards.
 */
export function useSearchUserDto(): FetchDataFunctionWithJustFilter<
  UserDto,
  FilterDashboardComptesUtilisateurs
> {
  const controllerApi = useApi().AdminFonctionnelControllerApi;

  return async (
    pagination: TablePaginationWithoutTotal,
    filters: Partial<FilterDashboardComptesUtilisateurs>,
  ) => {
    const queryParams: SearchUsingGETQueryParams = {
      ...filters,
      exact: false,
      page: pagination.page,
      pageSize: pagination.pageSize,
    };
    const result = await controllerApi
      .searchUsingGET(queryParams)
      .catch((e: Response) => {
        void backAlertMessage(e);
        throw e;
      });
    return {
      hasNextPage: result.hasNextPage ?? true,
      data: result.users ?? [],
    };
  };
}
