import { useState } from 'react';
import FileSaver from 'file-saver';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

import { RequestStatus } from 'types/RequestStatus';

interface HookResult {
  downloadStatus: RequestStatus | undefined;
  download: () => Promise<void>;
}

function useDownloadClassement(): HookResult {
  const api = useApi().AdminFonctionnelControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>();

  const download = async () => {
    await api
      .downloadCsvTableUsingGET()
      .then((result: Response) => {
        // Create dom link to download file
        return result.blob();
      })
      .then((blob: Blob) => {
        FileSaver.saveAs(blob, 'Décision de classement.xml');
        setRequestStatus(RequestStatus.SUCCESS);
      })
      .catch((errorResponse: Response) => {
        setRequestStatus(RequestStatus.ERROR);
        behaviourOnError(errorResponse, true);
      });
  };

  return {
    downloadStatus: requestStatus,
    download: download,
  };
}

export default useDownloadClassement;
