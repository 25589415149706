import React from 'react';
import { Button as AntdButton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { FilterDashboardGardien } from '../Filters/types';
import { useExportDashboard } from 'hooks/dossiers/useExportDashboard';
import { TableSort } from 'components/WrappedComponents/Table/types';
import { useUserStore } from 'hooks/UserStoreContext';

interface DownloadButtonProps<SortType extends string | undefined> {
  filters: Partial<FilterDashboardGardien>;
  sort: TableSort<SortType>;
}

function DownloadButton<SortType extends string | undefined>({
  filters,
  sort,
}: DownloadButtonProps<SortType>): React.ReactElement {
  const { exportDashboard, inProgress } = useExportDashboard();
  const user = useUserStore();

  return (
    <AntdButton
      className={'create-dossier-button'}
      icon={<DownloadOutlined />}
      type={'default'}
      shape={'round'}
      onClick={() => {
        exportDashboard(sort, {
          includeDossiersSortis: filters.includeDossiersSortis,
          classementList: filters.classement,
          dateRangeMiseAJour: filters.dateRangeMiseAJour,
          dateRangeMiseEnFourriere: filters.dateRangeMiseEnFourriere,
          idNatureBrancheList: filters.natureMiseEnFourriere,
          numeroImmatriculation: filters.searchPlaque,
          idFourriereList: user.idCorrelationFourriere
            ? [user.idCorrelationFourriere]
            : undefined,
          statutDossierEnumList: filters.statut,
          actionsRequises: filters.actionsRequises,
          idMotifSortieList: filters.motifDeSortie,
          includeDossiersAnonymises: filters.includeDossiersAnonymises,
        });
      }}
      loading={inProgress}
    />
  );
}

export default DownloadButton;
