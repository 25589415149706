import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

import { MotifSortieViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { FormInputProps } from 'components/BaseForm/types';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import { MotifSortieFormValues } from 'types/referentiels/MotifSortie';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';

import ModalEdition from '../../Modals/ModalEdition/ModalEdition';

interface ModalEditionMotifSortieProps {
  close: () => void;
  visible: boolean;
  initials: MotifSortieFormValues;
  title: string;
  submit: (values: MotifSortieFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: MotifSortieViolationDtoFormFieldEnum,
    values: MotifSortieFormValues,
  ) => Promise<void>;
}

export function ModalEditionMotifSortie({
  ...props
}: ModalEditionMotifSortieProps): React.ReactElement {
  const baseInputs: FormInputProps<
    MotifSortieFormValues,
    MotifSortieViolationDtoFormFieldEnum
  >[] = [
    {
      field: MotifSortieViolationDtoFormFieldEnum.MOTIF,
      name: 'motif',
      label: 'Motif',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      field: MotifSortieViolationDtoFormFieldEnum.COMMENTAIRE,
      name: 'commentaire',
      label: 'Commentaire',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      field: MotifSortieViolationDtoFormFieldEnum.DELAI_EFFACEMENT,
      name: 'delaiEffacement',
      label: "Délai d'anonymisation (en mois)",
      required: true,
      render: () => <BaseInputNumber placeholder={FormPlaceholders.Number} />,
    },
  ];

  return <ModalEdition {...props} inputs={baseInputs} />;
}
