/**
 * type-safe function to filter undefined or null items from a list
 */
export function filterUndefinedOrNull<T>(
  listToFilter: (T | undefined | null)[],
): T[] {
  return listToFilter.reduce(
    (filteredList: T[], item: T | undefined | null) => {
      if (item !== undefined && item !== null) {
        filteredList.push(item);
      }
      return filteredList;
    },
    [],
  );
}

/**
 * To use for default statement in switch where all cases must be covered
 *
 * ex:
 * switch(myVal) {
 *   case val1:
 *     ...
 *   case val2:
 *     ...
 *   default:
 *     throw new ShouldNotHappen(myVal);
 * }
 * --> this will produce a typing error if a possible value of myVal is not covered by
 *     previous cases.
 */
export class ShouldNotHappen extends Error {
  public constructor(val: never) {
    super(`this should not happen: ${val}`);
  }
}
