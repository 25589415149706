import moment, { Moment } from 'moment';
import {
  UserDto,
  FourriereDto,
  ProfilFourriereRequestDto,
  HorairesDto,
  Horaire,
  GardienFourriereControllerApi,
  ProfilFourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import {
  SemaineHoraire,
  FicheProfilFourriere,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';

function convertHoursToMoment(
  heures: Horaire[] | null | undefined,
): Moment[][] | undefined {
  if (heures && heures.length) {
    const horaire: Moment[][] = [];
    heures.forEach(item => {
      if (item.fin && item.debut) {
        horaire.push([moment(item.debut), moment(item.fin)]);
      }
    });
    return horaire;
  }
  return undefined;
}

function convertHoraireDTOToFicheProfil(
  horaires: HorairesDto | null,
): SemaineHoraire {
  return {
    lundiSurRendezVous: horaires?.lundi?.surRdvUniquement || false,
    lundiHorairesOuvertures: convertHoursToMoment(
      horaires?.lundi?.horairesOuverture,
    ),
    lundiHorairesAppels: convertHoursToMoment(horaires?.lundi?.horairesAppel),

    mardiSurRendezVous: horaires?.mardi?.surRdvUniquement || false,
    mardiHorairesOuvertures: convertHoursToMoment(
      horaires?.mardi?.horairesOuverture,
    ),
    mardiHorairesAppels: convertHoursToMoment(horaires?.mardi?.horairesAppel),

    mercrediSurRendezVous: horaires?.mercredi?.surRdvUniquement || false,
    mercrediHorairesOuvertures: convertHoursToMoment(
      horaires?.mercredi?.horairesOuverture,
    ),
    mercrediHorairesAppels: convertHoursToMoment(
      horaires?.mercredi?.horairesAppel,
    ),

    jeudiSurRendezVous: horaires?.jeudi?.surRdvUniquement || false,
    jeudiHorairesOuvertures: convertHoursToMoment(
      horaires?.jeudi?.horairesOuverture,
    ),
    jeudiHorairesAppels: convertHoursToMoment(horaires?.jeudi?.horairesAppel),

    vendrediSurRendezVous: horaires?.vendredi?.surRdvUniquement || false,
    vendrediHorairesOuvertures: convertHoursToMoment(
      horaires?.vendredi?.horairesOuverture,
    ),
    vendrediHorairesAppels: convertHoursToMoment(
      horaires?.vendredi?.horairesAppel,
    ),

    samediSurRendezVous: horaires?.samedi?.surRdvUniquement || false,
    samediHorairesOuvertures: convertHoursToMoment(
      horaires?.samedi?.horairesOuverture,
    ),
    samediHorairesAppels: convertHoursToMoment(horaires?.samedi?.horairesAppel),

    dimancheSurRendezVous: horaires?.dimanche?.surRdvUniquement || false,
    dimancheHorairesOuvertures: convertHoursToMoment(
      horaires?.dimanche?.horairesOuverture,
    ),
    dimancheHorairesAppels: convertHoursToMoment(
      horaires?.dimanche?.horairesAppel,
    ),
  };
}

export function generateFicheProfil(
  user: UserDto,
  fourriereDto: FourriereDto,
): FicheProfilFourriere {
  return {
    name: user.lastName || undefined,
    surname: user.firstName || undefined,
    socialReason: fourriereDto.raisonSociale,
    agreementNumber: fourriereDto.numeroAgrement || undefined,
    agreementStartDate: fourriereDto.dateAgrement
      ? moment(fourriereDto.dateAgrement)
      : undefined,
    agreementEndDate: fourriereDto.finAgrement
      ? moment(fourriereDto.finAgrement)
      : undefined,
    attachmentAuthority: fourriereDto.autoritesFourrieres.map(
      autorite => autorite.id,
    ),
    parkSize: fourriereDto.nombreEmplacements || undefined,
    streetNumber: fourriereDto.noVoie
      ? parseInt(fourriereDto.noVoie)
      : undefined,
    streetName: fourriereDto.adresse || undefined,
    adressComplement: fourriereDto.complementAdresse || undefined,
    zipCode: ('00000' + fourriereDto.codePostal).substr(-5, 5) || undefined,
    city: fourriereDto.commune || undefined,
    phoneNumber: fourriereDto.telephoneFixe || undefined,
    mobileNumber: fourriereDto.telephoneMobile || undefined,
    email: fourriereDto.adresseMail || undefined,
    gpsLocalization: fourriereDto.coordonneesGPS || undefined,
    bossName: fourriereDto.nomResponsable || undefined,
    bossEmail: fourriereDto.adresseMailResponsable || undefined,
    horaires: convertHoraireDTOToFicheProfil(fourriereDto.horaires),
    repetition: fourriereDto.repetition || undefined,
  };
}

function getInfosString(field: string | undefined | null): string | null {
  if (field !== undefined && field) {
    return field;
  }
  return null;
}

function getInfosArray(field: string[] | undefined): string[] | null {
  if (field !== undefined) {
    return field;
  }
  return null;
}

function getInfosNumber(field: number | undefined): number | null {
  if (field !== undefined) {
    return field;
  }
  return null;
}

function getInfosDate(
  field: Moment | undefined,
  format: string,
): string | null {
  if (field !== undefined) {
    return field.format(format);
  }

  return null;
}

function convertMomentToHours(
  moments: Moment[][] | null | undefined,
): Horaire[] {
  if (moments && moments.length) {
    const hours: Horaire[] = [];
    moments.forEach(item => {
      if (item.length === 2 && item[0] && item[1]) {
        hours.push({
          debut: item[0].format(UNIX_TIMESTAMP_FORMAT),
          fin: item[1].format(UNIX_TIMESTAMP_FORMAT),
        });
      }
    });
    return hours;
  }

  return [];
}

function convertHoraireToDTO(values: SemaineHoraire): HorairesDto {
  return {
    lundi: {
      surRdvUniquement: values.lundiSurRendezVous,
      horairesOuverture: convertMomentToHours(values.lundiHorairesOuvertures),
      horairesAppel: convertMomentToHours(values.lundiHorairesAppels),
    },
    mardi: {
      surRdvUniquement: values.mardiSurRendezVous,
      horairesOuverture: convertMomentToHours(values.mardiHorairesOuvertures),
      horairesAppel: convertMomentToHours(values.mardiHorairesAppels),
    },
    mercredi: {
      surRdvUniquement: values.mercrediSurRendezVous,
      horairesOuverture: convertMomentToHours(
        values.mercrediHorairesOuvertures,
      ),
      horairesAppel: convertMomentToHours(values.mercrediHorairesAppels),
    },
    jeudi: {
      surRdvUniquement: values.jeudiSurRendezVous,
      horairesOuverture: convertMomentToHours(values.jeudiHorairesOuvertures),
      horairesAppel: convertMomentToHours(values.jeudiHorairesAppels),
    },
    vendredi: {
      surRdvUniquement: values.vendrediSurRendezVous,
      horairesOuverture: convertMomentToHours(
        values.vendrediHorairesOuvertures,
      ),
      horairesAppel: convertMomentToHours(values.vendrediHorairesAppels),
    },
    samedi: {
      surRdvUniquement: values.samediSurRendezVous,
      horairesOuverture: convertMomentToHours(values.samediHorairesOuvertures),
      horairesAppel: convertMomentToHours(values.samediHorairesAppels),
    },
    dimanche: {
      surRdvUniquement: values.dimancheSurRendezVous,
      horairesOuverture: convertMomentToHours(
        values.dimancheHorairesOuvertures,
      ),
      horairesAppel: convertMomentToHours(values.dimancheHorairesOuvertures),
    },
  };
}

export function generateFourriereDTO(
  values: FicheProfilFourriere,
): ProfilFourriereRequestDto {
  return {
    repetition: getInfosString(values.repetition),
    raisonSociale: getInfosString(values.socialReason),
    numeroAgrement: getInfosString(values.agreementNumber),
    dateAgrementDebut: getInfosDate(
      values.agreementStartDate,
      UNIX_TIMESTAMP_FORMAT,
    ),
    dateAgrementFin: getInfosDate(
      values.agreementEndDate,
      UNIX_TIMESTAMP_FORMAT,
    ),
    autoriteFourriereRattachement: getInfosArray(values.attachmentAuthority),
    nombrePlacesParc: getInfosNumber(values.parkSize),
    numeroVoie: getInfosNumber(values.streetNumber)?.toString() || null,
    nomVoie: getInfosString(values.streetName),
    complementAdresse: getInfosString(values.adressComplement),
    codePostal: values.zipCode || null,
    commune: getInfosString(values.city),
    telephoneFixe: getInfosString(values.phoneNumber),
    telephoneMobile: getInfosString(values.mobileNumber),
    adresseMail: getInfosString(values.email),
    coordonneesGps: getInfosString(values.gpsLocalization),
    nomResponsable: getInfosString(values.bossName),
    mailResponsable: getInfosString(values.bossEmail),
    horaires: convertHoraireToDTO(values.horaires),
  };
}

export async function validateFicheProfilGf(
  gfController: GardienFourriereControllerApi,
  field: ProfilFourriereViolationDtoFormFieldEnum,
  idCorrelationFourriere: string,
  requestDto: ProfilFourriereRequestDto,
): Promise<void> {
  try {
    const response = await gfController.validateFourriereInfoFieldUsingPOST(
      field,
      idCorrelationFourriere,
      requestDto,
    );
    if (response.violationDtos) {
      const fieldViolations = response.violationDtos.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }

  return Promise.resolve();
}
