import { useState } from 'react';
import FileSaver from 'file-saver';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

import { ReferentielEnum } from 'types/Referentiel';
import { SupportedFile } from 'types/enums/SupportedFile';
import { RequestStatus, RequestStatusByName } from 'types/RequestStatus';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';

import {
  ReferentielEnumDtoReferentialEnumCSVEnum,
  ReferentielEnumDtoReferentialEnumJSONEnum,
} from 'lib_api/lib/api/gen';

type ExportFunction<T extends ReferentielEnum> = (
  referentiel: T,
  name: string,
) => Promise<void>;

interface HookResult {
  status: RequestStatusByName;
  downloadCsv: ExportFunction<ReferentielEnumDtoReferentialEnumCSVEnum>;
  downloadJson: ExportFunction<ReferentielEnumDtoReferentialEnumJSONEnum>;
}

function useDownloadReferentiel(): HookResult {
  const api = useApi().ReferentielControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const [requestStatus, setRequetStatus] = useState<RequestStatusByName>({});

  const updateStatus = (referentiel: string, newStatus: RequestStatus) => {
    setRequetStatus((prevState: RequestStatusByName) => {
      return {
        ...prevState,
        [referentiel]: newStatus,
      };
    });
  };

  function download<T extends ReferentielEnum>(
    extension: string,
    downloadFunction: (referentiel: T) => Promise<Response>,
  ) {
    return async (referentiel: T, name: string) => {
      await downloadFunction(referentiel)
        .then((result: Response) => {
          // Create dom link to download file
          return result.blob();
        })
        .then((blob: Blob) => {
          FileSaver.saveAs(blob, name + extension);
          updateStatus(name, RequestStatus.SUCCESS);
        })
        .catch((errorResponse: Response) => {
          updateStatus(name, RequestStatus.ERROR);
          behaviourOnError(errorResponse, true);
        });
    };
  }

  const downloadCsv = download<ReferentielEnumDtoReferentialEnumCSVEnum>(
    supportedFilesDetails[SupportedFile.CSV].extension,
    (referentiel: ReferentielEnumDtoReferentialEnumCSVEnum) =>
      api.exportCSVReferentielUsingGET(referentiel),
  );
  const downloadJson = download<ReferentielEnumDtoReferentialEnumJSONEnum>(
    supportedFilesDetails[SupportedFile.JSON].extension,
    (referentiel: ReferentielEnumDtoReferentialEnumJSONEnum) =>
      api.exportJSONReferentielUsingGET(referentiel),
  );

  return {
    status: requestStatus,
    downloadCsv: downloadCsv,
    downloadJson: downloadJson,
  };
}

export default useDownloadReferentiel;
