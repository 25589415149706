import React, { useCallback } from 'react';
import {
  ConditionsReactivateTypeAutoriteFourriereDto,
  ReferentielSearchResultDtoTypeAutoriteFourriereDto,
  ReferentielViolationsDto,
  TypeAutoriteFourriereDto,
  TypeAutoriteFourriereRequestDto,
  TypeAutoriteFourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
} from 'components/WrappedComponents/Table/types';
import { useApi } from 'hooks/ApiStoreContext';
import { useBoolean } from 'utils/genericUtils';
import { TypeAfFormValues } from 'types/referentiels/TypeAf';
import { FilterDashboardTypeAf, SubmitTypeAf, ValidateTypeAf } from './types';
import DashboardHeader from './DashboardHeader/DashboardHeader';

export const buildHeader: TableHeaderFunctionWithoutSort<
  TypeAutoriteFourriereDto,
  FilterDashboardTypeAf
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData?.total ?? 0}
    filter={filter}
    setFilter={setFilter}
  />
);

export function useSearchTypeAf(
  filter: FilterDashboardTypeAf,
): [
  () => Promise<ReferentielSearchResultDtoTypeAutoriteFourriereDto>,
  (
    result: ReferentielSearchResultDtoTypeAutoriteFourriereDto,
  ) => FetchDataResult<TypeAutoriteFourriereDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  return [
    () => {
      return controller.searchTypeAfUsingSpecsUsingGET({
        filters: {
          ...filter,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

function buildRequestDto(
  data: TypeAfFormValues,
): TypeAutoriteFourriereRequestDto {
  return {
    type: data.type?.trim() || null,
    displayedText: data.displayedText || null,
    codeTypologieNiveau1: data.codeTypologieNiveau1 || null,
    codeTypologieNiveau2: data.codeTypologieNiveau2 || null,
  };
}

export function useSubmitCreateTypeAf(
  refreshDashboard: () => void,
  close: () => void,
): SubmitTypeAf {
  const progress = useBoolean(false);

  const controller = useApi().AdminFonctionnelControllerApi;

  const submit = useCallback(
    (values: TypeAfFormValues) => {
      const requestDto: TypeAutoriteFourriereRequestDto =
        buildRequestDto(values);

      controller
        .createTypeAfUsingPOST(requestDto)
        .then(close)
        .then(refreshDashboard)
        .finally(progress.setIsFalse);
    },
    [controller, progress.setIsFalse, refreshDashboard, close],
  );

  return {
    inProgress: progress.value,
    submit,
  };
}

export function useValidateCreateTypeAf(): ValidateTypeAf {
  const controller = useApi().AdminFonctionnelControllerApi;
  const validate = useCallback(
    async (
      field: TypeAutoriteFourriereViolationDtoFormFieldEnum,
      values: TypeAfFormValues,
    ) => {
      const requestDto: TypeAutoriteFourriereRequestDto =
        buildRequestDto(values);
      const response: ReferentielViolationsDto =
        await controller.validateCreateTypeAFFieldUsingPOST(field, requestDto);

      const violations = response.typeAutoriteFourriereViolationDto ?? [];
      return violations.length === 0
        ? Promise.resolve()
        : Promise.reject(violations[0]);
    },
    [controller],
  );

  return { validate };
}

export function useSubmitUpdateTypeAf(
  idCorrelation: string,
  refreshDashboard: () => void,
  close: () => void,
): SubmitTypeAf {
  const progress = useBoolean(false);

  const controller = useApi().AdminFonctionnelControllerApi;

  const submit = useCallback(
    (values: TypeAfFormValues) => {
      const requestDto: TypeAutoriteFourriereRequestDto =
        buildRequestDto(values);

      controller
        .updateTypeAfUsingPUT(idCorrelation, requestDto)
        .then(close)
        .then(refreshDashboard)
        .finally(progress.setIsFalse);
    },
    [controller, progress.setIsFalse, refreshDashboard, close, idCorrelation],
  );

  return {
    inProgress: progress.value,
    submit,
  };
}

export function useValidateUpdateTypeAf(idCorrelation: string): ValidateTypeAf {
  const controller = useApi().AdminFonctionnelControllerApi;
  const validate = useCallback(
    async (
      field: TypeAutoriteFourriereViolationDtoFormFieldEnum,
      values: TypeAfFormValues,
    ) => {
      const requestDto: TypeAutoriteFourriereRequestDto =
        buildRequestDto(values);
      const response: ReferentielViolationsDto =
        await controller.validateUpdateTypeAfFieldUsingPOST(
          field,
          idCorrelation,
          requestDto,
        );

      const violations = response.typeAutoriteFourriereViolationDto ?? [];
      return violations.length === 0
        ? Promise.resolve()
        : Promise.reject(violations[0]);
    },
    [controller, idCorrelation],
  );

  return { validate };
}

export const buildFormValues = (
  typeAf: TypeAutoriteFourriereDto,
): TypeAfFormValues => {
  return {
    type: typeAf?.type ?? undefined,
    displayedText: typeAf?.displayedText ?? undefined,
    codeTypologieNiveau1: typeAf?.hermesCodeTypologieNiveau1 ?? undefined,
    codeTypologieNiveau2: typeAf?.hermesCodeTypologieNiveau2 ?? undefined,
  };
};

export function useChangeValidityTypeAf(): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (
    idCorrelation: string,
  ) => Promise<ConditionsReactivateTypeAutoriteFourriereDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateTypeAfUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateTypeAfUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (
      idCorrelation: string,
    ): Promise<ConditionsReactivateTypeAutoriteFourriereDto> => {
      return controller.conditionReactivationTypeAfUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ];
}
