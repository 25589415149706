import React from 'react';
import { Typography as AntdTypo } from 'antd';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { FilterDashboardUniteFo } from 'types/referentiels/UniteFo';
import FormFooter from './FormFooter/FormFooter';
import BaseForm from 'components/BaseForm/BaseForm';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';

interface FilterFormProps {
  filter: FilterDashboardUniteFo;
  setFilter: (value: FilterDashboardUniteFo) => void;
}

function FilterFormUniteFo({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  return (
    <>
      <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
      <BaseForm
        initialValues={filter}
        inputs={[
          {
            name: 'type',
            label: "Type d'unité",
            render: () => {
              return (
                <BaseSelect
                  options={[
                    {
                      value: 'GN',
                      displayValue: 'GN',
                    },
                    {
                      value: 'PN',
                      displayValue: 'PN',
                    },
                    {
                      value: 'PP',
                      displayValue: 'PP',
                    },
                    {
                      value: 'PM',
                      displayValue: 'PM',
                    },
                  ]}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.displayValue}
                  allowClear
                />
              );
            },
          },
          {
            name: 'idCorrelation',
            label: 'Id de corrélation',
          },
          {
            name: 'code',
            label: 'Code',
            render: () => {
              return (
                <BaseSelectWithSearch
                  useSearchEntities={useAsyncSearchUniteFo(
                    AutocompleteUniteFOResultDtoFieldEnum.CODE,
                  )}
                  getOptionLabel={(unite: UniteFOResponseDto) => unite.code}
                  getOptionValue={(unite: UniteFOResponseDto) => unite.code}
                  allowClear
                />
              );
            },
          },
          {
            name: 'abreviation',
            label: 'Abréviation',
            render: () => {
              return (
                <BaseSelectWithSearch
                  useSearchEntities={useAsyncSearchUniteFo(
                    AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                  )}
                  getOptionLabel={(unite: UniteFOResponseDto) =>
                    unite.abreviation
                  }
                  getOptionValue={(unite: UniteFOResponseDto) =>
                    unite.abreviation
                  }
                  allowClear
                />
              );
            },
          },
          {
            name: 'codePostal',
            label: 'Code postal',
          },
          {
            name: 'unite',
            label: "Libellé de l'unité",
            render: () => {
              return (
                <BaseSelectWithSearch
                  useSearchEntities={useAsyncSearchUniteFo(
                    AutocompleteUniteFOResultDtoFieldEnum.UNITE,
                  )}
                  getOptionLabel={(unite: UniteFOResponseDto) => unite.unite}
                  getOptionValue={(unite: UniteFOResponseDto) => unite.unite}
                  allowClear
                />
              );
            },
          },
          {
            name: 'rpsiStatus',
            label: "Etat de l'unité",
            render: () => {
              return (
                <BaseSelect
                  options={[
                    {
                      value: 'NOUVELLE_UNITE',
                      displayValue: 'Nouvellement créée',
                    },
                    {
                      value: 'MISE_A_JOUR_ERREUR_VALIDATION',
                      displayValue: 'Mise à jour bloquée',
                    },
                    {
                      value: 'ECHEC_DESACTIVATION_DOSSIER_ATTACHE',
                      displayValue: 'Invalidation bloquée (dossiers rattachés)',
                    },
                    {
                      value: 'ECHEC_DESACTIVATION_UNITE_PARENT',
                      displayValue: 'Invalidation bloquée (unité parent)',
                    },
                  ]}
                  getOptionValue={option => option.value}
                  getOptionLabel={option => option.displayValue}
                  allowClear
                />
              );
            },
          },
        ]}
        onSubmit={() => Promise.resolve()}
        renderButtonAction={() => {
          return <FormFooter setFilter={setFilter} />;
        }}
      />
    </>
  );
}

export default FilterFormUniteFo;
