import React, { useState } from 'react';
import { Button as AntdButton, Divider as AntdDivider } from 'antd';

import CoordonneesProprietaireForm from 'components/FicheDescriptiveDisplay/CoordonneesProprietaire/CoordonneesProprietaireForm';
import ProfilFormEditButton from 'components/ProfilFormContainer/ProfilFormEditButton';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';

import { useFetchPdf } from './utils';
import NotificationCoordonneesUniteFoForm from './NotificationCoordonneesUniteFoForm';
import EditableFormContainer from './EditableFormContainer';

import './EditionNotificationForm.less';

interface EditionNotificationFormProps {
  close: () => void;
}

const EditionNotificationForm = ({
  close,
}: EditionNotificationFormProps): React.ReactElement => {
  const notificationController = useApi().ForceOrdreControllerApi;
  const [isDownloading, fetchPdf] = useFetchPdf();
  const [dossier] = useDossierContext();

  const [isCoordonneesUniteFoEditable, setCoordonneesUniteFoEditable] =
    useState(false);
  const [
    isCoordonneesProprietaireEditable,
    setCoordonneesProprietaireEditable,
  ] = useState(false);

  // a sent notification cannot be edited
  const isNotificationSent =
    !!dossier?.body?.traitement?.notificationManuelle?.dateEnvoi;
  const uniteFoEditBtn =
    isNotificationSent || isCoordonneesUniteFoEditable ? undefined : (
      <ProfilFormEditButton
        setEdit={() => setCoordonneesUniteFoEditable(true)}
      />
    );
  const proprietaireEditBtn =
    isNotificationSent || isCoordonneesProprietaireEditable ? undefined : (
      <ProfilFormEditButton
        setEdit={() => setCoordonneesProprietaireEditable(true)}
      />
    );

  return (
    <>
      <EditableFormContainer button={uniteFoEditBtn}>
        <NotificationCoordonneesUniteFoForm
          onCancel={() => setCoordonneesUniteFoEditable(false)}
          editable={isCoordonneesUniteFoEditable}
        />
      </EditableFormContainer>

      <AntdDivider />

      <EditableFormContainer button={proprietaireEditBtn}>
        <CoordonneesProprietaireForm
          controller={notificationController}
          close={() => setCoordonneesProprietaireEditable(false)}
          editable={isCoordonneesProprietaireEditable}
        />
      </EditableFormContainer>

      {!isCoordonneesUniteFoEditable && !isCoordonneesProprietaireEditable && (
        <div className="ModalFooter">
          <AntdButton onClick={close}>Annuler</AntdButton>
          <AntdButton
            type={'primary'}
            onClick={() => {
              fetchPdf();
              close();
            }}
            loading={isDownloading}
            className={'ValidateFormButton'}
          >
            Générer la notification
          </AntdButton>
        </div>
      )}
    </>
  );
};

export default EditionNotificationForm;
