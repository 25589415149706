import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';
import { useState } from 'react';
import FileSaver from 'file-saver';
import { RequestStatus } from 'types/RequestStatus';
import { extractFileNameFromContentDispositionOrError } from '../../utils/requestUtils';

type ImportFunction = (file: Blob) => void;

/**
 * Hook to import DNID updates.
 */
export function useImportHermesContournement(): [
  ImportFunction,
  RequestStatus | undefined,
] {
  const hermesComptes = useApi().AdminFonctionnelControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const [status, setStatus] = useState<RequestStatus>();

  return [
    async (file: Blob) => {
      setStatus(RequestStatus.PENDING);
      await hermesComptes
        .contournementCsvImportUsingPOST(file)
        .then((result: Response) => {
          const fileName = extractFileNameFromContentDispositionOrError(result);

          setStatus(RequestStatus.SUCCESS);
          // Create dom link to download file
          void result.blob().then((blob: Blob) => {
            FileSaver.saveAs(blob, fileName);
          });
        })
        .catch((errorResponse: Response) => {
          setStatus(RequestStatus.ERROR);
          behaviourOnError(errorResponse, true);
        });
    },
    status,
  ];
}
