import React from 'react';
import useExportFile from 'hooks/useExportFile';
import UploadCard from 'components/Card/UploadCard';
import Downloader from 'components/WrappedComponents/Downloader/Downloader';
import { useApi } from 'hooks/ApiStoreContext';

interface FicheDescriptiveExportProps {
  label: string;
}

export default function FicheDescriptiveExport({
  label,
}: FicheDescriptiveExportProps): React.ReactElement {
  const { FicheDescriptiveControllerApi: api } = useApi();
  const { downloadStatus, download } = useExportFile(() =>
    api.downloadFicheDescriptiveUsingGET(),
  );

  return (
    <UploadCard status={downloadStatus}>
      <Downloader title={label} download={download} />
    </UploadCard>
  );
}
