import { EnergieDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';
import { nullSort } from '../utils/StringUtils';

export function fetchEnergie(
  refController: ReferentielControllerApi,
): Promise<EnergieDto[]> {
  return refController.getAllEnergieUsingGET().then(response => {
    return response.referenceDtoList
      .filter(energie => !!energie.libelle)
      .sort((a, b) => {
        return nullSort(a.libelle, b.libelle);
      });
  });
}
