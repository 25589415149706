import {
  EtatDto,
  EtatDtoEtatEnum,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

import { nullSort } from 'utils/StringUtils';

export function fetchEtatDetails(
  refController: ReferentielControllerApi,
  etat: EtatDtoEtatEnum | undefined,
): Promise<EtatDto[]> {
  if (!etat) {
    return Promise.resolve([]);
  }
  return refController.getDetailFromEtatUsingGET(etat).then(response => {
    return response.referenceDtoList
      .filter(etat => !!etat.libelleCourt) // do not display default etats
      .sort((a, b) => nullSort(a.libelleCourt, b.libelleCourt));
  });
}

export function fetchEtatById(
  refController: ReferentielControllerApi,
  idEtat: string,
): Promise<EtatDto> {
  return refController.getEtatByIdUsingGET(idEtat);
}
