import { useState, useEffect } from 'react';

import { ReferenceDtoStatutDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type StatutConsultableType = ReferenceDtoStatutDto | undefined;

export const useStatutConsultable = (callApi = true): StatutConsultableType => {
  const behaviourOnError = useHandleBackErrors();
  const [statuts, setStatuts] = useState<StatutConsultableType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (callApi && statuts === undefined) {
      referentielControllerApi
        .getStatutsConsultablesUsingGET()
        .then(fetchedRef => {
          setStatuts(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, callApi, statuts]);
  return statuts;
};
