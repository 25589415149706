import { ReferentielSearchResultDtoAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { searchAutoriteFourriereUsingSpecs } from 'search/searchAutoriteFourriere';
import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import { AutoriteFourriereSortOptions } from 'enums/referentiels/AutoriteFourriere';
import { useApi } from 'hooks/ApiStoreContext';

function useSearchAutoriteFourriere(): (
  filters: FilterDashboardAutoriteFourriere,
  sorts: TableSort<AutoriteFourriereSortOptions>,
  pagination: TablePagination,
) => Promise<ReferentielSearchResultDtoAutoriteFourriereDto> {
  const controller = useApi().ReferentielControllerApi;

  return (filters, sorts, pagination) => {
    return searchAutoriteFourriereUsingSpecs(
      controller,
      filters,
      sorts,
      pagination,
    );
  };
}

export default useSearchAutoriteFourriere;
