import React from 'react';

import { InformationMessageViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { Checkbox } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { useApi } from 'hooks/ApiStoreContext';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import {
  generateRequestDtoFromFormData,
  validateInformationMessageRequest,
} from '../utils';

function CheckboxActif({
  values,
}: FieldProps<InformationMessageFormValues>): React.ReactElement {
  const controller = useApi().ReferentielControllerApi;
  const key: keyof InformationMessageFormValues = 'active';

  return (
    <Checkbox
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Message activé',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateRequestDtoFromFormData({
                  ...values,
                  active: value || undefined,
                });

                return await validateInformationMessageRequest(
                  controller,
                  requestObject,
                  InformationMessageViolationDtoFormFieldEnum.VALID,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      checkboxProps={{ disabled: false }}
    />
  );
}

export default CheckboxActif;
