import { useCallback, useEffect, useState } from 'react';
import { EtatConventionnementResponseDto } from 'lib_api/lib/api/gen';
import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

interface FetchEtatConventionnementResult {
  etat: EtatConventionnementResponseDto | null;
  fetchEtat: () => Promise<void>;
  fetchInProgress: boolean;
  errorOccured: boolean;
}

export function useFetchEtatConventionnement(
  insee: string | null,
): FetchEtatConventionnementResult {
  const [etat, setEtat] = useState<EtatConventionnementResponseDto | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi } = useApi();
  const behaviourOnError = useHandleBackErrors();

  const fetchEtat = useCallback(async () => {
    setEtat(null);
    setErrorOccured(false);
    if (insee === null) {
      return;
    }
    setLoading(true);
    await AdminFonctionnelControllerApi.etatUsingGET({ insee })
      .then(etat => {
        setEtat(etat);
      })
      .catch(errorResponse => {
        setErrorOccured(true);
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [AdminFonctionnelControllerApi, insee, behaviourOnError]);

  useEffect(() => {
    void fetchEtat();
  }, [AdminFonctionnelControllerApi, insee, fetchEtat]);

  return {
    etat: etat,
    fetchEtat: fetchEtat,
    fetchInProgress: loading,
    errorOccured: errorOccured,
  };
}
