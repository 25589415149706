import React, { useEffect, useState } from 'react';

import SortableTable from './SortableTable';
import Pagination from './Pagination/Pagination';
import {
  FetchDataResult,
  TablePagination,
  TableProps,
  TableSelect,
} from './types';
import { useDelay } from '../../../hooks/useDelay';

/**
 * Table component to display a large amount of data. Data are not fetch all at once, but
 * page by page depending on pagination state, sort order and filters applied. This
 * component provide a SimpleTable, along with filter, header and pagination components.
 *
 * @param columns - Paramters to represent columns used in this Table. Each column is
 *        associated with a single attribute of fetched items, and may provide a button
 *        for sorting functionnality.
 * @param fetchData - Function called to fetch data (either sync or async) based on
 *        current pagination state, sorting order and filter applied. This function is
 *        supposed to return a list of fetched object corresponding to those filters.
 *        This function also return the total number of items corresponding to those
 *        filters, regardless of pagination.
 * @param canSelectItems - When true, add checkboxes to select items.
 * @param buildItemKey - Function to build a unique key for each item. Required when
 *        using item seection.
 * @param onSelectItems - When useSelectItems is true, this callback is triggered when
 *        selected items change.
 * @param header - Callback to generate a component used ahead of Table. This component
 *        may be use to set filters, or to display some data (ex: "100 items found").
 * @param pageSizeOptions - When defined, Pagination sub component will propose a
 *        PageSizeChanger with those options.
 * @param disabled
 * @param antdTableProps - To use other props defined by Antd library on Table component
 *        (/!\ may interfere with props already defined by this component).
 * @param paginationProps - To use other props defined by Antd library on Pagination
 *        component (/!\ may interfere with props already defined by this component).
 */
function Table<
  DataType extends object,
  FilterType,
  SortType extends string | undefined,
>({
  // Table - general
  columns,
  fetchData,
  // Table - select items
  canSelectItems,
  buildItemKey,
  onSelectItems,
  // Header
  header,
  // Pagination
  pageSizeOptions,
  pagination,
  setPagination,
  // Other
  disabled,
  antdTableProps,
  antPaginationProps,
  //  filters
  filter,
  setFilter,

  // Sorts
  sort,
  setSort,
}: TableProps<DataType, FilterType, SortType>): React.ReactElement {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [fetchedData, setFetchedData] = useState<
    FetchDataResult<DataType> | undefined
  >(undefined);
  const [isOnError, setIsOnError] = useState<boolean>(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);
  const [selected, setSelected] = useState<TableSelect<DataType>>([]);

  function innerSetSelected(newSelected: TableSelect<DataType>): void {
    setSelected(newSelected);
    onSelectItems && onSelectItems(newSelected);
  }

  function innerSetPagination(newPagination: TablePagination): void {
    setPagination(newPagination);
    // Reset selected items
    innerSetSelected([]);
  }

  // Go back to pagination when filter is update
  function innerSetFilter(filter: Partial<FilterType>): void {
    setPagination({ ...pagination, page: 1 });
    setFilter(filter);
  }

  const [fetchFunction, cancelTimer] = useDelay(
    (timerID, isOutDated?: boolean) => {
      const loadNewData = async function loadNewData(): Promise<void> {
        try {
          if (fetchData !== undefined) {
            if (timerID !== null) {
              setIsLoadingData(true);
              const data = await fetchData(pagination, sort, filter);
              if (!isOutDated) {
                setFetchedData(data);
                setIsOnError(false);
                setIsLoadingData(false);
              }
            }
          }
        } catch (e) {
          if (!isOutDated) {
            setFetchedData({ data: [], total: 0 });
            setIsOnError(true);
            setIsLoadingData(false);
          }
        }
      };
      void loadNewData();
    },
    300,
  );

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutDated = false;
    fetchFunction(isOutDated);
    return () => {
      cancelTimer();
      isOutDated = true;
    };
  }, [pagination, sort, filter, triggerUpdate, fetchData]);

  return (
    <>
      {header && header(fetchedData, selected, filter, innerSetFilter, sort)}
      <SortableTable
        data={fetchedData !== undefined ? fetchedData.data : []}
        columnsProps={columns}
        isLoading={isLoadingData}
        disabled={disabled}
        reload={() => setTriggerUpdate(val => ++val)}
        isOnError={isOnError}
        sortItems={{
          sorted: sort,
          setSort: setSort,
        }}
        selectItems={
          canSelectItems
            ? {
                selected: selected,
                onSelect: innerSetSelected,
              }
            : undefined
        }
        buildItemKey={buildItemKey}
        antdTableProps={{
          ...antdTableProps,
          pagination: false,
        }} // We use our custom Pagination component instead
      />

      <Pagination
        pagination={{
          paginated: pagination,
          setPagination: innerSetPagination,
        }}
        total={fetchedData !== undefined ? fetchedData.total : 0}
        pageSizeOptions={pageSizeOptions}
        disabled={disabled}
        antdPaginationProps={antPaginationProps}
      />
    </>
  );
}

export default Table;
