import { useRedirect } from 'components/RedirectHandler/RedirectHandler';
import { backAlertMessage } from './backAlertMessage';
import { useCallback } from 'react';

export const useHandleBackErrors = (): ((
  error: Response,
  display?: boolean, // should the whole error message be displayed in the client ? false by default
) => void) => {
  const { setRedirectUrl } = useRedirect();

  return useCallback(
    (error: Response, display = false): void => {
      if (error.status === 404) {
        setRedirectUrl('/404');
      } else {
        void backAlertMessage(error, display);
      }
    },
    [setRedirectUrl],
  );
};
