import React from 'react';
import { Divider } from 'antd';

import LeftSideDetailInfo from './components/LeftSideInfoDetail';
import RightSideDetailInfo from './components/RightSideInfoDetail';

import './MainInfoBody.less';

const MainInfoBody = (): React.ReactElement => {
  return (
    <div className="MainInfoBody">
      <div className="DetailInfo">
        <LeftSideDetailInfo />
      </div>
      <Divider className="Divider" type="vertical" />
      <div className="DetailInfo">
        <RightSideDetailInfo />
      </div>
    </div>
  );
};

export default MainInfoBody;
