import React from 'react';
import DashboardTable from './DashboardTable';
import './Dashboard.less';

const DashBoard: React.FC = () => {
  return (
    <div className="dashboard-container">
      <DashboardTable />
    </div>
  );
};

export default DashBoard;
