import {
  ReferentielSearchResultDtoModeleDto,
  SearchModeleUsingSpecsUsingGETQueryParams,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { searchModele } from 'search/searchModele';

export function useSearchModele(): (
  filters: SearchModeleUsingSpecsUsingGETQueryParams,
) => Promise<ReferentielSearchResultDtoModeleDto> {
  const controller = useApi().ReferentielControllerApi;

  return filters => {
    return searchModele(controller, filters);
  };
}
