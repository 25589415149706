import React from 'react';

import './EditableFormContainer.less';

interface Props {
  button?: React.ReactElement;
  children: React.ReactNode;
}

function EditableFormContainer({
  button,
  children,
}: Props): React.ReactElement {
  return (
    <div className="editable-form-container">
      <div className="profile-form-header">{button}</div>
      {children}
    </div>
  );
}

export default EditableFormContainer;
