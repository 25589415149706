import React, { useState, useEffect } from 'react';
import { Label } from 'components/WrappedComponents';

import { useRefAutoriteFourriere } from 'hooks/referentiels/useRefAutoriteFourriere';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { FicheProfilFourriere } from 'types/FicheProfil/FicheProfilGardienFourriere';
import { useRequiredUserActionContext } from 'hooks/RequiredUserActionContext';
import { ProfileConfirmation } from 'components/ProfileConfirmation/ProfileConfirmation';
import Spinner from 'components/WrappedComponents/Spinner';
import FicheProfilFourriereForm from './Components/FicheProfilFourriere';
import FicheProfilCoordonnesForm from './Components/FicheProfilCoordonnees';
import { generateFicheProfil } from './utils';

import './PageProfil.less';

const PageProfil: React.FC = () => {
  const GardienFourriereControllerApi = useApi().GardienFourriereControllerApi;
  const user = useUserStore();
  const refAutoriteFourriere = useRefAutoriteFourriere();
  const [data, setData] = useState<FicheProfilFourriere | null>(null);
  const { requiredUserActions, updateRequiredUserActions } =
    useRequiredUserActionContext();

  useEffect(() => {
    let cancel = false;
    if (data === null && user.idCorrelationFourriere) {
      GardienFourriereControllerApi.getFourriereInfoUsingGET(
        user.idCorrelationFourriere,
      )
        .then(fourriere => {
          if (cancel) {
            return;
          }
          const datas = generateFicheProfil(user, fourriere);
          setData(datas);
        })
        .catch(() => {
          if (cancel) {
            return;
          }
        });
    }

    return () => {
      cancel = true;
    };
  }, [GardienFourriereControllerApi, user, data]);

  if (data === null || refAutoriteFourriere === undefined) {
    return <Spinner />;
  }

  return (
    <>
      <div className="ProfileForm">
        <div className="title">Mes informations</div>
        <Label label="Nom" value={data.name} />
        <Label label="Prénom" value={data.surname} />
      </div>

      <FicheProfilFourriereForm
        values={data}
        refAutoriteFourriere={refAutoriteFourriere}
        mustConfirmAccount={requiredUserActions?.mustConfirmAccount}
        updateRequiredActions={updateRequiredUserActions}
      />

      <FicheProfilCoordonnesForm
        values={data}
        mustConfirmAccount={requiredUserActions?.mustConfirmAccount}
        updateRequiredActions={updateRequiredUserActions}
      />

      {(requiredUserActions?.idFourriereToConfirm ||
        requiredUserActions?.mustConfirmAccount) && (
        <ProfileConfirmation
          username={user.username}
          requiredActions={requiredUserActions}
          updateRequiredActions={updateRequiredUserActions}
        />
      )}
    </>
  );
};

export default PageProfil;
