export const PAGINATION_DEFAULT = {
  page: 1,
  pageSize: 10,
};

export const PAGINATION_WITHOUT_TOTAL_DEFAULT = {
  page: 1,
  pageSize: 10,
  hasNextPage: false,
};
