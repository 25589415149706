import React from 'react';
import {
  FaqDomainViolationDtoFormFieldEnum,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { FaqDomainFormValues } from '../../types';
import { InputName } from '../Fields';
import {
  convertFaqDomainFormValueToFaqDomainRequestDto,
  createFaqDomainInitialValues,
} from '../../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { FormModal } from 'components/WrappedComponents';

interface ModalCreationProps {
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalCreation({
  visible,
  close,
  refreshDashboard,
}: ModalCreationProps): React.ReactElement {
  const refController = useApi().AdminFonctionnelControllerApi;

  const validateFunction: (
    values: FaqDomainFormValues,
    key: keyof FaqDomainFormValues,
    field: FaqDomainViolationDtoFormFieldEnum,
    value: unknown,
  ) => Promise<ReferentielViolationsDto> = (values, key, field, value) => {
    const request = convertFaqDomainFormValueToFaqDomainRequestDto({
      ...values,
    });
    return refController.validateFaqDomainFieldUsingPOST(field, request);
  };

  return (
    <FormModal
      modalTitle="Créer un nouveau domaine"
      initialValues={createFaqDomainInitialValues}
      controllerValidate={(values: FaqDomainFormValues) => {
        return refController.validateFaqDomainUsingPOST(
          convertFaqDomainFormValueToFaqDomainRequestDto(values),
        );
      }}
      controllerValidateField={validateFunction}
      validationKey="faqDomainViolationsDto"
      controllerSubmit={(values: FaqDomainFormValues) => {
        return refController.createFaqDomainUsingPOST(
          convertFaqDomainFormValueToFaqDomainRequestDto(values),
        );
      }}
      visible={visible}
      close={close}
      refreshDashboard={refreshDashboard}
    >
      {(values, validateField): React.ReactElement => {
        return (
          <>
            <InputName values={values} validateField={validateField} />
          </>
        );
      }}
    </FormModal>
  );
}

export default ModalCreation;
