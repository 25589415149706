import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Modal as AntdModal } from 'antd';

import {
  EtatDto,
  EtatDtoEtatEnum,
  MotifMefDto,
  ReferenceDtoEtatDto,
  ReferentielControllerApi,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { FieldSummaryDisplay } from './FieldSummaryDisplay';
import { shouldShowDetail, stripNatureLibelle } from './utils';
import { fetchUniteFo } from 'search/searchUniteFo';
import { fetchMotifMefById } from 'search/searchMotifMef';
import { fetchEtatById } from 'search/searchEtatDetails';
import { FicheDescriptiveFormValues } from '../types';
import { etatMapping } from 'utils/enumData';

import './SummaryConfirm.less';

interface SummaryConfirmProps {
  data: FicheDescriptiveFormValues;
  etatDetails: ReferenceDtoEtatDto | undefined;
  refController: ReferentielControllerApi;
}

const SummaryConfirm = ({
  data,
  etatDetails,
  refController,
}: SummaryConfirmProps): React.ReactElement => {
  const [uniteFo, setUniteFo] = useState<UniteFOResponseDto | null>(null);
  const [motifMefDto, setMotifMefDto] = useState<MotifMefDto | null>(null);
  const [etatDto, setEtatDto] = useState<EtatDto | null>(null);

  useEffect(() => {
    if (!data.ficheInfraction.uniteFo?.option) {
      setUniteFo(null);
    } else {
      fetchUniteFo(
        refController,
        data.ficheInfraction.uniteFo.option.idCorrelation,
      )
        .then(setUniteFo)
        .catch(() => {
          //Do nothing
        });
    }
  }, [data.ficheInfraction.uniteFo]);

  useEffect(() => {
    if (!data.ficheInfraction.idMotifMef) {
      setMotifMefDto(null);
    } else {
      fetchMotifMefById(refController, data.ficheInfraction.idMotifMef)
        .then(setMotifMefDto)
        .catch(() => {
          // Do nothing
        });
    }
  }, [data.ficheInfraction.idMotifMef]);

  useEffect(() => {
    if (!data.ficheVehicule.precisionEtat) {
      setEtatDto(null);
    } else {
      fetchEtatById(refController, data.ficheVehicule.precisionEtat)
        .then(setEtatDto)
        .catch(() => {
          //Do nothing
        });
    }
  }, [data.ficheVehicule.precisionEtat]);

  const { abreviation, unite, ville, codePostal } = uniteFo || {};
  const { detail } = etatDto || {};
  const { numero, vin, etat, plusieursPlaques, numeroImmatriculationVisible } =
    data.ficheVehicule;
  const { motifMef, natureLibelleCourt } = motifMefDto || {};
  return (
    <div className={'summaryContainer'}>
      <FieldSummaryDisplay label={'Unité ayant prescrit la mesure'}>
        <p>
          {abreviation}
          <span className={'detail'}>{`: ${unite}`}</span>
        </p>
        <p>{`${ville} (${codePostal})`}</p>
      </FieldSummaryDisplay>

      <FieldSummaryDisplay label={'Motivation de la mesure'}>
        <p className={'detail'}>{stripNatureLibelle(motifMef)}</p>
        <p>{natureLibelleCourt}</p>
      </FieldSummaryDisplay>

      {plusieursPlaques && (
        <FieldSummaryDisplay
          label={'Faux numéro d’identification ou d’immatriculation (visible)'}
        >
          {numeroImmatriculationVisible}
        </FieldSummaryDisplay>
      )}

      <FieldSummaryDisplay
        label={"Numéro d'identification ou d'immatriculation"}
      >
        {numero}
      </FieldSummaryDisplay>

      <FieldSummaryDisplay label={'Numéro VIN'}>{vin}</FieldSummaryDisplay>

      <FieldSummaryDisplay label={'Etat du véhicule'}>
        {etat && <p>{etatMapping[etat].label}</p>}
        {shouldShowDetail(etatDetails) ? (
          <>
            <p className={'detail'}>Détails :</p>
            <label
              className={classNames('value', {
                missingValue: !detail,
              })}
            >
              {detail || 'Non renseigné'}
            </label>

            {data.ficheVehicule.etat === EtatDtoEtatEnum.DOMMAGES_GRAVES && (
              <p className="interdictionCirculer">
                Une interdiction de circuler sera inscrite.
              </p>
            )}
          </>
        ) : null}
      </FieldSummaryDisplay>
    </div>
  );
};

export function userSummaryConfirm(
  data: FicheDescriptiveFormValues,
  etatDetails: ReferenceDtoEtatDto | undefined,
  refController: ReferentielControllerApi,
): Promise<void> {
  return new Promise(function (resolve, reject) {
    AntdModal.confirm({
      title: `Avant de poursuivre, veuillez confirmer la validité des informations saisies`,
      content: (
        <SummaryConfirm
          data={data}
          etatDetails={etatDetails}
          refController={refController}
        />
      ),
      width: '53%',
      okText: 'Confirmer',
      onOk() {
        resolve();
      },
      onCancel() {
        reject();
      },
    });
  });
}
