import React from 'react';
import { Button as AntdButton } from 'antd';
import { useFormActionsContext } from 'components/WrappedComponents';

import './FormActions.less';

interface FormActionsProps<T> {
  setFilter: (value: Partial<unknown>) => void;
  defaultValue: T;
}

export default function FormActions<T>({
  setFilter,
  defaultValue,
}: FormActionsProps<T>): React.ReactElement {
  const formActions = useFormActionsContext();

  function resetSearch() {
    formActions.setFieldsValue(defaultValue);
  }

  return (
    <div className="formActions">
      <AntdButton onClick={resetSearch}>Réinitialiser</AntdButton>
      <AntdButton
        type={'primary'}
        onClick={() => setFilter(formActions.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}
