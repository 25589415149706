import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import { MotifMefViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { useApi } from 'hooks/ApiStoreContext';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { FormInputProps } from 'components/BaseForm/types';
import { MotifMefFormValues } from 'types/referentiels/MotifMef';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { fetchBranchesList } from 'search/searchBranche';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';

interface ModalEditionMotifMefProps {
  close: () => void;
  visible: boolean;
  initials: MotifMefFormValues;
  title: string;
  submit: (values: MotifMefFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: MotifMefViolationDtoFormFieldEnum,
    values: MotifMefFormValues,
  ) => Promise<void>;
}

function ModalEditionMotifMef({
  ...props
}: ModalEditionMotifMefProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();
  const baseInputs: Array<
    FormInputProps<MotifMefFormValues, MotifMefViolationDtoFormFieldEnum>
  > = [
    {
      field: MotifMefViolationDtoFormFieldEnum.BRANCHE,
      name: 'branche',
      label: 'Nature de MEF',
      required: true,
      render: () => (
        <AsyncSelect
          fetchOptions={() => fetchBranchesList(refController)}
          getOptionValue={option => option.idCorrelation}
          getOptionLabel={option => option.nature}
        />
      ),
    },
    {
      field: MotifMefViolationDtoFormFieldEnum.NATURE,
      name: 'nature',
      label: 'Détail de nature de MEF',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      field: MotifMefViolationDtoFormFieldEnum.MOTIF_MEF,
      name: 'motifMef',
      label: 'Motif de MEF',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      name: 'dicem',
      label: 'Motif DICEM',
      valuePropName: 'checked',
      render: () => <AntdCheckbox />,
    },
    {
      name: 'rodeo',
      label: 'Motif RODEO',
      valuePropName: 'checked',
      render: () => <AntdCheckbox />,
    },
  ];

  return <ModalEdition {...props} inputs={baseInputs} />;
}

export default ModalEditionMotifMef;
