import { useState } from 'react';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';
import { RequestStatus } from 'types/RequestStatus';
import { ManuelType, ManuelTypeEnum } from 'types/referentiels/Manuels';

type ImportFunction = (file: Blob) => void;

interface ImportManuelResult {
  fetchManuel: (manuelType: UserDtoProfileEnum) => ImportFunction;
  importStatus: RequestStatus | undefined;
}

/**
 * Hook to import any referentiel in CSV or JSOn format
 */
export function useImportManuel(manuelType: ManuelType): ImportManuelResult {
  const behaviourOnError = useHandleBackErrors();
  const api = useApi().AdminFonctionnelControllerApi;
  const [status, setStatus] = useState<RequestStatus>();

  const fetchManuel = (userType: UserDtoProfileEnum) => {
    return async (file: Blob) => {
      setStatus(RequestStatus.PENDING);
      const apiCall =
        manuelType === ManuelTypeEnum.UTILISATEUR
          ? api.saveManualUtilisateurUsingPOST(userType, file)
          : api.saveManualBdrUsingPOST(userType, file);

      await apiCall
        .then((_: Response) => setStatus(RequestStatus.SUCCESS))
        .catch((errorResponse: Response) => {
          setStatus(RequestStatus.ERROR);
          behaviourOnError(errorResponse, true);
        });
    };
  };

  return {
    fetchManuel: fetchManuel,
    importStatus: status,
  };
}
