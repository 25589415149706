import React from 'react';
import { Button as AntdButton } from 'antd';
import { EditOutlined } from '@ant-design/icons';

type Props = {
  setEdit: () => void;
};

function ProfilFormEditButton({ setEdit }: Props): React.ReactElement {
  return (
    <AntdButton
      className={'edit-profile-button right'}
      type={'primary'}
      onClick={() => setEdit()}
      icon={<EditOutlined style={{ fontSize: '30px' }} />}
      ghost={true}
      title={'Modifier'}
      size={'large'}
    />
  );
}

export default ProfilFormEditButton;
