import React, { useState } from 'react';

import {
  AdminFonctionnelControllerApi,
  DossierDtoAdminFonctionnel,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { UpdateSortieFormValues } from './types';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';

type SubmitFunction = {
  submit: (values: UpdateSortieFormValues) => void;
  inProgress: boolean;
};

function useSubmitUpdateSortie(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: UpdateSortieFormValues) => {
      setInProgress(true);
      return refController
        .updateSortieVehiculeUsingPOST(dossierId, {
          dateSortie: values.dateSortie?.format(UNIX_TIMESTAMP_FORMAT) ?? null,
          motifId: values.idCorrelationMotifSortie ?? null,
          comment: values.comment ?? null,
        })
        .then(setDossier)
        .then(fetchEvents)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitUpdateSortie;
