import React from 'react';

import ResponsiveContainer from 'components/ResponsiveContainer';
import CardLayout from 'components/CardLayout/CardLayout';
import UploadCard from 'components/Card/UploadCard';
import Uploader from 'components/WrappedComponents/Uploader/Uploader';
import { SupportedFile } from 'types/enums/SupportedFile';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';

import { useImportHermesContournement } from 'hooks/hermes/useImportContournementCsv';

const Import = (): React.ReactElement => {
  const [importCsv, status] = useImportHermesContournement();
  const { fileTypes } = supportedFilesDetails[SupportedFile.CSV];

  return (
    <ResponsiveContainer>
      <h1>{`Import des refus / impossibilité de vente`} </h1>
      <p>
        {`Vous pouvez depuis cette page importer les refus ou impossibilités
        de vendre un véhicule. Pour cela, il vous suffit de cliquer sur 
        l'élément 'Retours de la DNID' et de choisir le fichier contenant ces 
        véhicules. Ce fichier doit être au format CSV.`}
      </p>
      <CardLayout>
        <UploadCard status={status}>
          <Uploader
            importRoute={importCsv}
            title={'Retours de la DNID'}
            fileTypes={fileTypes}
          />
        </UploadCard>
      </CardLayout>
    </ResponsiveContainer>
  );
};

export default Import;
