import React, { useState } from 'react';
import { AdminFonctionnelControllerApi } from 'lib_api/lib/api/gen';

import { notification as AntdNotification } from 'antd';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { MigrateDossierFormValues } from './types';

type SubmitFunction = {
  submit: (values: MigrateDossierFormValues) => void;
  inProgress: boolean;
};

function useSubmitMigrateDossiersUniteFo(
  refController: AdminFonctionnelControllerApi,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: MigrateDossierFormValues) => {
      setInProgress(true);
      return refController
        .migrateUniteFoUsingPOST({
          oldUniteFoIdCorrelation: values.idCorrelationOldUnite ?? null,
          newUniteFoIdCorrelation:
            values.idCorrelationNewUnite?.option?.idCorrelation ?? null,
          comment: values.comment ?? null,
        })
        .then(() => {
          void AntdNotification.success({
            message: `La migration des dossiers associés à l'unité a été réalisée avec succès.`,
            style: {
              width: 600,
            },
            duration: 0, // won't close automatically
            placement: 'topLeft',
          });
        })
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitMigrateDossiersUniteFo;
