import React from 'react';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import useExportManuel from 'hooks/referentiels/useExportManuel';
import UploadCard from 'components/Card/UploadCard';
import Downloader from 'components/WrappedComponents/Downloader/Downloader';
import { ManuelType } from 'types/referentiels/Manuels';

interface ManuelExportProps {
  index: number;
  label: string;
  userType: UserDtoProfileEnum;
  manuelType: ManuelType;
}

export default function ManuelExport({
  index,
  label,
  userType,
  manuelType,
}: ManuelExportProps): React.ReactElement {
  const { downloadStatus, download } = useExportManuel(userType, manuelType);

  return (
    <UploadCard status={downloadStatus} key={index}>
      <Downloader title={label} download={download} />
    </UploadCard>
  );
}
