import React, { useState } from 'react';

import {
  AdminFonctionnelControllerApi,
  DossierDtoAdminFonctionnel,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { UpdateDonnesVehiculeFormValues } from './types';

type SubmitFunction = {
  submit: (values: UpdateDonnesVehiculeFormValues) => Promise<void>;
  inProgress: boolean;
};

function useSubmitUpdateDonneesVehicule(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: UpdateDonnesVehiculeFormValues) => {
      setInProgress(true);
      return refController
        .updateDonneesVehiculeUsingPOST(dossierId, {
          newSansPlaque: values.sansPlaque ?? null,
          newMotifEntreeIdCorrelation: values.idCorrelationMotifMef ?? null,
          newPaysEtranger: values.paysEtranger ?? null,
          newPlaqueImmatriculation: values.plaqueImmatriculation ?? null,
          newEtatIdCorrelation: values.idCorrelationEtatVehicule ?? null,
          comment: values.comment ?? null,
        })
        .then(setDossier)
        .then(fetchEvents)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitUpdateDonneesVehicule;
