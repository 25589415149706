import React, { useState } from 'react';

import Filter from './Filter/Filter';
import { FilterDashboardCentreVHU } from './types';

import { Button as AntdButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import './DashboardHeader.less';
import ModalCreation from '../Modals/ModalCreation';

interface DashboardHeaderProps {
  totalCount: number;
  setFilter: (value: Partial<FilterDashboardCentreVHU>) => void;
  filter: Partial<FilterDashboardCentreVHU>;
}

function DashboardHeader({
  totalCount,
  filter,
  setFilter,
}: DashboardHeaderProps): React.ReactElement {
  const countString = `${totalCount} centre${
    totalCount > 1 ? 's' : ''
  } VHU trouvé${totalCount > 1 ? 's' : ''}`;

  const [modalCreationOpen, setModalCreationOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalCreationOpen(true);
  };

  const closeModal = () => {
    setModalCreationOpen(false);
  };

  const refreshDashboard = () => {
    setFilter({ ...filter });
  };

  return (
    <div>
      <div className="dashboard-header-fields header-referentiels">
        <Filter filter={filter} setFilter={setFilter} />
        <AntdButton
          icon={<PlusOutlined />}
          type={'primary'}
          shape={'round'}
          onClick={openModal}
        >
          Créer un centre VHU
        </AntdButton>{' '}
        <ModalCreation
          visible={modalCreationOpen}
          close={closeModal}
          refreshDashboard={refreshDashboard}
        />
      </div>
      <p className="dashboard-number-centres-vhus">{countString}</p>
    </div>
  );
}

export default DashboardHeader;
