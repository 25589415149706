import React, { useState } from 'react';
import { FaqFieldProps, FaqFormValues } from '../../../types';
import { marked } from 'marked';
import TextAreaContent from './TextAreaContent';
import './InputContent.less';

function InputContent({
  values,
  validateField,
}: FaqFieldProps): React.ReactElement {
  const key: keyof FaqFormValues = 'content';
  const [showRendu, setShowRendu] = useState<boolean>(false);

  return (
    <div className="inputContent">
      <p className="inputContent--label">Contenu :</p>
      <div className="inputContent__body">
        <p className="inputContent__body__options">
          <span
            className={`inputContent__body__options--option ${
              !showRendu ? 'selected' : ''
            }`}
            onClick={() => setShowRendu(false)}
          >
            Contenu
          </span>
          <span className="inputContent__body__options--separator"> | </span>
          <span
            className={`inputContent__body__options--option ${
              showRendu ? 'selected' : ''
            }`}
            onClick={() => setShowRendu(true)}
          >
            Visualisation
          </span>
        </p>
        {showRendu ? (
          <div
            className="inputContent__body__render"
            dangerouslySetInnerHTML={{
              __html: marked.parse(values[key] || ''),
            }}
          />
        ) : (
          <TextAreaContent validateField={validateField} values={values} />
        )}
      </div>
    </div>
  );
}

export default InputContent;
