import React from 'react';
import { Button as AntdButton } from 'antd';

import { FicheFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import {
  FicheDescriptiveFormValues,
  FicheFourriereFormValues,
  FicheInfractionFormValues,
} from '../types';
import { useValidateFicheFourriereField } from './utils';
import { useUserStore } from 'hooks/UserStoreContext';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseDateTimePicker from 'components/BaseForm/BaseDateTimePicker';
import useSubmitCreateDossier from '../buildSubmitFunction';

interface FicheFourriereFormProps {
  values: FicheFourriereFormValues;
  setValues: (newValues: FicheFourriereFormValues) => void;
  formValues: FicheDescriptiveFormValues;
  dateConstatation: FicheInfractionFormValues['dateConstatation'];
  dateRedaction: FicheInfractionFormValues['dateRedaction'];
  goPreviousForm?: () => void;
  onCreationSucceed: () => void;
  userConfirm: () => Promise<void>;
}

const FicheFourriereForm: React.FC<FicheFourriereFormProps> = ({
  values,
  formValues,
  dateConstatation,
  dateRedaction,
  setValues,
  goPreviousForm,
  onCreationSucceed,
  userConfirm,
}: FicheFourriereFormProps) => {
  const user = useUserStore();
  const { submit, inProgress } = useSubmitCreateDossier(onCreationSucceed);
  const validateField = useValidateFicheFourriereField(
    dateConstatation,
    dateRedaction,
  );

  return (
    <BaseForm
      onSubmit={submitted => {
        userConfirm()
          .then(() => submit({ ...formValues, ficheFourriere: submitted }))
          .catch(() => {
            // on user cancel, do nothing
          });
      }}
      onChange={(_changed, all) => {
        setValues(all);
        return all;
      }}
      initialValues={values}
      validateField={validateField}
      inputs={[
        {
          label: "Date et heure d'enlèvement",
          name: 'dateEnlevement',
          field: FicheFourriereViolationDtoFormFieldEnum.ENLEVEMENT,
          required: true,
          render: () => {
            return <BaseDateTimePicker />;
          },
        },
        {
          label: "Date et heure d'entrée sur le parc",
          name: 'dateEntree',
          field: FicheFourriereViolationDtoFormFieldEnum.ENTREE,
          required: true,
          render: () => {
            return <BaseDateTimePicker />;
          },
        },
      ]}
      isSubmitting={inProgress}
      renderButtonAction={SubmitButton => {
        return (
          <div className="navigation">
            <AntdButton
              type={'primary'}
              className={'left'}
              onClick={goPreviousForm}
            >
              Précédent
            </AntdButton>
            <div className="right">{SubmitButton}</div>
          </div>
        );
      }}
    />
  );
};

export default FicheFourriereForm;
