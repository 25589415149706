import React from 'react';
import { Spin as AntdSpin } from 'antd';

import { AutoComplete } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FormFilterFieldCentreVHU } from '../../types';
import { useCommune } from 'hooks/dossiers/useCommune';
import { isCommune } from 'hooks/dossiers/useAdresse';

function InputCommune({
  values,
}: FormFilterFieldCentreVHU): React.ReactElement {
  const [communes, isFetching, fetchCommunes] = useCommune();

  const options = communes.map(res => {
    return {
      value: res.nom,
      label: res.nom,
    };
  });

  return (
    <AutoComplete
      wrapperProps={{
        values: values,
        fieldKey: 'commune',
        itemProps: {
          label: 'Commune',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          normalize: (value: unknown) => {
            const foundOption = communes.find(commune => value === commune.nom);
            if (foundOption) {
              return {
                commune: foundOption.nom,
                codePostal: foundOption.codesPostaux[0],
              };
            } else {
              return { commune: value };
            }
          },
          getValueProps: (value: unknown) => {
            if (isCommune(value)) {
              return { value: value.commune };
            }

            return {};
          },
        },
      }}
      optionProps={options}
      autoCompleteProps={{
        placeholder: FormPlaceholders.Input,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        onChange: fetchCommunes,
        notFoundContent: isFetching ? <AntdSpin /> : null,
      }}
    />
  );
}

export default InputCommune;
