import React from 'react';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { DateFieldDisplay } from 'components/FieldDisplay/DateFieldDisplay';
import FaussePlaqueFieldDisplay from './FaussePlaqueFieldDisplay/FaussePlaqueFieldDisplay';

const LeftSideDetailInfo = (): React.ReactElement => {
  const [{ result: dossier }] = useDossierAdminContext();

  return (
    <>
      <FaussePlaqueFieldDisplay
        immatriculation={dossier.immatriculation}
        immatriculationVisible={dossier.immatriculationVisible}
      />
      <FieldDisplay
        key="vin"
        label="VIN"
        value={dossier.vin}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="marque"
        label="Marque"
        value={dossier.libelleMarque}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="modele"
        label="Modèle"
        value={dossier.libelleModele}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="nature_libelle_court"
        label="Nature de MEF"
        value={dossier.libelleNatureMEF}
        labelClass="labelAdmin"
      />
      <DateFieldDisplay
        key="date_entre"
        label="Entrée le"
        value={dossier.dateEntree}
        labelClass="labelAdmin"
      />
      {dossier.dateSortie && (
        <DateFieldDisplay
          key="date_sortie"
          label="Sortie le"
          value={dossier.dateSortie}
          labelClass="labelAdmin"
        />
      )}
    </>
  );
};

export default LeftSideDetailInfo;
