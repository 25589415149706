import React from 'react';
import { Alert as AntdAlert } from 'antd';

import {
  ConditionsReactivateUniteFoDto,
  UniteFOAdminResponseDto,
} from 'lib_api/lib/api/gen/api';

import { useRefUniteFo } from 'hooks/referentiels/useRefUniteFo';
import './ConditionReactivateUniteFo.less';

interface ConditionReactivateUniteFoProps {
  conditions: ConditionsReactivateUniteFoDto | undefined;
  unite: UniteFOAdminResponseDto;
}

function shouldDisplayAlert(
  conditions: ConditionsReactivateUniteFoDto | undefined,
): boolean {
  return (
    conditions?.uniteParentValid === false ||
    conditions?.uniteCodeUnique === false ||
    conditions?.uniteAbreviationUnique === false ||
    conditions?.uniteUniteUnique === false
  );
}

function buildAlertMessage(
  conditions: ConditionsReactivateUniteFoDto | undefined,
  unite: UniteFOAdminResponseDto,
): React.ReactElement {
  const uniteParent = unite.idCorrelationParent
    ? useRefUniteFo(unite.idCorrelationParent)
    : undefined;

  return (
    <ul>
      {conditions?.uniteParentValid === false && (
        <li>
          {`L'unité parent ${uniteParent?.abreviation} n'est pas active. Vous devez l'activer pour activer cette unité.`}
        </li>
      )}
      {conditions?.uniteAbreviationUnique === false && (
        <li>
          {"L'abréviation " +
            unite.abreviation +
            ' est déjà utilisée pour une autre unité active. Vous devez la désactiver pour pouvoir activer cette unité.'}
        </li>
      )}
      {conditions?.uniteCodeUnique === false && (
        <li>
          {'Le code ' +
            unite.code +
            ' est déjà utilisé pour une autre unité active. Vous devez la désactiver pour pouvoir activer cette unité.'}
        </li>
      )}
      {conditions?.uniteUniteUnique === false && (
        <li>
          {"Le libellé d'unité " +
            unite.unite +
            ' est déjà utilisé pour une autre unité active. Vous devez la désactiver pour pouvoir activer cette unité.'}
        </li>
      )}
    </ul>
  );
}

function ConditionReactivateUniteFo({
  conditions,
  unite,
}: ConditionReactivateUniteFoProps): React.ReactElement {
  return (
    <>
      {shouldDisplayAlert(conditions) && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={buildAlertMessage(conditions, unite)}
        />
      )}
    </>
  );
}

export default ConditionReactivateUniteFo;
