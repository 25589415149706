import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import {
  Modal as AntdModal,
  Button as AntdButton,
  Descriptions as AntdDescriptions,
} from 'antd';
import { Form } from 'components/WrappedComponents';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { ProlongationMef } from 'types/ProlongationMef';
import { DATE_FORMAT } from 'utils/formats';
import {
  useValidateProlongation,
  generateProlongationRequestDtoFromProlongationMef,
  useSubmitProlongation,
} from './utils';
import DatePickerDecisionProcureur from './DatePickerDecisionProcureur';

import './Prolongation.less';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

interface ModalProlongationProps {
  visible?: boolean | undefined;
  close: () => void;
}

const ModalProlongation = ({
  visible,
  close,
}: ModalProlongationProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const [updatedDossier, submitForm, thenSubmmit, catchSubmit] =
    useSubmitProlongation();
  const [data, setData] = useState<ProlongationMef>({
    dateDecisionProcureur: undefined,
  });

  useEffect(() => {
    if (updatedDossier !== null) {
      setDossier(updatedDossier);
      close();
    }
  }, [updatedDossier, setDossier, close]);

  const dossierId = extractNonnullDossierIdOrThrow(dossier);

  const memoSubmit = useCallback(async () => {
    return submitForm(data, dossierId);
  }, [data, submitForm, dossierId]);

  const [validateAndSubmit] = useValidateProlongation(
    memoSubmit,
    thenSubmmit,
    catchSubmit,
  );

  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      title={'Prolonger la mise en fourrière administrative'}
      closable={false}
      maskClosable={false}
      className={'ModalProlongation'}
      footer={null}
    >
      <Form
        initialValue={data}
        id={'ProlongationForm'}
        onSubmit={() => {
          return validateAndSubmit(afController =>
            afController.validateProlongationUsingPOST(
              dossierId,
              {},
              generateProlongationRequestDtoFromProlongationMef(data),
            ),
          );
        }}
        onChange={values => {
          setData(values);
        }}
        validateButtonTitle={'Prolonger'}
        validateButtonClassName={'ValidateFormButton'}
      >
        {(values, SubmitButton): React.ReactElement => {
          return (
            <>
              <AntdDescriptions>
                <AntdDescriptions.Item
                  className="DateFinMef"
                  label="Fin de la mise en fourrière"
                >
                  {moment(dossier.computed?.dateFinMefAdministrative).format(
                    DATE_FORMAT,
                  )}
                </AntdDescriptions.Item>
              </AntdDescriptions>
              <DatePickerDecisionProcureur
                dossierId={dossierId}
                values={values}
              />
              <div className="ModalFooter">
                <AntdButton onClick={close}>Annuler</AntdButton>
                {SubmitButton}
              </div>
            </>
          );
        }}
      </Form>
    </AntdModal>
  );
};

export default ModalProlongation;
