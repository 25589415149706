import React from 'react';
import { Button as AntdButton } from 'antd';

import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import { FilterDashboardUniteFo } from 'types/referentiels/UniteFo';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: FilterDashboardUniteFo) => void;
}

export function FormFooter({ setFilter }: FormActionProps): React.ReactElement {
  const context = useBaseFormContext<FilterDashboardUniteFo>();

  function resetFilter() {
    context.setFieldsValue({
      type: undefined,
      abreviation: undefined,
      code: undefined,
      codePostal: undefined,
      unite: undefined,
      valid: true,
      ville: undefined,
      idCorrelation: undefined,
      rpsiStatus: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>
      <AntdButton
        type={'primary'}
        onClick={() => setFilter(context.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormFooter;
