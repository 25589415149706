import { useEffect, useState } from 'react';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { BaseSearchResult } from 'types/searchResult';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

function useAsyncSearch<
  FilterType,
  SortType extends string | undefined,
  ReferentielSearchResultDto,
  OptionType,
>(
  useSearch: () => (
    filters: FilterType,
    sorts: TableSort<SortType>,
    pagination: TablePagination,
  ) => Promise<ReferentielSearchResultDto>,
  mapResult: (result: ReferentielSearchResultDto) => OptionType[],
  updateFilters: (query: string | undefined) => FilterType,
  defaultFilter: FilterType,
  defaultSort: TableSort<SortType> = {},
  defaultPagination: TablePagination = { page: 1, pageSize: 10 },
  searchDelay = 500,
): BaseSearchResult<OptionType> {
  const [result, setResult] = useState<OptionType[]>([]);
  const [inProgress, setInProgress] = useState(false);
  const [query, setQuery] = useState<string>();
  const fetchData = useSearch();

  useEffect(() => {
    let isUptoDate = true;
    setInProgress(true);

    // The request is triggered immediately for the default value
    const delayBeforeTrigger = query ? searchDelay : 0;

    const delayedFunction = setTimeout(() => {
      fetchData(
        query ? updateFilters(query) : defaultFilter,
        defaultSort,
        defaultPagination,
      )
        .then(mapResult)
        .then(setResult)
        .catch(backAlertMessage)
        .finally(() => setInProgress(false));
    }, delayBeforeTrigger);

    return () => {
      clearTimeout(delayedFunction);
      isUptoDate = false;
      setInProgress(false);
    };
  }, [query]);

  return {
    result,
    inProgress,
    search: setQuery,
  };
}

export default useAsyncSearch;
