import { useCallback } from 'react';
import {
  CountDossiersResultDto,
  CountDossierSummariesUsingGETQueryParams,
} from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useFetchObject, UseFetchObjectResult } from '../utils/useFetchObject';
import { Filters } from './useSearchDossierSummaries';

export const useFetchCountDossier = (
  filters: Filters,
): UseFetchObjectResult<CountDossiersResultDto | null> => {
  const controller = useApi().DossierControllerApi;
  const fetcher = useCallback(() => {
    const queryParams: CountDossierSummariesUsingGETQueryParams = {
      dossierCriteriaDto: {
        ...filters,
        dateFromBonEnlevement: filters.dateRangeBonEnlevement?.[0]?.toDate(), // date bon enlevement (début)
        dateFromMiseAJour: filters.dateRangeMiseAJour?.[0]?.toDate(), // date mise à jour (début)
        dateFromMiseEnFourriere:
          filters.dateRangeMiseEnFourriere?.[0]?.toDate(), // date mise en fourrière (début)
        dateFromOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[0]?.toDate(), // date ordre destruction (début)
        dateFromSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[0]?.toDate(), // date sortie (début)
        dateToBonEnlevement: filters.dateRangeBonEnlevement?.[1]?.toDate(), // date bon enlevement (début)
        dateToMiseAJour: filters.dateRangeMiseAJour?.[1]?.toDate(), // date mise à jour (fin)
        dateToMiseEnFourriere: filters.dateRangeMiseEnFourriere?.[1]?.toDate(), // date mise en fourrière (fin)
        dateToOrdreDestruction:
          filters.dateRangeOrdreDestruction?.[1]?.toDate(), // date ordre de destruction (fin)
        dateToSortieDefinitive:
          filters.dateRangeSortieDefinitive?.[1]?.toDate(), // date sortie (fin)
        idUniteFOList: filters.listUniteFo,
      },
      dossierSortDto: {},
    };
    return controller.countDossierSummariesUsingGET(queryParams);
  }, [filters]);
  return useFetchObject(fetcher);
};
