import React from 'react';

import DashboardTable from './DashboardTable';

function Dashboard(): React.ReactElement {
  return (
    <div className="dashboard-container">
      <DashboardTable />
    </div>
  );
}

export default Dashboard;
