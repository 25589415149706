import React from 'react';
import { isArray } from 'lodash';

import AntdSelect, {
  SelectProps as AntdSelectProps,
  SelectValue as AntdSelectValue,
} from 'antd/lib/select';

import FieldWrapper from '../FieldWrapper';
import {
  generateMultiLinesSelectOption,
  isMultiLineOptionProps,
} from 'components/MultilineSelectOptions/MultiLinesSelectOption';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { WrappedProps } from '../types';
import { isValueProps } from '../utils';
import { isEmptyObject } from 'utils/genericUtils';
import { OptionProps } from 'types/searchResult';

import './Select.less';

function filter(input: string, Option: unknown): boolean {
  //  normalized text searched
  const normalizedInput = input
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

  if (Option !== undefined && isMultiLineOptionProps(Option)) {
    const optionContent = Option.children.props.children.toString();

    //  normalized the option content
    const normalizedOption = optionContent
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();

    //  split to get every words searched
    const words = normalizedInput.split(' ');
    //  for every words searched
    for (const word of words) {
      //  if one word is missing
      if (!normalizedOption.includes(word)) {
        //  the option should not be displayed
        return false;
      }
    }

    //  if all words have been found, the option should be displayed
    return true;
  }

  console.error(
    'Option content is not a string, search is not possible with this option.',
  );
  return false;
}

interface Props<T, V extends AntdSelectValue> {
  wrapperProps: WrappedProps<T>;
  selectProps: AntdSelectProps<V>;
  optionProps: OptionProps[];
}

function WrappedSelect<T, V extends AntdSelectValue = AntdSelectValue>({
  wrapperProps,
  selectProps,
  optionProps,
}: Props<T, V>): React.ReactElement {
  const select = () => {
    //  Prevent the select to use basic options to build our own
    if (selectProps.options !== undefined) {
      optionProps = selectProps.options.map((option, index) => {
        return {
          value: option.value ?? index,
          displayValue: option.label || option.value,
        };
      });
      selectProps.options = undefined;
    }

    //  Overload search feature
    if (selectProps.showSearch === undefined) {
      //  By default, enable search
      selectProps.showSearch = true;
    }
    if (selectProps.filterOption === undefined) {
      //  By default, build a search function that perform advanced search over displayed label and not value
      //  (since the value is the field  used when the option is selected and it may be different from the value displayed in the option)
      selectProps.filterOption = filter;
    }

    return (
      <AntdSelect {...selectProps} className={'Select'}>
        {optionProps.map((option, index) =>
          generateMultiLinesSelectOption(
            `option_${index}`,
            option.value,
            option.displayValue,
          ),
        )}
      </AntdSelect>
    );
  };

  wrapperProps.itemProps.trigger = 'onChange';
  wrapperProps.itemProps.validateTrigger = 'onChange';
  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;

  let value = formActions.getFieldValue(fieldKey);

  // if value has a complex type, then we need to convert the object
  // to a value renderable by FieldWrapper
  if (
    value !== null &&
    value !== undefined &&
    wrapperProps.itemProps?.getValueProps
  ) {
    const valueProps = wrapperProps.itemProps.getValueProps(value);
    if (isValueProps(valueProps)) {
      value = valueProps.value;
    } else if (isArray(valueProps)) {
      value = valueProps;
    } else if (isEmptyObject(valueProps)) {
      value = undefined;
    } else {
      throw new Error('getValueProps should return { value: string }');
    }
  }

  if (!wrapperProps.editable) {
    const currentValue = optionProps.filter(item => {
      if (typeof value === 'string') {
        return item.value.toString() === value;
      } else if (isArray(value)) {
        return value.includes(item.value.toString());
      }
      return undefined;
    });
    if (currentValue.length === 1) {
      value = currentValue[0].displayValue?.toString();
    } else if (currentValue.length > 1) {
      value = currentValue.map(item => (
        <>
          {item.displayValue} <br />
        </>
      ));
    }
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      WrappedInput={select()}
      ConsultationElement={value}
    />
  );
}

export default WrappedSelect;
