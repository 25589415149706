import React from 'react';

import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';

import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FilterDashboardComptesUtilisateurs } from '../../DashboardHeader/types';

function SelectProfile({
  values,
}: FieldProps<FilterDashboardComptesUtilisateurs>): React.ReactElement {
  const key: keyof FilterDashboardComptesUtilisateurs = 'userType';

  // list of options of select
  const selectItems = [
    {
      value: UserDtoProfileEnum.GARDIEN_FOURRIERE,
      displayValue: 'Gardien de fourrière',
    },
    {
      value: UserDtoProfileEnum.AUTORITE_FOURRIERE,
      displayValue: 'Collectivité territoriale',
    },
    {
      value: UserDtoProfileEnum.FORCE_DE_L_ORDRE,
      displayValue: 'Police municipale',
    },
    {
      value: UserDtoProfileEnum.DSR_BLR,
      displayValue: 'DSR',
    },
    {
      value: UserDtoProfileEnum.ADMIN_FONCTIONNEL,
      displayValue: 'Admin fonctionnel',
    },
  ];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Type de profil',
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.XLargeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        allowClear: true,
      }}
    />
  );
}

export default SelectProfile;
