import React, { useCallback, useEffect, useState } from 'react';
import { Button as AntdButton } from 'antd';
import moment from 'moment';

import { SummaryEventDto } from 'lib_api/lib/api/gen';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useApi } from 'hooks/ApiStoreContext';
import Spinner from 'components/WrappedComponents/Spinner';
import SuiviDossierTimeline from './SuiviDossierTimeline';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { DATE_FORMAT } from 'utils/formats';

import './SuiviDossier.less';

/**
 * Remove same day repeated events
 * We asuming that
 * - There is at least one event (creation)
 * - All events are ordered by date
 * - We only want remove repeated events of same day, not duplicates events
 * @param events
 * @returns
 */
function removeRepeatedEvents(events: SummaryEventDto[]) {
  if (events.length <= 1) {
    return events;
  }

  const [first, ...others] = events;
  return [
    first,
    ...others.filter((event, index) => {
      const previous = events[index];
      return previous.label !== event.label;
    }),
  ];
}

function SuiviDossier(): React.ReactElement {
  const [dossier] = useDossierContext();
  const { DossierControllerApi } = useApi();
  const [items, setItems] = useState<SummaryEventDto[] | null>(null);
  const [error, setError] = useState(false);

  const fetchAndSetEvents = useCallback(async () => {
    setItems(null);
    setError(false);

    try {
      const events: SummaryEventDto[] =
        await DossierControllerApi.getSuiviMefUsingGET(
          extractNonnullDossierIdOrThrow(dossier),
        );

      setItems(removeRepeatedEvents(events));
    } catch (e) {
      setError(true);
    }
  }, [DossierControllerApi, dossier]);

  // Fetch events
  useEffect(() => {
    void fetchAndSetEvents();
  }, [DossierControllerApi, dossier, fetchAndSetEvents]);

  if (error) {
    return (
      <div className="SuiviDossierEventError">
        <p>Une erreur est survenue, veuillez réessayer</p>
        <AntdButton type="primary" onClick={() => fetchAndSetEvents()}>
          Réessayer
        </AntdButton>
      </div>
    );
  }

  return items === null ? <Spinner /> : <SuiviDossierTimeline items={items} />;
}

export default SuiviDossier;
