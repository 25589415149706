import React from 'react';

import { FourriereFormValues } from 'types/referentiels/Fourriere';
import { useSubmitCreateFourriere, useValidateCreateFourriere } from '../utils';
import ModalEditionFourriere from './ModalEditionFourriere';

interface ModalCreateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
}

export default function ModalCreate({
  close,
  visible,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const initials: FourriereFormValues = {};
  const { submit, inProgress } = useSubmitCreateFourriere(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateFourriere();

  return (
    <ModalEditionFourriere
      close={close}
      visible={visible}
      title="Créer une fourrière"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}
