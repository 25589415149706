import React from 'react';
import { Col as AntdCol, Row as AntdRow } from 'antd';
import { FormList } from 'components/WrappedComponents';
import { Moment } from 'moment';

import { useBooleanStoreContext } from 'hooks/BooleanStoreContext';
import { SemaineHoraire } from 'types/FicheProfil/FicheProfilGardienFourriere';
import DismissHourWidget from './DismissHourWidget';
import AddHours from './AddHour';
import InputHour from '../inputs/HoursInput';
import { isArray } from 'lodash';

interface HoursWidgetProps<T extends keyof SemaineHoraire & string> {
  values: SemaineHoraire;
  horairesKey: SemaineHoraire[T] extends Moment[][] | undefined ? T : never;
}

function HoursWidget<T extends keyof SemaineHoraire & string>({
  values,
  horairesKey,
}: HoursWidgetProps<T>): React.ReactElement {
  const disabled = useBooleanStoreContext();

  const horaires = values[horairesKey];

  return (
    <FormList name={horairesKey} values={values}>
      {(fields, operation) => {
        return (
          <>
            {fields.map((item, index) => (
              <AntdRow key={index}>
                <AntdCol span={22}>
                  <InputHour
                    fieldKey={item.name}
                    completeFieldKey={[horairesKey, item.name]}
                  />
                </AntdCol>
                <AntdCol span={2}>
                  {!disabled && (
                    <DismissHourWidget
                      index={item.name}
                      removeHour={operation.remove}
                    />
                  )}
                </AntdCol>
              </AntdRow>
            ))}
            <AntdRow>
              <AntdCol span={12} />
              <AntdCol span={12}>
                {!disabled && <AddHours addHour={operation.add} />}
              </AntdCol>
            </AntdRow>

            {/* if no data to display and we can not edit */}
            {disabled &&
              (horaires === undefined ||
                (isArray(horaires) && horaires.length === 0)) && (
                <AntdRow>
                  <AntdCol span={12} />
                  <AntdCol span={12}>Non renseigné</AntdCol>
                </AntdRow>
              )}
          </>
        );
      }}
    </FormList>
  );
}

export default HoursWidget;
