import React from 'react';
import { Button as AntdButton } from 'antd';

import { DashboardDSRFilterFormValues } from 'pages/delegue-securite-routiere/Dashboard/types';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: Partial<DashboardDSRFilterFormValues>) => void;
}

export function FormAction({ setFilter }: FormActionProps): React.ReactElement {
  const context = useBaseFormContext<DashboardDSRFilterFormValues>();

  function resetFilter() {
    context.setFieldsValue({
      classement: undefined,
      dateRangeBonEnlevement: undefined,
      dateRangeMiseEnFourriere: undefined,
      dateRangeSortieDefinitive: undefined,
      fourriere: undefined,
      natureMiseEnFourriere: undefined,
      statut: undefined,
      dateRangeOrdreDestruction: undefined,
      motifDeSortie: undefined,
      includeDossiersAnonymises: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type="primary"
        onClick={() => setFilter(context.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormAction;
