import React from 'react';
import {
  Checkbox as AntdCheckbox,
  Radio as AntdRadio,
  Button as AntdButton,
} from 'antd';

import {
  EtatDtoEtatEnum,
  FicheVehiculeViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { fetchCouleur } from 'search/searchCouleur';
import { fetchGenresimplifie } from 'search/searchGenreSimplifie';
import { etatMapping } from 'utils/enumData';
import { CSSUtils } from 'utils/CSSUtils';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import BaseInput from 'components/BaseForm/BaseInput';
import BaseForm from 'components/BaseForm/BaseForm';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { useApi } from 'hooks/ApiStoreContext';
import {
  FicheVehiculeFormValues,
  VehiculeAccessoire,
  VehiculeVerouillage,
} from '../types';
import BaseSelectDetailsEtat from './Fields/BaseSelectDetailsEtat';
import BaseSelectMarqueMultiList from './Fields/BaseSelectMarqueMultiList';
import BaseSelectModeleMultiList from './Fields/BaseSelectModeleMultiList';
import {
  shouldDisplayDetailsMessage,
  shouldDisplayVINMessage,
  useValidateFicheVehiculeField,
} from './utils';

import './FicheVehiculeForm.less';
import '../SubFormNavigation.less';

interface FicheVehiculeFormProps {
  values: FicheVehiculeFormValues;
  setValues: (newData: FicheVehiculeFormValues) => void;
  goNextForm: () => void;
  goPreviousForm: () => void;
}

export default function FicheVehiculeForm({
  values,
  setValues,
  goNextForm,
  goPreviousForm,
}: FicheVehiculeFormProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();
  const validateField = useValidateFicheVehiculeField();
  const initials: FicheVehiculeFormValues = {
    ...values,
    verrouillage: values.verrouillage
      ? values.verrouillage
      : [
          VehiculeVerouillage.VEROUILLAGE_COFFRES,
          VehiculeVerouillage.VEROUILLAGE_PORTES,
        ],
  };

  return (
    <BaseForm
      initialValues={initials}
      validateField={validateField}
      onSubmit={submittedValue => {
        setValues(submittedValue);
        goNextForm();
      }}
      onChange={(changed, all) => {
        const newValue = {
          ...all,
          modele: changed.marque ? undefined : all.modele,
          precisionEtat: changed.etat ? undefined : all.precisionEtat,
          numero: all.sansPlaque === true ? undefined : all.numero,
        };
        setValues(newValue);

        return newValue;
      }}
      inputs={[
        {
          name: 'sansPlaque',
          label: 'Véhicule sans plaque',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.IMMATRICULATION_SANS_PLAQUE,
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          name: 'plusieursPlaques',
          label: "Renseigner plusieurs plaques d'immatriculation",
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          name: 'paysEtranger',
          label: 'Pays étranger',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.IMMATRICULATION_PAYS_ETRANGER,
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: 'Genre',
          name: 'genre',
          required: true,
          field: FicheVehiculeViolationDtoFormFieldEnum.DESCRIPTION_GENRE,
          render: () => {
            return (
              <AsyncSelect
                showSearch={true}
                fetchOptions={() => {
                  return fetchGenresimplifie(refController);
                }}
                getOptionLabel={genre => genre.libelle}
                getOptionValue={genre => genre.id}
                filterOptions={(_values, options) =>
                  options.sort((a, b) => a.libelle.localeCompare(b.libelle))
                }
              />
            );
          },
        },
        {
          label: 'Faux numéro d’identification ou d’immatriculation (visible)',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.IMMATRICULATION_NUMERO_VISIBLE,
          name: 'numeroImmatriculationVisible',
          dependencies: ['plusieursPlaques'],
          required: true,
          displayItem: (values: FicheVehiculeFormValues) => {
            return values.plusieursPlaques === true;
          },
          normalize: (value: string) => value.toUpperCase(),
        },
        {
          label: "Numéro d'identification ou d'immatriculation",
          name: 'numero',
          dependencies: ['genre', 'paysEtranger', 'sansPlaque'],
          field: FicheVehiculeViolationDtoFormFieldEnum.IMMATRICULATION_NUMERO,
          required: (values: FicheVehiculeFormValues) => {
            return values.sansPlaque !== true;
          },
          disabled: (values: FicheVehiculeFormValues) => {
            return values.sansPlaque === true;
          },
          normalize: (value: string) => value.toUpperCase(),
          render: ({ disabled }) => {
            return <BaseInput disabled={disabled} />;
          },
        },
        {
          label: 'Numéro VIN',
          name: 'vin',
          dependencies: ['numero', 'sansPlaque'],
          field: FicheVehiculeViolationDtoFormFieldEnum.IMMATRICULATION_VIN,
          normalize: (value: string) => value.toUpperCase(),
          renderBeforeInput: (values: FicheVehiculeFormValues) => {
            return (
              <>
                {shouldDisplayVINMessage(values.numero, values.vin) && (
                  <div className="MessageVIN">
                    Il est recommandé de renseigner le VIN d’un véhicule avec
                    une plaque WW- ou W-
                  </div>
                )}
              </>
            );
          },
        },
        {
          label: 'Marque',
          name: 'marque',
          field: FicheVehiculeViolationDtoFormFieldEnum.DESCRIPTION_MARQUE,
          required: true,
          render: () => {
            return <BaseSelectMarqueMultiList />;
          },
        },
        {
          label: 'Modèle',
          name: 'modele',
          field: FicheVehiculeViolationDtoFormFieldEnum.DESCRIPTION_MODELE,
          required: true,
          render: () => {
            return <BaseSelectModeleMultiList idMarqueField="marque" />;
          },
        },
        {
          label: 'Couleur',
          name: 'couleur',
          field: FicheVehiculeViolationDtoFormFieldEnum.DESCRIPTION_COULEUR,
          required: true,
          render: () => {
            return (
              <AsyncSelect
                fetchOptions={() => {
                  return fetchCouleur(refController);
                }}
                getOptionValue={couleur => couleur.id}
                getOptionLabel={couleur => couleur.couleur}
                showSearch={true}
              />
            );
          },
        },
        {
          label: 'État du véhicule',
          name: 'etat',
          field: FicheVehiculeViolationDtoFormFieldEnum.ETAT_LIBELLE,
          required: true,
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.XLargeWrapperCol },
          render: () => {
            return (
              <AntdRadio.Group
                options={Object.values(EtatDtoEtatEnum).map(etat => {
                  return {
                    value: etat,
                    label: etatMapping[etat].label,
                  };
                })}
              />
            );
          },
        },
        {
          label: 'Veuillez préciser',
          name: 'precisionEtat',
          field: FicheVehiculeViolationDtoFormFieldEnum.ETAT_PRECISION,
          dependencies: ['etat'],
          displayItem: values => {
            return (
              values.etat === EtatDtoEtatEnum.DEGRADE ||
              values.etat === EtatDtoEtatEnum.DOMMAGES_GRAVES
            );
          },
          render: () => {
            return <BaseSelectDetailsEtat idEtatField="etat" />;
          },
          renderBeforeInput: (values: FicheVehiculeFormValues) => {
            return (
              <>
                {shouldDisplayDetailsMessage(values.etat) && (
                  <div className="ConfirmationMessage">
                    Une interdiction de circuler sera inscrite. Veuillez
                    vérifier l&apos;état avant de continuer.
                  </div>
                )}
              </>
            );
          },
        },
        {
          label: 'Verrouillage',
          name: 'verrouillage',
          render: () => {
            return (
              <AntdCheckbox.Group
                options={[
                  {
                    label: 'Portes',
                    value: VehiculeVerouillage.VEROUILLAGE_PORTES,
                  },
                  {
                    label: 'Coffres',
                    value: VehiculeVerouillage.VEROUILLAGE_COFFRES,
                  },
                ]}
              />
            );
          },
        },
        {
          label: 'Accessoires visibles',
          name: 'accessoires',
          render: () => {
            return (
              <AntdCheckbox.Group
                options={[
                  {
                    label: 'Auto-radio',
                    value: VehiculeAccessoire.ACCESSOIRE_AUTO_RADIO,
                  },
                  {
                    label: 'Poste c.b',
                    value: VehiculeAccessoire.ACCESSOIRE_POSTE_CB,
                  },
                ]}
              />
            );
          },
        },
        {
          label: 'Téléphone portable',
          name: 'telephonePortable',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.ACCESSOIRES_TELEPHONE_PORTABLE,
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: "Nombre d'antennes",
          name: 'nombreAntennes',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.ACCESSOIRES_NOMBRE_ANTENNES,
          render: () => {
            return <BaseInputNumber />;
          },
        },
        {
          label: 'Si objets visibles, lesquels',
          name: 'objetsVisibles',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.ACCESSOIRES_OBJETS_VISIBLES,
        },
        {
          label: 'Observations',
          name: 'observations',
          field:
            FicheVehiculeViolationDtoFormFieldEnum.ACCESSOIRES_OBSERVATIONS,
          render: () => {
            return <BaseTextArea />;
          },
        },
      ]}
      submitText="Suivant"
      renderButtonAction={SubmitButton => {
        return (
          <div className="navigation">
            <AntdButton
              type={'primary'}
              className={'left'}
              onClick={goPreviousForm}
            >
              Précédent
            </AntdButton>
            <div className="right">{SubmitButton}</div>
          </div>
        );
      }}
    />
  );
}
