import React from 'react';
import { Button as AntdButton } from 'antd';

import { useFormActionsContext } from '../../Form/FormWrapper/FormActionsContext';

interface CancelEditFormButtonType<T> {
  buttonTitle: string;
  initialValues: T;
  close?: () => void;
  className?: string;
}

function CancelEditFormButton<T>({
  buttonTitle,
  initialValues,
  close,
  className,
}: CancelEditFormButtonType<T>): React.ReactElement {
  const formActions = useFormActionsContext();

  return (
    <AntdButton
      onClick={() => {
        formActions.setFieldsValue(initialValues);
        close && close();
      }}
      className={className}
    >
      {buttonTitle}
    </AntdButton>
  );
}

export default CancelEditFormButton;
