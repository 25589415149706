import React, { ReactElement } from 'react';
import { Button as AntdButton } from 'antd';
import { useFormActionsContext } from 'components/WrappedComponents';

interface ResetButtonProps {
  resetEdit: () => void;
}

function ResetButton({ resetEdit }: ResetButtonProps): ReactElement {
  const formAction = useFormActionsContext();
  const resetAction: () => void = () => {
    formAction.resetFields();
    resetEdit();
  };
  return (
    <AntdButton className={'left'} onClick={resetAction}>
      Annuler
    </AntdButton>
  );
}

export default ResetButton;
