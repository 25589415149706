import React from 'react';
import {
  Button as AntdButton,
  Modal as AntdModal,
  notification as AntdNotification,
} from 'antd';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useSubmitSuppressionDossier } from 'hooks/dossiers/useSubmitSuppressionDossier';
import { useNavigate } from 'react-router-dom';

interface ModalSuppressionDossierProps {
  visible: boolean;
  close: () => void;
  dossierId: string;
}

export const ModalSuppressionDossier = ({
  visible,
  close,
}: ModalSuppressionDossierProps): React.ReactElement => {
  const [{ result: dossier }] = useDossierAdminContext();
  const navigate = useNavigate();

  const thenSubmit = () => {
    void AntdNotification.success({
      message: `Le dossier ${dossier.immatriculation} a correctement été supprimé du SI Fourrière.`,
      style: {
        width: 600,
      },
      duration: 0, // won't close automatically
      placement: 'topLeft',
    });
    close();
    navigate('/');
  };

  const { submitSuppression, loading } = useSubmitSuppressionDossier(
    dossier.id,
    thenSubmit,
  );

  return (
    <AntdModal
      className="modalValidity"
      visible={visible}
      onCancel={close}
      footer={null}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
    >
      <h3>{'Confirmez-vous la suppression du dossier ?'}</h3>
      <p>
        {`Si vous confirmez, le dossier ${dossier.immatriculation} sera définitivement effacé.`}
      </p>
      <div className="modalActions">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton
          type="primary"
          onClick={() => {
            submitSuppression();
          }}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Veuillez patienter' : 'Confirmer'}
        </AntdButton>
      </div>
    </AntdModal>
  );
};
