import {
  EtatDtoEtatEnum,
  FicheVehiculeRequestDto,
  FicheVehiculeViolationDtoFormFieldEnum,
  GardienFourriereControllerApi,
} from 'lib_api/lib/api/gen';

import { fetchFormViolations } from 'components/BaseForm/utils';
import { useApi } from 'hooks/ApiStoreContext';
import { etatMapping } from 'utils/enumData';
import { VALUE_NON_REFERENCE } from 'utils/global';
import {
  FicheVehiculeFormValues,
  VehiculeAccessoire,
  VehiculeVerouillage,
} from '../types';

export function shouldDisplayVINMessage(
  numero: string | undefined,
  vin: string | undefined,
): boolean {
  if (!numero || vin) {
    return false;
  }
  return numero.match(/^WW?-/g) !== null;
}

export function shouldDisplayDetailsMessage(etat?: EtatDtoEtatEnum): boolean {
  return etat === EtatDtoEtatEnum.DOMMAGES_GRAVES;
}

export function vehiculeFormValuesToRequestDto(
  ficheVehicule: FicheVehiculeFormValues,
): FicheVehiculeRequestDto {
  return {
    sansPlaque: ficheVehicule.sansPlaque || null,
    paysEtranger: ficheVehicule.paysEtranger || null,
    genreSimplifie: ficheVehicule.genre || null,
    numero: ficheVehicule.numero || null,
    numeroImmatriculationVisible:
      ficheVehicule.numeroImmatriculationVisible || null,
    vin: ficheVehicule.vin || null,
    marque:
      ficheVehicule.marque?.value === VALUE_NON_REFERENCE
        ? null
        : ficheVehicule.marque?.option?.id || null, // Here we need to send the ref ID
    marqueNonReferencee: ficheVehicule.marque?.value === VALUE_NON_REFERENCE,
    modele:
      ficheVehicule.modele === VALUE_NON_REFERENCE
        ? null
        : ficheVehicule.modele || null, // Here we need to send the ref ID
    modeleNonReference: ficheVehicule.modele === VALUE_NON_REFERENCE,
    couleur: ficheVehicule.couleur || null, // Here we need to send the ref ID
    etatLibelle: ficheVehicule.etat
      ? etatMapping[ficheVehicule.etat].requestValue
      : null,
    etatPrecisionId: ficheVehicule.precisionEtat || null,
    telephonePortable: ficheVehicule.telephonePortable || null,
    nombreAntennes: ficheVehicule.nombreAntennes || null,
    objetsVisibles: ficheVehicule.objetsVisibles || null,
    observations: ficheVehicule.observations || null,
    autoRadioVisible:
      ficheVehicule.accessoires?.includes(
        VehiculeAccessoire.ACCESSOIRE_AUTO_RADIO,
      ) || null,
    posteCbVisible:
      ficheVehicule.accessoires?.includes(
        VehiculeAccessoire.ACCESSOIRE_POSTE_CB,
      ) || null,
    coffres:
      ficheVehicule.verrouillage?.includes(
        VehiculeVerouillage.VEROUILLAGE_COFFRES,
      ) || null,
    portes:
      ficheVehicule.verrouillage?.includes(
        VehiculeVerouillage.VEROUILLAGE_PORTES,
      ) || null,
  };
}

export function useValidateFicheVehiculeField(): (
  field: FicheVehiculeViolationDtoFormFieldEnum,
  formValues: FicheVehiculeFormValues,
) => Promise<void> {
  const { GardienFourriereControllerApi: gfController } = useApi();

  return (
    field: FicheVehiculeViolationDtoFormFieldEnum,
    formValues: FicheVehiculeFormValues,
  ) => {
    return validateFicheVehiculeField(field, formValues, gfController);
  };
}

export async function validateFicheVehiculeField(
  field: FicheVehiculeViolationDtoFormFieldEnum,
  formValues: FicheVehiculeFormValues,
  gfController: GardienFourriereControllerApi,
): Promise<void> {
  return fetchFormViolations(
    () => {
      return gfController.validateFicheVehiculeFieldWithoutDossierUsingPOST(
        field,
        vehiculeFormValuesToRequestDto(formValues),
      );
    },
    violationResponse => {
      return violationResponse.ficheVehiculeViolationDtoList;
    },
    violation => {
      return violation.message;
    },
  );
}
