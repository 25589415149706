import React, { ReactElement } from 'react';
import { Checkbox } from 'antd';
import {
  CarrosserieClassementManuelDto,
  ClassementManuelViolationDtoFormFieldEnum,
  EnergieDto,
  GenreDto,
} from 'lib_api/lib/api/gen';
import { useFetchDonneesTechniquesSiv } from 'hooks/dossiers/useFetchDonneesTechniquesSiv';
import { useApi } from 'hooks/ApiStoreContext';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import BaseForm from 'components/BaseForm/BaseForm';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseInput from 'components/BaseForm/BaseInput';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import { fetchGenre } from 'search/searchGenre';
import { fetchCarrosserie } from 'search/searchCarrosserie';
import { fetchEnergie } from 'search/searchEnergie';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { CSSUtils } from 'utils/CSSUtils';
import {
  convertInformationsTechniquesToClassementManuel,
  generateClassementManuelRequestFromClassement,
  useSubmitClassementManuel,
  validateClassementManuelField,
} from './utils';
import './ClassementForm.less';

interface ClassementFormProps {
  close: () => void;
}

const ClassementForm = ({ close }: ClassementFormProps): ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const { AutoriteFourriereControllerApi, ReferentielControllerApi } = useApi();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const [informationsTechniques, fetch, fetchInProgress, fetchErrorOccured] =
    useFetchDonneesTechniquesSiv(dossierId);
  const { submit, inProgress } = useSubmitClassementManuel(
    dossierId,
    AutoriteFourriereControllerApi,
    setDossier,
    close,
  );

  return (
    <AsyncComponent
      inProgress={fetchInProgress}
      reload={fetch}
      errorOccured={fetchErrorOccured}
      render={() => {
        if (informationsTechniques) {
          return (
            <>
              <h3 className={'mainTitle'}>
                Informations techniques du véhicule
              </h3>
              <BaseForm
                initialValues={convertInformationsTechniquesToClassementManuel(
                  informationsTechniques,
                )}
                isSubmitting={inProgress}
                validateField={async (field, formValues) => {
                  return validateClassementManuelField(
                    dossierId,
                    AutoriteFourriereControllerApi,
                    generateClassementManuelRequestFromClassement(formValues),
                    field,
                  );
                }}
                onSubmit={submit}
                onCancel={close}
                labelCol={{ span: CSSUtils.XLargeWrapperCol }}
                wrapperCol={{ span: CSSUtils.XLargeWrapperCol }}
                inputs={[
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.ETRANGER,
                    name: 'vehiculeEtranger',
                    label: 'Véhicule étranger',
                    valuePropName: 'checked',
                    disabled: true,
                    render: ({ disabled }) => {
                      return <Checkbox disabled={disabled} />;
                    },
                  },
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.DANGEREUX,
                    name: 'dangereux',
                    label: 'Mention "déclaré comme dangereux"',
                    valuePropName: 'checked',
                    disabled: !!informationsTechniques?.dangereux,
                    render: ({ disabled }) => {
                      return <Checkbox disabled={disabled} />;
                    },
                  },
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.REPARABLE,
                    name: 'reparable',
                    label: 'Mention réparable',
                    valuePropName: 'checked',
                    disabled: !!informationsTechniques?.reparable,
                    render: ({ disabled }) => {
                      return <Checkbox disabled={disabled} />;
                    },
                  },
                  {
                    field:
                      ClassementManuelViolationDtoFormFieldEnum.PREMIERE_IMMATRICULATION,
                    name: 'datePremiereImmatriculation',
                    label: 'Date de première immatriculation',
                    disabled:
                      !!informationsTechniques?.datePremiereImmatriculation,
                    render: () => {
                      return <BaseDatePicker />;
                    },
                  },
                  {
                    name: 'motifMEF',
                    label: 'Nature de mise en fourrière',
                    disabled: true,
                    render: ({ disabled }) => {
                      return <BaseInput disabled={disabled} />;
                    },
                  },
                  {
                    name: 'marque',
                    label: 'Marque',
                    disabled: true,
                    render: ({ disabled }) => {
                      return <BaseInput disabled={disabled} />;
                    },
                  },
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.GENRE,
                    name: 'genreId',
                    label: 'Genre',
                    disabled: !!informationsTechniques?.genreId,
                    render: ({ disabled }) => {
                      return (
                        <AsyncSelect
                          disabled={disabled}
                          showSearch={true}
                          fetchOptions={() => {
                            return fetchGenre(ReferentielControllerApi);
                          }}
                          getOptionValue={(genre: GenreDto) => genre.id}
                          getOptionLabel={(genre: GenreDto) =>
                            `${genre.code} - ${genre.libelle}`
                          }
                        />
                      );
                    },
                  },
                  {
                    field:
                      ClassementManuelViolationDtoFormFieldEnum.CARROSSERIE,
                    name: 'carrosserieId',
                    label: 'Carrosserie',
                    disabled: !!informationsTechniques?.carrosserieId,
                    render: ({ disabled }) => {
                      return (
                        <AsyncSelect
                          disabled={disabled}
                          showSearch={true}
                          fetchOptions={() => {
                            return fetchCarrosserie(ReferentielControllerApi);
                          }}
                          getOptionValue={(
                            carrosserie: CarrosserieClassementManuelDto,
                          ) => carrosserie.id}
                          getOptionLabel={(
                            carrosserie: CarrosserieClassementManuelDto,
                          ) => carrosserie.libelle}
                        />
                      );
                    },
                  },
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.ENERGIE,
                    name: 'energieId',
                    label: 'Energie',
                    disabled: !!informationsTechniques?.energieId,
                    render: ({ disabled }) => {
                      return (
                        <AsyncSelect
                          disabled={disabled}
                          showSearch={true}
                          fetchOptions={() => {
                            return fetchEnergie(ReferentielControllerApi);
                          }}
                          getOptionValue={(energie: EnergieDto) => energie.id}
                          getOptionLabel={(energie: EnergieDto) =>
                            energie.libelle
                          }
                        />
                      );
                    },
                  },
                  {
                    field: ClassementManuelViolationDtoFormFieldEnum.PUISSANCE,
                    name: 'puissance',
                    label: 'Puissance fiscale',
                    disabled: !!informationsTechniques?.puissanceCv,
                    render: ({ disabled }) => {
                      return <BaseInputNumber disabled={disabled} />;
                    },
                  },
                ]}
                renderButtonAction={(Submit, Cancel) => {
                  return (
                    <div className="UpdateInformationsTechniques">
                      <div className="UpdateAction">{Cancel}</div>
                      <div className="UpdateAction">{Submit}</div>
                    </div>
                  );
                }}
              />
            </>
          );
        }
        return <></>;
      }}
    />
  );
};

export default ClassementForm;
