import React from 'react';

import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { FilterDashboardTypeAf } from '../../types';

interface FilterProps {
  filter: Partial<FilterDashboardTypeAf>;
  setFilter: (value: Partial<FilterDashboardTypeAf>) => void;
}

export function Filter({ filter, setFilter }: FilterProps): React.ReactElement {
  const { valid } = filter;

  function setDisplayInvalidTypeAf(event: CheckboxChangeEvent) {
    setFilter({
      valid: !event.target.checked,
    });
  }

  return (
    <div className="dashboard-header-filter">
      <AntdCheckbox checked={!valid} onChange={setDisplayInvalidTypeAf}>
        Afficher les types inactifs
      </AntdCheckbox>
    </div>
  );
}
