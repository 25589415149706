import React from 'react';
import Uploader from 'components/WrappedComponents/Uploader/Uploader';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';
import UploadCard from 'components/Card/UploadCard';
import { useImportFile } from 'hooks/useImportFile';
import { useApi } from 'hooks/ApiStoreContext';

interface FicheDescriptiveImportProps {
  label: string;
}

export default function FicheDescriptiveImport({
  label,
}: FicheDescriptiveImportProps): React.ReactElement {
  const { AdminFonctionnelControllerApi: api } = useApi();
  const { importFile, importStatus } = useImportFile(
    api.saveFicheDescriptiveUsingPOST.bind(api),
  );

  return (
    <UploadCard status={importStatus}>
      <Uploader
        title={label}
        accept={supportedFilesDetails.PDF.extension}
        fileTypes={supportedFilesDetails.PDF.fileTypes}
        importRoute={importFile()}
      />
    </UploadCard>
  );
}
