import React from 'react';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  ExpediteurNotificationViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useUserStore } from 'hooks/UserStoreContext';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import AutocompleteCommune from 'components/BaseForm/Autocomplete/AutocompleteCommune';
import BaseInput from 'components/BaseForm/BaseInput';
import {
  buildInitialsFormValues,
  useSubmitUpdateCoordonneesUniteRule,
  useValidateUpdateCoordonneesUnite,
} from './utils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { FormInstance } from 'antd';
import { CoordonneesUniteFoFormValues } from './types';
import { useFetchUniteFo } from 'hooks/search/useSearchUniteFo';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

import './NotificationCoordonneesUniteFoForm.less';

export interface NotificationCoordonneesUniteFoFormProps {
  editable: boolean;
  onCancel: () => void;
}

export default function NotificationCoordonneesUniteFoForm({
  editable,
  onCancel,
}: NotificationCoordonneesUniteFoFormProps): React.ReactElement {
  // Extract context values
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const user = useUserStore();
  const idCorrelationUniteFo =
    dossier.body?.traitement?.expediteurNotification?.idCorrelationUniteFo ||
    user.idCorrelationUniteFO;

  // Retrieve UniteFO
  const [expediteur, fetchUniteFo, fetchInprogress, fetchHasError] =
    useFetchUniteFo(idCorrelationUniteFo);

  // Hook validate + submit
  const { submit, inProgress } = useSubmitUpdateCoordonneesUniteRule(
    dossierId,
    updatedDossier => {
      setDossier(updatedDossier);
      onCancel();
    },
  );
  const { validate } = useValidateUpdateCoordonneesUnite(dossierId);

  return (
    <AsyncComponent
      reload={fetchUniteFo}
      inProgress={fetchInprogress}
      errorOccured={fetchHasError}
      render={() => {
        const initials = buildInitialsFormValues(dossier, expediteur);
        return (
          expediteur && (
            <>
              <h3 className="mainTitle">Coordonnées de l&apos;unité</h3>
              {editable ? (
                <BaseForm
                  initialValues={initials}
                  onSubmit={submit}
                  isSubmitting={inProgress}
                  validateField={validate}
                  inputs={[
                    {
                      field:
                        ExpediteurNotificationViolationDtoFormFieldEnum.UNITE,
                      label: 'Unité',
                      name: 'uniteFo',
                      required: true,
                      render: () => {
                        return (
                          <BaseSelectWithSearch
                            useSearchEntities={useAsyncSearchUniteFo(
                              AutocompleteUniteFOResultDtoFieldEnum.UNITE,
                              expediteur,
                            )}
                            getOptionLabel={unite => unite.unite}
                            getOptionValue={unite => unite.idCorrelation}
                            dependencies={[
                              {
                                name: 'codePostal',
                                getValue: unite =>
                                  unite.codePostal ?? undefined,
                              },
                              {
                                name: 'adresse',
                                getValue: unite => unite.adresse ?? undefined,
                              },
                              {
                                name: 'commune',
                                getValue: unite => unite.ville ?? undefined,
                              },
                            ]}
                          />
                        );
                      },
                    },
                    {
                      label: 'Adresse',
                      name: 'adresse',
                      render: props => {
                        return (
                          <AutocompleteAdresse
                            {...props}
                            codePostalField="codePostal"
                            communeField="commune"
                          />
                        );
                      },
                    },
                    {
                      field:
                        ExpediteurNotificationViolationDtoFormFieldEnum.CODE_POSTAL,
                      label: 'Code postal',
                      name: 'codePostal',
                      render: () => {
                        return <BaseInput />;
                      },
                    },
                    {
                      label: 'Commune',
                      name: 'commune',
                      render: props => {
                        return (
                          <AutocompleteCommune
                            {...props}
                            codePostalField="codePostal"
                          />
                        );
                      },
                    },
                  ]}
                  onCancel={(
                    form: FormInstance<CoordonneesUniteFoFormValues>,
                  ) => {
                    form.setFieldsValue(initials);
                    onCancel();
                  }}
                />
              ) : (
                <div className="fields">
                  <FieldDisplay
                    additionalValueClassName="multiLineValue"
                    label="Unité"
                    value={expediteur?.unite}
                  />
                  <FieldDisplay
                    label="Adresse"
                    value={initials?.adresse ?? null}
                  />
                  <FieldDisplay
                    label="Code postal"
                    value={initials?.codePostal ?? null}
                  />
                  <FieldDisplay
                    label="Commune"
                    value={initials?.commune ?? null}
                  />
                </div>
              )}
            </>
          )
        );
      }}
    />
  );
}
