import React from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { AvailableAction } from '../types';

import './Actions.less';

interface ActionsProps {
  actions: AvailableAction[];
}

const Actions = ({ actions }: ActionsProps): React.ReactElement => {
  if (actions.length === 1) {
    const action = actions[0];
    return (
      <AntdButton
        onClick={action.onClick}
        type="primary"
        shape="round"
        className="ActionsButton"
      >
        {action.title}
      </AntdButton>
    );
  }

  const menu = (
    <AntdMenu>
      {actions.map((action: AvailableAction, index: number) => {
        return (
          <AntdMenu.Item key={index} onClick={action.onClick}>
            {action.title}
          </AntdMenu.Item>
        );
      })}
    </AntdMenu>
  );

  return (
    <AntdDropdown overlay={menu}>
      <AntdButton
        disabled={actions.length === 0}
        type="primary"
        shape="round"
        className="ActionsButton"
      >
        Actions <DownOutlined />
      </AntdButton>
    </AntdDropdown>
  );
};

export default Actions;
