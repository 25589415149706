import React from 'react';
import { Button as AntdButton } from 'antd';
import { RequiredUserActionsDto } from 'lib_api/lib/api/gen';

import { useConfirmProfile } from 'hooks/utils/useConfirmProfil';

import './ProfileConfirmation.less';

interface ProfileConfirmationProps {
  username: string;
  requiredActions: RequiredUserActionsDto;
  updateRequiredActions: () => void;
}

export const ProfileConfirmation = ({
  username,
  requiredActions,
  updateRequiredActions,
}: ProfileConfirmationProps): React.ReactElement => {
  const confirmProfile = useConfirmProfile(username);

  return (
    <div className="confirmProfile">
      <p className="confirmationMessage">
        <strong>
          Je confirme que les informations de mon profil sont exactes :
        </strong>
      </p>
      <AntdButton
        className={'confirmProfileButton'}
        type={'primary'}
        shape={'round'}
        onClick={async () => {
          await confirmProfile(requiredActions);
          updateRequiredActions();
        }}
      >
        Je confirme
      </AntdButton>
    </div>
  );
};
