import React from 'react';

import { FilterButton, SearchBar } from 'components/WrappedComponents';

import { FilterDashboardComptesUtilisateurs } from '../DashboardHeader/types';
import AdvancedSearchForm from './AdvancedSearchForm/AdvancedSearchForm';

import './Filter.less';

interface Props {
  setFilter: (value: Partial<FilterDashboardComptesUtilisateurs>) => void;
  filter: Partial<FilterDashboardComptesUtilisateurs>;
}

export default function Filter({
  filter,
  setFilter,
}: Props): React.ReactElement {
  return (
    <div className="dashboard-header-filter">
      <SearchBar
        value={filter.lastName}
        setValue={value => setFilter({ ...filter, lastName: value })}
        antdProps={{
          placeholder: 'Rechercher par nom',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
      <FilterButton
        setFilter={setFilter}
        filter={filter}
        textInactiveFilter={'Recherche avancée'}
        textActiveFilter={'Filtres (actifs)'}
      >
        {(setter, data) => (
          <AdvancedSearchForm setFilter={setter} filter={data} />
        )}
      </FilterButton>
    </div>
  );
}
