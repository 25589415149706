import React from 'react';
import classNames from 'classnames';

import { Banner } from 'components/Banner';

import './Header.less';

interface HeaderProps {
  className?: string;
}

const UnauthentifiedHeader = ({
  className,
}: HeaderProps): React.ReactElement => {
  return (
    <header className={classNames('HeaderContainer', className)}>
      <div className={'TopBarContainer'}>
        <div className={'Left'}>
          <Banner className={'Banner'} pathToRedirect={'/'} />
        </div>
      </div>
    </header>
  );
};

export default UnauthentifiedHeader;
