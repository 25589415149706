import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { FilterDashboardComptesUtilisateurs } from 'pages/admin-fonctionnel/Comptes/Dashboard/DashboardHeader/types';

interface ComptesUtilisateursFilterContextContent {
  filter: Partial<FilterDashboardComptesUtilisateurs>;
  setFilter: Dispatch<
    SetStateAction<Partial<FilterDashboardComptesUtilisateurs>>
  >;
}

const ComptesUtilisateursFilterContext =
  createContext<ComptesUtilisateursFilterContextContent | null>(null);

export const useComptesUtilisateursFilterContext = (): [
  Partial<FilterDashboardComptesUtilisateurs>,
  Dispatch<SetStateAction<Partial<FilterDashboardComptesUtilisateurs>>>,
] => {
  const context = useContext(ComptesUtilisateursFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter];
};

const ComptesUtilisateursFilterProvider =
  ComptesUtilisateursFilterContext.Provider;

export default ComptesUtilisateursFilterProvider;
