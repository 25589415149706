import React from 'react';

import {
  AdminFonctionnelControllerApi,
  DossierModificationViolationsDto,
  UpdateEntitesRattachementViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { UpdateEntiteRattachementFormValues } from './types';

type BuildValidateFunction = (
  values: UpdateEntiteRattachementFormValues,
  key: keyof UpdateEntiteRattachementFormValues,
  field: UpdateEntitesRattachementViolationDtoFormFieldEnum,
  value: unknown,
) => Promise<DossierModificationViolationsDto>;

function buildValidateFunction(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
): BuildValidateFunction {
  return (
    values: UpdateEntiteRattachementFormValues,
    key: keyof UpdateEntiteRattachementFormValues,
    field: UpdateEntitesRattachementViolationDtoFormFieldEnum,
    value: unknown,
  ) => {
    const request = {
      ...values,
      [key]: value,
    };

    return refController.validateUpdateEntitesRattachementFieldUsingPOST(
      field,
      dossierId,
      request,
    );
  };
}

export default buildValidateFunction;
