import React, { useEffect, useRef } from 'react';
import { Spin as AntdSpin } from 'antd';

import {
  CreateUserRequestDtoProfileEnum,
  CreateViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { Select, useFormActionsContext } from 'components/WrappedComponents';
import { Empty } from 'components/WrappedComponents/Empty';
import { OptionProps } from 'types/searchResult';

import { useApi } from 'hooks/ApiStoreContext';
import { useRefAutoriteFourriere } from 'hooks/referentiels/useRefAutoriteFourriere';
import { useRefFourriere } from 'hooks/referentiels/useRefFourriere';
import { useRefUniteFoSearch } from 'hooks/referentiels/useRefUniteFoSearch';
import { CreationCompteFormValues } from 'types/Compte/CreationCompte';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import {
  autoriteFourriereToItem,
  fourriereToItem,
  uniteFoToItem,
} from '../../Modification/utils';
import {
  generateCreateUserRequestDtoFromFormData,
  validateCreateUserRequest,
} from '../utils';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';

import '../../style.less';

interface SelectUniteRattachement extends FieldProps<CreationCompteFormValues> {
  profile?: CreateUserRequestDtoProfileEnum;
}

function SelectUniteRattachement({
  values,
  profile,
}: SelectUniteRattachement): React.ReactElement {
  const [refUniteFo, searchUniteFo, isFetchingUniteFo, fetchUniteFo] =
    useRefUniteFoSearch();
  const refAutoriteFourriere = useRefAutoriteFourriere();
  const refFourriere = useRefFourriere();

  const formActions = useFormActionsContext();
  const valuesRef = useRef<CreationCompteFormValues>();
  valuesRef.current = values;

  const cuController = useApi().AdminFonctionnelControllerApi;
  const key: keyof CreationCompteFormValues = 'uniteRattachement';

  const isProfileWithUniteRattachement = (
    profile: CreateUserRequestDtoProfileEnum,
  ) => {
    return [
      CreateUserRequestDtoProfileEnum.GARDIEN_FOURRIERE,
      CreateUserRequestDtoProfileEnum.AUTORITE_FOURRIERE,
      CreateUserRequestDtoProfileEnum.FORCE_DE_L_ORDRE,
    ].includes(profile);
  };

  useEffect(() => {
    if (
      !(
        values.profile &&
        isProfileWithUniteRattachement(values.profile) &&
        !values.uniteRattachement
      )
    ) {
      void formActions.validateFields([key]);
    }
  }, [values.profile, formActions, key, values.uniteRattachement]);

  // content if no data foud
  let notFoundContent = undefined;
  // list of options of select
  let selectItems: OptionProps[] = [];
  // action on search in select
  let onSearch = undefined;

  switch (profile) {
    case CreateUserRequestDtoProfileEnum.GARDIEN_FOURRIERE:
      selectItems =
        refFourriere !== undefined
          ? // Ref fetched
            refFourriere.referenceDtoList
              .sort((a, b) => a.raisonSociale.localeCompare(b.raisonSociale))
              .map(fourriereToItem)
          : [];
      break;
    case CreateUserRequestDtoProfileEnum.AUTORITE_FOURRIERE:
      selectItems =
        refAutoriteFourriere !== undefined
          ? // Ref fetched
            refAutoriteFourriere.referenceDtoList
              .sort((a, b) => a.libelleComplet.localeCompare(b.libelleComplet))
              .map(autoriteFourriereToItem)
          : [];
      break;
    case CreateUserRequestDtoProfileEnum.FORCE_DE_L_ORDRE:
      selectItems =
        refUniteFo !== undefined
          ? // Ref fetched
            refUniteFo.referenceDtoList
              .sort((a, b) => a.abreviation.localeCompare(b.abreviation))
              .map(uniteFoToItem)
          : [];
      notFoundContent = isFetchingUniteFo ? (
        <AntdSpin size="small" />
      ) : searchUniteFo !== undefined && searchUniteFo !== '' ? (
        <Empty />
      ) : null;
      onSearch = fetchUniteFo;
      break;
    default:
      break;
  }

  return refFourriere !== undefined && refAutoriteFourriere !== undefined ? (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Entité de rattachement',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateCreateUserRequestDtoFromFormData({
                  ...values,
                  uniteRattachement: value,
                });

                return await validateCreateUserRequest(
                  cuController,
                  requestObject,
                  CreateViolationDtoFormFieldEnum.ENTITE_RATTACHEMENT,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          getValueProps: (value: unknown) => {
            if (typeof value === 'string') {
              return { value: value };
            }
            return {
              value: undefined,
            };
          },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        disabled:
          profile === undefined || !isProfileWithUniteRattachement(profile),
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        onSearch: onSearch,
        notFoundContent: notFoundContent,
        allowClear: true,
      }}
    />
  ) : (
    <div className="WaitingForReferentiels">
      <label className="label" title="Entité de rattachement">
        Entité de rattachement
      </label>
      <div className="input">
        <AntdSpin />
      </div>
    </div>
  );
}

export default SelectUniteRattachement;
