import { VersionedReferentielFieldProps } from '../type';
import { CentreVHUViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

export enum CentreVHUSortOptions {
  'RAISON_SOCIALE' = 'RAISON_SOCIALE',
  'NUMERO_AGREMENT' = 'NUMERO_AGREMENT',
  'COMMUNE' = 'COMMUNE',
  'CODE_POSTAL' = 'CODE_POSTAL',
}

export type CentreVHUFormValues = {
  raisonSociale?: string;
  siren?: string;
  adresse?: string;
  complementAdresse?: string;
  codePostal?: string;
  commune?: string;
  telephone?: string;
  email?: string;
  numeroAgrement?: string;
  prefecture?: string;
};

export type CentreVHUFieldProps = VersionedReferentielFieldProps<
  CentreVHUFormValues,
  CentreVHUViolationDtoFormFieldEnum
>;
