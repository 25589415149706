import React from 'react';
import { Input } from 'components/WrappedComponents';

import { FieldProps, FicheProfilAutoriteFourriere } from '../types';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';

function InputIntituleComplet({
  values,
  isEditable,
}: FieldProps): React.ReactElement {
  const key: keyof FicheProfilAutoriteFourriere = 'intituleComplet';
  const label = 'Intitulé complet';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      inputProps={{
        placeholder: FormPlaceholders.Input,
      }}
    />
  );
}

export default InputIntituleComplet;
