import {
  AutocompleteUniteFOResultDto,
  AutocompleteUniteFOResultDtoFieldEnum,
  ReferentielControllerApi,
  ReferentielSearchResultDtoUniteFOAdminResponseDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { UniteFoSort } from 'enums/referentiels/UniteFo';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';

export interface FilterSearchUniteFo {
  type?: string;
  abreviation?: string;
  code?: string;
  codePostal?: string;
  unite?: string;
  valid?: boolean;
  ville?: string;
  idCorrelation?: string;
  rpsiStatus?:
    | 'MISE_A_JOUR_ERREUR_VALIDATION'
    | 'NOUVELLE_UNITE'
    | 'ECHEC_DESACTIVATION_UNITE_PARENT'
    | 'ECHEC_DESACTIVATION_DOSSIER_ATTACHE';
}
export function searchUniteFOUsingSpecs(
  controller: ReferentielControllerApi,
  filters: FilterSearchUniteFo,
  sorts: TableSort<UniteFoSort>,
  pagination: TablePagination,
): Promise<ReferentielSearchResultDtoUniteFOAdminResponseDto> {
  return controller.searchUniteFoUsingSpecsUsingGET({
    filters: {
      ...filters,
      page: pagination.page,
      pageSize: pagination.pageSize,
    },
    sorts: {
      abreviation: sorts?.ABREVIATION,
      code: sorts?.CODE,
      codeInsee: sorts?.CODE_INSEE,
      codePostal: sorts?.CODE_POSTAL,
      type: sorts?.TYPE,
      ville: sorts?.VILLE,
    },
  });
}

export function searchUniteFO(
  controller: ReferentielControllerApi,
  field: AutocompleteUniteFOResultDtoFieldEnum,
  searched: string,
): Promise<AutocompleteUniteFOResultDto> {
  return controller.searchedValidUniteFOUsingGET({ field, searched });
}

export function fetchUniteFo(
  refController: ReferentielControllerApi,
  idCorrelationUniteFo: string,
): Promise<UniteFOResponseDto> {
  return refController.getValidUniteFOByIdCorrelationUsingGET(
    idCorrelationUniteFo,
  );
}
