import {
  AutoriteFourriereDto,
  MotifMefDto,
  EtatDto,
  VerrouillageDto,
  AccessoiresDto,
} from 'lib_api/lib/api/gen';
import { etatMapping } from './enumData';

export const autoriteFourriereDtoToString = (
  dto: AutoriteFourriereDto,
): string => {
  return dto.libelleComplet;
};

export const motifMefDtoToString = (dto: MotifMefDto): string => {
  return `${dto.motifMef}`;
};

export const etatDtoToString = (dto: EtatDto): string => {
  const detail = dto.detail ? ` - ${dto.detail}` : '';
  return etatMapping[dto.etat].label + detail;
};

export const verrouillageDtoToString = (
  dto: VerrouillageDto,
): string | null => {
  if (dto.coffres && dto.portes) {
    return 'Portes et coffre';
  } else if (dto.coffres) {
    return 'Coffre';
  } else if (dto.portes) {
    return 'Portes';
  }

  return 'Portes et coffre déverrouillés';
};

export const accessoiresDtoToString = (dto: AccessoiresDto): string | null => {
  if (dto.autoRadioVisible && dto.posteCbVisible) {
    return 'Auto-radio et poste c.b';
  } else if (dto.autoRadioVisible) {
    return 'Auto-radio';
  } else if (dto.posteCbVisible) {
    return 'Poste c.b';
  }

  return null;
};
