import React from 'react';
import { Row as AntdRow, Col as AntdCol } from 'antd';
import { Moment } from 'moment';

import { SemaineHoraire } from 'types/FicheProfil/FicheProfilGardienFourriere';
import OpeningHoursWidget from './OpeningHoursComponent';
import HoursWidget from './HoursWidget';

interface DayComponentProp<
  T extends keyof SemaineHoraire & string,
  V extends keyof SemaineHoraire & string,
> {
  values: SemaineHoraire;
  surRendezVousKey: SemaineHoraire[V] extends boolean ? V : never;
  horairesOuverturesKey: SemaineHoraire[T] extends Moment[][] | undefined
    ? T
    : never;
  horairesAppelKey: SemaineHoraire[T] extends Moment[][] | undefined
    ? T
    : never;
  dayLabel: string;
}

function DayComponent<
  T extends keyof SemaineHoraire & string,
  V extends keyof SemaineHoraire & string,
>({
  values,
  surRendezVousKey,
  horairesOuverturesKey,
  horairesAppelKey,
  dayLabel,
}: DayComponentProp<T, V>): React.ReactElement {
  return (
    <div className="day">
      <span className="day-label">{dayLabel}</span>
      <AntdRow>
        <AntdCol span={12} className="custom-left-border">
          <span>{"Horaires d'ouverture :"}</span>
          <OpeningHoursWidget
            values={values}
            surRendezVousKey={surRendezVousKey}
            horairesOuverturesKey={horairesOuverturesKey}
          />
        </AntdCol>
        <AntdCol span={12}>
          <span className={'calling-hours-span'}>{"Horaires d'appel :"}</span>
          <HoursWidget values={values} horairesKey={horairesAppelKey} />
        </AntdCol>
      </AntdRow>
    </div>
  );
}

export default DayComponent;
