import React from 'react';

import { CentreVHUFormValues } from '../types';
import ModalEditionCentreVHU from './ModalEditionCentreVHU';
import { useSubmitCreateCentreVHU, useValidateCreateCentreVHU } from '../utils';

interface ModalCreationProps {
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalCreation({
  visible,
  close,
  refreshDashboard,
}: ModalCreationProps): React.ReactElement {
  const initials: CentreVHUFormValues = {};
  const { submit, inProgress } = useSubmitCreateCentreVHU(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateCentreVHU();

  return (
    <ModalEditionCentreVHU
      close={close}
      visible={visible}
      title="Créer un centre VHU"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}

export default ModalCreation;
