import { useState, useEffect } from 'react';

import { ReferenceDtoFaqDomainDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefFaqType = ReferenceDtoFaqDomainDto | undefined;

export const useRefFAQDomains = (): RefFaqType => {
  const behaviourOnError = useHandleBackErrors();
  const [refFaq, setRefFaq] = useState<RefFaqType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refFaq === undefined) {
      referentielControllerApi
        .getOnlyValidFaqByDomainsUsingGET()
        .then(fetchedRef => {
          setRefFaq(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, refFaq]);
  return refFaq;
};
