import React from 'react';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import BaseModal from 'components/WrappedComponents/BaseModal';
import { FaqAdminDto } from 'lib_api/lib/api/gen';
import { MarkdownFieldDisplay } from 'components/FieldDisplay/MarkdownFieldDisplay';
import { mapUserProfiles } from '../../utils';

interface ModalDetailsProps {
  faq: FaqAdminDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  faq,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <BaseModal isVisible={visible} closeModal={close} width={980}>
      <>
        <FieldDisplay
          labelClass="labelDetail"
          label="Id de corrélation"
          value={faq.idCorrelation}
        />
        <FieldDisplay
          labelClass="labelDetail"
          label="Domaine"
          value={faq.domainName}
        />
        <FieldDisplay
          additionalValueClassName="multiLineValue"
          labelClass="labelDetail"
          label="Titre"
          value={faq.title}
        />
        <FieldDisplay
          additionalValueClassName="multiLineValue"
          labelClass="labelDetail"
          label="Rôles concernés"
          value={mapUserProfiles(faq.profiles)}
        />
        <MarkdownFieldDisplay
          additionalValueClassName="textValue"
          labelClass="labelDetail"
          label="Contenu"
          value={faq.content}
        />
      </>
    </BaseModal>
  );
}

export default ModalDetails;
