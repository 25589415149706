import React from 'react';

import {
  FaqAdminDto,
  FaqViolationDtoFormFieldEnum,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { FaqFormValues } from '../../types';
import { InputContent, InputTitle, SelectProfile } from '../Fields';
import {
  buildUpdateFaqInitialValues,
  convertFaqFormValueToFaqRequestDto,
} from '../../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { FormModal } from 'components/WrappedComponents';
import InputDomain from '../Fields/InputDomain';

interface ModalCreationProps {
  faq: FaqAdminDto;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalModification({
  faq,
  visible,
  close,
  refreshDashboard,
}: ModalCreationProps): React.ReactElement {
  const idCorrelation = faq.idCorrelation;
  const initialValues: FaqFormValues = buildUpdateFaqInitialValues(faq);
  const controllerApi = useApi().AdminFonctionnelControllerApi;

  const validateFunction: (
    values: FaqFormValues,
    key: keyof FaqFormValues,
    field: FaqViolationDtoFormFieldEnum,
    value: unknown,
  ) => Promise<ReferentielViolationsDto> = (values, key, field, value) => {
    const request = convertFaqFormValueToFaqRequestDto({
      ...values,
      [key]: value,
    });
    return controllerApi.validateUpdateFaqFieldUsingPOST(
      field,
      idCorrelation,
      request,
    );
  };

  return (
    <FormModal
      modalTitle="Mettre à jour la question"
      initialValues={initialValues}
      controllerValidate={(values: FaqFormValues) => {
        return controllerApi.validateUpdateFaqUsingPOST(
          idCorrelation,
          convertFaqFormValueToFaqRequestDto(values),
        );
      }}
      controllerValidateField={validateFunction}
      validationKey="faqViolationsDto"
      controllerSubmit={(values: FaqFormValues) => {
        return controllerApi.updateFaqUsingPUT(
          idCorrelation,
          convertFaqFormValueToFaqRequestDto(values),
        );
      }}
      visible={visible}
      close={close}
      refreshDashboard={refreshDashboard}
    >
      {(values, validateField): React.ReactElement => {
        return (
          <>
            <InputDomain values={values} validateField={validateField} />
            <InputTitle values={values} validateField={validateField} />
            <SelectProfile values={values} validateField={validateField} />
            <InputContent values={values} validateField={validateField} />
          </>
        );
      }}
    </FormModal>
  );
}

export default ModalModification;
