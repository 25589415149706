import React, { useState } from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  ConditionsReactivateUniteFoDto,
  UniteFOAdminResponseDto,
} from 'lib_api/lib/api/gen';

import ModalDetails from './Modals/ModalDetails';
import { useApi } from 'hooks/ApiStoreContext';
import BaseModal from 'components/WrappedComponents/BaseModal';
import { useChangeValidityUniteFo } from './utils';
import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation, {
  ConditionReactivateProps,
} from '../Modals/ModalReactivation/ModalReactivation';
import UsesUniteFo from './UsesUniteFo/UsesUniteFo';
import ConditionReactivateUniteFo from './ConditionReactivateUniteFo/ConditionReactivateUniteFo';
import ModalUpdate from './Modals/ModalUpdate';
import ModalMigrationDossiers from '../Modals/ModalMigrationDossiers/ModalMigrationDossiers';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  unite: UniteFOAdminResponseDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  unite,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [isMigrateDossiersOpen, setIsMigrateDossiersOpen] =
    useState<boolean>(false);
  const [isChangeValidityOpen, setIsChangeValidityOpen] =
    useState<boolean>(false);

  const controller = useApi().AdminFonctionnelControllerApi;
  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ] = useChangeValidityUniteFo(controller);
  const conditionUnlock = (
    conditions: ConditionsReactivateUniteFoDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item
        onClick={() => {
          setIsUpdateOpen(true);
        }}
      >
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item
        onClick={() => {
          setIsChangeValidityOpen(true);
        }}
      >
        {unite.valid ? "Désactiver l'unité" : "Réactiver l'unité"}
      </AntdMenu.Item>
      {unite.valid && (
        <AntdMenu.Item
          onClick={() => {
            setIsMigrateDossiersOpen(true);
          }}
        >
          Migrer les dossiers
        </AntdMenu.Item>
      )}
    </AntdMenu>
  );
  const closeUpdateValidity = () => {
    setIsChangeValidityOpen(false);
  };

  return (
    <>
      <AntdButton
        onClick={() => {
          setIsDetailsOpen(true);
        }}
      >
        Détails
      </AntdButton>
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {isDetailsOpen && (
        <ModalDetails
          unite={unite}
          visible={isDetailsOpen}
          close={() => {
            setIsDetailsOpen(false);
          }}
        />
      )}
      {isMigrateDossiersOpen && (
        <BaseModal
          width={700}
          isVisible={isMigrateDossiersOpen}
          closeModal={() => {
            setIsMigrateDossiersOpen(false);
          }}
        >
          <ModalMigrationDossiers
            close={() => {
              setIsMigrateDossiersOpen(false);
            }}
            visible={isMigrateDossiersOpen}
            unite={unite}
            usesRequest={getUsesRequest}
          />
        </BaseModal>
      )}

      <ModalUpdate
        refreshDashboard={refreshDashboard}
        unite={unite}
        visible={isUpdateOpen}
        close={() => {
          setIsUpdateOpen(false);
        }}
      />
      {isChangeValidityOpen &&
        (unite.valid ? (
          <ModalDesactivation
            idCorrelation={unite.idCorrelation}
            titleModal="Désactiver l'unité"
            usesRequest={getUsesRequest}
            submitDeprecate={submitDeprecateReferentiel}
            componentUses={UsesUniteFo}
            visible={isChangeValidityOpen}
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
          />
        ) : (
          <ModalReactivation
            idCorrelation={unite.idCorrelation}
            visible={isChangeValidityOpen}
            titleModal="Réactiver l'unité"
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
            conditionRequest={conditionsReactivateReferentiel}
            componentConditions={({
              conditions,
            }: ConditionReactivateProps<ConditionsReactivateUniteFoDto>) => {
              return (
                <ConditionReactivateUniteFo
                  unite={unite}
                  conditions={conditions}
                />
              );
            }}
            conditionUnlock={conditionUnlock}
            reactivateRequest={submitReactivateReferentiel}
          />
        ))}
    </>
  );
}

export default CellDetailsAndActions;
