import React from 'react';
import { Col as AntdCol, Row as AntdRow } from 'antd';
import { Moment } from 'moment';

import { SemaineHoraire } from 'types/FicheProfil/FicheProfilGardienFourriere';
import RDVCheckbox from '../inputs/RDVCheckBox';
import HoursWidget from './HoursWidget';

interface OpeningHoursWidgetProps<
  T extends keyof SemaineHoraire & string,
  V extends keyof SemaineHoraire & string,
> {
  values: SemaineHoraire;
  surRendezVousKey: SemaineHoraire[V] extends boolean ? V : never;
  horairesOuverturesKey: SemaineHoraire[T] extends Moment[][] | undefined
    ? T
    : never;
}

function OpeningHoursWidget<
  T extends keyof SemaineHoraire & string,
  V extends keyof SemaineHoraire & string,
>({
  values,
  surRendezVousKey,
  horairesOuverturesKey,
}: OpeningHoursWidgetProps<T, V>): React.ReactElement {
  return (
    <div className={'hours-widget'}>
      <AntdRow>
        <AntdCol span={6} />
        <AntdCol span={18}>
          <RDVCheckbox values={values} surRendezVousKey={surRendezVousKey} />
        </AntdCol>
      </AntdRow>

      {!values[surRendezVousKey] && (
        <HoursWidget values={values} horairesKey={horairesOuverturesKey} />
      )}
    </div>
  );
}

export default OpeningHoursWidget;
