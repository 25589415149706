import { ModeleDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';

export function fetchModeleByMarqueIdCorrelation(
  refController: ReferentielControllerApi,
  idCorrelationMarque: string,
): Promise<ModeleDto[]> {
  return refController
    .getModeleByMarqueIdCorrelationUsingGET(idCorrelationMarque)
    .then(response => {
      return response.referenceDtoList.sort((a, b) =>
        a.libelle.localeCompare(b.libelle),
      );
    });
}
