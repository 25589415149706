import React from 'react';
import { Tag as AntdTag } from 'antd';
import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { statutEnumMapping } from 'utils/enumData';

import { getStatutFromDossier } from 'utils/StatutUtils';
import './SuiviVehiculeMainInfo.less';

export function buildStatutContent(
  dossier: DossierResponseDto,
): React.ReactNode {
  const statut = getStatutFromDossier(dossier);

  if (statut === undefined) {
    return undefined;
  } else {
    const { tagColor, label } = statutEnumMapping[statut];
    return (
      <AntdTag color={tagColor} className={'statusStyle'}>
        {label}
      </AntdTag>
    );
  }
}
