import { FixDataNotificationManuelleRequestDto } from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { FixDataNotificationManuelleFormValues } from './types';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { notificationManuelleInfosEnumMapping } from 'pages/forces-de-l-ordre/PageDossier/Actions/InfosNotification/utils';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useApi } from 'hooks/ApiStoreContext';
import { useBoolean } from 'utils/genericUtils';

export default function useSubmitFixNotificationManuelle(
  closeModal: () => void,
): {
  inProgress: boolean;
  submit: (values: FixDataNotificationManuelleFormValues) => void;
} {
  const [
    {
      result: { id: dossierId },
      setDossier,
    },
    { fetchEvents },
  ] = useDossierAdminContext();

  const {
    value: inProgress,
    setIsTrue: startProgress,
    setIsFalse: stopProgress,
  } = useBoolean();
  const { AdminFonctionnelControllerApi: adminController } = useApi();

  const submitFunction = (values: FixDataNotificationManuelleFormValues) => {
    startProgress();
    adminController
      .fixDataNotificationManuelleUsingPOST(
        dossierId,
        mapFormValuesToRequestDto(values),
      )
      .then(setDossier)
      .then(fetchEvents)
      .then(closeModal)
      .catch(backAlertMessage)
      .finally(stopProgress);
  };

  return {
    submit: submitFunction,
    inProgress: inProgress,
  };
}

//TODO: MINTF-4182 : transférer la gestion de ces dates dans le back
export function mapFormValuesToRequestDto(
  values: FixDataNotificationManuelleFormValues,
): FixDataNotificationManuelleRequestDto {
  return {
    dateEnvoi:
      values.dateEnvoi?.utc()?.startOf('day')?.format(UNIX_TIMESTAMP_FORMAT) ??
      null,
    dateSignature:
      values.dateSignature
        ?.utc()
        ?.startOf('day')
        ?.format(UNIX_TIMESTAMP_FORMAT) ?? null,
    etatDistribution: values.etatDistribution
      ? notificationManuelleInfosEnumMapping[values.etatDistribution]
          .fixDataEnum
      : null,
    justification: values.justification ?? null,
  };
}
