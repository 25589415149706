import { useEffect, useState } from 'react';
import { useApi } from 'hooks/ApiStoreContext';

export const useVersion = (): string | undefined => {
  const { VersionControllerApi } = useApi();
  const [version, setVersion] = useState<string>();

  useEffect(() => {
    VersionControllerApi.getVersionUsingGET()
      .then(versionDto => {
        setVersion(versionDto.version);
      })
      .catch((errorResponse: Response): void => {
        // in theory if fourriere-api is down, the whole front is down
        console.error(errorResponse);
      });
  });

  return version;
};
