import React from 'react';
import { DefaultOptionType } from 'antd/lib/select';

import { useBaseFormContext } from '../BaseFormContext';
import CommonBaseSelect, { CommonBaseSelectProps } from './CommonBaseSelect';

export interface BaseOptionType<OptionType> extends DefaultOptionType {
  option?: OptionType;
}

export interface BaseSelectProps<FormValues, OptionType>
  extends Omit<CommonBaseSelectProps<FormValues, OptionType>, 'options'> {
  options: OptionType[] | ((formValues: FormValues) => OptionType[]);
  getOptionValue: (option: OptionType) => string | number;
  getOptionLabel: (option: OptionType) => string | React.ReactNode;
}

export default function BaseSelect<FormValues, OptionType>({
  options,
  getOptionLabel,
  getOptionValue,
  ...props
}: BaseSelectProps<FormValues, OptionType>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();

  return (
    <CommonBaseSelect
      options={(typeof options === 'function'
        ? options(form.getFieldsValue())
        : options
      ).map(option => {
        return {
          value: getOptionValue(option),
          label: getOptionLabel(option),
          option: option,
        };
      })}
      {...props}
    />
  );
}
