import React from 'react';
import {
  NotificationInfosRequestDto,
  NotificationInfosViolationDtoFormFieldEnum,
  NotificationManuelleInfosDtoEtatDistributionEnum,
} from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import {
  NotificationInfosFieldsProps,
  NotificationInfos,
  validateNotificationInfosRule,
  notificationManuelleInfosEnumMapping,
} from '../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { CSSUtils } from 'utils/CSSUtils';
import { generateNotificationInfosRequestDto } from 'pages/forces-de-l-ordre/PageDossier/Actions/InfosNotification/utils';

function SelectEtatDistribution({
  values,
  dossierId,
}: NotificationInfosFieldsProps): React.ReactElement {
  const foController = useApi().ForceOrdreControllerApi;
  const key: keyof NotificationInfos = 'etatDistribution';

  const selectItems =
    // List of enum options...

    Object.values(NotificationManuelleInfosDtoEtatDistributionEnum).map(
      enumValue => {
        return {
          value: enumValue.toString(),
          displayValue: notificationManuelleInfosEnumMapping[enumValue].libelle,
        };
      },
    );

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Etat de distribution',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                const notificationInfosRequestDto: NotificationInfosRequestDto =
                  generateNotificationInfosRequestDto({
                    ...values,
                    [key]: value,
                  });
                return await validateNotificationInfosRule(
                  dossierId,
                  foController,
                  notificationInfosRequestDto,
                  NotificationInfosViolationDtoFormFieldEnum.ETAT_DISTRIBUTION,
                );
              },
            },
          ],
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        allowClear: true,
      }}
    />
  );
}

export default SelectEtatDistribution;
