import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FluxDto } from 'lib_api/lib/api/gen';
import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type FluxType = FluxDto | null;

export const useFetchFluxVenteVehicules = (
  id: string,
): [FluxType, Dispatch<SetStateAction<FluxType>>] => {
  const behaviourOnError = useHandleBackErrors();
  const [flux, setFlux] = useState<FluxType>(null);
  const controllerApi = useApi().AdminFonctionnelControllerApi;

  useEffect(() => {
    controllerApi
      .searchSpecificFluxUsingGET(id)
      .then(setFlux)
      .catch(behaviourOnError);
  }, [controllerApi, id, behaviourOnError]);

  return [flux, setFlux];
};
