import React from 'react';
import moment from 'moment';
import { Tag as AntdTag } from 'antd';

import {
  ActionsRequisesDtoActionsRequisesEnum,
  DossierSummaryResponseDto,
} from 'lib_api/lib/api/gen/api';
import {
  ColumnProps,
  TableHeaderFunction,
} from 'components/WrappedComponents/Table/types';

import { getStatutFromDossier } from 'utils/StatutUtils';
import { DATE_FORMAT } from 'utils/formats';
import { classementEnumMapping, statutEnumMapping } from 'utils/enumData';
import DashboardHeader from '../DashboardHeader';
import { FilterDashboardGardien } from '../DashboardHeader/Filters/types';
import { GardienSortOptions } from './types';
import {
  LABEL_MARQUE_NON_REFERENCEE,
  LABEL_MODELE_NON_REFERENCE,
} from 'utils/global';
import { buildActionsRequisesContent } from 'utils/actionsRequisesUtils';
import { DashbardImmatriculationCell } from 'components/Dashboard/DashboardImmatriculationCell/DashboardImmatriculationCell';

function buildImmatriculationContent(
  url: string,
  data: DossierSummaryResponseDto,
): React.ReactElement {
  return <DashbardImmatriculationCell url={url} dossier={data} />;
}
function buildStatutContent(data: DossierSummaryResponseDto): React.ReactNode {
  const statut = getStatutFromDossier(data);

  if (statut === undefined) {
    return null;
  } else {
    const { tagColor, label } = statutEnumMapping[statut];
    return <AntdTag color={tagColor}>{label}</AntdTag>;
  }
}

export function buildColumns(
  url: string,
  gfActionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined,
): ColumnProps<DossierSummaryResponseDto, GardienSortOptions>[] {
  return [
    {
      title: 'Immatriculation',
      buildCellContent: data => buildImmatriculationContent(url, data),
      sortedField: GardienSortOptions.IMMATRICULATION,
    },
    {
      title: 'Date de mise en fourrière',
      buildCellContent: data =>
        data.ficheFourriere !== null &&
        data.ficheFourriere.dateMiseEnFourriere !== null
          ? moment(data.ficheFourriere.dateMiseEnFourriere).format(DATE_FORMAT)
          : null,
      sortedField: GardienSortOptions.DATE_MISE_FOURRIERE,
    },
    {
      title: 'Date de mise à jour',
      buildCellContent: data =>
        data.traitement !== null && data.traitement.dateMiseAJour !== null
          ? moment(data.traitement.dateMiseAJour).format(DATE_FORMAT)
          : null,
      sortedField: GardienSortOptions.DATE_MISE_A_JOUR,
    },
    {
      title: 'Nature de mise en fourrière',
      buildCellContent: data =>
        data.computed !== null && data.computed.brancheDto !== null
          ? data.computed.brancheDto.nature
          : null,
      sortedField: GardienSortOptions.NATURE_MISE_EN_FOURRIERE,
    },
    {
      title: 'Statut',
      buildCellContent: buildStatutContent,
      sortedField: GardienSortOptions.STATUT,
    },
    {
      title: 'Classement',
      buildCellContent: data =>
        data.traitement !== null && data.traitement.classement !== null
          ? classementEnumMapping[data.traitement.classement].label
          : null,
      sortedField: GardienSortOptions.CLASSEMENT,
    },
    {
      title: 'Marque',
      buildCellContent: data =>
        data.ficheVehicule?.marque ?? LABEL_MARQUE_NON_REFERENCEE,
      sortedField: GardienSortOptions.MARQUE,
    },
    {
      title: 'Modèle',
      buildCellContent: data =>
        data.ficheVehicule?.modele ?? LABEL_MODELE_NON_REFERENCE,
      sortedField: GardienSortOptions.MODELE,
    },
    {
      title: 'Couleur',
      buildCellContent: data =>
        data.ficheVehicule !== null ? data.ficheVehicule.couleur : null,
      sortedField: GardienSortOptions.COULEUR,
    },
    {
      title: 'Action requise',
      buildCellContent: data =>
        buildActionsRequisesContent(url, data, gfActionsRequises),
      sortedField: GardienSortOptions.ACTIONS_REQUISES,
    },
  ];
}

export const buildHeader: TableHeaderFunction<
  DossierSummaryResponseDto,
  FilterDashboardGardien
> = (fetchedData, selectedItems, filters, setFilter, sort) => {
  const totalCount = fetchedData !== undefined ? fetchedData.total : 0;
  const selectedCount = selectedItems.length;
  return (
    <DashboardHeader
      totalCount={totalCount}
      selectedCount={selectedCount}
      filter={filters}
      setFilter={setFilter}
      sort={sort}
    />
  );
};
