import React, { useCallback, useState } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';
import { Form } from 'components/WrappedComponents';
import InputTitle from './Fields/InputTitle';
import InputContent from './Fields/InputContent';
import CheckboxActif from './Fields/CheckboxActif';
import SelectProfile from './Fields/SelectProfile';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import DatePickerStart from './Fields/DatePickerStart';
import DatePickerEnd from './Fields/DatePickerEnd';
import {
  generateRequestDtoFromFormData,
  useCreateOrUpdateInformationMessage,
  userConversionMapping,
  useValidateCreateUpdateInformationMessage,
} from './utils';
import moment from 'moment';
import { DetailedInformationMessageDto } from 'lib_api/lib/api/gen';

interface InformationMessageFormProps {
  initialValues?: DetailedInformationMessageDto;
  close: () => void;
  callAfterSubmit?: () => void;
}

const InformationMessageForm = ({
  initialValues,
  close,
  callAfterSubmit,
}: InformationMessageFormProps): React.ReactElement => {
  const [formData, setFormData] = useState<InformationMessageFormValues>({
    title: initialValues?.title,
    content: initialValues?.content,
    startDate: initialValues?.startDate
      ? moment(initialValues.startDate)
      : undefined,
    endDate: initialValues?.endDate ? moment(initialValues.endDate) : undefined,
    profiles: initialValues?.profiles.map(key => userConversionMapping[key]),
    active: initialValues?.active,
  });

  const [submitForm, thenSubmmit, catchSubmit] =
    useCreateOrUpdateInformationMessage(() => {
      close();
      callAfterSubmit && callAfterSubmit();
    }, initialValues?.id);

  const memoSubmit = useCallback(async () => {
    return submitForm(formData);
  }, [formData, submitForm]);

  const [validateAndSubmit] = useValidateCreateUpdateInformationMessage(
    memoSubmit,
    thenSubmmit,
    catchSubmit,
  );

  return (
    <>
      <AntdModal
        visible={true}
        onCancel={close}
        footer={null}
        closable={false}
        destroyOnClose={true}
        width={680}
        maskClosable={false}
      >
        <Form
          initialValue={formData}
          id={'ProlongationForm'}
          onSubmit={() => {
            return validateAndSubmit(controller =>
              controller.informationMessageValidateUsingPOST(
                generateRequestDtoFromFormData(formData),
              ),
            );
          }}
          onChange={setFormData}
          validateButtonTitle={initialValues ? 'Mettre à jour' : 'Créer'}
          validateButtonClassName={'ValidateFormButton'}
        >
          {(values, SubmitButton): React.ReactElement => {
            return (
              <>
                <InputTitle values={values} />
                <InputContent values={values} />
                <SelectProfile values={values} />
                <DatePickerStart values={values} />
                <DatePickerEnd values={values} />
                <CheckboxActif values={values} />
                <div className="ModalFooter">
                  <AntdButton onClick={close}>Annuler</AntdButton>
                  {SubmitButton}
                </div>
              </>
            );
          }}
        </Form>
      </AntdModal>
    </>
  );
};

export default InformationMessageForm;
