import React from 'react';

import { Select } from 'components/WrappedComponents';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';
import { useSelectOptionsFourriere } from 'utils/selectOptions/selectOptionsFourriere';
import { useUserStore } from 'hooks/UserStoreContext';

interface SelectFourriereProps {
  values: Partial<DashboardAutoriteFourriereFilterForm>;
}

function SelectFourriere({ values }: SelectFourriereProps): React.ReactElement {
  const user = useUserStore();
  const selectItems = useSelectOptionsFourriere(
    user.idCorrelationAutoriteFourriere,
  );
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'fourriere',
        itemProps: {
          label: 'Fourrière',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectFourriere;
