import React, { useCallback, useEffect, useState } from 'react';

import { DetailedEventDto } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';

export interface DossierActivityItem extends DetailedEventDto {
  ref: React.RefObject<HTMLDivElement>;
  detailsTitle?: string;
}

export interface FetchDetailedEventResult {
  events: DossierActivityItem[];
  fetchEvents: () => Promise<void>;
  fetchInProgress: boolean;
  errorOccured: boolean;
}

export default function useFetchDetailedEvents(
  dossierId: string,
): FetchDetailedEventResult {
  const [items, setItems] = useState<DossierActivityItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi } = useApi();
  const behaviourOnError = useHandleBackErrors();

  const fetchAndSetEvents = useCallback(async () => {
    setItems([]);
    setErrorOccured(false);
    setLoading(true);

    await AdminFonctionnelControllerApi.getDetailedEventsUsingGET(dossierId)
      .then(events => {
        setItems(
          events.reverse().map((item: DetailedEventDto) => {
            return {
              label: item.label,
              content: item.content,
              date: item.date,
              ref: React.createRef<HTMLDivElement>(),
              isIncident: item.isIncident,
              inducedStatutDossier: item.inducedStatutDossier,
              details: item.details,
            };
          }),
        );
      })
      .catch(errorResponse => {
        setErrorOccured(true);
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [AdminFonctionnelControllerApi, dossierId, behaviourOnError]);

  // Fetch events
  useEffect(() => {
    void fetchAndSetEvents();
  }, [AdminFonctionnelControllerApi, dossierId, fetchAndSetEvents]);

  return {
    events: items,
    fetchInProgress: loading,
    errorOccured: errorOccured,
    fetchEvents: fetchAndSetEvents,
  };
}
