import React from 'react';
import { FileTextOutlined, CarOutlined } from '@ant-design/icons';
import { Tabs as AntdTabs, Typography as AntdTypo } from 'antd';

import { BorderedContent } from 'components/WrappedComponents';
import { FicheVehicule } from './FicheVehicule/FicheVehicule';
import { FicheInfraction } from './FicheInfraction/FicheInfraction';
import { SuiviFourriere } from './SuiviFourriere/SuiviFourriere';

import './Content.less';

const { TabPane } = AntdTabs;
const { Text } = AntdTypo;

export const Content = (): React.ReactElement => {
  return (
    <div className={'ContentContainer'}>
      <BorderedContent className={'Tabs'}>
        <AntdTabs defaultActiveKey="1" animated={false} className={'AntTabs'}>
          <TabPane
            tab={
              <span>
                <CarOutlined />
                <Text>Informations véhicule</Text>
              </span>
            }
            key="1"
            className={'AntTabPan'}
          >
            <FicheVehicule />
          </TabPane>

          <TabPane
            tab={
              <span>
                <FileTextOutlined />
                <Text>Informations infraction</Text>
              </span>
            }
            key="2"
            className={'AntTabPan'}
          >
            <div className={'bordered'}>
              <FicheInfraction />
            </div>
          </TabPane>
        </AntdTabs>
      </BorderedContent>

      <div className={'SuiviContainer'}>
        <SuiviFourriere />
      </div>
    </div>
  );
};
