import { useSearchApiGouv } from './useSearchApiGouv';

export interface FetchCommuneSearchResult {
  nom: string;
  codesPostaux: Array<string>;
}

export interface CommuneApiGouvOption {
  nom: string;
  codespostaux: Array<string>;
}

function buildSearchCommuneUrl(option: CommuneApiGouvOption): string {
  return `${process.env.REACT_APP_API_GEO_GOUV_URL}/communes?nom=${encodeURI(
    option.nom,
  )}&fields=nom,codesPostaux&boost=population&format=json&limit=8`;
}

function mapFetchResultToOptions(
  result: Array<FetchCommuneSearchResult>,
): Array<CommuneApiGouvOption> {
  return result.map(option => {
    return {
      codespostaux: option.codesPostaux,
      nom: option.nom,
    };
  });
}

export const useSearchCommuneApiGouv = (): [
  Array<CommuneApiGouvOption>,
  boolean,
  (str: string) => void,
] => {
  const [options, isFetching, fetchOptions] = useSearchApiGouv(
    {
      nom: '',
      codespostaux: [],
    },
    (option: CommuneApiGouvOption) => option.nom,
    buildSearchCommuneUrl,
    search => {
      return { nom: search, codespostaux: [] };
    },
    mapFetchResultToOptions,
  );

  return [options, isFetching, fetchOptions];
};
