import React, { useState } from 'react';
import { Result } from 'antd';

import {
  SearchHermesRecuperationEtatBiensResponseDto,
  FluxDto,
  WarningDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import {
  TablePagination,
  FetchDataResult,
  TableSort,
} from 'components/WrappedComponents/Table/types';

import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { ReferentielSearchType } from '../../Referentiels/Dashboards/utils';
import {
  FilterDashboardFluxVenteDossiers,
  FilterDashboardFluxVenteDossiersWarnings,
  FluxStatusType,
  FluxVenteDossiersSortOptions,
} from './types';

export function useSearchFluxsDomaine(
  filters: FilterDashboardFluxVenteDossiers,
  sorts: TableSort<FluxVenteDossiersSortOptions>,
  pagination: TablePagination,
): [
  () => Promise<SearchHermesRecuperationEtatBiensResponseDto>,
  (
    result: SearchHermesRecuperationEtatBiensResponseDto,
  ) => FetchDataResult<FluxDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  return [
    () => {
      return controller.searchFluxUsingGET({
        sortDto: {
          dateAppel: sorts?.DATE_APPEL,
          dateDebut: sorts?.DATE_DEBUT,
          dateFin: sorts?.DATE_FIN,
        },
        page: pagination.page - 1,
        pageSize: pagination.pageSize,
      });
    },
    result => {
      return {
        data: result.flux,
        total: result.total,
      };
    },
  ];
}

export function useSearchWarningFluxsDomaine(
  fluxId: string,
): ReferentielSearchType<WarningDto, FilterDashboardFluxVenteDossiersWarnings> {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] =
    useState<FilterDashboardFluxVenteDossiersWarnings>({});

  const controller = useApi().AdminFonctionnelControllerApi;

  return {
    fetchData: async () => {
      const result = await controller
        .warningsOfFluxUsingGET(fluxId || '', {
          page: pagination.page - 1,
          pageSize: pagination.pageSize,
        })
        .catch((e: Response) => {
          void backAlertMessage(e);
          throw e;
        });
      return {
        data: result.warnings,
        total: result.total,
      };
    },
    pagination: pagination,
    setPagination: newPagination => {
      setPagination(newPagination);
    },
    filter: filter,
    setFilter: setFilter,
    refreshDashboard: () => {
      setFilter(filter);
    },
  };
}
