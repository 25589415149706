import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  ConfigProvider as AntdConfigProvider,
  message as AntdMessage,
} from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import moment from 'moment';
import 'moment/locale/fr';
import Maintenance from 'components/Maintenance/Maintenance';
import ExportFiles from 'components/DownloadableFiles/DownloadableFiles';
import Footer from 'components/Footer';
import Header from 'components/Header/Header';
import { InformationMessagesDisplay } from 'components/InformationMessageDisplay/InformationMessagesDisplay';
import RedirectHandler from 'components/RedirectHandler/RedirectHandler';
import {
  Environnement,
  EnvironnementProvider,
} from 'hooks/EnvironementStoreContext';
import { Authenticator } from 'Authenticator/Authenticator';
import AppRoutes from './AppRoutes';

import './App.less';

function parseKey<T>(toParse: unknown, searchedKey: string): T | null {
  if (typeof toParse === 'object' && toParse !== null) {
    for (const [key, value] of Object.entries(toParse)) {
      if (key === searchedKey) {
        return value;
      }
    }
  }

  return null;
}

function checkEnv(): Environnement | null {
  const env: Environnement | null = parseKey(window, '_env_');
  if (env !== null) {
    const backendUrl: unknown | null = parseKey(env, 'BACKEND_URL');
    if (backendUrl !== null && typeof backendUrl === 'string') {
      return env;
    }
  }

  return null;
}

function setupAntdConfig(): void {
  /* 125 is the banner's height */
  AntdMessage.config({ top: 125, duration: 1.5 });

  moment.locale('fr');
}

const App: React.FC = () => {
  const env = checkEnv();
  setupAntdConfig();

  if (env === null) {
    //  eslint-disable-next-line
    return <></>; // TODO : display proper error page (env is not configured => contact server admin)
  } else {
    return (
      <AntdConfigProvider locale={frFR}>
        <EnvironnementProvider value={env}>
          <Router>
            <Maintenance>
              <Authenticator>
                <ExportFiles>
                  <div className={'Container'}>
                    <Header className={'Header'} />
                    <InformationMessagesDisplay>
                      <RedirectHandler>
                        <main className={'Main'}>
                          <AppRoutes />
                        </main>
                      </RedirectHandler>
                      <Footer className={'Footer'} />
                    </InformationMessagesDisplay>
                  </div>
                </ExportFiles>
              </Authenticator>
            </Maintenance>
          </Router>
        </EnvironnementProvider>
      </AntdConfigProvider>
    );
  }
};

export default App;
