import React from 'react';
import { Badge, Button as AntdButton } from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import classNames from 'classnames';

import { Banner } from 'components/Banner';
import ProfileButton from 'components/ProfileButton';
import { useUserStore } from 'hooks/UserStoreContext';
import { useDownloadableFilesContext } from 'hooks/DownloadableFilesContext';
import { profileEnumMapping } from 'utils/enumData';

import './Header.less';

interface HeaderProps {
  className?: string;
}

const Header = ({ className }: HeaderProps): React.ReactElement => {
  const user = useUserStore();
  if (user.profile === null) {
    throw new Error('Authenticated user should be mapped to a profile');
  }

  const { homePath, profilPath } = profileEnumMapping[user.profile];
  const { newDownloadsNumber, showDownloadablePopUp } =
    useDownloadableFilesContext();

  return (
    <header className={classNames('HeaderContainer', className)}>
      <div className={'TopBarContainer'}>
        <div className={'Left'}>
          <Banner className={'Banner'} pathToRedirect={homePath} />
        </div>

        <div className={'Right'}>
          <Badge count={newDownloadsNumber} className="DownloadableFilesButton">
            <AntdButton
              className={'create-dossier-button'}
              icon={<PaperClipOutlined />}
              type={'default'}
              shape={'round'}
              onClick={() => showDownloadablePopUp(true)}
            />
          </Badge>
          <ProfileButton urlProfil={profilPath} />
        </div>
      </div>
    </header>
  );
};

export default Header;
