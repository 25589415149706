import React from 'react';
import { Modal as AntdModal } from 'antd';

import BaseForm from 'components/BaseForm/BaseForm';
import { FormInputProps } from 'components/BaseForm/types';

interface ModalEditionProps<FormValues, FieldType> {
  close: () => void;
  visible: boolean;
  initials: FormValues;
  title: string;
  submit: (values: FormValues) => void;
  isSubmitting: boolean;
  validate: (field: FieldType, values: FormValues) => Promise<void>;
  inputs: FormInputProps<FormValues, FieldType>[];
}

export default function ModalEdition<FormValues, FieldType>({
  close,
  visible,
  initials,
  submit,
  isSubmitting,
  validate,
  inputs,
  title,
}: ModalEditionProps<FormValues, FieldType>): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={680}
      maskClosable={false}
    >
      <h3 className="mainTitle">{title}</h3>
      <BaseForm
        initialValues={initials}
        onCancel={close}
        validateField={validate}
        onSubmit={submit}
        inputs={inputs}
        isSubmitting={isSubmitting}
      />
    </AntdModal>
  );
}
