import { SupportedFilesDetails } from 'types/FileImport';

export const supportedFilesDetails: SupportedFilesDetails = {
  CSV: {
    extension: '.csv',
    fileTypes: ['text/csv', 'application/vnd.ms-excel'],
  },
  JSON: {
    extension: '.json',
    fileTypes: ['application/json'],
  },
  PDF: {
    extension: '.pdf',
    fileTypes: ['application/pdf'],
  },
  DMN: {
    extension: '.dmn',
    fileTypes: ['text/xml', ''], // Supported files are XML and DMN (for which its types may not be recognised)
  },
};
