import {
  DossierViolationsDto,
  GardienFourriereControllerApi,
  SortieVehiculeViolationDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import {
  useValidationRequest,
  ValidationCallback,
} from 'hooks/utils/handleValidationRequest';

//  type a function that call a GardienFourriereControllerApi request
export type GFValidationCallback =
  ValidationCallback<GardienFourriereControllerApi>;

/**
 * helper function to validate form of fiche descriptive
 *
 * @param key form key in dossier violation
 * @param onValidationSuccess callback to call if form validation succeed
 * @returns a tuple containing the violation in the form and a function to attempt a submission
 */
export function useValidateSortieVehiculeForm(
  onValidationSuccess: () => Promise<void>,
): [
  (requestGenerator: GFValidationCallback) => Promise<void>,
  SortieVehiculeViolationDto[] | null,
] {
  const gfController = useApi().GardienFourriereControllerApi;

  return useValidationRequest(
    gfController,
    'sortieVehiculeViolationDtoList',
    onValidationSuccess,
  );
}
