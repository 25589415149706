import React, { useCallback } from 'react';
import { Checkbox } from 'antd';

import {
  EtatDto,
  MotifMefDto,
  UpdateDonneesVehiculeViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen/api';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import { fetchEtatsVehicule } from 'search/searchEtatsVehicule';
import { fetchMotifMefList } from 'search/searchMotifMef';
import BaseForm from 'components/BaseForm/BaseForm';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import useSubmitUpdateDonneesVehicule from './buildSubmitFunction';
import BaseInput from 'components/BaseForm/BaseInput';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';
import { etatDtoToString, motifMefDtoToString } from 'utils/dtoToString';
import useInitialValues from './useInitialValues';

import './UpdateDonneesVehicule.less';

interface UpdateDonneesVehiculeProps {
  closeModal: () => void;
}

function UpdateDonneesVehicule({
  closeModal,
}: UpdateDonneesVehiculeProps): React.ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const {
    dossier: dossierAdmin,
    fetchInProgress,
    fetchDossier,
    errorOccured,
  } = useDossierCompletAdmin(dossierId);
  const {
    AdminFonctionnelControllerApi: adminController,
    ReferentielControllerApi: refController,
  } = useApi();
  const initialValues = useInitialValues(dossierAdmin);

  const warningMessage = {
    title: 'Conséquence de la modification',
    detail:
      "Les données de la fiche descriptive seront modifiées et conduiront à une nouvelle consultation du SIV et à un nouveau classement. Ces modifications peuvent être effectuées uniquement avant l'envoi de la notification. Après l'envoi de la notification, il est préconisé de demander la re-saisie du dossier et de supprimer le dossier erroné.",
  };

  const { submit, inProgress } = useSubmitUpdateDonneesVehicule(
    adminController,
    dossierId,
    setDossier,
    fetchEvents,
    closeModal,
  );

  const submitFunction = useCallback(submit, [submit]);

  return (
    <AsyncComponent
      render={() => {
        return dossierAdmin ? (
          <>
            <h3>
              Mettre à jour des données du véhicule et de l&apos;infraction
            </h3>
            <BaseForm
              initialValues={initialValues}
              onSubmit={submitFunction}
              onCancel={closeModal}
              isSubmitting={inProgress}
              onChange={(_changed, all) => {
                if (all.sansPlaque) {
                  all.plaqueImmatriculation = undefined;
                }
                return all;
              }}
              inputs={[
                {
                  field:
                    UpdateDonneesVehiculeViolationDtoFormFieldEnum.NEW_MOTIF_ENTREE_ID_CORRELATION,
                  required: true,
                  name: 'idCorrelationMotifMef',
                  label: 'Motivation de la mesure',
                  dependencies: [
                    'plaqueImmatriculation',
                    'sansPlaque',
                    'paysEtranger',
                    'idCorrelationEtatVehicule',
                  ],
                  render: () => {
                    return (
                      <AsyncSelect
                        fetchOptions={() => {
                          return fetchMotifMefList(refController);
                        }}
                        getOptionValue={(motif: MotifMefDto) => {
                          return motif.idCorrelation;
                        }}
                        getOptionLabel={(motif: MotifMefDto) => {
                          return motifMefDtoToString(motif);
                        }}
                        showSearch={true}
                      />
                    );
                  },
                },
                {
                  field:
                    UpdateDonneesVehiculeViolationDtoFormFieldEnum.NEW_ETAT_ID_CORRELATION,
                  required: true,
                  name: 'idCorrelationEtatVehicule',
                  label: 'État du véhicule',
                  dependencies: [
                    'plaqueImmatriculation',
                    'sansPlaque',
                    'paysEtranger',
                    'idCorrelationMotifMef',
                  ],
                  render: () => {
                    return (
                      <AsyncSelect
                        fetchOptions={() => {
                          return fetchEtatsVehicule(refController);
                        }}
                        getOptionValue={(etat: EtatDto) => {
                          return etat.idCorrelation;
                        }}
                        getOptionLabel={(etat: EtatDto) => {
                          return etatDtoToString(etat);
                        }}
                        showSearch={true}
                      />
                    );
                  },
                },
                {
                  field:
                    UpdateDonneesVehiculeViolationDtoFormFieldEnum.NEW_IS_SANS_PLAQUE,
                  required: false,
                  name: 'sansPlaque',
                  label: 'Véhicule Sans plaque',
                  dependencies: [
                    'plaqueImmatriculation',
                    'paysEtranger',
                    'idCorrelationMotifMef',
                    'idCorrelationEtatVehicule',
                  ],
                  valuePropName: 'checked',
                  render: () => {
                    return <Checkbox />;
                  },
                },
                {
                  field:
                    UpdateDonneesVehiculeViolationDtoFormFieldEnum.NEW_IS_PAYS_ETRANGER,
                  required: false,
                  name: 'paysEtranger',
                  label: 'Pays Etranger',
                  dependencies: [
                    'plaqueImmatriculation',
                    'sansPlaque',
                    'idCorrelationMotifMef',
                    'idCorrelationEtatVehicule',
                  ],
                  valuePropName: 'checked',
                  render: () => {
                    return <Checkbox />;
                  },
                },
                {
                  field:
                    UpdateDonneesVehiculeViolationDtoFormFieldEnum.NEW_PLAQUE_IMMATRICULATION,
                  required: values => {
                    return !values.sansPlaque;
                  },
                  disabled: values => {
                    return values.sansPlaque === true;
                  },
                  name: 'plaqueImmatriculation',
                  label: "Numéro d'identification ou d'immatriculation",
                  dependencies: [
                    'paysEtranger',
                    'sansPlaque',
                    'idCorrelationMotifMef',
                    'idCorrelationEtatVehicule',
                  ],
                  render: ({ disabled }) => {
                    return <BaseInput disabled={disabled} />;
                  },
                },
                {
                  field: UpdateDonneesVehiculeViolationDtoFormFieldEnum.COMMENT,
                  required: true,
                  name: 'comment',
                  label: 'Justification',
                  render: () => {
                    return <BaseTextArea />;
                  },
                },
              ]}
              validateField={async (field, formValues) => {
                const response =
                  await adminController.validateUpdateDonneesVehiculeFieldUsingPOST(
                    field,
                    dossierId,
                    {
                      newSansPlaque: formValues.sansPlaque ?? null,
                      newPaysEtranger: formValues.paysEtranger ?? null,
                      newPlaqueImmatriculation:
                        formValues.plaqueImmatriculation ?? null,
                      newMotifEntreeIdCorrelation:
                        formValues.idCorrelationMotifMef ?? null,
                      newEtatIdCorrelation:
                        formValues.idCorrelationEtatVehicule ?? null,
                      comment: formValues.comment,
                    },
                  );

                const violations =
                  response.updateDonneesVehiculeViolationDtoList ?? [];
                if (violations.length === 0) {
                  return Promise.resolve();
                }

                return Promise.reject(violations[0].message);
              }}
              warningMessage={warningMessage}
              renderButtonAction={(Submit, Cancel) => {
                return (
                  <div className="UpdateDonneesVehiculeActions">
                    <div className="UpdateAction">{Cancel}</div>
                    <div className="UpdateAction">{Submit}</div>
                  </div>
                );
              }}
            />
          </>
        ) : null;
      }}
      inProgress={fetchInProgress}
      errorOccured={errorOccured}
      reload={fetchDossier}
      errorMessage="Impossible de charger les données du dossier, veuillez réessayer"
    />
  );
}

export default UpdateDonneesVehicule;
