import React, { useCallback } from 'react';
import {
  MarqueDto,
  MarqueDtoCategorieEnum,
  MarqueDtoTypeEnum,
  MarqueRequestDto,
  MarqueRequestDtoCategorieEnum,
  MarqueRequestDtoTypeEnum,
  MarqueViolationDtoFormFieldEnum,
  ReferentielSearchResultDtoMarqueDto,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';
import {
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { SubmitFunction } from 'components/BaseForm/types';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useValidateField } from 'hooks/utils/handleValidationRequest';
import { useBoolean } from 'utils/genericUtils';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { FilterDashboardMarque } from './DashboardHeader/types';
import { MarqueDtoFormValues, MarqueSort } from './types';

export const buildHeader: TableHeaderFunctionWithoutSort<
  MarqueDto,
  FilterDashboardMarque
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
  />
);

/**
 * Hook sent to generic search hook to send search request and convert returned result to readable data
 */
export function useSearchMarque(
  filters: FilterDashboardMarque,
  sorts: TableSort<MarqueSort>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoMarqueDto>,
  (result: ReferentielSearchResultDtoMarqueDto) => FetchDataResult<MarqueDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () => {
      return controller.searchMarqueUsingSpecsUsingGET({
        filters: {
          ...filters,
          types: filters?.type ? [filters.type] : [],
          page: pagination.page,
          pageSize: pagination.pageSize,
        },
        sorts: {
          categorie: sorts?.CATEGORIE,
          libelle: sorts?.LIBELLE,
          type: sorts?.TYPE,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function buildDefaultUpdateFormValues(
  marque: MarqueDto,
  marqueExacte: MarqueDto | null,
): MarqueDtoFormValues {
  return {
    libelle: marque.libelle,
    categorie: marque.categorie ?? undefined,
    type: marque.type ?? undefined,
    marqueExacte: {
      value: marqueExacte?.id,
      label: marqueExacte?.libelle,
      option: marqueExacte ?? undefined,
    },
  };
}

export type CategorieEnumMapping = {
  [key in MarqueDtoCategorieEnum]: MarqueRequestDtoCategorieEnum;
};
export type TypeEnumMapping = {
  [key in MarqueDtoTypeEnum]: MarqueRequestDtoTypeEnum;
};

export const categorieEnumMapping: CategorieEnumMapping = {
  A_CATEGORISER: MarqueRequestDtoCategorieEnum.A_CATEGORISER,
  PREMIUM: MarqueRequestDtoCategorieEnum.PREMIUM,
  NON_VALORISABLE: MarqueRequestDtoCategorieEnum.NON_VALORISABLE,
};
export const typeEnumMapping: TypeEnumMapping = {
  INCONNUE: MarqueRequestDtoTypeEnum.INCONNUE,
  APPROXIMATIVE: MarqueRequestDtoTypeEnum.APPROXIMATIVE,
  EXACTE: MarqueRequestDtoTypeEnum.EXACTE,
};

export function convertMarqueToUpdateMarqueRequestDto(
  formValues: MarqueDtoFormValues,
): MarqueRequestDto {
  return {
    categorie: formValues.categorie
      ? categorieEnumMapping[formValues.categorie]
      : null,
    type: formValues.type ? typeEnumMapping[formValues.type] : null,
    marqueExacteId:
      formValues.type === MarqueDtoTypeEnum.APPROXIMATIVE &&
      formValues?.marqueExacte?.option?.id
        ? formValues.marqueExacte?.option?.id
        : null,
  };
}

export function useValidateMarque(
  marqueIdCorrelation: string,
): (
  field: MarqueViolationDtoFormFieldEnum,
  values: MarqueDtoFormValues,
) => Promise<void> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const sendRequest = useCallback(
    (field: MarqueViolationDtoFormFieldEnum, request: MarqueRequestDto) => {
      return controller.validateUpdateMarqueFieldUsingPOST(
        field,
        marqueIdCorrelation,
        request,
      );
    },
    [marqueIdCorrelation, controller],
  );

  const extractor = useCallback((response: ReferentielViolationsDto) => {
    return response.marqueViolationsDto ?? [];
  }, []);

  return useValidateField(
    convertMarqueToUpdateMarqueRequestDto,
    sendRequest,
    extractor,
  ).validate;
}

export function useSubmitMarque(
  idCorrelation: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<MarqueDtoFormValues> {
  const {
    value: inProgress,
    setIsTrue: startProgress,
    setIsFalse: stopProgress,
  } = useBoolean();
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: MarqueDtoFormValues) => {
      startProgress();
      return controller
        .updateMarqueUsingPOST(
          idCorrelation,
          convertMarqueToUpdateMarqueRequestDto(values),
        )
        .then(closeModal)
        .then(refreshDashboard)
        .catch(backAlertMessage)
        .finally(() => {
          stopProgress();
        });
    },
    [controller, closeModal, refreshDashboard, startProgress, stopProgress],
  );

  return {
    submit,
    inProgress,
  };
}
