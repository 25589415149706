import { useCallback, useEffect, useState } from 'react';
import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';
import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';

interface FetchDossierAdminResult {
  dossier: ExportDonneesDossierAdminFonctionnelDto | null;
  fetchDossier: () => Promise<void>;
  fetchInProgress: boolean;
  errorOccured: boolean;
}

/**
 * Hook to get dossier with all inforation.
 */
export function useDossierCompletAdmin(
  dossierId: string,
): FetchDossierAdminResult {
  const [dossier, setDossier] =
    useState<ExportDonneesDossierAdminFonctionnelDto | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: dossierController } = useApi();
  const behaviourOnError = useHandleBackErrors();

  const fetchDossier = useCallback(async () => {
    setDossier(null);
    setLoading(true);
    setErrorOccured(false);

    await dossierController
      .getAllDonneesAndEventsDossierUsingGET(dossierId)
      .then((result: ExportDonneesDossierAdminFonctionnelDto) => {
        setDossier(result);
      })
      .catch((errorResponse: Response) => {
        behaviourOnError(errorResponse);
        setErrorOccured(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dossierController, dossierId, behaviourOnError]);

  useEffect(() => {
    void fetchDossier();
  }, [dossierController, dossierId, fetchDossier]);

  return {
    dossier: dossier,
    fetchInProgress: loading,
    errorOccured: errorOccured,
    fetchDossier: fetchDossier,
  };
}
