import React from 'react';
import { Typography as AntdTypo } from 'antd';
import { Form } from 'components/WrappedComponents';
import { DashboardForcesOrdreFilterForm } from '../../../types';
import { FormFooter } from './FormFooter';
import {
  DateBonEnlevement,
  DateFinProcedure,
  DateMiseEnFourriere,
  DateOrdreDestruction,
  SelectActionsRequises,
  SelectClassement,
  SelectCommuneEnlevement,
  SelectFourriere,
  SelectNatureMiseEnFourriere,
  SelectStatut,
  SelectUnitesFO,
} from './Fields';

import './FormFooter/FormFooter.less';
import { SelectMotifSortieUsingIdCorrelation } from 'components/WrappedComponents/Form/FormFields/Select/Referentiels/MotifSortie';
import CheckboxDossiersExpires from './Fields/CheckboxDossiersExpires';

interface FilterFormProps {
  filter: Partial<DashboardForcesOrdreFilterForm>;
  setFilter: (value: Partial<DashboardForcesOrdreFilterForm>) => void;
}

function FilterFormForces({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  return (
    <Form
      id="ForcesOrdreFilterForm"
      initialValue={filter}
      // We cannot use the onSubmit function because it causes an error on console,
      // because it try to re-render FormWrapper in the same time as FilterButton
      onSubmit={() => Promise.resolve()}
      validateButtonTitle="" // not used
    >
      {values => (
        <>
          <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>

          <AntdTypo.Title level={4}>Général</AntdTypo.Title>
          <SelectStatut values={values} />
          <SelectClassement values={values} />
          <SelectFourriere values={values} />
          <SelectUnitesFO values={values} />
          <SelectCommuneEnlevement values={values} />
          <SelectActionsRequises values={values} />
          <CheckboxDossiersExpires values={values} />

          <AntdTypo.Title level={4}>Mise en fourrière</AntdTypo.Title>
          <SelectNatureMiseEnFourriere values={values} />
          <DateMiseEnFourriere values={values} />

          <AntdTypo.Title level={4}>Suivi de mise en fourrière</AntdTypo.Title>
          <DateOrdreDestruction values={values} />
          <DateBonEnlevement values={values} />
          <DateFinProcedure values={values} />
          <SelectMotifSortieUsingIdCorrelation
            values={values}
            fieldKey="motifDeSortie"
            mode="multiple"
          />

          <FormFooter setFilter={setFilter} />
        </>
      )}
    </Form>
  );
}

export default FilterFormForces;
