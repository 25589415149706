import React, { useCallback, useState } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';

import { UserDto, UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { Form } from 'components/WrappedComponents';

import InputFirstName from './Fields/InputFirstName';
import InputLastName from './Fields/InputLastName';
import InputEmail from './Fields/InputEmail';
import CheckboxActif from './Fields/CheckboxActif';
import SelectUniteRattachement from './Fields/SelectUniteRattachement';
import {
  useSubmitUpdateUser,
  useValidateUpdateUser,
  generateUpdateUserRequestDtoFromFormData,
} from './utils';

interface ModalModificationProps {
  close: () => void;
  user: UserDto;
  callAfterSubmit: () => void;
}

export interface ModificationUtilisateurFormValues {
  uniteRattachement?: string;
  lastName?: string;
  firstName?: string;
  actif?: boolean;
  email?: string;
}

const getUserUniteRattachementId = (user: UserDto): string | undefined => {
  switch (user.profile) {
    case UserDtoProfileEnum.GARDIEN_FOURRIERE:
      return user.idCorrelationFourriere ?? undefined;
    case UserDtoProfileEnum.AUTORITE_FOURRIERE:
      return user.idCorrelationAutoriteFourriere ?? undefined;
    case UserDtoProfileEnum.FORCE_DE_L_ORDRE:
      return user.idCorrelationUniteFO ?? undefined;
    default:
      return undefined;
  }
};

const ModalModification = ({
  close,
  user,
  callAfterSubmit,
}: ModalModificationProps): React.ReactElement => {
  const userId = user.id;
  const [formData, setFormData] = useState<ModificationUtilisateurFormValues>({
    uniteRattachement: getUserUniteRattachementId(user),
    lastName: user.lastName,
    firstName: user.firstName,
    actif: user.enabled,
    email: user.email ?? undefined,
  });

  const [submitForm, thenSubmmit, catchSubmit] = useSubmitUpdateUser(() => {
    close();
    callAfterSubmit();
  });

  const memoSubmit = useCallback(async () => {
    return submitForm(formData, userId);
  }, [formData, submitForm, userId]);

  const [validateAndSubmit] = useValidateUpdateUser(
    memoSubmit,
    thenSubmmit,
    catchSubmit,
  );

  return (
    <>
      <AntdModal
        visible={true}
        onCancel={() => close()}
        footer={null}
        closable={false}
        destroyOnClose={true}
        width={680}
        maskClosable={false}
      >
        <Form
          initialValue={formData}
          id={'ProlongationForm'}
          onSubmit={() => {
            return validateAndSubmit(cuController =>
              cuController.validateUpdateUsingPOST(
                userId,
                generateUpdateUserRequestDtoFromFormData(formData),
              ),
            );
          }}
          onChange={values => {
            setFormData(values);
          }}
          validateButtonTitle={'Mettre à jour'}
          validateButtonClassName={'ValidateFormButton'}
        >
          {(values, SubmitButton): React.ReactElement => {
            return (
              <>
                <InputFirstName values={values} userId={userId} />
                <InputLastName values={values} userId={userId} />
                <InputEmail values={values} userId={userId} />
                <CheckboxActif values={values} userId={userId} />
                <SelectUniteRattachement values={values} user={user} />
                <div className="ModalFooter">
                  <AntdButton onClick={close}>Annuler</AntdButton>
                  {SubmitButton}
                </div>
              </>
            );
          }}
        </Form>
      </AntdModal>
    </>
  );
};

export default ModalModification;
