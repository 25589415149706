import React, { useCallback, useState } from 'react';

import {
  CentreVHUDto,
  AdminFonctionnelControllerApi,
  CentreVHURequestDto,
  ConditionsReactivateCentreVHUDto,
  ReferentielSearchResultDtoCentreVHUDto,
  ReferentielUsesDto,
  CentreVHUViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { FilterDashboardCentreVHU } from './DashboardHeader/types';
import {
  TableHeaderFunctionWithoutSort,
  TablePagination,
  FetchDataResult,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { CentreVHUFormValues, CentreVHUSortOptions } from './types';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

export const buildHeader: TableHeaderFunctionWithoutSort<
  CentreVHUDto,
  FilterDashboardCentreVHU
> = (fetchedData, _selectedData, filter, setFilter) => (
  <DashboardHeader
    totalCount={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
  />
);

/**
 * Hook sent to generic search hook to send search request and convert returned result to readable data
 */
export function useSearchCentreVHU(
  filters: FilterDashboardCentreVHU,
  sorts: TableSort<CentreVHUSortOptions>,
  pagination: TablePagination,
): [
  () => Promise<ReferentielSearchResultDtoCentreVHUDto>,
  (
    result: ReferentielSearchResultDtoCentreVHUDto,
  ) => FetchDataResult<CentreVHUDto>,
] {
  const controller = useApi().ReferentielControllerApi;

  return [
    () => {
      return controller.searchCentreVHUUsingSpecsUsingGET({
        filters: {
          ...filters,
          page: pagination.page,
          pageSize: pagination.pageSize,
          commune: filters.commune?.commune,
        },
        sorts: {
          codePostal: sorts?.CODE_POSTAL,
          commune: sorts?.COMMUNE,
          numeroAgrementVHU: sorts?.NUMERO_AGREMENT,
          raisonSociale: sorts?.RAISON_SOCIALE,
        },
      });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityCentreVHU(
  controller: AdminFonctionnelControllerApi,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateCentreVHUDto>,
  ((idCorrelation: string) => Promise<ReferentielUsesDto>) | undefined,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateCentreVhuUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateCentreVhuUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateCentreVHUDto> => {
      return controller.conditionReactivationCentreVhuUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    undefined,
  ];
}

export const buildFormValues = (
  centreVHU: CentreVHUDto,
): CentreVHUFormValues => {
  return {
    raisonSociale: centreVHU.raisonSociale,
    siren: centreVHU.siren,
    adresse: centreVHU.adresse ?? undefined,
    complementAdresse: centreVHU.complementAdresse ?? undefined,
    codePostal: centreVHU.codePostal,
    commune: centreVHU.commune ?? undefined,
    telephone: centreVHU.telephone ?? undefined,
    email: centreVHU.adresseMail ?? undefined,
    numeroAgrement: centreVHU.numeroAgrementVHU,
    prefecture: centreVHU.prefecture ?? undefined,
  };
};

export const buildRequestDto = (
  formValues: CentreVHUFormValues,
): CentreVHURequestDto => {
  return {
    raisonSociale: formValues.raisonSociale ?? null,
    siren: formValues.siren ?? null,
    adresse: formValues.adresse ?? null,
    complementAdresse: formValues.complementAdresse ?? null,
    codePostal: formValues.codePostal ?? null,
    commune: formValues.commune ?? null,
    telephone: formValues.telephone ?? null,
    adresseMail: formValues.email ?? null,
    numeroAgrementVHU: formValues.numeroAgrement ?? null,
    prefecture: formValues.prefecture ?? null,
  };
};

export function useValidateCreateCentreVHU(): ValidateFunction<
  CentreVHUFormValues,
  CentreVHUViolationDtoFormFieldEnum
> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: CentreVHUViolationDtoFormFieldEnum,
      values: CentreVHUFormValues,
    ) => {
      const response = await controller.validateCreateCentreVHUFieldUsingPOST(
        field,
        buildRequestDto(values),
      );

      const violations = response.centreVHUViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitCreateCentreVHU(
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<CentreVHUFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: CentreVHUFormValues) => {
      setInProgress(true);
      return controller
        .createCentreVHUUsingPOST(buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}

export function useValidateUpdateCentreVHU(
  uniteId: string,
): ValidateFunction<CentreVHUFormValues, CentreVHUViolationDtoFormFieldEnum> {
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: CentreVHUViolationDtoFormFieldEnum,
      values: CentreVHUFormValues,
    ) => {
      const response = await controller.validateUpdateCentreVHUFieldUsingPOST(
        field,
        uniteId,
        buildRequestDto(values),
      );

      const violations = response.centreVHUViolationsDto ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitUpdateCentreVHU(
  uniteId: string,
  refreshDashboard: () => void,
  closeModal: () => void,
): SubmitFunction<CentreVHUFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: CentreVHUFormValues) => {
      setInProgress(true);
      return controller
        .updateCentreVHUUsingPUT(uniteId, buildRequestDto(values))
        .then(closeModal)
        .then(refreshDashboard)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, refreshDashboard],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}
