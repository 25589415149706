import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { FilterButton } from 'components/WrappedComponents';

import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import FilterFormAutoriteFourriere from './FilterFormAutoriteFourriere';

interface FilterProps {
  setFilter: (value: Partial<FilterDashboardAutoriteFourriere>) => void;
  filter: Partial<FilterDashboardAutoriteFourriere>;
}

export default function Filter({
  filter,
  setFilter,
}: FilterProps): React.ReactElement {
  const { enabled, ...formFilter } = filter;
  function setFormFilter(value: Partial<FilterDashboardAutoriteFourriere>) {
    setFilter({
      ...filter,
      ...value,
    });
  }

  function setDisplayInvalidUniteFo(event: CheckboxChangeEvent) {
    setFilter({
      ...filter,
      enabled: !event.target.checked,
    });
  }

  return (
    <div className="dashboard-header-filter">
      <FilterButton
        filter={formFilter}
        setFilter={setFormFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <FilterFormAutoriteFourriere filter={data} setFilter={setData} />
        )}
      </FilterButton>
      <AntdCheckbox
        checked={!enabled}
        onChange={setDisplayInvalidUniteFo}
        className="checkbox-unites-inactives"
      >
        Afficher les autorités de fourrière inactives
      </AntdCheckbox>
    </div>
  );
}
