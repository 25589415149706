import React from 'react';
import { InformationMessageViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { DatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useApi } from 'hooks/ApiStoreContext';
import { DATE_FORMAT_WITH_TIME } from 'utils/formats';
import { CSSUtils } from 'utils/CSSUtils';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import {
  generateRequestDtoFromFormData,
  validateInformationMessageRequest,
} from '../utils';

function DatePickerStart({
  values,
}: FieldProps<InformationMessageFormValues>): React.ReactElement {
  const controller = useApi().ReferentielControllerApi;
  const key: keyof InformationMessageFormValues = 'startDate';

  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Date de début',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateRequestDtoFromFormData({
                  ...values,
                  startDate: value || undefined,
                });

                return await validateInformationMessageRequest(
                  controller,
                  requestObject,
                  InformationMessageViolationDtoFormFieldEnum.START_DATE,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      datePickerProps={{
        style: { width: '100%' },
        placeholder: FormPlaceholders.DateWithTime,
        format: DATE_FORMAT_WITH_TIME,
        locale: frFR.DatePicker,
        showTime: true,
      }}
    />
  );
}

export default DatePickerStart;
