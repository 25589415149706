import React from 'react';
import { Select } from 'components/WrappedComponents';
import { TraitementResponseDtoClassementEnum } from 'lib_api/lib/api/gen';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { classementEnumMapping } from 'utils/enumData';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';

interface SelectClassementProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

const selectItems =
  // List of enum options...
  Object.values(TraitementResponseDtoClassementEnum)
    .filter(
      enumValue => enumValue !== TraitementResponseDtoClassementEnum.MANUEL,
    )
    // ... with their corresponding label...
    .map(enumValue => {
      return {
        value: enumValue.toString(),
        displayValue: classementEnumMapping[enumValue].label,
      };
    });

function SelectClassement({
  values,
}: SelectClassementProps): React.ReactElement {
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'classement',
        itemProps: {
          label: 'Classement',
          hasFeedback: false,
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{ mode: 'multiple', placeholder: FormPlaceholders.Select }}
    />
  );
}

export default SelectClassement;
