import React from 'react';

import './WarningMessage.less';

interface WarningMessageProps {
  title: string;
  detail: string;
  style?: React.CSSProperties;
}

function WarningMessage({
  title,
  detail,
  style = { maxWidth: '30em' },
}: WarningMessageProps): React.ReactElement {
  return (
    <div className="WarningMessage" style={style}>
      <h4 className="Title">{title}</h4>
      <div>{detail}</div>
    </div>
  );
}

export default WarningMessage;
