import { EtatDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';
import { nullSort } from '../utils/StringUtils';

export async function fetchEtatsVehicule(
  refController: ReferentielControllerApi,
): Promise<EtatDto[]> {
  return refController.getEtatsVehiculeUsingGET().then(response => {
    return response.referenceDtoList
      .sort((a, b) => a.etat.localeCompare(b.etat))
      .sort((a, b) => {
        return nullSort(a.detail, b.detail);
      });
  });
}
