import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { FilterDashboardGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';
import { ActionsRequisesDtoActionsRequisesEnum } from 'lib_api/lib/api/gen';

interface GFFilterContextContent {
  filter: Partial<FilterDashboardGardien>;
  setFilter: Dispatch<SetStateAction<Partial<FilterDashboardGardien>>>;
  // all actions requises configured for gf users
  actionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined;
}

const GFFilterContext = createContext<GFFilterContextContent | null>(null);

export const useGFFilterContext = (): [
  Partial<FilterDashboardGardien>,
  Dispatch<SetStateAction<Partial<FilterDashboardGardien>>>,
  ActionsRequisesDtoActionsRequisesEnum[] | undefined,
] => {
  const context = useContext(GFFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter, context.actionsRequises];
};

const GFFilterProvider = GFFilterContext.Provider;

export default GFFilterProvider;
