import React, { useCallback, useEffect, useState } from 'react';
import { Empty as AntdEmpty, Spin as AntdSpin } from 'antd';

import BaseSelect, { BaseSelectProps } from './BaseSelect';

export interface AsyncSelectProps<FormValues, OptionType>
  extends Omit<BaseSelectProps<FormValues, OptionType>, 'options'> {
  fetchOptions: () => Promise<OptionType[]>;
  filterOptions?: (
    formValues: FormValues,
    options: OptionType[],
  ) => OptionType[];
}

function useFetchOptions<OptionType>(fetch: () => Promise<OptionType[]>): {
  inProgress: boolean;
  options: OptionType[];
} {
  const [options, setOptions] = useState<OptionType[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const fetchOptions = useCallback(() => {
    setInProgress(true);
    fetch()
      .then(results => {
        setOptions(results);
      })
      .finally(() => {
        setInProgress(false);
      });
  }, [setOptions, setInProgress]);

  useEffect(() => {
    fetchOptions();
  }, [fetchOptions]);

  return {
    inProgress: inProgress,
    options: options,
  };
}

export default function AsyncSelect<FormValues, OptionType>({
  fetchOptions,
  filterOptions,
  ...props
}: AsyncSelectProps<FormValues, OptionType>): React.ReactElement {
  const { options, inProgress } = useFetchOptions(fetchOptions);
  return (
    <BaseSelect
      {...props}
      options={(formValues: FormValues) => {
        return filterOptions ? filterOptions(formValues, options) : options;
      }}
      notFoundContent={inProgress ? <AntdSpin size="small" /> : <AntdEmpty />}
    />
  );
}
