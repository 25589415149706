import React from 'react';

import { ReferentielUsesDto } from 'lib_api/lib/api/gen';
import {
  buildMessageUUIDList,
  buildUsesInDossierMessage,
} from '../../Modals/ModalDesactivation/utils';

interface UsesUniteFoProps {
  uses: ReferentielUsesDto;
}

function UsesUniteFo({ uses }: UsesUniteFoProps): React.ReactElement {
  const listChildren = uses.listChildrenUniteIdCorrelation ?? [];
  const listDossierId = uses.searchDossierResult?.listDossierId ?? [];
  return (
    <ul>
      {listChildren.length > 0 && (
        <li>
          Cette unité a les unités enfants{' '}
          {buildMessageUUIDList(
            uses.listChildrenUniteIdCorrelation ?? [],
            uses.listChildrenUniteIdCorrelation?.length ?? 0,
          )}
        </li>
      )}
      {listDossierId.length > 0 && (
        <li>
          Cette unité est utilisée dans les dossiers{' '}
          {buildUsesInDossierMessage(uses.searchDossierResult)}
        </li>
      )}
    </ul>
  );
}

export default UsesUniteFo;
