import React from 'react';
import { Input as AntdInput } from 'antd';
import { TextAreaProps as AntdTextAreaProps } from 'antd/lib/input';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';

export default function BaseTextArea(
  props: AntdTextAreaProps,
): React.ReactElement {
  return <AntdInput.TextArea {...props} placeholder={FormPlaceholders.Input} />;
}
