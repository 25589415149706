import React from 'react';
import { Alert as AntdAlert } from 'antd';

import { ConditionsReactivateTypeAutoriteFourriereDto } from 'lib_api/lib/api/gen/api';

interface ConditionReactivateTypeAfProps {
  conditions: ConditionsReactivateTypeAutoriteFourriereDto | undefined;
}

function ConditionReactivateTypeAf({
  conditions,
}: ConditionReactivateTypeAfProps): React.ReactElement {
  const isInvalid: boolean =
    (conditions?.typeValid || conditions?.alreadyExist) ?? false;

  return (
    <>
      {isInvalid && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.typeValid && (
                <p className="alertInvalid--txt">
                  Le type d&apos;autorité de fourrière est déjà valide.
                </p>
              )}
              {conditions?.alreadyExist && (
                <p className="alertInvalid--txt">
                  Le type d&apos;autorité de fourrière existe déjà.
                </p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateTypeAf;
