import React from 'react';
import { Card, Skeleton } from 'antd';

import placeholderImg from 'assets/images/jpg/placeholder.jpg';
import { useVideoInfo } from './utils';
import { YOUTUBE_URL } from './constants';

import './VideoCard.less';

interface VideoCardsProps {
  videoIds: string[];
}

function VideoCards({ videoIds }: VideoCardsProps): React.ReactElement {
  return (
    <>
      <h1>Vidéos tutoriels</h1>
      <>
        {videoIds.length === 0 || !YOUTUBE_URL ? (
          <div className="noAvailableContent">
            {`Aucun contenu n'est disponible.`}
          </div>
        ) : (
          videoIds.map((videoId, i) => <VideoCard key={i} videoId={videoId} />)
        )}
      </>
    </>
  );
}

interface VideoCardProps {
  videoId: string;
}

export function VideoCard({ videoId }: VideoCardProps): React.ReactElement {
  const [loading, { title, description }] = useVideoInfo(videoId);
  if (!title) {
    return (
      <div className="card">
        <VideoPlayer videoId={videoId} />
      </div>
    );
  }

  return (
    <Card className="card" hoverable cover={<VideoPlayer videoId={videoId} />}>
      <Skeleton loading={loading} paragraph={{ rows: 1 }} active>
        <Card.Meta
          title={title}
          description={description}
          style={{ overflow: 'visible', whiteSpace: 'unset' }}
        />
      </Skeleton>
    </Card>
  );
}

function VideoPlayer({ videoId }: { videoId: string }): React.ReactElement {
  return (
    <div className="player-container">
      <img
        className="video-placeholder"
        src={placeholderImg}
        alt="Video Placeholder"
      />
      <iframe
        title={videoId}
        src={`${YOUTUBE_URL}/${videoId}?rel=0`}
        frameBorder="0"
        allowFullScreen
      />
    </div>
  );
}

export default VideoCards;
