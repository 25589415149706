import React, { useCallback, useState } from 'react';

import { ReferenceDtoAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriere,
  FicheProfilFourriereBlocFourriere,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { EditableProfilFormContainer } from 'components/ProfilFormContainer';
import InputSocialReason from './Inputs/InputSocialReason';
import InputAgreementNumber from './Inputs/InputAgreementNumber';
import DatePickerDateDebutAgrement from './Inputs/DatePickerDateDebutAgrement';
import SelectAttachmentAuthority from './Inputs/SelectAttachmentAuthority';
import DatePickerDateFinAgrement from './Inputs/DatePickerDateFinAgrement';
import InputParkSize from './Inputs/InputParkSize';
import { generateFicheProfil, generateFourriereDTO } from '../utils';
import { useConfirmProfile } from 'hooks/utils/useConfirmProfil';

const extractInitialValues = (
  values: FicheProfilFourriere,
): FicheProfilFourriereBlocFourriere => {
  return {
    socialReason: values.socialReason,
    agreementNumber: values.agreementNumber,
    agreementStartDate: values.agreementStartDate,
    agreementEndDate: values.agreementEndDate,
    attachmentAuthority: values.attachmentAuthority,
    parkSize: values.parkSize,
  };
};

interface FicheProfilProps {
  values: FicheProfilFourriere;
  refAutoriteFourriere: ReferenceDtoAutoriteFourriereDto;
  mustConfirmAccount: boolean | undefined;
  updateRequiredActions: () => void;
}

const FicheProfilFourriereForm: React.FC<FicheProfilProps> = ({
  values,
  refAutoriteFourriere,
  mustConfirmAccount,
  updateRequiredActions,
}: FicheProfilProps): React.ReactElement => {
  const user = useUserStore();
  const gardienFourriereControllerApi = useApi().GardienFourriereControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const [blocValues, setBlocValues] = useState(extractInitialValues(values));
  const confirmProfile = useConfirmProfile(user.username);

  const generateRequestDto = useCallback(
    (valuesFourriere: FicheProfilFourriereBlocFourriere) => {
      return generateFourriereDTO({ ...values, ...valuesFourriere });
    },
    [values],
  );

  const promise = useCallback(
    (valuesFourriere: FicheProfilFourriereBlocFourriere) => {
      const newInfos = generateRequestDto(valuesFourriere);
      return gardienFourriereControllerApi
        .setFourriereInfoUsingPOST(
          user.idCorrelationFourriere ? user.idCorrelationFourriere : '',
          newInfos,
        )
        .then(async savedInfo => {
          const datas = generateFicheProfil(user, savedInfo);
          setBlocValues(datas);
          // automatically confirm referentials edited by non admin users
          await confirmProfile({
            idFourriereToConfirm: savedInfo.id,
            mustConfirmAccount: mustConfirmAccount,
          });
          updateRequiredActions();
        })
        .catch(reason => {
          behaviourOnError(reason);
        });
    },
    [
      user,
      gardienFourriereControllerApi,
      behaviourOnError,
      setBlocValues,
      generateRequestDto,
      confirmProfile,
      mustConfirmAccount,
      updateRequiredActions,
    ],
  );

  return (
    <EditableProfilFormContainer
      title="Ma fourrière"
      idForm="FourriereForm"
      initialValue={blocValues}
      submit={promise}
    >
      {(data, isEdit) => (
        <>
          <InputSocialReason
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputAgreementNumber
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <DatePickerDateDebutAgrement
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <DatePickerDateFinAgrement
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <SelectAttachmentAuthority
            values={data}
            isEditable={isEdit}
            refAutoriteFourriere={refAutoriteFourriere}
            generateRequestDto={generateRequestDto}
          />
          <InputParkSize
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
        </>
      )}
    </EditableProfilFormContainer>
  );
};

export default FicheProfilFourriereForm;
