import React from 'react';
import { Button as AntdButton } from 'antd';

import { FilterDashboardAutoriteFourriere } from 'types/referentiels/AutoriteFourriere';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';

import './FilterFormFooter.less';

interface FormActionProps {
  setFilter: (value: FilterDashboardAutoriteFourriere) => void;
}

export default function FilterFormFooter({
  setFilter,
}: FormActionProps): React.ReactElement {
  const form = useBaseFormContext<FilterDashboardAutoriteFourriere>();

  function resetFilter() {
    form.setFieldsValue({
      idCorrelation: undefined,
      codeDepartement: undefined,
      libelleDepartement: undefined,
      idCorrelationTypeAutorite: undefined,
      libelle: undefined,
      enabled: true,
      ville: undefined,
      codePostal: undefined,
      idHermes: undefined,
      emptyIdHermes: false,
      idHermesUtilisateur: undefined,
      emptyIdHermesUtilisateur: false,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type={'primary'}
        onClick={() => setFilter(form.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}
