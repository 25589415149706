import { useCallback, useState } from 'react';
import { popErrorMessage } from '../utils/backAlertMessage';
import { useCancelablePromise } from '../useCancelablePromise';
import { useBoolean } from '../../utils/genericUtils';

interface Commune {
  nom: string;
  codesPostaux: Array<string>;
}

type CommuneFetcher = (commune: string) => void;

export const useCommune = (): [Array<Commune>, boolean, CommuneFetcher] => {
  const [communes, setCommunes] = useState<Array<Commune>>([]);
  const [value, setValue] = useState<string>('');

  const {
    value: isFetching,
    setIsTrue: startIsFetching,
    setIsFalse: stopIsFetching,
  } = useBoolean();

  const onFetch = () => {
    const geoApi = process.env.REACT_APP_API_GEO_GOUV_URL;

    const url = `${geoApi}/communes?nom=${encodeURI(
      value,
    )}&fields=nom,codesPostaux&boost=population&format=json&limit=8`;
    startIsFetching();
    return fetch(url);
  };

  const onSuccess = (response: Response) => {
    response
      .json()
      .then((result: Array<Commune>) => {
        setCommunes(result);
      })
      .catch((reason: string) => {
        popErrorMessage(
          `Une erreur lors du parsing des données d'autocomplétion est survenue : ${reason}`,
        );
      });
  };

  const onError = (reason: string) => {
    console.error(
      `Une erreur lors de la récupération des données d'autocomplétion est survenue : ${reason}`,
    );
  };

  useCancelablePromise(
    value === '' ? null : value,
    onFetch,
    onSuccess,
    onError,
    stopIsFetching,
    stopIsFetching,
    500,
  );

  const fetchOptions = useCallback((str: string) => {
    startIsFetching();
    setValue(str);
    setCommunes([]);
  }, []);

  return [communes, isFetching, fetchOptions];
};
