import React from 'react';
import { Alert as AntdAlert } from 'antd';

import { ConditionsReactivateMotifSortieDto } from 'lib_api/lib/api/gen/api';

interface ConditionReactivateMotifSortieProps {
  conditions: ConditionsReactivateMotifSortieDto | undefined;
}

function ConditionReactivateMotifSortie({
  conditions,
}: ConditionReactivateMotifSortieProps): React.ReactElement {
  const isInvalid: boolean =
    (conditions?.motifSortieValid || conditions?.alreadyExist) ?? false;

  return (
    <>
      {isInvalid && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.motifSortieValid && (
                <p className="alertInvalid--txt">
                  Le motif de sortie est déjà valide.
                </p>
              )}
              {conditions?.alreadyExist && (
                <p className="alertInvalid--txt">Le motif existe déjà.</p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateMotifSortie;
