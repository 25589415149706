import React from 'react';

import './FieldSummaryDisplay.less';

interface FieldSummaryProps {
  label: string;
  children: React.ReactNode;
}

export const FieldSummaryDisplay = ({
  label,
  children,
}: FieldSummaryProps): React.ReactElement => {
  return (
    <div className={'fieldSummaryContainer'}>
      <label className={'label'}>{label}</label>
      <label className={'spacer'}>:</label>
      {children === null || children === undefined ? (
        <label className="value missingValue">{'Non renseigné'}</label>
      ) : (
        <label className="value">{children}</label>
      )}
    </div>
  );
};
