import React from 'react';

import { TypeAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { ColumnProps } from 'components/WrappedComponents/Table/types';
import TableWithoutPaginationAndSort from 'components/WrappedComponents/Table/TableWithoutPaginationAndSort';

import { useReferentielDashboardUnpagedSearch } from '../utils';
import { FilterDashboardTypeAf } from './types';
import { buildHeader, useSearchTypeAf } from './utils';
import CellDetailsAndActions from './CellDetailsAndActions';

function buildDetailsAndActionsContent(
  typeAf: TypeAutoriteFourriereDto,
  refreshDashboard: () => void,
) {
  return (
    <CellDetailsAndActions
      typeAf={typeAf}
      refreshDashboard={refreshDashboard}
    />
  );
}

function buildColumns(
  refreshDashboard: () => void,
): ColumnProps<TypeAutoriteFourriereDto>[] {
  return [
    {
      title: 'Type',
      buildCellContent: typeAf => typeAf.type,
    },
    {
      title: 'Libellé notification',
      buildCellContent: typeAf => typeAf.displayedText,
    },
    {
      title: '',
      buildCellContent: typeAf =>
        buildDetailsAndActionsContent(typeAf, refreshDashboard),
    },
  ];
}

function DashboardTypeAf(): React.ReactElement {
  const defaultFilter: FilterDashboardTypeAf = { valid: true };

  const { fetchData, filter, setFilter, refreshDashboard } =
    useReferentielDashboardUnpagedSearch(useSearchTypeAf, defaultFilter);

  return (
    <div className="table-container">
      <TableWithoutPaginationAndSort
        columns={buildColumns(refreshDashboard)}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={typeAf => typeAf.id}
        filter={filter}
        setFilter={setFilter}
      />
    </div>
  );
}

export default DashboardTypeAf;
