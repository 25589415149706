import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DossierSummaryResponseDto } from 'lib_api/lib/api/gen/api';
import { Table } from 'components/WrappedComponents';
import {
  ColumnProps,
  FetchDataFunction,
} from 'components/WrappedComponents/Table/types';

import { useAFFilterContext } from 'hooks/AFFilterStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { useSearchDossierSummaries } from 'hooks/dossiers/useSearchDossierSummaries';
import { useSortContext } from 'hooks/SortStoreContext';
import { usePaginationContext } from 'hooks/PaginationStoreContext';
import { buildColumns, buildHeader } from './utils';

import {
  AutoriteFourriereSortOptions,
  DashboardAutoriteFourriereFilter,
} from '../types';

function DashboardTable(): React.ReactElement {
  const { pathname } = useLocation();
  const user = useUserStore();
  const [sort, setSort] = useSortContext();
  const [filter, setFilter, afActionsRequises] = useAFFilterContext();
  const searchDossierSummaries = useSearchDossierSummaries();
  const [pagination, setPagination] = usePaginationContext();

  /**
   * Fetch dossiers from backend based on search parameters and convert result
   */
  const fetchData: FetchDataFunction<
    DossierSummaryResponseDto,
    DashboardAutoriteFourriereFilter,
    AutoriteFourriereSortOptions
  > = useMemo(
    () => (pagination, sorters, filter) =>
      searchDossierSummaries(pagination, sorters, {
        communeEnlevementList: filter.commune,
        classementList: filter.classement,
        dateRangeBonEnlevement: filter.dateRangeBonEnlevement,
        dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
        dateRangeSortieDefinitive: filter.dateRangeSortieDefinitive,
        dateRangeOrdreDestruction: filter.dateRangeOrdreDestruction,
        idNatureBrancheList: filter.natureMiseEnFourriere,
        numeroImmatriculation: filter.search,
        idFourriereList: filter.fourriere,
        idAutoriteFourriere: user.idCorrelationAutoriteFourriere || undefined,
        statutDossierEnumList: filter.statut,
        includeDossiersSortis: filter.includeDossiersSortis,
        actionsRequises: filter.actionsRequises,
        statutsFacturation: filter.statutsTraitementFacturation,
        idMotifSortieList: filter.motifDeSortie,
        includeDossiersAnonymises: filter.includeDossiersAnonymises,
      }),
    [searchDossierSummaries, user.idCorrelationAutoriteFourriere],
  );

  const columns: ColumnProps<
    DossierSummaryResponseDto,
    AutoriteFourriereSortOptions
  >[] = useMemo(
    () => buildColumns(pathname, afActionsRequises),
    [pathname, afActionsRequises],
  );

  return (
    <div className="table-container">
      <Table
        columns={columns}
        header={buildHeader}
        fetchData={fetchData}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardTable;
