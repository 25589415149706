import React from 'react';

import {
  DossierDtoAdminFonctionnelStatusEnum,
  TraitementResponseDtoListStatutEnum,
} from 'lib_api/lib/api/gen';

import { statutEnumMapping } from 'utils/enumData';

import './StatusContent.less';

interface StatusContentProps {
  status: DossierDtoAdminFonctionnelStatusEnum | null;
}

export const StatusContent = ({
  status,
}: StatusContentProps): React.ReactElement => {
  if (!status) {
    return <></>;
  }

  const { tagColor, label } =
    statutEnumMapping[
      TraitementResponseDtoListStatutEnum[
        status as keyof typeof DossierDtoAdminFonctionnelStatusEnum
      ]
    ];

  return (
    <span style={{ backgroundColor: tagColor }} className="StatusContent">
      {label}
    </span>
  );
};
