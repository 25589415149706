import { useApi } from 'hooks/ApiStoreContext';
import { Dispatch, SetStateAction } from 'react';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { ComputedResponseDtoListAvailableFilesEnum } from 'lib_api/lib/api/gen';
import { message as AntdMessage } from 'antd';
import FileSaver from 'file-saver';
import { extractFileNameFromContentDisposition } from 'utils/requestUtils';

/**
 * Hook to export dossier based on given filters.
 */
export function useDownloadDocument(
  setLoading: Dispatch<SetStateAction<boolean>>,
  dossierId: string,
): (file: ComputedResponseDtoListAvailableFilesEnum) => void {
  const controllerApi = useApi().DossierControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return async fileEnum => {
    setLoading(true);
    await controllerApi
      .downloadDocumentUsingGET(fileEnum, dossierId)
      .then((result: Response) => {
        setLoading(false);
        const fileName = extractFileNameFromContentDisposition(result);

        if (fileName === undefined) {
          void AntdMessage.error(
            'Erreur lors du téléchargement: Veuillez réessayer ultérieurement',
          );
          return;
        }

        // Create dom link to download file
        result
          .blob()
          .then((blob: Blob) => {
            FileSaver.saveAs(blob, fileName);
          })
          .catch(behaviourOnError);
      })
      .catch((errorResponse: Response) => {
        setLoading(false);
        behaviourOnError(errorResponse);
      });
  };
}
