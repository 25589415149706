import React from 'react';
import { Form } from 'components/WrappedComponents';

import {
  FicheProfilFourriereBlocCoordonnees,
  SemaineHoraire,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import BooleanStoreProvider from 'hooks/BooleanStoreContext';
import DayComponent from './components/DayComponent';
import ResetButton from '../ResetButton';

interface FicheProfilProps {
  values: FicheProfilFourriereBlocCoordonnees;
  setHoraires: (horaires: SemaineHoraire) => void;
  closeForm: () => void;
  disable: boolean;
}

const HorairesForm: React.FC<FicheProfilProps> = ({
  values,
  setHoraires,
  closeForm,
  disable,
}: FicheProfilProps): React.ReactElement => {
  return (
    <Form
      onSubmit={async result => {
        setHoraires(result);
        closeForm();
        return Promise.resolve();
      }}
      initialValue={values.horaires}
      id={'HoraireForm'}
      validateButtonTitle={'Enregistrer'}
      validateButtonClassName={'right'}
    >
      {(data, SubmitButton): React.ReactElement => {
        return (
          <BooleanStoreProvider value={{ value: disable }}>
            <DayComponent
              values={data}
              dayLabel={'Lundi'}
              surRendezVousKey={'lundiSurRendezVous'}
              horairesOuverturesKey={'lundiHorairesOuvertures'}
              horairesAppelKey={'lundiHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Mardi'}
              surRendezVousKey={'mardiSurRendezVous'}
              horairesOuverturesKey={'mardiHorairesOuvertures'}
              horairesAppelKey={'mardiHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Mercredi'}
              surRendezVousKey={'mercrediSurRendezVous'}
              horairesOuverturesKey={'mercrediHorairesOuvertures'}
              horairesAppelKey={'mercrediHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Jeudi'}
              surRendezVousKey={'jeudiSurRendezVous'}
              horairesOuverturesKey={'jeudiHorairesOuvertures'}
              horairesAppelKey={'jeudiHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Vendredi'}
              surRendezVousKey={'vendrediSurRendezVous'}
              horairesOuverturesKey={'vendrediHorairesOuvertures'}
              horairesAppelKey={'vendrediHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Samedi'}
              surRendezVousKey={'samediSurRendezVous'}
              horairesOuverturesKey={'samediHorairesOuvertures'}
              horairesAppelKey={'samediHorairesAppels'}
            />
            <DayComponent
              values={data}
              dayLabel={'Dimanche'}
              surRendezVousKey={'dimancheSurRendezVous'}
              horairesOuverturesKey={'dimancheHorairesOuvertures'}
              horairesAppelKey={'dimancheHorairesAppels'}
            />

            {!disable && (
              <div className={'navigation'}>
                {SubmitButton}
                <ResetButton resetEdit={closeForm} />
              </div>
            )}
          </BooleanStoreProvider>
        );
      }}
    </Form>
  );
};

export default HorairesForm;
