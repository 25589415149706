import { RequiredUserActionsDto } from 'lib_api/lib/api/gen';
import { createContext, useContext } from 'react';

interface RequiredUserAction {
  requiredUserActions?: RequiredUserActionsDto;
  updateRequiredUserActions: () => void;
}

export const RequiredUserActionContext =
  createContext<RequiredUserAction | null>(null);

export const useRequiredUserActionContext = (): RequiredUserAction => {
  const context = useContext(RequiredUserActionContext);
  if (context === null) {
    throw new Error('useRequiredUserActionContext called outside Context.');
  }

  return context;
};

const RequiredUserActionProvider = RequiredUserActionContext.Provider;

export default RequiredUserActionProvider;
