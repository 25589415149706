import React, { useState } from 'react';
import { Button as AntdButton } from 'antd';
import ModalDetails from './Modals/ModalDetails';
import ModalModification from './Modals/ModalModification';
import { MarqueDto } from 'lib_api/lib/api/gen';
import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  marque: MarqueDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  marque,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isModifOpen, setIsModifOpen] = useState<boolean>(false);

  return (
    <>
      <AntdButton
        onClick={() => {
          setIsDetailsOpen(true);
        }}
      >
        Détails
      </AntdButton>
      <ModalDetails
        marque={marque}
        visible={isDetailsOpen}
        close={() => {
          setIsDetailsOpen(false);
        }}
      />
      <AntdButton
        className="menuActions"
        onClick={() => {
          setIsModifOpen(true);
        }}
      >
        Modifier
      </AntdButton>
      <ModalModification
        refreshDashboard={refreshDashboard}
        marque={marque}
        visible={isModifOpen}
        close={() => {
          setIsModifOpen(false);
        }}
      />
    </>
  );
}

export default CellDetailsAndActions;
