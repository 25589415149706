import {
  CarrosserieClassementManuelDto,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

export function fetchCarrosserie(
  refController: ReferentielControllerApi,
): Promise<CarrosserieClassementManuelDto[]> {
  return refController
    .getValidCarrosserieClassementManuelUsingGET()
    .then(response => {
      return response.referenceDtoList.sort((a, b) =>
        a.libelle.localeCompare(b.libelle),
      );
    });
}
