import React, { useCallback, useState } from 'react';
import FileSaver from 'file-saver';
import { Button as AntdButton, List as AntdList, Spin } from 'antd';

import { DownloadOutlined } from '@ant-design/icons';

import {
  DownloadableFileMetaData,
  DownloadableFileStatus,
} from 'hooks/DownloadableFilesContext';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import FileStatusIcon from './FileStatusIcon';

import './DownloadableFileItem.less';

const DownloadableFileItem = ({
  file,
}: {
  file: DownloadableFileMetaData;
}): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const exportApi = useApi().ExportControllerApi;

  const downloadFile = useCallback(() => {
    setLoading(true);
    exportApi
      .downloadExportUsingGET(file.id)
      .then((result: Response) => {
        setLoading(false);

        // Create dom link to download file
        return result.blob();
      })
      .then((blob: Blob) => {
        FileSaver.saveAs(blob, file.name);
      })
      .catch((errorResponse: Response) => {
        setLoading(false);
        void backAlertMessage(errorResponse);
      });
  }, [file, exportApi]);

  return (
    <AntdList.Item className="FileItem">
      <FileStatusIcon file={file} />
      <div className="FileItemMainArea">
        <h5 className="FileItemTitle">{file.name}</h5>
        <div className="FileItemDetail">{file.detail}</div>
        {file.requestDate && (
          <div className="FileItemDate">Créé {file.requestDate}.</div>
        )}
        {file.expirationDate && (
          <div className="FileItemDate">
            Ce fichier expirera {file.expirationDate}.
          </div>
        )}
      </div>

      {file.status === DownloadableFileStatus.SUCCESS && (
        <AntdButton
          icon={loading ? <Spin /> : <DownloadOutlined />}
          type={'default'}
          shape={'round'}
          onClick={() => downloadFile()}
        />
      )}
    </AntdList.Item>
  );
};

export default DownloadableFileItem;
