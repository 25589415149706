import React, { useMemo } from 'react';
import { Table as AntdTable } from 'antd';
import { ColumnsType as AntdColumnType } from 'antd/lib/table';
import { TableRowSelection as AntdTableRowSelection } from 'antd/lib/table/interface';

import BasicColumnTitle from './BasicColumnTitle';
import { Empty, EmptyOnError } from 'components/WrappedComponents/Empty';

import './BasicTable.less';
import { BasicTableProps } from './types';

/**
 * Basic table component using Antd's Table component. This component do not use
 * pagination by default because pagination will be managed by an upper component.
 *
 * @param data - Array of objects to be displayed in table. Each object need to have a
 *        key attribute so it can be identified to trigger actions on it (such as
 *        selecting it). Other attributes may be displayed when a column is defined with
 *        a key corresponding to this attribute (ex: {key: 'item1', val1: 1} is a valid
 *        object, and a column with key 'val1' will display '1' for this object). Since
 *        pagination is not activated here, all items provided in data will be displayed,
 *        it is the role of an upper component to manage pagination (we do not use Antd's
 *        Table default pagination because it would require to load all items at once
 *        instead of fetching them page by page).
 * @param columnsProps - Array of props defining columns to use. Those props will be
 *        converted into Antd columns props, and will use titles created using components
 *        ColumnTitle.
 * @param isLoading - Display a spinner
 * @param disabled - Set ColumnTitle components to disabled
 * @param reload - Function to call to reload the data of the table
 * @param isOnError - Boolean indicating if the table is on error and used to show a
 *        different empty component if it is the case
 * @param selectItems - getter and setter for items selection state (/!\ select
 *        functionnality won't work if no key can be defined for items (see buildItemKey
 *        below)
 * @param buildItemKey - function to choose a unique key for an item - this function is
 *        required when using selection functionnality
 * @param antdTableProps - to use other props defined by Antd library (/!\ may interfere
 *        with props already defined by this component)
 */
function BasicTable<DataType extends object>({
  data,
  columnsProps,
  isLoading,
  reload,
  isOnError,
  selectItems,
  buildItemKey,
  antdTableProps,
}: BasicTableProps<DataType>): React.ReactElement {
  // Columns properties converted to be used by Antd
  const columns: AntdColumnType<DataType> = useMemo(
    () =>
      columnsProps.map(columnProps => {
        const { dataIndex, buildCellContent, width, key } = columnProps;
        return {
          title: <BasicColumnTitle columnProps={columnProps} />,
          dataIndex: key || dataIndex, // if key exists (for "sort" column), else dataIndex
          width,
          key: key || dataIndex,
          render:
            buildCellContent !== undefined
              ? (_, value): React.ReactNode => buildCellContent(value)
              : undefined,
          align: 'center',
        };
      }),
    [columnsProps],
  );

  // Item selection properties converted to be used by Antd
  const rowSelection: AntdTableRowSelection<DataType> | undefined = useMemo(
    () =>
      selectItems !== undefined && buildItemKey !== undefined
        ? {
            type: 'checkbox',
            selectedRowKeys: selectItems.selected.map(item =>
              buildItemKey(item),
            ),
            onChange: (_, newSelected: DataType[]): void =>
              selectItems.onSelect(newSelected),
          }
        : undefined,
    [selectItems, buildItemKey],
  );

  // Add key to items so they can be selected
  const dataWithKeys = data.map(item => ({
    ...item,
    key: buildItemKey !== undefined ? buildItemKey(item) : undefined,
  }));

  return (
    <AntdTable
      dataSource={dataWithKeys}
      columns={columns}
      loading={isLoading}
      rowSelection={rowSelection}
      size="small"
      className="basic-table"
      locale={{
        emptyText: isOnError ? <EmptyOnError reload={reload} /> : <Empty />,
      }}
      {...antdTableProps}
    />
  );
}

export default BasicTable;
