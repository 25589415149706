import React, { useMemo } from 'react';
import moment from 'moment';

import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';

import { UpdateSortieFormValues } from './types';

function useInitialValues(
  dossierAdmin: ExportDonneesDossierAdminFonctionnelDto | null,
): UpdateSortieFormValues {
  const dateSortie = dossierAdmin?.dossier.body?.sortieVehicule?.dateSortie;
  const motifSortie = dossierAdmin?.dossier.body?.sortieVehicule?.motifSortie;

  return useMemo(() => {
    return {
      idCorrelationMotifSortie: motifSortie?.idCorrelation ?? undefined,
      dateSortie: dateSortie ? moment(dateSortie) : undefined,
      comment: null,
    };
  }, [motifSortie, dateSortie]);
}

export default useInitialValues;
