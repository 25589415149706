import React from 'react';

import {
  FourriereDto,
  FourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import {
  buildFormValues,
  useSubmitUpdateFourriere,
  useValidateUpdateFourriere,
} from '../utils';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import { FourriereFormValues } from 'types/referentiels/Fourriere';
import ModalEditionFourriere from './ModalEditionFourriere';

interface ModalUpdateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
  fourriere: FourriereDto;
}

function ModalUpdate({
  close,
  visible,
  refreshDashboard,
  fourriere,
}: ModalUpdateProps): React.ReactElement {
  const initials: FourriereFormValues = buildFormValues(fourriere);
  const { submit, inProgress } = useSubmitUpdateFourriere(
    fourriere.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateFourriere(fourriere.idCorrelation);

  return (
    <ModalEditionFourriere
      close={close}
      visible={visible}
      title="Mise à jour de l'autorité de fourrière"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
      additionalInputs={[
        {
          field: FourriereViolationDtoFormFieldEnum.ID_HERMES,
          name: 'idHermes',
          tooltipProps: {
            title:
              'Attention: toute modification de cet ID créé par la DNID aura des conséquences sur les flux de remises de véhicules à vendre.',
            trigger: ['focus'],
            placement: 'top',
          },
          label: 'Id Hermes',
          render: () => {
            return <BaseInputNumber />;
          },
        },
      ]}
    />
  );
}

export default ModalUpdate;
