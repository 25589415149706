import React from 'react';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { useImportManuel } from 'hooks/referentiels/useImportManuel';
import Uploader from 'components/WrappedComponents/Uploader/Uploader';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';
import UploadCard from 'components/Card/UploadCard';
import { ManuelType } from 'types/referentiels/Manuels';

interface ManuelImportProps {
  index: number;
  label: string;
  userType: UserDtoProfileEnum;
  manuelType: ManuelType;
}

export default function ManuelImport({
  index,
  label,
  userType,
  manuelType,
}: ManuelImportProps): React.ReactElement {
  const { fetchManuel, importStatus } = useImportManuel(manuelType);

  return (
    <UploadCard status={importStatus} key={index}>
      <Uploader
        title={label}
        accept={supportedFilesDetails.PDF.extension}
        fileTypes={supportedFilesDetails.PDF.fileTypes}
        importRoute={fetchManuel(userType)}
      />
    </UploadCard>
  );
}
