import React from 'react';
import { Spin as AntSpinner } from 'antd';
import './Spinner.less';

function Spinner(): JSX.Element {
  return (
    <div className="spinner-container">
      <AntSpinner />
    </div>
  );
}

export default Spinner;
