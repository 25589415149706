import React, { useMemo } from 'react';

import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';

import { UpdateDonnesVehiculeFormValues } from './types';

function useInitialValues(
  dossierAdmin: ExportDonneesDossierAdminFonctionnelDto | null,
): UpdateDonnesVehiculeFormValues {
  const immatriculation =
    dossierAdmin?.dossier?.body?.ficheVehicule?.immatriculation;
  const motifMef = dossierAdmin?.dossier?.body?.ficheInfraction?.motifMef;
  const etat = dossierAdmin?.dossier?.body?.ficheVehicule?.etat;

  return useMemo(() => {
    return {
      idCorrelationMotifMef: motifMef?.idCorrelation ?? undefined,
      plaqueImmatriculation: immatriculation?.numero ?? undefined,
      sansPlaque: immatriculation?.sansPlaque ?? undefined,
      paysEtranger: immatriculation?.paysEtranger ?? undefined,
      idCorrelationEtatVehicule: etat?.idCorrelation ?? undefined,
      comment: null,
    };
  }, [immatriculation, motifMef]);
}

export default useInitialValues;
