import React from 'react';
import { Modal as AntdModal } from 'antd';

import { MotifSortieDto, TypeAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

interface ModalDetailsProps {
  typeAf: TypeAutoriteFourriereDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  typeAf,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id de corrélation" value={typeAf.idCorrelation} />
      <FieldDisplay label="Type" value={typeAf.type} />
      <FieldDisplay
        label="Libellé notification"
        value={typeAf.displayedText}
        additionalValueClassName="multiLineValue"
      />
      <FieldDisplay
        label="Code typologie DNID Niveau 1"
        value={typeAf.hermesCodeTypologieNiveau1}
      />
      <FieldDisplay
        label="Code typologie DNID Niveau 2"
        value={typeAf.hermesCodeTypologieNiveau2}
      />
    </AntdModal>
  );
}

export default ModalDetails;
