import React from 'react';
import { FormInstance as AntdFormInstance } from 'antd/lib/form';

const FormActionsContext = React.createContext<AntdFormInstance | null>(null);

export function useFormActionsContext(): AntdFormInstance {
  const context = React.useContext(FormActionsContext);
  if (context === null) {
    throw new Error(
      'Context should not be null, you can only call this hook inside FormWrapper component',
    );
  }
  return context;
}

const FormActionsProvider = FormActionsContext.Provider;
export default FormActionsProvider;
