import React from 'react';
import { Button as AntdButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { DetailedInformationMessageDto } from 'lib_api/lib/api/gen';

import useFormModal from '../../Form/utils';
import InformationMessageForm from '../../Form/InformationMessageForm';
import Filter from '../Filter';
import { FilterInformationMessage } from './types';

import './DashboardHeader.less';

interface DashboardHeaderProps {
  setFilter: (value: Partial<FilterInformationMessage>) => void;
  filter: Partial<FilterInformationMessage>;
}

function DashboardHeader({
  setFilter,
  filter,
}: DashboardHeaderProps): React.ReactElement {
  const { isOpen, openFormModal, closeFormModal } =
    useFormModal<DetailedInformationMessageDto>();

  return (
    <div className="dashboard-header-fields">
      <Filter filter={filter} setFilter={setFilter} />
      <AntdButton
        className={'create-dossier-button'}
        icon={<PlusOutlined />}
        type={'primary'}
        shape={'round'}
        onClick={() => openFormModal()}
      >
        Créer un nouveau message d&apos;information
      </AntdButton>
      {isOpen && (
        <InformationMessageForm
          close={closeFormModal}
          callAfterSubmit={() => setFilter({ ...filter })}
        />
      )}
    </div>
  );
}

export default DashboardHeader;
