import { ReferentielSearchResultDtoFourriereDto } from 'lib_api/lib/api/gen';

import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { FilterDashboardFourriere } from 'types/referentiels/Fourriere';
import { FourriereSort } from 'enums/referentiels/Fourriere';
import { useApi } from 'hooks/ApiStoreContext';
import { searchFourriereUsingSpecs } from 'search/searchFourriere';

function useSearchFourriere(): (
  filters: FilterDashboardFourriere,
  sorts: TableSort<FourriereSort>,
  pagination: TablePagination,
) => Promise<ReferentielSearchResultDtoFourriereDto> {
  const controller = useApi().ReferentielControllerApi;

  return (filters, sorts, pagination) => {
    return searchFourriereUsingSpecs(controller, filters, sorts, pagination);
  };
}

export default useSearchFourriere;
