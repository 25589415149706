import React, { useCallback } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';
import AntdForm from 'antd/lib/form';

import { Form } from 'components/WrappedComponents';
import { useValidateAndSubmit } from './utils';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

interface Violation<FieldKey> {
  formField: FieldKey;
  message: string;
}

/**
 * All parameters for the generic modal form
 *
 * DtoType: Type returned by submit function
 * FormValues: Type of object sent to form
 * ViolationType: Type of violation object returned by validation requests
 * FieldKey: Type of enum used by backend to validate form field
 *
 * @param initialValues: Initial values of form
 * @param controllerValidate: Send request to validate complete form
 * @param controllerValidateField: Send request to validate one form field
 * @param validationKey: key of violations object with array of violations to display in form
 * @param controllerSubmit: Send request to submit form
 * @param visible: boolean to know if modal is visible or not
 * @param close: Function wich close modal (set visible to false)
 * @param refreshDashboard: Method to refresh dashboard after submit succeed
 * @param onChangeFormValues: Function to change the default onChange
 * @param modalTitle: Title of modal displayed
 * @param children: Content of form
 */
interface FormModalProps<DtoType, FormValues, ViolationType, FieldKey> {
  initialValues: FormValues;
  controllerValidate?: (values: FormValues) => Promise<ViolationType>;
  controllerValidateField?: (
    values: FormValues,
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => Promise<ViolationType>;
  validationKey?: keyof ViolationType;
  controllerSubmit: (requestGenerator: FormValues) => Promise<DtoType>;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
  onChangeFormValues?: (
    values: FormValues,
    changedValues: Partial<FormValues>,
  ) => void;
  modalTitle: string;
  children: (
    values: FormValues,
    validateField: (
      key: keyof FormValues,
      field: FieldKey,
      value: unknown,
    ) => Promise<void>,
    setFieldValues: (values: Partial<FormValues>) => void,
  ) => React.ReactElement;
}

function FormModal<DtoType, FormValues, ViolationType, FieldKey>({
  initialValues,
  controllerValidate,
  controllerValidateField,
  validationKey,
  controllerSubmit,
  visible,
  close,
  refreshDashboard,
  onChangeFormValues,
  modalTitle,
  children,
}: FormModalProps<
  DtoType,
  FormValues,
  ViolationType,
  FieldKey
>): React.ReactElement {
  const [form] = AntdForm.useForm();

  const { values, setValues, validateAndSubmit, closeAndResetModal } =
    useValidateAndSubmit(
      initialValues,
      controllerSubmit,
      close,
      refreshDashboard,
      controllerValidate,
      validationKey,
    );

  // Generic method to validate a field
  const validateField: (
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => Promise<void> = async (
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => {
    if (!controllerValidateField || !validationKey) {
      return Promise.resolve();
    }
    try {
      const response = await controllerValidateField(values, key, field, value);
      const violations = response[validationKey];
      if (violations && Array.isArray(violations)) {
        const fieldViolations = violations.filter(
          (violation: Violation<FieldKey>) => violation.formField === field,
        );

        if (fieldViolations.length > 0) {
          return Promise.reject(
            fieldViolations
              .map((violation: Violation<FieldKey>) => violation.message)
              .join(' '),
          );
        }
      }
    } catch (e) {
      if (e instanceof Response) {
        return Promise.reject((await backAlertMessage(e)).description);
      }
    }

    return Promise.resolve();
  };

  const setFieldValues = useCallback(
    (newValues: Partial<FormValues>) => {
      // Update the form items with new values
      form.setFieldsValue(newValues);

      // `form.setFieldsValue` is not enough to update the state of the form because it does not trigger the `onChange` method,
      // so we explicitly need to call setValues with the new values
      setValues(preValues => {
        return {
          ...preValues,
          ...newValues,
        };
      });
    },
    [form, setValues],
  );

  return (
    <AntdModal
      visible={visible}
      onCancel={() => {
        form.resetFields();
        closeAndResetModal();
      }}
      footer={null}
      destroyOnClose={true}
      width={680}
      maskClosable={false}
    >
      <Form
        form={form}
        initialValue={initialValues}
        id={'createOrUpdateModal'}
        onSubmit={async () => {
          await validateAndSubmit(values);
          form.resetFields();
        }}
        onChange={(values, newValues) => {
          if (onChangeFormValues) {
            onChangeFormValues(values, newValues);
          }

          setValues(values);
        }}
        validateButtonTitle={'Enregistrer'}
        validateButtonClassName={'ValidateFormButton'}
      >
        {(values, SubmitButton): React.ReactElement => {
          return (
            <>
              <h3 className={'mainTitle'}>{modalTitle}</h3>
              {children(values, validateField, setFieldValues)}

              <div className="ModalFooter">
                <AntdButton
                  onClick={() => {
                    form.resetFields();
                    closeAndResetModal();
                  }}
                >
                  Annuler
                </AntdButton>
                {SubmitButton}
              </div>
            </>
          );
        }}
      </Form>
    </AntdModal>
  );
}

export default FormModal;
