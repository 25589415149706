import React from 'react';
import { WarningOutlined } from '@ant-design/icons';

import './ErrorHeader.less';

export const ErrorHeader = (): React.ReactElement => {
  return (
    <div className="ErrorAlert">
      <div>
        <WarningOutlined className="ErrorIcon" />
      </div>
      <div>
        Attention : une erreur s&apos;est produite sur ce dossier, bloquant
        potentiellement son avancée dans le workflow.
      </div>
    </div>
  );
};
