import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Result, Tooltip } from 'antd';

import {
  DownloadableFileMetaData,
  DownloadableFileStatus,
} from 'hooks/DownloadableFilesContext';

import './FileStatusIcon.less';

type FileIconType = {
  [key in DownloadableFileStatus]: React.ReactNode;
};

const fileStatusIconMapping: FileIconType = {
  SUCCESS: <Result className="FileDownloadStatus" status="success" />,
  ON_ERROR: <Result className="FileDownloadStatus" status="error" />,
  IN_PROCESS: <LoadingOutlined style={{ fontSize: 24 }} spin />,
};

const FileStatusIcon = ({
  file,
}: {
  file: DownloadableFileMetaData;
}): React.ReactElement => {
  return (
    <Tooltip title={file.errorDetail}>
      <span className="FileIcon">{fileStatusIconMapping[file.status]}</span>
    </Tooltip>
  );
};

export default FileStatusIcon;
