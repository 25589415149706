import { useEffect, useState } from 'react';

import { ReferenceDtoInformationMessageDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { backAlertMessage } from '../utils/backAlertMessage';

type RefInformationMessageType = ReferenceDtoInformationMessageDto | undefined;

export const useGetInformationMessages = (): RefInformationMessageType => {
  const [refinformationMessage, setRefinformationMessage] =
    useState<RefInformationMessageType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refinformationMessage === undefined) {
      referentielControllerApi
        .getOnlyValidInformationMessageByProfileUsingGET()
        .then(fetchedRef => {
          setRefinformationMessage(fetchedRef);
        })
        .catch(backAlertMessage);
    }
  }, [referentielControllerApi, refinformationMessage]);
  return refinformationMessage;
};
