import { useState, useEffect } from 'react';

import { ReferenceDtoAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useRefAutoriteFourriere = (
  callApi = true,
): ReferenceDtoAutoriteFourriereDto | undefined => {
  const behaviourOnError = useHandleBackErrors();

  const [ref, setRef] = useState<ReferenceDtoAutoriteFourriereDto | undefined>(
    undefined,
  );
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (callApi && ref === undefined) {
      referentielControllerApi
        .getValidAutoriteFourriereUsingGET()
        .then(fetchedRef => {
          setRef(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, callApi, ref]);

  return ref;
};
