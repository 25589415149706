// Hook to interact with authentication api
import { Dispatch, SetStateAction, useCallback } from 'react';

import { JwtDto } from 'lib_api/lib/api/gen';
import {
  AuthControllerApiFp,
  ErrorDto,
  ErrorDtoCodeEnum,
} from 'lib_api/lib/api/gen/api';

import { useEnvironnement } from 'hooks/EnvironementStoreContext';
import { backAlertMessage, isErrorBodyAnErrorDto } from './backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';

type AuthenticationHook = {
  redirectLogin: () => void;
  redirectLogout: () => void;
  authenticate: (code: string) => Promise<JwtDto | null>;
  refresh: (refreshToken: string) => Promise<JwtDto | null>;
};

const defaultAuthError: ErrorDto = {
  code: ErrorDtoCodeEnum.API_AUTHENTICATION_ERROR,
  message: defaultErrorMessage,
  localized: defaultErrorMessage,
};

export const useAuthentication = (
  setAuthError?: Dispatch<SetStateAction<ErrorDto | undefined>>,
): AuthenticationHook => {
  const [env] = useEnvironnement();

  // Current location is used as redirect uri during login
  const loginRedirectUri = window.location.origin + window.location.pathname;
  // Root path is used as redirect uri during logout
  const logoutRedirectUri = window.location.origin;

  // Callback to redirect on IDP's login page
  const redirectLogin = useCallback(() => {
    AuthControllerApiFp()
      .redirectToLoginUsingGET({ redirectUri: loginRedirectUri })(
        fetch,
        env.BACKEND_URL,
      )
      .then(resp => window.location.replace(resp.redirectUri))
      .catch(backAlertMessage);
  }, [env, loginRedirectUri]);

  // Callback to redirect on IDP's logout page
  const redirectLogout = useCallback(() => {
    AuthControllerApiFp()
      .redirectToLogoutUsingGET({ redirectUri: logoutRedirectUri })(
        fetch,
        env.BACKEND_URL,
      )
      .then(resp => window.location.replace(resp.redirectUri))
      .catch(backAlertMessage);
  }, [env, logoutRedirectUri]);

  // Callback to use IDP's code to authenticate on SIF
  const authenticate = useCallback(
    (code: string) =>
      AuthControllerApiFp()
        .authenticateUsingGET({ code, redirectUri: loginRedirectUri })(
          fetch,
          env.BACKEND_URL,
        )
        .catch(async (errorResponse: Response) => {
          const errorBody = await errorResponse.json();
          setAuthError &&
            setAuthError(
              isErrorBodyAnErrorDto(errorBody) ? errorBody : defaultAuthError,
            );
          return null;
        }),
    [env, loginRedirectUri, setAuthError],
  );

  // Callback to use refresh token to authenticate on SIF
  const refresh = useCallback(
    (refreshToken: string) =>
      AuthControllerApiFp()
        .refreshUsingGET({ refreshToken })(fetch, env.BACKEND_URL)
        .catch(async (errorResponse: Response) => {
          const errorBody = await errorResponse.json();
          setAuthError &&
            setAuthError(
              isErrorBodyAnErrorDto(errorBody) ? errorBody : defaultAuthError,
            );
          return null;
        }),
    [env, loginRedirectUri, setAuthError],
  );

  return {
    redirectLogin,
    redirectLogout,
    authenticate,
    refresh,
  };
};
