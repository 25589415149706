import React from 'react';
import { TableSort } from 'components/WrappedComponents/Table/types';

import CreateDossier from './CreateDossier';
import DownloadButton from './DownloadButton';
import { Filter } from './Filters';
import { FilterDashboardGardien } from './Filters/types';

import './DashboardHeader.less';

interface DashboardHeaderProps<SortType extends string | undefined> {
  totalCount: number;
  selectedCount: number;
  setFilter: (value: Partial<FilterDashboardGardien>) => void;
  filter: Partial<FilterDashboardGardien>;
  sort: TableSort<SortType>;
}

function DashboardHeader<SortType extends string | undefined>({
  totalCount,
  setFilter,
  filter,
  sort,
}: DashboardHeaderProps<SortType>): React.ReactElement {
  const countString = `${totalCount} véhicule${
    totalCount > 1 ? 's' : ''
  } trouvé${totalCount > 1 ? 's' : ''}`;

  return (
    <div>
      <div className="dashboard-header-fields">
        <Filter filter={filter} setFilter={setFilter} />
        <div className="dashboard-header-actions">
          <CreateDossier />
          <DownloadButton filters={filter} sort={sort} />
        </div>
      </div>

      <p className="dashboard-number-vehicules">{countString}</p>
    </div>
  );
}

export default DashboardHeader;
