import React from 'react';
import { Modal as AntdModal } from 'antd';
import moment from 'moment';
import { FluxDto } from 'lib_api/lib/api/gen';
import { DATE_FORMAT } from 'utils/formats';
import './ModalError.less';

interface ModalDetailsProps {
  flux: FluxDto;
  visible: boolean;
  close: () => void;
}

function ModalError({
  flux,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={true}
      className="modalError"
    >
      <h3>
        {`Message d’erreur sur l’appel du ${moment(flux.dateAppel).format(
          DATE_FORMAT,
        )}`}
      </h3>
      <div className={'content'}>{flux.error}</div>
    </AntdModal>
  );
}

export default ModalError;
