import React from 'react';

import { MotifMefDto } from 'lib_api/lib/api/gen';

import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { useReferentielDashboardSearchWithSort } from '../search/search';
import CellDetailsAndActions from './CellDetailsAndActions';
import { buildHeader, useSearchMotifMef } from './utils';
import { MotifMefSort } from './types';

import './DashboardMotifMef.less';

function buildDetailsContent(
  data: MotifMefDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions
      motifMef={data}
      refreshDashboard={refreshDashboard}
    />
  );
}

function DashboardMotifMef(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchMotifMef,
    {
      enabled: true,
    },
    {
      NATURE: true,
    },
  );

  function buildColumns(): ColumnProps<MotifMefDto, MotifMefSort>[] {
    return [
      {
        title: 'Nature de MEF',
        buildCellContent: data => data.natureLibelleCourt,
        sortedField: 'NATURE',
      },
      {
        title: 'Motif de MEF',
        buildCellContent: data => (
          <span className="motif-mef">{data.motifMef}</span>
        ),
        sortedField: 'LIBELLE',
      },
      {
        title: 'Est Rodéo',
        buildCellContent: data => (data.rodeo === true ? 'Oui' : 'Non'),
      },
      {
        title: 'Est DICEM',
        buildCellContent: data => (data.dicem === true ? 'Oui' : 'Non'),
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<MotifMefDto, MotifMefSort>[] = buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardMotifMef;
