import React from 'react';
import {
  AutoComplete as AntdAutoComplete,
  AutoCompleteProps as AntdAutoCompleteProps,
  Spin as AntdSpin,
} from 'antd';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useBaseFormContext } from '../BaseFormContext';
import { setFieldValue } from '../utils';

export interface AutocompleteDependencies<FormValues, OptionType> {
  name: keyof FormValues & AntdNamePath & string;
  getValue: (option: OptionType) => string | undefined;
}

export interface BaseAutocompleteProps<FormValues, OptionType>
  extends Omit<AntdAutoCompleteProps<string, OptionType>, 'options' | 'name'> {
  optionsFetcher: () => [OptionType[], boolean, (str: string) => void];
  buildValue: (option: OptionType) => string;
  getCurrentFieldValue: (option: OptionType) => string;
  name: keyof FormValues & AntdNamePath & string;
  dependencies?: AutocompleteDependencies<FormValues, OptionType>[];
}

export default function BaseAutocomplete<FormValues, OptionType>({
  optionsFetcher,
  buildValue,
  getCurrentFieldValue,
  name,
  dependencies,
  ...props
}: BaseAutocompleteProps<FormValues, OptionType>): React.ReactElement {
  const [options, isFetching, fetchOptions] = optionsFetcher();
  const form = useBaseFormContext<FormValues>();

  return (
    <AntdAutoComplete
      {...props}
      onChange={(value: string, option: OptionType | OptionType[]) => {
        fetchOptions(value);

        if (
          !Array.isArray(option) &&
          getCurrentFieldValue(option) !== undefined
        ) {
          setFieldValue(form, name, getCurrentFieldValue(option));
          dependencies?.forEach(dependency => {
            setFieldValue(form, dependency.name, dependency.getValue(option));
          });
          props.onChange &&
            props.onChange(
              getCurrentFieldValue(option) as unknown as string,
              option,
            );
          form
            .validateFields(
              [name].concat(
                dependencies?.map(dependency => dependency.name) ?? [],
              ),
            )
            .catch(() => {
              // Validation error occurred, do nothing
            });
        } else {
          setFieldValue(form, name, value);
          props.onChange && props.onChange(value, option);
          form.validateFields([name]).catch(() => {
            // Validation error occurred, do nothing
          });
        }
      }}
      options={options.map(option => {
        return {
          value: buildValue(option),
          ...option,
        };
      })}
      notFoundContent={isFetching ? <AntdSpin /> : null}
      placeholder={FormPlaceholders.Input}
    />
  );
}
