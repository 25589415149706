import { Moment } from 'moment';

import {
  AutoritePrescriptriceDtoQualiteAutoriteEnum,
  EtatDtoEtatEnum,
  MarqueDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { BaseOptionType } from '../../../components/BaseForm/Select/BaseSelect';

export interface FicheInfractionFormValues {
  idCorrelationAutoriteFourriere?: string;
  dateConstatation?: Moment;
  nomAutoritePrescriptrice?: string;
  qualiteAutorite?: AutoritePrescriptriceDtoQualiteAutoriteEnum;
  nomAgent?: string;
  nigend?: string;
  uniteFo?: BaseOptionType<UniteFOResponseDto>;
  emailUniteFo?: string;
  lieuPrive?: boolean;
  idMotifMef?: string;
  adresseEnlevement?: string;
  communeEnlevement?: string;
  nuit?: boolean;
  pluieOuNeige?: boolean;
  autoriteLeverMesure?: string;
  dateRedaction?: Moment;
}

export interface FicheVehiculeFormValues {
  sansPlaque?: boolean;
  plusieursPlaques?: boolean;
  plusieursNumeroDeSerie?: boolean;
  paysEtranger?: boolean;
  genre?: string;
  numero?: string;
  numeroImmatriculationVisible?: string;
  vin?: string;
  marque?: BaseOptionType<MarqueDto>;
  modele?: string;
  couleur?: string;
  etat?: EtatDtoEtatEnum;
  precisionEtat?: string;
  verrouillage?: VehiculeVerouillage[];
  accessoires?: VehiculeAccessoire[];
  telephonePortable?: boolean;
  nombreAntennes?: number;
  objetsVisibles?: string;
  observations?: string;
}

export interface FicheFourriereFormValues {
  dateEnlevement?: Moment;
  dateEntree?: Moment;
}

export interface FicheDescriptiveFormValues {
  ficheInfraction: FicheInfractionFormValues;
  ficheVehicule: FicheVehiculeFormValues;
  ficheFourriere: FicheFourriereFormValues;
}

export interface SelectReferentielProps<T> extends FieldProps<T> {
  fieldKey: keyof T & string;
  label?: React.ReactNode;
  required?: boolean;
}

export interface FormValueProps<T> {
  values: T;
  setValues: (value: T) => void;
}

export interface FormProgressGoNext {
  goNextForm: () => Promise<void>;
}

export interface FormProgressGoPrevious {
  goPreviousForm: () => Promise<void>;
}

export enum VehiculeVerouillage {
  VEROUILLAGE_PORTES = 'portes',
  VEROUILLAGE_COFFRES = 'coffres',
}

export enum VehiculeAccessoire {
  ACCESSOIRE_AUTO_RADIO = 'autoRadio',
  ACCESSOIRE_POSTE_CB = 'posteCB',
}
