import React from 'react';
import { DownOutlined, UpOutlined, MinusOutlined } from '@ant-design/icons';
import { ColumnProps, TableSort } from '../../types';

import './ColumnTitle.less';

interface ColumnTitleProps<DataType, SortType extends string | undefined> {
  columnProps: ColumnProps<DataType, SortType>;
  sortItems?: {
    sorted: TableSort<SortType>;
    setSort: (newSorted: TableSort<SortType>) => void;
  };
  disabled?: boolean;
}

// Define next sort value when clicking on sort button
function nextSort<SortType extends string>(
  currentField: SortType,
  previousSort: TableSort<SortType>,
): TableSort<SortType> {
  if (previousSort[currentField] === undefined) {
    // Previously not sorting -> sort in ASC order
    previousSort[currentField] = true;
  } else if (previousSort[currentField] === true) {
    // Previously sorting in ASC order -> sort in DESC order
    previousSort[currentField] = false;
  } else {
    // Previously sorting in DESC order -> stop sorting
    previousSort[currentField] = undefined;
  }
  return previousSort;
}

// Pick sort clickable icon based on current sort value
function sortIcon<SortType extends string>(
  currentField: SortType,
  sort: TableSort<SortType>,
  onClick: () => void,
): React.ReactNode {
  // This field is currently sorted
  if (sort && sort[currentField] !== undefined) {
    if (sort[currentField]) {
      return <DownOutlined onClick={onClick} className={'sort-button'} />;
    } else {
      return <UpOutlined onClick={onClick} className={'sort-button'} />;
    }
  }
  // This field is not currently sorted
  return <MinusOutlined onClick={onClick} className={'sort-button'} />;
}

/**
 * Component inserted in the first cell of a Table's column.
 *
 * @param columnsProps - general props to define this component:
 *        - title: text displayed in this component
 *        - dataIndex: key identifying this column and which data attribute it refers to
 *        - sortedField: when defined, this column proposes a sorting button to sort on this field
 *        - buildCellContent: to manually build cell content instead on just displaying field value
 * @param sortItems - getter and setter for sort state. If not given, this component won't
 *        be able to propose a sort button.
 *        /!\ Cannot sort if no dataIndex is provided for this column.
 * @param disable - disable sort buttons
 */
function ColumnTitle<DataType, SortType extends string | undefined>({
  columnProps,
  sortItems,
  disabled,
}: ColumnTitleProps<DataType, SortType>): React.ReactElement {
  // When clicking on sort icon, set new sort order based on current columns index and
  // current sort state.
  function onSortClick(): void {
    !disabled &&
      columnProps.sortedField !== undefined &&
      sortItems !== undefined &&
      sortItems.setSort({
        ...nextSort(columnProps.sortedField, sortItems.sorted),
      });
  }

  return (
    <div className="column-title">
      {columnProps.title}
      {columnProps.sortedField !== undefined &&
        sortItems !== undefined &&
        sortIcon(columnProps.sortedField, sortItems.sorted, onSortClick)}
    </div>
  );
}

export default ColumnTitle;
