import { createContext, useContext, Dispatch, SetStateAction } from 'react';

type TablePaginationWithoutTotal = {
  page: number;
  pageSize: number;
  hasNextPage: boolean;
};

interface PaginationWithoutTotalContextContent<TablePaginationWithoutTotal> {
  pagination: TablePaginationWithoutTotal;
  setPagination: Dispatch<SetStateAction<TablePaginationWithoutTotal>>;
}

const PaginationWithoutTotalContext =
  createContext<PaginationWithoutTotalContextContent<TablePaginationWithoutTotal> | null>(
    null,
  );

export const usePaginationWithoutTotalContext = (): [
  TablePaginationWithoutTotal,
  Dispatch<SetStateAction<TablePaginationWithoutTotal>>,
] => {
  const context = useContext(PaginationWithoutTotalContext);
  if (context === null) {
    throw new Error('usePaginationWithoutTotalContext called outside Context.');
  }

  return [context.pagination, context.setPagination];
};

const PaginationWithoutTotalProvider = PaginationWithoutTotalContext.Provider;

export default PaginationWithoutTotalProvider;
