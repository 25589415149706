import React from 'react';

import {
  AutoriteFourriereViolationDtoFormFieldEnum,
  TypeAutoriteFourriereDto,
} from 'lib_api/lib/api/gen';
import { useApi } from 'hooks/ApiStoreContext';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import AutocompleteDepartement from 'components/BaseForm/Autocomplete/AutocompleteDepartement';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';
import { AutoriteFourriereFormValues } from '../type';
import { FormInputProps } from 'components/BaseForm/types';
import { fetchTypeAutoriteFourriere } from 'search/searchTypeAutoriteFourriere';

interface ModalEditionAutoriteFourriereProps {
  close: () => void;
  visible: boolean;
  initials: AutoriteFourriereFormValues;
  title: string;
  submit: (values: AutoriteFourriereFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: AutoriteFourriereViolationDtoFormFieldEnum,
    values: AutoriteFourriereFormValues,
  ) => Promise<void>;
  additionalInputs?: FormInputProps<
    AutoriteFourriereFormValues,
    AutoriteFourriereViolationDtoFormFieldEnum
  >[];
}

function ModalEditionAutoriteFourriere({
  additionalInputs,
  ...props
}: ModalEditionAutoriteFourriereProps): React.ReactElement {
  const { ReferentielControllerApi: refController } = useApi();
  const otherInputs: Array<
    FormInputProps<
      AutoriteFourriereFormValues,
      AutoriteFourriereViolationDtoFormFieldEnum
    >
  > = additionalInputs ?? [];

  const baseInputs: Array<
    FormInputProps<
      AutoriteFourriereFormValues,
      AutoriteFourriereViolationDtoFormFieldEnum
    >
  > = [
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.TYPE,
      required: true,
      name: 'idCorrelationType',
      dependencies: ['ville', 'adresse', 'codePostal'],
      label: "Type d'autorité de fourrière",
      render: () => {
        const fetchOptions = () => {
          return fetchTypeAutoriteFourriere(refController);
        };
        const getValue = (type: TypeAutoriteFourriereDto) => {
          return type.idCorrelation;
        };
        const getLabel = (type: TypeAutoriteFourriereDto) => {
          return type.type;
        };

        return (
          <AsyncSelect
            fetchOptions={fetchOptions}
            getOptionValue={getValue}
            getOptionLabel={getLabel}
          />
        );
      },
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.LIBELLE,
      name: 'libelle',
      dependencies: ['idCorrelationType'],
      label: 'Libellé',
      required: true,
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.LIBELLE_DEPARTEMENT,
      name: 'libelleDepartement',
      label: "Libellé de département de l'autorité",
      required: true,
      render: props => {
        return (
          <AutocompleteDepartement {...props} codeField="numeroDepartement" />
        );
      },
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.NUMERO_DEPARTEMENT,
      name: 'numeroDepartement',
      label: 'Numéro de département',
      required: true,
    },
    {
      name: 'numeroVoie',
      label: 'Numéro de voie',
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.VOIE,
      name: 'adresse',
      label: 'Libellé de voie',
      required: true,
      render: props => {
        return (
          <AutocompleteAdresse
            {...props}
            communeField="ville"
            codePostalField="codePostal"
          />
        );
      },
    },
    {
      name: 'complement',
      label: "Complément d'adresse",
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.VILLE,
      name: 'ville',
      label: 'Ville',
      dependencies: ['idCorrelationType', 'codePostal'],
      required: true,
      normalize: (value: string) => value.toUpperCase(),
    },
    {
      field: AutoriteFourriereViolationDtoFormFieldEnum.CODE_POSTAL,
      name: 'codePostal',
      label: 'Code postal',
      dependencies: ['idCorrelationType', 'ville'],
      required: true,
    },
    {
      name: 'cedex',
      label: 'Cedex',
    },
    {
      name: 'insee',
      label: 'Code Insee',
    },
  ];

  return <ModalEdition {...props} inputs={[...baseInputs, ...otherInputs]} />;
}

export default ModalEditionAutoriteFourriere;
