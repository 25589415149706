import React, { useCallback, useEffect, useState } from 'react';

import { InformationBanner } from 'components/Banner';
import {
  ComputedResponseDtoListActionsRequisesEnum,
  InformationMessageDto,
} from 'lib_api/lib/api/gen';
import DisplayedInformationMessageProvider, {
  InformationMessages,
} from 'hooks/DisplayMessageContext';
import { useGetInformationMessages } from 'hooks/referentiels/useRefInformationMessage';
import { useRequiredActions } from 'hooks/comptes/useRequiredActions';
import { useUserStore } from 'hooks/UserStoreContext';
import DossierActionsRequisesProvider from 'hooks/DossierActionsRequisesContext';
import { profileEnumMapping } from 'utils/enumData';
import RequiredUserActionProvider from 'hooks/RequiredUserActionContext';
import { RequiredUserActionBanner } from './RequiredUserActionBanner';
import { DossierActionRequiseBanner } from './DossierActionRequiseBanner';

interface DisplayMessagesProps {
  children: React.ReactNode;
}

export const InformationMessagesDisplay = ({
  children,
}: DisplayMessagesProps): React.ReactElement => {
  const user = useUserStore();
  if (user.profile === null) {
    throw new Error('Authenticated user should be mapped to a profile');
  }

  const [messages, setMessages] = useState<InformationMessages>({});
  const [areMessagesShown, setMessagesShown] = useState<boolean>(true);
  const retrievedMessages = useGetInformationMessages();
  const [requiredUserActions, updateRequiredUserActions] = useRequiredActions();
  const [dossierActionsRequises, setDossierActionsRequises] = useState<
    ComputedResponseDtoListActionsRequisesEnum[]
  >([]);
  const { profilPath } = profileEnumMapping[user.profile];

  const addMessage = useCallback((message: InformationMessageDto) => {
    setMessages(oldMessages => {
      return {
        ...oldMessages,
        [message.id]: message,
      };
    });
  }, []);

  const removeMessage = useCallback((id: string) => {
    setMessages(({ [id]: _, ...newMessages }) => newMessages);
  }, []);

  useEffect(() => {
    retrievedMessages?.referenceDtoList?.map(message => addMessage(message));
  }, [retrievedMessages, addMessage]);

  return (
    <DisplayedInformationMessageProvider
      value={{
        messages,
        addMessage,
        removeMessage,
        setMessagesShown,
      }}
    >
      <RequiredUserActionProvider
        value={{ requiredUserActions, updateRequiredUserActions }}
      >
        <DossierActionsRequisesProvider
          value={{ dossierActionsRequises, setDossierActionsRequises }}
        >
          {areMessagesShown && (
            <RequiredUserActionBanner profilPath={profilPath} />
          )}
          {areMessagesShown && <DossierActionRequiseBanner />}
          {areMessagesShown &&
            Object.values(messages).map(({ id, title, content }) => (
              <InformationBanner
                message={title}
                description={content}
                key={id}
                closable={true}
                onClose={() => removeMessage(id)}
                alertType={'info'}
              />
            ))}
          {children}
        </DossierActionsRequisesProvider>
      </RequiredUserActionProvider>
    </DisplayedInformationMessageProvider>
  );
};
