import React, { useState } from 'react';
import { FormInstance as AntdFormInstance } from 'antd/lib/form';
import { CancelEditFormButton, Form } from 'components/WrappedComponents';

import ProfilFormEditButton from './ProfilFormEditButton';
import ProfilFormContainer from './ProfilFormContainer';

interface Props<U> {
  form?: AntdFormInstance;
  title: string;
  idForm: string;
  initialValue: U;
  submit?: (value: U) => Promise<void>;
  onChange?: (value: U, changedValue: Partial<U>) => void;
  children: (value: U, isEdit: boolean) => React.ReactNode;
}

function EditableProfilFormContainer<U>({
  form,
  title,
  idForm,
  initialValue,
  submit,
  onChange,
  children,
}: Props<U>): React.ReactElement {
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const editBtn: React.ReactElement | undefined = isEdit ? undefined : (
    <ProfilFormEditButton setEdit={() => setIsEdit(true)} />
  );

  async function onSubmit(values: U) {
    submit && (await submit(values));
    setIsEdit(false);
  }

  return (
    <ProfilFormContainer title={title} button={editBtn}>
      <Form
        form={form}
        id={idForm}
        onSubmit={onSubmit}
        initialValue={initialValue}
        onChange={onChange}
        validateButtonTitle={'Enregistrer'}
        validateButtonClassName={'right'}
      >
        {(values, SubmitButton): React.ReactElement => (
          <div className="profil-form">
            {children(values, isEdit)}
            {isEdit && (
              <div className={'profile-form-buttons'}>
                {SubmitButton}
                <CancelEditFormButton
                  buttonTitle={'Annuler'}
                  initialValues={initialValue}
                  close={() => setIsEdit(false)}
                  className={'cancelButton'}
                />
              </div>
            )}
          </div>
        )}
      </Form>
    </ProfilFormContainer>
  );
}

export default EditableProfilFormContainer;
