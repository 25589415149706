import React from 'react';
import moment from 'moment';
import { Result } from 'antd';

import { FluxDto } from 'lib_api/lib/api/gen';
import { useReferentielDashboardSearchWithSort } from '../../Referentiels/Dashboards/search/search';
import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import ErrorCell from './Details/ErrorCell/ErrorCell';
import { FluxStatusType, FluxVenteDossiersSortOptions } from './types';
import { useSearchFluxsDomaine } from './utils';
import { DATE_FORMAT } from 'utils/formats';
import './DashboardVenteDossiers.less';

function DashboardVenteDossiers(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
  } = useReferentielDashboardSearchWithSort(
    useSearchFluxsDomaine,
    {},
    {
      DATE_APPEL: false,
    },
  );

  const statusIconMapping: FluxStatusType = {
    SUCCESS: <Result className="FluxStatus" status="success" />,
    ERROR: <Result className="FluxStatus" status="error" />,
    WARNING: <Result className="FluxStatus" status="warning" />,
  };

  function buildDetailsContent(data: FluxDto): React.ReactElement {
    return <ErrorCell flux={data} />;
  }

  function computeBadge(flux: FluxDto): React.ReactNode {
    if (flux.error) {
      return statusIconMapping.ERROR;
    } else if (flux.nbWarnings && flux.nbWarnings > 0) {
      return statusIconMapping.WARNING;
    } else {
      return statusIconMapping.SUCCESS;
    }
  }

  function buildColumns(): ColumnProps<
    FluxDto,
    FluxVenteDossiersSortOptions
  >[] {
    return [
      {
        title: '',
        buildCellContent: data => computeBadge(data),
      },
      {
        title: 'Appel',
        buildCellContent: data => moment(data.dateAppel).format(DATE_FORMAT),
        sortedField: FluxVenteDossiersSortOptions.DATE_APPEL,
      },
      {
        title: 'Début plage',
        buildCellContent: data => moment(data.dateDebut).format(DATE_FORMAT),
        sortedField: FluxVenteDossiersSortOptions.DATE_DEBUT,
      },
      {
        title: 'Fin plage',
        buildCellContent: data => moment(data.dateFin).format(DATE_FORMAT),
        sortedField: FluxVenteDossiersSortOptions.DATE_FIN,
      },
      {
        title: "Type d'erreur",
        buildCellContent: data => buildDetailsContent(data),
      },
    ];
  }

  const columns: ColumnProps<FluxDto, FluxVenteDossiersSortOptions>[] =
    React.useMemo(() => buildColumns(), []);

  return (
    <>
      <div className="dashboardVenteDossiers">
        <h1>Hermès : Flux de récupération des statuts de vente des dossiers</h1>
        <div className="tableContainer">
          <Table
            columns={columns}
            fetchData={fetchData}
            buildItemKey={(item): string => item.id}
            pageSizeOptions={[10, 20, 50]}
            pagination={pagination}
            setPagination={setPagination}
            setFilter={setFilter}
            setSort={setSort}
            sort={sort}
            filter={filter}
          />
        </div>
      </div>
    </>
  );
}

export default DashboardVenteDossiers;
