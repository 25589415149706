import React from 'react';
import { TimeRangePickerProps as AntdTimeRangePickerProps } from 'antd/lib/time-picker';
import { TimePicker as AntdTimePicker } from 'antd';
import { isMoment, Moment } from 'moment';

import FieldWrapperUntyped from '../FieldWrapperUntyped';
import { WrapperUntypedProps } from '../types';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { TIME_FORMAT } from 'utils/formats';

const { RangePicker: AntRangePicker } = AntdTimePicker;

interface Props {
  completeFieldKey: string | number | (string | number)[];
  wrapperProps: WrapperUntypedProps;
  timePickerProps: AntdTimeRangePickerProps;
}

function RangeTimePicker({
  completeFieldKey,
  wrapperProps,
  timePickerProps,
}: Props): React.ReactElement {
  const formActions = useFormActionsContext();
  const array = formActions.getFieldValue(completeFieldKey);

  let value;
  if (Array.isArray(array) && array.length === 2) {
    //start and end date are Moment if array index is Moment. undefined else
    const startDate: Moment | undefined = isMoment(array[0])
      ? array[0]
      : undefined;
    const endDate: Moment | undefined = isMoment(array[1])
      ? array[1]
      : undefined;

    //Value concat format parse of the 2 moments.
    value =
      startDate === undefined ? 'Non renseigné' : startDate.format(TIME_FORMAT);
    value +=
      endDate === undefined
        ? ' -> Non renseigné'
        : ' -> ' + endDate.format(TIME_FORMAT);
  }

  wrapperProps.itemProps.validateTrigger = 'onChange';

  const rangePicker = React.useMemo(() => {
    return <AntRangePicker {...timePickerProps} />;
  }, [timePickerProps]);

  return (
    <FieldWrapperUntyped
      {...wrapperProps}
      WrappedInput={rangePicker}
      ConsultationElement={value}
    />
  );
}

export default RangeTimePicker;
