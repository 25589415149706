import { createContext, useCallback, useContext } from 'react';
import { DossierResponseDto } from 'lib_api/lib/api/gen';

interface DossierContextContent {
  dossier: DossierResponseDto;
  setDossier: (dossier: DossierResponseDto) => void;
}

const DossierContext = createContext<DossierContextContent | null>(null);

export const useDossierContext = (): [
  DossierResponseDto,
  (dossier: DossierResponseDto) => void,
] => {
  const context = useContext(DossierContext);
  if (context === null) {
    throw new Error('useDossierContext called outside Context.');
  }

  const setter = context.setDossier;
  const setDossierNonNull = useCallback(
    (dossier: DossierResponseDto) => {
      setter(dossier);
    },
    [setter],
  );

  return [context.dossier, setDossierNonNull];
};

export const DossierProvider = DossierContext.Provider;
