import React from 'react';

import { ReferentielSearchResultDtoAutoriteFourriereDto } from 'lib_api/lib/api/gen/api';

import useAsyncSearch from 'hooks/search/async/useAsyncSearch';
import useSearchAutoriteFourriere from 'hooks/search/useSearchAutoriteFourriere';
import { SearchResult } from 'types/searchResult';

function useAsyncSearchAutoritefourriere(idCorrelation?: string): SearchResult {
  const mapResult = (
    result: ReferentielSearchResultDtoAutoriteFourriereDto,
  ) => {
    return result.results.referenceDtoList.map(autoriteFourriere => {
      return {
        value: autoriteFourriere.idCorrelation,
        displayValue: autoriteFourriere.libelleComplet,
      };
    });
  };
  const updateFilters = (query?: string) => {
    return { libelle: query };
  };
  const defaultFilter = { idCorrelation };

  return useAsyncSearch(
    useSearchAutoriteFourriere,
    mapResult,
    updateFilters,
    defaultFilter,
  );
}

export default useAsyncSearchAutoritefourriere;
