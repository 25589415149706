import { useCallback, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  DossierResponseDto,
  FixDataNotificationManuelleRequestDtoEtatDistributionEnum,
  ForceOrdreControllerApi,
  NotificationInfosRequestDto,
  NotificationInfosRequestDtoEtatDistributionEnum,
  NotificationInfosViolationDto,
  NotificationInfosViolationDtoFormFieldEnum,
  NotificationManuelleInfosDtoEtatDistributionEnum,
} from 'lib_api/lib/api/gen';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';
import {
  useValidationRequest,
  ValidationCallback,
} from 'hooks/utils/handleValidationRequest';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';

/** TYPE DECLARATION */
type NotificationInfosValidationCallback =
  ValidationCallback<ForceOrdreControllerApi>;

export interface NotificationInfosFieldsProps {
  values: NotificationInfos;
  dossierId: string;
  editable?: boolean;
}

export interface NotificationInfos {
  dateRetour?: Moment;
  dateEnvoi?: Moment;
  etatDistribution?: NotificationInfosRequestDtoEtatDistributionEnum;
}

export interface NotificationInfosEnumData {
  libelle: string;
  libelleDateRetour: string;
  fixDataEnum: FixDataNotificationManuelleRequestDtoEtatDistributionEnum;
}

type NotificationManuelleInfosEnumMapping = {
  [key in NotificationManuelleInfosDtoEtatDistributionEnum]: NotificationInfosEnumData;
};

export const DATE_DE_RETOUR = 'Date de retour';
export const DATE_DE_SIGNATURE = 'Date de signature';
export const DATE_DE_REFUS = 'Date de refus';

export const notificationManuelleInfosEnumMapping: NotificationManuelleInfosEnumMapping =
  {
    ABSENCE_AVEC_RETRAIT: {
      libelle: 'Absence du destinataire de la LRAR avec retrait',
      libelleDateRetour: DATE_DE_SIGNATURE,
      fixDataEnum:
        FixDataNotificationManuelleRequestDtoEtatDistributionEnum.ABSENCE_AVEC_RETRAIT,
    },
    ABSENCE_SANS_RETRAIT: {
      libelle: 'PND : Pli avisé et non réclamé',
      libelleDateRetour: DATE_DE_RETOUR,
      fixDataEnum:
        FixDataNotificationManuelleRequestDtoEtatDistributionEnum.ABSENCE_SANS_RETRAIT,
    },
    DESTINATAIRE_INCONNU: {
      libelle: 'Destinataire inconnu',
      libelleDateRetour: DATE_DE_RETOUR,
      fixDataEnum:
        FixDataNotificationManuelleRequestDtoEtatDistributionEnum.DESTINATAIRE_INCONNU,
    },
    RECEPTION_LRAR: {
      libelle: 'Accusé de réception signé',
      libelleDateRetour: DATE_DE_SIGNATURE,
      fixDataEnum:
        FixDataNotificationManuelleRequestDtoEtatDistributionEnum.RECEPTION_LRAR,
    },
    REFUS_PLI: {
      libelle: 'PND : Pli refusé par le destinataire',
      libelleDateRetour: DATE_DE_REFUS,
      fixDataEnum:
        FixDataNotificationManuelleRequestDtoEtatDistributionEnum.REFUS_PLI,
    },
  };

/** MAPPING DECLARATION */
export const generateNotificationInfosRequestDto = (
  values: NotificationInfos,
): NotificationInfosRequestDto => {
  return {
    dateRetour: values.dateRetour?.format(UNIX_TIMESTAMP_FORMAT) || null,
    dateEnvoi: values.dateEnvoi?.format(UNIX_TIMESTAMP_FORMAT) || null,
    etatDistribution: values.etatDistribution || null,
  };
};

export const generateNotificationInfosFromDossier = (
  values: DossierResponseDto,
): NotificationInfos => {
  return {
    dateRetour: undefined,
    dateEnvoi: values.body?.traitement?.notificationManuelle?.dateEnvoi
      ? moment(values.body?.traitement?.notificationManuelle?.dateEnvoi)
      : undefined,
    etatDistribution: values.body?.traitement?.notificationManuelle
      ?.etatDistribution
      ? NotificationInfosRequestDtoEtatDistributionEnum[
          values.body.traitement.notificationManuelle.etatDistribution
        ]
      : undefined,
  };
};

/** FUNCTIONS DECLARATION */

// Validate field rules
export async function validateNotificationInfosRule(
  dossier: string,
  controller: ForceOrdreControllerApi,
  values: NotificationInfosRequestDto,
  field: NotificationInfosViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateNotificationInfosFieldUsingPOST(
      field,
      dossier,
      values,
    );
    if (response.notificationInfosViolationDtos) {
      const fieldViolations = response.notificationInfosViolationDtos.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

/** HOOKS DECLARATION */

// Hook used to submit notification informations
export function useSubmitNotificationInfos(): [
  DossierResponseDto | null,
  (
    notificationInfos: NotificationInfos,
    dossierId: string,
  ) => Promise<DossierResponseDto>,
  (dossier: DossierResponseDto) => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const foController = useApi().ForceOrdreControllerApi;
  const [updatedDossier, setUpdatedDossier] =
    useState<DossierResponseDto | null>(null);

  const submitDossier = useCallback(
    (
      prolongationValues: NotificationInfos,
      dossierId: string,
    ): Promise<DossierResponseDto> => {
      const prolongationRequest =
        generateNotificationInfosRequestDto(prolongationValues);

      return foController.submitNotificationInfosUsingPOST(
        dossierId,
        prolongationRequest,
      );
    },
    [foController],
  );

  const onSuccess = useCallback(
    (dossier: DossierResponseDto): void => {
      setUpdatedDossier(dossier);
    },
    [setUpdatedDossier],
  );

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [updatedDossier, submitDossier, onSuccess, onError];
}

// Hook used to validate all fields submitted by notification info form
export function useValidateNotificationInfos(
  onSuccess: () => Promise<DossierResponseDto>,
  thenSubmit: (dossier: DossierResponseDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: NotificationInfosValidationCallback) => Promise<void>,
  NotificationInfosViolationDto[] | null,
] {
  const foController = useApi().ForceOrdreControllerApi;
  return useValidationRequest(
    foController,
    'notificationInfosViolationDtos',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}
