import React, { ReactNode } from 'react';
import { Alert as AntdAlert } from 'antd';

import './index.less';

interface InformationBannerProps {
  message?: string;
  description?: ReactNode;
  closable?: boolean;
  onClose?: (e: React.MouseEvent) => void;
  alertType?: 'success' | 'info' | 'warning' | 'error';
}

function InformationBanner({
  message,
  description,
  closable,
  onClose,
  alertType,
}: InformationBannerProps): React.ReactElement {
  return (
    <AntdAlert
      message={message}
      description={description}
      type={alertType}
      showIcon
      closable={closable ?? false}
      onClose={onClose}
    />
  );
}

export default InformationBanner;
