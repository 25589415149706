import React from 'react';

import CardLayout from 'components/CardLayout/CardLayout';
import { SupportedFile } from 'types/enums/SupportedFile';

import ReferentielImportComponent from './ReferentielImportComponent';

interface ReferentielImportLayoutProps<ReferentielType> {
  title: string;
  referentiels: ReferentielType[];
  getName: (referentiel: ReferentielType) => string;
  fileType: SupportedFile;
  importReferentiel: (
    file: Blob,
    referentiel: ReferentielType,
  ) => Promise<Response>;
}

export default function ReferentielImportLayout<ReferentielType>({
  title,
  referentiels,
  getName,
  importReferentiel,
  fileType,
}: ReferentielImportLayoutProps<ReferentielType>): React.ReactElement {
  return (
    <div className="ReferentielImportComponent">
      <h2 className="title">{title}</h2>
      <CardLayout>
        {referentiels.map((entry, index) => {
          return (
            <ReferentielImportComponent
              key={index}
              referentiel={entry}
              referentielName={getName(entry)}
              importReferentiel={importReferentiel}
              index={index}
              fileType={fileType}
            />
          );
        })}
      </CardLayout>
    </div>
  );
}
