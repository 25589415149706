import { useCallback } from 'react';

import { EtatDto, EtatDtoEtatEnum, ModeleDto } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { fetchEtatDetails } from 'search/searchEtatDetails';

export const useFetchDetailsByEtat = (): [
  (etat: EtatDtoEtatEnum | null) => Promise<Array<EtatDto>>,
] => {
  const { ReferentielControllerApi: refController } = useApi();

  const fetch = useCallback((etat: EtatDtoEtatEnum | null) => {
    if (!etat) {
      return Promise.resolve([]);
    }

    return fetchEtatDetails(refController, etat);
  }, []);

  return [fetch];
};
