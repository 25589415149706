import React from 'react';
import { Select } from 'components/WrappedComponents';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';
import { useRefBrancheNature } from 'hooks/referentiels/useRefBrancheNature';
import { ReferenceDtoBrancheDto } from 'lib_api/lib/api/gen';

interface SelectNatureMiseEnFourriereProps {
  values: Partial<DashboardAutoriteFourriereFilterForm>;
}

function selectItemsFromRef(refs: ReferenceDtoBrancheDto) {
  return refs.referenceDtoList
    .sort((a, b) => a.nature.localeCompare(b.nature))
    .map(branche => {
      return {
        value: branche.id,
        displayValue: branche.nature,
      };
    });
}

function SelectNatureMiseEnFourriere({
  values,
}: SelectNatureMiseEnFourriereProps): React.ReactElement {
  const nature = useRefBrancheNature();
  const selectItems = React.useMemo(
    () => (nature !== undefined ? selectItemsFromRef(nature) : []),
    [nature],
  );
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'natureMiseEnFourriere',
        itemProps: {
          label: 'Nature de mise en fourrière',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.XLargeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectNatureMiseEnFourriere;
