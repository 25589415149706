import React from 'react';

import { DashboardDSRFilter } from '../types';
import { TableSort } from 'components/WrappedComponents/Table/types';
import Filters from './Filters';
import DownloadButton from './DownloadButton';

import './DashboardHeader.less';

interface DashboardHeaderProps<SortType extends string | undefined> {
  totalResult: number;
  filter: Partial<DashboardDSRFilter>;
  setFilter: (value: Partial<DashboardDSRFilter>) => void;
  sort: TableSort<SortType>;
}

function DashboardHeader<SortType extends string | undefined>({
  totalResult,
  filter,
  setFilter,
  sort,
}: DashboardHeaderProps<SortType>): React.ReactElement {
  const countString = `${totalResult} ${
    totalResult > 1 ? 'véhicules trouvés' : 'véhicule trouvé'
  }`;

  return (
    <div>
      <div className="dashboard-header-fields">
        <Filters filter={filter} setFilter={setFilter} />
        <div className="dashboard-header-actions">
          <DownloadButton filters={filter} sort={sort} />
        </div>
      </div>
      <p className="dashboard-number-vehicules">{countString}</p>
    </div>
  );
}

export default DashboardHeader;
