import React from 'react';

import { Select } from 'components/WrappedComponents';
import { ProfilAutoriteFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useRefTypesAutoriteFourriere } from 'hooks/referentiels/useTypeAutoriteFourriere';
import { CSSUtils } from 'utils/CSSUtils';

import { FieldProps, FicheProfilAutoriteFourriere } from '../types';
import { validateProfilAutoriteFourriereField } from '../utils';

function SelectTypeAutoriteFourriere({
  values,
  isEditable,
}: FieldProps): React.ReactElement {
  const user = useUserStore();
  const { AutoriteFourriereControllerApi } = useApi();

  const types = useRefTypesAutoriteFourriere();
  const key: keyof FicheProfilAutoriteFourriere = 'idTypeAutoriteFourriere';
  const label = "Type d'autorité de fourrière";

  const selectItems =
    types !== undefined
      ? types.referenceDtoList
          .sort((a, b) => a.type.localeCompare(b.type))
          .map(type => ({
            value: type.idCorrelation,
            displayValue: type.type,
          }))
      : [];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateProfilAutoriteFourriereField(
                  AutoriteFourriereControllerApi,
                  ProfilAutoriteFourriereViolationDtoFormFieldEnum.TYPE,
                  user.idCorrelationAutoriteFourriere || '',
                  { ...values, [key]: value },
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectTypeAutoriteFourriere;
