import { Typography as AntdTypography } from 'antd';
import DragSortableTable from 'components/WrappedComponents/Table/DragSortableTable/DragSortableTable';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { FaqAdminDto } from 'lib_api/lib/api/gen';
import React, { useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import CellDetailsAndActions from './CellDetailsAndActions';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { mapUserProfiles, useSearchFaq } from './utils';
import { faqDomainPath } from 'pages/admin-fonctionnel/adminNavbarContent';
import { useReferentielDashboardWithoutArgument } from '../utils';

function buildDetailsContent(
  data: FaqAdminDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions faq={data} refreshDashboard={refreshDashboard} />
  );
}

function DashboardFaq(): React.ReactElement {
  const controller = useApi().AdminFonctionnelControllerApi;
  const [searchParams] = useSearchParams();
  const idDomain = searchParams.get('idDomaine');
  const nameDomain = searchParams.get('nameDomaine');

  const { fetchData, refreshDashboard, setFilter, filter } =
    useReferentielDashboardWithoutArgument(
      useSearchFaq,
      {
        valid: true,
        domainId: idDomain || '',
      },
      { position: true },
    );

  function buildColumns(): ColumnProps<FaqAdminDto>[] {
    return [
      {
        title: 'Question',
        buildCellContent: data => (
          <AntdTypography.Text ellipsis={true} style={{ width: 400 }}>
            {data.title}
          </AntdTypography.Text>
        ),
      },
      {
        title: 'Rôles concernés',
        buildCellContent: data => mapUserProfiles(data.profiles),
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  function changePosition(ordered: FaqAdminDto[]) {
    if (idDomain) {
      controller
        .changeFaqPositionUsingPOST(
          idDomain,
          ordered.map(faq => faq.id),
        )
        .then(refreshDashboard)
        .catch(e => backAlertMessage(e));
    }
  }

  if (idDomain === null) {
    return <Navigate replace to={faqDomainPath} />;
  }

  return (
    <div className="table-container">
      <DashboardHeader
        valid={filter.valid}
        setValid={valid => setFilter({ ...filter, valid })}
        nameDomain={nameDomain}
        idDomain={idDomain}
        refreshDashboard={refreshDashboard}
      />
      <DragSortableTable
        columns={buildColumns()}
        fetchData={fetchData}
        buildItemKey={item => item.id}
        antdTableProps={{
          tableLayout: 'fixed',
        }}
        onDragEnd={changePosition}
        disabled={!filter.valid}
      />
    </div>
  );
}
export default DashboardFaq;
