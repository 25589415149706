import React from 'react';
import { CentreVHUViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import ModalEdition from '../../Modals/ModalEdition/ModalEdition';
import { CentreVHUFormValues } from '../types';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';

interface ModalEditionCentreVHUProps {
  close: () => void;
  visible: boolean;
  initials: CentreVHUFormValues;
  title: string;
  submit: (values: CentreVHUFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: CentreVHUViolationDtoFormFieldEnum,
    values: CentreVHUFormValues,
  ) => Promise<void>;
}

function ModalEditionCentreVHU(
  props: ModalEditionCentreVHUProps,
): React.ReactElement {
  return (
    <ModalEdition
      {...props}
      inputs={[
        {
          label: 'Raison sociale',
          field: CentreVHUViolationDtoFormFieldEnum.RAISON_SOCIALE,
          name: 'raisonSociale',
          normalize: (value: string) => value.toUpperCase(),
          required: true,
        },
        {
          label: 'SIREN',
          field: CentreVHUViolationDtoFormFieldEnum.SIREN,
          required: true,
          name: 'siren',
        },
        {
          label: 'Adresse',
          field: CentreVHUViolationDtoFormFieldEnum.ADRESSE,
          required: false,
          name: 'adresse',
          dependencies: ['codePostal', 'commune'],
          render: ({ name }) => {
            return (
              <AutocompleteAdresse
                name={name}
                communeField="commune"
                codePostalField="codePostal"
              />
            );
          },
        },
        {
          label: "Complément d'adresse",
          field: CentreVHUViolationDtoFormFieldEnum.COMPLEMENT_ADRESSE,
          required: false,
          name: 'complementAdresse',
        },
        {
          label: 'Code postal',
          field: CentreVHUViolationDtoFormFieldEnum.CODE_POSTAL,
          required: true,
          name: 'codePostal',
          dependencies: ['adresse', 'commune'],
        },
        {
          label: 'Commune',
          field: CentreVHUViolationDtoFormFieldEnum.COMMUNE,
          required: false,
          name: 'commune',
          normalize: (value: string) => value.toUpperCase(),
          dependencies: ['adresse', 'codePostal'],
        },
        {
          label: 'Téléphone',
          field: CentreVHUViolationDtoFormFieldEnum.TELEPHONE,
          required: false,
          name: 'telephone',
        },
        {
          label: 'Adresse email',
          field: CentreVHUViolationDtoFormFieldEnum.EMAIL,
          required: false,
          name: 'email',
        },
        {
          label: "Numéro d'agrément",
          field: CentreVHUViolationDtoFormFieldEnum.NUMERO_AGREMENT_VHU,
          required: true,
          name: 'numeroAgrement',
        },
        {
          label: "Préfecture ayant délivré le numéro d'agrément",
          field: CentreVHUViolationDtoFormFieldEnum.PREFECTURE_DELIVRANTE,
          required: false,
          name: 'prefecture',
        },
      ]}
    />
  );
}

export default ModalEditionCentreVHU;
