import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { DashboardAutoriteFourriereFilter } from 'pages/autorite-fourriere/Dashboard/types';
import { ActionsRequisesDtoActionsRequisesEnum } from 'lib_api/lib/api/gen';

interface AFFilterContextContent {
  filter: Partial<DashboardAutoriteFourriereFilter>;
  setFilter: Dispatch<
    SetStateAction<Partial<DashboardAutoriteFourriereFilter>>
  >;
  // all actions requises configured for af users
  actionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined;
}

const AFFilterContext = createContext<AFFilterContextContent | null>(null);

export const useAFFilterContext = (): [
  Partial<DashboardAutoriteFourriereFilter>,
  Dispatch<SetStateAction<Partial<DashboardAutoriteFourriereFilter>>>,
  ActionsRequisesDtoActionsRequisesEnum[] | undefined,
] => {
  const context = useContext(AFFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter, context.actionsRequises];
};

const AFFilterProvider = AFFilterContext.Provider;

export default AFFilterProvider;
