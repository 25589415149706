import React from 'react';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { BooleanFieldDisplay } from 'components/FieldDisplay/BooleanFieldFisplay';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { FicheDisplay } from 'components/FicheDisplay/FicheDisplay';
import {
  LABEL_MODELE_NON_REFERENCE,
  LABEL_MARQUE_NON_REFERENCEE,
  LABEL_SANS_PLAQUE,
  LABEL_IMMAT_MANQUANTE,
} from 'utils/global';
import {
  etatDtoToString,
  verrouillageDtoToString,
  accessoiresDtoToString,
} from 'utils/dtoToString';

export const FicheVehicule = (): React.ReactElement => {
  const [dossier] = useDossierContext();

  const leftSideChildren = [
    <BooleanFieldDisplay
      key={1}
      label={'Véhicule sans plaque'}
      value={dossier.body?.ficheVehicule?.immatriculation?.sansPlaque}
    />,
    <BooleanFieldDisplay
      key={2}
      label={'Pays étranger'}
      value={dossier.body?.ficheVehicule?.immatriculation?.paysEtranger}
    />,
    <FieldDisplay
      key={3}
      label={'Genre'}
      value={
        dossier.body?.ficheVehicule?.description?.genreSimplifie?.libelle ||
        null
      }
    />,
    ...(dossier.body?.ficheVehicule?.immatriculation
      ?.numeroImmatriculationVisible
      ? [
          <FieldDisplay
            key={4}
            label={
              'Faux numéro d’identification ou d’immatriculation (visible)'
            }
            value={
              dossier.body?.ficheVehicule?.immatriculation
                ?.numeroImmatriculationVisible
            }
          />,
        ]
      : []),
    <FieldDisplay
      key={5}
      label={"Numéro d'identification ou d'immatriculation"}
      value={
        dossier.body?.ficheVehicule?.immatriculation?.sansPlaque &&
        !dossier.body?.ficheVehicule?.immatriculation?.numero
          ? LABEL_SANS_PLAQUE
          : dossier.body?.ficheVehicule?.immatriculation?.numero ||
            LABEL_IMMAT_MANQUANTE
      }
    />,
    <FieldDisplay
      key={7}
      label={'Numéro VIN'}
      value={dossier.body?.ficheVehicule?.immatriculation?.vin || null}
    />,
    <FieldDisplay
      key={8}
      label={'Marque'}
      value={
        dossier.body?.ficheVehicule?.description?.marqueNonReferencee === true
          ? LABEL_MARQUE_NON_REFERENCEE
          : dossier.body?.ficheVehicule?.description?.marque?.libelle || null
      }
    />,
    <FieldDisplay
      key={9}
      label={'Modèle'}
      value={
        dossier.body?.ficheVehicule?.description?.modeleNonReference === true
          ? LABEL_MODELE_NON_REFERENCE
          : dossier.body?.ficheVehicule?.description?.modele?.libelle || null
      }
    />,
    <FieldDisplay
      key={10}
      label={'Couleur'}
      value={dossier.body?.ficheVehicule?.description?.couleur || null}
    />,
  ];
  const rightSideChildren = [
    <FieldDisplay
      key={1}
      label={'État du véhicule'}
      value={
        dossier.body?.ficheVehicule?.etat
          ? etatDtoToString(dossier.body?.ficheVehicule?.etat)
          : null
      }
    />,
    <FieldDisplay
      key={2}
      label={'Verrouillage'}
      value={
        dossier.body?.ficheVehicule?.verrouillage
          ? verrouillageDtoToString(dossier.body?.ficheVehicule?.verrouillage)
          : null
      }
    />,
    <FieldDisplay
      key={3}
      label={'Accessoires visibles'}
      value={
        dossier.body?.ficheVehicule?.accessoires
          ? accessoiresDtoToString(dossier.body?.ficheVehicule?.accessoires)
          : null
      }
    />,
    <BooleanFieldDisplay
      key={4}
      label={'Téléphone portable'}
      value={dossier.body?.ficheVehicule?.accessoires?.telephonePortable}
    />,
    <FieldDisplay
      key={5}
      label={"Nombre d'antennes"}
      value={dossier.body?.ficheVehicule?.accessoires?.nombreAntennes || null}
    />,
    <FieldDisplay
      key={6}
      label={'Si objets visibles, lesquels'}
      value={dossier.body?.ficheVehicule?.accessoires?.objetsVisibles || null}
    />,
    <FieldDisplay
      key={7}
      label={'Observations'}
      value={dossier.body?.ficheVehicule?.accessoires?.observations || null}
      additionalValueClassName={'multiLineValue'}
    />,
  ];

  return (
    <FicheDisplay
      leftSideChildren={leftSideChildren}
      rightSideChildren={rightSideChildren}
    />
  );
};
