import React, { useEffect } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { marked } from 'marked';

import ResponsiveContainer from '../ResponsiveContainer';
import { useDisplayInformationMessageContext } from 'hooks/DisplayMessageContext';
import { useRefFAQDomains } from 'hooks/referentiels/useRefFAQDomain';

import './FAQ.less';

import { FaqDto } from 'lib_api/lib/api/gen';

const { Panel } = AntdCollapse;

const FAQ: React.FC = (): React.ReactElement => {
  const faqsWithDomain = useRefFAQDomains();
  const { setMessagesShown } = useDisplayInformationMessageContext();

  // do not display the information messages
  useEffect(() => {
    setMessagesShown(false);
    return () => setMessagesShown(true);
  }, [setMessagesShown]);

  return (
    <ResponsiveContainer className="FAQ-container">
      <div className="FAQ">
        <h1 className="FAQ-title">Foire Aux Questions</h1>
        {(faqsWithDomain?.referenceDtoList?.length ?? -1) > 0 && (
          <AntdCollapse>
            {faqsWithDomain?.referenceDtoList.map(
              ({ name, faqs }, domainIndex) => (
                <Panel header={name} key={domainIndex}>
                  {<FAQs faqs={faqs} />}
                </Panel>
              ),
            )}
          </AntdCollapse>
        )}
      </div>
    </ResponsiveContainer>
  );
};

interface FAQsProps {
  faqs?: FaqDto[];
}

export const FAQs = ({ faqs }: FAQsProps): React.ReactElement => {
  return (
    <>
      {faqs?.map(({ title, content }, faqIndex) => (
        <div className="question" key={faqIndex}>
          <div className="question-title">{title}</div>
          <div
            className="question-answer"
            dangerouslySetInnerHTML={{ __html: marked.parse(content) }}
          />
        </div>
      ))}
    </>
  );
};

export default FAQ;
