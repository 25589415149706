import React, { useCallback } from 'react';
import { Spin } from 'antd';

import {
  SortieVehiculeViolationDtoFormFieldEnum,
  MotifSortieDto,
} from 'lib_api/lib/api/gen/api';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import useInitialValues from './useInitialValues';
import BaseForm from 'components/BaseForm/BaseForm';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import useSubmitUpdateSortie from './buildSubmitFunction';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import { fetchMotifSortie } from 'search/searchMotifSortie';

import './UpdateSortie.less';

interface UpdateSortieProps {
  closeModal: () => void;
}

function UpdateSortie({ closeModal }: UpdateSortieProps): React.ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const { dossier: dossierAdmin } = useDossierCompletAdmin(dossierId);
  const {
    AdminFonctionnelControllerApi: adminController,
    ReferentielControllerApi: refController,
  } = useApi();
  const initialValues = useInitialValues(dossierAdmin);

  const { submit, inProgress } = useSubmitUpdateSortie(
    adminController,
    dossierId,
    setDossier,
    fetchEvents,
    closeModal,
  );

  const submitFunction = useCallback(submit, [submit]);

  if (!dossierAdmin) {
    return <Spin />;
  }

  return (
    <>
      <h3>Mettre à jour les données de la sortie</h3>
      <BaseForm
        initialValues={initialValues}
        onSubmit={submitFunction}
        onCancel={closeModal}
        isSubmitting={inProgress}
        inputs={[
          {
            field: SortieVehiculeViolationDtoFormFieldEnum.DATE_SORTIE,
            required: true,
            name: 'dateSortie',
            label: 'Date de sortie',
            dependencies: ['idCorrelationMotifSortie'],
            render: () => {
              return <BaseDatePicker />;
            },
          },
          {
            field: SortieVehiculeViolationDtoFormFieldEnum.MOTIF_SORTIE,
            required: true,
            name: 'idCorrelationMotifSortie',
            label: 'Motif de sortie',
            dependencies: ['dateSortie'],
            render: () => {
              const fetchOptions = () => {
                return fetchMotifSortie(refController);
              };
              const getValue = (motif: MotifSortieDto) => {
                return motif.idCorrelation;
              };
              const getLabel = (motif: MotifSortieDto) => {
                return motif.motif;
              };

              return (
                <AsyncSelect
                  fetchOptions={fetchOptions}
                  getOptionValue={getValue}
                  getOptionLabel={getLabel}
                />
              );
            },
          },
          {
            field: SortieVehiculeViolationDtoFormFieldEnum.COMMENT,
            required: true,
            name: 'comment',
            label: 'Justification',
            render: () => {
              return <BaseTextArea />;
            },
          },
        ]}
        validateField={async (field, formValues) => {
          const response =
            await adminController.validateUpdateSortieVehiculeFieldUsingPOST(
              field,
              dossierId,
              {
                motifId: formValues?.idCorrelationMotifSortie ?? null,
                dateSortie:
                  formValues?.dateSortie?.format(UNIX_TIMESTAMP_FORMAT) ?? null,
                comment: formValues.comment,
              },
            );

          const violations = response.sortieVehiculeViolationDtoList ?? [];
          if (violations.length === 0) {
            return Promise.resolve();
          }

          return Promise.reject(violations[0].message);
        }}
        renderButtonAction={(Submit, Cancel) => {
          return (
            <div className="UpdateSortieActions">
              <div className="UpdateAction">{Cancel}</div>
              <div className="UpdateAction">{Submit}</div>
            </div>
          );
        }}
      />
    </>
  );
}

export default UpdateSortie;
