import React from 'react';
import { Button as AntdButton } from 'antd';

import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import { FilterDashboardMotifMef } from '../../../types';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: FilterDashboardMotifMef) => void;
}

export function FormFooter({ setFilter }: FormActionProps): React.ReactElement {
  const context = useBaseFormContext<FilterDashboardMotifMef>();

  function resetFilter() {
    context.setFieldsValue({
      idCorrelation: undefined,
      libelle: undefined,
      natureLibelleCourt: undefined,
      isDicem: undefined,
      isRodeo: undefined,
      enabled: true,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>
      <AntdButton
        type="primary"
        onClick={() => setFilter(context.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormFooter;
