import React from 'react';
import moment, { Moment } from 'moment';

import { SummaryEventDto } from 'lib_api/lib/api/gen';

import Timeline from 'components/WrappedComponents/Timeline';
import { DATE_FORMAT } from 'utils/formats';
import { TimelineItemProps } from 'components/WrappedComponents/Timeline/types';

import './SuiviDossierTimeline.less';

export interface SuiviDossierTimelineProps {
  items: SummaryEventDto[];
}

function suiviDossierTimelineLabel(date: Moment): React.ReactElement {
  return (
    <p className="suivi-dossier-timeline-label">
      {date ? date.format(DATE_FORMAT) : ''}
    </p>
  );
}

function suiviDossierTimelineValue(item: SummaryEventDto): React.ReactElement {
  return (
    <p className="suivi-dossier-timeline-value">
      <span>
        {item.label}
        {item.details && (
          <>
            <br />
            <i>{item.details}</i>
          </>
        )}
      </span>
    </p>
  );
}

function SuiviDossierTimeline({
  items,
}: SuiviDossierTimelineProps): React.ReactElement {
  const timelineItems: TimelineItemProps[] = items.map(item => ({
    label:
      item.date !== undefined
        ? suiviDossierTimelineLabel(moment(item.date))
        : undefined,
    value: suiviDossierTimelineValue(item),
  }));

  return (
    <div className="suivi-dossier-timeline">
      <Timeline items={timelineItems} />
    </div>
  );
}

export default SuiviDossierTimeline;
