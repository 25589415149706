import React from 'react';
import { Button as AntdButton, Empty as AntdEmpty } from 'antd';

interface EmptyOnErrorProps {
  reload: () => void;
  description?: string;
}

function EmptyOnError({
  reload,
  description,
}: EmptyOnErrorProps): React.ReactElement {
  return (
    <AntdEmpty
      image={AntdEmpty.PRESENTED_IMAGE_SIMPLE}
      description={description ?? 'Une erreur est survenue'}
    >
      <AntdButton type="primary" onClick={reload}>
        Réessayer
      </AntdButton>
    </AntdEmpty>
  );
}

export default EmptyOnError;
