import React from 'react';
import {
  Button as AntdButton,
  Checkbox as AntdCheckbox,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import {
  ArrowLeftOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { views } from '../utils';
import ModalCreation from '../../Faq/Modals/Creation/ModalCreation';
import { useModal } from 'hooks/modal/useModal';

import './DashboardHeader.less';

interface DasboardHeaderFaqDomainProps {
  valid: boolean;
  setValid: (state: boolean) => void;
  nameDomain: string | null;
  idDomain: string | null;
  refreshDashboard: () => void;
}

function DashboardHeader({
  valid,
  setValid,
  nameDomain,
  idDomain,
  refreshDashboard,
}: DasboardHeaderFaqDomainProps): React.ReactElement {
  const navigate = useNavigate();

  const modalCreation = useModal();

  const onRefreshDashboard = () => {
    refreshDashboard();
  };
  const menu = (
    <AntdMenu>
      {views.map((view, index) => (
        <AntdMenu.Item key={index}>
          <Link to={view.link} target="_blank">
            {view.label}
          </Link>
        </AntdMenu.Item>
      ))}
    </AntdMenu>
  );

  return (
    <div>
      <div className="dashboard-header-filter">
        <AntdButton
          shape={'round'}
          icon={<ArrowLeftOutlined />}
          onClick={() => navigate(-1)}
        >
          Retour
        </AntdButton>
      </div>
      <div className="dashboard-header-domain">Domaine : {nameDomain}</div>
      <div className="dashboard-header-fields">
        <AntdCheckbox checked={!valid} onChange={e => setValid(!valid)}>
          Afficher les questions inactives
        </AntdCheckbox>
        <div>
          <AntdButton
            type="primary"
            shape="round"
            className="createFaqButton"
            onClick={modalCreation.open}
          >
            <PlusOutlined /> Créer une nouvelle question
          </AntdButton>
          <AntdDropdown overlay={menu}>
            <AntdButton type="default" shape="default">
              Visualiser FAQ <DownOutlined />
            </AntdButton>
          </AntdDropdown>
        </div>
        <ModalCreation
          visible={modalCreation.visible}
          close={modalCreation.close}
          refreshDashboard={onRefreshDashboard}
          idDomain={idDomain}
        />
      </div>
    </div>
  );
}

export default DashboardHeader;
