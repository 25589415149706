import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';

import './Downloader.less';

interface UploaderProps {
  download: () => Promise<void>;
  title: string;
}

const Downloader = ({ title, download }: UploaderProps): React.ReactElement => {
  return (
    <div className="DownloaderContainer" onClick={download}>
      <DownloadOutlined className="DownloadIcon" />
      <p className="DownloadTitle">{title}</p>
    </div>
  );
};

export default Downloader;
