import React from 'react';
import { DatePicker } from 'components/WrappedComponents';
import { ProfilFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriereBlocFourriere,
  InputPropFourriere,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { DATE_FORMAT } from 'utils/formats';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { validateFicheProfilGf } from '../../utils';

function DatePickerDateDebutAgrement({
  values,
  isEditable,
  generateRequestDto,
}: InputPropFourriere): React.ReactElement {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof FicheProfilFourriereBlocFourriere = 'agreementStartDate';

  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: "Date de début d'agrément",
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return validateFicheProfilGf(
                  gfController,
                  ProfilFourriereViolationDtoFormFieldEnum.DATE_AGREMENT_DEBUT,
                  user.idCorrelationFourriere || '',
                  generateRequestDto({ ...values, [key]: value }),
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      datePickerProps={{
        style: { width: '100%' },
        placeholder: FormPlaceholders.Date,
        format: DATE_FORMAT,
      }}
    />
  );
}

export default DatePickerDateDebutAgrement;
