import React from 'react';
import { Card, Skeleton } from 'antd';
import { useApi } from 'hooks/ApiStoreContext';
import pdfIcon from 'assets/images/jpg/pdf-icon.jpg';
import useExportFile from 'hooks/useExportFile';

function PdfTemplateCard(): React.ReactElement {
  const { FicheDescriptiveControllerApi: api } = useApi();
  const { download } = useExportFile(() =>
    api.downloadFicheDescriptiveUsingGET(),
  );
  return (
    <>
      <h1>Modèle de fiche descriptive</h1>
      <>
        <Card className="card" hoverable onClick={download}>
          <Card.Meta
            avatar={
              <img src={pdfIcon} style={{ maxHeight: '4em' }} alt="Pdf icon" />
            }
            title={'Fiche descriptive - Modèle.pdf'}
            description={''}
            style={{ overflow: 'visible', whiteSpace: 'unset' }}
          />
        </Card>
      </>
    </>
  );
}

export default PdfTemplateCard;
