import { escapeRegExp } from 'lodash';
import { message as AntdMessage } from 'antd';

export const extractFileNameFromContentDisposition = (
  result: Response,
): string | undefined => {
  const contentDisposition =
    result.headers.get('content-disposition') || undefined;
  return contentDisposition
    ?.split(';')
    .find(n => n.includes('filename='))
    ?.replace('filename=', '')
    ?.replace(new RegExp(escapeRegExp('"'), 'g'), '')
    .trim();
};

export const extractFileNameFromContentDispositionOrError = (
  result: Response,
): string | undefined => {
  const fileName = extractFileNameFromContentDisposition(result);

  if (fileName === undefined) {
    void AntdMessage.error(
      'Erreur lors du téléchargement : veuillez réessayer ultérieurement',
    );
    return;
  }
  return fileName;
};
