import { Typography as AntdTypo } from 'antd';
import { Link } from 'react-router-dom';
import { InformationBanner } from 'components/Banner';
import React from 'react';
import { useRequiredUserActionContext } from 'hooks/RequiredUserActionContext';

const { Text } = AntdTypo;

interface RequiredActionBannerProps {
  profilPath?: string;
}

/* GF and AF are asked to confirm their account info during the first login
 * or when their right giving entity got modified. If both actions are required
 * then only the first login one is shown */
export const RequiredUserActionBanner = ({
  profilPath,
}: RequiredActionBannerProps): React.ReactElement => {
  const { requiredUserActions } = useRequiredUserActionContext();

  // user first login
  if (requiredUserActions?.mustConfirmAccount) {
    return (
      <InformationBanner
        message={'Vérification du profil'}
        description={
          <Text>
            Bienvenue dans le SI Fourrières. Veuillez vérifier votre profil afin
            de confirmer l&apos;exactitude des informations.{' '}
            {profilPath && (
              <Link to={profilPath}>
                <strong>Vérifier mon profil</strong>
              </Link>
            )}
          </Text>
        }
        alertType={'warning'}
        key={'accountConfirmation'}
        closable={false}
      />
    );
  }

  // a referential got modified
  if (
    requiredUserActions?.idFourriereToConfirm ||
    requiredUserActions?.idAutoriteFourriereToConfirm
  ) {
    return (
      <InformationBanner
        message={'Vérification du profil'}
        description={
          <Text>
            Les informations de votre profil ont été modifiées par votre
            administrateur. Veuillez vérifier votre profil afin de confirmer
            l&apos;exactitude des informations.{' '}
            {profilPath && (
              <Link to={profilPath}>
                <strong>Vérifier mon profil</strong>
              </Link>
            )}
          </Text>
        }
        alertType={'warning'}
        key={'referentialConfirmation'}
        closable={false}
      />
    );
  }

  return <></>;
};
