import React from 'react';
import { FilterDashboardMarque } from './types';
import Filter from '../Filter/Filter';
import './DashboardHeader.less';

interface DashboardHeaderProps {
  totalCount: number;
  setFilter: (value: Partial<FilterDashboardMarque>) => void;
  filter: Partial<FilterDashboardMarque>;
}

function DashboardHeader({
  totalCount,
  filter,
  setFilter,
}: DashboardHeaderProps): React.ReactElement {
  const countString = `${totalCount} marque${
    totalCount > 1 ? 's' : ''
  } trouvée${totalCount > 1 ? 's' : ''}`;

  return (
    <div>
      <div className="dashboard-header-fields header-referentiels">
        <Filter filter={filter} setFilter={setFilter} />
      </div>
      <p className="dashboard-number-vehicules">{countString}</p>
    </div>
  );
}

export default DashboardHeader;
