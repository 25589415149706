import React from 'react';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  MigrateUniteFoViolationDtoFormFieldEnum,
  ReferentielUsesDto,
  UniteFOAdminResponseDto,
} from 'lib_api/lib/api/gen';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import { useApi } from 'hooks/ApiStoreContext';
import { useReferentielUses } from '../../utils';
import useInitialValues from './useInitialValues';
import useSubmitMigrateDossiersUniteFo from './buildSubmitFunction';

interface ModalMigrationDossiersProps {
  close: () => void;
  visible: boolean;
  unite: UniteFOAdminResponseDto;
  usesRequest: (idCorrelation: string) => Promise<ReferentielUsesDto>;
}

export default function ModalMigrationDossiers({
  close,
  unite,
  usesRequest,
}: ModalMigrationDossiersProps): React.ReactElement {
  const warningMessage = {
    title: 'Conséquence de la modification',
    detail:
      'La nouvelle unité de rattachement pourra accéder aux informations et effectuer les actions nécessaires des dossiers migrés via son tableau de bord, contrairement à la précédente qui n’y aura plus accès.',
  };

  const { AdminFonctionnelControllerApi: adminController } = useApi();

  const [uses] = useReferentielUses(unite.idCorrelation, usesRequest);
  const initialValues = useInitialValues(
    unite,
    uses?.searchDossierResult?.total ?? null,
  );
  const { submit, inProgress } = useSubmitMigrateDossiersUniteFo(
    adminController,
    close,
  );

  return (
    <>
      <h3>Migrer les dossiers vers une nouvelle unité</h3>
      <BaseForm
        initialValues={initialValues}
        onSubmit={submit}
        onCancel={close}
        isSubmitting={inProgress}
        inputs={[
          {
            field:
              MigrateUniteFoViolationDtoFormFieldEnum.OLD_UNITE_FO_CORRELATION_ID,
            name: 'idCorrelationOldUnite',
            label: 'Unité FO de départ',
            render: () => {
              return <div>{initialValues.abreviationOldUnite}</div>;
            },
          },
          {
            name: 'nbDossiers',
            label: 'Nombre de dossiers à migrer',
            disabled: true,
            render: () => {
              return <div>{initialValues.nbDossiers}</div>;
            },
          },
          {
            field:
              MigrateUniteFoViolationDtoFormFieldEnum.NEW_UNITE_FO_CORRELATION_ID,
            name: 'idCorrelationNewUnite',
            label: 'Unité cible pour migration des données',
            required: true,
            render: () => {
              return (
                <BaseSelectWithSearch
                  useSearchEntities={useAsyncSearchUniteFo(
                    AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                  )}
                  getOptionValue={option => option.idCorrelation}
                  getOptionLabel={option => option.abreviation}
                  allowClear={true}
                />
              );
            },
          },
          {
            field: MigrateUniteFoViolationDtoFormFieldEnum.COMMENT,
            required: true,
            name: 'comment',
            label: 'Justification',
            render: () => {
              return <BaseTextArea />;
            },
          },
        ]}
        validateField={async (field, formValues) => {
          const response =
            await adminController.validateMigrateUniteFoFieldUsingPOST(field, {
              newUniteFoIdCorrelation:
                formValues.idCorrelationNewUnite?.option?.idCorrelation || null,
              oldUniteFoIdCorrelation: formValues.idCorrelationOldUnite || null,
              comment: formValues.comment || null,
            });

          const violations = response ?? [];
          if (violations.length === 0) {
            return Promise.resolve();
          }

          return Promise.reject(violations[0].message);
        }}
        warningMessage={warningMessage}
      />
    </>
  );
}
