import { useCallback } from 'react';

import {
  AdminFonctionnelControllerApi,
  CreateUserRequestDto,
  CreateUserResultDto,
  CreateViolationDto,
  CreateViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import {
  UserValidationCallback,
  useValidationUserRequest,
} from 'hooks/utils/handleValidationRequest';
import { CreationCompteFormValues } from 'types/Compte/CreationCompte';
import { defaultErrorMessage } from 'utils/ErrorMessages';

/**
 * Convert form data to create request dto
 *
 * @param formData formData to convert
 */
export function generateCreateUserRequestDtoFromFormData(
  formData: CreationCompteFormValues,
): CreateUserRequestDto {
  return {
    prenom: formData.prenom ?? null,
    nom: formData.nom ?? null,
    email: formData.email ?? null,
    uniteRattachement: formData.uniteRattachement ?? null,
    profile: formData.profile ?? null,
    actif: formData.actif ?? null,
  };
}

/**
 * Validate create user request
 *
 * @param userId User id sent to request
 * @param controller Controller used to validate fields
 * @param values Request data sent to API
 * @param field FIeld to validate
 */
export async function validateCreateUserRequest(
  controller: AdminFonctionnelControllerApi,
  values: CreateUserRequestDto,
  field: CreateViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateCreateFieldUsingPOST(
      field,
      values,
    );

    if (response.createUserViolations) {
      const fieldViolations = response.createUserViolations.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

/**
 * Hook used to submit a request to create user
 *
 * @param thenSubmit function called if submit success
 */
export function useSubmitCreateUser(
  thenSubmit: (result: CreateUserResultDto) => void,
): [
  (createFormValues: CreationCompteFormValues) => Promise<CreateUserResultDto>,
  (result: CreateUserResultDto) => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const cuController = useApi().AdminFonctionnelControllerApi;

  const submitCreateUser = useCallback(
    (
      modificationFormValues: CreationCompteFormValues,
    ): Promise<CreateUserResultDto> => {
      const creationRequest = generateCreateUserRequestDtoFromFormData(
        modificationFormValues,
      );

      return cuController.createUsingPOST(creationRequest);
    },
    [cuController],
  );

  const onSuccess = useCallback(
    (result: CreateUserResultDto): void => {
      thenSubmit(result);
    },
    [thenSubmit],
  );

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [submitCreateUser, onSuccess, onError];
}

type CreateUserCallback = UserValidationCallback<AdminFonctionnelControllerApi>;

/**
 * Hook used to validate and submit a request to create user
 * @param onSuccess Function called if validation succeed
 * @param thenSubmit Function called if promise called in onSuccess succeed
 * @param catchSubmit Function called if promise called in onSuccess fails
 */
export function useValidateCreateUser(
  onSuccess: () => Promise<CreateUserResultDto>,
  thenSubmit: (result: CreateUserResultDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: CreateUserCallback) => Promise<void>,
  CreateViolationDto[] | null,
] {
  const cuController = useApi().AdminFonctionnelControllerApi;
  return useValidationUserRequest(
    cuController,
    'createUserViolations',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}
