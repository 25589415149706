import React from 'react';
import { AutoCompleteProps as AntdAutoCompleteProps } from 'antd';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import {
  CommuneApiGouvOption,
  useSearchCommuneApiGouv,
} from 'hooks/search/useSearchCommuneApiGouv';
import BaseAutocomplete from './BaseAutocomplete';

interface AutocompleteCommuneProps<FormValues>
  extends Omit<
    AntdAutoCompleteProps<string, CommuneApiGouvOption>,
    'options' | 'name'
  > {
  name: keyof FormValues & AntdNamePath & string;
  codePostalField?: keyof FormValues & AntdNamePath & string;
}

function buildValue(option: CommuneApiGouvOption): string {
  const codePostal = option.codespostaux[0] ?? undefined;
  return `${option.nom} ${codePostal && '-'} ${codePostal ?? ''}`.trim();
}

export default function AutocompleteCommune<FormValues>({
  name,
  codePostalField,
  ...props
}: AutocompleteCommuneProps<FormValues>): React.ReactElement {
  return (
    <BaseAutocomplete<FormValues, CommuneApiGouvOption>
      {...props}
      optionsFetcher={useSearchCommuneApiGouv}
      buildValue={buildValue}
      getCurrentFieldValue={option => option.nom}
      name={name}
      dependencies={
        codePostalField && [
          {
            name: codePostalField,
            getValue: option =>
              option.codespostaux && option.codespostaux.length > 0
                ? option.codespostaux[0]
                : undefined,
          },
        ]
      }
    />
  );
}
