import React from 'react';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  UniteFoViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import {
  getTypeUniteMessage,
  useSubmitCreateUniteFo,
  useValidateCreateUniteFo,
} from '../utils';
import { UniteFoFormValues } from 'types/referentiels/UniteFo';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import BaseInputCodeInsee from 'components/BaseForm/BaseInputCodeInsee';
import HelpMessage from 'components/BaseForm/HelpMessage';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';

interface ModalCreateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
}

export default function ModalCreate({
  close,
  visible,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const { submit, inProgress } = useSubmitCreateUniteFo(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateUniteFo();

  return (
    <ModalEdition
      close={close}
      visible={visible}
      title="Créer unité force de l'ordre"
      initials={{}}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
      inputs={[
        {
          field: UniteFoViolationDtoFormFieldEnum.TYPE,
          name: 'type',
          label: "Type d'unité",
          required: true,
          renderAfterInput: (values: UniteFoFormValues) => (
            <HelpMessage
              message={getTypeUniteMessage(values.type)}
              showMessage={values.type !== undefined}
            />
          ),
          render: () => {
            return (
              <BaseSelect
                options={[
                  {
                    value: 'GN',
                    displayValue: 'GN',
                  },
                  {
                    value: 'PN',
                    displayValue: 'PN',
                  },
                  {
                    value: 'PP',
                    displayValue: 'PP',
                  },
                  {
                    value: 'PM',
                    displayValue: 'PM',
                  },
                ]}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.displayValue}
              />
            );
          },
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE,
          name: 'code',
          label: 'Code',
          required: true,
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.ABREVIATION,
          name: 'abreviation',
          label: "Abréviation de l'unité",
          required: true,
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.UNITE,
          name: 'unite',
          label: "Libellé de l'unité",
          required: true,
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.PARENT,
          name: 'uniteFoParent',
          label: 'Unité parent',
          render: () => {
            return (
              <BaseSelectWithSearch
                useSearchEntities={useAsyncSearchUniteFo(
                  AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                )}
                getOptionValue={option => option.idCorrelation}
                getOptionLabel={option => option.abreviation}
                allowClear
              />
            );
          },
        },
        {
          name: 'adresse',
          label: "Adresse de l'unité",
          render: ({ name }) => {
            return (
              <AutocompleteAdresse
                name={name}
                communeField="ville"
                codePostalField="codePostal"
              />
            );
          },
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE_POSTAL,
          name: 'codePostal',
          label: 'Code postal',
        },
        {
          name: 'ville',
          label: 'Ville',
          normalize: (value: string) => value.toUpperCase(),
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE_INSEE,
          name: 'codeInsee',
          label: 'Code INSEE',
          render: ({ name }) => {
            return (
              <BaseInputCodeInsee name={name} communeField="ctRattachement" />
            );
          },
        },
        {
          name: 'ctRattachement',
          label: 'CT de rattachement',
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.EMAIL,
          name: 'email',
          label: 'Courriel',
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.TELEPHONE,
          name: 'telephone',
          label: 'Téléphone',
        },
      ]}
    />
  );
}
