import React from 'react';
import { Input as AntdInput, InputProps as AntdInputProps } from 'antd';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';

export default function BaseInput(props: AntdInputProps): React.ReactElement {
  return (
    <AntdInput
      {...props}
      placeholder={props.placeholder ?? FormPlaceholders.Input}
    />
  );
}
