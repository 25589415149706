import React from 'react';

import { TypeAfFormValues } from 'types/referentiels/TypeAf';
import { useSubmitCreateTypeAf, useValidateCreateTypeAf } from '../utils';
import { ModalEditionTypeAf } from './ModalEditionTypeAf';

interface ModalDetailsProps {
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalCreate({
  visible,
  close,
  refreshDashboard,
}: ModalDetailsProps): React.ReactElement {
  const initials: TypeAfFormValues = {};
  const { submit, inProgress } = useSubmitCreateTypeAf(refreshDashboard, close);
  const { validate } = useValidateCreateTypeAf();

  return (
    <ModalEditionTypeAf
      close={close}
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      visible={visible}
      title={"Créer un type d'AF"}
      validate={validate}
    />
  );
}

export default ModalCreate;
