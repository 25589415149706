import React from 'react';
import { Empty as AntdEmpty, Spin as AntdSpin } from 'antd';

import { MarqueDto } from 'lib_api/lib/api/gen';

import BaseSelectMultiList from 'components/BaseForm/Select/BaseSelectMultiList';
import { CommonBaseSelectProps } from 'components/BaseForm/Select/CommonBaseSelect';
import { setFieldValue } from 'components/BaseForm/utils';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import { LABEL_MARQUE_NON_REFERENCEE, VALUE_NON_REFERENCE } from 'utils/global';
import { useFetchMarques } from './utils';

type SelectMarqueMultiListProps<FormValues> = Omit<
  CommonBaseSelectProps<FormValues, MarqueDto | null>,
  'options' | 'getOptionValue' | 'getOptionLabel' | 'onBottomReached'
>;

const marqueNonReferencee: MarqueDto = {
  categorie: null,
  id: VALUE_NON_REFERENCE,
  idCorrelation: null,
  libelle: LABEL_MARQUE_NON_REFERENCEE,
  marqueExacteId: null,
  prioritaire: null,
  type: null,
};

export default function BaseSelectMarqueMultiList<FormValues>({
  ...props
}: SelectMarqueMultiListProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  const { marques, inProgress, search, paginate } = useFetchMarques(false);
  const { marques: marquesPrio, inProgress: inProgressPrio } =
    useFetchMarques(true);

  return (
    <BaseSelectMultiList
      {...props}
      options={[
        {
          label: 'Marques fréquentes',
          options: marquesPrio,
        },
        {
          label: 'Autres',
          options: [marqueNonReferencee, ...marques],
        },
      ]}
      notFoundContent={
        inProgress || inProgressPrio ? <AntdSpin size="small" /> : <AntdEmpty />
      }
      getOptionLabel={(marque: MarqueDto) => marque.libelle}
      getOptionValue={(marque: MarqueDto) => marque.id}
      showSearch
      onSearch={search}
      onBottomReached={paginate}
      onChange={(value, option) => {
        props.onChange && props.onChange(value, option);
        if (option && props.id) {
          setFieldValue(form, props.id, option);
        }
      }}
    />
  );
}
