import React from 'react';

import { Input } from 'components/WrappedComponents';
import { FaqViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FaqFormValues, FaqFieldProps } from '../../types';

function InputTitle({
  values,
  validateField,
}: FaqFieldProps): React.ReactElement {
  const key: keyof FaqFormValues = 'title';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Question',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateField(
                  key,
                  FaqViolationDtoFormFieldEnum.TITLE,
                  value,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}

export default InputTitle;
