import React from 'react';

import { Input } from 'components/WrappedComponents';
import { ProfilAutoriteFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';

import { FieldProps, FicheProfilAutoriteFourriere } from '../types';
import { validateProfilAutoriteFourriereField } from '../utils';

function InputCedex({ values, isEditable }: FieldProps): React.ReactElement {
  const user = useUserStore();
  const { AutoriteFourriereControllerApi } = useApi();

  const key: keyof FicheProfilAutoriteFourriere = 'cedex';
  const label = 'CEDEX';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                return await validateProfilAutoriteFourriereField(
                  AutoriteFourriereControllerApi,
                  ProfilAutoriteFourriereViolationDtoFormFieldEnum.CEDEX,
                  user.idCorrelationAutoriteFourriere || '',
                  { ...values, [key]: value },
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      inputProps={{
        placeholder: FormPlaceholders.Input,
      }}
    />
  );
}

export default InputCedex;
