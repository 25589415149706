import React from 'react';

import { TextArea } from 'components/WrappedComponents/index';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { UpdateFicheFormValues } from '../../types';

interface InputJustificationProps<FieldKey> {
  values: UpdateFicheFormValues;
  validateField: (
    key: keyof UpdateFicheFormValues,
    field: FieldKey,
    value: unknown,
  ) => Promise<void>;
  fieldKey: FieldKey;
}

function InputJustification<FieldKey>({
  values,
  validateField,
  fieldKey,
}: InputJustificationProps<FieldKey>): React.ReactElement {
  const key: keyof UpdateFicheFormValues = 'comment';

  return (
    <TextArea
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Justification',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.LlargeWrapperCol },
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateField(key, fieldKey, value);
              },
            },
          ],
        },
      }}
      textAreaProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}

export default InputJustification;
