import React from 'react';

import { Button as AntdButton } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useModal } from 'hooks/modal/useModal';

import { FilterDashboardTypeAf } from '../types';
import ModalCreate from '../Modals/ModalCreate';
import { Filter } from './Filter/Filter';

interface DashboardHeaderProps {
  totalCount: number;
  filter: Partial<FilterDashboardTypeAf>;
  setFilter: (value: Partial<FilterDashboardTypeAf>) => void;
}

function DashboardHeader({
  totalCount,
  filter,
  setFilter,
}: DashboardHeaderProps): React.ReactElement {
  const countString = `${totalCount} type${totalCount > 1 ? 's' : ''} trouvé${
    totalCount > 1 ? 's' : ''
  }`;

  const modalCreation = useModal();

  const refreshDashboard = () => {
    setFilter({ ...filter });
  };

  return (
    <div>
      <div className="dashboard-header-fields header-referentiels">
        <Filter filter={filter} setFilter={setFilter} />
        <AntdButton
          icon={<PlusOutlined />}
          type="primary"
          shape="round"
          onClick={modalCreation.open}
        >
          Créer un type d&apos;autorité de fourrière
        </AntdButton>
        <ModalCreate
          visible={modalCreation.visible}
          close={modalCreation.close}
          refreshDashboard={refreshDashboard}
        />
      </div>
      <p>{countString}</p>
    </div>
  );
}

export default DashboardHeader;
