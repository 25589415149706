import React, { useState } from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  AutoriteFourriereDto,
  ConditionsReactivateAutoriteFourriereDto,
} from 'lib_api/lib/api/gen';

import ModalDetails from './Modals/ModalDetails';
import { useChangeValidityAutoriteFourriere } from './utils';
import { useApi } from 'hooks/ApiStoreContext';
import ModalReactivation, {
  ConditionReactivateProps,
} from '../Modals/ModalReactivation/ModalReactivation';
import ConditionReactivateAutoriteFourriere from './ConditionReactivateAutoriteFourriere/ConditionReactivateAutoriteFourriere';
import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import UsesAutoriteFourriere from './UsesAutoriteFourriere/UsesAutoriteFourriere';
import ModalUpdate from './Modals/ModalUpdate';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  autorite: AutoriteFourriereDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  autorite,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const [isDetailsOpen, setIsDetailsOpen] = useState<boolean>(false);
  const [isUpdateOpen, setIsUpdateOpen] = useState<boolean>(false);
  const [isChangeValidityOpen, setIsChangeValidityOpen] =
    useState<boolean>(false);
  const controller = useApi().AdminFonctionnelControllerApi;
  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    getUsesRequest,
  ] = useChangeValidityAutoriteFourriere(controller);

  const conditionUnlock = (
    conditions: ConditionsReactivateAutoriteFourriereDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const closeUpdateValidity = () => {
    setIsChangeValidityOpen(false);
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item
        onClick={() => {
          setIsUpdateOpen(true);
        }}
      >
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item
        onClick={() => {
          setIsChangeValidityOpen(true);
        }}
      >
        {autorite.enabled ? "Désactiver l'autorité" : "Réactiver l'autorité"}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <AntdButton
        onClick={() => {
          setIsDetailsOpen(true);
        }}
      >
        Détails
      </AntdButton>
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      <ModalDetails
        autorite={autorite}
        visible={isDetailsOpen}
        close={() => {
          setIsDetailsOpen(false);
        }}
      />
      <ModalUpdate
        refreshDashboard={refreshDashboard}
        autorite={autorite}
        visible={isUpdateOpen}
        close={() => {
          setIsUpdateOpen(false);
        }}
      />
      {isChangeValidityOpen &&
        (autorite.enabled ? (
          <ModalDesactivation
            idCorrelation={autorite.idCorrelation}
            titleModal="Désactiver l'autorité de fourrière"
            usesRequest={getUsesRequest}
            submitDeprecate={submitDeprecateReferentiel}
            componentUses={UsesAutoriteFourriere}
            visible={isChangeValidityOpen}
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
          />
        ) : (
          <ModalReactivation
            idCorrelation={autorite.idCorrelation}
            titleModal="Réactiver l'autorité de fourrière"
            visible={isChangeValidityOpen}
            close={closeUpdateValidity}
            callAfterSubmit={refreshDashboard}
            conditionRequest={conditionsReactivateReferentiel}
            componentConditions={({
              conditions,
            }: ConditionReactivateProps<ConditionsReactivateAutoriteFourriereDto>) => {
              return (
                <ConditionReactivateAutoriteFourriere
                  autorite={autorite}
                  conditions={conditions}
                />
              );
            }}
            conditionUnlock={conditionUnlock}
            reactivateRequest={submitReactivateReferentiel}
          />
        ))}
    </>
  );
}

export default CellDetailsAndActions;
