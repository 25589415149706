import {
  ReferentielControllerApi,
  TypeAutoriteFourriereDto,
} from 'lib_api/lib/api/gen';

export function fetchTypeAutoriteFourriere(
  refController: ReferentielControllerApi,
): Promise<TypeAutoriteFourriereDto[]> {
  return refController
    .getValidTypeAutoriteFourriereUsingGET()
    .then(response => {
      return response.referenceDtoList;
    });
}
