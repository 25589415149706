import React from 'react';

import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import { FilterDashboardMotifSortie } from '../../types';

interface FilterProps {
  filter: Partial<FilterDashboardMotifSortie>;
  setFilter: (value: Partial<FilterDashboardMotifSortie>) => void;
}

export function Filter({ filter, setFilter }: FilterProps): React.ReactElement {
  const { valid } = filter;

  function setDisplayInvalidMotif(event: CheckboxChangeEvent) {
    setFilter({
      valid: !event.target.checked,
    });
  }

  return (
    <div className="dashboard-header-filter">
      <AntdCheckbox checked={!valid} onChange={setDisplayInvalidMotif}>
        Afficher les motifs inactifs
      </AntdCheckbox>
    </div>
  );
}
