import { createContext, useContext } from 'react';

interface BooleanStoreContextContent {
  value: boolean;
}

const BooleanStoreContext = createContext<BooleanStoreContextContent | null>(
  null,
);

export const useBooleanStoreContext = (): boolean => {
  const context = useContext(BooleanStoreContext);
  if (context === null) {
    throw new Error('useBooleanStoreContext called outside Context.');
  }

  return context.value;
};

const BooleanStoreProvider = BooleanStoreContext.Provider;

export default BooleanStoreProvider;
