import React from 'react';
import { UntypedField } from 'components/WrappedComponents';
import { ProfilFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { CSSUtils } from 'utils/CSSUtils';
import {
  FicheProfilFourriereBlocCoordonnees,
  InputPropCoordonnees,
  SemaineHoraire,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import ButtonCreateModal from '../Modal/ButtonCreateModal';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { validateFicheProfilGf } from '../../utils';

type InputHoraireProps = InputPropCoordonnees & {
  setValues: (values: SemaineHoraire) => void;
};

const InputHoraire = ({
  values,
  setValues,
  generateRequestDto,
  isEditable,
}: InputHoraireProps): React.ReactElement => {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const fieldKey: keyof FicheProfilFourriereBlocCoordonnees = 'horaires';

  return (
    <UntypedField
      fieldKey={fieldKey}
      itemProps={{
        label: "Horaires d'ouvertures",
        labelCol: { span: CSSUtils.defaultLabelCol },
        wrapperCol: { span: CSSUtils.largeWrapperCol },
        valuePropName: 'horaires',
        rules: [
          {
            validator: async (_rule, value): Promise<void> => {
              return validateFicheProfilGf(
                gfController,
                ProfilFourriereViolationDtoFormFieldEnum.HORAIRES,
                user.idCorrelationFourriere || '',
                generateRequestDto({ ...values, [fieldKey]: value }),
              );
            },
          },
        ],
      }}
      editable={isEditable}
      WrappedInput={
        <ButtonCreateModal
          values={values}
          setValues={setValues}
          title={<>Éditer les horaires</>}
        />
      }
      ConsultationElement={
        <ButtonCreateModal
          values={values}
          title={<>Consulter les horaires</>}
        />
      }
    />
  );
};

export default InputHoraire;
