import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchDossierAdmin } from 'hooks/dossierAdmin/useFetchDossierAdmin';
import { DossierAdminProvider } from 'hooks/dossierAdmin/DossierAdminContext';
import { FicheAdmin } from './FicheAdmin/FicheAdmin';
import { FicheHeader } from './FicheHeader/FicheHeader';
import AsyncComponent from 'components/AsyncComponent/AsyncComponent';

import './PageDossier.less';
import useFetchDetailedEvents from 'hooks/dossierAdmin/useFetchDetailedEvents';

const PageDossier: React.FC = () => {
  const params = useParams<Record<string, string>>();
  const id = params.id ?? ''; // TODO: Must not call API if dossier is undefined

  const { dossier, setDossier, fetchInProgress, errorOccured, fetchDossier } =
    useFetchDossierAdmin(id);

  const fetchResult = useFetchDetailedEvents(id);

  return (
    <div className="FicheAdminContainer">
      <AsyncComponent
        render={() => {
          return dossier ? (
            <DossierAdminProvider
              value={{
                dossier: { result: dossier, setDossier: setDossier },
                events: fetchResult,
              }}
            >
              <FicheHeader />
              <FicheAdmin />
            </DossierAdminProvider>
          ) : null;
        }}
        inProgress={fetchInProgress}
        errorOccured={errorOccured}
        reload={fetchDossier}
        errorMessage="Impossible de charger le dossier, une erreur est survenue"
      />
    </div>
  );
};

export default PageDossier;
