import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import frFR from 'antd/lib/locale/fr_FR';

import { DATE_FORMAT } from 'utils/formats';

const { RangePicker: AntRangePicker } = DatePicker;

function BaseRangeDatePicker<T>(props: RangePickerProps): React.ReactElement {
  return (
    <AntRangePicker
      {...props}
      style={{ width: '100%' }}
      format={props?.format ?? DATE_FORMAT}
      locale={frFR.DatePicker}
      placeholder={['De', 'À']}
    />
  );
}

export default BaseRangeDatePicker;
