import React from 'react';
import { Checkbox } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { SemaineHoraire } from 'types/FicheProfil/FicheProfilGardienFourriere';
import { useBooleanStoreContext } from 'hooks/BooleanStoreContext';

interface RDVCheckboxProps {
  values: SemaineHoraire;
  surRendezVousKey: keyof SemaineHoraire & string;
}

function RDVCheckbox({
  values,
  surRendezVousKey,
}: RDVCheckboxProps): React.ReactElement {
  const disabled = useBooleanStoreContext();

  return (
    <Checkbox
      wrapperProps={{
        values: values,
        fieldKey: surRendezVousKey,
        itemProps: {
          label: 'Sur RDV',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      checkboxProps={{
        disabled: disabled,
      }}
    />
  );
}

export default RDVCheckbox;
