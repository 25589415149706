import { UserDto, UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { ShouldNotHappen } from 'utils';

export function getUserLabel(user: UserDto): string | undefined {
  switch (user.profile) {
    case UserDtoProfileEnum.AUTORITE_FOURRIERE:
    case UserDtoProfileEnum.GARDIEN_FOURRIERE:
    case UserDtoProfileEnum.FORCE_DE_L_ORDRE:
    case UserDtoProfileEnum.ADMIN_FONCTIONNEL:
      return `${user.firstName || ''} ${user.lastName || ''}`.trim();
    case UserDtoProfileEnum.DSR_BLR:
    case UserDtoProfileEnum.CSU:
    case null:
      return undefined;
    default:
      throw new ShouldNotHappen(user.profile);
  }
}
