import React from 'react';

import { MotifSortieDto } from 'lib_api/lib/api/gen';

import { MotifSortieFormValues } from 'types/referentiels/MotifSortie';

import {
  useSubmitUpdateMotifSortie,
  useValidateUpdateMotifSortie,
} from '../utils';
import { ModalEditionMotifSortie } from './ModalEditionMotifSortie';

interface ModalCreateProps {
  motifSortie: MotifSortieDto;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalCreate({
  motifSortie,
  visible,
  close,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const initials: MotifSortieFormValues = {
    ...motifSortie,
    delaiEffacement: motifSortie.delaiEffacementEnMois,
  };
  const { submit, inProgress } = useSubmitUpdateMotifSortie(
    motifSortie.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateMotifSortie(motifSortie.idCorrelation);

  return (
    <ModalEditionMotifSortie
      close={close}
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      visible={visible}
      title={'Mise à jour du motif de sortie'}
      validate={validate}
    />
  );
}

export default ModalCreate;
