import React from 'react';
import moment from 'moment';

import {
  DetailedEventDtoInducedStatutDossierEnum,
  DossierDtoAdminFonctionnelStatusEnum,
} from 'lib_api/lib/api/gen';

import { StatusContent } from '../../../FicheAdminMain/MainInfoHeader/StatutContent/StatusContent';
import { DATE_FORMAT_WITH_TIME } from 'utils/formats';

import './EventItemLabel.less';

interface EventItemLabelProps {
  timestamp: string;
  label: string;
  inducedStatutDossier: DetailedEventDtoInducedStatutDossierEnum | null;
  details: string[];
}

export default function EventItemLabel({
  timestamp,
  label,
  inducedStatutDossier,
  details,
}: EventItemLabelProps): React.ReactElement {
  return (
    <div className="EventTypeLabel">
      <div className="LinkContent">
        <div className="Date">
          {moment(timestamp).format(DATE_FORMAT_WITH_TIME)}
        </div>
        <div className="Divider">-</div>
        <div className="Label">{label}</div>
        <div className="statusContent">
          <StatusContent
            status={
              inducedStatutDossier as unknown as DossierDtoAdminFonctionnelStatusEnum
            }
          />
        </div>
      </div>
      {details.length > 0 && (
        <ul className="DetailsList">
          {details.map((detail, index) => (
            <li key={index}>{detail}</li>
          ))}
        </ul>
      )}
    </div>
  );
}
