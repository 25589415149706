import { useCallback, useState } from 'react';
import { message as AntdMessage } from 'antd';
import FileSaver from 'file-saver';

import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { extractFileNameFromContentDisposition } from 'utils/requestUtils';
import {
  DossierResponseDto,
  ExpediteurNotificationRequestDto,
  ExpediteurNotificationViolationDtoFormFieldEnum,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { defaultErrorMessage } from 'utils/ErrorMessages';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { CoordonneesUniteFoFormValues } from './types';
import { useBoolean } from 'utils/genericUtils';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';

export const useFetchPdf = (): [boolean, () => void] => {
  const [dossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const notificationController = useApi().ForceOrdreControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const fetchPdf = () => {
    setIsDownloading(true);
    notificationController
      .manualNotificationPdfUsingGET(dossierId)
      .then((result: Response) => {
        setIsDownloading(false);
        const fileName = extractFileNameFromContentDisposition(result);

        if (fileName === undefined) {
          void AntdMessage.error(
            'Erreur lors du téléchargement: Veuillez réessayer ultérieurement',
          );
          return;
        }

        // Create dom link to download file
        void result.blob().then((blob: Blob) => {
          FileSaver.saveAs(blob, fileName);
        });
      })
      .catch((errorResponse: Response) => {
        setIsDownloading(false);
        behaviourOnError(errorResponse);
      });
  };

  return [isDownloading, fetchPdf];
};

function buildRequest(
  formValues: CoordonneesUniteFoFormValues,
): ExpediteurNotificationRequestDto {
  return {
    adresse: formValues.adresse || null,
    codePostal: formValues.codePostal || null,
    commune: formValues.commune || null,
    idCorrelationUniteFo: formValues.uniteFo?.option?.idCorrelation || null,
  };
}

export function useValidateUpdateCoordonneesUnite(
  dossierId: string,
): ValidateFunction<
  CoordonneesUniteFoFormValues,
  ExpediteurNotificationViolationDtoFormFieldEnum
> {
  const { ForceOrdreControllerApi: controller } = useApi();
  const validate = async (
    field: ExpediteurNotificationViolationDtoFormFieldEnum,
    values: CoordonneesUniteFoFormValues,
  ) => {
    try {
      const response =
        await controller.validateExpediteurNotificationFieldUsingPOST(
          field,
          dossierId,
          buildRequest(values),
        );
      if (response.updateExpediteurNotificationViolationDtoList) {
        const fieldViolations =
          response.updateExpediteurNotificationViolationDtoList.filter(
            violation => violation.formField === field,
          );

        if (fieldViolations.length > 0) {
          return Promise.reject(
            fieldViolations.map(violation => violation.message).join(', '),
          );
        }
      }
    } catch (e) {
      if (e instanceof Response) {
        return Promise.reject((await backAlertMessage(e)).description);
      }
      return Promise.reject(defaultErrorMessage);
    }
    return Promise.resolve();
  };

  return { validate: validate };
}

export function useSubmitUpdateCoordonneesUniteRule(
  dossierId: string,
  onSuccess: (dossier: DossierResponseDto) => void,
): SubmitFunction<CoordonneesUniteFoFormValues> {
  const { ForceOrdreControllerApi: controller } = useApi();

  const {
    value: inProgress,
    setIsTrue: start,
    setIsFalse: stop,
  } = useBoolean();

  const behaviourOnError = useHandleBackErrors();

  const submit = useCallback(
    (values: CoordonneesUniteFoFormValues): void => {
      start();
      controller
        .updateExpediteurNotificationUsingPOST(dossierId, buildRequest(values))
        .then(onSuccess)
        .catch(error => {
          behaviourOnError(error);
        })
        .finally(stop);
    },
    [controller],
  );

  return { submit: submit, inProgress: inProgress };
}

export function buildInitialsFormValues(
  dossier: DossierResponseDto,
  uniteFo: UniteFOResponseDto | null,
): CoordonneesUniteFoFormValues {
  const dossierExpediteur = dossier.body?.traitement?.expediteurNotification;
  if (dossierExpediteur) {
    return {
      uniteFo: {
        value: uniteFo?.idCorrelation,
        label: uniteFo?.unite,
        option: uniteFo ?? undefined,
      },
      adresse: dossierExpediteur?.adresse || undefined,
      codePostal: dossierExpediteur?.codePostal || undefined,
      commune: dossierExpediteur?.commune || undefined,
    };
  } else {
    // falling back to current user
    return {
      uniteFo: {
        value: uniteFo?.idCorrelation,
        label: uniteFo?.unite,
        option: uniteFo ?? undefined,
      },
      adresse: uniteFo?.adresse || undefined,
      codePostal: uniteFo?.codePostal || undefined,
      commune: uniteFo?.ville || undefined,
    };
  }
}
