import React from 'react';
import TextArea from 'antd/lib/input/TextArea';

import { TypeAutoriteFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import HelpMessage from 'components/BaseForm/HelpMessage';
import { FormInputProps } from 'components/BaseForm/types';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';

import { TypeAfFormValues } from 'types/referentiels/TypeAf';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';

import './ModalEditionTypeAf.less';

interface ModalEditionTypeAfProps {
  close: () => void;
  visible: boolean;
  initials: TypeAfFormValues;
  title: string;
  submit: (values: TypeAfFormValues) => void;
  isSubmitting: boolean;
  validate: (
    field: TypeAutoriteFourriereViolationDtoFormFieldEnum,
    values: TypeAfFormValues,
  ) => Promise<void>;
}

export function ModalEditionTypeAf({
  ...props
}: ModalEditionTypeAfProps): React.ReactElement {
  const baseInputs: FormInputProps<
    TypeAfFormValues,
    TypeAutoriteFourriereViolationDtoFormFieldEnum
  >[] = [
    {
      field: TypeAutoriteFourriereViolationDtoFormFieldEnum.TYPE,
      name: 'type',
      label: 'Type',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      field:
        TypeAutoriteFourriereViolationDtoFormFieldEnum.LIBELLE_NOTIFICATION,
      name: 'displayedText',
      label: 'Libellé Notification',
      required: true,
      render: () => <TextArea placeholder={FormPlaceholders.Input} />,
    },
    {
      field:
        TypeAutoriteFourriereViolationDtoFormFieldEnum.CODE_TYPOLOGIE_NIVEAU_1,
      name: 'codeTypologieNiveau1',
      label: 'Code Typologie DNID Niveau 1',
      required: true,
      renderAfterInput: () => (
        <div className={'messageTypeAf'}>
          <HelpMessage
            message='"1" si l’entité est une préfecture ou une sous-préfecture, "4" sinon'
            showMessage={true}
          />
        </div>
      ),
    },
    {
      name: 'codeTypologieNiveau2',
      label: 'Code Typologie DNID Niveau 2',
      renderAfterInput: () => (
        <div className={'messageTypeAf'}>
          <HelpMessage
            message='"10" si l’entité est une préfecture ou une sous-préfecture, "24" sinon'
            showMessage={true}
          />
        </div>
      ),
    },
  ];

  return <ModalEdition {...props} inputs={baseInputs} />;
}
