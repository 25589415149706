import { useCallback } from 'react';

import {
  AutoriteFourriereDto,
  AdminFonctionnelControllerApi,
  FourriereDto,
  UpdateUserRequestDto,
  UpdateViolationDto,
  UpdateViolationDtoFormFieldEnum,
  UserDto,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import {
  UserValidationCallback,
  useValidationUserRequest,
} from 'hooks/utils/handleValidationRequest';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { ModificationUtilisateurFormValues } from './ModalModification';
import { OptionProps } from 'types/searchResult';

export interface ModificationUserFieldProps
  extends FieldProps<ModificationUtilisateurFormValues> {
  userId: string;
}

/**
 * Convert form data to update request dto
 *
 * @param formData formData to convert
 */
export function generateUpdateUserRequestDtoFromFormData(
  formData: ModificationUtilisateurFormValues,
): UpdateUserRequestDto {
  return {
    firstName: formData.firstName ?? null,
    lastName: formData.lastName ?? null,
    email: formData.email ?? null,
    entiteRattachement: formData.uniteRattachement ?? null,
    actif: formData.actif ?? null,
  };
}

/**
 * Validate update user request
 *
 * @param userId User id sent to request
 * @param controller Controller used to validate fields
 * @param values Request data sent to API
 * @param field FIeld to validate
 */
export async function validateUpdateUserRequest(
  userId: string,
  controller: AdminFonctionnelControllerApi,
  values: UpdateUserRequestDto,
  field: UpdateViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateUpdateFieldUsingPOST(
      field,
      userId,
      values,
    );

    if (response.updateUserViolations) {
      const fieldViolations = response.updateUserViolations.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

/**
 * Convert unite FO to OptionProps
 *
 * @param uniteFo Unite FO to convert
 */
export const uniteFoToItem = (uniteFo: UniteFOResponseDto): OptionProps => ({
  value: uniteFo.idCorrelation,
  displayValue: uniteFo.abreviation,
});

/**
 * Convert Autorité Fourrière to OptionProps
 *
 * @param autoriteFourriere Autorite Fourriere to convert
 */
export const autoriteFourriereToItem = (
  autoriteFourriere: AutoriteFourriereDto,
): OptionProps => ({
  value: autoriteFourriere.idCorrelation,
  displayValue: autoriteFourriere.libelleComplet,
});

/**
 * Convert Fourriere to OptionProps
 *
 * @param autoriteFourriere Fourrière to convert
 */
export const fourriereToItem = (fourriere: FourriereDto): OptionProps => ({
  value: fourriere.idCorrelation ?? '',
  displayValue: fourriere.codePostal + ' - ' + fourriere.raisonSociale,
});

/**
 * Hook used to submit a request to update user
 *
 * @param thenSubmit function called if submit success
 */
export function useSubmitUpdateUser(
  thenSubmit: () => void,
): [
  (
    modificationFormValues: ModificationUtilisateurFormValues,
    userId: string,
  ) => Promise<UserDto>,
  () => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const cuController = useApi().AdminFonctionnelControllerApi;

  const submitUpdateUser = useCallback(
    (
      modificationFormValues: ModificationUtilisateurFormValues,
      userId: string,
    ): Promise<UserDto> => {
      const prolongationRequest = generateUpdateUserRequestDtoFromFormData(
        modificationFormValues,
      );

      return cuController.updateUsingPUT(userId, prolongationRequest);
    },
    [cuController],
  );

  const onSuccess = useCallback((): void => {
    thenSubmit();
  }, [thenSubmit]);

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [submitUpdateUser, onSuccess, onError];
}

type UpdateUserCallback = UserValidationCallback<AdminFonctionnelControllerApi>;

/**
 * Hook used to validate and submit a request to update user
 * @param onSuccess Function called if validation succeed
 * @param thenSubmit Function called if promise called in onSuccess succeed
 * @param catchSubmit Function called if promise called in onSuccess fails
 */
export function useValidateUpdateUser(
  onSuccess: () => Promise<UserDto>,
  thenSubmit: (user: UserDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: UpdateUserCallback) => Promise<void>,
  UpdateViolationDto[] | null,
] {
  const cuController = useApi().AdminFonctionnelControllerApi;
  return useValidationUserRequest(
    cuController,
    'updateUserViolations',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}
