import React from 'react';
import { Moment } from 'moment';
import { Checkbox, Typography as AntdTypo } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TraitementResponseDtoListStatutEnum } from 'lib_api/lib/api/gen';

import { FilterButton, SearchBar } from 'components/WrappedComponents';
import { BaseOptionType } from 'components/BaseForm/Select/BaseSelect';
import { OptionProps } from 'types/searchResult';
import { DashboardDSRFilterFormValues, DashboardDSRFilter } from '../../types';
import DashboardDSRFilterForm from './FilterFormDSR';

interface FiltersProps {
  filter: Partial<DashboardDSRFilter>;
  setFilter: (value: Partial<DashboardDSRFilter>) => void;
}

function keepDossierSortis(
  statut: TraitementResponseDtoListStatutEnum[],
  sortieFilter: boolean,
  dateRangeSortieDefinitive: [Moment | undefined, Moment | undefined],
  motifDeSortieFilter: string[],
  includeDossiersAnonymises: boolean,
): boolean {
  // automatically check the "sorti" checkbox
  return (
    // if the statut SORTI is selected
    statut.includes(TraitementResponseDtoListStatutEnum.SORTI) ||
    // if the checkbox is checked
    (statut.length === 0 && sortieFilter) ||
    // if we search for a date sortie
    !!dateRangeSortieDefinitive[0] ||
    !!dateRangeSortieDefinitive[1] ||
    motifDeSortieFilter.length !== 0 ||
    // if we search for anonymized dossiers
    includeDossiersAnonymises === true
  );
}

function Filters({ filter, setFilter }: FiltersProps): React.ReactElement {
  const { search, includeDossiersSortis, ...formFilter } = filter;

  function setFormFilter(value: Partial<DashboardDSRFilterFormValues>) {
    const {
      statut,
      dateRangeSortieDefinitive,
      motifDeSortie,
      includeDossiersAnonymises,
    } = value;
    setFilter({
      ...filter,
      ...value,
      includeDossiersSortis: keepDossierSortis(
        statut ?? [],
        filter.includeDossiersSortis ?? false,
        dateRangeSortieDefinitive ?? [undefined, undefined],
        motifDeSortie ?? [],
        includeDossiersAnonymises ?? false,
      ),
    });
  }

  function setIncludeDossiersSortis(event: CheckboxChangeEvent) {
    const { statut, dateRangeSortieDefinitive, motifDeSortie } = filter;
    const updatedStatut =
      statut?.filter(s => s !== TraitementResponseDtoListStatutEnum.SORTI) ||
      [];

    // Only add the SORTI statut filter if the statut filter is not empty
    if (event.target.checked && (statut?.length ?? -1) > 0) {
      updatedStatut.push(TraitementResponseDtoListStatutEnum.SORTI);
    }

    setFilter({
      ...filter,
      statut: updatedStatut,
      includeDossiersSortis: event.target.checked,
      // clear the date sortie filter when checkbox unchecked
      dateRangeSortieDefinitive: event.target.checked
        ? dateRangeSortieDefinitive
        : undefined,
      motifDeSortie: event.target.checked ? motifDeSortie : [],
      includeDossiersAnonymises: undefined,
    });
  }

  return (
    <div className="filters-container">
      <SearchBar
        value={filter.search}
        // Plates values are forced to uppercase
        setValue={search =>
          setFilter({ ...filter, search: search?.toUpperCase() })
        }
        antdProps={{
          placeholder: 'Rechercher une plaque...',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
      <FilterButton
        filter={formFilter}
        setFilter={setFormFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <>
            <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
            <DashboardDSRFilterForm filter={data} setFilter={setData} />
          </>
        )}
      </FilterButton>
      <Checkbox
        checked={includeDossiersSortis}
        onChange={setIncludeDossiersSortis}
      >
        Afficher les véhicules sortis
      </Checkbox>
    </div>
  );
}

export default Filters;
