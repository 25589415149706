import { Moment } from 'moment';

import {
  FichesInfractionAndFourriereRequestsWrapperDto,
  FicheFourriereRequestDto,
  FicheFourriereViolationDtoFormFieldEnum,
  GardienFourriereControllerApi,
} from 'lib_api/lib/api/gen';

import { FicheFourriereFormValues } from '../types';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { fetchFormViolations } from 'components/BaseForm/utils';

export function fourriereFormValuesToRequestWrapperDto(
  ficheFourriere: FicheFourriereFormValues,
  idCorrelationFourriere: string | null,
  dateConstatation: Moment | null,
  dateRedaction: Moment | null,
): FichesInfractionAndFourriereRequestsWrapperDto {
  return {
    dateConstatation: dateConstatation?.format(UNIX_TIMESTAMP_FORMAT) || null,
    dateRedaction: dateRedaction?.format(UNIX_TIMESTAMP_FORMAT) || null,
    ficheFourriereRequest: fourriereFormValuesToRequestDto(
      ficheFourriere,
      idCorrelationFourriere,
    ),
  };
}

export function fourriereFormValuesToRequestDto(
  ficheFourriere: FicheFourriereFormValues,
  idCorrelationFourriere: string | null,
): FicheFourriereRequestDto {
  return {
    dateEnlevement:
      ficheFourriere.dateEnlevement?.format(UNIX_TIMESTAMP_FORMAT) || null,
    dateEntree:
      ficheFourriere.dateEntree?.format(UNIX_TIMESTAMP_FORMAT) || null,
    idCorrelationFourriere: idCorrelationFourriere,
  };
}

export function useValidateFicheFourriereField(
  dateConstatation: Moment | undefined,
  dateRedaction: Moment | undefined,
): (
  field: FicheFourriereViolationDtoFormFieldEnum,
  formValues: FicheFourriereFormValues,
) => Promise<void> {
  const { GardienFourriereControllerApi: gfController } = useApi();
  const user = useUserStore();
  return (
    field: FicheFourriereViolationDtoFormFieldEnum,
    formValues: FicheFourriereFormValues,
  ) => {
    return validateFicheFourrierField(
      field,
      formValues,
      gfController,
      dateConstatation,
      dateRedaction,
      user.idCorrelationFourriere,
    );
  };
}

export async function validateFicheFourrierField(
  field: FicheFourriereViolationDtoFormFieldEnum,
  formValues: FicheFourriereFormValues,
  gfController: GardienFourriereControllerApi,
  dateConstatation: Moment | undefined,
  dateRedaction: Moment | undefined,
  idCorrelationFourriere: string | null,
): Promise<void> {
  return fetchFormViolations(
    () => {
      return gfController.validateFicheFourriereFieldWithoutDossierUsingPOST(
        field,
        fourriereFormValuesToRequestWrapperDto(
          formValues,
          idCorrelationFourriere,
          dateConstatation || null,
          dateRedaction || null,
        ),
      );
    },
    violationResponse => {
      return violationResponse.ficheFourriereViolationDtoList;
    },
    violation => {
      return violation.message;
    },
  );
}
