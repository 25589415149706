import { UserManual } from './types';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';

// Import const from .env file
import {
  AF_USER_MANUAL,
  FO_BDR_MANUAL,
  FO_USER_MANUAL,
  GF_USER_MANUAL,
} from './constants';
import { ManuelType, ManuelTypeEnum } from 'types/referentiels/Manuels';

/**
 * Return the user manuals for the given profile
 * User manuals are stored as front assets
 *
 * @param profile
 */
export function useGetManuals(profile: UserDtoProfileEnum): UserManual[] {
  function constructUserManual(
    userManualName: string | undefined,
    manuelType: ManuelType,
  ): UserManual[] {
    return userManualName
      ? [
          {
            title: userManualName,
            type: profile,
            manuelType: manuelType,
          },
        ]
      : [];
  }

  switch (profile) {
    case UserDtoProfileEnum.GARDIEN_FOURRIERE:
      return constructUserManual(GF_USER_MANUAL, ManuelTypeEnum.UTILISATEUR);

    case UserDtoProfileEnum.AUTORITE_FOURRIERE:
      return constructUserManual(AF_USER_MANUAL, ManuelTypeEnum.UTILISATEUR);

    case UserDtoProfileEnum.FORCE_DE_L_ORDRE:
      return [
        ...constructUserManual(FO_USER_MANUAL, ManuelTypeEnum.UTILISATEUR),
        ...constructUserManual(FO_BDR_MANUAL, ManuelTypeEnum.BDR),
      ];

    default:
      return [];
  }
}
