import React from 'react';

import { SupportedFile } from 'types/enums/SupportedFile';
import ResponsiveContainer from 'components/ResponsiveContainer';
import {
  importCsvReferentielNames,
  jsonReferentielNames,
} from 'utils/enumData';

import './ReferentielImport.less';
import ReferentielImportLayout from './ReferentielImportComponent/ReferentielImportLayout';
import {
  ReferentielEnumDtoImportReferentielEnumCSVEnum,
  ReferentielEnumDtoReferentialEnumJSONEnum,
} from 'lib_api/lib/api/gen';
import { useApi } from 'hooks/ApiStoreContext';

const ReferentielImport: React.FC = () => {
  const api = useApi().ReferentielControllerApi;
  return (
    <ResponsiveContainer className="ReferentielImport">
      <h1>Import des référentiels</h1>
      <p className="description">
        {`Il vous est possible d'importer de nouvelles versions des référentiels
        depuis cette page. Pour cela choisissez le type de référentiel que vous
        voulez modifier, vous pourrez alors choisir le fichier contenant les
        données du nouveau référentiel. Ce fichier doit être au format CSV ou
        JSON.`}
      </p>
      <ReferentielImportLayout
        title="Import des référentiels au format CSV"
        fileType={SupportedFile.CSV}
        referentiels={Object.values(
          ReferentielEnumDtoImportReferentielEnumCSVEnum,
        )}
        getName={(
          referentiel: ReferentielEnumDtoImportReferentielEnumCSVEnum,
        ) => {
          return importCsvReferentielNames[referentiel];
        }}
        importReferentiel={(
          file: Blob,
          referentiel: ReferentielEnumDtoImportReferentielEnumCSVEnum,
        ) => {
          return api.importCSVReferentielUsingPOST(referentiel, file);
        }}
      />
      <ReferentielImportLayout
        title="Import des référentiels au format JSON"
        fileType={SupportedFile.JSON}
        referentiels={Object.values(ReferentielEnumDtoReferentialEnumJSONEnum)}
        getName={(referentiel: ReferentielEnumDtoReferentialEnumJSONEnum) => {
          return jsonReferentielNames[referentiel];
        }}
        importReferentiel={(
          file: Blob,
          referentiel: ReferentielEnumDtoReferentialEnumJSONEnum,
        ) => {
          return api.importJSONReferentielUsingPOST(referentiel, file);
        }}
      />
    </ResponsiveContainer>
  );
};

export default ReferentielImport;
