import React, { useState } from 'react';

import {
  DossierResponseDto,
  ForceOrdreControllerApi,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { MainLevee } from './types';
import { convertMainLeveeToMainLeveeRequestDto } from './utils';

type SubmitFunction = {
  submit: (values: MainLevee) => void;
  inProgress: boolean;
};

function useSubmitMainlevee(
  controller: ForceOrdreControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: MainLevee) => {
      setInProgress(true);
      return controller
        .updateMainLeveeUsingPOST(
          dossierId,
          convertMainLeveeToMainLeveeRequestDto(values),
        )
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitMainlevee;
