import { useState, useEffect } from 'react';

import { ReferenceDtoTypeAutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefTypeAutoriteFourriere =
  | ReferenceDtoTypeAutoriteFourriereDto
  | undefined;

export const useRefTypesAutoriteFourriere = (): RefTypeAutoriteFourriere => {
  const behaviourOnError = useHandleBackErrors();
  const [refTypesAutoriteFourriere, setRefTypesAutoriteFourriere] =
    useState<RefTypeAutoriteFourriere>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refTypesAutoriteFourriere === undefined) {
      referentielControllerApi
        .getValidTypeAutoriteFourriereUsingGET()
        .then(fetchedRef => {
          setRefTypesAutoriteFourriere(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, refTypesAutoriteFourriere]);
  return refTypesAutoriteFourriere;
};
