import React from 'react';
import { Select } from 'components/WrappedComponents';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { statutFacturationEnumMapping } from 'utils/enumData';
import { DashboardAutoriteFourriereFilterForm } from 'pages/autorite-fourriere/Dashboard/types';
import { StatutTraitementFacturation } from 'hooks/dossiers/useSearchDossierSummaries';

interface SelectTraitementFacturationProps {
  values: Partial<DashboardAutoriteFourriereFilterForm>;
}

const selectItems =
  // List of enum options...
  [
    StatutTraitementFacturation.TRAITE,
    StatutTraitementFacturation.NON_TRAITE,
  ].map(enumValue => {
    return {
      value: enumValue.toString(),
      displayValue: statutFacturationEnumMapping[enumValue].label,
    };
  });

function SelectTraitementFacturation({
  values,
}: SelectTraitementFacturationProps): React.ReactElement {
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'statutsTraitementFacturation',
        itemProps: {
          label: 'Etat de facturation',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectTraitementFacturation;
