import React from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';

import { DownOutlined } from '@ant-design/icons';

import {
  ConditionsReactivateMotifMefDto,
  MotifMefDto,
} from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useModal } from 'hooks/modal/useModal';

import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation from '../Modals/ModalReactivation/ModalReactivation';
import ModalDetails from './Modals/ModalDetails';
import ModalUpdate from './Modals/ModalUpdate';
import ConditionReactivateMotifMef from './ConditionReactivateMotifMef/ConditionReactivateMotifMef';
import { useChangeValidityMotifMef } from './utils';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  motifMef: MotifMefDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  motifMef,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const modalDetails = useModal();
  const modalUpdate = useModal();
  const modalDeprecate = useModal();
  const controller = useApi().AdminFonctionnelControllerApi;

  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ] = useChangeValidityMotifMef(controller);

  const conditionUnlock = (
    conditions: ConditionsReactivateMotifMefDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item key="update" onClick={modalUpdate.open}>
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item key="deprecate" onClick={modalDeprecate.open}>
        {motifMef.enabled
          ? 'Désactiver le motif de MEF'
          : 'Réactiver le motif de MEF'}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <AntdButton onClick={modalDetails.open}>Détails</AntdButton>
      <ModalDetails
        motifMef={motifMef}
        visible={modalDetails.visible}
        close={modalDetails.close}
      />
      <ModalUpdate
        refreshDashboard={refreshDashboard}
        motifMef={motifMef}
        visible={modalUpdate.visible}
        close={modalUpdate.close}
      />
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {modalDeprecate.visible &&
        (motifMef.enabled ? (
          <ModalDesactivation
            idCorrelation={motifMef.idCorrelation}
            titleModal="Désactiver le motif de MEF"
            submitDeprecate={submitDeprecateReferentiel}
            visible={modalDeprecate.visible}
            close={modalDeprecate.close}
            callAfterSubmit={refreshDashboard}
            usesRequest={undefined}
            componentUses={undefined}
          />
        ) : (
          <ModalReactivation
            idCorrelation={motifMef.idCorrelation}
            titleModal="Réactiver le motif de MEF"
            visible={modalDeprecate.visible}
            close={modalDeprecate.close}
            callAfterSubmit={refreshDashboard}
            conditionRequest={conditionsReactivateReferentiel}
            componentConditions={ConditionReactivateMotifMef}
            conditionUnlock={conditionUnlock}
            reactivateRequest={submitReactivateReferentiel}
          />
        ))}
    </>
  );
}

export default CellDetailsAndActions;
