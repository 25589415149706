import React from 'react';
import { BorderedContent } from 'components/WrappedComponents';
import SuiviDossier from 'components/SuiviDossier';

import './SuiviFourriere.less';

export const SuiviFourriere = (): React.ReactElement => {
  return (
    <BorderedContent>
      <div className={'SuiviFourriere'}>
        <span>Suivi de mise en fourrière</span>
      </div>

      <SuiviDossier />
    </BorderedContent>
  );
};
