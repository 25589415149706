import React from 'react';
import { Modal as AntdModal } from 'antd';

import { MotifSortieDto } from 'lib_api/lib/api/gen';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

interface ModalDetailsProps {
  motifSortie: MotifSortieDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  motifSortie,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay
        label="Id de corrélation"
        value={motifSortie.idCorrelation}
      />
      <FieldDisplay label="Motif" value={motifSortie.motif} />
      <FieldDisplay
        label="Commentaire"
        value={motifSortie.commentaire}
        additionalValueClassName="multiLineValue"
      />
      <FieldDisplay
        label="Délai d'anonymisation (en mois)"
        value={motifSortie.delaiEffacementEnMois}
      />
    </AntdModal>
  );
}

export default ModalDetails;
