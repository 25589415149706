import { useCallback, useState } from 'react';
import { message as AntdMessage } from 'antd';

import { SortieVehicule } from 'types/SortieVehicule';
import { useHandleBackErrors } from '../utils/handleBackErrors';
import { useApi } from '../ApiStoreContext';
import { generateSortieVehiculeRequestDtoFromSortieVehicule } from 'pages/gardien-fourriere/Dossier/Components/SubForms/SortieVehiculeSubForm/utils';

/**
 * This hook is handled when the user submit a Sortie Vehicule form or want to validate fields
 */
export const useSubmitSortieVehicule = (): [
  boolean,
  (values: SortieVehicule, dossierId: string) => Promise<void>,
] => {
  const behaviourOnError = useHandleBackErrors();
  const gardienFourriereControllerApi = useApi().GardienFourriereControllerApi;
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const submitDossier = useCallback(
    (values: SortieVehicule, dossierId: string): Promise<void> => {
      const sortieVehiculeRequestDto =
        generateSortieVehiculeRequestDtoFromSortieVehicule(values);

      return gardienFourriereControllerApi
        .sortieVehiculeWithFormulaireSortieUsingPOST(
          dossierId,
          sortieVehiculeRequestDto,
        )
        .then(() => {
          void AntdMessage.success(
            'La sortie du véhicule a bien été enregistrée',
          );
          setShouldRedirect(true);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    },
    [behaviourOnError, gardienFourriereControllerApi],
  );

  return [shouldRedirect, submitDossier];
};
