import React from 'react';
import classNames from 'classnames';

import './BorderedContent.less';

interface BorderedContentProps {
  className?: string;
  children?: React.ReactNode;
}

export const BorderedContent = ({
  className,
  children,
}: BorderedContentProps): React.ReactElement => {
  return <div className={classNames('border', className)}>{children}</div>;
};
