import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import FieldWrapper from '../FieldWrapper';
import { WrappedProps } from '../types';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  inputProps: AntdInputProps;
}

function WrappedInput<T>({
  wrapperProps,
  inputProps,
}: Props<T>): React.ReactElement {
  const input = React.useMemo(() => {
    return <AntdInput {...inputProps} />;
  }, [inputProps]);
  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const value: unknown = formActions.getFieldValue(fieldKey);
  if (
    typeof value === 'string' ||
    typeof value === 'number' ||
    typeof value === 'undefined' ||
    value === null
  ) {
    const displayValue: { value?: string } = wrapperProps.itemProps
      .getValueProps
      ? wrapperProps.itemProps.getValueProps(value)
      : { value: value };
    return (
      <FieldWrapper
        {...wrapperProps}
        WrappedInput={input}
        ConsultationElement={displayValue.value}
      />
    );
  }
  return (
    <FieldWrapper
      {...wrapperProps}
      WrappedInput={input}
      ConsultationElement={
        <label>Type indéfini, veuillez contacter le support</label>
      }
    />
  );
}

export default WrappedInput;
