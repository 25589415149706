import React from 'react';
import { Link } from 'react-router-dom';
import { FaqDomainAdminDto } from 'lib_api/lib/api/gen';

import DragSortableTable from 'components/WrappedComponents/Table/DragSortableTable/DragSortableTable';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { faqPath } from 'pages/admin-fonctionnel/adminNavbarContent';
import CellDetailsAndActions from './CellDetailsAndActions';
import DashboardHeader from './DashboardHeader/DashboardHeader';
import { useReferentielDashboardWithoutArgument } from '../utils';
import { useSearchFaqDomain } from './utils';

function buildDetailsContent(
  data: FaqDomainAdminDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions domaine={data} refreshDashboard={refreshDashboard} />
  );
}

function DashboardFaqDomaine(): React.ReactElement {
  const controller = useApi().AdminFonctionnelControllerApi;
  const { fetchData, refreshDashboard, setFilter, filter } =
    useReferentielDashboardWithoutArgument(
      useSearchFaqDomain,
      {
        valid: true,
      },
      { position: true },
    );

  function buildColumns(): ColumnProps<FaqDomainAdminDto>[] {
    return [
      {
        title: 'Domaine',
        buildCellContent: data => (
          <Link to={`${faqPath}?idDomaine=${data.id}&nameDomaine=${data.name}`}>
            {data.name}
          </Link>
        ),
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  function changePosition(ordered: FaqDomainAdminDto[]) {
    controller
      .changeFaqDomainPositionUsingPOST(ordered.map(domain => domain.id))
      .then(refreshDashboard)
      .catch(e => backAlertMessage(e));
  }

  return (
    <div className="table-container">
      <DashboardHeader
        valid={filter.valid || false}
        setValid={valid => setFilter({ ...filter, valid })}
        refreshDashboard={refreshDashboard}
      />
      <DragSortableTable
        columns={buildColumns()}
        fetchData={fetchData}
        buildItemKey={item => item.id}
        antdTableProps={{
          tableLayout: 'fixed',
        }}
        onDragEnd={changePosition}
        disabled={!filter.valid}
      />
    </div>
  );
}
export default DashboardFaqDomaine;
