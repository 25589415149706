import React from 'react';
import classNames from 'classnames';
import { Input as AntdInput } from 'antd';
import { SearchProps as AntdSearchProps } from 'antd/lib/input';

import './SearchBar.less';

interface SearchBarProps {
  value: string | undefined;
  setValue: (value?: string) => void;
  antdProps?: AntdSearchProps;
  className?: string;
  rounded?: boolean;
}

function SearchBar({
  value,
  setValue,
  antdProps,
  className,
  rounded,
}: SearchBarProps): React.ReactElement {
  function onChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value === '' ? undefined : event.target.value);
  }

  return (
    <AntdInput.Search
      {...antdProps}
      value={value}
      onChange={onChange}
      className={classNames(
        rounded ? 'rounded-search-bar' : 'search-bar',
        className,
      )}
    />
  );
}

export default SearchBar;
