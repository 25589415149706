import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';
import { ActionsRequisesDtoActionsRequisesEnum } from 'lib_api/lib/api/gen';

interface FOFilterContextContent {
  filter: Partial<DashboardForcesOrdreFilterForm>;
  setFilter: Dispatch<SetStateAction<Partial<DashboardForcesOrdreFilterForm>>>;
  // all actions requises configured for fo users
  actionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined;
}

const FOFilterContext = createContext<FOFilterContextContent | null>(null);

export const useFOFilterContext = (): [
  Partial<DashboardForcesOrdreFilterForm>,
  Dispatch<SetStateAction<Partial<DashboardForcesOrdreFilterForm>>>,
  ActionsRequisesDtoActionsRequisesEnum[] | undefined,
] => {
  const context = useContext(FOFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter, context.actionsRequises];
};

const FOFilterProvider = FOFilterContext.Provider;

export default FOFilterProvider;
