import React from 'react';

import {
  NotificationInfosRequestDto,
  NotificationInfosViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { DatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import {
  NotificationInfosFieldsProps,
  NotificationInfos,
  validateNotificationInfosRule,
  DATE_DE_RETOUR,
  notificationManuelleInfosEnumMapping,
} from '../utils';
import { DATE_FORMAT } from 'utils/formats';
import { useApi } from 'hooks/ApiStoreContext';
import { CSSUtils } from 'utils/CSSUtils';
import { generateNotificationInfosRequestDto } from 'pages/forces-de-l-ordre/PageDossier/Actions/InfosNotification/utils';

function DatePickerRetour({
  values,
  dossierId,
}: NotificationInfosFieldsProps): React.ReactElement {
  const foController = useApi().ForceOrdreControllerApi;
  const key: keyof NotificationInfos = 'dateRetour';
  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label:
            values.etatDistribution === undefined
              ? DATE_DE_RETOUR
              : notificationManuelleInfosEnumMapping[values.etatDistribution]
                  .libelleDateRetour,
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                const sortieVehiculeRequestDto: NotificationInfosRequestDto =
                  generateNotificationInfosRequestDto({
                    ...values,
                    [key]: value,
                  });
                return await validateNotificationInfosRule(
                  dossierId,
                  foController,
                  sortieVehiculeRequestDto,
                  NotificationInfosViolationDtoFormFieldEnum.DATE_RETOUR,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      datePickerProps={{
        style: { width: '100%' },
        placeholder: FormPlaceholders.Date,
        format: DATE_FORMAT,
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DatePickerRetour;
