import { useState, useEffect, useCallback } from 'react';

import { popErrorMessage } from '../utils/backAlertMessage';

type Timeout = ReturnType<typeof setTimeout>;
type ApiGouvFetcher = (str: string) => void;

export function useSearchApiGouv<ApiGouvOption, FetchResult>(
  initial: ApiGouvOption,
  getSearchField: (option: ApiGouvOption) => string,
  buildUrl: (option: ApiGouvOption) => string,
  buildEmptyFromSearch: (search: string) => ApiGouvOption,
  mapFetchResultTopOption: (option: FetchResult) => ApiGouvOption[],
): [Array<ApiGouvOption>, boolean, ApiGouvFetcher] {
  const [options, setOptions] = useState<Array<ApiGouvOption>>([]);
  const [value, setValue] = useState<ApiGouvOption>(initial);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (getSearchField(value) === '') {
      setOptions([]);
      setIsFetching(false);
      return;
    }

    const fetchResults = () => {
      fetch(buildUrl(value))
        .then((response: Response) => {
          return response.json();
        })
        .then((fetchResult: FetchResult) => {
          if (timerID !== null) {
            setOptions(mapFetchResultTopOption(fetchResult));
          }
        })
        .catch(reason => {
          if (timerID !== null) {
            // Nous n'affichons pas cette erreur à l'utilisateur car nous ne maitrisons pas l'api Geo Gouv
            console.error(
              `Une erreur lors de la récupération des données d'autocomplétion est survenue : ${reason}`,
            );
          }
        })
        .finally(() => {
          setIsFetching(false);
        });
    };

    let timerID: Timeout | null = setTimeout(fetchResults, 500);

    return () => {
      if (timerID !== null) {
        clearTimeout(timerID);
        timerID = null;
      }
    };
  }, [value]);

  const fetchOptions = useCallback((str: string) => {
    setIsFetching(true);
    setValue(buildEmptyFromSearch(str));
    setOptions([]);
  }, []);

  return [options, isFetching, fetchOptions];
}
