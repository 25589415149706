import React from 'react';
import {
  ForceOrdreControllerApi,
  AutoriteFourriereControllerApi,
} from 'lib_api/lib/api/gen';

import { CoordonneesProprietaire } from '../types';

import { useComputeImpossibiliteNotifier } from 'hooks/dossiers/useComputeImpossibiliteNotifier';

import './ImpossibiliteNotifierSourcesDisplay.less';

interface DisplaySourcesProps {
  values: CoordonneesProprietaire;
  dossierId: string;
  controller: AutoriteFourriereControllerApi | ForceOrdreControllerApi;
}

const ImpossibiliteNotifierSourcesDisplay = ({
  dossierId,
  controller,
  values,
}: DisplaySourcesProps): React.ReactElement => {
  const sources = useComputeImpossibiliteNotifier(
    controller,
    dossierId,
    values,
  )?.sources;

  return (
    <>
      {sources && sources.length > 0 && (
        <div className="impossibiliteNotifierText">
          Les coordonnées saisies ne sont en l&lsquo;état pas suffisantes pour
          notifier le propriétaire par courrier.
          <br />
          Les informations requises et manquantes dans le cas présent sont :
          <ul>
            {sources.map((source, key) => (
              <li key={key}>{source.message}</li>
            ))}
          </ul>
          <p>
            Merci de les compléter si vous en avez connaissance, dans le cas
            contraire, ne rien saisir dans les champs précités.
          </p>
        </div>
      )}
    </>
  );
};

export default ImpossibiliteNotifierSourcesDisplay;
