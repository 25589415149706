import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

import {
  FicheInfractionViolationDtoFormFieldEnum,
  AutoritePrescriptriceDtoQualiteAutoriteEnum,
  AutocompleteUniteFOResultDtoFieldEnum,
  BrancheDtoBrancheEnum,
} from 'lib_api/lib/api/gen';

import BaseForm from 'components/BaseForm/BaseForm';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { fetchAutoriteFourriereOfFourriere } from 'search/searchAutoriteFourriere';
import {
  autoriteFourriereDtoToString,
  motifMefDtoToString,
} from 'utils/dtoToString';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import BaseInputNumber from 'components/BaseForm/BaseInputNumber';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import { fetchMotifMefList } from 'search/searchMotifMef';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import TextArea from 'antd/lib/input/TextArea';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import BaseDateTimePicker from 'components/BaseForm/BaseDateTimePicker';
import { FicheInfractionFormValues } from '../types';
import { useValidateFicheInfractionField } from './utils';
import AutocompleteCommune from 'components/BaseForm/Autocomplete/AutocompleteCommune';
import { qualiteAutoriteMapping } from 'utils/enumData';

interface FicheInfractionFormProps {
  goNextForm: () => void;
  initialValues: FicheInfractionFormValues;
  setValues: (newData: FicheInfractionFormValues) => void;
}

export default function FicheInfractionForm({
  goNextForm,
  initialValues,
  setValues,
}: FicheInfractionFormProps): React.ReactElement {
  const user = useUserStore();
  const userIdFourriere = user.idCorrelationFourriere;
  if (!userIdFourriere) {
    throw new Error('id correlation fourrière should be defined');
  }
  const validateField = useValidateFicheInfractionField(userIdFourriere);

  const { ReferentielControllerApi: refController } = useApi();

  return (
    <BaseForm
      initialValues={initialValues}
      onChange={(changed, all) => {
        const newValue: FicheInfractionFormValues = {
          ...all,
          idMotifMef:
            changed.lieuPrive !== undefined ? undefined : all.idMotifMef,
        };

        setValues(newValue);
        return newValue;
      }}
      validateField={validateField}
      inputs={[
        {
          label: 'Autorité de fourrière',
          name: 'idCorrelationAutoriteFourriere',
          required: true,
          field: FicheInfractionViolationDtoFormFieldEnum.AUTORITE_FOURRIERE,
          render: () => {
            return (
              <AsyncSelect
                fetchOptions={() => {
                  return fetchAutoriteFourriereOfFourriere(
                    refController,
                    userIdFourriere,
                  );
                }}
                getOptionLabel={autorite =>
                  autoriteFourriereDtoToString(autorite)
                }
                getOptionValue={autorite => autorite.idCorrelation}
              />
            );
          },
        },
        {
          label: "Date de constatation de l'infraction",
          name: 'dateConstatation',
          required: true,
          field: FicheInfractionViolationDtoFormFieldEnum.DATE_CONSTATATION,
          render: () => {
            return <BaseDatePicker />;
          },
        },
        {
          label: "Nom de l'autorité ayant prescrit la mesure",
          field:
            FicheInfractionViolationDtoFormFieldEnum.NOM_AUTORITE_PRESCRIPTRICE,
          name: 'nomAutoritePrescriptrice',
          required: true,
        },
        {
          label: "Qualité de l'autorité",
          field:
            FicheInfractionViolationDtoFormFieldEnum.QUALITE_AUTORITE_PRESCRIPTRICE,
          name: 'qualiteAutorite',
          required: true,
          render: () => {
            return (
              <BaseSelect
                options={Object.values(
                  AutoritePrescriptriceDtoQualiteAutoriteEnum,
                ).map(qualite => {
                  return {
                    value: qualite,
                    displayValue: qualiteAutoriteMapping[qualite].label,
                  };
                })}
                getOptionLabel={option => option.displayValue}
                getOptionValue={option => option.value}
              />
            );
          },
        },
        {
          label: "Nom de l'agent",
          field: FicheInfractionViolationDtoFormFieldEnum.NOM_AGENT,
          name: 'nomAgent',
          required: true,
        },
        {
          label: 'Identifiant / NIGEND',
          field: FicheInfractionViolationDtoFormFieldEnum.NIGEND,
          name: 'nigend',
          render: () => {
            return (
              <BaseInputNumber
                placeholder={FormPlaceholders.Figures}
                minLength={6}
                maxLength={10}
              />
            );
          },
        },
        {
          label: 'Unité',
          field: FicheInfractionViolationDtoFormFieldEnum.UNITE,
          name: 'uniteFo',
          required: true,
          render: () => {
            return (
              <BaseSelectWithSearch
                useSearchEntities={useAsyncSearchUniteFo(
                  AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                )}
                getOptionValue={unite => unite.idCorrelation}
                getOptionLabel={unite => unite.abreviation}
                dependencies={[
                  {
                    getValue: unite => unite.email ?? undefined,
                    name: 'emailUniteFo',
                  },
                ]}
              />
            );
          },
        },
        {
          label: 'Adresse électronique',
          field: FicheInfractionViolationDtoFormFieldEnum.MAIL_UNITE,
          name: 'emailUniteFo',
        },
        {
          label: 'Lieu privé',
          field: FicheInfractionViolationDtoFormFieldEnum.LIEU_PRIVE,
          name: 'lieuPrive',
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: 'Motivation de la mesure',
          name: 'idMotifMef',
          required: true,
          field: FicheInfractionViolationDtoFormFieldEnum.MOTIVATION_MESURE,
          render: () => {
            return (
              <AsyncSelect
                fetchOptions={() => {
                  return fetchMotifMefList(refController);
                }}
                getOptionLabel={motif => motifMefDtoToString(motif)}
                getOptionValue={motif => motif.id}
                dependencies={[
                  {
                    getValue: motif =>
                      motif.branche.branche ===
                      BrancheDtoBrancheEnum.MAITRE_LIEU,
                    name: 'lieuPrive',
                  },
                ]}
                filterOptions={(
                  formValues: FicheInfractionFormValues,
                  options,
                ) => {
                  return options.filter(option => {
                    return formValues.lieuPrive
                      ? option.branche.branche ===
                          BrancheDtoBrancheEnum.MAITRE_LIEU
                      : true;
                  });
                }}
                showSearch
              />
            );
          },
        },
        {
          label: "Lieu d'enlèvement",
          required: true,
          name: 'adresseEnlevement',
          field: FicheInfractionViolationDtoFormFieldEnum.LIEU_ENLEVEMENT,
          render: ({ name }) => {
            return (
              <AutocompleteAdresse
                name={name}
                communeField="communeEnlevement"
              />
            );
          },
        },
        {
          label: 'Commune',
          required: true,
          field: FicheInfractionViolationDtoFormFieldEnum.COMMUNE,
          name: 'communeEnlevement',
          normalize: (value: string) => value.toUpperCase(),
          render: ({ name }) => {
            return <AutocompleteCommune name={name} />;
          },
        },
        {
          label: 'Nuit',
          field: FicheInfractionViolationDtoFormFieldEnum.NUIT,
          name: 'nuit',
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: 'Pluie ou neige',
          field: FicheInfractionViolationDtoFormFieldEnum.PLUIE_NEIGE,
          name: 'pluieOuNeige',
          valuePropName: 'checked',
          render: () => {
            return <AntdCheckbox />;
          },
        },
        {
          label: 'Autorité qualifiée pour lever la mesure',
          field:
            FicheInfractionViolationDtoFormFieldEnum.AUTORITE_POUR_LEVER_MESURE,
          name: 'autoriteLeverMesure',
          render: () => {
            return <TextArea placeholder={FormPlaceholders.Input} />;
          },
        },
        {
          label: "Date et heure d'établissement de la fiche",
          field: FicheInfractionViolationDtoFormFieldEnum.DATE_REDACTION,
          name: 'dateRedaction',
          render: () => {
            return <BaseDateTimePicker />;
          },
        },
      ]}
      onSubmit={values => {
        setValues(values);
        goNextForm();
      }}
      submitText="Suivant"
    />
  );
}
