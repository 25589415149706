import React from 'react';
import { FormInstance as AntdFormInstance } from 'antd/lib/form';

const BaseFormContext = React.createContext<AntdFormInstance | null>(null);

export function useBaseFormContext<FormValues>(): AntdFormInstance<FormValues> {
  const context = React.useContext(BaseFormContext);
  if (context === null) {
    throw new Error(
      'Context should not be null, you can only call this hook inside BaseForm component',
    );
  }
  return context;
}

const BaseFormProvider = BaseFormContext.Provider;
export default BaseFormProvider;
