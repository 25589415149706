import React from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

import { Button, Col as AntdCol, Row as AntdRow } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useUserStore } from 'hooks/UserStoreContext';
import {
  LABEL_DONNEE_EXPIREE,
  LABEL_IMMAT_MANQUANTE,
  LABEL_SANS_PLAQUE,
} from 'utils/global';

import { TraitementFacturation } from './TraiteEnFacturation';
import { buildStatutContent } from './utils';

import './SuiviVehiculeMainInfo.less';

interface SuiviVehiculeMainInfoProps {
  additionalClassName?: string;
}

export const SuiviVehiculeMainInfo = ({
  additionalClassName,
}: SuiviVehiculeMainInfoProps): React.ReactElement => {
  const navigate = useNavigate();
  const user = useUserStore();
  const [dossier] = useDossierContext();

  return (
    <div className={classNames('suiviContainer', additionalClassName)}>
      <Button
        className="return-button"
        icon={<ArrowLeftOutlined />}
        onClick={(): void => navigate(-1)}
        type="default"
        shape="round"
        size="middle"
      >
        {'Retour'}
      </Button>
      <AntdRow gutter={10}>
        <AntdCol className="label-col" span={8}>
          <span className="label">Véhicule :</span>
        </AntdCol>
        <AntdCol className="field-col">
          <span className={'immatriculation'}>
            {dossier.body?.anonymise ? (
              <>{LABEL_DONNEE_EXPIREE}</>
            ) : (
              <>
                {dossier.body?.ficheVehicule?.immatriculation?.sansPlaque
                  ? LABEL_SANS_PLAQUE
                  : dossier.body?.ficheVehicule?.immatriculation?.numero ||
                    LABEL_IMMAT_MANQUANTE}
                {dossier.body?.ficheVehicule?.immatriculation
                  ?.numeroImmatriculationVisible
                  ? ' [ ' +
                    dossier.body?.ficheVehicule?.immatriculation
                      ?.numeroImmatriculationVisible +
                    ' ]'
                  : ''}
              </>
            )}
          </span>
        </AntdCol>
      </AntdRow>
      <AntdRow gutter={10}>
        <AntdCol className="label-col" span={8}>
          <span className="label">Statut :</span>
        </AntdCol>
        <AntdCol className="field-col">
          <span className={'status'}>{buildStatutContent(dossier)}</span>
        </AntdCol>
      </AntdRow>
      <TraitementFacturation dossier={dossier} profile={user.profile} />
    </div>
  );
};
