import React from 'react';

import { ReferentielUsesDto } from 'lib_api/lib/api/gen';
import { buildUsesInDossierMessage } from '../../Modals/ModalDesactivation/utils';

interface UsesFourriereProps {
  uses: ReferentielUsesDto;
}

function UsesFourriere({ uses }: UsesFourriereProps): React.ReactElement {
  const listDossierId = uses.searchDossierResult?.listDossierId ?? [];
  return (
    <ul>
      {listDossierId.length > 0 && (
        <li>
          Cette fourrière est utilisée dans les dossiers{' '}
          {buildUsesInDossierMessage(uses.searchDossierResult)}
        </li>
      )}
    </ul>
  );
}

export default UsesFourriere;
