import React from 'react';
import { Modal as AntdModal } from 'antd';

import { AutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

import './ModalDetails.less';

interface ModalDetailsProps {
  autorite: AutoriteFourriereDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  autorite,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id de corrélation" value={autorite.idCorrelation} />
      <FieldDisplay
        label="Type d'autorité de fourrière"
        value={autorite.type.type}
      />
      <FieldDisplay label="Libellé" value={autorite.libelleComplet} />
      <FieldDisplay
        label="Libellé de département de l'autorité"
        value={autorite.libelleDepartement}
      />
      <FieldDisplay
        label="Numéro de département"
        value={autorite.numeroDepartement}
      />
      <FieldDisplay label="Numéro de voie" value={autorite.numeroVoie} />
      <FieldDisplay label="Libellé de voie" value={autorite.voie} />
      <FieldDisplay
        label="Complément d'adresse"
        value={autorite.complementAdresse}
      />
      <FieldDisplay label="Ville" value={autorite.ville} />
      <FieldDisplay label="Code postal" value={autorite.codePostal} />
      <FieldDisplay label="Cedex" value={autorite.cedex} />
      <FieldDisplay label="Code INSEE" value={autorite.codeInsee} />
      <FieldDisplay label="Id Hermes" value={autorite.idHermes} />
      <FieldDisplay
        label="Id Hermes utilisateur remettant"
        value={autorite.idHermesUtilisateur}
      />
    </AntdModal>
  );
}

export default ModalDetails;
