import React from 'react';
import { Select } from 'components/WrappedComponents';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';
import { useSelectOptionsUniteFo } from 'utils/selectOptions/selectOptionsUniteFo';

interface SelectUniteFoProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

function SelectUniteFo({ values }: SelectUniteFoProps): React.ReactElement {
  const uniteFoList = useSelectOptionsUniteFo();
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'listUniteFo',
        itemProps: {
          label: 'Unités du périmètre',
          hasFeedback: false,
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={uniteFoList}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectUniteFo;
