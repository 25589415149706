import {
  AutoriteFourriereControllerApi,
  AutoriteFourriereDto,
  ProfilAutoriteFourriereRequestDto,
  ProfilAutoriteFourriereViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';

import { FicheProfilAutoriteFourriere } from './types';

export function ficheProfilFromDto(
  af: AutoriteFourriereDto,
): FicheProfilAutoriteFourriere {
  return {
    idTypeAutoriteFourriere: af.type.idCorrelation,
    codePostal: ('00000' + af.codePostal).substr(-5, 5),
    commune: af.ville,
    numeroVoie: af.numeroVoie || undefined,
    voie: af.voie,
    complementAdresse: af.complementAdresse || undefined,
    cedex: af.cedex || undefined,
    intituleComplet: af.libelleComplet,
  };
}

export function requestDtoFromFicheProfil(
  ficheProfil: FicheProfilAutoriteFourriere,
): ProfilAutoriteFourriereRequestDto {
  return {
    cedex: ficheProfil.cedex || null,
    codePostal: ficheProfil.codePostal || null,
    complementAdresse: ficheProfil.complementAdresse || null,
    numeroVoie: ficheProfil.numeroVoie || null,
    commune: ficheProfil.commune || null,
    voie: ficheProfil.voie || null,
  };
}

export async function validateProfilAutoriteFourriereField(
  controller: AutoriteFourriereControllerApi,
  field: ProfilAutoriteFourriereViolationDtoFormFieldEnum,
  idCorrelationAutoriteFourriere: string,
  values: FicheProfilAutoriteFourriere,
): Promise<void> {
  try {
    const response =
      await controller.validateAutoriteFourriereInfosFieldUsingPOST(
        field,
        idCorrelationAutoriteFourriere,
        requestDtoFromFicheProfil(values),
      );
    if (response.violationDtos) {
      const fieldViolations = response.violationDtos.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

export async function validateAndSubmitAutoriteFourriere(
  controller: AutoriteFourriereControllerApi,
  idCorrelationAutoriteFourriere: string,
  values: FicheProfilAutoriteFourriere,
): Promise<AutoriteFourriereDto | undefined> {
  const requestDto = requestDtoFromFicheProfil(values);

  try {
    const response = await controller.validateAutoriteFourriereInfosUsingPOST(
      idCorrelationAutoriteFourriere,
      requestDto,
    );

    if (
      response.violationDtos !== null &&
      response.violationDtos.length === 0
    ) {
      const updatedAF = await controller.updateAutoriteFourriereInfoUsingPOST(
        idCorrelationAutoriteFourriere,
        requestDto,
      );
      return Promise.resolve(updatedAF);
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
}
