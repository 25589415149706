import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useApi } from '../ApiStoreContext';
import { DossierResponseDto } from 'lib_api/lib/api/gen/api';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type DossierType = DossierResponseDto | null;

export const useFetchDossier = (
  id: string,
): [DossierType, Dispatch<SetStateAction<DossierType>>] => {
  const behaviourOnError = useHandleBackErrors();
  const [dossier, setDossier] = useState<DossierType>(null);
  const controllerApi = useApi().DossierControllerApi;

  useEffect(() => {
    controllerApi.getByIdUsingGET(id).then(setDossier).catch(behaviourOnError);
  }, [controllerApi, id, behaviourOnError]);

  return [dossier, setDossier];
};
