import React from 'react';
import { OptionProps } from 'types/searchResult';
import { MotifSortieDto } from 'lib_api/lib/api/gen';
import InternalSelectMotifSortie from './InternalSelectMotifSortie';
import { SelectMotifSortieProps } from './type';

function mapMotifSortieToSelectValueUsingId(
  motifSortie: MotifSortieDto,
): OptionProps {
  return {
    value: motifSortie.id,
    displayValue: motifSortie.motif,
  };
}

function SelectMotifSortieUsingId<FormValues>({
  values,
  fieldKey,
  validator,
  mode,
}: SelectMotifSortieProps<FormValues>): React.ReactElement {
  return (
    <InternalSelectMotifSortie
      values={values}
      fieldKey={fieldKey}
      validator={validator}
      mode={mode}
      mapMotifSortieToSelectValue={mapMotifSortieToSelectValueUsingId}
    />
  );
}

export default SelectMotifSortieUsingId;
