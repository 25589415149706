import { ReferenceDtoEtatDto } from 'lib_api/lib/api/gen';

// 'Rodéo (L236-1) - Procédure judiciaire' => 'Rodéo (L236-1)'
export function stripNatureLibelle(motifMef?: string): string | undefined {
  // motif mef finishes on a line number, maybe followed by a ')'
  const motifMefRegex = /.*\d\)?/;
  return motifMef?.match(motifMefRegex)?.[0];
}

export function shouldShowDetail(
  etatDetails: ReferenceDtoEtatDto | undefined,
): boolean {
  if (etatDetails === undefined) {
    return false;
  }
  return (
    etatDetails.referenceDtoList.find(ref => ref.detail !== null) !== undefined
  );
}
