import React from 'react';
import { Alert as AntdAlert } from 'antd';

import {
  AutoriteFourriereDto,
  ConditionsReactivateAutoriteFourriereDto,
} from 'lib_api/lib/api/gen/api';

import './ConditionReactivateAutoriteFourriere.less';

interface ConditionReactivateAutoriteFourriereProps {
  conditions: ConditionsReactivateAutoriteFourriereDto | undefined;
  autorite: AutoriteFourriereDto;
}

function ConditionReactivateAutoriteFourriere({
  conditions,
  autorite,
}: ConditionReactivateAutoriteFourriereProps): React.ReactElement {
  return (
    <>
      {conditions?.typeValid === false && (
        <AntdAlert
          type="warning"
          className="alertTypeInvalid"
          message={
            'Le type ' +
            autorite.type.type +
            " de l'autorité  n'est pas actif. Vous devez l'activer pour activer cette autorité de fourrière."
          }
        />
      )}
      {conditions?.alreadyExistsLibelle === true && (
        <AntdAlert
          type="warning"
          className="alertTypeInvalid"
          message={
            'Le libellé ' +
            autorite.libelleComplet +
            ' est déjà utilisé pour une AF active. Vous devez la désactiver pour pouvoir activer cette autorité.'
          }
        />
      )}
      {conditions?.alreadyExistsType === true && (
        <AntdAlert
          type="warning"
          className="alertTypeInvalid"
          message={
            'Une AF de ce type existe déjà à ' +
            autorite.ville +
            '. Vous devez la désactiver pour pouvoir activer cette autorité.'
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateAutoriteFourriere;
