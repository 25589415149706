import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen';
import { useState } from 'react';

interface SubmitAnnulationSortieResult {
  submitAnnulation: () => void;
  loading: boolean;
}

export function useSubmitAnnulationSortie(
  dossierId: string,
  onSuccess: (dossier: DossierDtoAdminFonctionnel) => void,
): SubmitAnnulationSortieResult {
  const [loading, setLoading] = useState<boolean>(false);
  const behaviourOnError = useHandleBackErrors();
  const { AdminFonctionnelControllerApi: controller } = useApi();
  const submit = () => {
    setLoading(true);
    controller
      .annulationSortieVehiculeUsingPUT(dossierId)
      .then(dossierDto => {
        onSuccess(dossierDto);
      })
      .catch((error: Response) => {
        behaviourOnError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    submitAnnulation: submit,
    loading: loading,
  };
}
