import {
  SortieVehiculeResponseDtoStatutFacturationEnum,
  UserDtoProfileEnum,
} from 'lib_api/lib/api/gen';

export function getDisplayableStatutFacturation(
  statutFacturation?: SortieVehiculeResponseDtoStatutFacturationEnum | null,
): string | null {
  switch (statutFacturation) {
    case SortieVehiculeResponseDtoStatutFacturationEnum.TRAITE:
      return 'Traité';
    case SortieVehiculeResponseDtoStatutFacturationEnum.NON_TRAITE:
      return 'Non traité';
    default:
      return null;
  }
}

export function isStatutFacturationVisibleForProfile(
  profile: UserDtoProfileEnum,
): boolean {
  return [UserDtoProfileEnum.AUTORITE_FOURRIERE].includes(profile);
}
