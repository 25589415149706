import React from 'react';

import { SuiviVehiculeMainInfo } from 'components/FicheDescriptiveDisplay/SuiviVehiculeMainInfo/SuiviVehiculeMainInfo';

import './FicheDescriptiveHeader.less';

interface FicheDescriptiveHeaderProps {
  children: React.ReactNode;
}

export const FicheDescriptiveHeader = ({
  children,
}: FicheDescriptiveHeaderProps): React.ReactElement => {
  return (
    <div>
      <span className={'header'}>
        <SuiviVehiculeMainInfo additionalClassName={'mainInfo'} />
        {children}
      </span>
    </div>
  );
};
