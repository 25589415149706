import React, { useMemo } from 'react';

import { UniteFOAdminResponseDto } from 'lib_api/lib/api/gen';
import { MigrateDossierFormValues } from './types';

function useInitialValues(
  unite: UniteFOAdminResponseDto | null,
  nbDossiers: number | null,
): MigrateDossierFormValues {
  return useMemo(() => {
    return {
      idCorrelationOldUnite: unite?.idCorrelation,
      abreviationOldUnite: unite?.abreviation,
      nbDossiers: nbDossiers ?? undefined,
      idCorrelationNewUnite: undefined,
      comment: undefined,
    };
  }, [nbDossiers]);
}

export default useInitialValues;
