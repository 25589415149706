import React from 'react';

import Spinner from 'components/WrappedComponents/Spinner';
import { EmptyOnError } from 'components/WrappedComponents';

interface AsyncComponentProps {
  render: () => React.ReactElement | null;
  inProgress: boolean;
  errorOccured?: boolean;
  reload: () => void;
  errorMessage?: string;
}

function AsyncComponent({
  render,
  inProgress,
  errorOccured,
  reload,
  errorMessage,
}: AsyncComponentProps): JSX.Element {
  return errorOccured ? (
    <EmptyOnError reload={reload} description={errorMessage} />
  ) : inProgress ? (
    <Spinner />
  ) : (
    render() ?? <></>
  );
}

export default AsyncComponent;
