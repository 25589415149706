import {
  MarqueDto,
  MarqueDtoCategorieEnum,
  MarqueDtoTypeEnum,
} from 'lib_api/lib/api/gen';
import { BaseOptionType } from 'components/BaseForm/Select/BaseSelect';

export enum MarqueSort {
  'LIBELLE' = 'LIBELLE',
  'CATEGORIE' = 'CATEGORIE',
  'TYPE' = 'TYPE',
}

export type MarqueDtoFormValues = {
  libelle?: string;
  categorie?: MarqueDtoCategorieEnum;
  type?: MarqueDtoTypeEnum;
  marqueExacte?: BaseOptionType<MarqueDto>;
};
