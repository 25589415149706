import React, { ReactElement } from 'react';
import { Button as AntdButton } from 'antd';
import classNames from 'classnames';

import { DonneesAControlerResponseDto } from 'lib_api/lib/api/gen';

import {
  LABEL_MARQUE_NON_REFERENCEE,
  LABEL_MODELE_NON_REFERENCE,
  LABEL_NON_RENSEIGNE,
  LABEL_SANS_PLAQUE,
} from 'utils/global';
import {
  buildInitialValues,
  ControleDonneesVehiculeFormValues,
} from '../utils';

import './ComparaisonSivFd.less';

interface ComparaisonSivFdProps {
  onClose: () => void;
  openUpdateForm: () => void;
  donneesAControler: DonneesAControlerResponseDto | null;
  submit: (values: ControleDonneesVehiculeFormValues) => void;
  submitInProgress: boolean;
}

const ComparaisonSivFd: React.FC<ComparaisonSivFdProps> = ({
  onClose,
  openUpdateForm,
  donneesAControler,
  submit,
  submitInProgress,
}: ComparaisonSivFdProps): ReactElement => {
  return (
    <div className="comparaisonSivFd">
      <p>
        Un doute sur la fiabilité des données du dossier a été émis suite à la
        comparaison des données issues de la fiche descriptive du véhicule avec
        celles lui étant associées dans le SIV.{' '}
        <b>
          Veuillez vérifier les informations de la fiche véhicule, et les
          corriger si nécessaire.
        </b>
      </p>
      <div
        className={classNames('comparaisonSivFdData', {
          withoutFaussePlaque:
            !donneesAControler?.donneesFd?.plaqueImmatriculationVisible,
        })}
      >
        <div className="nomChamps">
          {donneesAControler?.donneesFd?.plaqueImmatriculationVisible && (
            <p>
              Faux numéro d&apos;identification ou d&apos;immatriculation
              (visible) :
            </p>
          )}
          <p>Numéro d&apos;identification ou d&apos;immatriculation :</p>
          <p>Numéro VIN :</p>
          <p>Marque :</p>
          <p>Modèle :</p>
        </div>
        <div className="encart donneesFd">
          <p>FICHE DESCRIPTIVE</p>
          {donneesAControler?.donneesFd?.plaqueImmatriculationVisible && (
            <p>{donneesAControler.donneesFd.plaqueImmatriculationVisible}</p>
          )}
          <p>
            {donneesAControler?.donneesFd?.plaqueImmatriculation ||
              LABEL_SANS_PLAQUE}
          </p>
          <p>{donneesAControler?.donneesFd?.vin || LABEL_NON_RENSEIGNE}</p>
          <p>
            {donneesAControler?.donneesFd?.marque?.libelle ||
              LABEL_MARQUE_NON_REFERENCEE}
          </p>
          <p>
            {donneesAControler?.donneesFd?.modele?.libelle ||
              LABEL_MODELE_NON_REFERENCE}
          </p>
          <AntdButton
            type="primary"
            className="button"
            onClick={openUpdateForm}
          >
            Modifier
          </AntdButton>
        </div>
        <div className="encart donneesSiv">
          <p>SIV</p>
          {donneesAControler?.donneesFd?.plaqueImmatriculationVisible && (
            <p>N/A</p>
          )}
          <p>
            {donneesAControler?.donneesSiv?.plaqueImmatriculation ||
              LABEL_NON_RENSEIGNE}
          </p>
          <p>{donneesAControler?.donneesSiv?.vin || LABEL_NON_RENSEIGNE}</p>
          <p>
            {donneesAControler?.donneesSiv?.marque?.libelle ||
              LABEL_NON_RENSEIGNE}
          </p>
          <p>
            {donneesAControler?.donneesSiv?.modele?.libelle ||
              LABEL_NON_RENSEIGNE}
          </p>
        </div>
      </div>
      <div className="buttonsContainer">
        <AntdButton onClick={onClose}>Annuler</AntdButton>
        <AntdButton
          type="primary"
          disabled={submitInProgress}
          onClick={() => submit(buildInitialValues(donneesAControler))}
        >
          Valider les données de la FD
        </AntdButton>
      </div>
    </div>
  );
};

export default ComparaisonSivFd;
