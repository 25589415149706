import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal as AntdModal } from 'antd';
import { Form } from 'components/WrappedComponents';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import {
  DatePickerEnvoi,
  DatePickerRetour,
  SelectEtatDistribution,
} from './Fields';
import {
  generateNotificationInfosFromDossier,
  generateNotificationInfosRequestDto,
  NotificationInfos,
  useSubmitNotificationInfos,
  useValidateNotificationInfos,
} from './utils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

interface ActionSaisieInfoNotificationProps {
  close: () => void;
  visible: boolean;
}

export const ActionSaisieInfoNotification = ({
  close,
  visible,
}: ActionSaisieInfoNotificationProps): React.ReactElement => {
  const [dossier, setDossier] = useDossierContext();
  const defaultValue = useMemo(
    () => generateNotificationInfosFromDossier(dossier),
    [dossier],
  );
  const [values, setValues] = useState<NotificationInfos>(defaultValue);
  const [updatedDossier, submitForm, thenSubmmit, catchSubmit] =
    useSubmitNotificationInfos();

  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const dateEditable =
    dossier.body?.traitement?.notificationManuelle?.dateEnvoi === null ||
    dossier.body?.traitement?.notificationManuelle?.dateEnvoi === undefined;

  useEffect(() => {
    if (updatedDossier !== null) {
      setDossier(updatedDossier);
      close();
    }
  }, [updatedDossier, setDossier, close]);

  const memoSubmit = useCallback(async () => {
    return submitForm(values, dossierId);
  }, [values, submitForm, dossierId]);

  const [validateAndSubmit] = useValidateNotificationInfos(
    memoSubmit,
    thenSubmmit,
    catchSubmit,
  );

  return (
    <AntdModal
      visible={visible}
      destroyOnClose={true}
      footer={null}
      onCancel={() => {
        setValues(defaultValue);
        close();
      }}
    >
      <Form
        initialValue={values}
        id={'NotificationInfosForm'}
        onSubmit={data => {
          return validateAndSubmit(foController =>
            foController.validateNotificationInfosUsingPOST(
              dossierId,
              generateNotificationInfosRequestDto(data),
            ),
          );
        }}
        onChange={(
          values: NotificationInfos,
          changedValues: Partial<NotificationInfos>,
        ): void => {
          setValues({
            ...values,
            dateEnvoi:
              changedValues.dateEnvoi?.utc().startOf('day') ?? values.dateEnvoi,
            dateRetour:
              changedValues.dateRetour?.utc().startOf('day') ??
              values.dateRetour,
          });
        }}
        validateButtonTitle={'Renseigner les informations de la notification'}
      >
        {(values, submitButton): React.ReactElement => {
          return (
            <>
              <DatePickerEnvoi
                values={values}
                dossierId={dossierId}
                editable={dateEditable}
              />
              <SelectEtatDistribution values={values} dossierId={dossierId} />
              {values.etatDistribution !== undefined && (
                <DatePickerRetour values={values} dossierId={dossierId} />
              )}

              <div className="SubmitWrapper">{submitButton}</div>
            </>
          );
        }}
      </Form>
    </AntdModal>
  );
};
