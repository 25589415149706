import React from 'react';

import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import {
  ConditionsReactivateMotifSortieDto,
  MotifSortieDto,
} from 'lib_api/lib/api/gen';

import { useModal } from 'hooks/modal/useModal';

import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation from '../Modals/ModalReactivation/ModalReactivation';
import ConditionReactivateMotifSortie from './ConditionReactivateMotifSortie/ConditionReactivateMotifSortie';
import ModalDetails from './Modals/ModalDetails';
import ModalUpdate from './Modals/ModalUpdate';
import { useChangeValidityMotifSortie } from './utils';

interface CellDetailsAndActionsProps {
  motifSortie: MotifSortieDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  motifSortie: motifSortie,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const modalDetails = useModal();
  const modalUpdate = useModal();
  const modalDeprecate = useModal();

  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ] = useChangeValidityMotifSortie();

  const conditionUnlock = (
    conditions: ConditionsReactivateMotifSortieDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item onClick={modalUpdate.open}>Modifier</AntdMenu.Item>
      <AntdMenu.Item onClick={modalDeprecate.open}>
        {motifSortie.valid ? 'Désactiver le motif' : 'Activer le motif'}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <AntdButton onClick={modalDetails.open}>Détails</AntdButton>
      <ModalDetails
        motifSortie={motifSortie}
        visible={modalDetails.visible}
        close={modalDetails.close}
      />
      <ModalUpdate
        motifSortie={motifSortie}
        visible={modalUpdate.visible}
        close={modalUpdate.close}
        refreshDashboard={refreshDashboard}
      />
      <AntdDropdown
        overlay={menu}
        className="menuActions"
        key={motifSortie.idCorrelation}
      >
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {modalDeprecate.visible &&
        (motifSortie.valid ? (
          <ModalDesactivation
            idCorrelation={motifSortie.idCorrelation}
            titleModal="Désactiver le motif de sortie"
            submitDeprecate={submitDeprecateReferentiel}
            visible={modalDeprecate.visible}
            close={modalDeprecate.close}
            callAfterSubmit={refreshDashboard}
            usesRequest={undefined}
            componentUses={undefined}
          />
        ) : (
          <ModalReactivation
            idCorrelation={motifSortie.idCorrelation}
            titleModal="Réactiver le motif de sortie"
            visible={modalDeprecate.visible}
            close={modalDeprecate.close}
            callAfterSubmit={refreshDashboard}
            conditionRequest={conditionsReactivateReferentiel}
            componentConditions={ConditionReactivateMotifSortie}
            conditionUnlock={conditionUnlock}
            reactivateRequest={submitReactivateReferentiel}
          />
        ))}
    </>
  );
}

export default CellDetailsAndActions;
