import React from 'react';
import { DatePicker } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import { isMoment, Moment } from 'moment';

import { WrappedProps } from '../types';
import FieldWrapper from '../FieldWrapper';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { DATE_FORMAT } from 'utils/formats';

const { RangePicker: AntRangePicker } = DatePicker;

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  dateRangeProps: RangePickerProps;
}

function RangeDatePicker<T>({
  wrapperProps,
  dateRangeProps,
}: Props<T>): React.ReactElement {
  wrapperProps.itemProps.validateTrigger = 'onChange';
  if (dateRangeProps.format === undefined) {
    dateRangeProps.format = DATE_FORMAT;
  }
  const rangePicker = React.useMemo(() => {
    return <AntRangePicker {...dateRangeProps} />;
  }, [dateRangeProps]);

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const array = formActions.getFieldValue(fieldKey);

  let value;
  if (Array.isArray(array) && array.length === 2) {
    //start and end date are Moment if array index is Moment. undefined else
    const startDate: Moment | undefined = isMoment(array[0])
      ? array[0]
      : undefined;
    const endDate: Moment | undefined = isMoment(array[1])
      ? array[1]
      : undefined;

    //Value concat format parse of the 2 moments.
    value =
      startDate === undefined ? 'Non renseigné' : startDate.format(DATE_FORMAT);
    value +=
      endDate === undefined
        ? ' -> Non renseigné'
        : ' -> ' + endDate.format(DATE_FORMAT);
  }

  return (
    <FieldWrapper
      {...wrapperProps}
      ConsultationElement={value}
      WrappedInput={rangePicker}
    />
  );
}

export default RangeDatePicker;
