import React from 'react';
import { ColumnProps } from '../../types';

import './BasicColumnTitle.less';

interface BasicColumnTitleProps<DataType> {
  columnProps: ColumnProps<DataType>;
}

/**
 * Component inserted in the first cell of a Table's column.
 *
 * @param columnsProps - general props to define this component:
 *        - title: text displayed in this component
 *        - dataIndex: key identifying this column and which data attribute it refers to
 *        - buildCellContent: to manually build cell content instead on just displaying field value
 */
function BasicColumnTitle<DataType>({
  columnProps,
}: BasicColumnTitleProps<DataType>): React.ReactElement {
  return <div className="column-title">{columnProps.title}</div>;
}

export default BasicColumnTitle;
