import { createContext, Dispatch, SetStateAction, useContext } from 'react';
import { ComputedResponseDtoListActionsRequisesEnum } from 'lib_api/lib/api/gen';

interface DossierActionsRequises {
  dossierActionsRequises: ComputedResponseDtoListActionsRequisesEnum[];
  setDossierActionsRequises: Dispatch<
    SetStateAction<ComputedResponseDtoListActionsRequisesEnum[]>
  >;
}

export const DossierActionsRequisesContext =
  createContext<DossierActionsRequises | null>(null);

export const useDossierActionsRequisesContext = (): DossierActionsRequises => {
  const context = useContext(DossierActionsRequisesContext);
  if (context === null) {
    throw new Error('useDossierActionsRequisesContext called outside Context.');
  }

  return context;
};

const DossierActionsRequisesProvider = DossierActionsRequisesContext.Provider;

export default DossierActionsRequisesProvider;
