import React from 'react';
import { Select } from 'components/WrappedComponents';

import { FormFilterFieldGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { ReferenceDtoStatutDto } from 'lib_api/lib/api/gen';
import { statutEnumMapping } from 'utils/enumData';
import { useStatutConsultable } from 'hooks/referentiels/useStatutConsultable';

function selectItemsFromRef(refs: ReferenceDtoStatutDto) {
  return refs.statuts.map(enumValue => {
    return {
      value: enumValue.toString(),
      displayValue: statutEnumMapping[enumValue].label,
    };
  });
}

export function StatutMiseEnFourriere({
  values,
}: FormFilterFieldGardien): React.ReactElement {
  const statuts = useStatutConsultable();
  const selectItems = React.useMemo(
    () => (statuts !== undefined ? selectItemsFromRef(statuts) : []),
    [statuts],
  );
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'statut',
        itemProps: {
          label: 'Statut',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{ mode: 'multiple', placeholder: FormPlaceholders.Select }}
    />
  );
}
