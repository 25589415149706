import { Dispatch, SetStateAction, useCallback, useState } from 'react';

import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

/**
 * Type returned by useValidateAndSubmit
 *
 * values: Form values in useState
 * setValues: Function wich set form values
 * validateAndSubmit: Function wich submit form after validate it
 * closeAndResetModal: Function to be used to close modal
 */
export type ValidateAndSubmitType<FormValues> = {
  values: FormValues;
  setValues: Dispatch<SetStateAction<FormValues>>;
  validateAndSubmit: (values: FormValues) => Promise<void>;
  closeAndResetModal: () => void;
};

/**
 * Type of function validating form field, sent to FormModal in 'controllerValidateField'
 */
export type ValidateFieldFunctionType<FormValues, FieldEnum, ViolationType> = (
  values: FormValues,
  key: keyof FormValues,
  field: FieldEnum,
  value: unknown,
) => Promise<ViolationType>;

/**
 * This hook is used to send request in modal forms. Submit request is sent only if validation succeed
 *
 * @param initialValues Initial form values (set when we update modal)
 * @param controllerValidate Function return promise wich validate form
 * @param validationKey Key of ValidationType containing violations
 * @param controllerSubmit Function return promise wich submit form
 * @param closeModal Change modal visibility to false
 * @param refreshDashboard Refresh dashboard
 * @returns ValidateAndSubmitType with usefull data for modal
 */
export function useValidateAndSubmit<DtoType, FormValues, ViolationType>(
  initialValues: FormValues,
  controllerSubmit: (requestGenerator: FormValues) => Promise<DtoType>,
  closeModal: () => void,
  refreshDashboard: () => void,
  controllerValidate?: (values: FormValues) => Promise<ViolationType>,
  validationKey?: keyof ViolationType,
): ValidateAndSubmitType<FormValues> {
  const behaviourOnError = useHandleBackErrors();
  const [values, setValues] = useState<FormValues>(initialValues);

  const submitRequest = useCallback(
    (formValues: FormValues): Promise<DtoType> => {
      return controllerSubmit(formValues);
    },
    [controllerSubmit],
  );

  const validateRequest = useCallback(
    (formValues: FormValues): Promise<ViolationType | void> => {
      if (controllerValidate) {
        return controllerValidate(formValues);
      } else {
        return Promise.resolve();
      }
    },
    [controllerValidate],
  );

  // If submit succeess, we close modal, refresh dashboard and reset form values
  const onSuccess = useCallback((): void => {
    closeModal();
    refreshDashboard();
    setValues(initialValues);
  }, [closeModal, refreshDashboard, initialValues]);

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  const memoSubmit = useCallback(async () => {
    return submitRequest(values);
  }, [values, submitRequest]);

  const memoValidate = useCallback(async () => {
    return validateRequest(values);
  }, [values, validateRequest]);

  const validateAndSubmit = useCallback(async (): Promise<void> => {
    if (!validationKey) {
      return Promise.resolve();
    }
    try {
      const violationResponse: ViolationType | void = await memoValidate();
      if (violationResponse) {
        const violations: ViolationType[keyof ViolationType] | null =
          violationResponse[validationKey];
        if (
          violations === null ||
          !Array.isArray(violations) ||
          violations.length > 0
        ) {
          return;
        }
      }
      memoSubmit().then(onSuccess).catch(onError);
    } catch (error) {
      if (error instanceof Response) {
        return Promise.reject((await backAlertMessage(error)).description);
      }
    }
    return Promise.resolve();
  }, [memoSubmit, memoValidate, onError, onSuccess, validationKey]);

  return {
    values: values,
    setValues: setValues,
    validateAndSubmit: validateAndSubmit,
    closeAndResetModal: () => {
      closeModal();
      setValues(initialValues);
    },
  };
}
