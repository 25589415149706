import React from 'react';
import { Modal as AntdModal } from 'antd';

import { MotifMefDto } from 'lib_api/lib/api/gen';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { BooleanFieldDisplay } from 'components/FieldDisplay/BooleanFieldFisplay';

import './ModalDetails.less';

interface ModalDetailsProps {
  motifMef: MotifMefDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  motifMef,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id de corrélation" value={motifMef.idCorrelation} />
      <FieldDisplay label="Nature de MEF" value={motifMef.natureLibelleCourt} />
      <FieldDisplay
        label="Détail de Nature de MEF"
        value={motifMef.nature}
        additionalValueClassName={'multiLineValue'}
      />
      <FieldDisplay
        label="Motif de MEF"
        value={motifMef.motifMef}
        additionalValueClassName={'multiLineValue'}
      />
      <BooleanFieldDisplay label="Est Rodéo" value={motifMef.rodeo} />
      <BooleanFieldDisplay label="Est DICEM" value={motifMef.dicem} />
    </AntdModal>
  );
}

export default ModalDetails;
