import React, { useMemo } from 'react';
import { Select as AntdSelect } from 'antd';
import './PageSizeChanger.less';

interface PageSizeChangerProps {
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
  options: number[];
  suffix: string;
  disabled?: boolean;
}

/**
 * Component used to change number of items deplayed in one page. The selected size will
 * therefore also change the pagination's available pages.
 *
 * @param pageSize - page size currently selected (it should be one of the options)
 * @param setPageSize - callback when new size is defined
 * @param options - proposed options to change page size (supposed to be distinct)
 * @param suffix - text displayed after each option in the Select component
 *        (ex: ' / page')
 * @param disabled
 */
function PageSizeChanger({
  pageSize,
  setPageSize,
  options,
  suffix,
  disabled,
}: PageSizeChangerProps): React.ReactElement {
  const antOptions: React.ReactElement[] = useMemo(
    () =>
      options.map((option, index) => (
        <AntdSelect.Option value={index} key={option}>
          {option} {suffix}
        </AntdSelect.Option>
      )),
    [options, suffix],
  );

  const onSelect = (index: number): void => {
    setPageSize(options[index]);
  };

  return (
    <AntdSelect
      value={options.indexOf(pageSize)}
      onSelect={onSelect}
      disabled={disabled}
      size="small"
      className="table-page-size-changer"
    >
      {antOptions}
    </AntdSelect>
  );
}

export default PageSizeChanger;
