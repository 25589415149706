import React from 'react';
import { Divider as AntdDivider, Space as AntdSpace } from 'antd';

import './FicheDisplay.less';

interface FicheDisplayProps {
  leftSideChildren: React.ReactNode[];
  rightSideChildren: React.ReactNode[];
}

export const FicheDisplay = ({
  leftSideChildren,
  rightSideChildren,
}: FicheDisplayProps): React.ReactElement => {
  return (
    <div className={'FicheContainer'}>
      <AntdSpace key={'left'} direction={'vertical'} className={'node'}>
        {leftSideChildren}
      </AntdSpace>

      <AntdDivider type={'vertical'} className={'separator'} />

      <AntdSpace key={'right'} direction={'vertical'} className={'node'}>
        {rightSideChildren}
      </AntdSpace>
    </div>
  );
};
