import { useCallback, useEffect } from 'react';

import {
  MarqueDto,
  ModeleDto,
  SearchMarqueUsingSpecsUsingGETQueryParamsFilters,
  SearchModeleUsingSpecsUsingGETQueryParamsFilters,
} from 'lib_api/lib/api/gen';

import { useSearchMarque } from 'hooks/search/useSearchMarque';
import { useSearchModele } from 'hooks/search/useSearchModele';
import { useSearchWithPagination } from 'hooks/search/useSearchWithPagination';
import { VALUE_NON_REFERENCE } from 'utils/global';
import { BaseOptionType } from 'components/BaseForm/Select/BaseSelect';

const INITIAL_MARQUE_FILTERS: SearchMarqueUsingSpecsUsingGETQueryParamsFilters =
  {
    page: 1,
    pageSize: 30,
    types: ['EXACTE', 'INCONNUE'],
  };

const INITIAL_MODELE_FILTERS: SearchMarqueUsingSpecsUsingGETQueryParamsFilters =
  {
    page: 1,
    pageSize: 30,
  };

export const useFetchMarques = (
  prioritaire: boolean,
): {
  inProgress: boolean;
  marques: MarqueDto[];
  search: (query: string) => void;
  paginate: () => void;
} => {
  const searchMarque = useSearchMarque();

  const fetch = useCallback(
    (filters: SearchMarqueUsingSpecsUsingGETQueryParamsFilters) => {
      return searchMarque({
        filters: {
          ...filters,
        },
        sorts: {
          libelle: true,
        },
      });
    },
    [],
  );

  const { inProgress, options, search, pagination, filters, fetchOptions } =
    useSearchWithPagination(
      fetch,
      {
        ...INITIAL_MARQUE_FILTERS,
        prioritaire,
      },
      response => response.results.referenceDtoList,
    );

  useEffect(() => {
    fetchOptions(filters.page === 1);
  }, [filters]);

  const paginate = () => {
    pagination(filters.page);
  };

  return {
    inProgress,
    marques: options,
    search,
    paginate,
  };
};

export const useFetchModeles = (
  prioritaire: boolean,
  marque: BaseOptionType<MarqueDto> | null,
): {
  inProgress: boolean;
  modeles: ModeleDto[];
  search: (query: string) => void;
  paginate: () => void;
} => {
  const searchModele = useSearchModele();

  const fetch = useCallback(
    (filters: SearchModeleUsingSpecsUsingGETQueryParamsFilters) => {
      return searchModele({
        filters: {
          ...filters,
        },
        sorts: {
          libelle: true,
        },
      });
    },
    [],
  );

  const {
    inProgress,
    options,
    search,
    pagination,
    filters,
    setFilters,
    fetchOptions,
  } = useSearchWithPagination(
    fetch,
    {
      ...INITIAL_MODELE_FILTERS,
      prioritaire,
    },
    response => response.results.referenceDtoList,
  );

  useEffect(() => {
    if (!marque?.option?.id || marque.value === VALUE_NON_REFERENCE) {
      return;
    }
    fetchOptions(filters.page === 1);
  }, [filters]);

  useEffect(() => {
    setFilters({
      ...filters,
      marqueId: marque?.option?.id,
      page: 1,
    });
  }, [marque]);

  const paginate = () => {
    pagination(filters.page);
  };

  return {
    inProgress,
    modeles: options,
    search,
    paginate,
  };
};
