import React from 'react';
import { Row as AntdRow, Col as AntdCol } from 'antd';

import './Label.less';

export interface LabelInfoProp {
  label: string;
  value?: string;
}

function LabelInfo({ label, value }: LabelInfoProp): React.ReactElement {
  return (
    <AntdRow>
      <AntdCol span={12} className={'label'}>
        <label className="label-info">{label} :</label>
      </AntdCol>
      <AntdCol span={12}>
        {value !== undefined ? (
          <label className="value-info">{value}</label>
        ) : (
          <label>Non renseigné</label>
        )}
      </AntdCol>
    </AntdRow>
  );
}

export default LabelInfo;
