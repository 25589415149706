import { useEffect, useState } from 'react';

import { VideoInfo, YoutubeVideoList } from './types';
import { YOUTUBE_API, YOUTUBE_API_KEY } from './constants';

export function useVideoInfo(videoId: string): [boolean, VideoInfo] {
  const [loading, setLoading] = useState(true);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);

  useEffect(() => {
    setLoading(true);
    const url = `${YOUTUBE_API}/videos?part=snippet&id=${videoId}&key=${YOUTUBE_API_KEY}`;

    fetch(url)
      .then((res: Response) => res.json())
      .then((data: YoutubeVideoList) => {
        if (data?.items && data?.items?.length !== 0) {
          const [video] = data.items;
          setTitle(video.snippet.localized.title);
          setDescription(video.snippet.localized.description);
        }
      })
      .finally(() => setLoading(false));
  }, [videoId]);

  return [loading, { title, description }];
}
