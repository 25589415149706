import React from 'react';

import {
  AdminFonctionnelControllerApi,
  DossierDtoAdminFonctionnel,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { UpdateEntiteRattachementFormValues } from './types';

type SubmitFunction = (
  values: UpdateEntiteRattachementFormValues,
) => Promise<void>;

function buildSubmitFunction(
  refController: AdminFonctionnelControllerApi,
  dossierId: string,
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void,
  fetchEvents: () => Promise<void>,
  closeModal: () => void,
): SubmitFunction {
  return (values: UpdateEntiteRattachementFormValues) => {
    return refController
      .updateEntitesRattachementUsingPOST(dossierId, values)
      .then(setDossier)
      .then(fetchEvents)
      .then(closeModal)
      .catch((error: Response) => {
        void backAlertMessage(error);
      });
  };
}

export default buildSubmitFunction;
