import React from 'react';

import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';

import { useUserStore } from '../hooks/UserStoreContext';
import { UnauthorizedPage } from 'components/Error';

interface Props {
  roles: UserDtoProfileEnum[];
  children: React.ReactNode;
}

/**
 * Create a routing guard that only let users with enough rights access a route.
 *
 * @param roles The list of `Roles` that can access this resource.
 * @param pathToRedirect Where to redirect the user when he tries to access the route with insufficient rights.
 *
 */
const AuthGuard: React.FC<Props> = ({
  children,
  roles,
}: Props): React.ReactElement => {
  const user = useUserStore();

  if (!roles.includes(user.profile)) {
    return (
      <UnauthorizedPage errorMessage="Votre compte n'a pas le bon rôle pour accéder à cette page." />
    );
  }

  return <>{children}</>;
};

export default AuthGuard;
