import {
  Feature,
  FeatureCollection,
  GeoJsonObject,
  GeoJsonProperties,
  Geometry,
} from 'geojson';

import { useSearchApiGouv } from './useSearchApiGouv';

export interface AdresseApiGouvOption {
  adresse: string;
  label?: string;
  commune?: string;
  codepostal?: string;
}

interface AdresseApiGouvResponse {
  features: ApiGouvFeature[];
}

interface ApiGouvFeature {
  properties: {
    label?: string;
    name?: string;
    city?: string;
    postcode?: string;
  };
}

function buildSearchAdresseUrl(option: AdresseApiGouvOption): string {
  return `${
    process.env.REACT_APP_API_ADRESSE_GOUV_URL
  }/search/?limit=15&q=${encodeURI(option.adresse)}`;
}

function mapFetchResultToOptions(
  result: AdresseApiGouvResponse,
): Array<AdresseApiGouvOption> {
  return result.features
    .map((feature: ApiGouvFeature): AdresseApiGouvOption | null => {
      return {
        label: feature.properties?.label,
        adresse: feature.properties?.name ?? '',
        commune: feature.properties?.city?.toUpperCase(),
        codepostal: feature.properties?.postcode,
      };
    })
    .filter(
      (elem: AdresseApiGouvOption | null) => !!elem,
    ) as Array<AdresseApiGouvOption>; // typecast here because typescript can't automatically deduce the filtered array type
}

export const useSearchAdresseApiGouv = (): [
  Array<AdresseApiGouvOption>,
  boolean,
  (str: string) => void,
] => {
  const [options, isFetching, fetchOptions] = useSearchApiGouv(
    { adresse: '' },
    option => option.adresse,
    buildSearchAdresseUrl,
    search => {
      return { adresse: search };
    },
    mapFetchResultToOptions,
  );

  return [options, isFetching, fetchOptions];
};
