import { useState, useEffect, useCallback } from 'react';
import { useApi } from 'hooks/ApiStoreContext';
import { backAlertMessage } from '../utils/backAlertMessage';
import { RequiredUserActionsDto } from 'lib_api/lib/api/gen';

export const useRequiredActions = (): [
  RequiredUserActionsDto | undefined,
  () => void,
] => {
  const [requiredActions, setRequiredActions] =
    useState<RequiredUserActionsDto>();
  const { ComptesUtilisateursControllerApi } = useApi();

  const updateRequiredActions = useCallback(() => {
    ComptesUtilisateursControllerApi.getRequiredActionsUsingGET()
      .then(requiredActionsDto => setRequiredActions(requiredActionsDto))
      .catch(e => backAlertMessage(e));
  }, [ComptesUtilisateursControllerApi]);

  useEffect(updateRequiredActions, [ComptesUtilisateursControllerApi]);

  return [requiredActions, updateRequiredActions];
};
