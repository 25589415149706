import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';

import { BasicTable } from 'components/WrappedComponents/Table';
import { useBoolean } from 'utils/genericUtils';

import {
  ColumnProps,
  FetchDataResult,
  FetchDataUnpagedFunctionWithoutSort,
  TableHeaderFunctionWithoutSort,
  TableSelect,
} from '../types';

interface TableWithoutPaginationAndSortProps<
  DataType extends object,
  FilterType,
> {
  // Table - general
  columns: ColumnProps<DataType>[];
  fetchData?: FetchDataUnpagedFunctionWithoutSort<DataType, FilterType>;
  // Table - select items
  canSelectItems?: boolean;
  buildItemKey?: (item: DataType) => string | number;
  onSelectItems?: (selectedItems: DataType[]) => void;
  // Header
  header?: TableHeaderFunctionWithoutSort<DataType, FilterType>;
  // Other
  antdTableProps?: Partial<AntdTableProps<DataType>>;
  //  filters
  filter: Partial<FilterType>;
  setFilter: Dispatch<SetStateAction<Partial<FilterType>>>;
}

/**
 * Table component to display a large amount of data.
 * This component provide a SimpleTable, along with filter, header.
 *
 * @param columns - Paramters to represent columns used in this Table. Each column is
 *        associated with a single attribute of fetched items, and may provide a button
 *        for sorting functionnality.
 * @param fetchData - Function called to fetch data (either sync or async),
 *        sorting order and filter applied. This function is
 *        supposed to return a list of fetched object corresponding to those filters.
 *        This function also return the total number of items corresponding to those
 *        filters.
 * @param canSelectItems - When true, add checkboxes to select items.
 * @param buildItemKey - Function to build a unique key for each item. Required when
 *        using item seection.
 * @param onSelectItems - When useSelectItems is true, this callback is triggered when
 *        selected items change.
 * @param header - Callback to generate a component used ahead of Table. This component
 *        may be use to set filters, or to display some data (ex: "100 items found").
 * @param antdTableProps - To use other props defined by Antd library on Table component
 *        (/!\ may interfere with props already defined by this component).
 * @param filter
 * @param setFilter
 */
function TableWithoutPaginationAndSort<DataType extends object, FilterType>({
  // Table - general
  columns,
  fetchData,
  // Table - select items
  canSelectItems,
  buildItemKey,
  onSelectItems,
  // Header
  header,
  // Other
  antdTableProps,
  //  filters
  filter,
  setFilter,
}: TableWithoutPaginationAndSortProps<
  DataType,
  FilterType
>): React.ReactElement {
  const loadingData = useBoolean(true);
  const [fetchedData, setFetchedData] = useState<
    FetchDataResult<DataType> | undefined
  >(undefined);
  const onError = useBoolean(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  const [selected, setSelected] = useState<TableSelect<DataType>>([]);

  function innerSetSelected(newSelected: TableSelect<DataType>): void {
    setSelected(newSelected);
    onSelectItems && onSelectItems(newSelected);
  }

  // Go back to pagination when filter is update
  function innerSetFilter(filter: Partial<FilterType>): void {
    setFilter(filter);
  }

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutdated = false;
    // Fetch new data
    const loadNewData = async function loadNewData(): Promise<void> {
      try {
        if (fetchData !== undefined) {
          loadingData.setIsTrue();
          const data = await fetchData(filter);
          if (!isOutdated) {
            setFetchedData(data);
            onError.setIsFalse();
            loadingData.setIsFalse();
          }
        }
      } catch (e) {
        if (!isOutdated) {
          setFetchedData({ data: [], total: 0 });
          onError.setIsTrue();
          loadingData.setIsFalse();
        }
      }
    };
    void loadNewData();
    return (): void => {
      isOutdated = true;
    };
  }, [filter, triggerUpdate, fetchData]);

  return (
    <>
      {header && header(fetchedData, selected, filter, innerSetFilter)}
      <BasicTable
        data={fetchedData !== undefined ? fetchedData.data : []}
        columnsProps={columns}
        isLoading={loadingData.value}
        reload={() => setTriggerUpdate(val => ++val)}
        isOnError={onError.value}
        selectItems={
          canSelectItems
            ? {
                selected: selected,
                onSelect: innerSetSelected,
              }
            : undefined
        }
        buildItemKey={buildItemKey}
        antdTableProps={{ ...antdTableProps, pagination: false }} // We use our custom Pagination component instead
      />
    </>
  );
}

export default TableWithoutPaginationAndSort;
