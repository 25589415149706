import * as React from 'react';

const CarIcon = (props: React.SVGProps<SVGSVGElement>): React.ReactElement => (
  <svg viewBox="0 0 512 512" {...props}>
    <path d="M96 277.333c-29.397 0-53.333 23.936-53.333 53.333S66.603 384 96 384s53.333-23.936 53.333-53.333S125.397 277.333 96 277.333zm0 85.334c-17.643 0-32-14.357-32-32s14.357-32 32-32 32 14.357 32 32c0 17.642-14.357 32-32 32zM416 277.333c-29.397 0-53.333 23.936-53.333 53.333S386.603 384 416 384s53.333-23.936 53.333-53.333-23.936-53.334-53.333-53.334zm0 85.334c-17.643 0-32-14.357-32-32s14.357-32 32-32 32 14.357 32 32c0 17.642-14.357 32-32 32z" />
    <path d="M448 213.333h-18.048a29.208 29.208 0 01-18.219-6.4L327.68 139.69c-9.429-7.53-21.269-11.69-33.323-11.69H140.16c-17.877 0-34.453 8.875-44.373 23.744l-18.155 27.264A29.206 29.206 0 0153.333 192C23.936 192 0 215.936 0 245.333V288c0 29.397 23.936 53.333 53.333 53.333 5.888 0 10.667-4.779 10.667-10.667S59.221 320 53.333 320c-17.643 0-32-14.357-32-32v-42.667c0-17.643 14.357-32 32-32 16.939 0 32.64-8.405 42.048-22.507l18.155-27.243a31.972 31.972 0 0126.624-14.251h154.197c7.253 0 14.336 2.496 20.011 7.019l84.053 67.221c8.896 7.147 20.117 11.093 31.552 11.093H448c31.296 0 42.667 31.893 42.667 53.333 0 21.013-16.085 32-32 32-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667C489.067 341.333 512 318.4 512 288c0-36.203-22.421-74.667-64-74.667z" />
    <path d="M373.333 320H138.667c-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667h234.667c5.888 0 10.667-4.779 10.667-10.667S379.221 320 373.333 320zM352 213.333h-96v-32c0-5.888-4.779-10.667-10.667-10.667s-10.667 4.779-10.667 10.667V224c0 5.888 4.779 10.667 10.667 10.667H352c5.888 0 10.667-4.779 10.667-10.667s-4.779-10.667-10.667-10.667zM202.667 213.333h-68.075l13.632-27.221c2.624-5.269.491-11.669-4.779-14.315-5.291-2.624-11.669-.512-14.315 4.779l-21.333 42.667c-1.664 3.285-1.472 7.232.469 10.368s5.376 5.056 9.067 5.056h85.333c5.888 0 10.667-4.779 10.667-10.667s-4.778-10.667-10.666-10.667zM288 256h-21.333c-5.888 0-10.667 4.779-10.667 10.667s4.779 10.667 10.667 10.667H288c5.888 0 10.667-4.779 10.667-10.667S293.888 256 288 256z" />
  </svg>
);

export default CarIcon;
