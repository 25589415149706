import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

import './MentionsLegales.less';

function MentionsLegales(): React.ReactElement {
  const navigate = useNavigate();

  return (
    <div className="MentionsLegalesContainer">
      <Button
        className="return-button"
        icon={<ArrowLeftOutlined />}
        onClick={(): void => navigate(-1)}
        type="default"
        shape="round"
        size="middle"
      >
        Retour
      </Button>

      <div className="MentionsLegales">
        <h1 className="MentionsLegalesTitle">Mentions Légales</h1>

        <h2 className="MentionsLegalesSubTitle">
          Information - protection des données personnelles « Service-Public.fr
          »
        </h2>

        <div className="MetionLegaleSection">
          <p className="MetionLegaleSectionTitle">Objet du traitement</p>
          <div className="MetionLegaleSectionContent">
            <p>
              La délégation à la sécurité routière a mis en place un système
              d&apos;information national des fourrières en automobiles qui
              permet de :
              <ol>
                <li>
                  Procéder à l&apos;enregistrement et à la conservation des
                  données recueillies par l&apos;autorité prescriptive au moyen
                  d&apos;appareils électroniques ;
                </li>
                <li>
                  Enregistrer la gestion et le suivi par les autorités
                  compétentes des procédures relatives aux véhicules mis en
                  fourrière ;
                </li>
                <li>
                  Centraliser les informations relatives à la mise en fourrière
                  et permettre le partage de ces informations entre les acteurs
                  de la procédure de mise en fourrière ;
                </li>
                <li>
                  Mettre à disposition des usagers des informations en ligne
                  relative la mise en fourrière de leur véhicule ;
                </li>
                <li>
                  Faciliter la restitution des véhicules volés et stockés en
                  fourrière.
                </li>
              </ol>
            </p>
            <p>
              La base légale du traitement est l&apos;intérêt légitime (cf.
              article 6.1.e) du Règlement européen sur la protection des
              données.
            </p>
          </div>
        </div>

        <div className="MetionLegaleSection">
          <p className="MetionLegaleSectionTitle">Catégories de données</p>
          <div className="MetionLegaleSectionContent">
            <ul>
              <li>
                Données relatives aux gardiens de fourrière, aux autorités de
                fourrière et aux autorités prescrivant des mises en fourrière
              </li>
              <li>
                Données relatives à la prescription de mise en fourrière du
                véhicule
              </li>
              <li>Données relatives à la garde du véhicule en fourrière</li>
              <li>
                Données relatives au classement du véhicule et à la notification
                de la mise en fourrière
              </li>
              <li>Données relatives à la mainlevée</li>
              <li>Données relatives à l&apos;abandon du véhicule</li>
              <li>Données relatives à la remise pour destruction</li>
              <li>
                Données relatives à la remise au service chargé du domaine
              </li>
              <li>
                Données relatives à l&apos;indemnisation du gardien de fourrière
                par l&apos;autorité dont relève la fourrière
              </li>
            </ul>
          </div>
        </div>

        <div className="MetionLegaleSection">
          <p className="MetionLegaleSectionTitle">Destinataire des données</p>
          <div className="MetionLegaleSectionContent">
            <p>
              Pourront se voir communiquer les données collectées :
              <ul>
                <li>
                  Les propriétaires et conducteurs des véhicules mis en
                  fourrière ;
                </li>
                <li>
                  Les agents du service chargé du domaine mentionnés à
                  l&apos;article L. 325-8 du code de la route ;
                </li>
                <li>
                  Les personnels des entreprises chargées de la destruction des
                  véhicules mentionnées à l&apos;article R. 325-45 du code de la
                  route ;
                </li>
                <li>
                  Les maîtres des lieux mentionnés à l&apos;article L. 325-12 du
                  code de la route ;
                </li>
                <li>
                  Les agents habilités des organismes d&apos;assurance liés par
                  un protocole d&apos;accord avec le ministère chargé de la
                  sécurité routière pour les seules informations relatives aux
                  véhicules volés et découverts ;
                </li>
                <li>
                  Les autorités judiciaires mentionnées à l&apos;article L.
                  321-1-1 du code de la route.
                </li>
              </ul>
            </p>
          </div>
        </div>

        <div className="MetionLegaleSection">
          <p className="MetionLegaleSectionTitle">
            Durées de conservation des données : un an
          </p>
          <div className="MetionLegaleSectionContent">
            <p>
              Néanmoins, les données peuvent être conservées pour une durée
              supérieure dans deux cas : lorsque les véhicules font l&apos;objet
              d&apos;une vente ou lorsqu&apos;ils sont mis en destruction.
            </p>
            <p>
              Par ailleurs, ces durées de conservation peuvent être prolongées
              en cas de recours contentieux ou contre un gardien de fourrière
              jusqu&apos;à l&apos;intervention d&apos;une décision
              juridictionnelle devenue définitive.
            </p>
          </div>
        </div>

        <div className="MetionLegaleSection">
          <p className="MetionLegaleSectionTitle">Vos droits</p>
          <div className="MetionLegaleSectionContent">
            <p>
              Vous pouvez accéder aux données vous concernant ou demander leur
              rectification (cf. cnil.fr pour plus d&apos;informations sur vos
              droits) en adressant votre demande, par courrier, auprès de :
            </p>
            <p>
              Délégation à la sécurité routière – Place Beauvau – 75800 PARIS
              Cedex 08
            </p>
            <p>
              Pour toute question sur le traitement de vos données dans ce
              dispositif, vous pouvez contacter notre DPO par voie électronique
              : <span className="MetionLegaleContact">@</span>
            </p>
            <p>
              Si vous estimez, après nous avoir contactés, que vos droits
              Informatique et Libertés ne sont pas respectés, vous pouvez
              adresser une réclamation à la CNIL.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MentionsLegales;
