export function isValueDefined(value: unknown): boolean {
  if (value === undefined) {
    return false;
  } else if (value === null) {
    return false;
  } else if (typeof value === 'string' && value === '') {
    return false;
  }

  return true;
}

export function isValueProps(
  valueProps: unknown,
): valueProps is { value: string } {
  if (typeof valueProps === 'object' && valueProps !== null) {
    for (const [key, value] of Object.entries(valueProps)) {
      if (key === 'value' && typeof value === 'string') {
        return true;
      }
    }
  }

  return false;
}

/**
 *
 * @param object We want to know if this object has a string named value
 * @returns true if object has {value:string}
 */
export function hasStringValueField(object: unknown): boolean {
  if (
    typeof object === 'object' &&
    object !== null &&
    object.hasOwnProperty('value')
  ) {
    for (const [key, value] of Object.entries(object)) {
      if (key === 'value' && typeof value === 'string') {
        return true;
      }
    }
  }
  return false;
}
