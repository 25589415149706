import React from 'react';

import './CardLayout.less';

interface CardLayoutProps {
  children: React.ReactNode;
}

const CardLayout = (props: CardLayoutProps): React.ReactElement => {
  return <div className="CardLayout">{props.children}</div>;
};

export default CardLayout;
