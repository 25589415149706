import React, { useState } from 'react';

import ResponsiveContainer from 'components/ResponsiveContainer';
import CardLayout from 'components/CardLayout/CardLayout';
import UploadCard from 'components/Card/UploadCard';
import Uploader from 'components/WrappedComponents/Uploader/Uploader';
import Downloader from 'components/WrappedComponents/Downloader/Downloader';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';
import { SupportedFile } from 'types/enums/SupportedFile';
import { RequestStatus } from 'types/RequestStatus';
import useDownloadClassement from 'hooks/classement/useDownloadClassement';
import { useImportClassement } from 'hooks/classement/useImportClassementCsv';

const Classement = (): React.ReactElement => {
  const { downloadStatus, download } = useDownloadClassement();
  const [status, setStatus] = useState<RequestStatus>();
  const importCsv = useImportClassement(setStatus);
  const { fileTypes } = supportedFilesDetails[SupportedFile.DMN];

  return (
    <ResponsiveContainer>
      <h1>{`Import d'une nouvelle logique de classement`} </h1>
      <p>
        {`Vous pouvez depuis cette page importer une nouvelle logique de classement des véhicules. 
        Pour cela, il vous suffit de cliquer sur l'élément "Logique de classement" et de choisir le fichier contenant cette logique.         
        Ce fichier doit être au format XML ou DMN.`}
      </p>
      <CardLayout>
        <UploadCard status={status}>
          <Uploader
            importRoute={importCsv}
            title={'Logique de classement'}
            fileTypes={fileTypes}
          />
        </UploadCard>
      </CardLayout>
      <br />
      <h1>{`Export de la logique actuelle`}</h1>
      <p>
        {`Il vous est possible de télécharger la logique actuellement utilisée depuis cette page. Le fichier généré est de type XML.`}
      </p>
      <CardLayout>
        <UploadCard key={0} status={downloadStatus}>
          <Downloader title={'Logique de classement'} download={download} />
        </UploadCard>
      </CardLayout>
    </ResponsiveContainer>
  );
};

export default Classement;
