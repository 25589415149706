import React, { useCallback } from 'react';
import { Spin } from 'antd';
import { UpdateClassementViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import BaseForm from 'components/BaseForm/BaseForm';
import useSubmitUpdateClassement from './buildSubmitFunction';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import { CSSUtils } from 'utils/CSSUtils';
import useInitialValues from './useInitialValues';

import './UpdateClassement.less';

interface UpdateSortieProps {
  closeModal: () => void;
}

function UpdateClassement({
  closeModal,
}: UpdateSortieProps): React.ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const { dossier: dossierAdmin } = useDossierCompletAdmin(dossierId);
  const { AdminFonctionnelControllerApi: adminController } = useApi();
  const initialValues = useInitialValues(dossierAdmin);

  const { submit, inProgress } = useSubmitUpdateClassement(
    adminController,
    dossierId,
    setDossier,
    fetchEvents,
    closeModal,
  );

  const submitFunction = useCallback(submit, [submit]);

  if (!dossierAdmin) {
    return <Spin />;
  }

  return (
    <>
      <h3 className="updateClassementTitle">
        Confirmez-vous vouloir classer le véhicule en destruction ?
      </h3>
      <BaseForm
        initialValues={initialValues}
        onSubmit={submitFunction}
        onCancel={closeModal}
        isSubmitting={inProgress}
        warningMessage={{
          detail:
            'Une fois le passage en destruction confirmé, le nouveau statut du dossier sera “À détruire” et un ordre' +
            ' de destruction sera signalé. ',
          title: 'Conséquence de la modification',
        }}
        inputs={[
          {
            field: UpdateClassementViolationDtoFormFieldEnum.JUSTIFICATION,
            required: true,
            label: 'Justification',
            name: 'comment',
            render: () => {
              return <BaseTextArea />;
            },
          },
        ]}
        labelCol={{ span: CSSUtils.largeWrapperCol }}
        wrapperCol={{ span: CSSUtils.XLargeWrapperCol }}
        validateField={async (field, formValues) => {
          const response =
            await adminController.validateFieldUpdateClassementUsingPOST(
              field,
              dossierId,
              {
                newClassement: formValues.newClassement || null,
                justification: formValues.comment,
              },
            );

          const violations = response.updateClassementViolationDtoList ?? [];
          if (violations.length === 0) {
            return Promise.resolve();
          }

          return Promise.reject(violations[0].message);
        }}
        renderButtonAction={(Submit, Cancel) => {
          return (
            <div className="UpdateActions">
              <div className="UpdateAction">{Cancel}</div>
              <div className="UpdateAction">{Submit}</div>
            </div>
          );
        }}
        submitText={'Confirmer'}
      />
    </>
  );
}

export default UpdateClassement;
