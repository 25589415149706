import {
  ForceOrdreControllerApi,
  ProfilUniteFoRequestDto,
  ProfilUniteFoViolationDtoFormFieldEnum,
  UniteFOResponseDto,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';

import { FicheProfilForceOrdre } from './types';

export function ficheProfilFromDto(
  uniteFO: UniteFOResponseDto,
): FicheProfilForceOrdre {
  return {
    codeUnite: uniteFO.code,
    unite: uniteFO.unite,
    abreviationUnite: uniteFO.abreviation,
    adresse: uniteFO.adresse || undefined,
    codePostal: uniteFO.codePostal || undefined,
    commune: uniteFO.ville || undefined,
    email: uniteFO.email || undefined,
    telephone: uniteFO.telephone || undefined,
  };
}

export function requestDtoFromFicheProfil(
  ficheProfil: FicheProfilForceOrdre,
): ProfilUniteFoRequestDto {
  return {
    adresse: ficheProfil.adresse || null,
    codePostal: ficheProfil.codePostal || null,
    email: ficheProfil.email || null,
    telephone: ficheProfil.telephone || null,
    ville: ficheProfil.commune || null,
  };
}

export async function validateProfilFOField(
  controller: ForceOrdreControllerApi,
  field: ProfilUniteFoViolationDtoFormFieldEnum,
  idCorrelationUniteFO: string,
  values: FicheProfilForceOrdre,
): Promise<void> {
  try {
    const response = await controller.validateUniteFoInfosFieldUsingPOST(
      field,
      idCorrelationUniteFO,
      requestDtoFromFicheProfil(values),
    );
    if (response.violationDtos) {
      const fieldViolations = response.violationDtos.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}
