import { useEffect, useState } from 'react';

import { ReferenceDtoFooterLinkDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { backAlertMessage } from '../utils/backAlertMessage';

type RefFooterLinkType = ReferenceDtoFooterLinkDto | undefined;

export const useRefFooterLink = (): RefFooterLinkType => {
  const [refFooterLink, setRefFooterLink] =
    useState<RefFooterLinkType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refFooterLink === undefined) {
      referentielControllerApi
        .getOnlyValidFooterLinkUsingGET()
        .then(fetchedRef => {
          setRefFooterLink(fetchedRef);
        })
        .catch(backAlertMessage);
    }
  }, [referentielControllerApi, refFooterLink]);
  return refFooterLink;
};
