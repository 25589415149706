import React from 'react';
import { Button as AntdButton } from 'antd';

import { useFormActionsContext } from 'components/WrappedComponents';

import { FilterDashboardCentreVHU } from '../../types';

import './FormFooter.less';

interface FormActionProps {
  setFilter: (value: FilterDashboardCentreVHU) => void;
}

export function FormFooter({ setFilter }: FormActionProps): React.ReactElement {
  const formActions = useFormActionsContext();

  function resetFilter() {
    formActions.setFieldsValue({
      idCorrelation: undefined,
      commune: undefined,
      codePostal: undefined,
      siren: undefined,
      raisonSociale: undefined,
      numeroAgrement: undefined,
    });
  }

  return (
    <div className={'form-footer-container'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type={'primary'}
        onClick={() => setFilter(formActions.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}

export default FormFooter;
