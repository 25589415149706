import { ExportFilesDto } from 'lib_api/lib/api/gen';
import moment, { Moment } from 'moment';
import {
  DownloadableFileMetaData,
  DownloadableFileStatus,
} from 'hooks/DownloadableFilesContext';
import { notification } from 'antd';

function getStatus(fileDto: ExportFilesDto): DownloadableFileStatus {
  if (fileDto.errorOccured) {
    return DownloadableFileStatus.ON_ERROR;
  }

  return fileDto.generated
    ? DownloadableFileStatus.SUCCESS
    : DownloadableFileStatus.IN_PROCESS;
}

function getDateFromRawString(rawDate?: string): Moment | undefined {
  if (!rawDate) {
    return undefined;
  }

  const date = moment(rawDate);

  return date.isValid() ? date : undefined;
}

function compareRawDates(rawDate1?: string, rawDate2?: string): number {
  const date1 = getDateFromRawString(rawDate1);
  const date2 = getDateFromRawString(rawDate2);

  return date1 && date2 ? date2.diff(date1) : 0;
}

export function sortAndConvertFileDtoToFileData(
  files: ExportFilesDto[],
): DownloadableFileMetaData[] {
  return files
    .sort((file1, file2) =>
      compareRawDates(
        file1.expirationDate ?? undefined,
        file2.expirationDate ?? undefined,
      ),
    )
    .map(file => {
      return {
        id: file.id,
        name: file.fileName,
        expirationDate: getDateFromRawString(
          file.expirationDate ?? undefined,
        )?.fromNow(),
        errorDetail: file.errorDescription ?? undefined,
        status: getStatus(file),
      };
    });
}

export function isAnyFileStillNotGenerated(
  files: DownloadableFileMetaData[],
): boolean {
  return files.some(file => file.status === DownloadableFileStatus.IN_PROCESS);
}

export function notifyFileGenerationInProgress(): void {
  notification.warn({
    message: 'Le fichier est en cours de téléchargement. Veuillez patienter.',
    duration: 5,
    placement: 'topLeft',
  });
}

/** Get the number of files that are available to download and that were previously being generated */
export function getNewlyGeneratedFilesNumber(
  previousFiles: DownloadableFileMetaData[],
  newFiles: DownloadableFileMetaData[],
): number {
  const newAvailableFiles = newFiles
    .filter(file => file.status === DownloadableFileStatus.SUCCESS)
    .filter(file =>
      // Filter only the files already known and that have not yet been generated
      previousFiles.some(
        prevFile =>
          prevFile.id === file.id &&
          prevFile.status === DownloadableFileStatus.IN_PROCESS,
      ),
    );

  return newAvailableFiles.length;
}
