import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';
import { CheckboxProps as AntdCheckboxProps } from 'antd/lib/checkbox';

import FieldWrapper from '../FieldWrapper';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { WrappedProps } from '../types';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  checkboxProps: AntdCheckboxProps;
}

function WrappedCheckbox<T>({
  wrapperProps,
  checkboxProps,
}: Props<T>): React.ReactElement {
  const checkbox = React.useMemo(() => {
    return <AntdCheckbox {...checkboxProps} />;
  }, [checkboxProps]);

  wrapperProps.itemProps.valuePropName = 'checked';
  wrapperProps.itemProps.validateTrigger = 'onChange';

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const value = formActions.getFieldValue(fieldKey);

  return (
    <FieldWrapper
      {...wrapperProps}
      ConsultationElement={value === true ? 'Oui' : 'Non'}
      WrappedInput={checkbox}
    />
  );
}

export default WrappedCheckbox;
