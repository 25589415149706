import React from 'react';

import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';

const RightSideDetailInfo = (): React.ReactElement => {
  const [{ result: dossier }] = useDossierAdminContext();

  return (
    <>
      <FieldDisplay
        key="fourriere"
        label="Fourrière"
        value={dossier.raisonSociale}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="autorite_fourriere"
        label="Autorité de fourrière"
        value={dossier.libelleComplet}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="uniteFO"
        label="Unité FO"
        value={dossier.unite}
        labelClass="labelAdmin"
      />
      <FieldDisplay
        key="parentFO"
        label="Unité parent FO"
        value={dossier.libelleUniteFoParent}
        labelClass="labelAdmin"
        emptyMessage="Unité sans parent"
      />
    </>
  );
};

export default RightSideDetailInfo;
