import React from 'react';

import { Select } from 'components/WrappedComponents';

import { useRefAutoriteFourriere } from 'hooks/referentiels/useRefAutoriteFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FilterDashboardFourriere } from 'types/referentiels/Fourriere';
import { CSSUtils } from 'utils/CSSUtils';
import { autoriteFourriereDtoToString } from 'utils/dtoToString';
import { FilterDashboardFourriereProps } from '../../../types';

function SelectAutoriteFourriere({
  values,
}: FilterDashboardFourriereProps): React.ReactElement {
  const key: keyof FilterDashboardFourriere = 'idCorrelationAF';
  const refAutoriteFourriere = useRefAutoriteFourriere();

  const selectItems =
    refAutoriteFourriere !== undefined
      ? refAutoriteFourriere.referenceDtoList
          .sort(
            (a, b) =>
              a.ville.localeCompare(b.ville) ||
              a.type.type.localeCompare(b.type.type),
          )
          .map(autoriteFourriere => ({
            value: autoriteFourriere.idCorrelation,
            displayValue: autoriteFourriereDtoToString(autoriteFourriere),
          }))
      : [];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Autorité de fourrière',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        allowClear: true,
      }}
    />
  );
}

export default SelectAutoriteFourriere;
