import React from 'react';

import FicheDescriptiveForm from '../../FicheDescriptive/FicheDescriptiveForm';

import './Creation.less';

export type CreationProps = {
  callAfterSubmit: () => void | null;
};

const Creation: React.FC<CreationProps> = ({
  callAfterSubmit,
}: CreationProps): React.ReactElement => {
  return (
    <div className={'Creation'}>
      <FicheDescriptiveForm onCreationSucceed={callAfterSubmit} />
    </div>
  );
};

export default Creation;
