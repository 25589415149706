import React from 'react';

import { DossierResponseDto } from 'lib_api/lib/api/gen';

import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { InformationBanner } from 'components/Banner';

function buildLabel(dossier: DossierResponseDto): React.ReactNode {
  return (
    <div>
      {`Ce véhicule possède un numéro d'immatriculation maquillé 
      ${dossier?.body?.ficheVehicule?.immatriculation?.numeroImmatriculationVisible} qui a été apposé lors d'un vol. 
      Le véritable numéro d'immatriculation de ce véhicule est ${dossier?.body?.ficheVehicule?.immatriculation?.numero}.`}
    </div>
  );
}

export const AlertDoublePlaque = (): React.ReactElement => {
  const [dossier] = useDossierContext();

  return (
    <div>
      {dossier?.body?.ficheVehicule?.immatriculation
        ?.numeroImmatriculationVisible && (
        <InformationBanner
          message={'Plaque maquillée'}
          description={buildLabel(dossier)}
          key={'plaqueMaquillee'}
          alertType={'warning'}
        />
      )}
    </div>
  );
};
