import React from 'react';

import { AutoriteFourriereFormValues } from '../type';
import {
  useSubmitCreateAutoriteFourriere,
  useValidateCreateAutoriteFourriere,
} from '../utils';
import ModalEditionAutoriteFourriere from './ModalEditionAutoriteFourriere';

interface ModalCreateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
}

export default function ModalCreate({
  close,
  visible,
  refreshDashboard,
}: ModalCreateProps): React.ReactElement {
  const initials: AutoriteFourriereFormValues = {};
  const { submit, inProgress } = useSubmitCreateAutoriteFourriere(
    refreshDashboard,
    close,
  );
  const { validate } = useValidateCreateAutoriteFourriere();

  return (
    <ModalEditionAutoriteFourriere
      close={close}
      visible={visible}
      title="Créer autorité de fourrière"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}
