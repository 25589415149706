import React from 'react';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';
import { Moment } from 'moment';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { DATE_FORMAT_WITH_TIME } from 'utils/formats';
import BaseDatePicker from './BaseDatePicker';

export default function BaseDateTimePicker(
  props: PickerDateProps<Moment>,
): React.ReactElement {
  return (
    <BaseDatePicker
      {...props}
      placeholder={FormPlaceholders.DateWithTime}
      format={DATE_FORMAT_WITH_TIME}
      showTime={true}
    />
  );
}
