import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button as AntdButton, Typography as AntdTypo } from 'antd';

import NotFound from 'assets/images/svg/icons/not_found.svg';

import './NotFoundPage.less';

const { Title } = AntdTypo;

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="NotFoundPage">
      <Title>Cette page est introuvable</Title>
      <div>
        <p>Erreur 404. La page que vous cherchez ne peut être trouvée.</p>
        <Link to="/">
          <AntdButton shape={'round'} type={'primary'} className="button">
            {"Page d'accueil"}
          </AntdButton>
        </Link>
        <AntdButton
          shape={'round'}
          className="button"
          onClick={() => navigate(-1)}
        >
          Page précédente
        </AntdButton>
      </div>
      <img className="NotFoundImg" src={NotFound} alt="Not found" />
    </div>
  );
};

export default NotFoundPage;
