import React, { useCallback, useState } from 'react';

import { Form, FormActions } from 'components/WrappedComponents';

import InputSearchCompte from './InputSearchCompte';

import { FilterDashboardComptesUtilisateurs } from '../../DashboardHeader/types';
import { FilterComptesUtilisateursProps } from './types';
import SelectProfile from './SelectProfile';
import SelectUniteRattachement from './SelectUniteRattachement';

const defaultValue: Partial<FilterDashboardComptesUtilisateurs> = {
  lastName: undefined,
  firstName: undefined,
  email: undefined,
  username: undefined,
  userType: undefined,
  entiteRattachement: undefined,
};

export default function AdvancedSearchForm({
  filter,
  setFilter,
}: FilterComptesUtilisateursProps): React.ReactElement {
  // Form internal values, used to filter only on submit
  const [formValues, setFormValues] = useState(filter);

  const updateFormValues = useCallback(
    (newValues: Partial<FilterDashboardComptesUtilisateurs>) => {
      // Clear entite rattachement filter if the type of the profile filter changes
      if (formValues.userType !== newValues.userType) {
        newValues.entiteRattachement = undefined;
      }

      setFormValues(newValues);
    },
    [formValues],
  );

  return (
    <div className="AdvancedSearchForm">
      <Form
        id="advancedSearchForm"
        initialValue={filter}
        validateButtonTitle="Rechercher"
        className="bordered"
        onSubmit={() =>
          new Promise(resolve => {
            setFilter(formValues);
            resolve();
          })
        }
        onChange={updateFormValues}
        validateButtonClassName="right"
      >
        {values => {
          return (
            <>
              <h3 className={'mainTitle'}>Recherche avancée</h3>
              <InputSearchCompte
                values={values}
                fieldKey="lastName"
                label="Nom"
              />
              <InputSearchCompte
                values={values}
                fieldKey="firstName"
                label="Prénom"
              />
              <InputSearchCompte
                values={values}
                fieldKey="email"
                label="Adresse e-mail"
              />
              <InputSearchCompte
                values={values}
                fieldKey="username"
                label="Identifiant"
              />
              <SelectProfile values={values} />
              <SelectUniteRattachement
                values={values}
                profile={values.userType}
              />
              <FormActions defaultValue={defaultValue} setFilter={setFilter} />
            </>
          );
        }}
      </Form>
    </div>
  );
}
