import { useState } from 'react';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { FicheDescriptiveFormValues } from './types';
import { ficheDescriptiveFormValuesToRequest } from './utils';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';

type SubmitFunction = {
  submit: (values: FicheDescriptiveFormValues) => void;
  inProgress: boolean;
};

function useSubmitCreateDossier(onSuccess: () => void): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { GardienFourriereControllerApi: gfController } = useApi();
  const user = useUserStore();
  const idCorrelationFourriere = user.idCorrelationFourriere;
  if (!idCorrelationFourriere) {
    throw new Error('id correlation fourriere should be defined');
  }
  return {
    submit: (values: FicheDescriptiveFormValues) => {
      setInProgress(true);
      const dossierRequest = ficheDescriptiveFormValuesToRequest(
        values,
        idCorrelationFourriere,
      );
      return gfController
        .createDossierWithInitialInfoUsingPOST(dossierRequest)
        .then(onSuccess)
        .catch(backAlertMessage)
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}

export default useSubmitCreateDossier;
