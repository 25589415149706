import { useState } from 'react';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { HookSearchTypeWithSort, ReferentielSearchTypeWithSort } from './type';

/**
 * This hook return all utils to build and search data for a referentiel
 * DtoType: Type of referentiel used
 * FilterType: Type of filter object
 * ReferentielSearchResultDto: Type of returned object by API
 *
 * @param hookSearchReferentiel Hook wich send request and convert result to readable data by dashboard
 * @param defaultFilter Filter set when we load dashboard
 * @param defaultSort
 * @returns {
 *  fetchData: function used to fetch API data in dashboard
 *  pagination: current pagination in dashboard
 *  setPagination: useEffect pagination setter
 *  filter: current filter in dashboard
 *  setFilter: useEffect filter setter
 *  refreshDashboard: reload search result in dashboard
 * }
 */
export function useReferentielDashboardSearchWithSort<
  DtoType,
  FilterType,
  SortType extends string | undefined,
  ReferentielSearchResultDto,
>(
  hookSearchReferentiel: HookSearchTypeWithSort<
    DtoType,
    FilterType,
    SortType,
    ReferentielSearchResultDto
  >,
  defaultFilter: FilterType,
  defaultSort: TableSort<SortType>,
): ReferentielSearchTypeWithSort<DtoType, FilterType, SortType> {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);

  const [filter, setFilter] = useState<FilterType>(defaultFilter);
  const [sort, setSort] = useState<TableSort<SortType>>(defaultSort);

  const [searchReferentiel, convertResultToFetchDataResult] =
    hookSearchReferentiel(filter, sort, pagination);

  return {
    fetchData: async () => {
      const result = await searchReferentiel().catch(e => {
        void backAlertMessage(e);
        throw e;
      });
      return convertResultToFetchDataResult(result);
    },
    pagination: pagination,
    setPagination: newPagination => {
      setPagination(newPagination);
    },
    filter: filter,
    setFilter: setFilter,
    sort: sort,
    setSort: setSort,
    refreshDashboard: () => {
      setFilter({ ...filter });
    },
  };
}
