import React, { useEffect, useState } from 'react';

import {
  AutocompleteUniteFOResultDtoFieldEnum,
  UniteFOAdminResponseDto,
  UniteFoViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import {
  buildFormValues,
  useSubmitUpdateUniteFo,
  useValidateUpdateUniteFo,
} from '../utils';
import { UniteFoFormValues } from 'types/referentiels/UniteFo';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import AutocompleteAdresse from 'components/BaseForm/Autocomplete/AutocompleteAdresse';
import BaseInputCodeInsee from 'components/BaseForm/BaseInputCodeInsee';
import ModalEdition from '../../Modals/ModalEdition/ModalEdition';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';
import { fetchUniteFo } from 'search/searchUniteFo';
import { useAsyncSearchUniteFo } from 'hooks/search/async/useAsyncSearchUniteFo';
import BaseSelectWithSearch from 'components/BaseForm/Select/BaseSelectWithSearch';

import './ModalUpdate.less';

interface ModalUpdateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
  unite: UniteFOAdminResponseDto;
}

export default function ModalUpdate({
  close,
  visible,
  refreshDashboard,
  unite,
}: ModalUpdateProps): React.ReactElement {
  const [initials, setInitials] = useState<UniteFoFormValues>(
    buildFormValues(unite),
  );
  const { submit, inProgress } = useSubmitUpdateUniteFo(
    unite.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateUniteFo(unite.idCorrelation);
  const behaviourOnError = useHandleBackErrors();
  const { ReferentielControllerApi: refController } = useApi();

  useEffect(() => {
    if (unite.idCorrelationParent) {
      fetchUniteFo(refController, unite.idCorrelationParent)
        .then(uniteParent => {
          setInitials({
            ...initials,
            uniteFoParent: {
              value: uniteParent.idCorrelation,
              label: uniteParent.abreviation,
              option: uniteParent,
            },
          });
        })
        .catch(() => behaviourOnError);
    }
  }, [unite.idCorrelationParent]);

  return (
    <ModalEdition
      close={close}
      visible={visible}
      title="Mettre à jour unité force de l'ordre"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
      inputs={[
        {
          field: UniteFoViolationDtoFormFieldEnum.TYPE,
          name: 'type',
          label: "Type d'unité",
          required: true,
          renderBeforeInput: () => {
            return (
              <p className="messageRpsi">
                Données issues du référentiel RPSI : toutes modifications de ces
                données seront écrasées en cas de mise à jour de cette unité
                dans le référentiel source RPSI
              </p>
            );
          },
          render: () => {
            return (
              <BaseSelect
                options={[
                  {
                    value: 'GN',
                    displayValue: 'GN',
                  },
                  {
                    value: 'PN',
                    displayValue: 'PN',
                  },
                  {
                    value: 'PP',
                    displayValue: 'PP',
                  },
                  {
                    value: 'PM',
                    displayValue: 'PM',
                  },
                ]}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.displayValue}
              />
            );
          },
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE,
          name: 'code',
          label: 'Code',
          required: true,
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.ABREVIATION,
          name: 'abreviation',
          label: "Abréviation de l'unité",
          required: true,
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.UNITE,
          name: 'unite',
          label: "Libellé de l'unité",
          required: true,
        },
        {
          name: 'adresse',
          label: "Adresse de l'unité",
          render: ({ name }) => {
            return (
              <AutocompleteAdresse
                name={name}
                communeField="ville"
                codePostalField="codePostal"
              />
            );
          },
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE_POSTAL,
          name: 'codePostal',
          label: 'Code postal',
        },
        {
          name: 'ville',
          label: 'Ville',
          normalize: (value: string) => value.toUpperCase(),
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.EMAIL,
          name: 'email',
          label: 'Courriel',
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.TELEPHONE,
          name: 'telephone',
          label: 'Téléphone',
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.PARENT,
          name: 'uniteFoParent',
          label: 'Unité parent',
          renderBeforeInput: () => {
            return (
              <p className="messageRpsi noMarginTop">
                Données hors du référentiel RPSI : les modifications de ces
                données ne seront pas impactées en cas de mise à jour de cette
                unité dans le référentiel source RPSI
              </p>
            );
          },
          render: () => {
            return (
              <BaseSelectWithSearch
                useSearchEntities={useAsyncSearchUniteFo(
                  AutocompleteUniteFOResultDtoFieldEnum.ABREVIATION,
                )}
                getOptionValue={option => option.idCorrelation}
                getOptionLabel={option => option.abreviation}
                allowClear
              />
            );
          },
        },
        {
          field: UniteFoViolationDtoFormFieldEnum.CODE_INSEE,
          name: 'codeInsee',
          label: 'Code INSEE',
          render: ({ name }) => {
            return (
              <BaseInputCodeInsee name={name} communeField="ctRattachement" />
            );
          },
        },
        {
          name: 'ctRattachement',
          label: 'CT de rattachement',
        },
      ]}
    />
  );
}
