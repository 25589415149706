import React from 'react';

import { AutoriteFourriereDto } from 'lib_api/lib/api/gen';

import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { AutoriteFourriereSortOptions } from 'enums/referentiels/AutoriteFourriere';
import { useReferentielDashboardSearchWithSort } from '../search/search';
import { buildHeader, useSearchAutoriteFourriere } from './utils';
import CellDetailsAndActions from './CellDetailsAndActions';

import './DashboardAutoriteFourriere.less';

function buildDetailsContent(
  data: AutoriteFourriereDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions
      autorite={data}
      refreshDashboard={refreshDashboard}
    />
  );
}

function DashboardAutoriteFourriere(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchAutoriteFourriere,
    {
      enabled: true,
    },
    {
      DEPARTEMENT: true,
      LIBELLE: true,
    },
  );

  function buildColumns(): ColumnProps<
    AutoriteFourriereDto,
    AutoriteFourriereSortOptions
  >[] {
    return [
      {
        title: 'Département',
        buildCellContent: data => data.libelleDepartement,
        sortedField: AutoriteFourriereSortOptions.DEPARTEMENT,
      },
      {
        title: 'Type',
        buildCellContent: data => data.type.type,
        sortedField: AutoriteFourriereSortOptions.TYPE,
      },
      {
        title: 'Libellé',
        buildCellContent: data => data.libelleComplet,
        sortedField: AutoriteFourriereSortOptions.LIBELLE,
      },
      {
        title: 'Code postal',
        buildCellContent: data => data.codePostal.padStart(5, '0'),
        sortedField: AutoriteFourriereSortOptions.CODE_POSTAL,
      },
      {
        title: 'Ville',
        buildCellContent: data => data.ville,
        sortedField: AutoriteFourriereSortOptions.VILLE,
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<
    AutoriteFourriereDto,
    AutoriteFourriereSortOptions
  >[] = buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardAutoriteFourriere;
