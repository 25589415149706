import { useCallback, useEffect, useState } from 'react';

import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen/api';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

interface FetchDossierAdminResult {
  dossier: DossierDtoAdminFonctionnel | null;
  setDossier: (dossier: DossierDtoAdminFonctionnel | null) => void;
  fetchDossier: () => Promise<void>;
  fetchInProgress: boolean;
  errorOccured: boolean;
}

export function useFetchDossierAdmin(
  dossierId: string,
): FetchDossierAdminResult {
  const [dossier, setDossier] = useState<DossierDtoAdminFonctionnel | null>(
    null,
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const { AdminFonctionnelControllerApi } = useApi();
  const behaviourOnError = useHandleBackErrors();

  const fetchDossier = useCallback(async () => {
    setDossier(null);
    setErrorOccured(false);
    setLoading(true);

    await AdminFonctionnelControllerApi.getInformationsByDossierIdUsingGET(
      dossierId,
    )
      .then(dossier => {
        setDossier(dossier);
      })
      .catch(errorResponse => {
        setErrorOccured(true);
        behaviourOnError(errorResponse);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [AdminFonctionnelControllerApi, dossierId, behaviourOnError]);

  const setter = (newDossier: DossierDtoAdminFonctionnel | null) => {
    setDossier(newDossier);
  };

  useEffect(() => {
    void fetchDossier();
  }, [AdminFonctionnelControllerApi, dossierId, fetchDossier]);

  return {
    dossier: dossier,
    setDossier: setter,
    fetchDossier: fetchDossier,
    fetchInProgress: loading,
    errorOccured: errorOccured,
  };
}
