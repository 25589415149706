import React from 'react';

import { Input } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FormFilterFieldCentreVHU } from '../../types';

function InputRaisonSociale({
  values,
}: FormFilterFieldCentreVHU): React.ReactElement {
  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: 'raisonSociale',
        itemProps: {
          label: 'Raison sociale',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}

export default InputRaisonSociale;
