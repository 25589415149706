import React, { useEffect, useState } from 'react';
import AntdForm from 'antd/lib/form';
import { Tooltip as AntdTooltip } from 'antd';

import { WrapperUntypedProps } from './types';
import { isValueDefined } from './utils';
import { useFormActionsContext } from '../FormWrapper/FormActionsContext';

import './FieldWrapper.less';

interface Props extends WrapperUntypedProps {
  WrappedInput: React.ReactElement;
  ConsultationElement: React.ReactElement | string | undefined;
}

function FieldWrapperUntyped({
  itemProps,
  WrappedInput,
  fieldKey,
  editable,
  ConsultationElement,
  tooltipProps,
}: Props): React.ReactElement {
  const formActions = useFormActionsContext();
  const [isFieldTouched, setIsFieldTouched] = useState<boolean>(
    isValueDefined(formActions.getFieldValue(fieldKey)),
  );

  useEffect(() => {
    //  is the field display an error and has not been touched yet (ie -> try to submit the form with the submit button)
    //  we set the field as touched
    if (formActions.getFieldError(fieldKey).length > 0 && !isFieldTouched) {
      //  set field as touched for custom isFieldTouched
      setIsFieldTouched(true);

      //  fix an issue from ant
      //  detail :
      //  if you submit the form with some fields not touched yet (meaning you have not set this field value)
      //  antd will display the errors but validateTrigger of Form.Input is not updated
      //  fix :
      //  I force the form to refresh fields status, your input will
      //  validate when you changes its value and not when you exit the field
      const values = formActions.getFieldsValue() as Record<string, unknown>;
      const fields = Object.keys(values).map(key => {
        return {
          touched: true,
          validating: false,
          errors: formActions.getFieldError(key),
          name: key,
          value: values[key],
        };
      });
      formActions.setFields(fields);
    }
  }, [formActions, fieldKey, isFieldTouched]);

  // If we consult data, we don't render input component but we just display value
  if (editable === false) {
    return (
      <AntdForm.Item {...itemProps}>
        {ConsultationElement !== undefined ? (
          <span className="ConsultationValue">{ConsultationElement}</span>
        ) : (
          <span>Missing value</span>
        )}
      </AntdForm.Item>
    );
  }

  //  this is use to overide Antd Field default validation behavior
  if (isFieldTouched) {
    //  if the field is touched we want to valide the value on every change
    //  we don't display the feedback because of user's understanding issues.
    if (itemProps.hasFeedback === undefined) {
      itemProps.hasFeedback = false;
    }

    //  this prop configure when the form try to valide the field
    //  set to onChange to validate the field everytime it changes
    if (itemProps.validateTrigger === undefined) {
      itemProps.validateTrigger = 'onChange';
    }
  } else {
    //  if the field is not touched
    //  when we are typing a field for the first time we don't try to valide the value everytime the value change but rather when we exit the field

    //  we don't display the feedback since we are not validating the field
    if (itemProps.hasFeedback === undefined) {
      itemProps.hasFeedback = false;
    }

    //  this prop configure when the form try to valide the field
    //  set to onBlur to validate the field only when we exit the field
    if (itemProps.validateTrigger === undefined) {
      itemProps.validateTrigger = 'onBlur';
    }
  }

  //  inject custom onBlur function to trigger touched field
  const ControlledInput = React.cloneElement(WrappedInput, {
    onBlur: () => {
      setIsFieldTouched(true);
    },
  });

  const formItem = (
    <AntdForm.Item {...itemProps} name={fieldKey}>
      {ControlledInput}
    </AntdForm.Item>
  );

  if (tooltipProps !== undefined) {
    return <AntdTooltip {...tooltipProps}>{formItem}</AntdTooltip>;
  } else {
    return formItem;
  }
}

export default FieldWrapperUntyped;
