import { useCallback } from 'react';

import { MarqueDto, ModeleDto } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { VALUE_NON_REFERENCE } from 'utils/global';
import { fetchModeleByMarqueIdCorrelation } from 'search/searchModeleByMarqueIdCorrelation';
import { BaseOptionType } from '../../components/BaseForm/Select/BaseSelect';

export const useFetchModeleByMarqueIdCorrelation = (): [
  (marque: BaseOptionType<MarqueDto> | null) => Promise<Array<ModeleDto>>,
] => {
  const { ReferentielControllerApi: refController } = useApi();

  const fetch = useCallback((marque: BaseOptionType<MarqueDto> | null) => {
    if (!marque?.option?.id || marque.value === VALUE_NON_REFERENCE) {
      return Promise.resolve([]);
    }

    return fetchModeleByMarqueIdCorrelation(
      refController,
      marque.option?.idCorrelation || '',
    );
  }, []);

  return [fetch];
};
