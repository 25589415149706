import React from 'react';
import moment from 'moment';
import { Tag as AntdTag } from 'antd';

import {
  ActionsRequisesDtoActionsRequisesEnum,
  DossierSummaryResponseDto,
} from 'lib_api/lib/api/gen/api';
import {
  ColumnProps,
  TableHeaderFunction,
} from 'components/WrappedComponents/Table/types';

import { getStatutFromDossier } from 'utils/StatutUtils';
import { buildActionsRequisesContent } from 'utils/actionsRequisesUtils';
import { classementEnumMapping, statutEnumMapping } from 'utils/enumData';
import { DATE_FORMAT } from 'utils/formats';
import { SortOptions } from 'hooks/dossiers/useSearchDossierSummaries';
import { DashboardFOFilter } from '../types';
import DashboardHeader from '../DashboardHeader';
import { DashbardImmatriculationCell } from 'components/Dashboard/DashboardImmatriculationCell/DashboardImmatriculationCell';

function buildImmatriculationContent(
  url: string,
  data: DossierSummaryResponseDto,
) {
  return <DashbardImmatriculationCell url={url} dossier={data} />;
}

function buildStatutContent(data: DossierSummaryResponseDto): React.ReactNode {
  const statut = getStatutFromDossier(data);

  if (statut === undefined) {
    return null;
  } else {
    const { tagColor, label } = statutEnumMapping[statut];
    return <AntdTag color={tagColor}>{label}</AntdTag>;
  }
}

export function buildColumns(
  url: string,
  foActionsRequises: ActionsRequisesDtoActionsRequisesEnum[] | undefined,
): ColumnProps<DossierSummaryResponseDto, SortOptions>[] {
  return [
    {
      title: 'Immatriculation',
      sortedField: SortOptions.IMMATRICULATION,
      buildCellContent: data => buildImmatriculationContent(url, data),
    },
    {
      title: "Date d'entrée",
      sortedField: SortOptions.DATE_MISE_FOURRIERE,
      buildCellContent: data =>
        data.ficheFourriere !== null &&
        data.ficheFourriere.dateMiseEnFourriere !== null
          ? moment(data.ficheFourriere.dateMiseEnFourriere).format(DATE_FORMAT)
          : null,
    },
    {
      title: 'Nature de mise en fourrière',
      sortedField: SortOptions.NATURE_MISE_EN_FOURRIERE,
      buildCellContent: data =>
        data.computed !== null && data.computed.brancheDto !== null
          ? data.computed.brancheDto.nature
          : null,
    },
    {
      title: 'Statut',
      sortedField: SortOptions.STATUT,
      buildCellContent: buildStatutContent,
    },
    {
      title: 'Classement',
      sortedField: SortOptions.CLASSEMENT,
      buildCellContent: data =>
        data.traitement && data.traitement.classement
          ? classementEnumMapping[data.traitement.classement].label
          : null,
    },
    {
      title: "Date d'ordre de destruction",
      sortedField: SortOptions.DATE_ORDRE_DESTRUCTION,
      buildCellContent: data =>
        data.traitement && data.traitement.dateOrdreDestruction
          ? moment(data.traitement.dateOrdreDestruction).format(DATE_FORMAT)
          : null,
    },
    {
      title: 'Date de sortie définitive',
      sortedField: SortOptions.DATE_SORTIE_DEFINITIVE,
      buildCellContent: data =>
        data.sortie !== null && data.sortie.dateSortie !== null
          ? moment(data.sortie.dateSortie).format(DATE_FORMAT)
          : null,
    },
    {
      title: 'Fourrière',
      sortedField: SortOptions.FOURRIERE,
      buildCellContent: data =>
        data.computed?.fourriereDto?.raisonSociale || null,
    },
    {
      title: 'Unité',
      buildCellContent: data => data.computed?.uniteFo || null,
    },
    {
      title: 'Action requise',
      sortedField: SortOptions.ACTIONS_REQUISES,
      buildCellContent: data =>
        buildActionsRequisesContent(url, data, foActionsRequises),
    },
  ];
}

export const buildHeader: TableHeaderFunction<
  DossierSummaryResponseDto,
  DashboardFOFilter
> = (fetchedData, _selectedData, filter, setFilter, sort) => (
  <DashboardHeader
    totalResult={fetchedData !== undefined ? fetchedData.total : 0}
    filter={filter}
    setFilter={setFilter}
    sort={sort}
  />
);
