import React from 'react';
import {
  Button as AntdButton,
  Menu as AntdMenu,
  Dropdown as AntdDropdown,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import {
  ConditionsReactivateFaqDomainDto,
  FaqDomainAdminDto,
} from 'lib_api/lib/api/gen';

import ModalModification from './Modals/Modification/ModalModification';
import ModalDesactivation from '../Modals/ModalDesactivation/ModalDesactivation';
import ModalReactivation from '../Modals/ModalReactivation/ModalReactivation';
import { useApi } from 'hooks/ApiStoreContext';
import { useChangeValidityFaqDomain } from './utils';
import ConditionReactivateFaqDomain from './ConditionReactivateFaqDomain/ConditionReactivateFaqDomain';
import { useModal } from 'hooks/modal/useModal';

import './CellDetailsAndActions.less';

interface CellDetailsAndActionsProps {
  domaine: FaqDomainAdminDto;
  refreshDashboard: () => void;
}

function CellDetailsAndActions({
  domaine,
  refreshDashboard,
}: CellDetailsAndActionsProps): React.ReactElement {
  const updateModal = useModal();
  const validityModal = useModal();

  const controller = useApi().AdminFonctionnelControllerApi;

  const [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ] = useChangeValidityFaqDomain(controller);

  const conditionUnlock = (
    conditions: ConditionsReactivateFaqDomainDto | undefined,
  ): boolean => {
    return conditions?.reactivationAvailable ?? false;
  };

  const menu = (
    <AntdMenu>
      <AntdMenu.Item key={'update'} onClick={updateModal.open}>
        Modifier
      </AntdMenu.Item>
      <AntdMenu.Item key={'valid'} onClick={validityModal.open}>
        {domaine.valid ? 'Désactiver le domaine' : 'Réactiver le domaine'}
      </AntdMenu.Item>
    </AntdMenu>
  );

  return (
    <>
      <ModalModification
        refreshDashboard={refreshDashboard}
        faqDomain={domaine}
        visible={updateModal.visible}
        close={updateModal.close}
      />
      <AntdDropdown overlay={menu} className="menuActions">
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
      {domaine.valid ? (
        <ModalDesactivation
          idCorrelation={domaine.idCorrelation}
          titleModal="Désactiver le domaine"
          usesRequest={undefined}
          submitDeprecate={submitDeprecateReferentiel}
          componentUses={undefined}
          visible={validityModal.visible}
          close={validityModal.close}
          callAfterSubmit={refreshDashboard}
        />
      ) : (
        <ModalReactivation
          idCorrelation={domaine.idCorrelation}
          titleModal="Réactiver le domaine"
          visible={validityModal.visible}
          close={validityModal.close}
          callAfterSubmit={refreshDashboard}
          conditionRequest={conditionsReactivateReferentiel}
          componentConditions={ConditionReactivateFaqDomain}
          conditionUnlock={conditionUnlock}
          reactivateRequest={submitReactivateReferentiel}
        />
      )}
    </>
  );
}

export default CellDetailsAndActions;
