import React from 'react';
import { Empty as AntdEmpty, Spin as AntdSpin } from 'antd';
import { NamePath as AntdNamePath } from 'rc-field-form/lib/interface';

import { ModeleDto } from 'lib_api/lib/api/gen';

import BaseSelectMultiList from 'components/BaseForm/Select/BaseSelectMultiList';
import { CommonBaseSelectProps } from 'components/BaseForm/Select/CommonBaseSelect';
import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import { LABEL_MODELE_NON_REFERENCE, VALUE_NON_REFERENCE } from 'utils/global';
import { useFetchModeles } from './utils';

interface SelectModeleMultiListProps<FormValues>
  extends Omit<
    CommonBaseSelectProps<FormValues, ModeleDto | null>,
    'options' | 'getOptionValue' | 'getOptionLabel' | 'onBottomReached'
  > {
  idMarqueField: keyof FormValues & AntdNamePath;
}

const modeleNonReferencee: ModeleDto = {
  id: VALUE_NON_REFERENCE,
  idCorrelation: null,
  libelle: LABEL_MODELE_NON_REFERENCE,
  prioritaire: null,
  marque: '',
};

export default function BaseSelectModeleMultiList<FormValues>({
  idMarqueField,
  ...props
}: SelectModeleMultiListProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  const idMarque = form.getFieldValue(idMarqueField);
  const { modeles, inProgress, search, paginate } = useFetchModeles(
    false,
    idMarque,
  );
  const { modeles: modelesPrio, inProgress: inProgressPrio } = useFetchModeles(
    true,
    idMarque,
  );

  return (
    <BaseSelectMultiList
      {...props}
      options={[
        {
          label: 'Modèles fréquents',
          options: modelesPrio,
        },
        {
          label: 'Autres',
          options: [modeleNonReferencee, ...modeles],
        },
      ]}
      notFoundContent={
        inProgress || inProgressPrio ? <AntdSpin size="small" /> : <AntdEmpty />
      }
      getOptionLabel={(modele: ModeleDto) => modele.libelle}
      getOptionValue={(modele: ModeleDto) => modele.id}
      showSearch
      onSearch={search}
      onBottomReached={paginate}
    />
  );
}
