import React from 'react';

import CardLayout from 'components/CardLayout/CardLayout';
import UploadCard from 'components/Card/UploadCard';
import Downloader from 'components/WrappedComponents/Downloader/Downloader';
import { ReferentielEnum } from 'types/Referentiel';
import { RequestStatusByName } from 'types/RequestStatus';

import './ReferentielExportComponent.less';

interface Props<T extends ReferentielEnum> {
  title: string;
  values: { [key: string]: T };
  submit: (value: T, name: string) => Promise<void>;
  status: RequestStatusByName;
}

/**
 * This component is a generic component that can handle the export of
 * several CSV or Json documents
 *
 * @param title
 * @param values: object that maps for each document a displayable value with
 *  its corresponding referentiel enum value (which can either be a value of
 *  'ReferentielEnumqDtoReferentialEnumJSONEnum' or 'ReferentielEnumDtoReferentialEnumCSVEnum'
 * @param submit: function to call when clicking on the download button
 * @param status: for each document indicate the status of the download
 * @constructor
 */
function ReferentielExportComponent<T extends ReferentielEnum>({
  title,
  values,
  submit,
  status,
}: Props<T>): React.ReactElement {
  const sortedValues = Object.entries(values).sort();

  return (
    <div className="ReferentielExportComponent">
      <h2 className="title">{title}</h2>
      <CardLayout>
        {sortedValues.map(([displayableValue, rawValue], index) => (
          <UploadCard key={index} status={status[displayableValue]}>
            <Downloader
              title={displayableValue}
              download={() => submit(rawValue, displayableValue)}
            />
          </UploadCard>
        ))}
      </CardLayout>
    </div>
  );
}

export default ReferentielExportComponent;
