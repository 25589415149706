import {
  ReferenceDtoStatutDtoStatutsEnum,
  ReferentielControllerApi,
} from 'lib_api/lib/api/gen';

export async function fetchStatutsConsultables(
  refController: ReferentielControllerApi,
): Promise<ReferenceDtoStatutDtoStatutsEnum[]> {
  return refController.getStatutsConsultablesUsingGET().then(response => {
    return response.statuts;
  });
}
