import React from 'react';
import { Button as AntdButton } from 'antd';
import AntdForm from 'antd/lib/form';

import { Form } from 'components/WrappedComponents';
import { UpdateFicheFormValues } from './types';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import InputJustification from './Field/InputJustification/InputJustification';
import WarningMessage from 'components/WarningMessage/WarningMessage';

import './UpdateFicheModal.less';

interface Violation<FieldKey> {
  formField: FieldKey;
  message: string;
}

/**
 * All parameters for the generic modal
 *
 * @param visible: boolean to know if modal is visible or not
 * @param close: Function wich close modal (set visible to false)
 * @param modalTitle: Title of modal displayed
 * @param children: Content of form
 */
interface UpdateFicheModalProps<
  FormValues extends UpdateFicheFormValues,
  FieldKey,
  ViolationType,
> {
  modalTitle: string;
  warningMessage: string;
  initialValues: FormValues;
  onSubmit: (values: FormValues) => Promise<void>;
  closeModal: () => void;
  controllerValidateField?: (
    values: FormValues,
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => Promise<ViolationType>;
  validationKey?: keyof ViolationType;
  commentFieldKey: FieldKey;
  children: (
    values: FormValues,
    validateField: (
      key: keyof FormValues,
      field: FieldKey,
      values: unknown,
    ) => Promise<void>,
  ) => React.ReactElement;
}
function UpdateFicheModal<
  FormValues extends UpdateFicheFormValues,
  FieldKey,
  ViolationType,
>({
  initialValues,
  modalTitle,
  warningMessage,
  onSubmit,
  closeModal,
  controllerValidateField,
  validationKey,
  commentFieldKey,
  children,
}: UpdateFicheModalProps<
  FormValues,
  FieldKey,
  ViolationType
>): React.ReactElement {
  const [form] = AntdForm.useForm();

  // Generic method to validate a field
  const validateField: (
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => Promise<void> = async (
    key: keyof FormValues,
    field: FieldKey,
    value: unknown,
  ) => {
    if (!controllerValidateField || !validationKey) {
      return Promise.resolve();
    }
    try {
      const response: ViolationType = await controllerValidateField(
        form.getFieldsValue(),
        key,
        field,
        value,
      );
      const violations = response[validationKey];
      if (violations && Array.isArray(violations)) {
        const fieldViolations = violations.filter(
          (violation: Violation<FieldKey>) => violation.formField === field,
        );

        if (fieldViolations.length > 0) {
          return Promise.reject(
            fieldViolations
              .map((violation: Violation<FieldKey>) => violation.message)
              .join(' '),
          );
        }
      }
    } catch (e) {
      if (e instanceof Response) {
        return Promise.reject((await backAlertMessage(e)).description);
      }
    }

    return Promise.resolve();
  };

  return (
    <Form
      form={form}
      className={'UpdateFicheForm'}
      initialValue={initialValues}
      id={'UpdateFicheModal'}
      onSubmit={onSubmit}
      validateButtonTitle={'Enregistrer'}
      validateButtonClassName={'ValidateFormButton'}
    >
      {(values, SubmitButton): React.ReactElement => {
        return (
          <>
            <h3>{modalTitle}</h3>
            {children(values, validateField)}
            <InputJustification
              values={values}
              validateField={validateField}
              fieldKey={commentFieldKey}
            />

            <WarningMessage
              title="Conséquence de la modification"
              detail={warningMessage}
            />

            <div className="ModalFooter">
              <AntdButton onClick={closeModal}>Annuler</AntdButton>
              {SubmitButton}
            </div>
          </>
        );
      }}
    </Form>
  );
}

export default UpdateFicheModal;
