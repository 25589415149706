import React from 'react';
import { Spin as AntdSpin } from 'antd';

import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';
import { Empty } from 'components/WrappedComponents/Empty';

import { useRefAutoriteFourriere } from 'hooks/referentiels/useRefAutoriteFourriere';
import { useRefFourriere } from 'hooks/referentiels/useRefFourriere';
import { useRefUniteFoSearch } from 'hooks/referentiels/useRefUniteFoSearch';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import {
  autoriteFourriereToItem,
  fourriereToItem,
  uniteFoToItem,
} from '../../../Modification/utils';
import { FilterDashboardComptesUtilisateurs } from '../../DashboardHeader/types';
import { OptionProps } from 'types/searchResult';

interface SelectUniteRattachement
  extends FieldProps<FilterDashboardComptesUtilisateurs> {
  profile?: UserDtoProfileEnum;
}

function SelectUniteRattachement({
  values,
  profile,
}: SelectUniteRattachement): React.ReactElement {
  const [refUniteFo, searchUniteFo, isFetchingUniteFo, fetchUniteFo] =
    useRefUniteFoSearch();
  const refAutoriteFourriere = useRefAutoriteFourriere();
  const refFourriere = useRefFourriere();

  const key: keyof FilterDashboardComptesUtilisateurs = 'entiteRattachement';

  const profileWithUniteRattachement: UserDtoProfileEnum[] = [
    UserDtoProfileEnum.GARDIEN_FOURRIERE,
    UserDtoProfileEnum.AUTORITE_FOURRIERE,
    UserDtoProfileEnum.FORCE_DE_L_ORDRE,
  ];

  // content if no data foud
  let notFoundContent = undefined;
  // list of options of select
  let selectItems: OptionProps[] = [];
  // action on search in select
  let onSearch = undefined;

  switch (profile) {
    case UserDtoProfileEnum.GARDIEN_FOURRIERE:
      selectItems =
        refFourriere !== undefined
          ? // Ref fetched
            refFourriere.referenceDtoList
              .sort((a, b) => a.raisonSociale.localeCompare(b.raisonSociale))
              .map(fourriereToItem)
          : [];
      break;
    case UserDtoProfileEnum.AUTORITE_FOURRIERE:
      selectItems =
        refAutoriteFourriere !== undefined
          ? // Ref fetched
            refAutoriteFourriere.referenceDtoList
              .sort((a, b) => a.libelleComplet.localeCompare(b.libelleComplet))
              .map(autoriteFourriereToItem)
          : [];
      break;
    case UserDtoProfileEnum.FORCE_DE_L_ORDRE:
      selectItems =
        refUniteFo !== undefined
          ? // Ref fetched
            refUniteFo.referenceDtoList
              .sort((a, b) => a.abreviation.localeCompare(b.abreviation))
              .map(uniteFoToItem)
          : [];
      notFoundContent = isFetchingUniteFo ? (
        <AntdSpin size="small" />
      ) : searchUniteFo !== undefined && searchUniteFo !== '' ? (
        <Empty />
      ) : null;
      onSearch = fetchUniteFo;
      break;
    default:
      break;
  }

  return refFourriere !== undefined && refAutoriteFourriere !== undefined ? (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Entité de rattachement',
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.XLargeWrapperCol },
          getValueProps: (value: unknown) => {
            if (typeof value === 'string') {
              return { value: value };
            }
            return {
              value: undefined,
            };
          },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        disabled:
          profile === undefined ||
          !profileWithUniteRattachement.includes(profile),
        placeholder: FormPlaceholders.Select,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        onSearch: onSearch,
        notFoundContent: notFoundContent,
        allowClear: true,
      }}
    />
  ) : (
    <div className="WaitingForReferentiels">
      <label className="label" title="Entité de rattachement">
        Entité de rattachement
      </label>
      <div className="input">
        <AntdSpin />
      </div>
    </div>
  );
}

export default SelectUniteRattachement;
