import React from 'react';
import { Modal as AntdModal } from 'antd';
import {
  ComputedResponseDtoListActionsEnum,
  ComputedResponseDtoListAvailableFilesEnum,
} from 'lib_api/lib/api/gen';
import { useLocation } from 'react-router-dom';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { useGFFilterContext } from 'hooks/GFFilterStoreContext';
import { useDisplayActionsRequisesBanner } from 'hooks/useActionsRequises';
import { AvailableAction } from 'components/ActionAndFileWrapper/types';
import ActionAndFileWrapper from 'components/ActionAndFileWrapper/ActionAndFileWrapper';
import { ActionsRequisesLinkState } from 'utils/actionsRequisesUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import { filterUndefinedOrNull } from 'utils';

import SortieVehiculeForm from '../../Components/SubForms/SortieVehiculeSubForm/SortieVehiculeForm';
import BonEnlevementGardienForm from '../../Components/SubForms/BonEnlevementGardienSubForm/BonEnlevementGardienForm';
import EntreeVehiculeForm from '../../Components/SubForms/EntreeVehiculeSubForm/EntreeVehiculeForm';

const ActionsSuiviVehicule = (): React.ReactElement => {
  const { state } = useLocation() as { state: ActionsRequisesLinkState };
  const [isSortieOpen, setIsSortieOpen] = React.useState<boolean>(false);
  const [isBonEnlevementOpen, setIsBonEnlevementOpen] = React.useState<boolean>(
    state?.openSaisieVhu || false,
  );
  const [isEnregistrerEntreeOpen, setIsEnregistrerEntreeOpen] =
    React.useState<boolean>(state?.openEntreeVehicule || false);

  const [dossier, setDossier] = useDossierContext();
  const [, , gfActionsRequises] = useGFFilterContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);

  // display the actions requises banner
  useDisplayActionsRequisesBanner(dossier, gfActionsRequises);

  function mapComputedListActionsEnumToAvailableAction(
    actionEnum: ComputedResponseDtoListActionsEnum,
  ): AvailableAction | null {
    switch (actionEnum) {
      case ComputedResponseDtoListActionsEnum.SAISIE_VHU:
        return {
          title: 'Renseigner le centre VHU',
          onClick: () => {
            setIsBonEnlevementOpen(true);
          },
        };

      case ComputedResponseDtoListActionsEnum.RENSEIGNER_SORTIE_VEHICULE:
        return {
          title: 'Enregistrer la sortie',
          onClick: () => {
            setIsSortieOpen(true);
          },
        };

      case ComputedResponseDtoListActionsEnum.ENREGISTRER_ENTREE:
        return {
          title: "Enregistrer l'entrée",
          onClick: () => {
            setIsEnregistrerEntreeOpen(true);
          },
        };

      default:
        return null;
    }
  }

  const filesList: ComputedResponseDtoListAvailableFilesEnum[] =
    dossier.computed?.listAvailableFiles ?? [];
  const actions: AvailableAction[] = filterUndefinedOrNull(
    dossier.computed?.listActions?.map(action => {
      return mapComputedListActionsEnumToAvailableAction(action);
    }) || [],
  );

  return (
    <>
      <ActionAndFileWrapper
        filesList={filesList}
        actions={actions}
        dossierId={dossierId}
      />

      {/* Action sortie du véhicule */}
      <AntdModal
        visible={isSortieOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setIsSortieOpen(false)}
      >
        <SortieVehiculeForm dossier={dossier} />
      </AntdModal>

      {/* Action entrée véhicule */}
      <AntdModal
        visible={isEnregistrerEntreeOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setIsEnregistrerEntreeOpen(false)}
      >
        <EntreeVehiculeForm close={() => setIsEnregistrerEntreeOpen(false)} />
      </AntdModal>

      {/* Action renseignement des coordonnées VHU */}
      <AntdModal
        visible={isBonEnlevementOpen}
        destroyOnClose={true}
        footer={null}
        onCancel={() => setIsBonEnlevementOpen(false)}
        width={'40%'}
      >
        <BonEnlevementGardienForm
          dossier={dossier}
          setDossier={setDossier}
          close={() => setIsBonEnlevementOpen(false)}
        />
      </AntdModal>
    </>
  );
};

export default ActionsSuiviVehicule;
