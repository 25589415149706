import { useState, useEffect } from 'react';

import { ReferenceDtoBrancheDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefNatureType = ReferenceDtoBrancheDto | undefined;

export const useRefBrancheNature = (): RefNatureType => {
  const behaviourOnError = useHandleBackErrors();

  const [refBrancheNature, setRefBrancheNature] =
    useState<RefNatureType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refBrancheNature === undefined) {
      referentielControllerApi
        .getAllValidNaturesUsingGET()
        .then(fetchedRef => {
          setRefBrancheNature(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, refBrancheNature]);

  return refBrancheNature;
};
