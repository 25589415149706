import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { NOT_FOUND_PATH } from 'utils/enumData';
import useActiveHermesSolution from '../useActiveHermesSolution';
import Import from './Import';

const RoutesDomaine: React.FC = () => {
  const activeSolution = useActiveHermesSolution();

  return (
    <Routes>
      {(!activeSolution || activeSolution?.contournementImportEnabled) && (
        <Route path="import" element={<Import />} />
      )}
    </Routes>
  );
};

export default RoutesDomaine;
