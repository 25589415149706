import { useState, useEffect } from 'react';

import { ReferenceDtoFourriereDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useRefFourriereAutoriteFourriere = (
  idCorrelationAutoriteFourriere: string,
  callApi = true,
): ReferenceDtoFourriereDto | undefined => {
  const behaviourOnError = useHandleBackErrors();

  const [fourrieres, setFourrieres] = useState<
    ReferenceDtoFourriereDto | undefined
  >(undefined);
  const controllerApi = useApi().AdminFonctionnelControllerApi;

  useEffect(() => {
    if (callApi && fourrieres === undefined) {
      controllerApi
        .getFourrieresFromValidAutoriteFourriereUsingGET(
          idCorrelationAutoriteFourriere,
        )
        .then(fetchedRef => {
          setFourrieres(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [controllerApi, behaviourOnError, callApi, fourrieres]);

  return fourrieres;
};
