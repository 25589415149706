import React, { useEffect, useState } from 'react';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import { ModeleDto } from 'lib_api/lib/api/gen';

import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import BaseSelect, {
  BaseSelectProps,
} from 'components/BaseForm/Select/BaseSelect';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useFetchModeleByMarqueIdCorrelation } from 'hooks/search/useSearchModeleByMarqueIdCorrelation';
import { LABEL_MODELE_NON_REFERENCE, VALUE_NON_REFERENCE } from 'utils/global';

interface SelectModeleByIdCorrelationMarqueProps<FormValues>
  extends Omit<
    BaseSelectProps<FormValues, ModeleDto | null>,
    'options' | 'getOptionValue' | 'getOptionLabel'
  > {
  idMarqueField: keyof FormValues & AntdNamePath;
}

export default function SelectModeleByIdCorrelationMarque<FormValues>({
  idMarqueField,
  ...props
}: SelectModeleByIdCorrelationMarqueProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext();
  const [modeles, setModeles] = useState<Array<ModeleDto>>([]);
  const behaviourOnError = useHandleBackErrors();
  const idMarque = form.getFieldValue(idMarqueField);
  const [fetchModeles] = useFetchModeleByMarqueIdCorrelation();

  useEffect(() => {
    setModeles([]);
    if (!idMarque) {
      return;
    }
    fetchModeles(idMarque).then(setModeles).catch(behaviourOnError);
  }, [idMarque]);

  return (
    <BaseSelect
      options={[null, ...modeles]}
      getOptionValue={(modele: ModeleDto | null) =>
        modele ? modele.idCorrelation || '' : VALUE_NON_REFERENCE
      }
      getOptionLabel={(modele: ModeleDto | null) =>
        modele ? modele.libelle : LABEL_MODELE_NON_REFERENCE
      }
      showSearch
      {...props}
    />
  );
}
