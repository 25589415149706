import {
  FourriereDto,
  ReferentielControllerApi,
  ReferentielSearchResultDtoFourriereDto,
} from 'lib_api/lib/api/gen';

import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { FilterDashboardFourriere } from 'types/referentiels/Fourriere';
import { FourriereSort } from 'enums/referentiels/Fourriere';

export function searchFourriereUsingSpecs(
  controller: ReferentielControllerApi,
  filter: FilterDashboardFourriere,
  sort: TableSort<FourriereSort>,
  pagination: TablePagination,
): Promise<ReferentielSearchResultDtoFourriereDto> {
  return controller.searchFourriereUsingSpecsUsingGET({
    filters: {
      ...filter,
      page: pagination.page,
      pageSize: pagination.pageSize,
      commune: filter.commune?.commune,
    },
    sorts: {
      codePostal: sort?.CODE_POSTAL,
      commune: sort?.COMMUNE,
      raisonSociale: sort?.RAISON_SOCIALE,
      raisonSocialeDnid: sort?.RAISON_SOCIALE_DNID,
    },
  });
}

export function fetchFourriere(
  refController: ReferentielControllerApi,
): Promise<FourriereDto[]> {
  return refController.getValidFourriereUsingGET().then(response => {
    return response.referenceDtoList;
  });
}
