import {
  DossierResponseDto,
  DossierSummaryResponseDto,
  TraitementResponseDtoListStatutEnum,
} from 'lib_api/lib/api/gen/api';

export function getStatutFromDossier(
  data: DossierSummaryResponseDto | DossierResponseDto,
): TraitementResponseDtoListStatutEnum | undefined {
  const key = data.computed?.statutConsultable ?? undefined;
  if (!key) {
    return undefined;
  }

  if (Object.keys(TraitementResponseDtoListStatutEnum).includes(key)) {
    // Typescript does not understand key has been verified as a key of this enum
    return TraitementResponseDtoListStatutEnum[
      key as keyof typeof TraitementResponseDtoListStatutEnum
    ];
  }
  return undefined;
}
