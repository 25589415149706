import React, { useState } from 'react';

import ComptesUtilisateursFilterProvider from 'hooks/FilterComptesUtilisateursStoreContext';
import PaginationWithoutTotalProvider from 'hooks/PaginationWithoutTotalStoreContext';
import { PAGINATION_WITHOUT_TOTAL_DEFAULT } from 'utils/DashboardUtils';
import { FilterDashboardComptesUtilisateurs } from './Dashboard/DashboardHeader/types';
import DashBoardTable from './Dashboard/DashboardTable/DashboardTable';
import { TablePaginationWithoutTotal } from 'components/WrappedComponents/Table/types';

const RoutesComptes: React.FC = () => {
  const [pagination, setPagination] = useState<TablePaginationWithoutTotal>(
    PAGINATION_WITHOUT_TOTAL_DEFAULT,
  );
  const [filter, setFilter] = useState<
    Partial<FilterDashboardComptesUtilisateurs>
  >({});

  return (
    <PaginationWithoutTotalProvider
      value={{ pagination: pagination, setPagination: setPagination }}
    >
      <ComptesUtilisateursFilterProvider
        value={{ filter: filter, setFilter: setFilter }}
      >
        <DashBoardTable />
      </ComptesUtilisateursFilterProvider>
    </PaginationWithoutTotalProvider>
  );
};

export default RoutesComptes;
