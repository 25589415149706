import { MotifMefDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';

export async function fetchMotifMefList(
  refController: ReferentielControllerApi,
): Promise<MotifMefDto[]> {
  return refController.getValidMotifMefUsingGET().then(response => {
    return response.referenceDtoList.sort((a, b) =>
      a.motifMef.localeCompare(b.motifMef),
    );
  });
}

export function fetchMotifMefById(
  refController: ReferentielControllerApi,
  idMotifMef: string,
): Promise<MotifMefDto> {
  return refController.getMotifMefByIdUsingGET(idMotifMef);
}
