import { useCallback, useState } from 'react';
import { Feature, FeatureCollection, GeoJsonProperties } from 'geojson';

import { popErrorMessage } from '../utils/backAlertMessage';
import { AdresseVhu, CommuneVhu } from 'types/BonEnlevementGardien';
import { AutoCompleteOptionProps } from 'components/WrappedComponents/Form/FormFields/AutoComplete/types';
import { useCancelablePromise } from '../useCancelablePromise';
import { useBoolean } from '../../utils/genericUtils';

export interface Adresses extends AutoCompleteOptionProps {
  adresse: string;
  commune: string;
  codePostal: string;
}

/**
 * checks if a provided value can be recognized as a communeVhu
 * we do so by checking the existance of required fields
 * @param value to check
 */
export function isCommune(value: unknown): value is CommuneVhu {
  if (typeof value === 'object' && value !== null) {
    let isCommuneFound = false;
    let isCodePostalMissingOrString = true;
    Object.entries(value).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'commune' && typeof value === 'string') {
        isCommuneFound = true;
      } else if (key === 'codePostal') {
        isCodePostalMissingOrString = typeof value === 'string';
      }
    });
    return isCommuneFound && isCodePostalMissingOrString;
  }
  return false;
}

/**
 * checks if a provided value can be recognized as an adresseVhu
 * we do so by checking the existance of required fields
 * we use isCommune in the process since communeVhu extends adresseVhu
 * @param value to check
 */
export function isAdresse(value: unknown): value is AdresseVhu {
  if (value !== undefined && typeof value === 'object' && value !== null) {
    let isAdresseFound = false;
    Object.entries(value).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'adresse') {
        isAdresseFound = typeof value === 'string';
      }
    });
    return isAdresseFound && isCommune(value);
  }
  return false;
}

type AdresseFetcher = (voie: string) => void;

/**
 * used to get adresses from the gouvernement's api
 * adresses = adresses that will be returned
 * isFetching = whether the hook is currently trying to fetch data or not
 * fetchAdresses = function to fetch the adresses
 */
export const useAdresse = (): [Array<Adresses>, boolean, AdresseFetcher] => {
  const [adresses, setOptions] = useState<Array<Adresses>>([]);
  const [value, setValue] = useState<AdresseVhu>({
    adresse: '',
    codePostal: '',
    commune: '',
  });
  const {
    value: isFetching,
    setIsTrue: startIsFetching,
    setIsFalse: stopIsFetching,
  } = useBoolean();

  const onFetch = () => {
    const geoApiAdresse = process.env.REACT_APP_API_ADRESSE_GOUV_URL;

    const url = `${geoApiAdresse}/search/?limit=15&q=${encodeURI(
      value.adresse,
    )}`;
    startIsFetching();
    return fetch(url);
  };

  const onSuccess = (response: Response) => {
    response
      .json()
      .then((featureCollection: FeatureCollection) => {
        setOptions(
          featureCollection.features
            .map((feature: Feature): Adresses | null => {
              const props: GeoJsonProperties = feature.properties;
              if (!props || !props.street) {
                return null;
              }
              return {
                label: feature.properties?.label,
                value: feature.properties?.name,
                adresse: `${feature.properties?.housenumber} ${feature.properties?.street}`,
                commune: feature.properties?.city,
                codePostal: feature.properties?.citycode,
              };
            })
            .filter((elem: Adresses | null) => !!elem) as Adresses[], // typecast here because typescript can't automatically deduce the filtered array type
        );
      })
      .catch(reason => {
        popErrorMessage(
          `Une erreur lors du parsing des données d'autocomplétion est survenue : ${reason}`,
        );
      });
  };

  const onError = (reason: string) => {
    console.error(
      `Une erreur lors de la récupération des données d'autocomplétion est survenue : ${reason}`,
    );
  };

  useCancelablePromise(
    value.adresse === '' ? null : value.adresse,
    onFetch,
    onSuccess,
    onError,
    stopIsFetching,
    stopIsFetching,
    500,
  );

  const fetchAdresses = useCallback((str: string) => {
    startIsFetching();
    setValue({ adresse: str, commune: '', codePostal: '' });
    setOptions([]);
  }, []);
  return [adresses, isFetching, fetchAdresses];
};
