import React from 'react';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import ResponsiveContainer from 'components/ResponsiveContainer';
import CardLayout from 'components/CardLayout/CardLayout';
import { profileEnumMapping } from 'utils/enumData';
import { ManuelType, ManuelTypeEnum } from 'types/referentiels/Manuels';
import ManuelImport from './ManuelImport/ManuelImport';
import ManuelExport from './ManuelExport/ManuelExport';

const Manuels: React.FC = () => {
  const manuels = [
    UserDtoProfileEnum.GARDIEN_FOURRIERE,
    UserDtoProfileEnum.AUTORITE_FOURRIERE,
    UserDtoProfileEnum.FORCE_DE_L_ORDRE,
  ];
  const manuelsBdr = [UserDtoProfileEnum.FORCE_DE_L_ORDRE];

  return (
    <div>
      <ResponsiveContainer>
        <h2>Import des manuels utilisateurs</h2>
        <p>
          Il vous est possible d&apos;importer de nouvelles versions des manuels
          utilisateur depuis cette page. Pour cela, choisissez le type de manuel
          que vous voulez modifier, vous pourrez alors importer le fichier de
          manuel à jour. Ce fichier doit être au format PDF.
        </p>
        <CardLayout>
          {manuels.map((userType, index) => {
            return (
              <ManuelImport
                key={index}
                index={index}
                label={profileEnumMapping[userType].name}
                userType={userType}
                manuelType={ManuelTypeEnum.UTILISATEUR}
              />
            );
          })}
          {manuelsBdr.map((userType, index) => {
            return (
              <ManuelImport
                key={index}
                index={index}
                label={`Documentation BDR ${profileEnumMapping[userType].name}`}
                userType={userType}
                manuelType={ManuelTypeEnum.BDR}
              />
            );
          })}
        </CardLayout>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <h2>Export des manuels utilisateurs</h2>
        <p>
          Il vous est possible de télécharger les derniers manuels importés
          depuis cette page. Pour cela, il vous suffit de cliquer sur le manuel
          que vous voulez télécharger. Le fichier généré sera au format PDF.
        </p>
        <CardLayout>
          {manuels.map((userType, index) => {
            return (
              <ManuelExport
                key={index}
                index={index}
                label={profileEnumMapping[userType].name}
                userType={userType}
                manuelType={ManuelTypeEnum.UTILISATEUR}
              />
            );
          })}
          {manuelsBdr.map((userType, index) => {
            return (
              <ManuelExport
                key={index}
                index={index}
                label={`Documentation BDR ${profileEnumMapping[userType].name}`}
                userType={userType}
                manuelType={ManuelTypeEnum.BDR}
              />
            );
          })}
        </CardLayout>
      </ResponsiveContainer>
    </div>
  );
};

export default Manuels;
