import React from 'react';

import { FilterButton } from 'components/WrappedComponents';

import { FilterDashboardCentreVHU } from '../types';
import FilterFormCentreVHU from './FilterFormCentreVHU';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { Checkbox as AntdCheckbox } from 'antd';

interface FilterProps {
  setFilter: (value: Partial<FilterDashboardCentreVHU>) => void;
  filter: Partial<FilterDashboardCentreVHU>;
}

export default function Filter({
  filter,
  setFilter,
}: FilterProps): React.ReactElement {
  const { valid, ...formFilter } = filter;
  function setFormFilter(value: Partial<FilterDashboardCentreVHU>) {
    setFilter({
      ...filter,
      ...value,
    });
  }

  function setDisplayInvalidCentreVHU(event: CheckboxChangeEvent) {
    setFilter({
      ...filter,
      valid: !event.target.checked,
    });
  }

  return (
    <div className="dashboard-header-filter">
      <FilterButton
        filter={formFilter}
        setFilter={setFormFilter}
        textInactiveFilter="Filtres"
        textActiveFilter="Filtres (actifs)"
      >
        {(setData, data) => (
          <FilterFormCentreVHU filter={data} setFilter={setData} />
        )}
      </FilterButton>
      <AntdCheckbox
        checked={!valid}
        onChange={setDisplayInvalidCentreVHU}
        className="checkbox-unites-inactives"
      >
        Afficher les centres VHU inactifs
      </AntdCheckbox>
    </div>
  );
}
