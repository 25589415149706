import React from 'react';

import { UpdateViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { Input } from 'components/WrappedComponents';

import { useApi } from 'hooks/ApiStoreContext';
import { ModificationUtilisateurFormValues } from '../ModalModification';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import {
  generateUpdateUserRequestDtoFromFormData,
  ModificationUserFieldProps,
  validateUpdateUserRequest,
} from '../utils';

function InputFirstName({
  values,
  userId,
}: ModificationUserFieldProps): React.ReactElement {
  const cuController = useApi().AdminFonctionnelControllerApi;
  const key: keyof ModificationUtilisateurFormValues = 'firstName';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Prénom',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateUpdateUserRequestDtoFromFormData({
                  ...values,
                  firstName: value === '' ? undefined : value,
                });

                return await validateUpdateUserRequest(
                  userId,
                  cuController,
                  requestObject,
                  UpdateViolationDtoFormFieldEnum.FIRSTNAME,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}

export default InputFirstName;
