import React from 'react';

import { Input } from 'components/WrappedComponents';
import { getValueFromEventContainingOnlyNumeric } from 'components/BaseForm/utils';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FilterDashboardFourriereProps } from '../../../types';

function InputCodePostal({
  values,
}: FilterDashboardFourriereProps): React.ReactElement {
  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: 'codePostal',
        itemProps: {
          label: 'Code postal',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          getValueFromEvent: getValueFromEventContainingOnlyNumeric,
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Number }}
    />
  );
}

export default InputCodePostal;
