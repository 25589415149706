import React from 'react';

import {
  SortieVehiculeViolationDtoFormFieldEnum,
  SortieVehiculeRequestDto,
} from 'lib_api/lib/api/gen';
import { DatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { SortieVehiculeFieldsProps } from './types';
import { SortieVehicule } from 'types/SortieVehicule';
import { DATE_FORMAT_WITH_TIME } from 'utils/formats';
import { generateSortieVehiculeRequestDtoFromSortieVehicule } from '../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { validateSortieVehiculeRule } from '../utils';
import { CSSUtils } from 'utils/CSSUtils';

function DatePickerSortieParc({
  values,
  dossierId,
}: SortieVehiculeFieldsProps): React.ReactElement {
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof SortieVehicule = 'dateSortie';
  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Date et heure de sortie du véhicule',
          rules: [
            {
              validator: async (_rule, value): Promise<void> => {
                const sortieVehiculeRequestDto: SortieVehiculeRequestDto =
                  generateSortieVehiculeRequestDtoFromSortieVehicule({
                    ...values,
                    [key]: value,
                  });
                return await validateSortieVehiculeRule(
                  dossierId,
                  gfController,
                  sortieVehiculeRequestDto,
                  SortieVehiculeViolationDtoFormFieldEnum.DATE_SORTIE,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      datePickerProps={{
        style: { width: '100%' },
        placeholder: FormPlaceholders.DateWithTime,
        format: DATE_FORMAT_WITH_TIME,
        showTime: true,
        locale: frFR.DatePicker,
      }}
    />
  );
}

export default DatePickerSortieParc;
