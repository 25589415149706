import React, { useState } from 'react';
import {
  Button as AntdButton,
  Checkbox as AntdCheckbox,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
  message as AntdMessage,
  Modal as AntdModal,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { UserDto } from 'lib_api/lib/api/gen';
import { FilterableTable } from 'components/WrappedComponents/Table';
import {
  ColumnProps,
  TableHeaderFunctionWithJustFilters,
} from 'components/WrappedComponents/Table/types';

import { FilterDashboardComptesUtilisateurs } from '../DashboardHeader/types';
import { useSearchUserDto } from 'hooks/comptes/useSearchUserDto';
import { usePaginationWithoutTotalContext } from 'hooks/PaginationWithoutTotalStoreContext';
import { useComptesUtilisateursFilterContext } from 'hooks/FilterComptesUtilisateursStoreContext';
import { profileEnumMapping } from 'utils/enumData';
import DashboardHeader from '../DashboardHeader';
import ModalModification from '../../Modification/ModalModification';
import { useSubmitResetUserPassword } from '../../ResetPassword/utils';
import { LONG_DELAY } from '../constants';
import { useSubmitDeleteAccount } from '../../DeleteAccount/utils';

import './DashboardTable.less';

function buildEnabledContent(data: UserDto): React.ReactElement {
  return <AntdCheckbox checked={data.enabled} disabled={true} />;
}

function buildActionsContent(
  data: UserDto,
  openModalModification: (user: UserDto) => void,
  openModalResetPassword: (user: UserDto) => void,
  openModalDeleteAccount: (user: UserDto) => void,
): React.ReactElement {
  const menu = (
    <AntdMenu>
      <AntdMenu.Item
        onClick={() => {
          openModalResetPassword(data);
        }}
      >
        Réinitialiser le mot de passe
      </AntdMenu.Item>
      <AntdMenu.Item
        onClick={() => {
          openModalModification(data);
        }}
      >
        Modifier l&apos;utilisateur
      </AntdMenu.Item>
      <AntdMenu.Item
        onClick={() => {
          openModalDeleteAccount(data);
        }}
      >
        Supprimer l&apos;utilisateur
      </AntdMenu.Item>
    </AntdMenu>
  );
  return (
    <>
      <AntdDropdown overlay={menu}>
        <AntdButton>
          Actions <DownOutlined />
        </AntdButton>
      </AntdDropdown>
    </>
  );
}

const buildHeader: TableHeaderFunctionWithJustFilters<
  FilterDashboardComptesUtilisateurs
> = (filters, setFilter) => {
  return <DashboardHeader filter={filters} setFilter={setFilter} />;
};

const DashBoardTable: React.FC = () => {
  const searchUser = useSearchUserDto();
  const [pagination, setPagination] = usePaginationWithoutTotalContext();
  const [filter, setFilter] = useComptesUtilisateursFilterContext();
  const [updatedUser, setUpdatedUser] = useState<UserDto>();
  const [isLoadingResetPassword, setIsLoadingResetPassword] =
    useState<boolean>(false);
  const [isLoadingDeleteAccount, setIsLoadingDeleteAccount] =
    useState<boolean>(false);
  const [modal, contextHolder] = AntdModal.useModal();

  const closeModalModification = (): void => {
    setUpdatedUser(undefined);
  };

  const submitResetPassword = useSubmitResetUserPassword(
    setIsLoadingResetPassword,
  );

  const submitDeleteAccount = useSubmitDeleteAccount(setIsLoadingDeleteAccount);

  const openModalConfirmResetPassword = (user: UserDto) => {
    if (user.email) {
      modal.confirm({
        title: 'Voulez-vous vraiment réinitialiser le mot de passe ?',
        okText: 'Réinitialiser',
        cancelText: 'Annuler',
        onOk: () => {
          submitResetPassword(user.id, user.username, user.email);
        },
        okButtonProps: {
          loading: isLoadingResetPassword,
        },
      });
    } else {
      void AntdMessage.error(
        `Veuillez configurer l'adresse mail du compte ${user.username} pour pouvoir réinitialiser son mot de passe.`,
        LONG_DELAY,
      );
    }
  };

  const openModalConfirmDeleteAccount = (user: UserDto) => {
    modal.confirm({
      title: `Voulez-vous vraiment supprimer l'utilisateur ${user.username} ?`,
      okText: 'Supprimer',
      cancelText: 'Annuler',
      onOk: () => {
        submitDeleteAccount(user.id, user.username);
      },
      okButtonProps: {
        loading: isLoadingDeleteAccount,
      },
    });
  };

  function buildColumns(): ColumnProps<UserDto>[] {
    return [
      {
        title: 'Nom',
        buildCellContent: data => data.lastName,
      },
      {
        title: 'Prénom',
        buildCellContent: data => data.firstName,
      },
      {
        title: 'Identifiant',
        buildCellContent: data => data.username,
      },
      {
        title: 'Rôle',
        buildCellContent: data => profileEnumMapping[data.profile].name,
      },
      {
        title: 'Adresse e-mail',
        buildCellContent: data => data.email,
      },
      {
        title: 'Entité de rattachement',
        buildCellContent: data => data.libelleEntiteRattachement,
      },
      {
        title: 'Actif',
        buildCellContent: data => buildEnabledContent(data),
      },
      {
        title: '',
        buildCellContent: data =>
          buildActionsContent(
            data,
            setUpdatedUser,
            openModalConfirmResetPassword,
            openModalConfirmDeleteAccount,
          ),
      },
    ];
  }

  const columns: ColumnProps<UserDto>[] = buildColumns();

  return (
    <div className="table-container">
      <FilterableTable
        columns={columns}
        fetchData={searchUser}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
      />
      {updatedUser !== undefined && (
        <ModalModification
          close={closeModalModification}
          user={updatedUser}
          callAfterSubmit={() => {
            setFilter({ ...filter });
          }}
        />
      )}
      {contextHolder}
    </div>
  );
};

export default DashBoardTable;
