import React, { useState } from 'react';
import {
  Button as AntdButton,
  Dropdown as AntdDropdown,
  Menu as AntdMenu,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

import { ComputedResponseDtoListAvailableFilesEnum } from 'lib_api/lib/api/gen';
import { useDownloadDocument } from 'hooks/dossiers/useDownloadDocument';
import { availableFilesMapping } from 'utils/enumData';

interface AvailableFilesProps {
  dossierId: string;
  files: ComputedResponseDtoListAvailableFilesEnum[];
}

const AvailableFiles = ({
  files,
  dossierId,
}: AvailableFilesProps): React.ReactElement => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const downloadDocument = useDownloadDocument(setIsDownloading, dossierId);

  if (files.length === 1) {
    const file = files[0];
    return (
      <AntdButton
        onClick={() => {
          downloadDocument(file);
        }}
        type="default"
        shape="round"
        className="ActionsButton"
        loading={isDownloading}
      >
        {availableFilesMapping[file]}
      </AntdButton>
    );
  }

  const menu = (
    <AntdMenu>
      {files.map(
        (file: ComputedResponseDtoListAvailableFilesEnum, index: number) => {
          return (
            <AntdMenu.Item
              key={index}
              onClick={() => {
                downloadDocument(file);
              }}
              disabled={isDownloading}
            >
              {isDownloading
                ? 'Veuillez patienter...'
                : availableFilesMapping[file]}
            </AntdMenu.Item>
          );
        },
      )}
    </AntdMenu>
  );

  return (
    <AntdDropdown overlay={menu}>
      <AntdButton
        disabled={files.length === 0}
        type="primary"
        shape="round"
        className="ActionsButton"
      >
        Documents disponibles <DownOutlined />
      </AntdButton>
    </AntdDropdown>
  );
};

export default AvailableFiles;
