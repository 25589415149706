import React from 'react';
import classNames from 'classnames';

import { FieldDisplayProps } from './types';
import { formatValue } from './utils';
import './FieldDisplay.less';

export const FieldDisplay = ({
  label,
  value,
  additionalValueClassName = 'singleLineValue',
  labelClass = 'label',
  emptyMessage = 'Non renseigné',
}: FieldDisplayProps): React.ReactElement => {
  const formatedValue: string = formatValue(value, emptyMessage);

  return (
    <div className={'fieldContainer'}>
      <label className={labelClass}>{label}</label>
      <label
        className={labelClass === 'labelDetail' ? 'spacerDetail' : 'spacer'}
      >
        :
      </label>
      <label
        title={formatedValue}
        className={classNames(
          'value',
          { missingValue: value === null || value === undefined },
          additionalValueClassName,
        )}
      >
        {formatedValue}
      </label>
    </div>
  );
};
