import { createContext, useContext } from 'react';

export enum DownloadableFileStatus {
  'SUCCESS' = 'SUCCESS',
  'ON_ERROR' = 'ON_ERROR',
  'IN_PROCESS' = 'IN_PROCESS',
}

export interface DownloadableFileMetaData {
  id: string;
  name: string;
  status: DownloadableFileStatus;
  detail?: string;
  errorDetail?: string;
  requestDate?: string;
  expirationDate?: string;
}

export interface DownloadableFiles {
  files: DownloadableFileMetaData[];
  newDownloadsNumber: number;
  showDownloadablePopUp: (show: boolean) => void;
  getDownloadableFiles: () => void;
}

export const DownloadableFilesContext = createContext<DownloadableFiles | null>(
  null,
);

export const useDownloadableFilesContext = (): DownloadableFiles => {
  const context = useContext(DownloadableFilesContext);
  if (context === null) {
    throw new Error('useDownloadableFilesContext called outside Context.');
  }

  return context;
};

const DownloadableFileProvider = DownloadableFilesContext.Provider;

export default DownloadableFileProvider;
