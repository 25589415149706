import { createContext, useContext, Dispatch, SetStateAction } from 'react';

import { DashboardDSRFilterFormValues } from 'pages/delegue-securite-routiere/Dashboard/types';

interface DSRFilterContextContent {
  filter: Partial<DashboardDSRFilterFormValues>;
  setFilter: Dispatch<SetStateAction<Partial<DashboardDSRFilterFormValues>>>;
}

const DSRFilterContext = createContext<DSRFilterContextContent | null>(null);

export const useDSRFilterContext = (): [
  Partial<DashboardDSRFilterFormValues>,
  Dispatch<SetStateAction<Partial<DashboardDSRFilterFormValues>>>,
] => {
  const context = useContext(DSRFilterContext);
  if (context === null) {
    throw new Error('useFilterContext called outside Context.');
  }

  return [context.filter, context.setFilter];
};

const DSRFilterProvider = DSRFilterContext.Provider;

export default DSRFilterProvider;
