import { useEffect, useState } from 'react';

import { ReferenceDtoVideoTutorielDto } from 'lib_api/lib/api/gen/api';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefVideoTutorielType = ReferenceDtoVideoTutorielDto | undefined;

export const useGetTutorielsVideos = (): RefVideoTutorielType => {
  const behaviourOnError = useHandleBackErrors();
  const [refVideoTutoriel, setRefVideoTutoriel] =
    useState<RefVideoTutorielType>(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refVideoTutoriel === undefined) {
      referentielControllerApi
        .getVideoTutorielUsingGET()
        .then(fetchedRef => {
          setRefVideoTutoriel(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, refVideoTutoriel]);
  return refVideoTutoriel;
};
