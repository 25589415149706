import {
  ReferentielSearchResultDtoMarqueDto,
  SearchMarqueUsingSpecsUsingGETQueryParams,
} from 'lib_api/lib/api/gen';
import { useApi } from '../ApiStoreContext';
import { searchMarque } from 'search/searchMarque';

export function useSearchMarque(): (
  filters: SearchMarqueUsingSpecsUsingGETQueryParams,
) => Promise<ReferentielSearchResultDtoMarqueDto> {
  const controller = useApi().ReferentielControllerApi;

  return filters => {
    return searchMarque(controller, filters);
  };
}
