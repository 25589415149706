import React from 'react';
import { useFormActionsContext } from 'components/WrappedComponents';
import { Button as AntdButton } from 'antd';

import { FormFilterGardien } from '../../types';

import './FormFooter.less';

interface Props {
  setFilter: (value: Partial<FormFilterGardien>) => void;
}

export function FormFooter({ setFilter }: Props): React.ReactElement {
  const formActions = useFormActionsContext();

  function resetFilter() {
    formActions.setFieldsValue({
      actionsRequises: undefined,
      classement: undefined,
      statut: undefined,
      natureMiseEnFourriere: undefined,
      dateRangeMiseEnFourriere: undefined,
      motifDeSortie: undefined,
      dateRangeMiseAJour: undefined,
      includeDossiersAnonymises: undefined,
    });
  }

  return (
    <div className={'footer_form_gardien'}>
      <AntdButton onClick={resetFilter}>Réinitialiser</AntdButton>

      <AntdButton
        type={'primary'}
        onClick={() => setFilter(formActions.getFieldsValue())}
      >
        Rechercher
      </AntdButton>
    </div>
  );
}
