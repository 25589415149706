export enum MessageStatus {
  DISPLAYED,
  FUTURE,
  OUTDATED,
  DEACTIVATED,
}

export enum MessageSortOptions {
  START_DATE = 'START_DATE',
  END_DATE = 'END_DATE',
  IMMATRICULATION = 'IMMATRICULATION',
}

export interface MessageStatusInfo {
  label: string;
  color: string;
}

export type MessageStatusMapping = {
  [key in keyof typeof MessageStatus]: MessageStatusInfo;
};
