import React, { useState } from 'react';

import UploadCard from 'components/Card/UploadCard';
import Uploader from 'components/WrappedComponents/Uploader/Uploader';
import { supportedFilesDetails } from 'components/WrappedComponents/Uploader/constants';
import { SupportedFile } from 'types/enums/SupportedFile';

import { useImportReferentiel } from 'hooks/referentiels/useImportReferentiel';

import './ReferentielImportComponent.less';
import { RequestStatus } from 'types/RequestStatus';

interface ReferentielImportComponentProps<Referentiel> {
  referentiel: Referentiel;
  referentielName: string;
  index: number;
  fileType: SupportedFile;
  importReferentiel: (
    file: Blob,
    referentiel: Referentiel,
  ) => Promise<Response>;
}

export default function ReferentielImportComponent<Referentiel>({
  referentiel,
  referentielName,
  index,
  fileType,
  importReferentiel,
}: ReferentielImportComponentProps<Referentiel>): React.ReactElement {
  const [status, setStatus] = useState<RequestStatus>();
  const handleStatus = (newStatus: RequestStatus) => {
    setStatus(newStatus);
  };
  const { extension, fileTypes } = supportedFilesDetails[fileType];
  const [fetchImportReferentiel] = useImportReferentiel(
    handleStatus,
    importReferentiel,
  );

  return (
    <UploadCard status={status} key={index}>
      <Uploader
        title={referentielName}
        accept={extension}
        fileTypes={fileTypes}
        importRoute={fetchImportReferentiel(referentiel)}
      />
    </UploadCard>
  );
}
