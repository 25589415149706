import React, { useCallback, useState } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';

import { CreateUserResultDto } from 'lib_api/lib/api/gen';
import { Form } from 'components/WrappedComponents';

import { CreationCompteFormValues } from 'types/Compte/CreationCompte';
import SelectProfile from './Fields/SelectProfile';
import SelectUniteRattachement from './Fields/SelectUniteRattachement';
import InputNom from './Fields/InputNom';
import InputPrenom from './Fields/InputPrenom';
import InputEmail from './Fields/InputEmail';
import CheckboxActif from './Fields/CheckboxActif';
import {
  generateCreateUserRequestDtoFromFormData,
  useSubmitCreateUser,
  useValidateCreateUser,
} from './utils';

interface ModalCreationProps {
  close: () => void;
  callAfterSubmit: (
    result: CreateUserResultDto,
    email: string | undefined,
  ) => void;
  visible: boolean;
}

const defaultData: CreationCompteFormValues = {
  actif: true,
  nom: undefined,
  prenom: undefined,
  email: undefined,
  profile: undefined,
  uniteRattachement: undefined,
};

const ModalCreation = ({
  close,
  callAfterSubmit,
  visible,
}: ModalCreationProps): React.ReactElement => {
  const [data, setData] = useState(defaultData);

  const [submitForm, thenSubmmit, catchSubmit] = useSubmitCreateUser(result => {
    close();
    callAfterSubmit(result, data.email);
  });

  const memoSubmit = useCallback(async () => {
    return submitForm(data);
  }, [data, submitForm]);

  const [validateAndSubmit] = useValidateCreateUser(
    memoSubmit,
    thenSubmmit,
    catchSubmit,
  );

  const updateFormValues = useCallback(
    (newValues: CreationCompteFormValues) => {
      // Clear entite rattachement if the type of the profile changes
      if (data.profile !== newValues.profile) {
        newValues.uniteRattachement = undefined;
      }

      setData(newValues);
    },
    [data],
  );

  const onCancel = () => {
    close();
    setData(defaultData);
  };
  return (
    <AntdModal
      visible={visible}
      onCancel={onCancel}
      footer={null}
      destroyOnClose={true}
      width={680}
      maskClosable={false}
      closable={true}
      title={<div className={'centered'}>Créer un nouveau compte</div>}
    >
      <Form
        id="addUserForm"
        initialValue={data}
        onChange={updateFormValues}
        onSubmit={async () => {
          await validateAndSubmit(cuController =>
            cuController.validateCreateUsingPOST(
              generateCreateUserRequestDtoFromFormData(data),
            ),
          );
          setData(defaultData);
        }}
        validateButtonTitle={'Ajouter'}
        validateButtonClassName={'ValidateFormButton'}
      >
        {(values, SubmitButton): React.ReactElement => {
          return (
            <>
              <InputNom values={values} />
              <InputPrenom values={values} />
              <InputEmail values={values} />
              <CheckboxActif values={values} />
              <SelectProfile values={values} />
              <SelectUniteRattachement
                values={values}
                profile={values.profile}
              />
              <div className="ModalFooter">
                <AntdButton onClick={onCancel}>Annuler</AntdButton>
                {SubmitButton}
              </div>
            </>
          );
        }}
      </Form>
    </AntdModal>
  );
};

export default ModalCreation;
