import React from 'react';
import { Typography as AntdTypo } from 'antd';
import { MarqueDtoCategorieEnum, MarqueDtoTypeEnum } from 'lib_api/lib/api/gen';
import { FilterDashboardMarque } from '../DashboardHeader/types';
import { categorieMarqueMapping, typeMarqueMapping } from 'utils/enumData';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import BaseForm from 'components/BaseForm/BaseForm';
import FormFooter from './FormFooter/FormFooter';

interface FilterFormProps {
  filter: FilterDashboardMarque;
  setFilter: (value: FilterDashboardMarque) => void;
}

function FilterFormMarque({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  // list of options of select
  const categoriesOptions = [
    {
      value: MarqueDtoCategorieEnum.A_CATEGORISER,
      displayValue:
        categorieMarqueMapping[MarqueDtoCategorieEnum.A_CATEGORISER],
    },
    {
      value: MarqueDtoCategorieEnum.NON_VALORISABLE,
      displayValue:
        categorieMarqueMapping[MarqueDtoCategorieEnum.NON_VALORISABLE],
    },
    {
      value: MarqueDtoCategorieEnum.PREMIUM,
      displayValue: categorieMarqueMapping[MarqueDtoCategorieEnum.PREMIUM],
    },
  ];

  // list of options of select of types
  const typesOptions = [
    {
      value: MarqueDtoTypeEnum.INCONNUE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.INCONNUE],
    },
    {
      value: MarqueDtoTypeEnum.APPROXIMATIVE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.APPROXIMATIVE],
    },
    {
      value: MarqueDtoTypeEnum.EXACTE,
      displayValue: typeMarqueMapping[MarqueDtoTypeEnum.EXACTE],
    },
  ];

  return (
    <>
      <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
      <BaseForm
        initialValues={filter}
        onSubmit={values => {
          setFilter(values);
        }}
        inputs={[
          {
            name: 'id',
            label: 'Id',
          },
          {
            name: 'libelle',
            label: 'Libellé',
          },
          {
            name: 'categorie',
            label: 'Catégorie',
            render: () => {
              return (
                <BaseSelect
                  options={categoriesOptions}
                  getOptionLabel={option => option.displayValue}
                  getOptionValue={option => option.value}
                />
              );
            },
          },
          {
            name: 'type',
            label: 'Type',
            render: () => {
              return (
                <BaseSelect
                  options={typesOptions}
                  getOptionLabel={option => option.displayValue}
                  getOptionValue={option => option.value}
                />
              );
            },
          },
        ]}
        renderButtonAction={() => {
          return <FormFooter setFilter={setFilter} />;
        }}
      />
    </>
  );
}

export default FilterFormMarque;
