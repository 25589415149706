import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import { DatePickerProps as AntdDatePickerProps } from 'antd/lib/date-picker';
import { isMoment, Moment } from 'moment';

import FieldWrapper from '../FieldWrapper';
import { WrappedProps } from '../types';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { DATE_FORMAT } from 'utils/formats';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  datePickerProps: AntdDatePickerProps;
}

function DatePicker<T>({
  wrapperProps,
  datePickerProps,
}: Props<T>): React.ReactElement {
  const datePicker = React.useMemo(() => {
    return <AntdDatePicker {...datePickerProps} />;
  }, [datePickerProps]);

  wrapperProps.itemProps.validateTrigger = 'onChange';

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const value = formActions.getFieldValue(fieldKey);
  const dateValue: Moment | undefined = isMoment(value) ? value : undefined; //If not a moment, we put undefined in date

  return (
    <FieldWrapper
      {...wrapperProps}
      ConsultationElement={
        dateValue === undefined ? dateValue : dateValue.format(DATE_FORMAT)
      }
      WrappedInput={datePicker}
    />
  );
}

export default DatePicker;
