import React from 'react';
import { RangeTimePicker } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { TIME_FORMAT } from 'utils/formats';
import { useBooleanStoreContext } from 'hooks/BooleanStoreContext';

interface InputHourProps {
  completeFieldKey: string | number | (string | number)[];
  fieldKey: string | number | (string | number)[];
}

function InputHour({
  completeFieldKey,
  fieldKey,
}: InputHourProps): React.ReactElement {
  const disabled = useBooleanStoreContext();

  return (
    <RangeTimePicker
      completeFieldKey={completeFieldKey}
      wrapperProps={{
        fieldKey: fieldKey,
        itemProps: {
          label: 'Horaires',
          rules: [
            {
              required: true,
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.LlargeWrapperCol },
        },
        editable: !disabled,
      }}
      timePickerProps={{
        style: { width: '100%' },
        placeholder: ['de', 'à'],
        format: TIME_FORMAT,
        allowEmpty: [true, true],
      }}
    />
  );
}

export default InputHour;
