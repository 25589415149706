import { DossierResponseDto } from 'lib_api/lib/api/gen';

export const extractNonnullDossierIdOrThrow = (
  dossier: DossierResponseDto,
): string => {
  const dossierId = dossier.header?.id;

  if (dossierId === undefined || dossierId === null) {
    throw new Error('Dossier id should be defined');
  }

  return dossierId;
};
