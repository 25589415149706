import React from 'react';
import { Input } from 'components/WrappedComponents';
import { ProfilFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriereBlocCoordonnees,
  InputPropCoordonnees,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { validateFicheProfilGf } from '../../utils';

function InputZIPCode({
  values,
  isEditable,
  generateRequestDto,
}: InputPropCoordonnees): React.ReactElement {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof FicheProfilFourriereBlocCoordonnees = 'zipCode';
  const label = 'Code postal';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          getValueFromEvent: (
            event: React.ChangeEvent<HTMLInputElement>,
          ): string => {
            const reg = new RegExp('^[0-9]*$');
            if (!reg.test(event.currentTarget.value)) {
              return event.currentTarget.defaultValue;
            }
            return event.currentTarget.value;
          },
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return validateFicheProfilGf(
                  gfController,
                  ProfilFourriereViolationDtoFormFieldEnum.CODE_POSTAL,
                  user.idCorrelationFourriere || '',
                  generateRequestDto({ ...values, [key]: value }),
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      inputProps={{
        placeholder: FormPlaceholders.Input,
      }}
    />
  );
}

export default InputZIPCode;
