import React, { useState } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';

import {
  FicheProfilFourriereBlocCoordonnees,
  SemaineHoraire,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import HorairesForm from './HorairesModal';

interface ButtonCreateModalProp {
  values: FicheProfilFourriereBlocCoordonnees;
  setValues?: (horaires: SemaineHoraire) => void;
  title: React.ReactElement;
}

function ButtonCreateModal({
  values,
  setValues,
  title,
}: ButtonCreateModalProp): React.ReactElement {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <AntdButton
        className={'create-dossier-button'}
        type={'primary'}
        shape={'round'}
        onClick={() => setVisible(true)}
      >
        {title}
      </AntdButton>

      <AntdModal
        title={<div className={'centered'}>Horaires</div>}
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        width={'60%'}
        footer={null}
        cancelText="Annuler les modifications"
        maskClosable={setValues === undefined}
      >
        <HorairesForm
          values={values}
          setHoraires={horaires => setValues && setValues(horaires)}
          closeForm={() => setVisible(false)}
          disable={setValues === undefined}
        />
      </AntdModal>
    </>
  );
}

export default ButtonCreateModal;
