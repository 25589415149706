import React from 'react';

export interface AddHoursProp {
  addHour: () => void;
}

function AddHours({ addHour }: AddHoursProp): React.ReactElement {
  const antdButton = (
    <button
      className={'add-hour-button'}
      onClick={() => {
        addHour();
      }}
    >
      Ajouter un créneau...
    </button>
  );
  return antdButton;
}

export default AddHours;
