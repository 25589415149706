import React, { useCallback } from 'react';
import { Spin } from 'antd';

import {
  NotificationManuelleInfosDtoEtatDistributionEnum,
  FixDataNotificationManuelleViolationDto,
  FixDataNotificationManuelleViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen/api';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import useInitialValues from './useInitialValues';
import { mapFormValuesToRequestDto } from './buildSubmitFunction';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseDatePicker from 'components/BaseForm/BaseDatePicker';
import BaseTextArea from 'components/BaseForm/BaseTextArea';
import BaseSelect from 'components/BaseForm/Select/BaseSelect';
import { notificationManuelleInfosEnumMapping } from 'pages/forces-de-l-ordre/PageDossier/Actions/InfosNotification/utils';
import useSubmitFixNotificationManuelle from './buildSubmitFunction';

interface FixDataNotificationManuelleProps {
  closeModal: () => void;
}

export default function FixDataNotificationManuelle({
  closeModal,
}: FixDataNotificationManuelleProps): React.ReactElement {
  const [
    {
      result: { id: dossierId },
    },
  ] = useDossierAdminContext();
  const { dossier: dossierAdmin } = useDossierCompletAdmin(dossierId);
  const { AdminFonctionnelControllerApi: adminController } = useApi();
  const initialValues = useInitialValues(dossierAdmin);
  const { submit, inProgress } = useSubmitFixNotificationManuelle(closeModal);

  const submitFunction = useCallback(submit, [adminController, dossierId]);

  if (!dossierAdmin) {
    return <Spin />;
  }

  return (
    <>
      <h3>Modifier le suivi de notification</h3>
      <BaseForm
        initialValues={initialValues}
        onSubmit={submitFunction}
        onCancel={closeModal}
        isSubmitting={inProgress}
        inputs={[
          {
            field:
              FixDataNotificationManuelleViolationDtoFormFieldEnum.NEW_DATE_ENVOI,
            name: 'dateEnvoi',
            disabled: initialValues.dateEnvoi === undefined,
            label: "Date d'envoi de la notification",
            dependencies: ['dateSignature', 'etatDistribution'],
            render: ({ disabled }) => {
              return <BaseDatePicker allowClear disabled={disabled} />;
            },
          },
          {
            field:
              FixDataNotificationManuelleViolationDtoFormFieldEnum.NEW_ETAT_DISTRIBUTION,
            name: 'etatDistribution',
            disabled: initialValues.etatDistribution === undefined,
            label: 'Etat de distribution',
            dependencies: ['dateSignature', 'dateEnvoi'],
            render: ({ disabled }) => {
              return (
                <BaseSelect
                  options={Object.values(
                    NotificationManuelleInfosDtoEtatDistributionEnum,
                  )}
                  getOptionLabel={option =>
                    notificationManuelleInfosEnumMapping[option].libelle
                  }
                  getOptionValue={option => option}
                  disabled={disabled}
                  allowClear
                />
              );
            },
          },
          {
            field:
              FixDataNotificationManuelleViolationDtoFormFieldEnum.NEW_DATE_SIGNATURE,
            name: 'dateSignature',
            disabled: initialValues.dateSignature === undefined,
            label: 'Date de retour',
            dependencies: ['dateEnvoi', 'etatDistribution'],
            render: ({ disabled }) => {
              return <BaseDatePicker disabled={disabled} allowClear />;
            },
          },
          {
            field:
              FixDataNotificationManuelleViolationDtoFormFieldEnum.JUSTIFICATION,
            required: true,
            name: 'justification',
            label: 'Justification',
            render: () => {
              return <BaseTextArea />;
            },
          },
        ]}
        validateField={async (field, formValues) => {
          const response =
            await adminController.validateFixDataNotificationManuelleFieldUsingPOST(
              field,
              dossierId,
              mapFormValuesToRequestDto(formValues),
            );

          const violations: Array<FixDataNotificationManuelleViolationDto> =
            response.fixDataNotificationManuelleViolationDtoList ?? [];
          if (violations.length === 0) {
            return Promise.resolve();
          }

          return Promise.reject(violations[0].message);
        }}
        renderButtonAction={(Submit, Cancel) => {
          return (
            <div className="UpdateSortieActions">
              <div className="UpdateAction">{Cancel}</div>
              <div className="UpdateAction">{Submit}</div>
            </div>
          );
        }}
      />
    </>
  );
}
