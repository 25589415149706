import { useEffect } from 'react';
import { useDelay } from './useDelay';

/**
 * function to call an api with a delay of 500ms
 * @param value: the value to check to call the API or not
 * @param onFetch
 * @param onSuccess
 * @param onError
 * @param onFinish
 * @param onEmptyValue
 * @param delay
 */
export function useCancelablePromise<ValueType, ResponseType>(
  value: ValueType,
  onFetch: () => Promise<ResponseType>,
  onSuccess?: (response: ResponseType) => void,
  onError?: (error: string) => void,
  onFinish?: () => void,
  onEmptyValue?: () => void,
  delay?: number,
): void {
  const [fetchFunction, cancelTimer] = useDelay(timerID => {
    onFetch()
      .then(res => {
        if (timerID && onSuccess) {
          onSuccess(res);
        }
      })
      .catch(reason => {
        if (timerID && onError) {
          onError(reason);
        }
      })
      .finally(() => {
        onFinish && onFinish();
      });
  }, delay || 500);
  useEffect(() => {
    if (value === null) {
      onEmptyValue && onEmptyValue();
      return;
    }
    fetchFunction();
    return cancelTimer;
  }, [value]);
}
