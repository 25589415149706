import React, { useEffect, useState } from 'react';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import { EtatDto, EtatDtoEtatEnum } from 'lib_api/lib/api/gen';

import { useBaseFormContext } from 'components/BaseForm/BaseFormContext';
import BaseSelect, {
  BaseSelectProps,
} from 'components/BaseForm/Select/BaseSelect';
import { useFetchDetailsByEtat } from 'hooks/search/useSearchDetailsEtat';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';

interface BaseSelectDetailEtatProps<FormValues>
  extends Omit<
    BaseSelectProps<FormValues, EtatDto | null>,
    'options' | 'getOptionValue' | 'getOptionLabel'
  > {
  idEtatField: keyof FormValues & AntdNamePath;
}

export default function BaseSelectDetailsEtat<FormValues>({
  idEtatField,
  showSearch,
  ...props
}: BaseSelectDetailEtatProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext();
  const [etats, setEtats] = useState<Array<EtatDto>>([]);
  const behaviourOnError = useHandleBackErrors();
  const etat: EtatDtoEtatEnum = form.getFieldValue(idEtatField);
  const [fetchDetails] = useFetchDetailsByEtat();

  useEffect(() => {
    setEtats([]);
    if (!etat) {
      return;
    }
    fetchDetails(etat).then(setEtats).catch(behaviourOnError);
  }, [etat]);

  return (
    <BaseSelect
      options={etats}
      getOptionValue={etat => etat.id}
      getOptionLabel={etat => etat.libelleCourt}
      showSearch={showSearch ?? true}
      {...props}
    />
  );
}
