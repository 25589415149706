import React from 'react';
import { Select } from 'components/WrappedComponents';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useGFFilterContext } from 'hooks/GFFilterStoreContext';
import { actionsRequisesMapping } from 'hooks/useActionsRequises';
import { FormFilterGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';

interface SelectActionsRequisesProps {
  values: Partial<FormFilterGardien>;
}

export function SelectActionsRequises({
  values,
}: SelectActionsRequisesProps): React.ReactElement {
  const [, , gfActionsRequises] = useGFFilterContext();
  const selectItems =
    gfActionsRequises?.map(actionRequise => {
      return {
        value: actionRequise.toString(),
        displayValue: actionsRequisesMapping[actionRequise],
      };
    }) || [];

  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'actionsRequises',
        itemProps: {
          label: 'Action requise',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}
