import React from 'react';
import { Modal as AntdModal } from 'antd';
import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { CentreVHUDto } from 'lib_api/lib/api/gen';
import './ModalDetails.less';

interface ModalDetailsProps {
  centreVHU: CentreVHUDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  centreVHU,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id de correlation" value={centreVHU.idCorrelation} />
      <FieldDisplay label="Raison sociale" value={centreVHU.raisonSociale} />
      <FieldDisplay label="SIREN" value={centreVHU.siren} />
      <FieldDisplay label="Adresse" value={centreVHU.adresse} />
      <FieldDisplay
        label="Complément d'adresse"
        value={centreVHU.complementAdresse}
      />
      <FieldDisplay label="Code postal" value={centreVHU.codePostal} />
      <FieldDisplay label="Commune" value={centreVHU.commune} />
      <FieldDisplay label="Téléphone" value={centreVHU.telephone} />
      <FieldDisplay label="Adresse mail" value={centreVHU.adresseMail} />
      <FieldDisplay
        label="Numéro agrément"
        value={centreVHU.numeroAgrementVHU}
      />
      <FieldDisplay
        label="Préfecture ayant délivré le numéro d'agrément"
        value={centreVHU.prefecture}
      />
    </AntdModal>
  );
}

export default ModalDetails;
