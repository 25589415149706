import React from 'react';
import { Button as AntdButton, Popover as AntdPopover } from 'antd';
import { FormInstance as AntdFormInstance } from 'antd/lib/form';

import { ErrorInfo, SingleFieldError, SubmitButtonConf } from './type';

export function createSubmitButton(
  configuration: SubmitButtonConf,
): React.ReactElement {
  const GetButton = (
    <AntdButton
      type={'primary'}
      onClick={configuration.submit}
      className={configuration.className}
      disabled={configuration.hasError}
      loading={configuration.isSubmiting}
    >
      {configuration.title}
    </AntdButton>
  );

  if (configuration.popOverConf) {
    return (
      <AntdPopover
        content={configuration.popOverConf.content}
        title={configuration.popOverConf.title}
        trigger={'hover'}
      >
        {GetButton}
      </AntdPopover>
    );
  } else {
    return GetButton;
  }
}

export function usePromiseTrigger(): [
  Promise<void> | null,
  React.Dispatch<React.SetStateAction<Promise<void> | null>>,
] {
  const [submitPromise, setSubmitPromise] =
    React.useState<Promise<void> | null>(null);
  React.useEffect(() => {
    let mounted = true;
    const s = async (): Promise<void> => {
      if (submitPromise) {
        await submitPromise;

        if (mounted) {
          setSubmitPromise(null);
        }
      }
    };

    void s();
    return (): void => {
      mounted = false;
    };
  }, [submitPromise]);

  return [submitPromise, setSubmitPromise];
}

function isArrayOfString(strArray: unknown[]): strArray is string[] {
  for (const str of strArray) {
    if (typeof str !== 'string') {
      return false;
    }
  }

  return true;
}

function isSingleError(singleError: unknown): singleError is SingleFieldError {
  let isMatching = false;

  if (typeof singleError === 'object' && singleError !== null) {
    Object.entries(singleError).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'name' && value !== null && Array.isArray(value)) {
        isMatching = isArrayOfString(value);
      }
    });
  }

  return isMatching;
}

function areAllSingleError(
  singleErrors: unknown[],
): singleErrors is SingleFieldError[] {
  for (const singleError of singleErrors) {
    if (!isSingleError(singleError)) {
      return false;
    }
  }

  return true;
}

function isErrorInfo(errorInfo: unknown): errorInfo is ErrorInfo {
  let isMatching = false;

  if (typeof errorInfo === 'object' && errorInfo !== null) {
    Object.entries(errorInfo).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'errorFields' && value !== null && Array.isArray(value)) {
        isMatching = value.length > 0 ? areAllSingleError(value) : true;
      }
    });
  }

  return isMatching;
}

export function generateSubmitFunction<T>(
  setSubmitPromise: React.Dispatch<React.SetStateAction<Promise<void> | null>>,
  onSubmit: (values: T) => Promise<void>,
  values: React.MutableRefObject<T>,
  form: AntdFormInstance,
): (e?: React.FormEvent<HTMLFormElement>) => void {
  const triggerSubmit = async (values: T): Promise<void> => {
    //  validate all fields before submiting the form
    try {
      await form.validateFields(Object.keys(values));
    } catch (e) {
      //  find first field error and scroll to it
      if (isErrorInfo(e) && e.errorFields.length > 0) {
        const firstFieldWithError = e.errorFields[0];
        if (firstFieldWithError.name.length > 0) {
          const name = firstFieldWithError.name[0];
          form.scrollToField(name, { behavior: 'smooth' });
        }
      }

      // Error in validateFields.
      // Antd should have already disabled submit button and display errors on fields
      return;
    }

    //  all fields are validated, submit the form
    await onSubmit(values);
  };
  return (e?: React.FormEvent<HTMLFormElement>): void => {
    e && e.preventDefault();

    setSubmitPromise(async () => await triggerSubmit(values.current));
  };
}
