import React from 'react';
import { Modal as AntdModal } from 'antd';
import moment from 'moment';

import { FourriereDto } from 'lib_api/lib/api/gen';

import { FieldDisplay } from 'components/FieldDisplay/FieldDisplay';
import { DataFieldDisplay } from 'components/FieldDisplay/DataFieldDisplay';
import { DATE_FORMAT } from 'utils/formats';

import './ModalDetails.less';

interface ModalDetailsProps {
  fourriere: FourriereDto;
  visible: boolean;
  close: () => void;
}

function ModalDetails({
  fourriere,
  visible,
  close,
}: ModalDetailsProps): React.ReactElement {
  return (
    <AntdModal
      visible={visible}
      onCancel={close}
      footer={null}
      destroyOnClose={true}
      width={980}
      maskClosable={false}
      className="modalDetails"
    >
      <FieldDisplay label="Id de corrélation" value={fourriere.idCorrelation} />
      <FieldDisplay label="Raison sociale" value={fourriere.raisonSociale} />
      <FieldDisplay
        label="Numéro d'agrément"
        value={fourriere.numeroAgrement}
      />
      <DataFieldDisplay
        formatter={date => moment(date).format(DATE_FORMAT)}
        label="Date de l'agrément"
        value={fourriere.dateAgrement}
      />
      <DataFieldDisplay
        formatter={date => moment(date).format(DATE_FORMAT)}
        label="Date de fin de l'agrément"
        value={fourriere.finAgrement}
      />
      <FieldDisplay
        label="Autorité(s) de fourrière"
        value={fourriere.autoritesFourrieres?.map(af => {
          return af?.libelleComplet;
        })}
        additionalValueClassName={'multiLineValue'}
      />
      <FieldDisplay
        label="Nombre de places"
        value={fourriere.nombreEmplacements}
      />
      <FieldDisplay label="Numéro de voie" value={fourriere.noVoie} />
      <FieldDisplay label="Répétition" value={fourriere.repetition} />
      <FieldDisplay label="Libellé de voie" value={fourriere.adresse} />
      <FieldDisplay
        label="Complément d'adresse"
        value={fourriere.complementAdresse}
      />
      <FieldDisplay label="Code postal" value={fourriere.codePostal} />
      <FieldDisplay label="Commune" value={fourriere.commune} />
      <FieldDisplay label="Téléphone fixe" value={fourriere.telephoneFixe} />
      <FieldDisplay
        label="Téléphone portable"
        value={fourriere.telephoneMobile}
      />
      <FieldDisplay label="Adresse email" value={fourriere.adresseMail} />
      <FieldDisplay label="Coordonnées GPS" value={fourriere.coordonneesGPS} />
      <FieldDisplay
        label="Raison sociale DNID"
        value={fourriere.raisonSocialeDnid}
      />
      <FieldDisplay label="Adresse DNID" value={fourriere.adresseDnid} />
      <FieldDisplay
        label="Nom du responsable"
        value={fourriere.nomResponsable}
      />
      <FieldDisplay
        label="Adresse email du responsable"
        value={fourriere.adresseMailResponsable}
      />
      <FieldDisplay label="Id Hermes" value={fourriere.idHermes} />
    </AntdModal>
  );
}

export default ModalDetails;
