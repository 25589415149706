import {
  AutoriteFourriereDto,
  DossierResponseDto,
  EntreeVehiculeRequestDto,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { useApi } from 'hooks/ApiStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { useBoolean } from 'utils/genericUtils';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';

import { EntreVehiculeFormValues } from './types';

/**
 * Generate a EntreeVehicule object from a DossierDto
 *
 * @param dossier: DossierDto we want to extract SortieVehicule data
 */
export function generateEntreeVehiculeFromDossier(
  dossier: DossierResponseDto | null,
): EntreVehiculeFormValues {
  return {
    idCorrelationAutoriteFourriere:
      dossier?.body?.ficheInfraction?.idCorrelationAutoriteFourriereFicheFo ||
      undefined,
  };
}

/**
 * Convert an EntreeVehicule object to an EntreeVehiculeRequestDto
 * @param entreeVehicule
 */
export function convertEntreeVehiculeToEntreeVehiculeRequestDto(
  entreeVehicule: EntreVehiculeFormValues,
): EntreeVehiculeRequestDto {
  return {
    idCorrelationAutoriteFourriere:
      entreeVehicule.idCorrelationAutoriteFourriere || null,
    dateEnlevement:
      entreeVehicule.dateEnlevement?.format(UNIX_TIMESTAMP_FORMAT) || null,
    dateEntree:
      entreeVehicule.dateEntree?.format(UNIX_TIMESTAMP_FORMAT) || null,
  };
}

type UseSubmitEntreeVehiculeReturn = {
  submit: (values: EntreVehiculeFormValues) => void;
  inProgress: boolean;
};

export function useSubmitEntreeVehicule(
  closeModal: () => void,
): UseSubmitEntreeVehiculeReturn {
  const [dossier, setDossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const { GardienFourriereControllerApi } = useApi();
  const {
    value: inProgress,
    setIsTrue: setInProgressTrue,
    setIsFalse: setInProgressFalse,
  } = useBoolean();
  return {
    submit: (values: EntreVehiculeFormValues) => {
      setInProgressTrue();
      return GardienFourriereControllerApi.entreeVehiculeWithFormulaireEntreeUsingPOST(
        dossierId,
        convertEntreeVehiculeToEntreeVehiculeRequestDto(values),
      )
        .then(setDossier)
        .then(closeModal)
        .catch(backAlertMessage)
        .finally(setInProgressFalse);
    },
    inProgress: inProgress,
  };
}

export function removeRepeatedAf(
  afs: AutoriteFourriereDto[],
): AutoriteFourriereDto[] {
  if (afs.length <= 1) {
    return afs;
  }

  const afsAsMap = new Map<string, AutoriteFourriereDto>(
    afs.map(autorite => [autorite.libelleComplet, autorite]),
  );

  return Array.from(afsAsMap.values());
}
