import React, { useCallback, useEffect, useState } from 'react';
import AntdForm from 'antd/lib/form';

import {
  FicheProfilFourriere,
  FicheProfilFourriereBlocCoordonnees,
  SemaineHoraire,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { EditableProfilFormContainer } from 'components/ProfilFormContainer';
import InputStreetName from './Inputs/InputStreetName';
import InputStreetNumber from './Inputs/InputStreetNumber';
import InputAdressComplement from './Inputs/InputAdressComplement';
import InputZIPCode from './Inputs/InputZIPCode';
import InputCity from './Inputs/InputCity';
import InputPhoneNumber from './Inputs/InputPhoneNumber';
import InputMobileNumber from './Inputs/InputMobileNumber';
import InputEmail from './Inputs/InputEmail';
import InputGPSLocalization from './Inputs/InputGPSLocalization';
import InputBossName from './Inputs/InputBossName';
import InputBossEmail from './Inputs/InputBossEmail';
import InputHoraire from './Inputs/InputHoraire';
import { generateFicheProfil, generateFourriereDTO } from '../utils';
import { useMountedComponent } from 'hooks/useMountedComponent';
import { useConfirmProfile } from 'hooks/utils/useConfirmProfil';

const extractInitialValues = (
  values: FicheProfilFourriere,
): FicheProfilFourriereBlocCoordonnees => {
  return {
    streetNumber: values.streetNumber,
    streetName: values.streetName,
    adressComplement: values.adressComplement,
    zipCode: values.zipCode,
    city: values.city,
    phoneNumber: values.phoneNumber,
    mobileNumber: values.mobileNumber,
    email: values.email,
    gpsLocalization: values.gpsLocalization,
    bossName: values.bossName,
    bossEmail: values.bossEmail,
    horaires: values.horaires,
  };
};

interface FicheProfilProps {
  values: FicheProfilFourriere;
  mustConfirmAccount: boolean | undefined;
  updateRequiredActions: () => void;
}

const FicheProfilCoordonnesForm: React.FC<FicheProfilProps> = ({
  values,
  mustConfirmAccount,
  updateRequiredActions,
}: FicheProfilProps): React.ReactElement => {
  const user = useUserStore();
  const gardienFourriereControllerApi = useApi().GardienFourriereControllerApi;
  const behaviourOnError = useHandleBackErrors();
  const isMounted = useMountedComponent();
  const [data, setData] = useState(extractInitialValues(values));
  const [horaires, setHoraires] = useState<SemaineHoraire>(data.horaires);
  const [pForm] = AntdForm.useForm();
  const confirmProfile = useConfirmProfile(user.username);

  const generateRequestDto = useCallback(
    (valuesCoordonnees: FicheProfilFourriereBlocCoordonnees) => {
      return generateFourriereDTO({
        ...values,
        ...valuesCoordonnees,
        horaires: horaires,
      });
    },
    [horaires, values],
  );
  const submit = useCallback(
    (valuesCoordonnees: FicheProfilFourriereBlocCoordonnees) => {
      const newInfos = generateRequestDto(valuesCoordonnees);
      return gardienFourriereControllerApi
        .setFourriereInfoUsingPOST(
          user.idCorrelationFourriere ? user.idCorrelationFourriere : '',
          newInfos,
        )
        .then(async savedInfo => {
          if (!isMounted.current) {
            return;
          }

          const datas = generateFicheProfil(user, savedInfo);
          setData(datas);
          setHoraires(datas.horaires);

          // automatically confirm referentials edited by non admin users
          await confirmProfile({
            idFourriereToConfirm: savedInfo.id,
            mustConfirmAccount: mustConfirmAccount,
          });
          updateRequiredActions();
        })
        .catch(reason => {
          behaviourOnError(reason);
        });
    },
    [
      user,
      gardienFourriereControllerApi,
      behaviourOnError,
      generateRequestDto,
      isMounted,
      setData,
      confirmProfile,
      updateRequiredActions,
      mustConfirmAccount,
    ],
  );

  useEffect(() => {
    pForm.setFieldsValue(data);
  }, [pForm, data]);

  return (
    <EditableProfilFormContainer
      form={pForm}
      title="Coordonnées de ma fourrière"
      idForm="CoordonneesForm"
      initialValue={data}
      submit={submit}
    >
      {(data, isEdit) => (
        <>
          <InputStreetNumber
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputStreetName
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputAdressComplement
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputZIPCode
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputCity
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputPhoneNumber
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputMobileNumber
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputEmail
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputGPSLocalization
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputBossName
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputBossEmail
            values={data}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
          />
          <InputHoraire
            values={{ ...values, horaires: horaires }}
            isEditable={isEdit}
            generateRequestDto={generateRequestDto}
            setValues={setHoraires}
          />
        </>
      )}
    </EditableProfilFormContainer>
  );
};

export default FicheProfilCoordonnesForm;
