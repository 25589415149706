import { useCallback, useState } from 'react';

import { useBoolean } from '../../utils/genericUtils';
import { useHandleBackErrors } from './handleBackErrors';

export interface UseFetchObjectResult<ObjectType> {
  object: ObjectType | null;
  inProgress: boolean;
  errorOccured: boolean;
  fetch: () => void;
}

export function useFetchObject<ObjectType>(
  fetcher: () => Promise<ObjectType>,
): UseFetchObjectResult<ObjectType> {
  const {
    value: inProgress,
    setIsTrue: startInProgress,
    setIsFalse: stopInProgress,
  } = useBoolean();
  const {
    value: errorOccured,
    setIsTrue: isErrorOccured,
    setIsFalse: resetErrorOccured,
  } = useBoolean();
  const [object, setObject] = useState<ObjectType | null>(null);
  const behaviourOnError = useHandleBackErrors();

  const fetch = useCallback(() => {
    startInProgress();
    resetErrorOccured();
    fetcher()
      .then(setObject)
      .catch(error => {
        behaviourOnError(error);
        isErrorOccured();
      })
      .finally(stopInProgress);
  }, [
    fetcher,
    startInProgress,
    stopInProgress,
    isErrorOccured,
    resetErrorOccured,
    setObject,
  ]);

  return {
    object,
    errorOccured,
    inProgress,
    fetch,
  };
}
