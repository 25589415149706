import React from 'react';
import { RangeDatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FormFilterFieldGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';
import { CSSUtils } from 'utils/CSSUtils';

export function DateMiseEnFourriere({
  values,
}: FormFilterFieldGardien): React.ReactElement {
  return (
    <RangeDatePicker
      wrapperProps={{
        values: values,
        fieldKey: 'dateRangeMiseEnFourriere',
        itemProps: {
          label: "Date d'entrée sur le parc",
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      dateRangeProps={{
        placeholder: ['De', 'A'],
        allowEmpty: [true, true],
        style: { width: '100%' },
        locale: frFR.DatePicker,
      }}
    />
  );
}
