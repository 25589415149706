import React from 'react';
import { EntreeVehiculeViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { autoriteFourriereDtoToString } from 'utils/dtoToString';
import { extractNonnullDossierIdOrThrow } from 'utils/dossierUtils';
import BaseForm from 'components/BaseForm/BaseForm';
import BaseDateTimePicker from 'components/BaseForm/BaseDateTimePicker';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { useDossierContext } from 'hooks/dossiers/DossierContext';
import {
  fetchAutoriteFourriereOfFourriere,
  fetchValidAutoriteFourriere,
} from 'search/searchAutoriteFourriere';

import {
  convertEntreeVehiculeToEntreeVehiculeRequestDto,
  generateEntreeVehiculeFromDossier,
  removeRepeatedAf,
  useSubmitEntreeVehicule,
} from './utils';

interface EntreeVehiculeProps {
  close: () => void;
}

const EntreeVehiculeForm: React.FC<EntreeVehiculeProps> = ({
  close,
}: EntreeVehiculeProps): React.ReactElement => {
  const user = useUserStore();
  const [dossier] = useDossierContext();
  const dossierId = extractNonnullDossierIdOrThrow(dossier);
  const { GardienFourriereControllerApi, ReferentielControllerApi } = useApi();

  const userIdFourriere = user.idCorrelationFourriere;
  if (!userIdFourriere) {
    throw new Error('id correlation fourrière should be defined');
  }

  const { inProgress, submit } = useSubmitEntreeVehicule(close);

  const immatriculation =
    dossier.body?.ficheVehicule?.immatriculation?.numero || undefined;

  const selectedAfByFo =
    dossier.body?.ficheInfraction?.idCorrelationAutoriteFourriereFicheFo || '';

  return (
    <>
      <h3 className={'mainTitle'}>
        {`Enregistrer l'entrée du véhicule ${immatriculation || ''}`}
      </h3>

      <BaseForm
        initialValues={generateEntreeVehiculeFromDossier(dossier)}
        isSubmitting={inProgress}
        validateField={async (field, formValues) => {
          const response =
            await GardienFourriereControllerApi.validateEntreeVehiculeFormFieldUsingPOST(
              field,
              dossierId,
              convertEntreeVehiculeToEntreeVehiculeRequestDto(formValues),
            );

          const violations = response.entreeVehiculeViolationDtoList ?? [];
          if (violations.length === 0) {
            return Promise.resolve();
          }

          return Promise.reject(violations[0].message);
        }}
        onSubmit={submit}
        onCancel={close}
        inputs={[
          {
            field:
              EntreeVehiculeViolationDtoFormFieldEnum.ID_CORRELATION_AUTORITE_FOURRIERE,
            required: true,
            name: 'idCorrelationAutoriteFourriere',
            //dependencie ajoutée pour déclencher une validation avant la soumission de l'AF saisie dans BdR
            dependencies: ['dateEnlevement'],
            label: 'Autorité de fourrière',
            render: () => {
              return (
                <AsyncSelect
                  fetchOptions={() => {
                    return Promise.all([
                      fetchValidAutoriteFourriere(
                        ReferentielControllerApi,
                        selectedAfByFo,
                      ),
                      fetchAutoriteFourriereOfFourriere(
                        ReferentielControllerApi,
                        userIdFourriere,
                      ),
                    ]).then(results => removeRepeatedAf(results.flat()));
                  }}
                  getOptionLabel={autorite =>
                    autoriteFourriereDtoToString(autorite)
                  }
                  getOptionValue={autorite => autorite.idCorrelation}
                />
              );
            },
          },
          {
            field: EntreeVehiculeViolationDtoFormFieldEnum.DATE_ENLEVEMENT,
            required: true,
            name: 'dateEnlevement',
            dependencies: ['dateEntree'],
            label: "Date et heure d'enlèvement",
            render: () => {
              return <BaseDateTimePicker />;
            },
          },
          {
            field: EntreeVehiculeViolationDtoFormFieldEnum.DATE_ENTREE,
            required: true,
            name: 'dateEntree',
            dependencies: ['dateEnlevement'],
            label: "Date et heure d'entrée sur le parc",
            render: () => {
              return <BaseDateTimePicker />;
            },
          },
        ]}
      />
    </>
  );
};

export default EntreeVehiculeForm;
