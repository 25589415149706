import React from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';
import { RequestStatus } from 'types/RequestStatus';
import { notification as AntdNotification } from 'antd';
import { ImportViolationListDto } from 'lib_api/lib/api/gen';

type ImportFunction = (file: Blob) => void;

/**
 * Open a notification box within which the whole message is displayed.
 * The violations encountered are not blocking the import but can lead to unexpected behaviour when computing the classement.
 */
const handleViolations = (violations: ImportViolationListDto) => {
  const errors: string[] = violations.violations.map(v => v.message);

  if (errors.length !== 0) {
    // display the error
    AntdNotification.warn({
      message: `La logique peut ne pas adopter le comportement voulu`,
      // build the multiline message
      description: errors.map((value, index) => (
        <span key={index}>
          {value}
          <br />
        </span>
      )),
      style: {
        width: 600,
      },
      duration: 0, // won't close automatically
      placement: 'topLeft',
    });
  }
};

/**
 * Hook to import new classement logic updates.
 */
export function useImportClassement(
  setStatus: Dispatch<SetStateAction<RequestStatus | undefined>>,
): ImportFunction {
  const classementController = useApi().AdminFonctionnelControllerApi;
  const behaviourOnError = useHandleBackErrors();

  return async (file: Blob) => {
    setStatus(RequestStatus.PENDING);
    await classementController
      .importCsvTableUsingPOST(file)
      .then(violations => {
        handleViolations(violations);
        setStatus(RequestStatus.SUCCESS);
      })
      .catch((errorResponse: Response) => {
        setStatus(RequestStatus.ERROR);
        behaviourOnError(errorResponse, true);
      });
  };
}
