import React, { useState } from 'react';
import {
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';

import GFFilterProvider from 'hooks/GFFilterStoreContext';
import PaginationProvider from 'hooks/PaginationStoreContext';
import SortProvider from 'hooks/SortStoreContext';
import { useActionsRequises } from 'hooks/useActionsRequises';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { NOT_FOUND_PATH } from 'utils/enumData';
import { FilterDashboardGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';
import { GardienSortOptions } from './Dashboard/DashboardTable/types';
import { Navigate, Route, Routes } from 'react-router-dom';
import DashBoard from './Dashboard';
import Details from './Dossier/Details/Details';

const GfRouter: React.FC = () => {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] = useState<Partial<FilterDashboardGardien>>({});
  const [sort, setSort] = useState<
    Partial<TableSort<GardienSortOptions | undefined>>
  >({
    IMMATRICULATION: true,
  });

  const gfActionsRequises = useActionsRequises();

  return (
    <PaginationProvider
      value={{ pagination: pagination, setPagination: setPagination }}
    >
      <GFFilterProvider
        value={{
          filter: filter,
          setFilter: setFilter,
          actionsRequises: gfActionsRequises,
        }}
      >
        <SortProvider value={{ sort: sort, setSort: setSort }}>
          <Routes>
            <Route path="" element={<DashBoard />} />
            <Route path="dossier/:id" element={<Details />} />
            <Route
              path="*"
              element={<Navigate replace to={NOT_FOUND_PATH} />}
            />
          </Routes>
        </SortProvider>
      </GFFilterProvider>
    </PaginationProvider>
  );
};

export default GfRouter;
