import { useCallback, useState } from 'react';

import {
  BonEnlevementGardienRequestDto,
  BonEnlevementGardienViolationDtoFormFieldEnum,
  DossierResponseDto,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from '../../../../../../hooks/utils/backAlertMessage';
import { BonEnlevementGardienFormValues } from 'types/BonEnlevementGardien';
import { useApi } from 'hooks/ApiStoreContext';
import { SubmitFunction, ValidateFunction } from 'components/BaseForm/types';

/**
 * Used to map bon enlevement
 *
 * @param values SaisieBonEnlevement object we want to map
 */
export const buildRequestDto = (
  values: BonEnlevementGardienFormValues,
): BonEnlevementGardienRequestDto => {
  return {
    adresse: values.adresseVhu || null,
    complementAdresse: values.complementAdresse || null,
    isCarteGriseRetiree: values.isCarteGriseRetiree || false,
    codePostal: values.codePostalVhu || null,
    commune: values.communeVhu || null,
    idCorrelationCentreVhu: values.idCorrelationCentreVhu || null,
    motifAbsenceImmatriculation: values.motifAbsenceImmatriculation || null,
    numeroAgrement: values.numeroAgrement || null,
    numeroSiren: values.numeroSiren || null,
    prefectureAgrement: values.prefectureAgrement || null,
    raisonSociale: values.raisonSociale || null,
  };
};

export function buildFormValues(
  dossier: DossierResponseDto | null,
): BonEnlevementGardienFormValues {
  return {
    adresseVhu: dossier?.body?.traitement?.bonEnlevementGardienData?.adresse,
    complementAdresse:
      dossier?.body?.traitement?.bonEnlevementGardienData?.complementAdresse ??
      undefined,
    communeVhu: dossier?.body?.traitement?.bonEnlevementGardienData?.commune,
    codePostalVhu:
      dossier?.body?.traitement?.bonEnlevementGardienData?.codePostal,
    idCorrelationCentreVhu:
      dossier?.body?.traitement?.bonEnlevementGardienData
        ?.idCorrelationCentreVhu ?? undefined,
    isCarteGriseRetiree:
      dossier?.body?.traitement?.bonEnlevementGardienData
        ?.isCarteGriseRetiree ?? true,
    motifAbsenceImmatriculation:
      dossier?.body?.traitement?.bonEnlevementGardienData
        ?.motifAbsenceImmatriculation || undefined,
    numeroAgrement:
      dossier?.body?.traitement?.bonEnlevementGardienData?.numeroAgrement ||
      undefined,
    numeroSiren:
      dossier?.body?.traitement?.bonEnlevementGardienData?.numeroSiren ||
      undefined,
    prefectureAgrement:
      dossier?.body?.traitement?.bonEnlevementGardienData?.prefectureAgrement ||
      undefined,
    raisonSociale:
      dossier?.body?.traitement?.bonEnlevementGardienData?.raisonSociale ||
      undefined,
  };
}

export function useValidateUpdateBonEnlevementGardien(
  dossierId: string,
): ValidateFunction<
  BonEnlevementGardienFormValues,
  BonEnlevementGardienViolationDtoFormFieldEnum
> {
  const { GardienFourriereControllerApi: controller } = useApi();

  const validate = useCallback(
    async (
      field: BonEnlevementGardienViolationDtoFormFieldEnum,
      values: BonEnlevementGardienFormValues,
    ) => {
      const response =
        await controller.validateBonEnlevementGardienFieldUsingPOST(
          field,
          dossierId,
          buildRequestDto(values),
        );

      const violations = response.bonEnlevementGardienViolationDtoList ?? [];
      if (violations.length === 0) {
        return Promise.resolve();
      }

      return Promise.reject(violations[0].message);
    },
    [],
  );

  return {
    validate: validate,
  };
}

export function useSubmitUpdateBonEnlevementGardien(
  dossierId: string,
  setDossier: (dossierResponseDto: DossierResponseDto) => void,
  closeModal: () => void,
): SubmitFunction<BonEnlevementGardienFormValues> {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const { GardienFourriereControllerApi: controller } = useApi();

  const submit = useCallback(
    (values: BonEnlevementGardienFormValues) => {
      setInProgress(true);
      return controller
        .updateBonEnlevementGardienUsingPOST(dossierId, buildRequestDto(values))
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    [closeModal, setDossier],
  );

  return {
    submit: submit,
    inProgress: inProgress,
  };
}
