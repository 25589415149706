import { useState } from 'react';
import FileSaver from 'file-saver';
import { UserDtoProfileEnum } from 'lib_api/lib/api/gen';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useApi } from 'hooks/ApiStoreContext';
import { RequestStatus } from 'types/RequestStatus';
import { ManuelType, ManuelTypeEnum } from 'types/referentiels/Manuels';
import { extractFileNameFromContentDisposition } from 'utils/requestUtils';

interface ExportManuelResult {
  downloadStatus: RequestStatus | undefined;
  download: () => Promise<void>;
}

/**
 * Hook to import any referentiel in CSV or JSOn format
 */
function useExportManuel(
  userType: UserDtoProfileEnum,
  manuelType: ManuelType,
): ExportManuelResult {
  const behaviourOnError = useHandleBackErrors();
  const { ManuelControllerApi: api } = useApi();
  const [requestStatus, setStatus] = useState<RequestStatus>();

  const download = async () => {
    setStatus(RequestStatus.PENDING);
    let fileName: string | undefined = undefined;

    const apiCall =
      manuelType === ManuelTypeEnum.UTILISATEUR
        ? api.downloadManualUtilisateurUsingGET(userType)
        : api.downloadManualBdrUsingGET(userType);

    await apiCall
      .then((result: Response) => {
        fileName = extractFileNameFromContentDisposition(result);
        // Create dom link to download file
        return result.blob();
      })
      .then((blob: Blob) => {
        FileSaver.saveAs(blob, fileName);
        setStatus(RequestStatus.SUCCESS);
      })
      .catch((errorResponse: Response) => {
        setStatus(RequestStatus.ERROR);
        behaviourOnError(errorResponse, true);
      });
  };
  return {
    downloadStatus: requestStatus,
    download: download,
  };
}

export default useExportManuel;
