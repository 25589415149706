import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { useState } from 'react';

interface SubmitSuppressionDossierResult {
  submitSuppression: () => void;
  loading: boolean;
}

export function useSubmitSuppressionDossier(
  dossierId: string,
  onSuccess: () => void,
): SubmitSuppressionDossierResult {
  const [loading, setLoading] = useState<boolean>(false);
  const behaviourOnError = useHandleBackErrors();
  const { AdminFonctionnelControllerApi: controller } = useApi();
  const submit = () => {
    setLoading(true);
    controller
      .suppressionDossierUsingDELETE(dossierId)
      .then(() => {
        onSuccess();
      })
      .catch((error: Response) => {
        behaviourOnError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    submitSuppression: submit,
    loading: loading,
  };
}
