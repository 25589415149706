import React from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';
import { useSubmitAnnulationSortie } from 'hooks/dossiers/useSubmitAnnulationSortie';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen';

interface ModalConfirmationAnnulationSortieProps {
  visible: boolean;
  close: () => void;
  dossierId: string;
}

export const ModalConfirmationAnnulationSortie = ({
  visible,
  close,
}: ModalConfirmationAnnulationSortieProps): React.ReactElement => {
  const [{ result: dossier, setDossier }, { fetchEvents }] =
    useDossierAdminContext();
  const thenSubmit = (newDossier: DossierDtoAdminFonctionnel) => {
    setDossier(newDossier);
    void fetchEvents();
    close();
  };

  const { submitAnnulation, loading } = useSubmitAnnulationSortie(
    dossier.id,
    thenSubmit,
  );
  return (
    <AntdModal
      className="modalValidity"
      visible={visible}
      onCancel={close}
      footer={null}
      closable={false}
      destroyOnClose={true}
      maskClosable={false}
    >
      <h3>{'Confirmez-vous l’annulation de la sortie ?'}</h3>
      <p>
        {
          "Après confirmation, le dossier reviendra à l'état précédant sa sortie."
        }
      </p>
      <div className="modalActions">
        <AntdButton onClick={close}>Annuler</AntdButton>
        <AntdButton
          type="primary"
          onClick={() => {
            submitAnnulation();
          }}
          disabled={loading}
          loading={loading}
        >
          {loading ? 'Veuillez patienter' : 'Confirmer'}
        </AntdButton>
      </div>
    </AntdModal>
  );
};
