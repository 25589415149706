import React, { useMemo } from 'react';
import AntdAutoComplete, {
  AutoCompleteProps as AntdAutoCompleteProps,
} from 'antd/lib/auto-complete';

import { WrappedProps } from '../types';
import FieldWrapper from '../FieldWrapper';
import { hasStringValueField, isValueProps } from '../utils';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';
import { AutoCompleteOptionProps } from './types';
import { isEmptyObject } from 'utils/genericUtils';

interface Props<T, V extends AutoCompleteOptionProps> {
  wrapperProps: WrappedProps<T>;
  autoCompleteProps: AntdAutoCompleteProps;
  optionProps: Array<V>;
}

function WrappedAutoComplete<T, V extends AutoCompleteOptionProps>({
  wrapperProps,
  optionProps,
  autoCompleteProps,
}: Props<T, V>): React.ReactElement {
  wrapperProps.itemProps.trigger = 'onChange';
  wrapperProps.itemProps.validateTrigger = 'onBlur';

  const autocomplete = useMemo(() => {
    //  Prevent the select to use basic options to build our own
    autoCompleteProps.options = undefined;
    return (
      <AntdAutoComplete {...autoCompleteProps}>
        {optionProps.map((option: V, index: number) => (
          <AntdAutoComplete.Option key={`option_${index}`} value={option.value}>
            {option.label}
          </AntdAutoComplete.Option>
        ))}
      </AntdAutoComplete>
    );
  }, [autoCompleteProps, optionProps]);

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;

  let value = formActions.getFieldValue(fieldKey);

  // if value has a complex type, then we need to convert the object
  // to a value renderable by FieldWrapper
  if (
    value !== null &&
    value !== undefined &&
    wrapperProps.itemProps?.getValueProps
  ) {
    const valueProps = wrapperProps.itemProps.getValueProps(value);
    if (isValueProps(valueProps)) {
      value = valueProps.value;
    } else if (isEmptyObject(valueProps)) {
      value = '';
    } else if (typeof valueProps === 'string') {
      value = valueProps;
    } else {
      throw new Error(
        'getValueProps should return { value: string }, string or empty object',
      );
    }
  }

  const itemprops = {
    ...wrapperProps.itemProps,
    getValueProps: (value: unknown) => {
      if (wrapperProps.itemProps.getValueProps !== undefined) {
        const valueProps = wrapperProps.itemProps.getValueProps(value);
        if (hasStringValueField(valueProps)) {
          return valueProps;
        }
        if (isEmptyObject(valueProps)) {
          return { value: '' };
        }
      }
      if (!value || typeof value === 'string') {
        return { value: value ?? '' };
      }
      if (isEmptyObject(value)) {
        return { value: '' };
      }
      throw new Error(
        'Type of value is not recognized. It should be String or an object containing `value` field',
      );
    },
  };
  return (
    <FieldWrapper
      {...{ ...wrapperProps, itemProps: itemprops }}
      WrappedInput={autocomplete}
      ConsultationElement={value}
    />
  );
}

export default WrappedAutoComplete;
