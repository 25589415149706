import React from 'react';

import { Select as AntdSelect } from 'antd';

import './MultiLinesSelectOption.less';

interface MultiLinesDivProps {
  className: string;
  children: string | number;
}

interface MultiLinesOptionProps {
  key: string | number;
  value: React.ReactText;
  children: React.ReactElement<MultiLinesDivProps>;
}

export function generateMultiLinesSelectOption(
  key: string | number,
  value: string | number,
  children: React.ReactNode,
): React.ReactElement<MultiLinesOptionProps> {
  return (
    <AntdSelect.Option key={key} value={value}>
      <div className={'Option'}>{children}</div>
    </AntdSelect.Option>
  );
}

function isMultiLinesDivProps(
  DivProps: unknown,
): DivProps is MultiLinesDivProps {
  if (
    DivProps !== undefined &&
    typeof DivProps === 'object' &&
    DivProps !== null
  ) {
    let foundClassName = false;
    let foundChildren = false;
    Object.entries(DivProps).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'className') {
        foundClassName =
          value !== undefined && value !== null && typeof value === 'string';
      } else if (key === 'children') {
        foundChildren =
          value !== undefined &&
          value !== null &&
          (typeof value === 'string' || typeof value === 'number');
      }
    });

    return foundClassName && foundChildren;
  }

  return false;
}

function isMultiLinesDiv(
  Div: unknown,
): Div is React.ReactElement<MultiLinesDivProps> {
  if (Div !== undefined && typeof Div === 'object' && Div !== null) {
    let foundProps = false;
    Object.entries(Div).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'props') {
        foundProps =
          value !== undefined && value !== null && isMultiLinesDivProps(value);
      }
    });

    return foundProps;
  }

  return false;
}

export function isMultiLineOptionProps(
  Option: unknown,
): Option is MultiLinesOptionProps {
  if (Option !== undefined && typeof Option === 'object' && Option !== null) {
    let foundKey = false;
    let foundValue = false;
    let foundChildren = false;
    Object.entries(Option).forEach(entry => {
      const key: string = entry[0];
      const value: unknown = entry[1];
      if (key === 'key') {
        foundKey =
          value !== undefined &&
          value !== null &&
          (typeof value === 'string' || typeof value === 'number');
      }
      if (key === 'value') {
        foundValue =
          value !== undefined &&
          value !== null &&
          (typeof value === 'string' || typeof value === 'number');
      }
      if (key === 'children') {
        foundChildren =
          value !== undefined && value !== null && isMultiLinesDiv(value);
      }
    });

    return foundKey && foundValue && foundChildren;
  }

  return false;
}
