import React from 'react';

import { CentreVHUDto } from 'lib_api/lib/api/gen';
import {
  buildFormValues,
  useSubmitUpdateCentreVHU,
  useValidateUpdateCentreVHU,
} from '../utils';
import ModalEditionCentreVHU from './ModalEditionCentreVHU';
import { CentreVHUFormValues } from '../types';

interface ModalUpdateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
  centreVHU: CentreVHUDto;
}

export default function ModalUpdate({
  close,
  visible,
  refreshDashboard,
  centreVHU,
}: ModalUpdateProps): React.ReactElement {
  const initials: CentreVHUFormValues = buildFormValues(centreVHU);
  const { submit, inProgress } = useSubmitUpdateCentreVHU(
    centreVHU.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateCentreVHU(centreVHU.idCorrelation);

  return (
    <ModalEditionCentreVHU
      close={close}
      visible={visible}
      title="Mettre à jour le centre VHU"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}
