import React from 'react';
import { DatePicker as AntdDatePicker } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';
import { Moment } from 'moment';
import { PickerDateProps } from 'antd/lib/date-picker/generatePicker';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { DATE_FORMAT } from 'utils/formats';

export default function BaseDatePicker(
  props: PickerDateProps<Moment>,
): React.ReactElement {
  return (
    <AntdDatePicker
      {...props}
      style={{ width: '100%' }}
      showTime={props.showTime ?? false}
      placeholder={props.placeholder ?? FormPlaceholders.Date}
      format={props.format ?? DATE_FORMAT}
      locale={frFR.DatePicker}
    />
  );
}
