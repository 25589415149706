import React from 'react';
import { AutoCompleteProps as AntdAutoCompleteProps } from 'antd';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import {
  DepartementApiGouvOption,
  useSearchDepartementApiGouv,
} from 'hooks/search/useSearchDepartementApiGouv';
import BaseAutocomplete from './BaseAutocomplete';

interface AutocompleteDepartementProps<FormValues>
  extends Omit<
    AntdAutoCompleteProps<string, DepartementApiGouvOption>,
    'options'
  > {
  name: keyof FormValues & AntdNamePath & string;
  codeField?: keyof FormValues & AntdNamePath & string;
}

function buildValue(option: DepartementApiGouvOption): string {
  return `${option.nom} - ${option.code}`.trim();
}

export default function AutocompleteDepartement<FormValues>({
  name,
  codeField,
  onChange,
  ...props
}: AutocompleteDepartementProps<FormValues>): React.ReactElement {
  return (
    <BaseAutocomplete<FormValues, DepartementApiGouvOption>
      {...props}
      optionsFetcher={useSearchDepartementApiGouv}
      buildValue={buildValue}
      getCurrentFieldValue={option => option.nom}
      name={name}
      dependencies={
        codeField && [
          {
            name: codeField,
            getValue: option => option.code,
          },
        ]
      }
    />
  );
}
