import React from 'react';
import { Modal as AntdModal, ModalProps } from 'antd';

/**
 * All parameters for the generic modal
 *
 * @param modalTitle: Title of modal displayed
 * @param child: Content of form
 */
interface BaseModalProps {
  width: string | number;
  isVisible: boolean;
  closeModal: () => void;
  children: React.ReactElement;
  antdProps?: ModalProps;
}
function BaseModal({
  width,
  isVisible,
  closeModal,
  children,
  antdProps,
}: BaseModalProps): React.ReactElement {
  return (
    <AntdModal
      visible={isVisible}
      onCancel={closeModal}
      footer={null}
      destroyOnClose={true}
      width={width}
      maskClosable={false}
      {...antdProps}
    >
      {children}
    </AntdModal>
  );
}

export default BaseModal;
