import React from 'react';
import { useParams } from 'react-router-dom';

import { useFetchDossier } from 'hooks/dossiers/useFetchDossier';
import { DossierProvider } from 'hooks/dossiers/DossierContext';
import { FicheDescriptiveDisplay } from 'components/FicheDescriptiveDisplay/FicheDescriptiveDisplay';
import Spinner from 'components/WrappedComponents/Spinner';
import ActionsSuiviVehicule from './Actions/ActionsSuiviVehicule';
import { PageDossierParam } from 'types/pageDossier';

import './PageDossier.less';

const PageDossier: React.FC = () => {
  const { id } = useParams<PageDossierParam>();

  if (id === undefined) {
    throw new Error("L'Id du dossier est null");
  }

  const [dossier, setDossier] = useFetchDossier(id);

  if (dossier === null) {
    return <Spinner />;
  }

  return (
    <DossierProvider value={{ dossier: dossier, setDossier: setDossier }}>
      <FicheDescriptiveDisplay>
        <div className={'actions'}>
          <ActionsSuiviVehicule />
        </div>
      </FicheDescriptiveDisplay>
    </DossierProvider>
  );
};

export default PageDossier;
