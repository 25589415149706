import React, { ReactElement } from 'react';
import {
  Button as AntdButton,
  Row as AntdRow,
  Typography as AntdTypo,
} from 'antd';

import { ArrowLeftOutlined } from '@ant-design/icons';

import { useAuthentication } from 'hooks/utils/authentication';
import { ErrorDto } from 'lib_api/lib/api/gen';

import './UnauthorizedPage.less';
import { defaultErrorMessage } from 'utils/ErrorMessages';

const { Title } = AntdTypo;

export type UnauthorizedProps = {
  error?: ErrorDto;
  errorMessage?: string;
};

const UnauthorizedPage = (
  unauthorizedProps: UnauthorizedProps,
): ReactElement => {
  const { redirectLogout } = useAuthentication();

  return (
    <>
      <div className="UnauthorizedPage">
        <Title>Impossible de continuer</Title>
        <p>
          <br />
          Ce compte ne dispose pas des droits nécessaires pour effectuer des
          opérations sur le SI Fourrières.
          <br />
          Pour plus d&apos;informations, veuillez contacter votre
          administrateur.
        </p>
        <div className="UnauthorizedPage--errorMsg">
          <ErrorDetailSection {...unauthorizedProps} />
        </div>
      </div>

      <AntdRow justify="center" align="middle">
        <AntdButton
          shape={'round'}
          type={'primary'}
          icon={<ArrowLeftOutlined />}
          onClick={redirectLogout}
        >
          Se déconnecter
        </AntdButton>
      </AntdRow>
    </>
  );
};

export default UnauthorizedPage;

const ErrorDetailSection = ({
  error,
  errorMessage,
}: UnauthorizedProps): ReactElement => {
  if (errorMessage) {
    return <p>{`Cause de l'erreur : ${errorMessage}`}</p>;
  }

  return (
    <>
      <p>
        {`Cause de l'erreur : ${error?.localized ?? defaultErrorMessage}`}
        <div>Code : {error?.code}</div>
        <div>Détail : {error?.message}</div>
      </p>
    </>
  );
};
