import React from 'react';

import { MotifMefDto } from 'lib_api/lib/api/gen';

import {
  buildFormValues,
  useSubmitUpdateMotifMef,
  useValidateUpdateMotifMef,
} from '../utils';
import { MotifMefFormValues } from 'types/referentiels/MotifMef';
import ModalEditionMotifMef from './ModalEditionMotifMef';

interface ModalUpdateProps {
  close: () => void;
  visible: boolean;
  refreshDashboard: () => void;
  motifMef: MotifMefDto;
}

function ModalUpdate({
  close,
  visible,
  refreshDashboard,
  motifMef,
}: ModalUpdateProps): React.ReactElement {
  const initials: MotifMefFormValues = buildFormValues(motifMef);
  const { submit, inProgress } = useSubmitUpdateMotifMef(
    motifMef.idCorrelation,
    refreshDashboard,
    close,
  );
  const { validate } = useValidateUpdateMotifMef(motifMef.idCorrelation);

  return (
    <ModalEditionMotifMef
      close={close}
      visible={visible}
      title="Mise à jour du motif de MEF"
      initials={initials}
      isSubmitting={inProgress}
      submit={submit}
      validate={validate}
    />
  );
}

export default ModalUpdate;
