import { useCallback, useEffect, useState } from 'react';
import {
  ForceOrdreControllerApi,
  MainleveeResponseDto,
} from 'lib_api/lib/api/gen';

export type MainleveeResponse = MainleveeResponseDto | null;

export const useFetchDonneesMainlevee = (
  controller: ForceOrdreControllerApi,
  id: string,
): [MainleveeResponse, () => Promise<void>, boolean, boolean] => {
  const [inProgress, setInProgress] = useState<boolean>(false);
  const [errorOccured, setErrorOccured] = useState<boolean>(false);
  const fetch = useCallback(async () => {
    setInProgress(true);
    setErrorOccured(false);
    try {
      const response = await controller.retrieveMainleveeUsingGET(id);
      setMainlevee(response);
    } catch {
      setErrorOccured(true);
    }

    setInProgress(false);
  }, []);

  const [mainLevee, setMainlevee] = useState<MainleveeResponse>(null);
  useEffect(() => {
    void fetch();
  }, [fetch]);

  return [mainLevee, fetch, inProgress, errorOccured];
};
