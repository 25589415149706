import React, { useCallback } from 'react';
import {
  ConditionsReactivateFaqDomainDto,
  FaqDomainAdminDto,
  AdminFonctionnelControllerApi,
  FaqDomainRequestDto,
  ReferentielSearchResultDtoFaqDomainAdminDto,
  ReferentielUsesDto,
} from 'lib_api/lib/api/gen';

import { FaqDomainFormValues, FilterDashboardFaqDomain } from './types';
import { FetchDataResult } from 'components/WrappedComponents/Table/types';
import { useApi } from 'hooks/ApiStoreContext';

export function convertFaqDomainFormValueToFaqDomainRequestDto(
  formValues: FaqDomainFormValues,
): FaqDomainRequestDto {
  return {
    name: formValues.name || null,
  };
}

export const createFaqDomainInitialValues: FaqDomainFormValues = {
  name: undefined,
};

export const buildUpdateFaqDomainInitialValues = (
  FaqDomain: FaqDomainAdminDto,
): FaqDomainFormValues => {
  return {
    name: FaqDomain.name,
  };
};

export function useSearchFaqDomain(
  filters: FilterDashboardFaqDomain,
): [
  () => Promise<ReferentielSearchResultDtoFaqDomainAdminDto>,
  (
    result: ReferentielSearchResultDtoFaqDomainAdminDto,
  ) => FetchDataResult<FaqDomainAdminDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;
  return [
    () => {
      return controller.searchFaqDomainUsingSpecsUsingGET({ filters });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityFaqDomain(
  controller: AdminFonctionnelControllerApi,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateFaqDomainDto>,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateFaqDomainUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateFaqDomainUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateFaqDomainDto> => {
      return controller.conditionReactivationFaqDomainUsingGET(idCorrelation);
    },
    [controller],
  );
  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
  ];
}
