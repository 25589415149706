import { useCallback, useState } from 'react';

/**
 *
 * @param object We want to know if this object is {}
 * @returns true if object is {}
 */
export function isEmptyObject(object: unknown): boolean {
  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).length === 0;
  }

  return false;
}

interface UseBooleanResult {
  value: boolean;
  setIsTrue: () => void;
  setIsFalse: () => void;
}

export function useBoolean(initialValue?: boolean): UseBooleanResult {
  const [value, setValue] = useState<boolean>(initialValue || false);
  const setIsTrue = useCallback(() => {
    setValue(true);
  }, [setValue]);
  const setIsFalse = useCallback(() => {
    setValue(false);
  }, [setValue]);

  return {
    value,
    setIsTrue,
    setIsFalse,
  };
}
