import { useEffect } from 'react';
import moment from 'moment';
import { notification } from 'antd';
import { useEnvironnement } from './EnvironementStoreContext';

interface MaintenanceType {
  isActuallyInMaintenance?: boolean;
  endDateFromNow?: string;
}

const useMaintenance = (): MaintenanceType => {
  const [env] = useEnvironnement();

  const currentDate = moment();

  // startDate indicates whether or not the SI is actually in maintenance
  const startDate = env.MAINTENANCE_START_DATE
    ? moment(env.MAINTENANCE_START_DATE)
    : null;

  // endDate only indicates the approximate time when the SI will be functional again
  const endDate = env.MAINTENANCE_END_DATE
    ? moment(env.MAINTENANCE_END_DATE)
    : null;

  const isActuallyInMaintenance =
    startDate?.isValid() && currentDate.isAfter(startDate);

  useEffect(() => {
    if (startDate?.isValid() && currentDate.isBefore(startDate)) {
      notification.warn({
        message: 'Une maintenance est plannifiée...',
        description: `Celle-ci devrait avoir lieu ${startDate.fromNow()}. Nous vous prions de bien vouloir nous en excuser.`,
        duration: 0,
        placement: 'topLeft',
      });
    }
  }, [currentDate, startDate]);

  // We want to display the remaining time only if it is valid and in the future
  const isEndDateValidAndAfterNow =
    endDate?.isValid() && endDate?.isAfter(currentDate);

  return {
    isActuallyInMaintenance,
    endDateFromNow:
      endDate && isEndDateValidAndAfterNow ? endDate.fromNow() : undefined,
  };
};

export default useMaintenance;
