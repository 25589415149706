import React from 'react';
import { DatePicker } from 'components/WrappedComponents';
import frFR from 'antd/lib/locale/fr_FR';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { DATE_FORMAT } from 'utils/formats';
import { CSSUtils } from 'utils/CSSUtils';
import { ProlongationMef } from 'types/ProlongationMef';
import {
  generateProlongationRequestDtoFromProlongationMef,
  validateProlongationRule,
} from './utils';
import { useApi } from 'hooks/ApiStoreContext';
import { ProlongationViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

interface InputProps {
  dossierId: string;
  values: ProlongationMef;
}

function DatePickerDecisionProcureur({
  dossierId,
  values,
}: InputProps): React.ReactElement {
  const key: keyof ProlongationMef = 'dateDecisionProcureur';
  const afController = useApi().AutoriteFourriereControllerApi;

  return (
    <DatePicker
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Date de décision du procureur',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject =
                  generateProlongationRequestDtoFromProlongationMef({
                    ...values,
                    [key]: value,
                  });
                return await validateProlongationRule(
                  dossierId,
                  afController,
                  requestObject,
                  ProlongationViolationDtoFormFieldEnum.DATE_DECISION_PROCUREUR,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      datePickerProps={{
        style: { width: '100%' },
        placeholder: FormPlaceholders.Date,
        format: DATE_FORMAT,
        locale: frFR.DatePicker,
        className: 'DatePickerDecisionProcureur',
      }}
    />
  );
}

export default DatePickerDecisionProcureur;
