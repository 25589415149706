import { useApi } from 'hooks/ApiStoreContext';
import { RequiredUserActionsDto } from 'lib_api/lib/api/gen';
import { useCallback } from 'react';
import { backAlertMessage } from './backAlertMessage';

type ConfirmProfilFunction = (
  requiredUserActions: Partial<RequiredUserActionsDto>,
) => Promise<void>;

export const useConfirmProfile = (username: string): ConfirmProfilFunction => {
  const { GardienFourriereControllerApi, AutoriteFourriereControllerApi } =
    useApi();

  const confirmAutoriteFourriere = useCallback(
    async (id: string) => {
      await AutoriteFourriereControllerApi.confirmAutoriteFourriereUsingPUT(
        id,
      ).catch(e => backAlertMessage(e));
    },
    [AutoriteFourriereControllerApi],
  );

  const confirmFourriere = useCallback(
    async (id: string) => {
      await GardienFourriereControllerApi.confirmFourriereUsingPUT(id).catch(
        e => backAlertMessage(e),
      );
    },
    [GardienFourriereControllerApi],
  );

  const confirmAccount = useCallback(
    async (username: string) => {
      await AutoriteFourriereControllerApi.confirmAccountUsingPUT(
        username,
      ).catch(e => backAlertMessage(e));
    },
    [AutoriteFourriereControllerApi],
  );

  /** Call the route(s) to confirm the profile information according to the required actions */
  return useCallback(
    async ({
      idFourriereToConfirm,
      idAutoriteFourriereToConfirm,
      mustConfirmAccount,
    }: Partial<RequiredUserActionsDto>) => {
      const promises = [];
      if (idFourriereToConfirm) {
        promises.push(confirmFourriere(idFourriereToConfirm));
      }
      if (idAutoriteFourriereToConfirm) {
        promises.push(confirmAutoriteFourriere(idAutoriteFourriereToConfirm));
      }
      if (mustConfirmAccount) {
        promises.push(confirmAccount(username));
      }

      await Promise.all(promises);
    },
    [confirmAccount, confirmFourriere, confirmAutoriteFourriere, username],
  );
};
