import React from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';
import {
  Button as AntdButton,
  Menu as AntdMenu,
  Dropdown as AntdDropdown,
} from 'antd';

import { useAuthentication } from 'hooks/utils/authentication';
import { useUserStore } from 'hooks/UserStoreContext';
import { getUserLabel } from 'utils/UserUtils';

interface ProfileButtonProps {
  urlProfil?: string;
}

function buildMenu(
  urlProfil: string | undefined,
  onClickLogout: () => void,
): React.ReactElement {
  return (
    <AntdMenu>
      {urlProfil !== undefined && (
        <AntdMenu.Item key={1}>
          <Link to={urlProfil}>Profil</Link>
        </AntdMenu.Item>
      )}

      <AntdMenu.Item key={2} onClick={onClickLogout}>
        Déconnexion
      </AntdMenu.Item>
    </AntdMenu>
  );
}

function ProfileButton({ urlProfil }: ProfileButtonProps): React.ReactElement {
  const user = useUserStore();
  const { redirectLogout } = useAuthentication();

  return (
    <AntdDropdown overlay={buildMenu(urlProfil, redirectLogout)}>
      <AntdButton
        icon={<UserOutlined />}
        type="default"
        shape="round"
        size="middle"
      >
        {getUserLabel(user)}
      </AntdButton>
    </AntdDropdown>
  );
}

export default ProfileButton;
