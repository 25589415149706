import {
  DisplayableReferentielEnumCSV,
  DisplayableReferentielEnumJSON,
} from 'types/Referentiel';
import { csvReferentielNames, jsonReferentielNames } from './enumData';
import {
  ReferentielEnumDtoReferentialEnumCSVEnum,
  ReferentielEnumDtoReferentialEnumJSONEnum,
} from 'lib_api/lib/api/gen';

/**
 * Map displayable name of CSV Referential with their corresponding enum value
 */
export function getCSVEnumWithDisplayableValue(): DisplayableReferentielEnumCSV {
  const displayableReferentielEnumCSV: DisplayableReferentielEnumCSV = {};

  return Object.values(ReferentielEnumDtoReferentialEnumCSVEnum).reduce(
    (acc, csvEnumValue) => {
      return {
        ...acc,
        [csvReferentielNames[csvEnumValue]]: csvEnumValue,
      };
    },
    displayableReferentielEnumCSV,
  );
}

/**
 * Map displayable name of JSON Referential with their corresponding enum value
 */
export function getJSONEnumWithDisplayableValue(): DisplayableReferentielEnumJSON {
  const displayableReferentielEnumJSON: DisplayableReferentielEnumJSON = {};

  return Object.values(ReferentielEnumDtoReferentialEnumJSONEnum).reduce(
    (acc, jsonEnumValue) => {
      return {
        ...acc,
        [jsonReferentielNames[jsonEnumValue]]: jsonEnumValue,
      };
    },
    displayableReferentielEnumJSON,
  );
}
