import React from 'react';
import './WarningMainLevee.less';

function WarningMainLevee(): React.ReactElement {
  return (
    <div className="WarningMainLevee">
      Ce véhicule a été remis au domaine après le constat d&apos;abandon. Pensez
      à vérifier que les frais de mise en vente ont été payés avant de délivrer
      l&apos;autorisation de sortie de fourrière.
    </div>
  );
}
export default WarningMainLevee;
