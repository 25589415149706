import React, { useState } from 'react';
import { Steps as AntdSteps } from 'antd';
import Icon, { FileTextOutlined, HomeOutlined } from '@ant-design/icons';

import { CarIcon } from 'components/Icon';
import { initialFicheDescriptiveFormValues } from './utils';
import { userSummaryConfirm } from './SummaryConfirm/SummaryConfirm';
import { useRefEtatDetails } from 'hooks/referentiels/useRefEtatDetails';
import FicheInfractionForm from 'pages/gardien-fourriere/FicheDescriptive/FicheInfractionForm/FicheInfractionForm';
import { useApi } from 'hooks/ApiStoreContext';
import FicheFourriereForm from './FicheFourriereForm/FicheFourriereForm';
import {
  FicheDescriptiveFormValues,
  FicheFourriereFormValues,
  FicheInfractionFormValues,
  FicheVehiculeFormValues,
} from './types';
import FicheVehiculeForm from './FicheVehiculeForm/FicheVehiculeForm';

import './FicheDescriptive.less';

const { Step } = AntdSteps;
export type FicheDescriptiveFormProps = {
  onCreationSucceed: () => void;
};

export default function FicheDescriptiveForm({
  onCreationSucceed,
}: FicheDescriptiveFormProps): React.ReactElement {
  const [indexTab, setIndexTab] = useState(0);
  const [data, setData] = useState<FicheDescriptiveFormValues>(
    initialFicheDescriptiveFormValues(),
  );
  const { ReferentielControllerApi: refController } = useApi();
  const etatDetails = useRefEtatDetails(data.ficheVehicule.etat);
  const onChangeTab = (indexTab: number): void => {
    setIndexTab(Math.min(2, Math.max(indexTab, 0)));
  };
  const goNextTab = (): Promise<void> => {
    return new Promise(() => {
      onChangeTab(indexTab + 1);
    });
  };
  const goPreviousTab = (): Promise<void> => {
    return new Promise(() => {
      onChangeTab(indexTab - 1);
    });
  };

  const userConfirm = (): Promise<void> => {
    return userSummaryConfirm(data, etatDetails, refController);
  };

  const steps = [
    {
      title: 'Infraction',
      content: (
        <FicheInfractionForm
          initialValues={data.ficheInfraction}
          setValues={(values: FicheInfractionFormValues): void => {
            setData(data => {
              return {
                ...data,
                ficheInfraction: values,
              };
            });
          }}
          goNextForm={goNextTab}
        />
      ),
      icon: <FileTextOutlined />,
    },
    {
      title: 'Véhicule',
      content: (
        <FicheVehiculeForm
          values={data.ficheVehicule}
          setValues={(values: FicheVehiculeFormValues): void => {
            setData(data => {
              return {
                ...data,
                ficheVehicule: values,
              };
            });
          }}
          goNextForm={goNextTab}
          goPreviousForm={goPreviousTab}
        />
      ),
      icon: (
        <Icon
          component={(): React.ReactElement => (
            <CarIcon width="32px" height="32px" />
          )}
        />
      ),
    },
    {
      title: 'Entrée en fourrière',
      content: (
        <FicheFourriereForm
          values={data.ficheFourriere}
          formValues={data}
          dateConstatation={data.ficheInfraction.dateConstatation}
          dateRedaction={data.ficheInfraction.dateRedaction}
          setValues={(values: FicheFourriereFormValues): void => {
            setData(data => {
              return {
                ...data,
                ficheFourriere: values,
              };
            });
          }}
          goPreviousForm={goPreviousTab}
          onCreationSucceed={onCreationSucceed}
          userConfirm={userConfirm}
        />
      ),
      icon: <HomeOutlined />,
    },
  ];

  return (
    <div className={'FicheDescriptive'}>
      <AntdSteps type="default" current={indexTab} onChange={onChangeTab}>
        {steps.map((item, index) => (
          <Step
            key={item.title}
            title={item.title}
            icon={item.icon}
            disabled={index >= indexTab}
          />
        ))}
      </AntdSteps>
      <div className="content">{steps[indexTab].content}</div>
    </div>
  );
}
