import React from 'react';

import { UniteFOAdminResponseDto } from 'lib_api/lib/api/gen';

import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { UniteFoSort } from 'enums/referentiels/UniteFo';

import { useReferentielDashboardSearchWithSort } from '../search/search';

import CellDetailsAndActions from './CellDetailsAndActions';
import { buildHeader, useSearchUniteFO } from './utils';

import './DashboardUniteFo.less';

function buildDetailsContent(
  data: UniteFOAdminResponseDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions unite={data} refreshDashboard={refreshDashboard} />
  );
}

function DashboardUniteFo(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchUniteFO,
    {
      valid: true,
    },
    {
      ABREVIATION: true,
    },
  );

  function buildColumns(): ColumnProps<UniteFOAdminResponseDto, UniteFoSort>[] {
    return [
      {
        title: 'Type',
        buildCellContent: data => data.type,
        sortedField: UniteFoSort.TYPE,
      },
      {
        title: 'Code',
        buildCellContent: data => data.code,
        sortedField: UniteFoSort.CODE,
      },
      {
        title: 'Abréviation',
        buildCellContent: data => data.abreviation,
        sortedField: UniteFoSort.ABREVIATION,
      },
      {
        title: 'Code postal',
        buildCellContent: data => data.codePostal,
        sortedField: UniteFoSort.CODE_POSTAL,
      },
      {
        title: 'Ville',
        buildCellContent: data => data.ville,
        sortedField: UniteFoSort.VILLE,
      },
      {
        title: 'Code INSEE',
        buildCellContent: data => data.codeInsee,
        sortedField: UniteFoSort.CODE_INSEE,
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<UniteFOAdminResponseDto, UniteFoSort>[] =
    buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        setSort={setSort}
        sort={sort}
      />
    </div>
  );
}

export default DashboardUniteFo;
