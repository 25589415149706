import { isArray } from 'lodash';

export function formatValue(
  value: string | number | string[] | null,
  emptyMessage: string,
): string {
  if (value === null || value === undefined) {
    return emptyMessage;
  } else if (isArray(value)) {
    return value.join('\n');
  }
  return value.toString();
}
