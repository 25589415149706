import React from 'react';

import { BooleanFieldDisplayProps } from './types';
import { FieldDisplay } from './FieldDisplay';

export const BooleanFieldDisplay = ({
  label,
  value,
  labelClass = 'label',
}: BooleanFieldDisplayProps): React.ReactElement => {
  let formatedValue: string | null;
  if (value === null || value === undefined) {
    formatedValue = 'Non';
  } else {
    formatedValue = value ? 'Oui' : 'Non';
  }
  return (
    <FieldDisplay label={label} value={formatedValue} labelClass={labelClass} />
  );
};
