import React from 'react';

import { InformationMessageViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';
import { Input } from 'components/WrappedComponents';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FieldProps } from 'components/WrappedComponents/Form/FormWrapper/type';
import { useApi } from 'hooks/ApiStoreContext';
import { InformationMessageFormValues } from 'types/InformationMessage/InformationMessage';
import {
  generateRequestDtoFromFormData,
  validateInformationMessageRequest,
} from '../utils';

function InputTitle({
  values,
}: FieldProps<InformationMessageFormValues>): React.ReactElement {
  const controller = useApi().ReferentielControllerApi;
  const key: keyof InformationMessageFormValues = 'title';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Titre',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                const requestObject = generateRequestDtoFromFormData({
                  ...values,
                  title: value || undefined,
                });

                return await validateInformationMessageRequest(
                  controller,
                  requestObject,
                  InformationMessageViolationDtoFormFieldEnum.TITRE,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}

export default InputTitle;
