import React from 'react';

import { Content } from 'components/FicheDescriptiveDisplay/DetailContent/Content';
import { FicheDescriptiveHeader } from './FicheDescriptiveHeader/FicheDescriptiveHeader';

import './FicheDescriptiveDisplay.less';
import { AlertDoublePlaque } from './AlertDoublePlaque/AlertDoublePlaque';

interface FicheDescriptiveDisplayProps {
  children?: React.ReactNode;
}

export const FicheDescriptiveDisplay = ({
  children,
}: FicheDescriptiveDisplayProps): React.ReactElement => {
  return (
    <div>
      <AlertDoublePlaque />
      <div className={'pageContainer'}>
        <FicheDescriptiveHeader>{children}</FicheDescriptiveHeader>

        <Content />
      </div>
    </div>
  );
};
