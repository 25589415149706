import { useState, useEffect } from 'react';

import { UniteFOResponseDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

export const useRefUniteFoWithChildren = (
  idCorrelationParent: string,
): Array<UniteFOResponseDto> | undefined => {
  const behaviourOnError = useHandleBackErrors();

  const [refUniteFo, setReferentielUniteFo] = useState<
    Array<UniteFOResponseDto> | undefined
  >(undefined);
  const referentielControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    if (refUniteFo === undefined) {
      referentielControllerApi
        .getValidUniteFOWithChildrenUsingGET(idCorrelationParent)
        .then(fetchedReferentiel => setReferentielUniteFo(fetchedReferentiel))
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [referentielControllerApi, behaviourOnError, refUniteFo]);

  return refUniteFo;
};
