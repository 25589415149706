import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Button as AntdButton } from 'antd';

interface DismissHourProp {
  index: number;
  removeHour: (index: number) => void;
}

function DismissHourWidget({
  index,
  removeHour,
}: DismissHourProp): React.ReactElement {
  return (
    <AntdButton
      icon={<DeleteOutlined style={{ fontSize: '20px' }} />}
      className={'delete-hour'}
      type={'primary'}
      ghost={true}
      title={'Supprimer'}
      onClick={() => {
        removeHour(index);
      }}
    />
  );
}

export default DismissHourWidget;
