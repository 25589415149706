import React from 'react';

import {
  FaqAdminDtoProfilesEnum,
  FaqViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useApi } from 'hooks/ApiStoreContext';
import { FaqFieldProps, FaqFormValues } from '../../types';

function SelectProfile({
  values,
  validateField,
}: FaqFieldProps): React.ReactElement {
  const key: keyof FaqFormValues = 'profiles';

  // list of options of select
  const selectItems = [
    {
      value: FaqAdminDtoProfilesEnum.GARDIEN_FOURRIERE,
      displayValue: 'Gardien de fourrière',
    },
    {
      value: FaqAdminDtoProfilesEnum.AUTORITE_FOURRIERE,
      displayValue: 'Autorité de fourrière',
    },
    {
      value: FaqAdminDtoProfilesEnum.FORCE_DE_L_ORDRE,
      displayValue: "Force de l'ordre",
    },
  ];

  return (
    <Select
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: 'Rôles concernés',
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateField(
                  key,
                  FaqViolationDtoFormFieldEnum.PROFILES,
                  value,
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        placeholder: FormPlaceholders.Select,
        mode: 'multiple',
      }}
    />
  );
}

export default SelectProfile;
