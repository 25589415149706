import { BrancheDto, ReferentielControllerApi } from 'lib_api/lib/api/gen';

export async function fetchBranchesList(
  refController: ReferentielControllerApi,
): Promise<BrancheDto[]> {
  return refController.getAllValidNaturesUsingGET().then(response => {
    return response.referenceDtoList.sort((a, b) =>
      a.nature.localeCompare(b.nature),
    );
  });
}
