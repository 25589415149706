import { useState, useEffect, useCallback } from 'react';

import { SearchCommuneEnlevementResultDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefCommuneEnlevementType = SearchCommuneEnlevementResultDto | undefined;
type RefCommuneEnlevementFetcher = (str: string) => void;

export const useRefCommuneEnlevement = (
  callApi = true,
): [
  RefCommuneEnlevementType,
  string | undefined,
  boolean,
  RefCommuneEnlevementFetcher,
] => {
  const behaviourOnError = useHandleBackErrors();

  const [searchStr, setSearchStr] = useState<string | undefined>(undefined);
  const [fetching, setFetching] = useState<boolean>(false);
  const [refCommuneEnlevement, setRefCommuneEnlevement] = useState<
    RefCommuneEnlevementType | undefined
  >(undefined);
  const communeEnlevementControllerApi = useApi().ReferentielControllerApi;

  useEffect(() => {
    let cancel = false;
    if (!searchStr) {
      setFetching(false);
      return;
    }

    communeEnlevementControllerApi
      .searchCommuneEnlevementUsingGET({ searchStr })
      .then(fetchedRef => {
        if (cancel) {
          return;
        }

        setFetching(false);
        setRefCommuneEnlevement(fetchedRef);
      })
      .catch((errorResponse: Response) => {
        if (cancel) {
          return;
        }

        setFetching(false);
        behaviourOnError(errorResponse);
      });

    return () => {
      cancel = true;
    };
  }, [communeEnlevementControllerApi, behaviourOnError, callApi, searchStr]);

  const fetch = useCallback(
    (str: string) => {
      setFetching(true);
      setSearchStr(encodeURIComponent(str));
      setRefCommuneEnlevement(undefined);
    },
    [setSearchStr, setRefCommuneEnlevement],
  );

  return [refCommuneEnlevement, searchStr, fetching, fetch];
};
