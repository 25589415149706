import React, { useMemo } from 'react';

import { ExportDonneesDossierAdminFonctionnelDto } from 'lib_api/lib/api/gen';

import { UpdateEntiteRattachementFormValues } from './types';

function useInitialValues(
  dossierAdmin: ExportDonneesDossierAdminFonctionnelDto | null,
): UpdateEntiteRattachementFormValues {
  const idCorrelationFourriere =
    dossierAdmin?.dossier.body?.ficheFourriere?.idCorrelationFourriere;
  const idCorrelationAutoriteFourriere =
    dossierAdmin?.dossier.body?.ficheInfraction?.idCorrelationAutoriteFourriere;
  const idCorrelationUniteFo =
    dossierAdmin?.dossier.body?.ficheInfraction?.idCorrelationUniteFO;

  return useMemo(() => {
    return {
      newAutoriteFourriereIdCorrelation: idCorrelationAutoriteFourriere ?? null,
      newFourriereIdCorrelation: idCorrelationFourriere ?? null,
      newUniteFoIdCorrelation: idCorrelationUniteFo ?? null,
      comment: null,
    };
  }, [
    idCorrelationFourriere,
    idCorrelationAutoriteFourriere,
    idCorrelationUniteFo,
  ]);
}

export default useInitialValues;
