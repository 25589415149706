import { useState, useEffect } from 'react';

import {
  ReferenceDtoFaqDomainDto,
  FaqAdminDtoProfilesEnum,
} from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { useHandleBackErrors } from '../utils/handleBackErrors';

type RefFaqType = ReferenceDtoFaqDomainDto | undefined;

export const useRefFAQDomainAdmin = (
  profile: FaqAdminDtoProfilesEnum,
): RefFaqType => {
  const behaviourOnError = useHandleBackErrors();
  const [refFaq, setRefFaq] = useState<RefFaqType>(undefined);
  const adminFonctionnelControllerApi = useApi().AdminFonctionnelControllerApi;

  useEffect(() => {
    if (refFaq === undefined) {
      adminFonctionnelControllerApi
        .getOnlyValidFaqByDomainsForProfileUsingGET(profile)
        .then(fetchedRef => {
          setRefFaq(fetchedRef);
        })
        .catch((errorResponse: Response) => {
          behaviourOnError(errorResponse);
        });
    }
  }, [adminFonctionnelControllerApi, behaviourOnError, refFaq, profile]);
  return refFaq;
};
