import React from 'react';

import { Input } from 'components/WrappedComponents';
import { getValueFromEventContainingOnlyNumeric } from 'components/BaseForm/utils';

import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { FormFilterFieldCentreVHU } from '../../types';

function InputSiren({ values }: FormFilterFieldCentreVHU): React.ReactElement {
  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: 'siren',
        itemProps: {
          label: 'SIREN',
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
          getValueFromEvent: getValueFromEventContainingOnlyNumeric,
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Number }}
    />
  );
}

export default InputSiren;
