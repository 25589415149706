import { SearchDossierIdResultDto } from 'lib_api/lib/api/gen';

export function buildMessageUUIDList(uuids: string[], total: number): string {
  const nbDisplayed = 3;
  return (
    '[ ' +
    uuids.slice(0, nbDisplayed).join() +
    ' ]' +
    (total > nbDisplayed
      ? ` et ${total - nbDisplayed} autre${total - nbDisplayed > 1 ? 's' : ''}`
      : '')
  );
}

export function buildUsesInDossierMessage(
  searchResult: SearchDossierIdResultDto | null,
): string {
  return buildMessageUUIDList(
    searchResult?.listDossierId ?? [],
    searchResult?.total ?? 0,
  );
}
