import {
  MarqueDto,
  ReferentielControllerApi,
  ReferentielSearchResultDtoMarqueDto,
  SearchMarqueUsingSpecsUsingGETQueryParams,
} from 'lib_api/lib/api/gen';

export function fetchMarque(
  refController: ReferentielControllerApi,
): Promise<MarqueDto[]> {
  return refController.getAllValidMarqueUsingGET().then(response => {
    return response.referenceDtoList.sort((a, b) =>
      a.libelle.localeCompare(b.libelle),
    );
  });
}

export function searchMarque(
  controller: ReferentielControllerApi,
  filter: SearchMarqueUsingSpecsUsingGETQueryParams,
): Promise<ReferentielSearchResultDtoMarqueDto> {
  return controller.searchMarqueUsingSpecsUsingGET(filter);
}
