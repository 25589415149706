import React, { ReactElement } from 'react';
import { Form as AntdForm } from 'antd';
import { StoreValue as AntdStoreValue } from 'antd/lib/form/interface';
import { FormListFieldData } from 'antd/lib/form/FormList';

interface Operation {
  add: (defaultValue?: AntdStoreValue) => void;
  remove: (index: number) => void;
}

interface FormListProps<T> {
  values: T;
  name: string;
  children: (
    fields: FormListFieldData[],
    { add, remove }: Operation,
  ) => React.ReactElement;
}

function FormList<T>({ name, children }: FormListProps<T>): React.ReactElement {
  return (
    <AntdForm.List name={name}>
      {(fields, operation): ReactElement => children(fields, operation)}
    </AntdForm.List>
  );
}

export default FormList;
