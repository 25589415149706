import React from 'react';
import { InputProps as AntdInputProps } from 'antd';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';

import { setFieldValue } from './utils';
import { useBaseFormContext } from './BaseFormContext';
import BaseInput from './BaseInput';

interface BaseInputCodeInseeProps<FormValues> extends AntdInputProps {
  name: keyof FormValues & AntdNamePath & string;
  communeField?: keyof FormValues & AntdNamePath & string;
}

interface GeoCommune {
  nom: string;
  code: string;
  codeDepartement: string;
}

export default function BaseInputCodeInsee<FormValues>({
  name,
  communeField,
  ...props
}: BaseInputCodeInseeProps<FormValues>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();

  return (
    <BaseInput
      {...props}
      onChange={event => {
        const value = event.target.value;
        const geoApi = process.env.REACT_APP_API_GEO_GOUV_URL;
        const listToValidate: AntdNamePath[] = [name];

        if (communeField && value && value.trim().length > 0) {
          fetch(`${geoApi}/communes/${value}`)
            .then(result => result.json())
            .then((result: GeoCommune) => {
              setFieldValue(form, communeField, result.nom);
              listToValidate.push(communeField);
            })
            // We catch any error that occurs when fetching or parsing the result into a GeoCommune
            .catch(() => {
              setFieldValue(form, communeField, undefined);
            });
        }

        form.validateFields(listToValidate).catch(() => {
          // Validation error occurred, do nothing
        });

        props.onChange && props.onChange(event);
      }}
    />
  );
}
