import React, { useState } from 'react';

import { DetailedInformationMessageDto } from 'lib_api/lib/api/gen';
import { Table } from 'components/WrappedComponents';

import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import { useRefDetailedInformationMessage } from 'hooks/referentiels/useRefDetailedInformationMessage';

import { FilterInformationMessage } from '../DashboardHeader/types';
import { buildColumns, buildHeader } from './utils';
import { MessageSortOptions } from './types';

import './DashboardTable.less';
import {
  ColumnProps,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';

const DashBoardTable: React.FC = (): React.ReactElement => {
  const [filter, setFilter] = useState<Partial<FilterInformationMessage>>({});
  const [sort, setSort] = useState<TableSort<MessageSortOptions>>({
    START_DATE: true,
  });
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const fetchData = useRefDetailedInformationMessage();
  const columns: ColumnProps<
    DetailedInformationMessageDto,
    MessageSortOptions
  >[] = buildColumns(() => setFilter({ ...filter }));

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        filter={filter}
        setFilter={setFilter}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
};

export default DashBoardTable;
