import { DetailedInformationMessageDto } from 'lib_api/lib/api/gen';

import { useApi } from '../ApiStoreContext';
import { FilterInformationMessage } from 'pages/admin-fonctionnel/Referentiels/InformationMessage/Dashboard/DashboardHeader/types';
import { MessageSortOptions } from 'pages/admin-fonctionnel/Referentiels/InformationMessage/Dashboard/DashboardTable/types';
import { FetchDataFunction } from 'components/WrappedComponents/Table/types';

export const useRefDetailedInformationMessage = (): FetchDataFunction<
  DetailedInformationMessageDto,
  FilterInformationMessage,
  MessageSortOptions
> => {
  const referentielControllerApi = useApi().ReferentielControllerApi;

  return async (pagination, sort, filter) => {
    const result =
      await referentielControllerApi.getAllInformationMessageUsingGET({
        search: filter.searchField,
        sortByEndDate: sort?.END_DATE,
        sortByStartDate: sort?.START_DATE,
        page: pagination.page - 1,
        pageSize: pagination.pageSize,
      });

    return {
      total: result.total,
      data: result.messages.map(dossier => ({
        ...dossier,
        key: dossier.id,
      })),
    };
  };
};
