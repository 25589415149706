import React from 'react';

import { useBaseFormContext } from '../BaseFormContext';
import CommonBaseSelect, { CommonBaseSelectProps } from './CommonBaseSelect';

interface OptionList<OptionType> {
  label?: string;
  options: OptionType[];
}

export interface BaseSelectMultiListProps<FormValues, OptionType>
  extends Omit<CommonBaseSelectProps<FormValues, OptionType>, 'options'> {
  options:
    | OptionList<OptionType>[]
    | ((formValues: FormValues) => OptionList<OptionType>[]);
  getOptionValue: (option: OptionType) => string | number;
  getOptionLabel: (option: OptionType) => string | React.ReactNode;
  onBottomReached?: () => void;
}

export default function BaseSelectMultiList<FormValues, OptionType>({
  options,
  getOptionLabel,
  getOptionValue,
  onBottomReached,
  ...props
}: BaseSelectMultiListProps<FormValues, OptionType>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();

  function mapToOption(option: OptionType) {
    return {
      value: getOptionValue(option),
      label: getOptionLabel(option),
      option: option,
    };
  }

  function mapToOptionGroup(optionList: OptionList<OptionType>) {
    return {
      label: optionList.label,
      options: optionList.options.map(mapToOption),
    };
  }

  return (
    <CommonBaseSelect
      options={(typeof options === 'function'
        ? options(form.getFieldsValue())
        : options
      ).map(mapToOptionGroup)}
      onPopupScroll={(e: React.UIEvent<HTMLDivElement, UIEvent>) => {
        const offsetHeight: number = e.currentTarget.offsetHeight;
        const scrollTop: number = e.currentTarget.scrollTop;
        const scrollHeight: number = e.currentTarget.scrollHeight;
        if (offsetHeight + scrollTop >= scrollHeight - 1) {
          onBottomReached && onBottomReached();
        }
      }}
      {...props}
    />
  );
}
