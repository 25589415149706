import React from 'react';
import { Checkbox as AntdCheckbox, Typography as AntdTypo } from 'antd';
import BaseForm from 'components/BaseForm/BaseForm';
import AsyncSelect from 'components/BaseForm/Select/AsyncSelect';
import { fetchBranchesList } from 'search/searchBranche';
import { useApi } from 'hooks/ApiStoreContext';
import { FilterDashboardMotifMef } from '../../types';
import FormFooter from './FormFooter/FormFooter';

interface FilterFormProps {
  filter: FilterDashboardMotifMef;
  setFilter: (value: FilterDashboardMotifMef) => void;
}

function FilterFormMotifMef({
  filter,
  setFilter,
}: FilterFormProps): React.ReactElement {
  const refController = useApi().ReferentielControllerApi;

  return (
    <>
      <AntdTypo.Title level={3}>Filtres</AntdTypo.Title>
      <BaseForm
        initialValues={filter}
        inputs={[
          {
            name: 'idCorrelation',
            label: 'Id de corrélation',
          },
          {
            name: 'natureLibelleCourt',
            label: 'Nature de Mef',
            render: () => (
              <AsyncSelect
                fetchOptions={() => fetchBranchesList(refController)}
                getOptionValue={option => option.nature}
                getOptionLabel={option => option.nature}
                allowClear
              />
            ),
          },
          {
            name: 'libelle',
            label: 'Motif de Mef',
          },
          {
            name: 'isDicem',
            label: 'Est Dicem',
            valuePropName: 'checked',
            render: () => <AntdCheckbox />,
          },
          {
            name: 'isRodeo',
            label: 'Est Rodéo',
            valuePropName: 'checked',
            render: () => <AntdCheckbox />,
          },
        ]}
        onSubmit={() => Promise.resolve()}
        renderButtonAction={() => <FormFooter setFilter={setFilter} />}
      />
    </>
  );
}

export default FilterFormMotifMef;
