import React from 'react';
import { AuthApi } from 'lib_api/lib/api/buildAuthApi';

const apiContext = React.createContext<AuthApi | undefined>(undefined);

export const ApiProvider = apiContext.Provider;

export const useApi = (): AuthApi => {
  const api = React.useContext(apiContext);
  if (api === undefined) {
    throw new Error('Missing wrapping ApiProvider');
  }
  return api;
};
