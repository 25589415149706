import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { DossierSummaryResponseDto } from 'lib_api/lib/api/gen/api';
import { Table } from 'components/WrappedComponents';
import {
  ColumnProps,
  FetchDataFunction,
} from 'components/WrappedComponents/Table/types';

import { useUserStore } from 'hooks/UserStoreContext';
import { useSearchDossierSummaries } from 'hooks/dossiers/useSearchDossierSummaries';
import { usePaginationContext } from 'hooks/PaginationStoreContext';
import { useGFFilterContext } from 'hooks/GFFilterStoreContext';
import { useSortContext } from 'hooks/SortStoreContext';
import { GardienSortOptions } from './types';
import { buildColumns, buildHeader } from './utils';
import { FilterDashboardGardien } from '../DashboardHeader/Filters/types';

import './DashboardTable.less';

function DashboardTable(): React.ReactElement {
  const { pathname } = useLocation();
  const user = useUserStore();
  const [sort, setSort] = useSortContext();
  const [filter, setFilter, gfActionsRequises] = useGFFilterContext();
  const searchDossierSummaries = useSearchDossierSummaries();
  const [pagination, setPagination] = usePaginationContext();

  const fetchData: FetchDataFunction<
    DossierSummaryResponseDto,
    FilterDashboardGardien,
    GardienSortOptions
  > = useMemo(
    () => (pagination, sorters, filter) => {
      return searchDossierSummaries(pagination, sorters, {
        includeDossiersSortis: filter.includeDossiersSortis,
        idMotifSortieList: filter.motifDeSortie,
        classementList: filter.classement,
        dateRangeMiseAJour: filter.dateRangeMiseAJour,
        dateRangeMiseEnFourriere: filter.dateRangeMiseEnFourriere,
        idNatureBrancheList: filter.natureMiseEnFourriere,
        numeroImmatriculation: filter.searchPlaque,
        idFourriereList: user.idCorrelationFourriere
          ? [user.idCorrelationFourriere]
          : undefined,
        statutDossierEnumList: filter.statut,
        actionsRequises: filter.actionsRequises,
        includeDossiersAnonymises: filter.includeDossiersAnonymises,
      });
    },
    [searchDossierSummaries, user.idCorrelationFourriere],
  );

  const columns: ColumnProps<DossierSummaryResponseDto, GardienSortOptions>[] =
    useMemo(
      () => buildColumns(pathname, gfActionsRequises),
      [pathname, gfActionsRequises],
    );

  return (
    <div className="table-container">
      <Table
        columns={columns}
        header={buildHeader}
        fetchData={fetchData}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        sort={sort}
        setSort={setSort}
      />
    </div>
  );
}

export default DashboardTable;
