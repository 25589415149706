import React from 'react';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

import { NavbarDropdownItem, NavbarItem } from './types';

function withLink(content: React.ReactNode, link?: string): React.ReactNode {
  if (link === undefined) {
    return content;
  }

  // Url paths are handled with react-router
  if (link.startsWith('/')) {
    return <Link to={link}>{content}</Link>;
  }

  // Otherwise, we use a native <a>
  return (
    <a href={link} target={'_blank'} rel="noopener noreferrer">
      {content}
    </a>
  );
}

function buildDropdownMenu(
  dropdown: NavbarDropdownItem[],
  title?: string,
): React.ReactElement {
  return (
    <SubMenu title={title} key={title}>
      {dropdown.map(item => (
        <Menu.Item key={item.label} onClick={item.onClick}>
          {withLink(item.label, item.link)}
        </Menu.Item>
      ))}
    </SubMenu>
  );
}

export function buildItem(item: NavbarItem, key: number): React.ReactNode {
  if (item.dropdown) {
    return buildDropdownMenu(item.dropdown, item.label);
  }
  return (
    <Menu.Item key={item.label}>{withLink(item.label, item.link)}</Menu.Item>
  );
}
