import { useCallback } from 'react';
import {
  ConditionsReactivateFaqDto,
  FaqAdminDto,
  FaqAdminDtoProfilesEnum,
  FaqDomainAdminDto,
  AdminFonctionnelControllerApi,
  FaqRequestDto,
  FaqRequestDtoProfilesEnum,
  ReferentielSearchResultDtoFaqAdminDto,
  ReferentielSearchResultDtoFaqDomainAdminDto,
  ReferentielUsesDto,
} from 'lib_api/lib/api/gen/api';

import { faqViewPath } from 'pages/admin-fonctionnel/adminNavbarContent';
import { userMapping } from 'utils/enumData';
import { FaqFormValues, FilterDashboardFaq } from './types';
import {
  FetchDataResult,
  TablePagination,
  TableSort,
} from 'components/WrappedComponents/Table/types';
import { useApi } from 'hooks/ApiStoreContext';
import {
  FaqDomainSortOptions,
  FilterDashboardFaqDomain,
} from '../FaqDomaine/types';
import { SearchResult } from 'types/searchResult';
import useAsyncSearch from 'hooks/search/async/useAsyncSearch';

export function mapUserProfiles(users: FaqAdminDtoProfilesEnum[]): string {
  return users.map(profile => userMapping[profile]).join('\n');
}

export const views = [
  {
    label: 'FAQ AF',
    link: `${faqViewPath}/AUTORITE_FOURRIERE`,
  },
  {
    label: 'FAQ FO',
    link: `${faqViewPath}/FORCE_DE_L_ORDRE`,
  },
  {
    label: 'FAQ GF',
    link: `${faqViewPath}/GARDIEN_FOURRIERE`,
  },
];

export function convertFaqFormValueToFaqRequestDto(
  formValues: FaqFormValues,
): FaqRequestDto {
  return {
    title: formValues.title || null,
    content: formValues.content || null,
    profiles: formValues.profiles || null,
    domainId: formValues.domainId || null,
  };
}

export const createFaqInitialValues = (id: string | null): FaqFormValues => {
  return {
    title: undefined,
    content: undefined,
    profiles: undefined,
    domainId: id || undefined,
  };
};

export const buildUpdateFaqInitialValues = (
  faq: FaqAdminDto,
): FaqFormValues => {
  return {
    title: faq.title,
    content: faq.content,
    profiles: mapFaqAdminProfilsToFaqRequestProfils(faq.profiles),
    domainId: faq?.domainId || undefined,
  };
};

const mapFaqAdminProfilsToFaqRequestProfils = (
  profiles: FaqAdminDtoProfilesEnum[],
): FaqRequestDtoProfilesEnum[] => {
  return profiles.map(
    p =>
      FaqRequestDtoProfilesEnum[
        FaqAdminDtoProfilesEnum[p] as keyof typeof FaqRequestDtoProfilesEnum
      ],
  );
};

export function useSearchFaq(
  filters: FilterDashboardFaq,
): [
  () => Promise<ReferentielSearchResultDtoFaqAdminDto>,
  (
    result: ReferentielSearchResultDtoFaqAdminDto,
  ) => FetchDataResult<FaqAdminDto>,
] {
  const controller = useApi().AdminFonctionnelControllerApi;

  return [
    () => {
      return controller.searchFaqUsingSpecsUsingGET({ filters });
    },
    result => {
      return {
        data: result.results.referenceDtoList,
        total: result.total,
      };
    },
  ];
}

export function useChangeValidityFaq(
  controller: AdminFonctionnelControllerApi,
): [
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<Response>,
  (idCorrelation: string) => Promise<ConditionsReactivateFaqDto>,
  ((idCorrelation: string) => Promise<ReferentielUsesDto>) | undefined,
] {
  const submitDeprecateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.deprecateFaqUsingPUT(idCorrelation);
    },
    [controller],
  );

  const submitReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<Response> => {
      return controller.reactivateFaqUsingPUT(idCorrelation);
    },
    [controller],
  );

  const conditionsReactivateReferentiel = useCallback(
    (idCorrelation: string): Promise<ConditionsReactivateFaqDto> => {
      return controller.conditionReactivationFaqUsingGET(idCorrelation);
    },
    [controller],
  );

  return [
    submitReactivateReferentiel,
    submitDeprecateReferentiel,
    conditionsReactivateReferentiel,
    undefined,
  ];
}

export function useAsyncSearchDomain(id?: string): SearchResult {
  const mapResult = (result: ReferentielSearchResultDtoFaqDomainAdminDto) => {
    return result.results.referenceDtoList
      .sort((a, b) => a.name.localeCompare(b.name))
      .map(domain => {
        return {
          value: domain.id,
          displayValue: domain.name,
        };
      });
  };
  const updateFilters = (query?: string) => {
    return { name: query };
  };
  const defaultFilter = { id };

  return useAsyncSearch(
    searchFaqDomain,
    mapResult,
    updateFilters,
    defaultFilter,
  );
}

function searchFaqDomain(): (
  filters: FilterDashboardFaqDomain,
  sorts: TableSort<FaqDomainSortOptions>,
  pagination: TablePagination,
) => Promise<ReferentielSearchResultDtoFaqDomainAdminDto> {
  const controller = useApi().AdminFonctionnelControllerApi;

  return () => {
    return controller.searchFaqDomainUsingSpecsUsingGET({ filters: {} });
  };
}
