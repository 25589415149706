import { useState, useCallback } from 'react';
import { DossierViolationsDto, UserViolationsDto } from 'lib_api/lib/api/gen';

import { defaultErrorMessage } from 'utils/ErrorMessages';
import { backAlertMessage } from './backAlertMessage';
import { ValidateFunction } from 'components/BaseForm/types';

/* Hook de validation à utiliser pour la validation de tous les formulaires BaseForm */
interface ViolationDto {
  message: string;
}

export function useValidateField<
  Field,
  FormValues,
  RequestDto,
  ResponseDto,
  ViolationType extends ViolationDto,
>(
  buildRequest: (formValues: FormValues) => RequestDto,
  sendRequest: (field: Field, request: RequestDto) => Promise<ResponseDto>,
  violationsExtractor: (response: ResponseDto) => ViolationType[],
): ValidateFunction<FormValues, Field> {
  const validate = useCallback(
    async (field: Field, formValues: FormValues) => {
      try {
        const response = await sendRequest(field, buildRequest(formValues));
        const violations = violationsExtractor(response);
        if (violations.length === 0) {
          return Promise.resolve();
        }

        return Promise.reject(violations[0].message);
      } catch (e) {
        if (e instanceof Response) {
          return Promise.reject(
            (await backAlertMessage(e, false, false)).description,
          );
        } else {
          return Promise.reject(e);
        }
      }
    },
    [buildRequest, sendRequest, violationsExtractor, backAlertMessage],
  );

  return { validate };
}

/* Hook générique utilisé pour la validation des Form simples dépréciés */

export type ValidationCallback<T> = (
  controller: T,
) => Promise<DossierViolationsDto>;

export function useValidationRequest<
  Controller,
  Key extends keyof DossierViolationsDto,
  PromiseReturnType,
>(
  controller: Controller,
  validationKey: Key,
  promiseOnSuccess: () => Promise<PromiseReturnType>,
  thenPromiseOnsuccess?: (data: PromiseReturnType) => void,
  catchPromiseOnSuccess?: (errorResponse: Response) => void,
): [
  (requestGenerator: ValidationCallback<Controller>) => Promise<void>,
  DossierViolationsDto[Key] | null,
] {
  const [violations, setViolations] = useState<
    DossierViolationsDto[Key] | null
  >(null);

  const onSubmit = useCallback(
    async (
      requestGenerator: (
        controller: Controller,
      ) => Promise<DossierViolationsDto>,
    ) => {
      try {
        const violationResponse = await requestGenerator(controller);
        const violations: DossierViolationsDto[Key] | null =
          violationResponse[validationKey];

        setViolations(violations);
        if (violations !== null && violations.length === 0) {
          await promiseOnSuccess()
            .then(thenPromiseOnsuccess)
            .catch(catchPromiseOnSuccess);
        }
      } catch (e) {
        if (e instanceof Response) {
          return Promise.reject((await backAlertMessage(e)).description);
        }
        return Promise.reject(defaultErrorMessage);
      }
      return Promise.resolve();
    },
    [
      controller,
      validationKey,
      promiseOnSuccess,
      thenPromiseOnsuccess,
      catchPromiseOnSuccess,
    ],
  );

  return [onSubmit, violations];
}

export type UserValidationCallback<T> = (
  controller: T,
) => Promise<UserViolationsDto>;

export function useValidationUserRequest<
  Controller,
  Key extends keyof UserViolationsDto,
  PromiseReturnType,
>(
  controller: Controller,
  validationKey: Key,
  promiseOnSuccess: () => Promise<PromiseReturnType>,
  thenPromiseOnsuccess?: (data: PromiseReturnType) => void,
  catchPromiseOnSuccess?: (errorResponse: Response) => void,
): [
  (requestGenerator: UserValidationCallback<Controller>) => Promise<void>,
  UserViolationsDto[Key] | null,
] {
  const [violations, setViolations] = useState<UserViolationsDto[Key] | null>(
    null,
  );

  const onSubmit = useCallback(
    async (
      requestGenerator: (controller: Controller) => Promise<UserViolationsDto>,
    ) => {
      try {
        const violationResponse = await requestGenerator(controller);
        const violations: UserViolationsDto[Key] | null =
          violationResponse[validationKey];

        setViolations(violations);
        if (violations !== null && violations.length === 0) {
          await promiseOnSuccess()
            .then(thenPromiseOnsuccess)
            .catch(catchPromiseOnSuccess);
        }
      } catch (e) {
        if (e instanceof Response) {
          return Promise.reject((await backAlertMessage(e)).description);
        }
        return Promise.reject(defaultErrorMessage);
      }
      return Promise.resolve();
    },
    [
      controller,
      validationKey,
      promiseOnSuccess,
      thenPromiseOnsuccess,
      catchPromiseOnSuccess,
    ],
  );

  return [onSubmit, violations];
}
