import { createContext, useCallback, useContext } from 'react';
import { DossierDtoAdminFonctionnel } from 'lib_api/lib/api/gen';
import { FetchDetailedEventResult } from './useFetchDetailedEvents';

interface DossierProps {
  result: DossierDtoAdminFonctionnel;
  setDossier: (dossier: DossierDtoAdminFonctionnel) => void;
}

interface DossierAdminContextContent {
  dossier: DossierProps;
  events: FetchDetailedEventResult;
}

const DossierAdminContext = createContext<DossierAdminContextContent | null>(
  null,
);

export const useDossierAdminContext = (): [
  DossierProps,
  FetchDetailedEventResult,
] => {
  const context = useContext(DossierAdminContext);
  if (context === null) {
    throw new Error('useDossierAdminContext called outside Context.');
  }

  const setter = context.dossier.setDossier;
  const setDossierNonNull = useCallback(
    (dossier: DossierDtoAdminFonctionnel) => {
      setter(dossier);
    },
    [setter],
  );

  return [
    { result: context.dossier.result, setDossier: setDossierNonNull },
    context.events,
  ];
};

export const DossierAdminProvider = DossierAdminContext.Provider;
