import React from 'react';
import { Result } from 'antd';

import './UploadCard.less';

interface CardProps {
  children: React.ReactNode;
  status?: 'success' | 'error' | 'info';
}

const UploadCard = ({ status, children }: CardProps): React.ReactElement => {
  return (
    <div className="UploadCard">
      {children}
      {status && <Result className="ResultIcon" status={status} />}
    </div>
  );
};

export default UploadCard;
