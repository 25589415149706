import { useState } from 'react';
import moment from 'moment';
import {
  AutoriteFourriereControllerApi,
  ClassementManuelRequestDto,
  ClassementManuelViolationDto,
  ClassementManuelViolationDtoFormFieldEnum,
  DossierResponseDto,
  InformationsTechniquesResponseDto,
} from 'lib_api/lib/api/gen';

import { defaultErrorMessage } from 'utils/ErrorMessages';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import {
  useValidationRequest,
  ValidationCallback,
} from 'hooks/utils/handleValidationRequest';
import { useApi } from 'hooks/ApiStoreContext';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';
import { ClassementManuel } from './types';

export function generateClassementManuelRequestFromClassement(
  values: ClassementManuel,
): ClassementManuelRequestDto {
  return {
    carrosserieId: values?.carrosserieId || null,
    dangereux: values?.dangereux || null,
    energieId: values?.energieId || null,
    datePremiereImmatriculation:
      values?.datePremiereImmatriculation?.format(UNIX_TIMESTAMP_FORMAT) ||
      null,
    genreId: values?.genreId || null,
    puissanceCv: values?.puissance || null,
    reparable: values?.reparable || null,
  };
}

export function convertInformationsTechniquesToClassementManuel(
  informationsTechniques: InformationsTechniquesResponseDto | null | undefined,
): ClassementManuel {
  return {
    carrosserieId: informationsTechniques?.carrosserieId || undefined,
    dangereux: informationsTechniques?.dangereux || undefined,
    energieId: informationsTechniques?.energieId || undefined,
    datePremiereImmatriculation:
      informationsTechniques?.datePremiereImmatriculation
        ? moment(informationsTechniques?.datePremiereImmatriculation)
        : undefined,
    genreId: informationsTechniques?.genreId || undefined,
    puissance: informationsTechniques?.puissanceCv || undefined,
    reparable: informationsTechniques?.reparable || undefined,
    marque: informationsTechniques?.libelleMarque || undefined,
    motifMEF: informationsTechniques?.labelMotifMef || undefined,
  };
}

export async function validateClassementManuelField(
  dossierId: string,
  controller: AutoriteFourriereControllerApi,
  values: ClassementManuelRequestDto,
  field: ClassementManuelViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateClassementManuelFieldUsingPOST(
      field,
      dossierId,
      values,
    );
    if (response.classementManuelViolationDtoList) {
      const fieldViolations = response.classementManuelViolationDtoList.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

type ClassementManuelValidationCallback =
  ValidationCallback<AutoriteFourriereControllerApi>;

export function useValidateClassementManuel(
  onSuccess: () => Promise<DossierResponseDto>,
  thenSubmit: (dossier: DossierResponseDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: ClassementManuelValidationCallback) => Promise<void>,
  ClassementManuelViolationDto[] | null,
] {
  const afController = useApi().AutoriteFourriereControllerApi;
  return useValidationRequest(
    afController,
    'classementManuelViolationDtoList',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}

type SubmitFunction = {
  submit: (values: ClassementManuel) => void;
  inProgress: boolean;
};

export function useSubmitClassementManuel(
  dossierId: string,
  controller: AutoriteFourriereControllerApi,
  setDossier: (dossier: DossierResponseDto) => void,
  closeModal: () => void,
): SubmitFunction {
  const [inProgress, setInProgress] = useState<boolean>(false);
  return {
    submit: (values: ClassementManuel) => {
      setInProgress(true);
      const classementRequest =
        generateClassementManuelRequestFromClassement(values);

      return controller
        .updateClassementUsingPUT(dossierId, classementRequest)
        .then(setDossier)
        .then(closeModal)
        .catch((error: Response) => {
          void backAlertMessage(error);
        })
        .finally(() => {
          setInProgress(false);
        });
    },
    inProgress: inProgress,
  };
}
