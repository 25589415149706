import React from 'react';
import { Pagination as AntdPagination } from 'antd';
import PageSizeChanger from './PageSizeChanger';
import './Pagination.less';
import { PaginationProps } from './types';

/**
 * This custom Pagination component is defined to replace the default one used in Table
 * component. We have therefore a better control over this component behaviour (better
 * style control + we can specify its size instead of relying on table size which would
 * require to load all the table components at once).
 *
 * @param pagination - getter and setter for pagination state:
 *        - page: page currently active (/!\ Antd Pagination component start at index 1)
 *        - pageSize: number of items deplayed in a page (used to define the total number
 *        of pages)
 * @param total - total number of items (used to defined total number of pages)
 *        (ex: pageSize = 10 ; total = 121 => 13 pages)
 * @param pageSizeOptions - when defined, add a PageSizeChanger component with those
 *        options
 * @param disabled
 * @param antdPaginationProps - to use other props defined by Antd library (/!\ may
 *        interfere with props already defined by this component)
 */
function Pagination({
  pagination,
  total,
  pageSizeOptions,
  disabled,
  antdPaginationProps,
}: PaginationProps): React.ReactElement {
  const { paginated, setPagination } = pagination;

  return (
    <div className="pagination-container">
      <AntdPagination
        current={paginated.page}
        pageSize={paginated.pageSize}
        total={total}
        onChange={(newPage: number): void =>
          // Go to requested page, page size unchanged
          setPagination({
            page: newPage,
            pageSize: paginated.pageSize,
          })
        }
        showSizeChanger={false} // We use a custom size changer instead
        disabled={disabled}
        size="small"
        {...antdPaginationProps}
      />
      {pageSizeOptions !== undefined && (
        <div className="page-size-changer">
          <PageSizeChanger
            pageSize={paginated.pageSize}
            setPageSize={(newPageSize: number): void =>
              // Change page size, return to first page
              setPagination({
                page: 1,
                pageSize: newPageSize,
              })
            }
            options={pageSizeOptions}
            suffix="/ page"
            disabled={disabled}
          />
        </div>
      )}
    </div>
  );
}

export default Pagination;
