import React from 'react';
import { OptionProps } from 'types/searchResult';
import { MotifSortieDto } from 'lib_api/lib/api/gen';
import InternalSelectMotifSortie from './InternalSelectMotifSortie';
import { SelectMotifSortieProps } from './type';

function mapMotifSortieToSelectValueUsingIdCorrelation(
  motifSortie: MotifSortieDto,
): OptionProps {
  return {
    value: motifSortie.idCorrelation,
    displayValue: motifSortie.motif,
  };
}

function SelectMotifSortieUsingIdCorrelation<FormValues>({
  values,
  fieldKey,
  validator,
  mode,
}: SelectMotifSortieProps<FormValues>): React.ReactElement {
  return (
    <InternalSelectMotifSortie
      values={values}
      fieldKey={fieldKey}
      validator={validator}
      mode={mode}
      mapMotifSortieToSelectValue={
        mapMotifSortieToSelectValueUsingIdCorrelation
      }
    />
  );
}

export default SelectMotifSortieUsingIdCorrelation;
