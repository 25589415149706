import React from 'react';

import { Input } from 'components/WrappedComponents';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';

import { FilterDashboardComptesUtilisateurs } from '../../DashboardHeader/types';

interface InputSearchCompteProps<T> {
  values: T;
  fieldKey: keyof T;
  label: string;
  placeholder?: string;
}

export default function InputSearchCompte({
  values,
  fieldKey,
  label,
}: InputSearchCompteProps<FilterDashboardComptesUtilisateurs>): React.ReactElement {
  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: fieldKey,
        itemProps: {
          label: label,
          labelCol: { span: CSSUtils.LlargeWrapperCol },
          wrapperCol: { span: CSSUtils.XLargeWrapperCol },
        },
      }}
      inputProps={{ placeholder: FormPlaceholders.Input }}
    />
  );
}
