import React from 'react';
import ResponsiveContainer from 'components/ResponsiveContainer';
import CardLayout from 'components/CardLayout/CardLayout';
import FicheDescriptiveImport from './FicheDescriptiveImport';
import FicheDescriptiveExport from './FicheDescriptiveExport';

const FicheDescriptive: React.FC = () => {
  return (
    <div>
      <ResponsiveContainer>
        <h2>Import du modèle de fiche descriptive</h2>
        <p>
          Il vous est possible d&apos;importer une nouvelle version de la fiche
          descriptive depuis cette page. Pour cela, cliquez sur le bouton
          ci-dessous. Ce fichier doit être au format PDF.
        </p>
        <CardLayout>
          <FicheDescriptiveImport label={'Fiche descriptive'} />
        </CardLayout>
      </ResponsiveContainer>
      <ResponsiveContainer>
        <h2>Export du modèle de fiche descriptive</h2>
        <p>
          Il vous est possible de télécharger le dernier modèle de fiche
          descriptive importé depuis cette page. Pour cela, cliquez sur le
          bouton ci-dessous. Le fichier généré sera au format PDF.
        </p>
        <CardLayout>
          <FicheDescriptiveExport label={'Fiche descriptive'} />
        </CardLayout>
      </ResponsiveContainer>
    </div>
  );
};

export default FicheDescriptive;
