import React from 'react';
import { Spin as AntdSpin } from 'antd';

import { AutoComplete } from 'components/WrappedComponents';
import { ProfilAutoriteFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import { useApi } from 'hooks/ApiStoreContext';
import { useUserStore } from 'hooks/UserStoreContext';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useCommune } from 'hooks/dossiers/useCommune';

import { FieldProps, FicheProfilAutoriteFourriere } from '../types';
import { validateProfilAutoriteFourriereField } from '../utils';

function InputCommune({ values, isEditable }: FieldProps): React.ReactElement {
  const user = useUserStore();
  const { AutoriteFourriereControllerApi } = useApi();

  const key: keyof FicheProfilAutoriteFourriere = 'commune';
  const label = 'Commune';
  const [communes, isFetching, fetchCommunes] = useCommune();

  const options = communes.map(res => {
    return {
      value: res.nom,
      label: res.nom,
    };
  });

  return (
    <AutoComplete
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return await validateProfilAutoriteFourriereField(
                  AutoriteFourriereControllerApi,
                  ProfilAutoriteFourriereViolationDtoFormFieldEnum.COMMUNE,
                  user.idCorrelationAutoriteFourriere || '',
                  { ...values, [key]: value },
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      optionProps={options}
      autoCompleteProps={{
        placeholder: FormPlaceholders.Input,
        dropdownMatchSelectWidth: 350,
        suffixIcon: null,
        onChange: fetchCommunes,
        notFoundContent: isFetching ? <AntdSpin /> : null,
      }}
    />
  );
}

export default InputCommune;
