import React from 'react';
import { Select as AntdSelect, SelectProps as AntdSelectProps } from 'antd';
import { DefaultOptionType } from 'antd/lib/select';
import { NamePath as AntdNamePath } from 'antd/lib/form/interface';
import { RawValueType } from 'rc-select/lib/BaseSelect';
import { LabelInValueType } from 'rc-select/lib/Select';

import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { useBaseFormContext } from '../BaseFormContext';
import { filterOption, setFieldValue } from '../utils';

import './CommonBaseSelect.less';

export interface BaseOptionType<OptionType> extends DefaultOptionType {
  option?: OptionType;
}

export interface BaseSelectDependencies<FormValues, OptionType> {
  name: keyof FormValues & AntdNamePath & string;
  getValue: (option: OptionType) => unknown;
}

export interface CommonBaseSelectProps<FormValues, OptionType>
  extends AntdSelectProps {
  dependencies?: BaseSelectDependencies<FormValues, OptionType>[];
}

export default function CommonBaseSelect<FormValues, OptionType>({
  dependencies,
  ...props
}: CommonBaseSelectProps<FormValues, OptionType>): React.ReactElement {
  const form = useBaseFormContext<FormValues>();
  return (
    <AntdSelect
      placeholder={FormPlaceholders.Select}
      filterOption={(input: string, option?: BaseOptionType<OptionType>) => {
        if (option?.label) {
          return filterOption(input, option.label as string);
        }
        return false;
      }}
      onSelect={(
        value: RawValueType | LabelInValueType,
        option: BaseOptionType<OptionType>,
      ) => {
        props.onSelect && props.onSelect(value, option);
        const selectedValue = option.option;

        if (selectedValue) {
          dependencies?.forEach(dependency => {
            setFieldValue(
              form,
              dependency.name,
              dependency.getValue(selectedValue),
            );
          });

          form
            .validateFields(
              dependencies?.map(dependency => dependency.name) ?? [],
            )
            .catch(() => {
              // Validation error occurred, do nothing
            });
        }
      }}
      {...props}
    />
  );
}
