import React from 'react';
import {
  FaqViolationDtoFormFieldEnum,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { createFaqInitialValues } from '../../utils';

import { useApi } from 'hooks/ApiStoreContext';
import { FormModal } from 'components/WrappedComponents';
import { FaqFormValues } from '../../types';
import { convertFaqFormValueToFaqRequestDto } from '../../utils';
import {
  InputContent,
  SelectProfile,
  InputTitle,
  InputDomain,
} from '../Fields';

interface ModalCreationProps {
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
  idDomain: string | null;
}

function ModalCreation({
  visible,
  close,
  refreshDashboard,
  idDomain,
}: ModalCreationProps): React.ReactElement {
  const refController = useApi().AdminFonctionnelControllerApi;

  const validateFunction: (
    values: FaqFormValues,
    key: keyof FaqFormValues,
    field: FaqViolationDtoFormFieldEnum,
    value: unknown,
  ) => Promise<ReferentielViolationsDto> = (values, key, field, value) => {
    const request = convertFaqFormValueToFaqRequestDto({
      ...values,
      [key]: value,
    });
    return refController.validateFaqFieldUsingPOST(field, request);
  };

  return (
    <FormModal
      modalTitle="Créer une nouvelle question"
      initialValues={createFaqInitialValues(idDomain)}
      controllerValidate={(values: FaqFormValues) => {
        return refController.validateFaqUsingPOST(
          convertFaqFormValueToFaqRequestDto(values),
        );
      }}
      controllerValidateField={validateFunction}
      validationKey="faqViolationsDto"
      controllerSubmit={(values: FaqFormValues) => {
        return refController.createFaqUsingPOST(
          convertFaqFormValueToFaqRequestDto(values),
        );
      }}
      visible={visible}
      close={close}
      refreshDashboard={refreshDashboard}
    >
      {(values, validateField): React.ReactElement => {
        return (
          <>
            <InputDomain values={values} validateField={validateField} />
            <InputTitle values={values} validateField={validateField} />
            <SelectProfile values={values} validateField={validateField} />
            <InputContent values={values} validateField={validateField} />
          </>
        );
      }}
    </FormModal>
  );
}

export default ModalCreation;
