import React from 'react';

import {
  CentreVHUDto,
  CentreVHUViolationDtoFormFieldEnum,
  FaqDomainAdminDto,
  FaqDomainViolationDtoFormFieldEnum,
  ReferentielViolationsDto,
} from 'lib_api/lib/api/gen';

import { FaqDomainFormValues } from '../../types';
import { InputName } from '../Fields';
import {
  buildUpdateFaqDomainInitialValues,
  convertFaqDomainFormValueToFaqDomainRequestDto,
} from '../../utils';
import { useApi } from 'hooks/ApiStoreContext';
import { FormModal } from 'components/WrappedComponents';

interface ModalCreationProps {
  faqDomain: FaqDomainAdminDto;
  visible: boolean;
  close: () => void;
  refreshDashboard: () => void;
}

function ModalModification({
  faqDomain,
  visible,
  close,
  refreshDashboard,
}: ModalCreationProps): React.ReactElement {
  const idCorrelation = faqDomain.idCorrelation;
  const initialValues: FaqDomainFormValues =
    buildUpdateFaqDomainInitialValues(faqDomain);
  const refController = useApi().AdminFonctionnelControllerApi;

  const validateFunction: (
    values: FaqDomainFormValues,
    key: keyof FaqDomainFormValues,
    field: FaqDomainViolationDtoFormFieldEnum,
    value: unknown,
  ) => Promise<ReferentielViolationsDto> = (values, key, field, value) => {
    const request = convertFaqDomainFormValueToFaqDomainRequestDto({
      ...values,
    });
    return refController.validateFaqDomainFieldUsingPOST(field, request);
  };

  return (
    <FormModal
      modalTitle="Mettre à jour le domaine"
      initialValues={initialValues}
      controllerValidate={(values: FaqDomainFormValues) => {
        return refController.validateFaqDomainUsingPOST(
          convertFaqDomainFormValueToFaqDomainRequestDto(values),
        );
      }}
      controllerValidateField={validateFunction}
      validationKey="faqDomainViolationsDto"
      controllerSubmit={(values: FaqDomainFormValues) => {
        return refController.updateFaqDomainUsingPUT(
          idCorrelation,
          convertFaqDomainFormValueToFaqDomainRequestDto(values),
        );
      }}
      visible={visible}
      close={close}
      refreshDashboard={refreshDashboard}
    >
      {(values, validateField): React.ReactElement => {
        return (
          <>
            <InputName values={values} validateField={validateField} />
          </>
        );
      }}
    </FormModal>
  );
}

export default ModalModification;
