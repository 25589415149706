import {
  ReferentielControllerApi,
  ReferentielSearchResultDtoModeleDto,
  SearchModeleUsingSpecsUsingGETQueryParams,
} from 'lib_api/lib/api/gen';

export function searchModele(
  controller: ReferentielControllerApi,
  filter: SearchModeleUsingSpecsUsingGETQueryParams,
): Promise<ReferentielSearchResultDtoModeleDto> {
  return controller.searchModeleUsingSpecsUsingGET(filter);
}
