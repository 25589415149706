import React, { useEffect } from 'react';
import { Collapse as AntdCollapse } from 'antd';
import { Navigate, useParams } from 'react-router-dom';

import ResponsiveContainer from '../ResponsiveContainer';
import { useDisplayInformationMessageContext } from 'hooks/DisplayMessageContext';
import { useRefFAQDomainAdmin } from 'hooks/referentiels/useRefFAQDomainAdmin';
import { FaqAdminDtoProfilesEnum } from 'lib_api/lib/api/gen';
import { FAQs } from './FAQ';

import './FAQ.less';

const { Panel } = AntdCollapse;

function convertUrlParamToProfil(
  profile: string | undefined,
): FaqAdminDtoProfilesEnum | null {
  switch (profile) {
    case 'AUTORITE_FOURRIERE':
      return FaqAdminDtoProfilesEnum.AUTORITE_FOURRIERE;
    case 'FORCE_DE_L_ORDRE':
      return FaqAdminDtoProfilesEnum.FORCE_DE_L_ORDRE;
    case 'GARDIEN_FOURRIERE':
      return FaqAdminDtoProfilesEnum.GARDIEN_FOURRIERE;
    default:
      return null;
  }
}

const FAQAdmin: React.FC = (): React.ReactElement => {
  const params = useParams<Record<string, string>>();
  const profile = convertUrlParamToProfil(params.profile);
  if (profile === null) {
    return <Navigate replace to="/admin/referentiels/dashboard/faq-domaine" />;
  }
  const faqsWithDomain = useRefFAQDomainAdmin(profile);

  const { setMessagesShown } = useDisplayInformationMessageContext();

  useEffect(() => {
    setMessagesShown(false);
    return () => setMessagesShown(true);
  }, [setMessagesShown]);

  return (
    <ResponsiveContainer className="FAQ-container">
      <div className="FAQ">
        <h1 className="FAQ-title">Foire Aux Questions</h1>
        {(faqsWithDomain?.referenceDtoList?.length ?? -1) > 0 && (
          <AntdCollapse>
            {faqsWithDomain?.referenceDtoList.map(
              ({ name, faqs }, domainIndex) => (
                <Panel header={name} key={domainIndex}>
                  {<FAQs faqs={faqs} />}
                </Panel>
              ),
            )}
          </AntdCollapse>
        )}
      </div>
    </ResponsiveContainer>
  );
};
export default FAQAdmin;
