import React from 'react';
import { Alert as AntdAlert } from 'antd';

import { ConditionsReactivateCentreVHUDto } from 'lib_api/lib/api/gen/api';

import './ConditionReactivateCentreVHU.less';

interface ConditionReactivateCentreVHUProps {
  conditions: ConditionsReactivateCentreVHUDto | undefined;
}

function ConditionReactivateCentreVHU({
  conditions,
}: ConditionReactivateCentreVHUProps): React.ReactElement {
  return (
    <>
      {(conditions?.centreVHUValid || conditions?.alreadyExists) && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.centreVHUValid && (
                <p className="alertInvalid--txt">
                  Le centre VHU est déjà valide.
                </p>
              )}
              {conditions?.alreadyExists && (
                <p className="alertInvalid--txt">
                  Un centre VHU actif équivalent existe déjà dans le
                  référentiel.
                </p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateCentreVHU;
