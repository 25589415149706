import React from 'react';
import { Typography as AntdTypo } from 'antd';
import { Link } from 'react-router-dom';

import './Banner.less';
import LogoGouvernement from 'assets/images/jpg/logo-gouvernement.jpg';

const { Title } = AntdTypo;

interface Props {
  className?: string;
  pathToRedirect: string;
}

function Banner({ className, pathToRedirect }: Props): React.ReactElement {
  return (
    <>
      <Link to={pathToRedirect} className={className}>
        <img src={LogoGouvernement} className={'Image'} alt={'Gouvernement'} />
      </Link>

      <Title level={3} className={'Title'}>
        <div className={'TitleText'}>
          <strong className={'TitleStrongText'}>S</strong>ystème d&apos;{' '}
          <strong className={'TitleStrongText'}>I</strong>nformation national
          des <strong className={'TitleStrongText'}>F</strong>ourrières en
          automobile
        </div>
      </Title>
    </>
  );
}

export default Banner;
