import { useCallback, useState } from 'react';
import {
  AutoriteFourriereControllerApi,
  DossierResponseDto,
  ProlongationRequestDto,
  ProlongationViolationDto,
  ProlongationViolationDtoFormFieldEnum,
} from 'lib_api/lib/api/gen';

import { backAlertMessage } from 'hooks/utils/backAlertMessage';
import { defaultErrorMessage } from 'utils/ErrorMessages';
import { ProlongationMef } from 'types/ProlongationMef';
import {
  useValidationRequest,
  ValidationCallback,
} from 'hooks/utils/handleValidationRequest';
import { useApi } from 'hooks/ApiStoreContext';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { UNIX_TIMESTAMP_FORMAT } from 'utils/formats';

export function generateProlongationRequestDtoFromProlongationMef(
  values: ProlongationMef,
): ProlongationRequestDto {
  return {
    dateDecisionProcureur:
      values.dateDecisionProcureur?.format(UNIX_TIMESTAMP_FORMAT) || null,
  };
}

export async function validateProlongationRule(
  dossierId: string,
  controller: AutoriteFourriereControllerApi,
  values: ProlongationRequestDto,
  field: ProlongationViolationDtoFormFieldEnum,
): Promise<void> {
  try {
    const response = await controller.validateProlongationUsingPOST(
      dossierId,
      { field },
      values,
    );
    if (response.prolongationViolationDtoList) {
      const fieldViolations = response.prolongationViolationDtoList.filter(
        violation => violation.formField === field,
      );

      if (fieldViolations.length > 0) {
        return Promise.reject(
          fieldViolations.map(violation => violation.message).join(', '),
        );
      }
    }
  } catch (e) {
    if (e instanceof Response) {
      return Promise.reject((await backAlertMessage(e)).description);
    }
    return Promise.reject(defaultErrorMessage);
  }
  return Promise.resolve();
}

type ClassementManuelValidationCallback =
  ValidationCallback<AutoriteFourriereControllerApi>;

export function useValidateProlongation(
  onSuccess: () => Promise<DossierResponseDto>,
  thenSubmit: (dossier: DossierResponseDto) => void,
  catchSubmit: (errorResponse: Response) => void,
): [
  (requestGenerator: ClassementManuelValidationCallback) => Promise<void>,
  ProlongationViolationDto[] | null,
] {
  const afController = useApi().AutoriteFourriereControllerApi;
  return useValidationRequest(
    afController,
    'prolongationViolationDtoList',
    onSuccess,
    thenSubmit,
    catchSubmit,
  );
}

export function useSubmitProlongation(): [
  DossierResponseDto | null,
  (
    prolongationValues: ProlongationMef,
    dossierId: string,
  ) => Promise<DossierResponseDto>,
  (dossier: DossierResponseDto) => void,
  (errorResponse: Response) => void,
] {
  const behaviourOnError = useHandleBackErrors();
  const afController = useApi().AutoriteFourriereControllerApi;
  const [updatedDossier, setUpdatedDossier] =
    useState<DossierResponseDto | null>(null);

  const submitDossier = useCallback(
    (
      prolongationValues: ProlongationMef,
      dossierId: string,
    ): Promise<DossierResponseDto> => {
      const prolongationRequest =
        generateProlongationRequestDtoFromProlongationMef(prolongationValues);

      return afController.submitProlongationMiseEnFourriereUsingPOST(
        dossierId,
        prolongationRequest,
      );
    },
    [afController],
  );

  const onSuccess = useCallback(
    (dossier: DossierResponseDto): void => {
      setUpdatedDossier(dossier);
    },
    [setUpdatedDossier],
  );

  const onError = useCallback(
    (errorResponse: Response): void => {
      behaviourOnError(errorResponse);
    },
    [behaviourOnError],
  );

  return [updatedDossier, submitDossier, onSuccess, onError];
}
