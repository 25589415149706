import React from 'react';
import { Input as AntdInput } from 'antd';
import { InputProps as AntdInputProps } from 'antd/lib/input';

import { WrappedProps } from '../types';
import FieldWrapper from '../FieldWrapper';
import { useFormActionsContext } from '../../FormWrapper/FormActionsContext';

interface Props<T> {
  wrapperProps: WrappedProps<T>;
  numberInputProps: AntdInputProps;
}

function NumberInput<T>({
  wrapperProps,
  numberInputProps,
}: Props<T>): React.ReactElement {
  const inputNumber = React.useMemo(() => {
    return <AntdInput {...numberInputProps} />;
  }, [numberInputProps]);

  const itemProps = {
    ...wrapperProps.itemProps,
    getValueFromEvent: (event: React.ChangeEvent<HTMLInputElement>): string => {
      // This regex will check if the input contains only a "0"
      // or it contains a number which not start with a "0" (ex: 09284).
      // It will also prevent against letters or symbols input.
      // <!> This regex will not treat decimal numbers.
      const reg = new RegExp('^(([0])|((?!(0))[0-9]*))$');
      if (!reg.test(event.currentTarget.value)) {
        return event.currentTarget.defaultValue;
      }
      return event.currentTarget.value;
    },
  };

  const formActions = useFormActionsContext();
  const fieldKey = wrapperProps.fieldKey;
  const value = formActions.getFieldValue(fieldKey);
  return (
    <FieldWrapper
      {...wrapperProps}
      itemProps={itemProps}
      ConsultationElement={value}
      WrappedInput={inputNumber}
    />
  );
}

export default NumberInput;
