import { useState } from 'react';
import { useHandleBackErrors } from 'hooks/utils/handleBackErrors';
import { RequestStatus } from 'types/RequestStatus';

type ImportFunction = (file: Blob) => void;

interface ImportFileResult {
  importFile: () => ImportFunction;
  importStatus: RequestStatus | undefined;
}

/**
 * Hook to import any referentiel in CSV or JSOn format
 */
export function useImportFile(
  importFile: (file: Blob) => Promise<Response>,
): ImportFileResult {
  const behaviourOnError = useHandleBackErrors();
  const [status, setStatus] = useState<RequestStatus>();

  const importFileFunc = () => {
    return (file: Blob) => {
      setStatus(RequestStatus.PENDING);
      importFile(file)
        .then((_: Response) => setStatus(RequestStatus.SUCCESS))
        .catch((errorResponse: Response) => {
          setStatus(RequestStatus.ERROR);
          behaviourOnError(errorResponse, true);
        });
    };
  };

  return {
    importFile: importFileFunc,
    importStatus: status,
  };
}
