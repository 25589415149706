import React, { Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { useAuthentication } from '../hooks/utils/authentication';
import { ErrorDto } from 'lib_api/lib/api/gen';
import { backAlertMessage } from 'hooks/utils/backAlertMessage';

interface OpenIdConnectIDPProps {
  setToken: Dispatch<SetStateAction<string | undefined>>;
  setRefreshToken: Dispatch<SetStateAction<string | undefined>>;
  setAuthError: Dispatch<SetStateAction<ErrorDto | undefined>>;
}

/**
 * Authentication using OpenID Connect Provider:
 * - redirect to portal page to get a code
 * - send this code to backend to retrieve a jwt
 */
function OpenIdConnectIDP({
  setToken,
  setRefreshToken,
  setAuthError,
}: OpenIdConnectIDPProps): React.ReactElement {
  const { redirectLogin, authenticate } = useAuthentication(setAuthError);
  const location = useLocation();
  const code = new URLSearchParams(location.search).get('code');

  // Authenticate/redirect only once when this component is mounted
  // This component is supposed to be unmounted when authentication is performed
  // (until it is re-mounted when we need to authenticate again)
  React.useEffect(() => {
    if (code === null) {
      // No code provided yet in url --> redirect to Passage 2 portal
      redirectLogin();
    } else {
      // Code provided, fetch JWT from backend
      authenticate(code)
        .then(jwt => {
          if (jwt !== null) {
            setRefreshToken(jwt.refreshToken || undefined);
            setToken(jwt.accessToken);
          }
        })
        .catch(backAlertMessage);
    }
  }, [redirectLogin, authenticate, setToken, setRefreshToken, code]);

  return <div />;
}

export default OpenIdConnectIDP;
