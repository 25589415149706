import React, { useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { TableSort } from 'components/WrappedComponents/Table/types';

import DSRFilterProvider from 'hooks/DSRFilterStoreContext';
import PaginationProvider from 'hooks/PaginationStoreContext';
import { PAGINATION_DEFAULT } from 'utils/DashboardUtils';
import Dashboard from 'pages/delegue-securite-routiere/Dashboard';
import PageDossier from 'pages/delegue-securite-routiere/PageDossier';
import { DashboardDSRFilterFormValues } from 'pages/delegue-securite-routiere/Dashboard/types';
import { NOT_FOUND_PATH } from 'utils/enumData';
import SortProvider from 'hooks/SortStoreContext';
import { SortOptions } from 'hooks/dossiers/useSearchDossierSummaries';

interface TablePagination {
  page: number;
  pageSize: number;
}

const DsrRoutes = (): React.ReactElement => {
  const [pagination, setPagination] =
    useState<TablePagination>(PAGINATION_DEFAULT);
  const [filter, setFilter] = useState<Partial<DashboardDSRFilterFormValues>>(
    {},
  );
  const [sort, setSort] = useState<Partial<TableSort<SortOptions | undefined>>>(
    {
      IMMATRICULATION: true,
    },
  );

  return (
    <PaginationProvider
      value={{ pagination: pagination, setPagination: setPagination }}
    >
      <DSRFilterProvider value={{ filter: filter, setFilter: setFilter }}>
        <SortProvider value={{ sort: sort, setSort: setSort }}>
          <Routes>
            <Route path="" element={<Dashboard />} />
            <Route path="dossier/:id" element={<PageDossier />} />
            <Route
              path="*"
              element={<Navigate replace to={NOT_FOUND_PATH} />}
            />
          </Routes>
        </SortProvider>
      </DSRFilterProvider>
    </PaginationProvider>
  );
};

export default DsrRoutes;
