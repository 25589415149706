import React, { useCallback } from 'react';
import { Spin } from 'antd';

import { UpdateEntitesRattachementViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen/api';

import { useApi } from 'hooks/ApiStoreContext';
import { useDossierAdminContext } from 'hooks/dossierAdmin/DossierAdminContext';
import { useDossierCompletAdmin } from 'hooks/dossierAdmin/useDownloadDossierAdminFonctionnel';
import useAsyncSearchFourriere from 'hooks/search/async/useAsyncSearchFourriere';
import useAsyncSearchAdminUniteFo from 'hooks/search/async/useAsyncSearchUniteFo';
import useAsyncSearchAutoritefourriere from 'hooks/search/async/useAsyncSearchAutoritefourriere';
import UpdateFicheModal from 'components/WrappedComponents/UpdateFicheModal/UpdateFicheModal';
import SelectWithSearch from 'components/WrappedComponents/Form/FormFields/Select/SelectWithSearch';
import useInitialValues from './useInitialValues';
import buildValidateFunction from './buildValidateFunction';
import buildSubmitFunction from './buildSubmitFunction';
import {
  newAutoriteFourriereIdCorrelation,
  newFourriereIdCorrelation,
  newUniteFoIdCorrelation,
} from './constants';

interface UpdateEntiteRatachementProps {
  closeModal: () => void;
}

function UpdateEntiteRatachement({
  closeModal,
}: UpdateEntiteRatachementProps): React.ReactElement {
  const [{ result, setDossier }, { fetchEvents }] = useDossierAdminContext();
  const dossierId = result.id;
  const { dossier: dossierAdmin } = useDossierCompletAdmin(dossierId);
  const refController = useApi().AdminFonctionnelControllerApi;
  const initialValues = useInitialValues(dossierAdmin);

  const validateFunction = useCallback(
    buildValidateFunction(refController, dossierId),
    [refController, dossierId],
  );
  const submitFunction = useCallback(
    buildSubmitFunction(
      refController,
      dossierId,
      setDossier,
      fetchEvents,
      closeModal,
    ),
    [refController, dossierId],
  );

  if (!dossierAdmin) {
    return <Spin />;
  }

  return (
    <UpdateFicheModal
      modalTitle="Mettre à jour les entités de rattachement"
      warningMessage={`La nouvelle entité pourra accéder à ce dossier via son tableau de bord, contrairement à la précédente qui n'y aura plus accès.`}
      onSubmit={submitFunction}
      closeModal={closeModal}
      initialValues={initialValues}
      commentFieldKey={
        UpdateEntitesRattachementViolationDtoFormFieldEnum.COMMENT
      }
      controllerValidateField={validateFunction}
      validationKey="updateEntitesRattachementViolationDtoList"
    >
      {(values, validateField) => {
        return (
          <>
            <SelectWithSearch
              label="Fourrière"
              values={values}
              defaultValue={values.newFourriereIdCorrelation ?? undefined}
              validateField={validateField}
              field={
                UpdateEntitesRattachementViolationDtoFormFieldEnum.NEW_FOURRIERE_CORRELATION_ID
              }
              fieldsToValidateOnChange={[
                newUniteFoIdCorrelation,
                newAutoriteFourriereIdCorrelation,
              ]}
              formKey={newFourriereIdCorrelation}
              useSearchEntities={useAsyncSearchFourriere}
            />
            <SelectWithSearch
              label="Unité FO"
              values={values}
              defaultValue={values.newUniteFoIdCorrelation ?? undefined}
              validateField={validateField}
              field={
                UpdateEntitesRattachementViolationDtoFormFieldEnum.NEW_UNITE_FO_CORRELATION_ID
              }
              fieldsToValidateOnChange={[
                newFourriereIdCorrelation,
                newAutoriteFourriereIdCorrelation,
              ]}
              formKey={newUniteFoIdCorrelation}
              useSearchEntities={useAsyncSearchAdminUniteFo}
            />
            <SelectWithSearch
              label="Autorité de fourrière"
              values={values}
              validateField={validateField}
              fieldsToValidateOnChange={[
                newFourriereIdCorrelation,
                newUniteFoIdCorrelation,
              ]}
              defaultValue={
                values.newAutoriteFourriereIdCorrelation ?? undefined
              }
              field={
                UpdateEntitesRattachementViolationDtoFormFieldEnum.NEW_AUTORITE_FOURRIERE_CORRELATION_ID
              }
              formKey={newAutoriteFourriereIdCorrelation}
              useSearchEntities={useAsyncSearchAutoritefourriere}
            />
          </>
        );
      }}
    </UpdateFicheModal>
  );
}

export default UpdateEntiteRatachement;
