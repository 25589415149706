import React from 'react';
import { Alert as AntdAlert } from 'antd';

import { ConditionsReactivateMotifMefDto } from 'lib_api/lib/api/gen/api';

interface ConditionReactivateMotifMefProps {
  conditions: ConditionsReactivateMotifMefDto | undefined;
}

function ConditionReactivateMotifMef({
  conditions,
}: ConditionReactivateMotifMefProps): React.ReactElement {
  const isInvalid: boolean =
    (conditions?.motifMefValid || conditions?.alreadyExist) ?? false;

  return (
    <>
      {isInvalid && (
        <AntdAlert
          type="warning"
          className="alertInvalid"
          message={
            <>
              {conditions?.motifMefValid && (
                <p className="alertInvalid--txt">
                  Le motif de MEF est déjà valide.
                </p>
              )}
              {conditions?.alreadyExist && (
                <p className="alertInvalid--txt">
                  Le motif de MEF existe déjà.
                </p>
              )}
            </>
          }
        />
      )}
    </>
  );
}

export default ConditionReactivateMotifMef;
