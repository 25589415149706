import React from 'react';
import { MarqueDto } from 'lib_api/lib/api/gen';
import { Table } from 'components/WrappedComponents';
import { ColumnProps } from 'components/WrappedComponents/Table/types';
import { categorieMarqueMapping } from 'utils/enumData';
import { useReferentielDashboardSearchWithSort } from '../search/search';
import CellDetailsAndActions from './CellDetailsAndActions';
import { buildHeader, useSearchMarque } from './utils';
import { MarqueSort } from './types';

import './DashboardMarque.less';

function buildDetailsContent(
  data: MarqueDto,
  refreshDashboard: () => void,
): React.ReactElement {
  return (
    <CellDetailsAndActions marque={data} refreshDashboard={refreshDashboard} />
  );
}

function DashboardMarque(): React.ReactElement {
  const {
    fetchData,
    pagination,
    setPagination,
    filter,
    setFilter,
    sort,
    setSort,
    refreshDashboard,
  } = useReferentielDashboardSearchWithSort(
    useSearchMarque,
    {},
    {
      LIBELLE: true,
    },
  );

  function buildColumns(): ColumnProps<MarqueDto, MarqueSort>[] {
    return [
      {
        title: 'Marque',
        buildCellContent: data => data.libelle,
        sortedField: MarqueSort.LIBELLE,
      },
      {
        title: 'Catégorie',
        buildCellContent: data =>
          data.categorie ? categorieMarqueMapping[data.categorie] : null,
        sortedField: MarqueSort.CATEGORIE,
      },
      {
        title: 'Type',
        buildCellContent: data => data.type,
        sortedField: MarqueSort.TYPE,
      },
      {
        title: '',
        buildCellContent: data => buildDetailsContent(data, refreshDashboard),
      },
    ];
  }

  const columns: ColumnProps<MarqueDto, MarqueSort>[] = buildColumns();

  return (
    <div className="table-container">
      <Table
        columns={columns}
        fetchData={fetchData}
        header={buildHeader}
        buildItemKey={(item): string => item.id}
        pageSizeOptions={[10, 20, 50]}
        pagination={pagination}
        setPagination={setPagination}
        filter={filter}
        setFilter={setFilter}
        setSort={setSort}
        sort={sort}
      />
    </div>
  );
}

export default DashboardMarque;
