import React from 'react';
import { Input } from 'components/WrappedComponents';
import { ProfilFourriereViolationDtoFormFieldEnum } from 'lib_api/lib/api/gen';

import {
  FicheProfilFourriereBlocCoordonnees,
  InputPropCoordonnees,
} from 'types/FicheProfil/FicheProfilGardienFourriere';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { useUserStore } from 'hooks/UserStoreContext';
import { useApi } from 'hooks/ApiStoreContext';
import { validateFicheProfilGf } from '../../utils';

function InputPhoneNumber({
  values,
  isEditable,
  generateRequestDto,
}: InputPropCoordonnees): React.ReactElement {
  const user = useUserStore();
  const gfController = useApi().GardienFourriereControllerApi;
  const key: keyof FicheProfilFourriereBlocCoordonnees = 'phoneNumber';
  const label = 'Téléphone fixe';

  return (
    <Input
      wrapperProps={{
        values: values,
        fieldKey: key,
        itemProps: {
          label: label,
          rules: [
            {
              required: true,
              validator: async (_rule, value): Promise<void> => {
                return validateFicheProfilGf(
                  gfController,
                  ProfilFourriereViolationDtoFormFieldEnum.TELEPHONE_FIXE,
                  user.idCorrelationFourriere || '',
                  generateRequestDto({ ...values, [key]: value }),
                );
              },
            },
          ],
          labelCol: { span: CSSUtils.defaultLabelCol },
          wrapperCol: { span: CSSUtils.largeWrapperCol },
        },
        editable: isEditable,
      }}
      inputProps={{
        placeholder: FormPlaceholders.Figures,
      }}
    />
  );
}

export default InputPhoneNumber;
