import React, { useState } from 'react';
import { Button as AntdButton, message as AntdMessage } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Filter from '../Filter';
import { FilterDashboardComptesUtilisateurs } from './types';
import ModalCreation from '../../Creation/ModalCreation';

import './DashboardHeader.less';
import { LONG_DELAY } from '../constants';

interface DashboardHeaderProps {
  setFilter: (value: Partial<FilterDashboardComptesUtilisateurs>) => void;
  filter: Partial<FilterDashboardComptesUtilisateurs>;
}

function DashboardHeader({
  setFilter,
  filter,
}: DashboardHeaderProps): React.ReactElement {
  const [modalCreationOpen, setModalCreationOpen] = useState<boolean>(false);

  const openModal = () => {
    setModalCreationOpen(true);
  };

  const closeModal = () => {
    setModalCreationOpen(false);
  };

  return (
    <div className="dashboard-header-fields">
      <Filter filter={filter} setFilter={setFilter} />
      <AntdButton
        className={'create-dossier-button'}
        icon={<PlusOutlined />}
        type={'primary'}
        shape={'round'}
        onClick={openModal}
      >
        Créer un nouveau compte
      </AntdButton>
      <ModalCreation
        close={closeModal}
        callAfterSubmit={(result, email) => {
          let successMessage = `Le compte ${result.username} a été créé avec succès.`;
          if (email) {
            successMessage += ` Un courriel d'initialisation de compte a été automatiquement envoyé à ${email}.`;
          }
          void AntdMessage.success(successMessage, LONG_DELAY);
          setFilter({
            ...filter,
          });
        }}
        visible={modalCreationOpen}
      />
    </div>
  );
}

export default DashboardHeader;
