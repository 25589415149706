import React from 'react';
import { DateFieldDisplayProps } from './types';
import { DataFieldDisplay } from './DataFieldDisplay';
import moment from 'moment';
import { DATE_FORMAT } from 'utils/formats';

export function DateFieldDisplay({
  label,
  value,
  labelClass = 'label',
}: DateFieldDisplayProps): React.ReactElement {
  return (
    <DataFieldDisplay
      label={label}
      formatter={date => moment(date).format(DATE_FORMAT)}
      value={value}
      labelClass={labelClass}
    />
  );
}
