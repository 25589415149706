import React from 'react';
import { TraitementResponseDtoClassementEnum } from 'lib_api/lib/api/gen';
import { Select } from 'components/WrappedComponents';

import { FormFilterFieldGardien } from 'pages/gardien-fourriere/Dashboard/DashboardHeader/Filters/types';
import { classementEnumMapping } from 'utils/enumData';
import { CSSUtils } from 'utils/CSSUtils';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';

const selectItems =
  // List of enum options...
  Object.values(TraitementResponseDtoClassementEnum)
    .filter(
      enumValue => enumValue !== TraitementResponseDtoClassementEnum.MANUEL,
    )
    // ... with their corresponding label...
    .map(enumValue => ({
      value: enumValue.toString(),
      displayValue: classementEnumMapping[enumValue].label,
    }));

export function ClassementFilterField({
  values,
}: FormFilterFieldGardien): React.ReactElement {
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'classement',
        itemProps: {
          label: 'Classement',
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{ mode: 'multiple', placeholder: FormPlaceholders.Select }}
    />
  );
}
