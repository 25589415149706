import React from 'react';

import { SearchBar } from 'components/WrappedComponents';
import { FilterInformationMessage } from '../DashboardHeader/types';

import './Filter.less';

interface Props {
  setFilter: (value: Partial<FilterInformationMessage>) => void;
  filter: Partial<FilterInformationMessage>;
}

export default function Filter({
  filter,
  setFilter,
}: Props): React.ReactElement {
  return (
    <div className="dashboard-header-filter">
      <SearchBar
        value={filter.searchField}
        setValue={value => {
          setFilter({ ...filter, searchField: value || undefined });
        }}
        antdProps={{
          placeholder: 'Rechercher dans le titre ou le contenu du message',
          style: { maxWidth: 300 },
        }}
        rounded={true}
      />
    </div>
  );
}
