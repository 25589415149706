import React, { useState, useCallback } from 'react';
import { Button as AntdButton, Modal as AntdModal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { useGFFilterContext } from 'hooks/GFFilterStoreContext';
import Creation from '../../../Dossier/Creation';

import './CreateDossier.less';

function CreateDossier(): React.ReactElement {
  const [visible, setVisible] = useState(false);
  const [, setfilters] = useGFFilterContext();

  const handleClosure = useCallback(() => {
    setVisible(false);
    setfilters(filters => {
      return {
        ...filters,
      };
    });
  }, [setVisible, setfilters]);

  return (
    <>
      <AntdButton
        className={'create-dossier-button'}
        icon={<PlusOutlined />}
        type={'primary'}
        shape={'round'}
        onClick={() => setVisible(true)}
      >
        Créer une nouvelle fiche véhicule
      </AntdButton>

      <AntdModal
        title={
          <div className={'centered'}>Créer une nouvelle fiche véhicule</div>
        }
        visible={visible}
        onCancel={() => setVisible(false)}
        destroyOnClose={true}
        width={'55%'}
        footer={null}
        maskClosable={false}
      >
        <Creation callAfterSubmit={handleClosure} />
      </AntdModal>
    </>
  );
}

export default CreateDossier;
