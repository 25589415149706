import React from 'react';
import { Select } from 'components/WrappedComponents';
import { ReferenceDtoStatutDto } from 'lib_api/lib/api/gen';

import { useStatutConsultable } from 'hooks/referentiels/useStatutConsultable';
import { FormPlaceholders } from 'types/enums/FormPlaceholders';
import { CSSUtils } from 'utils/CSSUtils';
import { statutEnumMapping } from 'utils/enumData';
import { DashboardForcesOrdreFilterForm } from 'pages/forces-de-l-ordre/Dashboard/types';

interface SelectStatutProps {
  values: Partial<DashboardForcesOrdreFilterForm>;
}

function selectItemsFromRef(refs: ReferenceDtoStatutDto) {
  return refs.statuts.map(enumValue => {
    return {
      value: enumValue.toString(),
      displayValue: statutEnumMapping[enumValue].label,
    };
  });
}

function SelectStatut({ values }: SelectStatutProps): React.ReactElement {
  const statuts = useStatutConsultable();
  const selectItems = React.useMemo(
    () => (statuts !== undefined ? selectItemsFromRef(statuts) : []),
    [statuts],
  );
  return (
    <Select
      wrapperProps={{
        values,
        fieldKey: 'statut',
        itemProps: {
          label: 'Statut',
          hasFeedback: false,
          labelCol: { span: CSSUtils.defaultLabelCol },
        },
      }}
      optionProps={selectItems}
      selectProps={{
        mode: 'multiple',
        placeholder: FormPlaceholders.Select,
      }}
    />
  );
}

export default SelectStatut;
