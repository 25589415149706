import React, { createContext, useContext, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

type RedirectionUrl = string | null;

interface RedirectHandlerProps {
  children: React.ReactNode;
}

type RedirectContextProps = {
  setRedirectUrl: React.Dispatch<React.SetStateAction<RedirectionUrl>>;
};

const RedirectContext = createContext<RedirectContextProps | undefined>(
  undefined,
);

/*
 * This component aims to manage every redirections by passing a url
 */
const RedirectHandler = ({
  children,
}: RedirectHandlerProps): React.ReactElement => {
  const [redirectUrl, setRedirectUrl] = useState<RedirectionUrl>(null);

  const ContextPayload = React.useMemo(
    () => ({ setRedirectUrl }),
    [setRedirectUrl],
  );

  useEffect(() => {
    if (redirectUrl !== null) {
      setRedirectUrl(null);
    }
  }, [redirectUrl]);

  return (
    <RedirectContext.Provider value={ContextPayload}>
      {redirectUrl !== null && <Navigate to={redirectUrl} />}
      {children}
    </RedirectContext.Provider>
  );
};

/*
 * More convenient way to use the hook on import
 * Prevent for an undefined value
 */
export const useRedirect = (): RedirectContextProps => {
  const value: RedirectContextProps | undefined = useContext(RedirectContext);
  if (value === undefined) {
    throw new Error(
      'You should use this hook inside the RedirectContextProvider',
    );
  }
  return value;
};

export default RedirectHandler;
