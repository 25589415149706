import React from 'react';
import { Timeline as AntdTimeline } from 'antd';
import './Timeline.less';
import { TimelineProps } from './types';

function Timeline({
  items,
  antdTimelineProps,
}: TimelineProps): React.ReactElement {
  return (
    <AntdTimeline mode="left" {...antdTimelineProps} className="timeline">
      {items.map((item, index) => (
        <AntdTimeline.Item
          key={index}
          label={item.label}
          dot={item.dot}
          {...item.antdTimelineItemProps}
        >
          {item.value}
        </AntdTimeline.Item>
      ))}
    </AntdTimeline>
  );
}

export default Timeline;
