import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { TableProps as AntdTableProps } from 'antd/lib/table';
import { PaginationProps as AntdPaginationProps } from 'antd/lib/pagination';

import {
  ColumnProps,
  FetchDataFunctionWithoutSort,
  FetchDataResult,
  TableHeaderFunctionWithoutSort,
  TablePagination,
  TableSelect,
} from '../types';
import { Pagination } from '../Pagination';
import { BasicTable } from 'components/WrappedComponents/Table';

import './TableWithoutSort.less';

interface TableWithoutSortProps<DataType extends object, FilterType> {
  // Table - general
  columns: ColumnProps<DataType>[];
  fetchData?: FetchDataFunctionWithoutSort<DataType, FilterType>;
  // Table - select items
  canSelectItems?: boolean;
  buildItemKey?: (item: DataType) => string | number;
  onSelectItems?: (selectedItems: DataType[]) => void;
  // Header
  header?: TableHeaderFunctionWithoutSort<DataType, FilterType>;
  // Pagination
  pageSizeOptions?: number[];
  pagination: TablePagination;
  setPagination: Dispatch<SetStateAction<TablePagination>>;
  // Other
  disabled?: boolean;
  antdTableProps?: Partial<AntdTableProps<DataType>>;
  antPaginationProps?: Partial<AntdPaginationProps>;
  //  filters
  filter: Partial<FilterType>;
  setFilter: Dispatch<SetStateAction<Partial<FilterType>>>;
}

/**
 * Table component to display a large amount of data. Data are not fetch all at once, but
 * page by page depending on pagination state and filters applied. This component provide a SimpleTable, along with filter,
 * header and pagination components.
 *
 * @param columns - Paramters to represent columns used in this Table. Each column is
 *        associated with a single attribute of fetched items, and may provide a button
 *        for sorting functionnality.
 * @param fetchData - Function called to fetch data (either sync or async) based on
 *        current pagination state, sorting order and filter applied. This function is
 *        supposed to return a list of fetched object corresponding to those filters.
 *        This function also return the total number of items corresponding to those
 *        filters, regardless of pagination.
 * @param canSelectItems - When true, add checkboxes to select items.
 * @param buildItemKey - Function to build a unique key for each item. Required when
 *        using item seection.
 * @param onSelectItems - When useSelectItems is true, this callback is triggered when
 *        selected items change.
 * @param header - Callback to generate a component used ahead of Table. This component
 *        may be use to set filters, or to display some data (ex: "100 items found").
 * @param pageSizeOptions - When defined, Pagination sub component will propose a
 *        PageSizeChanger with those options.
 * @param disabled
 * @param antdTableProps - To use other props defined by Antd library on Table component
 *        (/!\ may interfere with props already defined by this component).
 * @param paginationProps - To use other props defined by Antd library on Pagination
 *        component (/!\ may interfere with props already defined by this component).
 */
function TableWithoutSort<DataType extends object, FilterType>({
  // Table - general
  columns,
  fetchData,
  // Table - select items
  canSelectItems,
  buildItemKey,
  onSelectItems,
  // Header
  header,
  // Pagination
  pageSizeOptions,
  pagination,
  setPagination,
  // Other
  disabled,
  antdTableProps,
  antPaginationProps,
  //  filters
  filter,
  setFilter,
}: TableWithoutSortProps<DataType, FilterType>): React.ReactElement {
  const [isLoadingData, setIsLoadingData] = useState<boolean>(true);
  const [fetchedData, setFetchedData] = useState<
    FetchDataResult<DataType> | undefined
  >(undefined);
  const [isOnError, setIsOnError] = useState<boolean>(false);

  // This variable is increase each time we want to re-fetch data
  const [triggerUpdate, setTriggerUpdate] = useState<number>(0);

  const [selected, setSelected] = useState<TableSelect<DataType>>([]);

  function innerSetSelected(newSelected: TableSelect<DataType>): void {
    setSelected(newSelected);
    onSelectItems && onSelectItems(newSelected);
  }

  // Go back to pagination when filter is update
  function innerSetFilter(filter: Partial<FilterType>): void {
    setPagination({ ...pagination, page: 1 });
    setFilter(filter);
  }

  function innerSetPagination(newPagination: TablePagination): void {
    setPagination(newPagination);
    // Reset selected items
    innerSetSelected([]);
  }

  // When the parameters change, we recall the function to retrieve corresponding data
  useEffect(() => {
    let isOutdated = false;
    // Fetch new data
    const loadNewData = async function loadNewData(): Promise<void> {
      try {
        if (fetchData !== undefined) {
          setIsLoadingData(true);
          const data = await fetchData(pagination, filter);
          if (!isOutdated) {
            setFetchedData(data);
            setIsOnError(false);
            setIsLoadingData(false);
          }
        }
      } catch (e) {
        if (!isOutdated) {
          setFetchedData({ data: [], total: 0 });
          setIsOnError(true);
          setIsLoadingData(false);
        }
      }
    };
    void loadNewData();
    return (): void => {
      isOutdated = true;
    };
  }, [pagination, filter, triggerUpdate, fetchData]);

  return (
    <>
      {header && header(fetchedData, selected, filter, innerSetFilter)}
      <BasicTable
        data={fetchedData !== undefined ? fetchedData.data : []}
        columnsProps={columns}
        isLoading={isLoadingData}
        reload={() => setTriggerUpdate(val => ++val)}
        isOnError={isOnError}
        selectItems={
          canSelectItems
            ? {
                selected: selected,
                onSelect: innerSetSelected,
              }
            : undefined
        }
        buildItemKey={buildItemKey}
        antdTableProps={{ ...antdTableProps, pagination: false }} // We use our custom Pagination component instead
      />
      <Pagination
        pagination={{
          paginated: pagination,
          setPagination: innerSetPagination,
        }}
        total={fetchedData !== undefined ? fetchedData.total : 0}
        pageSizeOptions={pageSizeOptions}
        disabled={disabled}
        antdPaginationProps={antPaginationProps}
      />
    </>
  );
}

export default TableWithoutSort;
