import { ErrorDtoCodeEnum } from 'lib_api/lib/api/gen';

export type BackErrorMessages = {
  [key in ErrorDtoCodeEnum]: string;
} & {
  default: string;
};

// Some error messages received from the back can be displayed as they are
// In particular those indicating problems in reading CSV files
export const displayableBackErrorMessages: ErrorDtoCodeEnum[] = [
  ErrorDtoCodeEnum.COMPTES_UTILISATEURS_BAD_CSV_LINES,
  ErrorDtoCodeEnum.COMPTES_UTILISATEURS_RESPONSE_CSV_EXCEPTION,
  ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UNABLE_PARSE_JSON_RESPONSE,
  ErrorDtoCodeEnum.COMPTES_UTILISATEURS_UNABLE_READ_FILE,
  ErrorDtoCodeEnum.HERMES_BAD_CSV_LINES,
  ErrorDtoCodeEnum.HERMES_UNREADABLE_FILE,
  ErrorDtoCodeEnum.REFERENTIEL_NOT_FOUND,
  ErrorDtoCodeEnum.REFERENTIEL_PROFILE_NOT_FOUND,
  ErrorDtoCodeEnum.REFERENTIEL_INVALID_DATA,
  ErrorDtoCodeEnum.REFERENTIEL_INVALID_FILE,
  ErrorDtoCodeEnum.CLASSEMENT_CSV_ERROR,
  ErrorDtoCodeEnum.CLASSEMENT_BAD_TABLE_FORMAT_ERROR,
  ErrorDtoCodeEnum.FILE_ENCODING_ERROR,
];
